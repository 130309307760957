export const Ten = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["888", "4"],
    "01/02/2024": ["123", "6"],
    "01/03/2024": ["147", "2"],
    "01/04/2024": ["222", "6"],
    "01/05/2024": ["778", "2"],
    "01/06/2024": ["123", "6"],
    "01/07/2024": ["125", "8"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["456", "5"],
    "01/09/2024": ["555", "5"],
    "01/10/2024": ["127", "0"],
    "01/11/2024": ["0", "0"],
    "01/12/2024": ["458", "7"],
    "01/13/2024": ["115", "7"],
    "01/14/2024": ["460", "0"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["359", "7"],
    "01/16/2024": ["456", "5"],
    "01/17/2024": ["460", "0"],
    "01/18/2024": ["123", "6"],
    "01/19/2024": ["458", "7"],
    "01/20/2024": ["123", "6"],
    "01/21/2024": ["357", "5"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["456", "5"],
    "01/23/2024": ["249", "5"],
    "01/24/2024": ["358", "6"],
    "01/25/2024": ["290", "1"],
    "01/26/2024": ["130", "4"],
    "01/27/2024": ["129", "2"],
    "01/28/2024": ["235", "0"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["120", "3"],
    "01/30/2024": ["289", "9"],
    "01/31/2024": ["348", "5"],
    "02/01/2024": ["167", "4"],
    "02/02/2024": ["156", "2"],
    "02/03/2024": ["123", "6"],
    "02/04/2024": ["370", "0"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["179", "7"],
    "02/06/2024": ["245", "1"],
    "02/07/2024": ["369", "8"],
    "02/08/2024": ["459", "8"],
    "02/09/2024": ["890", "7"],
    "02/10/2024": ["160", "7"],
    "02/11/2024": ["389", "0"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["670", "3"],
    "02/13/2024": ["349", "6"],
    "02/14/2024": ["560", "1"],
    "02/15/2024": ["337", "3"],
    "02/16/2024": ["100", "1"],
    "02/17/2024": ["145", "0"],
    "02/18/2024": ["145", "0"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["348", "5"],
    "02/20/2024": ["357", "5"],
    "02/21/2024": ["155", "1"],
    "02/22/2024": ["145", "0"],
    "02/23/2024": ["370", "0"],
    "02/24/2024": ["190", "0"],
    "02/25/2024": ["460", "0"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["289", "9"],
    "02/27/2024": ["467", "7"],
    "02/28/2024": ["247", "3"],
    "02/29/2024": ["347", "4"],
    "03/01/2024": ["140", "5"],
    "03/02/2024": ["347", "4"],
    "03/03/2024": ["377", "7"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["358", "6"],
    "03/05/2024": ["220", "4"],
    "03/06/2024": ["299", "0"],
    "03/07/2024": ["589", "2"],
    "03/08/2024": ["389", "0"],
    "03/09/2024": ["356", "4"],
    "03/10/2024": ["467", "7"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["140", "5"],
    "03/12/2024": ["149", "4"],
    "03/13/2024": ["448", "6"],
    "03/14/2024": ["566", "7"],
    "03/15/2024": ["200", "2"],
    "03/16/2024": ["345", "2"],
    "03/17/2024": ["128", "1"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["136", "0"],
    "03/19/2024": ["269", "7"],
    "03/20/2024": ["114", "6"],
    "03/21/2024": ["115", "7"],
    "03/22/2024": ["490", "3"],
    "03/23/2024": ["489", "1"],
    "03/24/2024": ["158", "4"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["269", "7"],
    "03/26/2024": ["129", "2"],
    "03/27/2024": ["127", "0"],
    "03/28/2024": ["169", "6"],
    "03/29/2024": ["449", "7"],
    "03/30/2024": ["238", "3"],
    "03/31/2024": ["235", "0"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["149", "4"],
    "04/02/2024": ["236", "1"],
    "04/03/2024": ["568", "9"],
    "04/04/2024": ["789", "4"],
    "04/05/2024": ["568", "9"],
    "04/06/2024": ["356", "4"],
    "04/07/2024": ["290", "1"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["450", "9"],
    "04/09/2024": ["118", "0"],
    "04/10/2024": ["249", "5"],
    "04/11/2024": ["567", "8"],
    "04/12/2024": ["349", "6"],
    "04/13/2024": ["588", "1"],
    "04/14/2024": ["379", "9"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["229", "3"],
    "04/16/2024": ["778", "2"],
    "04/17/2024": ["114", "6"],
    "04/18/2024": ["338", "4"],
    "04/19/2024": ["235", "0"],
    "04/20/2024": ["189", "8"],
    "04/21/2024": ["690", "5"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["379", "9"],
    "04/23/2024": ["490", "3"],
    "04/24/2024": ["460", "0"],
    "04/25/2024": ["136", "0"],
    "04/26/2024": ["480", "2"],
    "04/27/2024": ["178", "6"],
    "04/28/2024": ["230", "5"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["466", "6"],
    "04/30/2024": ["450", "9"],
    "05/01/2024": ["360", "9"],
    "05/02/2024": ["250", "7"],
    "05/03/2024": ["156", "2"],
    "05/04/2024": ["600", "6"],
    "05/05/2024": ["340", "7"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["357", "5"],
    "05/07/2024": ["390", "2"],
    "05/08/2024": ["135", "9"],
    "05/09/2024": ["379", "9"],
    "05/10/2024": ["890", "7"],
    "05/11/2024": ["150", "6"],
    "05/12/2024": ["790", "6"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["450", "9"],
    "05/14/2024": ["288", "8"],
    "05/15/2024": ["449", "7"],
    "05/16/2024": ["138", "2"],
    "05/17/2024": ["990", "8"],
    "05/18/2024": ["459", "8"],
    "05/19/2024": ["129", "2"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["168", "5"],
    "05/21/2024": ["350", "8"],
    "05/22/2024": ["468", "8"],
    "05/23/2024": ["234", "9"],
    "05/24/2024": ["289", "9"],
    "05/25/2024": ["479", "0"],
    "05/26/2024": ["116", "8"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["478", "9"],
    "05/28/2024": ["227", "1"],
    "05/29/2024": ["578", "0"],
    "05/30/2024": ["480", "2"],
    "05/31/2024": ["469", "9"],
    "06/01/2024": ["240", "6"],
    "06/02/2024": ["237", "2"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["179", "7"],
    "06/04/2024": ["578", "0"],
    "06/05/2024": ["570", "2"],
    "06/06/2024": ["180", "9"],
    "06/07/2024": ["689", "3"],
    "06/08/2024": ["578", "0"],
    "06/09/2024": ["360", "9"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["460", "0"],
    "06/11/2024": ["110", "2"],
    "06/12/2024": ["478", "9"],
    "06/13/2024": ["190", "0"],
    "06/14/2024": ["139", "3"],
    "06/15/2024": ["348", "5"],
    "06/16/2024": ["157", "3"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["569", "0"],
    "06/18/2024": ["369", "8"],
    "06/19/2024": ["237", "2"],
    "06/20/2024": ["477", "8"],
    "06/21/2024": ["139", "3"],
    "06/22/2024": ["457", "6"],
    "06/23/2024": ["127", "0"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["247", "3"],
    "06/25/2024": ["123", "6"],
    "06/26/2024": ["135", "9"],
    "06/27/2024": ["156", "2"],
    "06/28/2024": ["188", "7"],
    "06/29/2024": ["123", "6"],
    "06/30/2024": ["222", "6"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["170", "8"],
    "07/02/2024": ["367", "6"],
    "07/03/2024": ["239", "4"],
    "07/04/2024": ["234", "9"],
    "07/05/2024": ["579", "1"],
    "07/06/2024": ["234", "9"],
    "07/07/2024": ["123", "6"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["167", "4"],
    "07/09/2024": ["123", "6"],
    "07/10/2024": ["134", "8"],
    "07/11/2024": ["111", "3"],
    "07/12/2024": ["111", "3"],
    "07/13/2024": ["111", "3"],
    "07/14/2024": ["111", "3"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["111", "3"],
    "07/16/2024": ["227", "1"],
    "07/17/2024": ["233", "8"],
    "07/18/2024": ["990", "8"],
    "07/19/2024": ["789", "4"],
    "07/20/2024": ["578", "0"],
    "07/21/2024": ["160", "7"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["199", "9"],
    "07/23/2024": ["399", "1"],
    "07/24/2024": ["399", "1"],
    "07/25/2024": ["444", "2"],
    "07/26/2024": ["123", "6"],
    "07/27/2024": ["678", "1"],
    "07/28/2024": ["990", "8"],
  },
};
export const Eleven = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["125", "8"],
    "01/02/2024": ["569", "0"],
    "01/03/2024": ["235", "0"],
    "01/04/2024": ["127", "0"],
    "01/05/2024": ["148", "3"],
    "01/06/2024": ["777", "1"],
    "01/07/2024": ["458", "7"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["149", "4"],
    "01/09/2024": ["389", "0"],
    "01/10/2024": ["117", "9"],
    "01/11/2024": ["145", "0"],
    "01/12/2024": ["456", "5"],
    "01/13/2024": ["368", "7"],
    "01/14/2024": ["456", "5"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["569", "0"],
    "01/16/2024": ["359", "7"],
    "01/17/2024": ["249", "5"],
    "01/18/2024": ["569", "0"],
    "01/19/2024": ["458", "7"],
    "01/20/2024": ["150", "6"],
    "01/21/2024": ["458", "7"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["349", "6"],
    "01/23/2024": ["568", "9"],
    "01/24/2024": ["570", "2"],
    "01/25/2024": ["123", "6"],
    "01/26/2024": ["128", "1"],
    "01/27/2024": ["345", "2"],
    "01/28/2024": ["247", "3"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["668", "0"],
    "01/30/2024": ["257", "4"],
    "01/31/2024": ["259", "6"],
    "02/01/2024": ["156", "2"],
    "02/02/2024": ["360", "9"],
    "02/03/2024": ["170", "8"],
    "02/04/2024": ["789", "4"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["134", "8"],
    "02/06/2024": ["234", "9"],
    "02/07/2024": ["346", "3"],
    "02/08/2024": ["246", "2"],
    "02/09/2024": ["125", "8"],
    "02/10/2024": ["125", "8"],
    "02/11/2024": ["147", "2"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["130", "4"],
    "02/13/2024": ["100", "1"],
    "02/14/2024": ["258", "5"],
    "02/15/2024": ["567", "8"],
    "02/16/2024": ["990", "8"],
    "02/17/2024": ["156", "2"],
    "02/18/2024": ["200", "2"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["560", "1"],
    "02/20/2024": ["356", "4"],
    "02/21/2024": ["357", "5"],
    "02/22/2024": ["136", "0"],
    "02/23/2024": ["680", "4"],
    "02/24/2024": ["356", "4"],
    "02/25/2024": ["149", "4"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["338", "4"],
    "02/27/2024": ["480", "2"],
    "02/28/2024": ["477", "8"],
    "02/29/2024": ["700", "7"],
    "03/01/2024": ["457", "6"],
    "03/02/2024": ["234", "9"],
    "03/03/2024": ["230", "5"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["567", "8"],
    "03/05/2024": ["468", "8"],
    "03/06/2024": ["110", "2"],
    "03/07/2024": ["130", "4"],
    "03/08/2024": ["689", "3"],
    "03/09/2024": ["290", "1"],
    "03/10/2024": ["447", "5"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["180", "9"],
    "03/12/2024": ["400", "4"],
    "03/13/2024": ["136", "0"],
    "03/14/2024": ["138", "2"],
    "03/15/2024": ["679", "2"],
    "03/16/2024": ["235", "0"],
    "03/17/2024": ["347", "4"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["770", "4"],
    "03/19/2024": ["258", "5"],
    "03/20/2024": ["345", "2"],
    "03/21/2024": ["578", "0"],
    "03/22/2024": ["370", "0"],
    "03/23/2024": ["557", "7"],
    "03/24/2024": ["237", "2"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["117", "9"],
    "03/26/2024": ["349", "6"],
    "03/27/2024": ["178", "6"],
    "03/28/2024": ["480", "2"],
    "03/29/2024": ["457", "6"],
    "03/30/2024": ["169", "6"],
    "03/31/2024": ["248", "4"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["699", "4"],
    "04/02/2024": ["237", "2"],
    "04/03/2024": ["123", "6"],
    "04/04/2024": ["238", "3"],
    "04/05/2024": ["690", "5"],
    "04/06/2024": ["580", "3"],
    "04/07/2024": ["277", "6"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["359", "7"],
    "04/09/2024": ["578", "0"],
    "04/10/2024": ["567", "8"],
    "04/11/2024": ["578", "0"],
    "04/12/2024": ["457", "6"],
    "04/13/2024": ["558", "8"],
    "04/14/2024": ["179", "7"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["357", "5"],
    "04/16/2024": ["233", "8"],
    "04/17/2024": ["478", "9"],
    "04/18/2024": ["260", "8"],
    "04/19/2024": ["457", "6"],
    "04/20/2024": ["456", "5"],
    "04/21/2024": ["237", "2"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["560", "1"],
    "04/23/2024": ["579", "1"],
    "04/24/2024": ["500", "5"],
    "04/25/2024": ["367", "6"],
    "04/26/2024": ["360", "9"],
    "04/27/2024": ["789", "4"],
    "04/28/2024": ["470", "1"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["569", "0"],
    "04/30/2024": ["260", "8"],
    "05/01/2024": ["248", "4"],
    "05/02/2024": ["469", "9"],
    "05/03/2024": ["790", "6"],
    "05/04/2024": ["169", "6"],
    "05/05/2024": ["257", "4"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["229", "3"],
    "05/07/2024": ["890", "7"],
    "05/08/2024": ["170", "8"],
    "05/09/2024": ["390", "2"],
    "05/10/2024": ["770", "4"],
    "05/11/2024": ["480", "2"],
    "05/12/2024": ["590", "4"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["566", "7"],
    "05/14/2024": ["378", "8"],
    "05/15/2024": ["469", "9"],
    "05/16/2024": ["157", "3"],
    "05/17/2024": ["239", "4"],
    "05/18/2024": ["389", "0"],
    "05/19/2024": ["489", "1"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["267", "5"],
    "05/21/2024": ["129", "2"],
    "05/22/2024": ["128", "1"],
    "05/23/2024": ["338", "4"],
    "05/24/2024": ["247", "3"],
    "05/25/2024": ["233", "8"],
    "05/26/2024": ["679", "2"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["578", "0"],
    "05/28/2024": ["115", "7"],
    "05/29/2024": ["189", "8"],
    "05/30/2024": ["246", "2"],
    "05/31/2024": ["148", "3"],
    "06/01/2024": ["234", "9"],
    "06/02/2024": ["166", "3"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["880", "6"],
    "06/04/2024": ["377", "7"],
    "06/05/2024": ["580", "3"],
    "06/06/2024": ["300", "3"],
    "06/07/2024": ["150", "6"],
    "06/08/2024": ["350", "8"],
    "06/09/2024": ["489", "1"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["257", "4"],
    "06/11/2024": ["558", "8"],
    "06/12/2024": ["358", "6"],
    "06/13/2024": ["235", "0"],
    "06/14/2024": ["336", "2"],
    "06/15/2024": ["788", "3"],
    "06/16/2024": ["579", "1"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["688", "2"],
    "06/18/2024": ["690", "5"],
    "06/19/2024": ["368", "7"],
    "06/20/2024": ["120", "3"],
    "06/21/2024": ["159", "5"],
    "06/22/2024": ["280", "0"],
    "06/23/2024": ["137", "1"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["269", "7"],
    "06/25/2024": ["178", "6"],
    "06/26/2024": ["149", "4"],
    "06/27/2024": ["150", "6"],
    "06/28/2024": ["166", "3"],
    "06/29/2024": ["188", "7"],
    "06/30/2024": ["233", "8"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["160", "7"],
    "07/02/2024": ["124", "7"],
    "07/03/2024": ["123", "6"],
    "07/04/2024": ["250", "7"],
    "07/05/2024": ["237", "2"],
    "07/06/2024": ["177", "5"],
    "07/07/2024": ["155", "1"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["168", "5"],
    "07/09/2024": ["567", "8"],
    "07/10/2024": ["124", "7"],
    "07/11/2024": ["112", "4"],
    "07/12/2024": ["122", "5"],
    "07/13/2024": ["113", "5"],
    "07/14/2024": ["133", "7"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["111", "3"],
    "07/16/2024": ["347", "4"],
    "07/17/2024": ["235", "0"],
    "07/18/2024": ["333", "9"],
    "07/19/2024": ["137", "1"],
    "07/20/2024": ["179", "7"],
    "07/21/2024": ["139", "3"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["450", "9"],
    "07/23/2024": ["277", "6"],
    "07/24/2024": ["233", "8"],
    "07/25/2024": ["379", "9"],
    "07/26/2024": ["137", "1"],
    "07/27/2024": ["348", "5"],
    "07/28/2024": ["133", "7"],
  },
};
export const Twelve = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["456", "5"],
    "01/02/2024": ["357", "5"],
    "01/03/2024": ["899", "6"],
    "01/04/2024": ["256", "3"],
    "01/05/2024": ["569", "0"],
    "01/06/2024": ["569", "0"],
    "01/07/2024": ["999", "7"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["255", "2"],
    "01/09/2024": ["140", "5"],
    "01/10/2024": ["555", "5"],
    "01/11/2024": ["558", "8"],
    "01/12/2024": ["127", "0"],
    "01/13/2024": ["470", "1"],
    "01/14/2024": ["789", "4"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["458", "7"],
    "01/16/2024": ["690", "5"],
    "01/17/2024": ["359", "7"],
    "01/18/2024": ["489", "1"],
    "01/19/2024": ["348", "5"],
    "01/20/2024": ["358", "6"],
    "01/21/2024": ["360", "9"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["356", "4"],
    "01/23/2024": ["368", "7"],
    "01/24/2024": ["260", "8"],
    "01/25/2024": ["350", "8"],
    "01/26/2024": ["278", "7"],
    "01/27/2024": ["789", "4"],
    "01/28/2024": ["124", "7"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["789", "4"],
    "01/30/2024": ["339", "5"],
    "01/31/2024": ["148", "3"],
    "02/01/2024": ["123", "6"],
    "02/02/2024": ["140", "5"],
    "02/03/2024": ["390", "2"],
    "02/04/2024": ["200", "2"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["134", "8"],
    "02/06/2024": ["230", "5"],
    "02/07/2024": ["150", "6"],
    "02/08/2024": ["237", "2"],
    "02/09/2024": ["580", "3"],
    "02/10/2024": ["127", "0"],
    "02/11/2024": ["230", "5"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["128", "1"],
    "02/13/2024": ["158", "4"],
    "02/14/2024": ["289", "9"],
    "02/15/2024": ["348", "5"],
    "02/16/2024": ["258", "5"],
    "02/17/2024": ["368", "7"],
    "02/18/2024": ["290", "1"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["256", "3"],
    "02/20/2024": ["245", "1"],
    "02/21/2024": ["390", "2"],
    "02/22/2024": ["125", "8"],
    "02/23/2024": ["270", "9"],
    "02/24/2024": ["900", "9"],
    "02/25/2024": ["110", "2"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["245", "1"],
    "02/27/2024": ["147", "2"],
    "02/28/2024": ["579", "1"],
    "02/29/2024": ["145", "0"],
    "03/01/2024": ["900", "9"],
    "03/02/2024": ["126", "9"],
    "03/03/2024": ["240", "6"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["110", "2"],
    "03/05/2024": ["289", "9"],
    "03/06/2024": ["689", "3"],
    "03/07/2024": ["280", "0"],
    "03/08/2024": ["289", "9"],
    "03/09/2024": ["359", "7"],
    "03/10/2024": ["129", "2"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["360", "9"],
    "03/12/2024": ["249", "5"],
    "03/13/2024": ["890", "7"],
    "03/14/2024": ["235", "0"],
    "03/15/2024": ["127", "0"],
    "03/16/2024": ["290", "1"],
    "03/17/2024": ["900", "9"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["360", "9"],
    "03/19/2024": ["120", "3"],
    "03/20/2024": ["889", "5"],
    "03/21/2024": ["238", "3"],
    "03/22/2024": ["690", "5"],
    "03/23/2024": ["690", "5"],
    "03/24/2024": ["338", "4"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["558", "8"],
    "03/26/2024": ["478", "9"],
    "03/27/2024": ["234", "9"],
    "03/28/2024": ["258", "5"],
    "03/29/2024": ["179", "7"],
    "03/30/2024": ["128", "1"],
    "03/31/2024": ["156", "2"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["279", "8"],
    "04/02/2024": ["126", "9"],
    "04/03/2024": ["489", "1"],
    "04/04/2024": ["178", "6"],
    "04/05/2024": ["346", "3"],
    "04/06/2024": ["278", "7"],
    "04/07/2024": ["138", "2"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["258", "5"],
    "04/09/2024": ["690", "5"],
    "04/10/2024": ["679", "2"],
    "04/11/2024": ["480", "2"],
    "04/12/2024": ["379", "9"],
    "04/13/2024": ["347", "4"],
    "04/14/2024": ["139", "3"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["367", "6"],
    "04/16/2024": ["560", "1"],
    "04/17/2024": ["690", "5"],
    "04/18/2024": ["889", "5"],
    "04/19/2024": ["358", "6"],
    "04/20/2024": ["670", "3"],
    "04/21/2024": ["789", "4"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["230", "5"],
    "04/23/2024": ["668", "0"],
    "04/24/2024": ["357", "5"],
    "04/25/2024": ["336", "2"],
    "04/26/2024": ["247", "3"],
    "04/27/2024": ["279", "8"],
    "04/28/2024": ["250", "7"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["349", "6"],
    "04/30/2024": ["157", "3"],
    "05/01/2024": ["234", "9"],
    "05/02/2024": ["678", "1"],
    "05/03/2024": ["246", "2"],
    "05/04/2024": ["459", "8"],
    "05/05/2024": ["130", "4"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["340", "7"],
    "05/07/2024": ["780", "5"],
    "05/08/2024": ["456", "5"],
    "05/09/2024": ["480", "2"],
    "05/10/2024": ["149", "4"],
    "05/11/2024": ["129", "2"],
    "05/12/2024": ["129", "2"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["459", "8"],
    "05/14/2024": ["150", "6"],
    "05/15/2024": ["220", "4"],
    "05/16/2024": ["223", "7"],
    "05/17/2024": ["347", "4"],
    "05/18/2024": ["460", "0"],
    "05/19/2024": ["334", "0"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["344", "1"],
    "05/21/2024": ["259", "6"],
    "05/22/2024": ["660", "2"],
    "05/23/2024": ["126", "9"],
    "05/24/2024": ["479", "0"],
    "05/25/2024": ["589", "2"],
    "05/26/2024": ["346", "3"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["450", "9"],
    "05/28/2024": ["135", "9"],
    "05/29/2024": ["236", "1"],
    "05/30/2024": ["389", "0"],
    "05/31/2024": ["134", "8"],
    "06/01/2024": ["129", "2"],
    "06/02/2024": ["233", "8"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["780", "5"],
    "06/04/2024": ["560", "1"],
    "06/05/2024": ["469", "9"],
    "06/06/2024": ["590", "4"],
    "06/07/2024": ["127", "0"],
    "06/08/2024": ["138", "2"],
    "06/09/2024": ["128", "1"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["189", "8"],
    "06/11/2024": ["150", "6"],
    "06/12/2024": ["379", "9"],
    "06/13/2024": ["580", "3"],
    "06/14/2024": ["330", "6"],
    "06/15/2024": ["100", "1"],
    "06/16/2024": ["240", "6"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["233", "8"],
    "06/18/2024": ["256", "3"],
    "06/19/2024": ["236", "1"],
    "06/20/2024": ["779", "3"],
    "06/21/2024": ["458", "7"],
    "06/22/2024": ["370", "0"],
    "06/23/2024": ["346", "3"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["179", "7"],
    "06/25/2024": ["150", "6"],
    "06/26/2024": ["178", "6"],
    "06/27/2024": ["127", "0"],
    "06/28/2024": ["157", "3"],
    "06/29/2024": ["156", "2"],
    "06/30/2024": ["125", "8"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["167", "4"],
    "07/02/2024": ["123", "6"],
    "07/03/2024": ["129", "2"],
    "07/04/2024": ["136", "0"],
    "07/05/2024": ["117", "9"],
    "07/06/2024": ["189", "8"],
    "07/07/2024": ["127", "0"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["160", "7"],
    "07/09/2024": ["166", "3"],
    "07/10/2024": ["133", "7"],
    "07/11/2024": ["199", "9"],
    "07/12/2024": ["123", "6"],
    "07/13/2024": ["113", "5"],
    "07/14/2024": ["177", "5"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["127", "0"],
    "07/16/2024": ["170", "8"],
    "07/17/2024": ["290", "1"],
    "07/18/2024": ["279", "8"],
    "07/19/2024": ["247", "3"],
    "07/20/2024": ["289", "9"],
    "07/21/2024": ["222", "6"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["280", "0"],
    "07/23/2024": ["149", "4"],
    "07/24/2024": ["111", "3"],
    "07/25/2024": ["233", "8"],
    "07/26/2024": ["222", "6"],
    "07/27/2024": ["267", "5"],
    "07/28/2024": ["135", "9"],
  },
};

export const One = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["999", "7"],
    "01/02/2024": ["568", "9"],
    "01/03/2024": ["900", "9"],
    "01/04/2024": ["899", "6"],
    "01/05/2024": ["699", "4"],
    "01/06/2024": ["555", "5"],
    "01/07/2024": ["789", "4"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["355", "3"],
    "01/09/2024": ["880", "6"],
    "01/10/2024": ["128", "1"],
    "01/11/2024": ["115", "7"],
    "01/12/2024": ["459", "8"],
    "01/13/2024": ["690", "5"],
    "01/14/2024": ["250", "7"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["156", "2"],
    "01/16/2024": ["569", "0"],
    "01/17/2024": ["689", "3"],
    "01/18/2024": ["359", "7"],
    "01/19/2024": ["189", "8"],
    "01/20/2024": ["157", "3"],
    "01/21/2024": ["190", "0"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["140", "5"],
    "01/23/2024": ["369", "8"],
    "01/24/2024": ["256", "3"],
    "01/25/2024": ["128", "1"],
    "01/26/2024": ["189", "8"],
    "01/27/2024": ["145", "0"],
    "01/28/2024": ["129", "2"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["346", "3"],
    "01/30/2024": ["670", "3"],
    "01/31/2024": ["257", "4"],
    "02/01/2024": ["578", "0"],
    "02/02/2024": ["167", "4"],
    "02/03/2024": ["600", "6"],
    "02/04/2024": ["346", "3"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["457", "6"],
    "02/06/2024": ["468", "8"],
    "02/07/2024": ["347", "4"],
    "02/08/2024": ["150", "6"],
    "02/09/2024": ["356", "4"],
    "02/10/2024": ["670", "3"],
    "02/11/2024": ["129", "2"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["349", "6"],
    "02/13/2024": ["458", "7"],
    "02/14/2024": ["445", "3"],
    "02/15/2024": ["122", "5"],
    "02/16/2024": ["457", "6"],
    "02/17/2024": ["120", "3"],
    "02/18/2024": ["140", "5"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["150", "6"],
    "02/20/2024": ["146", "1"],
    "02/21/2024": ["139", "3"],
    "02/22/2024": ["137", "1"],
    "02/23/2024": ["237", "2"],
    "02/24/2024": ["780", "5"],
    "02/25/2024": ["120", "3"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["145", "0"],
    "02/27/2024": ["550", "0"],
    "02/28/2024": ["200", "2"],
    "02/29/2024": ["337", "3"],
    "03/01/2024": ["135", "9"],
    "03/02/2024": ["124", "7"],
    "03/03/2024": ["347", "4"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["369", "8"],
    "03/05/2024": ["480", "2"],
    "03/06/2024": ["246", "2"],
    "03/07/2024": ["128", "1"],
    "03/08/2024": ["256", "3"],
    "03/09/2024": ["340", "7"],
    "03/10/2024": ["257", "4"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["557", "7"],
    "03/12/2024": ["145", "0"],
    "03/13/2024": ["780", "5"],
    "03/14/2024": ["567", "8"],
    "03/15/2024": ["157", "3"],
    "03/16/2024": ["566", "7"],
    "03/17/2024": ["480", "2"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["569", "0"],
    "03/19/2024": ["257", "4"],
    "03/20/2024": ["490", "3"],
    "03/21/2024": ["237", "2"],
    "03/22/2024": ["558", "8"],
    "03/23/2024": ["349", "6"],
    "03/24/2024": ["180", "9"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["560", "1"],
    "03/26/2024": ["150", "6"],
    "03/27/2024": ["336", "2"],
    "03/28/2024": ["379", "9"],
    "03/29/2024": ["678", "1"],
    "03/30/2024": ["689", "3"],
    "03/31/2024": ["360", "9"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["140", "5"],
    "04/02/2024": ["347", "4"],
    "04/03/2024": ["349", "6"],
    "04/04/2024": ["227", "1"],
    "04/05/2024": ["247", "3"],
    "04/06/2024": ["560", "1"],
    "04/07/2024": ["467", "7"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["356", "4"],
    "04/09/2024": ["589", "2"],
    "04/10/2024": ["235", "0"],
    "04/11/2024": ["258", "5"],
    "04/12/2024": ["990", "8"],
    "04/13/2024": ["568", "9"],
    "04/14/2024": ["257", "4"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["248", "4"],
    "04/16/2024": ["468", "8"],
    "04/17/2024": ["278", "7"],
    "04/18/2024": ["569", "0"],
    "04/19/2024": ["300", "3"],
    "04/20/2024": ["379", "9"],
    "04/21/2024": ["480", "2"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["589", "2"],
    "04/23/2024": ["479", "0"],
    "04/24/2024": ["478", "9"],
    "04/25/2024": ["236", "1"],
    "04/26/2024": ["389", "0"],
    "04/27/2024": ["115", "7"],
    "04/28/2024": ["556", "6"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["568", "9"],
    "04/30/2024": ["389", "0"],
    "05/01/2024": ["378", "8"],
    "05/02/2024": ["138", "2"],
    "05/03/2024": ["139", "3"],
    "05/04/2024": ["189", "8"],
    "05/05/2024": ["478", "9"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["237", "2"],
    "05/07/2024": ["690", "5"],
    "05/08/2024": ["468", "8"],
    "05/09/2024": ["588", "1"],
    "05/10/2024": ["115", "7"],
    "05/11/2024": ["227", "1"],
    "05/12/2024": ["560", "1"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["389", "0"],
    "05/14/2024": ["389", "0"],
    "05/15/2024": ["680", "4"],
    "05/16/2024": ["489", "1"],
    "05/17/2024": ["289", "9"],
    "05/18/2024": ["456", "5"],
    "05/19/2024": ["112", "4"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["245", "1"],
    "05/21/2024": ["124", "7"],
    "05/22/2024": ["257", "4"],
    "05/23/2024": ["477", "8"],
    "05/24/2024": ["780", "5"],
    "05/25/2024": ["157", "3"],
    "05/26/2024": ["560", "1"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["330", "6"],
    "05/28/2024": ["239", "4"],
    "05/29/2024": ["468", "8"],
    "05/30/2024": ["225", "9"],
    "05/31/2024": ["370", "0"],
    "06/01/2024": ["369", "8"],
    "06/02/2024": ["136", "0"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["356", "4"],
    "06/04/2024": ["178", "6"],
    "06/05/2024": ["890", "7"],
    "06/06/2024": ["258", "5"],
    "06/07/2024": ["248", "4"],
    "06/08/2024": ["667", "9"],
    "06/09/2024": ["346", "3"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["390", "2"],
    "06/11/2024": ["378", "8"],
    "06/12/2024": ["490", "3"],
    "06/13/2024": ["256", "3"],
    "06/14/2024": ["246", "2"],
    "06/15/2024": ["349", "6"],
    "06/16/2024": ["389", "0"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["130", "4"],
    "06/18/2024": ["588", "1"],
    "06/19/2024": ["235", "0"],
    "06/20/2024": ["490", "3"],
    "06/21/2024": ["589", "2"],
    "06/22/2024": ["356", "4"],
    "06/23/2024": ["460", "0"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["359", "7"],
    "06/25/2024": ["135", "9"],
    "06/26/2024": ["127", "0"],
    "06/27/2024": ["112", "4"],
    "06/28/2024": ["177", "5"],
    "06/29/2024": ["133", "7"],
    "06/30/2024": ["188", "7"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["100", "1"],
    "07/02/2024": ["269", "7"],
    "07/03/2024": ["167", "4"],
    "07/04/2024": ["150", "6"],
    "07/05/2024": ["369", "8"],
    "07/06/2024": ["255", "2"],
    "07/07/2024": ["177", "5"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["125", "8"],
    "07/09/2024": ["128", "1"],
    "07/10/2024": ["188", "7"],
    "07/11/2024": ["159", "5"],
    "07/12/2024": ["124", "7"],
    "07/13/2024": ["133", "7"],
    "07/14/2024": ["155", "1"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["190", "0"],
    "07/16/2024": ["157", "3"],
    "07/17/2024": ["359", "7"],
    "07/18/2024": ["137", "1"],
    "07/19/2024": ["400", "4"],
    "07/20/2024": ["129", "2"],
    "07/21/2024": ["590", "4"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["277", "6"],
    "07/23/2024": ["333", "9"],
    "07/24/2024": ["267", "5"],
    "07/25/2024": ["111", "3"],
    "07/26/2024": ["230", "5"],
    "07/27/2024": ["339", "5"],
    "07/28/2024": ["258", "5"],
  },
};

export const Two = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["123", "6"],
    "01/02/2024": ["258", "5"],
    "01/03/2024": ["333", "9"],
    "01/04/2024": ["569", "0"],
    "01/05/2024": ["359", "7"],
    "01/06/2024": ["235", "0"],
    "01/07/2024": ["789", "4"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["100", "1"],
    "01/09/2024": ["778", "2"],
    "01/10/2024": ["338", "4"],
    "01/11/2024": ["446", "4"],
    "01/12/2024": ["159", "5"],
    "01/13/2024": ["567", "8"],
    "01/14/2024": ["890", "7"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["890", "7"],
    "01/16/2024": ["256", "3"],
    "01/17/2024": ["128", "1"],
    "01/18/2024": ["890", "7"],
    "01/19/2024": ["125", "8"],
    "01/20/2024": ["489", "1"],
    "01/21/2024": ["169", "6"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["580", "3"],
    "01/23/2024": ["238", "3"],
    "01/24/2024": ["127", "0"],
    "01/25/2024": ["249", "5"],
    "01/26/2024": ["578", "0"],
    "01/27/2024": ["120", "3"],
    "01/28/2024": ["589", "2"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["456", "5"],
    "01/30/2024": ["570", "2"],
    "01/31/2024": ["478", "9"],
    "02/01/2024": ["135", "9"],
    "02/02/2024": ["389", "0"],
    "02/03/2024": ["567", "8"],
    "02/04/2024": ["160", "7"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["789", "4"],
    "02/06/2024": ["356", "4"],
    "02/07/2024": ["489", "1"],
    "02/08/2024": ["356", "4"],
    "02/09/2024": ["245", "1"],
    "02/10/2024": ["379", "9"],
    "02/11/2024": ["390", "2"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["258", "5"],
    "02/13/2024": ["156", "2"],
    "02/14/2024": ["345", "2"],
    "02/15/2024": ["288", "8"],
    "02/16/2024": ["578", "0"],
    "02/17/2024": ["356", "4"],
    "02/18/2024": ["346", "3"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["345", "2"],
    "02/20/2024": ["178", "6"],
    "02/21/2024": ["356", "4"],
    "02/22/2024": ["400", "4"],
    "02/23/2024": ["126", "9"],
    "02/24/2024": ["348", "5"],
    "02/25/2024": ["600", "6"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["456", "5"],
    "02/27/2024": ["257", "4"],
    "02/28/2024": ["245", "1"],
    "02/29/2024": ["160", "7"],
    "03/01/2024": ["123", "6"],
    "03/02/2024": ["457", "6"],
    "03/03/2024": ["139", "3"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["356", "4"],
    "03/05/2024": ["190", "0"],
    "03/06/2024": ["168", "5"],
    "03/07/2024": ["459", "8"],
    "03/08/2024": ["290", "1"],
    "03/09/2024": ["129", "2"],
    "03/10/2024": ["290", "1"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["290", "1"],
    "03/12/2024": ["390", "2"],
    "03/13/2024": ["459", "8"],
    "03/14/2024": ["124", "7"],
    "03/15/2024": ["133", "7"],
    "03/16/2024": ["356", "4"],
    "03/17/2024": ["890", "7"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["678", "1"],
    "03/19/2024": ["155", "1"],
    "03/20/2024": ["130", "4"],
    "03/21/2024": ["340", "7"],
    "03/22/2024": ["468", "8"],
    "03/23/2024": ["148", "3"],
    "03/24/2024": ["579", "1"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["380", "1"],
    "03/26/2024": ["299", "0"],
    "03/27/2024": ["345", "2"],
    "03/28/2024": ["478", "9"],
    "03/29/2024": ["279", "8"],
    "03/30/2024": ["236", "1"],
    "03/31/2024": ["277", "6"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["369", "8"],
    "04/02/2024": ["469", "9"],
    "04/03/2024": ["347", "4"],
    "04/04/2024": ["357", "5"],
    "04/05/2024": ["278", "7"],
    "04/06/2024": ["146", "1"],
    "04/07/2024": ["450", "9"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["124", "7"],
    "04/09/2024": ["469", "9"],
    "04/10/2024": ["455", "4"],
    "04/11/2024": ["159", "5"],
    "04/12/2024": ["138", "2"],
    "04/13/2024": ["469", "9"],
    "04/14/2024": ["334", "0"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["120", "3"],
    "04/16/2024": ["346", "3"],
    "04/17/2024": ["235", "0"],
    "04/18/2024": ["146", "1"],
    "04/19/2024": ["136", "0"],
    "04/20/2024": ["388", "9"],
    "04/21/2024": ["158", "4"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["449", "7"],
    "04/23/2024": ["347", "4"],
    "04/24/2024": ["388", "9"],
    "04/25/2024": ["589", "2"],
    "04/26/2024": ["168", "5"],
    "04/27/2024": ["224", "8"],
    "04/28/2024": ["240", "6"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["278", "7"],
    "04/30/2024": ["168", "5"],
    "05/01/2024": ["347", "4"],
    "05/02/2024": ["336", "2"],
    "05/03/2024": ["577", "9"],
    "05/04/2024": ["237", "2"],
    "05/05/2024": ["228", "2"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["679", "2"],
    "05/07/2024": ["258", "5"],
    "05/08/2024": ["240", "6"],
    "05/09/2024": ["268", "6"],
    "05/10/2024": ["356", "4"],
    "05/11/2024": ["290", "1"],
    "05/12/2024": ["136", "0"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["350", "8"],
    "05/14/2024": ["119", "1"],
    "05/15/2024": ["268", "6"],
    "05/16/2024": ["339", "5"],
    "05/17/2024": ["135", "9"],
    "05/18/2024": ["345", "2"],
    "05/19/2024": ["280", "0"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["590", "4"],
    "05/21/2024": ["358", "6"],
    "05/22/2024": ["249", "5"],
    "05/23/2024": ["245", "1"],
    "05/24/2024": ["789", "4"],
    "05/25/2024": ["690", "5"],
    "05/26/2024": ["334", "0"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["490", "3"],
    "05/28/2024": ["480", "2"],
    "05/29/2024": ["234", "9"],
    "05/30/2024": ["150", "6"],
    "05/31/2024": ["269", "7"],
    "06/01/2024": ["770", "4"],
    "06/02/2024": ["460", "0"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["345", "2"],
    "06/04/2024": ["155", "1"],
    "06/05/2024": ["144", "9"],
    "06/06/2024": ["446", "4"],
    "06/07/2024": ["235", "0"],
    "06/08/2024": ["225", "9"],
    "06/09/2024": ["239", "4"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["348", "5"],
    "06/11/2024": ["335", "1"],
    "06/12/2024": ["260", "8"],
    "06/13/2024": ["399", "1"],
    "06/14/2024": ["237", "2"],
    "06/15/2024": ["230", "5"],
    "06/16/2024": ["149", "4"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["557", "7"],
    "06/18/2024": ["448", "6"],
    "06/19/2024": ["169", "6"],
    "06/20/2024": ["144", "9"],
    "06/21/2024": ["126", "9"],
    "06/22/2024": ["449", "7"],
    "06/23/2024": ["340", "7"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["177", "5"],
    "06/25/2024": ["126", "9"],
    "06/26/2024": ["124", "7"],
    "06/27/2024": ["115", "7"],
    "06/28/2024": ["136", "0"],
    "06/29/2024": ["180", "9"],
    "06/30/2024": ["277", "6"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["200", "2"],
    "07/02/2024": ["147", "2"],
    "07/03/2024": ["227", "1"],
    "07/04/2024": ["233", "8"],
    "07/05/2024": ["133", "7"],
    "07/06/2024": ["188", "7"],
    "07/07/2024": ["149", "4"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["112", "4"],
    "07/09/2024": ["188", "7"],
    "07/10/2024": ["199", "9"],
    "07/11/2024": ["119", "1"],
    "07/12/2024": ["188", "7"],
    "07/13/2024": ["119", "1"],
    "07/14/2024": ["244", "6"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["177", "5"],
    "07/16/2024": ["360", "9"],
    "07/17/2024": ["159", "5"],
    "07/18/2024": ["123", "6"],
    "07/19/2024": ["125", "8"],
    "07/20/2024": ["200", "2"],
    "07/21/2024": ["377", "7"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["399", "1"],
    "07/23/2024": ["299", "0"],
    "07/24/2024": ["149", "4"],
    "07/25/2024": ["230", "5"],
    "07/26/2024": ["333", "9"],
    "07/27/2024": ["147", "2"],
    "07/28/2024": ["880", "6"],
  },
};

export const Three = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["125", "8"],
    "01/02/2024": ["999", "7"],
    "01/03/2024": ["222", "6"],
    "01/04/2024": ["222", "6"],
    "01/05/2024": ["223", "7"],
    "01/06/2024": ["777", "1"],
    "01/07/2024": ["230", "5"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["800", "8"],
    "01/09/2024": ["668", "0"],
    "01/10/2024": ["258", "5"],
    "01/11/2024": ["236", "1"],
    "01/12/2024": ["359", "7"],
    "01/13/2024": ["569", "0"],
    "01/14/2024": ["255", "2"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["590", "4"],
    "01/16/2024": ["123", "6"],
    "01/17/2024": ["230", "5"],
    "01/18/2024": ["159", "5"],
    "01/19/2024": ["348", "5"],
    "01/20/2024": ["280", "0"],
    "01/21/2024": ["890", "7"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["489", "1"],
    "01/23/2024": ["246", "2"],
    "01/24/2024": ["148", "3"],
    "01/25/2024": ["890", "7"],
    "01/26/2024": ["367", "6"],
    "01/27/2024": ["446", "4"],
    "01/28/2024": ["450", "9"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["569", "0"],
    "01/30/2024": ["149", "4"],
    "01/31/2024": ["278", "7"],
    "02/01/2024": ["246", "2"],
    "02/02/2024": ["289", "9"],
    "02/03/2024": ["356", "4"],
    "02/04/2024": ["139", "3"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["467", "7"],
    "02/06/2024": ["355", "3"],
    "02/07/2024": ["235", "0"],
    "02/08/2024": ["890", "7"],
    "02/09/2024": ["110", "2"],
    "02/10/2024": ["467", "7"],
    "02/11/2024": ["136", "0"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["134", "8"],
    "02/13/2024": ["145", "0"],
    "02/14/2024": ["458", "7"],
    "02/15/2024": ["234", "9"],
    "02/16/2024": ["346", "3"],
    "02/17/2024": ["290", "1"],
    "02/18/2024": ["330", "6"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["145", "0"],
    "02/20/2024": ["577", "9"],
    "02/21/2024": ["467", "7"],
    "02/22/2024": ["457", "6"],
    "02/23/2024": ["356", "4"],
    "02/24/2024": ["123", "6"],
    "02/25/2024": ["157", "3"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["125", "8"],
    "02/27/2024": ["259", "6"],
    "02/28/2024": ["457", "6"],
    "02/29/2024": ["134", "8"],
    "03/01/2024": ["370", "0"],
    "03/02/2024": ["129", "2"],
    "03/03/2024": ["125", "8"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["150", "6"],
    "03/05/2024": ["120", "3"],
    "03/06/2024": ["145", "0"],
    "03/07/2024": ["137", "1"],
    "03/08/2024": ["180", "9"],
    "03/09/2024": ["126", "9"],
    "03/10/2024": ["180", "9"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["160", "7"],
    "03/12/2024": ["199", "9"],
    "03/13/2024": ["128", "1"],
    "03/14/2024": ["568", "9"],
    "03/15/2024": ["680", "4"],
    "03/16/2024": ["135", "9"],
    "03/17/2024": ["389", "0"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["489", "1"],
    "03/19/2024": ["389", "0"],
    "03/20/2024": ["246", "2"],
    "03/21/2024": ["348", "5"],
    "03/22/2024": ["799", "5"],
    "03/23/2024": ["368", "7"],
    "03/24/2024": ["235", "0"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["457", "6"],
    "03/26/2024": ["799", "5"],
    "03/27/2024": ["369", "8"],
    "03/28/2024": ["234", "9"],
    "03/29/2024": ["790", "6"],
    "03/30/2024": ["237", "2"],
    "03/31/2024": ["357", "5"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["119", "1"],
    "04/02/2024": ["680", "4"],
    "04/03/2024": ["129", "2"],
    "04/04/2024": ["149", "4"],
    "04/05/2024": ["367", "6"],
    "04/06/2024": ["890", "7"],
    "04/07/2024": ["379", "9"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["780", "5"],
    "04/09/2024": ["458", "7"],
    "04/10/2024": ["234", "9"],
    "04/11/2024": ["257", "4"],
    "04/12/2024": ["578", "0"],
    "04/13/2024": ["277", "6"],
    "04/14/2024": ["479", "0"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["115", "7"],
    "04/16/2024": ["234", "9"],
    "04/17/2024": ["490", "3"],
    "04/18/2024": ["557", "7"],
    "04/19/2024": ["159", "5"],
    "04/20/2024": ["245", "1"],
    "04/21/2024": ["245", "1"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["129", "2"],
    "04/23/2024": ["780", "5"],
    "04/24/2024": ["140", "5"],
    "04/25/2024": ["457", "6"],
    "04/26/2024": ["360", "9"],
    "04/27/2024": ["359", "7"],
    "04/28/2024": ["480", "2"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["346", "3"],
    "04/30/2024": ["780", "5"],
    "05/01/2024": ["116", "8"],
    "05/02/2024": ["146", "1"],
    "05/03/2024": ["257", "4"],
    "05/04/2024": ["470", "1"],
    "05/05/2024": ["889", "5"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["245", "1"],
    "05/07/2024": ["370", "0"],
    "05/08/2024": ["359", "7"],
    "05/09/2024": ["490", "3"],
    "05/10/2024": ["113", "5"],
    "05/11/2024": ["120", "3"],
    "05/12/2024": ["125", "8"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["114", "6"],
    "05/14/2024": ["370", "0"],
    "05/15/2024": ["139", "3"],
    "05/16/2024": ["134", "8"],
    "05/17/2024": ["178", "6"],
    "05/18/2024": ["458", "7"],
    "05/19/2024": ["688", "2"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["488", "0"],
    "05/21/2024": ["113", "5"],
    "05/22/2024": ["568", "9"],
    "05/23/2024": ["578", "0"],
    "05/24/2024": ["340", "7"],
    "05/25/2024": ["246", "2"],
    "05/26/2024": ["369", "8"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["358", "6"],
    "05/28/2024": ["270", "9"],
    "05/29/2024": ["122", "5"],
    "05/30/2024": ["469", "9"],
    "05/31/2024": ["589", "2"],
    "06/01/2024": ["390", "2"],
    "06/02/2024": ["367", "6"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["120", "3"],
    "06/04/2024": ["137", "1"],
    "06/05/2024": ["678", "1"],
    "06/06/2024": ["779", "3"],
    "06/07/2024": ["568", "9"],
    "06/08/2024": ["288", "8"],
    "06/09/2024": ["280", "0"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["679", "2"],
    "06/11/2024": ["770", "4"],
    "06/12/2024": ["589", "2"],
    "06/13/2024": ["289", "9"],
    "06/14/2024": ["889", "5"],
    "06/15/2024": ["190", "0"],
    "06/16/2024": ["250", "7"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["390", "2"],
    "06/18/2024": ["239", "4"],
    "06/19/2024": ["889", "5"],
    "06/20/2024": ["569", "0"],
    "06/21/2024": ["450", "9"],
    "06/22/2024": ["249", "5"],
    "06/23/2024": ["279", "8"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["139", "3"],
    "06/25/2024": ["123", "6"],
    "06/26/2024": ["126", "9"],
    "06/27/2024": ["233", "8"],
    "06/28/2024": ["137", "1"],
    "06/29/2024": ["124", "7"],
    "06/30/2024": ["244", "6"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["300", "3"],
    "07/02/2024": ["127", "0"],
    "07/03/2024": ["239", "4"],
    "07/04/2024": ["567", "8"],
    "07/05/2024": ["222", "6"],
    "07/06/2024": ["144", "9"],
    "07/07/2024": ["130", "4"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["111", "3"],
    "07/09/2024": ["***", "*"],
    "07/10/2024": ["147", "2"],
    "07/11/2024": ["122", "5"],
    "07/12/2024": ["155", "1"],
    "07/13/2024": ["130", "4"],
    "07/14/2024": ["299", "0"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["230", "5"],
    "07/16/2024": ["339", "5"],
    "07/17/2024": ["123", "6"],
    "07/18/2024": ["139", "3"],
    "07/19/2024": ["250", "7"],
    "07/20/2024": ["359", "7"],
    "07/21/2024": ["279", "0"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["222", "6"],
    "07/23/2024": ["189", "8"],
    "07/24/2024": ["370", "0"],
    "07/25/2024": ["260", "8"],
    "07/26/2024": ["345", "2"],
    "07/27/2024": ["450", "9"],
    "07/28/2024": ["339", "5"],
  },
};

export const Four = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["789", "4"],
    "01/02/2024": ["457", "6"],
    "01/03/2024": ["445", "3"],
    "01/04/2024": ["666", "8"],
    "01/05/2024": ["288", "8"],
    "01/06/2024": ["489", "1"],
    "01/07/2024": ["458", "7"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["125", "8"],
    "01/09/2024": ["699", "4"],
    "01/10/2024": ["236", "1"],
    "01/11/2024": ["558", "8"],
    "01/12/2024": ["123", "6"],
    "01/13/2024": ["238", "3"],
    "01/14/2024": ["129", "2"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["380", "1"],
    "01/16/2024": ["789", "4"],
    "01/17/2024": ["123", "6"],
    "01/18/2024": ["268", "6"],
    "01/19/2024": ["149", "4"],
    "01/20/2024": ["478", "9"],
    "01/21/2024": ["147", "2"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["125", "8"],
    "01/23/2024": ["349", "6"],
    "01/24/2024": ["450", "9"],
    "01/25/2024": ["456", "5"],
    "01/26/2024": ["138", "2"],
    "01/27/2024": ["457", "6"],
    "01/28/2024": ["679", "2"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["134", "8"],
    "01/30/2024": ["123", "6"],
    "01/31/2024": ["189", "8"],
    "02/01/2024": ["456", "5"],
    "02/02/2024": ["270", "9"],
    "02/03/2024": ["234", "9"],
    "02/04/2024": ["347", "4"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["245", "1"],
    "02/06/2024": ["578", "0"],
    "02/07/2024": ["148", "3"],
    "02/08/2024": ["569", "0"],
    "02/09/2024": ["190", "0"],
    "02/10/2024": ["569", "0"],
    "02/11/2024": ["569", "0"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["345", "2"],
    "02/13/2024": ["123", "6"],
    "02/14/2024": ["145", "0"],
    "02/15/2024": ["245", "1"],
    "02/16/2024": ["356", "4"],
    "02/17/2024": ["550", "0"],
    "02/18/2024": ["459", "8"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["122", "5"],
    "02/20/2024": ["100", "1"],
    "02/21/2024": ["145", "0"],
    "02/22/2024": ["489", "1"],
    "02/23/2024": ["245", "1"],
    "02/24/2024": ["257", "4"],
    "02/25/2024": ["256", "3"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["470", "1"],
    "02/27/2024": ["379", "9"],
    "02/28/2024": ["356", "4"],
    "02/29/2024": ["489", "1"],
    "03/01/2024": ["359", "7"],
    "03/02/2024": ["140", "5"],
    "03/03/2024": ["370", "0"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["340", "7"],
    "03/05/2024": ["160", "7"],
    "03/06/2024": ["170", "8"],
    "03/07/2024": ["220", "4"],
    "03/08/2024": ["257", "4"],
    "03/09/2024": ["258", "5"],
    "03/10/2024": ["258", "5"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["247", "3"],
    "03/12/2024": ["247", "3"],
    "03/13/2024": ["246", "2"],
    "03/14/2024": ["660", "2"],
    "03/15/2024": ["280", "0"],
    "03/16/2024": ["133", "7"],
    "03/17/2024": ["677", "0"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["239", "4"],
    "03/19/2024": ["350", "8"],
    "03/20/2024": ["450", "9"],
    "03/21/2024": ["590", "4"],
    "03/22/2024": ["259", "6"],
    "03/23/2024": ["345", "2"],
    "03/24/2024": ["360", "9"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["389", "0"],
    "03/26/2024": ["160", "7"],
    "03/27/2024": ["220", "4"],
    "03/28/2024": ["470", "1"],
    "03/29/2024": ["590", "4"],
    "03/30/2024": ["466", "6"],
    "03/31/2024": ["348", "5"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["778", "2"],
    "04/02/2024": ["589", "2"],
    "04/03/2024": ["689", "3"],
    "04/04/2024": ["145", "0"],
    "04/05/2024": ["480", "2"],
    "04/06/2024": ["267", "5"],
    "04/07/2024": ["358", "6"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["589", "2"],
    "04/09/2024": ["280", "0"],
    "04/10/2024": ["890", "7"],
    "04/11/2024": ["359", "7"],
    "04/12/2024": ["167", "4"],
    "04/13/2024": ["118", "0"],
    "04/14/2024": ["256", "3"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["390", "2"],
    "04/16/2024": ["159", "5"],
    "04/17/2024": ["145", "0"],
    "04/18/2024": ["130", "4"],
    "04/19/2024": ["237", "2"],
    "04/20/2024": ["350", "8"],
    "04/21/2024": ["330", "6"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["590", "4"],
    "04/23/2024": ["124", "7"],
    "04/24/2024": ["699", "4"],
    "04/25/2024": ["270", "9"],
    "04/26/2024": ["779", "3"],
    "04/27/2024": ["570", "2"],
    "04/28/2024": ["229", "3"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["170", "8"],
    "04/30/2024": ["129", "2"],
    "05/01/2024": ["140", "5"],
    "05/02/2024": ["129", "2"],
    "05/03/2024": ["556", "6"],
    "05/04/2024": ["137", "1"],
    "05/05/2024": ["136", "0"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["567", "8"],
    "05/07/2024": ["489", "1"],
    "05/08/2024": ["460", "0"],
    "05/09/2024": ["258", "5"],
    "05/10/2024": ["367", "6"],
    "05/11/2024": ["346", "3"],
    "05/12/2024": ["130", "4"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["780", "5"],
    "05/14/2024": ["145", "0"],
    "05/15/2024": ["128", "1"],
    "05/16/2024": ["169", "6"],
    "05/17/2024": ["160", "7"],
    "05/18/2024": ["110", "2"],
    "05/19/2024": ["235", "0"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["338", "4"],
    "05/21/2024": ["268", "6"],
    "05/22/2024": ["389", "0"],
    "05/23/2024": ["147", "2"],
    "05/24/2024": ["368", "7"],
    "05/25/2024": ["160", "7"],
    "05/26/2024": ["178", "6"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["226", "0"],
    "05/28/2024": ["240", "6"],
    "05/29/2024": ["166", "3"],
    "05/30/2024": ["125", "8"],
    "05/31/2024": ["248", "4"],
    "06/01/2024": ["117", "9"],
    "06/02/2024": ["470", "1"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["279", "8"],
    "06/04/2024": ["159", "5"],
    "06/05/2024": ["134", "8"],
    "06/06/2024": ["245", "1"],
    "06/07/2024": ["366", "5"],
    "06/08/2024": ["344", "1"],
    "06/09/2024": ["125", "8"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["188", "7"],
    "06/11/2024": ["167", "4"],
    "06/12/2024": ["588", "1"],
    "06/13/2024": ["160", "7"],
    "06/14/2024": ["799", "5"],
    "06/15/2024": ["127", "0"],
    "06/16/2024": ["499", "2"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["169", "6"],
    "06/18/2024": ["458", "7"],
    "06/19/2024": ["338", "4"],
    "06/20/2024": ["136", "0"],
    "06/21/2024": ["455", "4"],
    "06/22/2024": ["346", "3"],
    "06/23/2024": ["270", "9"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["229", "3"],
    "06/25/2024": ["133", "7"],
    "06/26/2024": ["169", "6"],
    "06/27/2024": ["145", "0"],
    "06/28/2024": ["239", "4"],
    "06/29/2024": ["155", "1"],
    "06/30/2024": ["177", "5"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["250", "7"],
    "07/02/2024": ["119", "1"],
    "07/03/2024": ["117", "9"],
    "07/04/2024": ["170", "8"],
    "07/05/2024": ["246", "2"],
    "07/06/2024": ["168", "5"],
    "07/07/2024": ["346", "3"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["100", "1"],
    "07/09/2024": ["144", "9"],
    "07/10/2024": ["199", "9"],
    "07/11/2024": ["180", "9"],
    "07/12/2024": ["159", "5"],
    "07/13/2024": ["250", "7"],
    "07/14/2024": ["111", "3"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["459", "8"],
    "07/16/2024": ["300", "3"],
    "07/17/2024": ["390", "2"],
    "07/18/2024": ["123", "6"],
    "07/19/2024": ["333", "9"],
    "07/20/2024": ["169", "6"],
    "07/21/2024": ["123", "6"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["190", "0"],
    "07/23/2024": ["222", "6"],
    "07/24/2024": ["245", "1"],
    "07/25/2024": ["500", "5"],
    "07/26/2024": ["500", "5"],
    "07/27/2024": ["447", "5"],
    "07/28/2024": ["170", "8"],
  },
};

export const Five = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["999", "7"],
    "01/02/2024": ["568", "9"],
    "01/03/2024": ["900", "9"],
    "01/04/2024": ["899", "6"],
    "01/05/2024": ["699", "4"],
    "01/06/2024": ["555", "5"],
    "01/07/2024": ["789", "4"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["355", "3"],
    "01/09/2024": ["880", "6"],
    "01/10/2024": ["128", "1"],
    "01/11/2024": ["115", "7"],
    "01/12/2024": ["459", "8"],
    "01/13/2024": ["690", "5"],
    "01/14/2024": ["250", "7"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["156", "2"],
    "01/16/2024": ["569", "0"],
    "01/17/2024": ["689", "3"],
    "01/18/2024": ["359", "7"],
    "01/19/2024": ["189", "8"],
    "01/20/2024": ["157", "3"],
    "01/21/2024": ["190", "0"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["140", "5"],
    "01/23/2024": ["369", "8"],
    "01/24/2024": ["256", "3"],
    "01/25/2024": ["128", "1"],
    "01/26/2024": ["189", "8"],
    "01/27/2024": ["145", "0"],
    "01/28/2024": ["129", "2"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["346", "3"],
    "01/30/2024": ["670", "3"],
    "01/31/2024": ["257", "4"],
    "02/01/2024": ["578", "0"],
    "02/02/2024": ["167", "4"],
    "02/03/2024": ["600", "6"],
    "02/04/2024": ["346", "3"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["457", "6"],
    "02/06/2024": ["468", "8"],
    "02/07/2024": ["347", "4"],
    "02/08/2024": ["150", "6"],
    "02/09/2024": ["356", "4"],
    "02/10/2024": ["670", "3"],
    "02/11/2024": ["129", "2"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["349", "6"],
    "02/13/2024": ["458", "7"],
    "02/14/2024": ["445", "3"],
    "02/15/2024": ["122", "5"],
    "02/16/2024": ["457", "6"],
    "02/17/2024": ["120", "3"],
    "02/18/2024": ["140", "5"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["150", "6"],
    "02/20/2024": ["146", "1"],
    "02/21/2024": ["139", "3"],
    "02/22/2024": ["137", "1"],
    "02/23/2024": ["237", "2"],
    "02/24/2024": ["780", "5"],
    "02/25/2024": ["120", "3"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["145", "0"],
    "02/27/2024": ["550", "0"],
    "02/28/2024": ["200", "2"],
    "02/29/2024": ["337", "3"],
    "03/01/2024": ["135", "9"],
    "03/02/2024": ["124", "7"],
    "03/03/2024": ["347", "4"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["369", "8"],
    "03/05/2024": ["480", "2"],
    "03/06/2024": ["246", "2"],
    "03/07/2024": ["128", "1"],
    "03/08/2024": ["256", "3"],
    "03/09/2024": ["340", "7"],
    "03/10/2024": ["257", "4"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["557", "7"],
    "03/12/2024": ["145", "0"],
    "03/13/2024": ["780", "5"],
    "03/14/2024": ["567", "8"],
    "03/15/2024": ["157", "3"],
    "03/16/2024": ["566", "7"],
    "03/17/2024": ["480", "2"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["569", "0"],
    "03/19/2024": ["257", "4"],
    "03/20/2024": ["490", "3"],
    "03/21/2024": ["237", "2"],
    "03/22/2024": ["558", "8"],
    "03/23/2024": ["349", "6"],
    "03/24/2024": ["180", "9"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["560", "1"],
    "03/26/2024": ["150", "6"],
    "03/27/2024": ["336", "2"],
    "03/28/2024": ["379", "9"],
    "03/29/2024": ["678", "1"],
    "03/30/2024": ["689", "3"],
    "03/31/2024": ["360", "9"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["140", "5"],
    "04/02/2024": ["347", "4"],
    "04/03/2024": ["349", "6"],
    "04/04/2024": ["227", "1"],
    "04/05/2024": ["247", "3"],
    "04/06/2024": ["560", "1"],
    "04/07/2024": ["467", "7"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["356", "4"],
    "04/09/2024": ["589", "2"],
    "04/10/2024": ["235", "0"],
    "04/11/2024": ["258", "5"],
    "04/12/2024": ["990", "8"],
    "04/13/2024": ["568", "9"],
    "04/14/2024": ["257", "4"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["248", "4"],
    "04/16/2024": ["468", "8"],
    "04/17/2024": ["278", "7"],
    "04/18/2024": ["569", "0"],
    "04/19/2024": ["300", "3"],
    "04/20/2024": ["379", "9"],
    "04/21/2024": ["480", "2"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["589", "2"],
    "04/23/2024": ["479", "0"],
    "04/24/2024": ["478", "9"],
    "04/25/2024": ["236", "1"],
    "04/26/2024": ["389", "0"],
    "04/27/2024": ["115", "7"],
    "04/28/2024": ["556", "6"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["568", "9"],
    "04/30/2024": ["389", "0"],
    "05/01/2024": ["378", "8"],
    "05/02/2024": ["138", "2"],
    "05/03/2024": ["139", "3"],
    "05/04/2024": ["189", "8"],
    "05/05/2024": ["478", "9"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["237", "2"],
    "05/07/2024": ["690", "5"],
    "05/08/2024": ["468", "8"],
    "05/09/2024": ["588", "1"],
    "05/10/2024": ["115", "7"],
    "05/11/2024": ["227", "1"],
    "05/12/2024": ["560", "1"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["389", "0"],
    "05/14/2024": ["389", "0"],
    "05/15/2024": ["680", "4"],
    "05/16/2024": ["489", "1"],
    "05/17/2024": ["289", "9"],
    "05/18/2024": ["456", "5"],
    "05/19/2024": ["112", "4"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["245", "1"],
    "05/21/2024": ["124", "7"],
    "05/22/2024": ["257", "4"],
    "05/23/2024": ["477", "8"],
    "05/24/2024": ["780", "5"],
    "05/25/2024": ["157", "3"],
    "05/26/2024": ["560", "1"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["330", "6"],
    "05/28/2024": ["239", "4"],
    "05/29/2024": ["468", "8"],
    "05/30/2024": ["225", "9"],
    "05/31/2024": ["370", "0"],
    "06/01/2024": ["369", "8"],
    "06/02/2024": ["136", "0"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["356", "4"],
    "06/04/2024": ["178", "6"],
    "06/05/2024": ["890", "7"],
    "06/06/2024": ["258", "5"],
    "06/07/2024": ["248", "4"],
    "06/08/2024": ["667", "9"],
    "06/09/2024": ["346", "3"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["390", "2"],
    "06/11/2024": ["378", "8"],
    "06/12/2024": ["490", "3"],
    "06/13/2024": ["256", "3"],
    "06/14/2024": ["246", "2"],
    "06/15/2024": ["349", "6"],
    "06/16/2024": ["389", "0"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["130", "4"],
    "06/18/2024": ["588", "1"],
    "06/19/2024": ["235", "0"],
    "06/20/2024": ["490", "3"],
    "06/21/2024": ["589", "2"],
    "06/22/2024": ["356", "4"],
    "06/23/2024": ["460", "0"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["125", "8"],
    "06/25/2024": ["157", "3"],
    "06/26/2024": ["134", "8"],
    "06/27/2024": ["166", "3"],
    "06/28/2024": ["158", "4"],
    "06/29/2024": ["178", "6"],
    "06/30/2024": ["113", "5"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["299", "0"],
    "07/02/2024": ["333", "9"],
    "07/03/2024": ["179", "7"],
    "07/04/2024": ["155", "1"],
    "07/05/2024": ["277", "6"],
    "07/06/2024": ["145", "0"],
    "07/07/2024": ["244", "6"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["200", "2"],
    "07/09/2024": ["111", "3"],
    "07/10/2024": ["499", "2"],
    "07/11/2024": ["170", "8"],
    "07/12/2024": ["333", "9"],
    "07/13/2024": ["277", "6"],
    "07/14/2024": ["239", "4"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["460", "0"],
    "07/16/2024": ["245", "1"],
    "07/17/2024": ["199", "9"],
    "07/18/2024": ["300", "3"],
    "07/19/2024": ["333", "9"],
    "07/20/2024": ["127", "0"],
    "07/21/2024": ["499", "2"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["111", "3"],
    "07/23/2024": ["123", "6"],
    "07/24/2024": ["200", "2"],
    "07/25/2024": ["222", "6"],
    "07/26/2024": ["347", "4"],
    "07/27/2024": ["159", "5"],
    "07/28/2024": ["680", "4"],
  },
};

export const Six = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["444", "2"],
    "01/02/2024": ["789", "4"],
    "01/03/2024": ["225", "9"],
    "01/04/2024": ["600", "6"],
    "01/05/2024": ["369", "8"],
    "01/06/2024": ["259", "6"],
    "01/07/2024": ["890", "7"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["699", "4"],
    "01/09/2024": ["558", "8"],
    "01/10/2024": ["226", "0"],
    "01/11/2024": ["115", "7"],
    "01/12/2024": ["258", "5"],
    "01/13/2024": ["115", "7"],
    "01/14/2024": ["357", "5"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["159", "5"],
    "01/16/2024": ["590", "4"],
    "01/17/2024": ["456", "5"],
    "01/18/2024": ["230", "5"],
    "01/19/2024": ["258", "5"],
    "01/20/2024": ["369", "8"],
    "01/21/2024": ["236", "1"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["249", "5"],
    "01/23/2024": ["679", "2"],
    "01/24/2024": ["249", "5"],
    "01/25/2024": ["379", "9"],
    "01/26/2024": ["356", "4"],
    "01/27/2024": ["146", "1"],
    "01/28/2024": ["367", "6"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["360", "9"],
    "01/30/2024": ["458", "7"],
    "01/31/2024": ["244", "0"],
    "02/01/2024": ["570", "2"],
    "02/02/2024": ["235", "0"],
    "02/03/2024": ["578", "0"],
    "02/04/2024": ["456", "5"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["344", "1"],
    "02/06/2024": ["568", "9"],
    "02/07/2024": ["100", "1"],
    "02/08/2024": ["690", "5"],
    "02/09/2024": ["289", "9"],
    "02/10/2024": ["346", "3"],
    "02/11/2024": ["256", "3"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["358", "6"],
    "02/13/2024": ["120", "3"],
    "02/14/2024": ["235", "0"],
    "02/15/2024": ["469", "9"],
    "02/16/2024": ["128", "1"],
    "02/17/2024": ["567", "8"],
    "02/18/2024": ["678", "1"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["890", "7"],
    "02/20/2024": ["145", "0"],
    "02/21/2024": ["478", "9"],
    "02/22/2024": ["580", "3"],
    "02/23/2024": ["256", "3"],
    "02/24/2024": ["140", "5"],
    "02/25/2024": ["140", "5"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["890", "7"],
    "02/27/2024": ["137", "1"],
    "02/28/2024": ["138", "2"],
    "02/29/2024": ["550", "0"],
    "03/01/2024": ["300", "3"],
    "03/02/2024": ["100", "1"],
    "03/03/2024": ["559", "9"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["100", "1"],
    "03/05/2024": ["130", "4"],
    "03/06/2024": ["789", "4"],
    "03/07/2024": ["189", "8"],
    "03/08/2024": ["200", "2"],
    "03/09/2024": ["235", "0"],
    "03/10/2024": ["120", "3"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["123", "6"],
    "03/12/2024": ["440", "8"],
    "03/13/2024": ["125", "8"],
    "03/14/2024": ["249", "5"],
    "03/15/2024": ["117", "9"],
    "03/16/2024": ["778", "2"],
    "03/17/2024": ["367", "6"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["467", "7"],
    "03/19/2024": ["689", "3"],
    "03/20/2024": ["690", "5"],
    "03/21/2024": ["267", "5"],
    "03/22/2024": ["127", "0"],
    "03/23/2024": ["135", "9"],
    "03/24/2024": ["490", "3"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["568", "9"],
    "03/26/2024": ["578", "0"],
    "03/27/2024": ["789", "4"],
    "03/28/2024": ["148", "3"],
    "03/29/2024": ["358", "6"],
    "03/30/2024": ["224", "8"],
    "03/31/2024": ["478", "9"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["346", "3"],
    "04/02/2024": ["288", "8"],
    "04/03/2024": ["456", "5"],
    "04/04/2024": ["236", "1"],
    "04/05/2024": ["120", "3"],
    "04/06/2024": ["458", "7"],
    "04/07/2024": ["159", "5"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["134", "8"],
    "04/09/2024": ["345", "2"],
    "04/10/2024": ["358", "6"],
    "04/11/2024": ["779", "3"],
    "04/12/2024": ["479", "0"],
    "04/13/2024": ["470", "1"],
    "04/14/2024": ["134", "8"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["456", "5"],
    "04/16/2024": ["167", "4"],
    "04/17/2024": ["579", "1"],
    "04/18/2024": ["359", "7"],
    "04/19/2024": ["589", "2"],
    "04/20/2024": ["148", "3"],
    "04/21/2024": ["259", "6"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["678", "1"],
    "04/23/2024": ["224", "8"],
    "04/24/2024": ["569", "0"],
    "04/25/2024": ["448", "6"],
    "04/26/2024": ["680", "4"],
    "04/27/2024": ["239", "4"],
    "04/28/2024": ["379", "9"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["180", "9"],
    "04/30/2024": ["357", "5"],
    "05/01/2024": ["455", "4"],
    "05/02/2024": ["468", "8"],
    "05/03/2024": ["457", "6"],
    "05/04/2024": ["140", "5"],
    "05/05/2024": ["678", "1"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["277", "6"],
    "05/07/2024": ["244", "0"],
    "05/08/2024": ["789", "4"],
    "05/09/2024": ["228", "2"],
    "05/10/2024": ["668", "0"],
    "05/11/2024": ["336", "2"],
    "05/12/2024": ["199", "9"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["136", "0"],
    "05/14/2024": ["169", "6"],
    "05/15/2024": ["590", "4"],
    "05/16/2024": ["790", "6"],
    "05/17/2024": ["569", "0"],
    "05/18/2024": ["157", "3"],
    "05/19/2024": ["269", "7"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["134", "8"],
    "05/21/2024": ["115", "7"],
    "05/22/2024": ["150", "6"],
    "05/23/2024": ["449", "7"],
    "05/24/2024": ["240", "6"],
    "05/25/2024": ["348", "5"],
    "05/26/2024": ["280", "0"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["356", "4"],
    "05/28/2024": ["589", "2"],
    "05/29/2024": ["167", "4"],
    "05/30/2024": ["190", "0"],
    "05/31/2024": ["500", "5"],
    "06/01/2024": ["226", "0"],
    "06/02/2024": ["458", "7"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["388", "9"],
    "06/04/2024": ["123", "6"],
    "06/05/2024": ["229", "3"],
    "06/06/2024": ["359", "7"],
    "06/07/2024": ["467", "7"],
    "06/08/2024": ["245", "1"],
    "06/09/2024": ["300", "3"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["260", "8"],
    "06/11/2024": ["259", "6"],
    "06/12/2024": ["280", "0"],
    "06/13/2024": ["469", "9"],
    "06/14/2024": ["149", "4"],
    "06/15/2024": ["500", "5"],
    "06/16/2024": ["225", "9"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["245", "1"],
    "06/18/2024": ["145", "0"],
    "06/19/2024": ["144", "9"],
    "06/20/2024": ["360", "9"],
    "06/21/2024": ["156", "2"],
    "06/22/2024": ["260", "8"],
    "06/23/2024": ["267", "5"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["267", "5"],
    "06/25/2024": ["239", "4"],
    "06/26/2024": ["199", "9"],
    "06/27/2024": ["239", "4"],
    "06/28/2024": ["457", "6"],
    "06/29/2024": ["137", "1"],
    "06/30/2024": ["129", "2"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["129", "2"],
    "07/02/2024": ["244", "6"],
    "07/03/2024": ["199", "9"],
    "07/04/2024": ["159", "5"],
    "07/05/2024": ["270", "9"],
    "07/06/2024": ["400", "4"],
    "07/07/2024": ["257", "4"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["228", "2"],
    "07/09/2024": ["112", "4"],
    "07/10/2024": ["333", "9"],
    "07/11/2024": ["459", "8"],
    "07/12/2024": ["235", "0"],
    "07/13/2024": ["377", "7"],
    "07/14/2024": ["347", "4"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["677", "0"],
    "07/16/2024": ["600", "6"],
    "07/17/2024": ["346", "3"],
    "07/18/2024": ["299", "0"],
    "07/19/2024": ["300", "3"],
    "07/20/2024": ["234", "9"],
    "07/21/2024": ["188", "7"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["237", "2"],
    "07/23/2024": ["333", "9"],
    "07/24/2024": ["500", "5"],
    "07/25/2024": ["200", "2"],
    "07/26/2024": ["111", "3"],
    "07/27/2024": ["147", "4"],
    "07/28/2024": ["257", "2"],
  },
};

export const Seven = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["569", "0"],
    "01/02/2024": ["124", "7"],
    "01/03/2024": ["555", "5"],
    "01/04/2024": ["444", "2"],
    "01/05/2024": ["357", "5"],
    "01/06/2024": ["236", "1"],
    "01/07/2024": ["300", "3"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["358", "6"],
    "01/09/2024": ["333", "9"],
    "01/10/2024": ["368", "7"],
    "01/11/2024": ["228", "2"],
    "01/12/2024": ["666", "8"],
    "01/13/2024": ["159", "5"],
    "01/14/2024": ["369", "8"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["458", "7"],
    "01/16/2024": ["125", "8"],
    "01/17/2024": ["890", "7"],
    "01/18/2024": ["125", "8"],
    "01/19/2024": ["169", "6"],
    "01/20/2024": ["357", "5"],
    "01/21/2024": ["189", "8"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["248", "4"],
    "01/23/2024": ["137", "1"],
    "01/24/2024": ["380", "1"],
    "01/25/2024": ["247", "3"],
    "01/26/2024": ["120", "3"],
    "01/27/2024": ["260", "8"],
    "01/28/2024": ["158", "4"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["167", "4"],
    "01/30/2024": ["246", "2"],
    "01/31/2024": ["258", "5"],
    "02/01/2024": ["260", "8"],
    "02/02/2024": ["357", "5"],
    "02/03/2024": ["235", "0"],
    "02/04/2024": ["457", "6"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["346", "3"],
    "02/06/2024": ["890", "7"],
    "02/07/2024": ["590", "4"],
    "02/08/2024": ["689", "3"],
    "02/09/2024": ["129", "2"],
    "02/10/2024": ["570", "2"],
    "02/11/2024": ["356", "4"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["156", "2"],
    "02/13/2024": ["450", "9"],
    "02/14/2024": ["245", "1"],
    "02/15/2024": ["100", "1"],
    "02/16/2024": ["246", "2"],
    "02/17/2024": ["457", "6"],
    "02/18/2024": ["248", "4"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["369", "8"],
    "02/20/2024": ["256", "3"],
    "02/21/2024": ["349", "6"],
    "02/22/2024": ["790", "6"],
    "02/23/2024": ["110", "2"],
    "02/24/2024": ["157", "3"],
    "02/25/2024": ["245", "1"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["256", "3"],
    "02/27/2024": ["358", "6"],
    "02/28/2024": ["135", "9"],
    "02/29/2024": ["390", "2"],
    "03/01/2024": ["369", "8"],
    "03/02/2024": ["589", "2"],
    "03/03/2024": ["146", "1"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["136", "0"],
    "03/05/2024": ["245", "1"],
    "03/06/2024": ["400", "4"],
    "03/07/2024": ["368", "7"],
    "03/08/2024": ["560", "1"],
    "03/09/2024": ["900", "9"],
    "03/10/2024": ["359", "7"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["149", "4"],
    "03/12/2024": ["169", "6"],
    "03/13/2024": ["568", "9"],
    "03/14/2024": ["120", "3"],
    "03/15/2024": ["369", "8"],
    "03/16/2024": ["150", "6"],
    "03/17/2024": ["159", "5"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["348", "5"],
    "03/19/2024": ["140", "5"],
    "03/20/2024": ["569", "0"],
    "03/21/2024": ["380", "1"],
    "03/22/2024": ["359", "7"],
    "03/23/2024": ["235", "0"],
    "03/24/2024": ["179", "7"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["230", "5"],
    "03/26/2024": ["459", "8"],
    "03/27/2024": ["569", "0"],
    "03/28/2024": ["457", "6"],
    "03/29/2024": ["578", "0"],
    "03/30/2024": ["789", "4"],
    "03/31/2024": ["113", "5"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["490", "3"],
    "04/02/2024": ["580", "3"],
    "04/03/2024": ["238", "3"],
    "04/04/2024": ["690", "5"],
    "04/05/2024": ["235", "0"],
    "04/06/2024": ["460", "0"],
    "04/07/2024": ["148", "3"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["680", "4"],
    "04/09/2024": ["480", "2"],
    "04/10/2024": ["123", "6"],
    "04/11/2024": ["356", "4"],
    "04/12/2024": ["679", "2"],
    "04/13/2024": ["340", "7"],
    "04/14/2024": ["245", "1"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["124", "7"],
    "04/16/2024": ["789", "4"],
    "04/17/2024": ["378", "8"],
    "04/18/2024": ["567", "8"],
    "04/19/2024": ["357", "5"],
    "04/20/2024": ["470", "1"],
    "04/21/2024": ["389", "0"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["779", "3"],
    "04/23/2024": ["257", "4"],
    "04/24/2024": ["790", "6"],
    "04/25/2024": ["568", "9"],
    "04/26/2024": ["256", "3"],
    "04/27/2024": ["348", "5"],
    "04/28/2024": ["236", "1"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["128", "1"],
    "04/30/2024": ["270", "9"],
    "05/01/2024": ["567", "8"],
    "05/02/2024": ["234", "9"],
    "05/03/2024": ["680", "4"],
    "05/04/2024": ["337", "3"],
    "05/05/2024": ["147", "2"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["356", "4"],
    "05/07/2024": ["279", "8"],
    "05/08/2024": ["590", "4"],
    "05/09/2024": ["179", "7"],
    "05/10/2024": ["125", "8"],
    "05/11/2024": ["459", "8"],
    "05/12/2024": ["224", "8"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["569", "0"],
    "05/14/2024": ["268", "6"],
    "05/15/2024": ["225", "9"],
    "05/16/2024": ["278", "7"],
    "05/17/2024": ["579", "1"],
    "05/18/2024": ["159", "5"],
    "05/19/2024": ["358", "6"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["279", "8"],
    "05/21/2024": ["480", "2"],
    "05/22/2024": ["456", "5"],
    "05/23/2024": ["479", "0"],
    "05/24/2024": ["345", "2"],
    "05/25/2024": ["119", "1"],
    "05/26/2024": ["347", "4"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["159", "5"],
    "05/28/2024": ["367", "6"],
    "05/29/2024": ["247", "3"],
    "05/30/2024": ["550", "0"],
    "05/31/2024": ["468", "8"],
    "06/01/2024": ["169", "6"],
    "06/02/2024": ["145", "0"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["456", "5"],
    "06/04/2024": ["149", "4"],
    "06/05/2024": ["490", "3"],
    "06/06/2024": ["357", "5"],
    "06/07/2024": ["299", "0"],
    "06/08/2024": ["249", "5"],
    "06/09/2024": ["127", "0"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["225", "9"],
    "06/11/2024": ["389", "0"],
    "06/12/2024": ["445", "3"],
    "06/13/2024": ["590", "4"],
    "06/14/2024": ["577", "9"],
    "06/15/2024": ["580", "3"],
    "06/16/2024": ["380", "1"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["699", "4"],
    "06/18/2024": ["166", "3"],
    "06/19/2024": ["669", "1"],
    "06/20/2024": ["178", "6"],
    "06/21/2024": ["380", "1"],
    "06/22/2024": ["116", "8"],
    "06/23/2024": ["230", "5"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["126", "9"],
    "06/25/2024": ["139", "3"],
    "06/26/2024": ["129", "2"],
    "06/27/2024": ["129", "2"],
    "06/28/2024": ["339", "5"],
    "06/29/2024": ["149", "4"],
    "06/30/2024": ["127", "0"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["139", "3"],
    "07/02/2024": ["***", "*"],
    "07/03/2024": ["300", "3"],
    "07/04/2024": ["222", "6"],
    "07/05/2024": ["288", "8"],
    "07/06/2024": ["377", "7"],
    "07/07/2024": ["455", "4"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["280", "0"],
    "07/09/2024": ["345", "2"],
    "07/10/2024": ["789", "4"],
    "07/11/2024": ["333", "9"],
    "07/12/2024": ["345", "2"],
    "07/13/2024": ["799", "5"],
    "07/14/2024": ["467", "7"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["369", "8"],
    "07/16/2024": ["260", "8"],
    "07/17/2024": ["299", "0"],
    "07/18/2024": ["288", "8"],
    "07/19/2024": ["128", "1"],
    "07/20/2024": ["144", "9"],
    "07/21/2024": ["299", "0"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["188", "7"],
    "07/23/2024": ["300", "3"],
    "07/24/2024": ["230", "5"],
    "07/25/2024": ["100", "1"],
    "07/26/2024": ["400", "4"],
    "07/27/2024": ["336", "2"],
    "07/28/2024": ["358", "6"],
  },
};

export const Eight = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["235", "0"],
    "01/02/2024": ["456", "5"],
    "01/03/2024": ["569", "0"],
    "01/04/2024": ["456", "5"],
    "01/05/2024": ["779", "3"],
    "01/06/2024": ["366", "5"],
    "01/07/2024": ["900", "9"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["458", "7"],
    "01/09/2024": ["115", "7"],
    "01/10/2024": ["247", "3"],
    "01/11/2024": ["445", "3"],
    "01/12/2024": ["156", "2"],
    "01/13/2024": ["169", "6"],
    "01/14/2024": ["125", "8"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["789", "4"],
    "01/16/2024": ["159", "5"],
    "01/17/2024": ["236", "1"],
    "01/18/2024": ["789", "4"],
    "01/19/2024": ["489", "1"],
    "01/20/2024": ["159", "5"],
    "01/21/2024": ["469", "9"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["577", "9"],
    "01/23/2024": ["228", "2"],
    "01/24/2024": ["120", "3"],
    "01/25/2024": ["157", "3"],
    "01/26/2024": ["689", "3"],
    "01/27/2024": ["289", "9"],
    "01/28/2024": ["489", "1"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["290", "1"],
    "01/30/2024": ["567", "8"],
    "01/31/2024": ["345", "2"],
    "02/01/2024": ["379", "9"],
    "02/02/2024": ["345", "2"],
    "02/03/2024": ["589", "2"],
    "02/04/2024": ["568", "9"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["129", "2"],
    "02/06/2024": ["110", "2"],
    "02/07/2024": ["890", "7"],
    "02/08/2024": ["189", "8"],
    "02/09/2024": ["226", "0"],
    "02/10/2024": ["259", "6"],
    "02/11/2024": ["146", "1"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["890", "7"],
    "02/13/2024": ["249", "5"],
    "02/14/2024": ["357", "5"],
    "02/15/2024": ["135", "9"],
    "02/16/2024": ["689", "3"],
    "02/17/2024": ["367", "6"],
    "02/18/2024": ["250", "7"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["458", "7"],
    "02/20/2024": ["578", "0"],
    "02/21/2024": ["359", "7"],
    "02/22/2024": ["129", "2"],
    "02/23/2024": ["890", "7"],
    "02/24/2024": ["456", "5"],
    "02/25/2024": ["199", "9"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["147", "2"],
    "02/27/2024": ["148", "3"],
    "02/28/2024": ["569", "0"],
    "02/29/2024": ["140", "5"],
    "03/01/2024": ["236", "1"],
    "03/02/2024": ["238", "3"],
    "03/03/2024": ["260", "8"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["145", "0"],
    "03/05/2024": ["248", "4"],
    "03/06/2024": ["169", "6"],
    "03/07/2024": ["180", "9"],
    "03/08/2024": ["359", "7"],
    "03/09/2024": ["467", "7"],
    "03/10/2024": ["199", "9"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["800", "8"],
    "03/12/2024": ["146", "1"],
    "03/13/2024": ["179", "7"],
    "03/14/2024": ["126", "9"],
    "03/15/2024": ["890", "7"],
    "03/16/2024": ["125", "8"],
    "03/17/2024": ["117", "9"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["589", "2"],
    "03/19/2024": ["569", "0"],
    "03/20/2024": ["247", "3"],
    "03/21/2024": ["477", "8"],
    "03/22/2024": ["568", "9"],
    "03/23/2024": ["568", "9"],
    "03/24/2024": ["258", "5"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["367", "6"],
    "03/26/2024": ["288", "8"],
    "03/27/2024": ["448", "6"],
    "03/28/2024": ["346", "3"],
    "03/29/2024": ["347", "4"],
    "03/30/2024": ["359", "7"],
    "03/31/2024": ["679", "2"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["559", "9"],
    "04/02/2024": ["149", "4"],
    "04/03/2024": ["134", "8"],
    "04/04/2024": ["349", "6"],
    "04/05/2024": ["369", "8"],
    "04/06/2024": ["134", "8"],
    "04/07/2024": ["447", "5"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["228", "2"],
    "04/09/2024": ["269", "7"],
    "04/10/2024": ["168", "5"],
    "04/11/2024": ["260", "8"],
    "04/12/2024": ["225", "9"],
    "04/13/2024": ["223", "7"],
    "04/14/2024": ["678", "1"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["334", "0"],
    "04/16/2024": ["237", "2"],
    "04/17/2024": ["259", "6"],
    "04/18/2024": ["225", "9"],
    "04/19/2024": ["356", "4"],
    "04/20/2024": ["550", "0"],
    "04/21/2024": ["458", "7"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["247", "3"],
    "04/23/2024": ["168", "5"],
    "04/24/2024": ["245", "1"],
    "04/25/2024": ["370", "0"],
    "04/26/2024": ["455", "4"],
    "04/27/2024": ["168", "5"],
    "04/28/2024": ["150", "6"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["258", "5"],
    "04/30/2024": ["467", "7"],
    "05/01/2024": ["778", "2"],
    "05/02/2024": ["356", "4"],
    "05/03/2024": ["366", "5"],
    "05/04/2024": ["247", "3"],
    "05/05/2024": ["260", "8"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["350", "8"],
    "05/07/2024": ["137", "1"],
    "05/08/2024": ["139", "3"],
    "05/09/2024": ["458", "7"],
    "05/10/2024": ["250", "7"],
    "05/11/2024": ["689", "3"],
    "05/12/2024": ["450", "9"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["157", "3"],
    "05/14/2024": ["356", "4"],
    "05/15/2024": ["346", "3"],
    "05/16/2024": ["680", "4"],
    "05/17/2024": ["250", "7"],
    "05/18/2024": ["226", "0"],
    "05/19/2024": ["150", "6"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["300", "3"],
    "05/21/2024": ["680", "4"],
    "05/22/2024": ["270", "9"],
    "05/23/2024": ["349", "6"],
    "05/24/2024": ["579", "1"],
    "05/25/2024": ["270", "9"],
    "05/26/2024": ["470", "1"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["223", "7"],
    "05/28/2024": ["479", "0"],
    "05/29/2024": ["358", "6"],
    "05/30/2024": ["149", "4"],
    "05/31/2024": ["290", "1"],
    "06/01/2024": ["246", "2"],
    "06/02/2024": ["358", "6"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["113", "5"],
    "06/04/2024": ["440", "8"],
    "06/05/2024": ["170", "8"],
    "06/06/2024": ["238", "3"],
    "06/07/2024": ["116", "8"],
    "06/08/2024": ["124", "7"],
    "06/09/2024": ["689", "3"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["579", "1"],
    "06/11/2024": ["160", "7"],
    "06/12/2024": ["339", "5"],
    "06/13/2024": ["169", "6"],
    "06/14/2024": ["890", "7"],
    "06/15/2024": ["689", "3"],
    "06/16/2024": ["147", "2"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["158", "4"],
    "06/18/2024": ["228", "2"],
    "06/19/2024": ["135", "9"],
    "06/20/2024": ["129", "2"],
    "06/21/2024": ["490", "3"],
    "06/22/2024": ["780", "5"],
    "06/23/2024": ["380", "1"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["177", "5"],
    "06/25/2024": ["129", "2"],
    "06/26/2024": ["137", "1"],
    "06/27/2024": ["127", "0"],
    "06/28/2024": ["149", "4"],
    "06/29/2024": ["239", "4"],
    "06/30/2024": ["113", "5"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["267", "5"],
    "07/02/2024": ["***", "*"],
    "07/03/2024": ["333", "9"],
    "07/04/2024": ["117", "9"],
    "07/05/2024": ["223", "7"],
    "07/06/2024": ["199", "9"],
    "07/07/2024": ["300", "3"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["468", "8"],
    "07/09/2024": ["467", "7"],
    "07/10/2024": ["156", "2"],
    "07/11/2024": ["169", "6"],
    "07/12/2024": ["567", "8"],
    "07/13/2024": ["117", "9"],
    "07/14/2024": ["567", "8"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["223", "7"],
    "07/16/2024": ["233", "8"],
    "07/17/2024": ["169", "6"],
    "07/18/2024": ["180", "9"],
    "07/19/2024": ["100", "1"],
    "07/20/2024": ["234", "9"],
    "07/21/2024": ["233", "8"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["122", "5"],
    "07/23/2024": ["100", "1"],
    "07/24/2024": ["300", "3"],
    "07/25/2024": ["237", "2"],
    "07/26/2024": ["227", "1"],
    "07/27/2024": ["677", "0"],
    "07/28/2024": ["158", "4"],
  },
};

export const Nine = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["778", "2"],
    "01/02/2024": ["568", "9"],
    "01/03/2024": ["569", "0"],
    "01/04/2024": ["777", "1"],
    "01/05/2024": ["159", "5"],
    "01/06/2024": ["569", "0"],
    "01/07/2024": ["330", "6"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["147", "2"],
    "01/09/2024": ["249", "5"],
    "01/10/2024": ["566", "7"],
    "01/11/2024": ["999", "7"],
    "01/12/2024": ["128", "1"],
    "01/13/2024": ["369", "8"],
    "01/14/2024": ["115", "7"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["147", "2"],
    "01/16/2024": ["489", "1"],
    "01/17/2024": ["479", "0"],
    "01/18/2024": ["156", "2"],
    "01/19/2024": ["250", "7"],
    "01/20/2024": ["446", "4"],
    "01/21/2024": ["257", "4"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["149", "4"],
    "01/23/2024": ["380", "1"],
    "01/24/2024": ["125", "8"],
    "01/25/2024": ["250", "7"],
    "01/26/2024": ["789", "4"],
    "01/27/2024": ["370", "0"],
    "01/28/2024": ["447", "5"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["289", "9"],
    "01/30/2024": ["190", "0"],
    "01/31/2024": ["190", "0"],
    "02/01/2024": ["389", "0"],
    "02/02/2024": ["446", "4"],
    "02/03/2024": ["345", "2"],
    "02/04/2024": ["260", "8"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["390", "2"],
    "02/06/2024": ["157", "3"],
    "02/07/2024": ["138", "2"],
    "02/08/2024": ["137", "1"],
    "02/09/2024": ["240", "6"],
    "02/10/2024": ["290", "1"],
    "02/11/2024": ["179", "7"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["190", "0"],
    "02/13/2024": ["678", "1"],
    "02/14/2024": ["150", "6"],
    "02/15/2024": ["358", "6"],
    "02/16/2024": ["478", "9"],
    "02/17/2024": ["369", "8"],
    "02/18/2024": ["180", "9"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["239", "4"],
    "02/20/2024": ["156", "2"],
    "02/21/2024": ["144", "9"],
    "02/22/2024": ["456", "5"],
    "02/23/2024": ["239", "4"],
    "02/24/2024": ["345", "2"],
    "02/25/2024": ["123", "6"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["700", "7"],
    "02/27/2024": ["357", "5"],
    "02/28/2024": ["258", "5"],
    "02/29/2024": ["125", "8"],
    "03/01/2024": ["789", "4"],
    "03/02/2024": ["990", "8"],
    "03/03/2024": ["147", "2"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["160", "7"],
    "03/05/2024": ["299", "0"],
    "03/06/2024": ["489", "1"],
    "03/07/2024": ["499", "2"],
    "03/08/2024": ["140", "5"],
    "03/09/2024": ["120", "3"],
    "03/10/2024": ["235", "0"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["550", "0"],
    "03/12/2024": ["190", "0"],
    "03/13/2024": ["239", "4"],
    "03/14/2024": ["267", "5"],
    "03/15/2024": ["126", "9"],
    "03/16/2024": ["149", "4"],
    "03/17/2024": ["267", "5"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["450", "9"],
    "03/19/2024": ["345", "2"],
    "03/20/2024": ["470", "1"],
    "03/21/2024": ["789", "4"],
    "03/22/2024": ["889", "5"],
    "03/23/2024": ["790", "6"],
    "03/24/2024": ["457", "6"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["560", "1"],
    "03/26/2024": ["679", "2"],
    "03/27/2024": ["450", "9"],
    "03/28/2024": ["290", "1"],
    "03/29/2024": ["127", "0"],
    "03/30/2024": ["347", "4"],
    "03/31/2024": ["668", "0"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["267", "5"],
    "04/02/2024": ["678", "1"],
    "04/03/2024": ["569", "0"],
    "04/04/2024": ["479", "0"],
    "04/05/2024": ["169", "6"],
    "04/06/2024": ["268", "6"],
    "04/07/2024": ["480", "2"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["135", "9"],
    "04/09/2024": ["126", "9"],
    "04/10/2024": ["145", "0"],
    "04/11/2024": ["457", "6"],
    "04/12/2024": ["599", "3"],
    "04/13/2024": ["230", "5"],
    "04/14/2024": ["690", "5"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["450", "9"],
    "04/16/2024": ["139", "3"],
    "04/17/2024": ["279", "8"],
    "04/18/2024": ["489", "1"],
    "04/19/2024": ["890", "7"],
    "04/20/2024": ["235", "0"],
    "04/21/2024": ["140", "5"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["168", "5"],
    "04/23/2024": ["350", "8"],
    "04/24/2024": ["178", "6"],
    "04/25/2024": ["377", "7"],
    "04/26/2024": ["145", "0"],
    "04/27/2024": ["123", "6"],
    "04/28/2024": ["119", "1"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["124", "7"],
    "04/30/2024": ["478", "9"],
    "05/01/2024": ["669", "1"],
    "05/02/2024": ["135", "9"],
    "05/03/2024": ["256", "3"],
    "05/04/2024": ["234", "9"],
    "05/05/2024": ["360", "9"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["125", "8"],
    "05/07/2024": ["468", "8"],
    "05/08/2024": ["344", "1"],
    "05/09/2024": ["445", "3"],
    "05/10/2024": ["289", "9"],
    "05/11/2024": ["460", "0"],
    "05/12/2024": ["550", "0"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["233", "8"],
    "05/14/2024": ["480", "2"],
    "05/15/2024": ["140", "5"],
    "05/16/2024": ["120", "3"],
    "05/17/2024": ["379", "9"],
    "05/18/2024": ["124", "7"],
    "05/19/2024": ["360", "9"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["348", "5"],
    "05/21/2024": ["233", "8"],
    "05/22/2024": ["689", "3"],
    "05/23/2024": ["140", "5"],
    "05/24/2024": ["236", "1"],
    "05/25/2024": ["346", "3"],
    "05/26/2024": ["300", "3"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["146", "1"],
    "05/28/2024": ["249", "5"],
    "05/29/2024": ["449", "7"],
    "05/30/2024": ["335", "1"],
    "05/31/2024": ["359", "7"],
    "06/01/2024": ["560", "1"],
    "06/02/2024": ["580", "3"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["189", "8"],
    "06/04/2024": ["369", "8"],
    "06/05/2024": ["388", "9"],
    "06/06/2024": ["140", "5"],
    "06/07/2024": ["168", "5"],
    "06/08/2024": ["569", "0"],
    "06/09/2024": ["246", "2"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["178", "6"],
    "06/11/2024": ["450", "9"],
    "06/12/2024": ["135", "9"],
    "06/13/2024": ["448", "6"],
    "06/14/2024": ["267", "5"],
    "06/15/2024": ["470", "1"],
    "06/16/2024": ["112", "4"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["230", "5"],
    "06/18/2024": ["460", "0"],
    "06/19/2024": ["568", "9"],
    "06/20/2024": ["588", "1"],
    "06/21/2024": ["267", "5"],
    "06/22/2024": ["358", "6"],
    "06/23/2024": ["235", "0"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["159", "5"],
    "06/25/2024": ["189", "8"],
    "06/26/2024": ["239", "4"],
    "06/27/2024": ["346", "3"],
    "06/28/2024": ["117", "9"],
    "06/29/2024": ["147", "2"],
    "06/30/2024": ["167", "4"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["111", "3"],
    "07/02/2024": ["***", "*"],
    "07/03/2024": ["345", "2"],
    "07/04/2024": ["111", "3"],
    "07/05/2024": ["123", "6"],
    "07/06/2024": ["288", "8"],
    "07/07/2024": ["237", "2"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["357", "5"],
    "07/09/2024": ["145", "0"],
    "07/10/2024": ["222", "6"],
    "07/11/2024": ["236", "1"],
    "07/12/2024": ["459", "8"],
    "07/13/2024": ["111", "3"],
    "07/14/2024": ["233", "8"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["137", "1"],
    "07/16/2024": ["236", "1"],
    "07/17/2024": ["388", "9"],
    "07/18/2024": ["222", "6"],
    "07/19/2024": ["400", "4"],
    "07/20/2024": ["100", "1"],
    "07/21/2024": ["244", "6"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["125", "8"],
    "07/23/2024": ["122", "5"],
    "07/24/2024": ["170", "8"],
    "07/25/2024": ["288", "8"],
    "07/26/2024": ["112", "4"],
    "07/27/2024": ["110", "2"],
    "07/28/2024": ["355", "3"],
  },
};

export const AllSingleDayChart = {
  "01/01/2024": {
    1: ["123", "6"],
    2: ["569", "0"],
    3: ["123", "6"],
    4: ["999", "7"],
    5: ["789", "4"],
    6: ["125", "8"],
    7: ["456", "5"],
    8: ["458", "7"],
    9: ["125", "8"],
    10: ["444", "2"],
    11: ["235", "0"],
    12: ["778", "2"],
  },
  "02/01/2024": {
    1: ["888", "4"],
    2: ["699", "4"],
    3: ["457", "6"],
    4: ["456", "5"],
    5: ["124", "7"],
    6: ["258", "5"],
    7: ["999", "7"],
    8: ["569", "0"],
    9: ["568", "9"],
    10: ["789", "4"],
    11: ["568", "9"],
    12: ["357", "5"],
  },
  "03/01/2024": {
    1: ["225", "9"],
    2: ["555", "5"],
    3: ["900", "9"],
    4: ["222", "6"],
    5: ["235", "0"],
    6: ["333", "9"],
    7: ["990", "8"],
    8: ["778", "2"],
    9: ["445", "3"],
    10: ["569", "0"],
    11: ["569", "0"],
    12: ["899", "6"],
  },
  "04/01/2024": {
    1: ["777", "1"],
    2: ["256", "3"],
    3: ["666", "8"],
    4: ["600", "6"],
    5: ["444", "2"],
    6: ["999", "7"],
    7: ["222", "6"],
    8: ["222", "6"],
    9: ["456", "5"],
    10: ["127", "0"],
    11: ["569", "0"],
    12: ["899", "6"],
  },
  "05/01/2024": {
    1: ["369", "8"],
    2: ["159", "5"],
    3: ["569", "0"],
    4: ["699", "4"],
    5: ["148", "3"],
    6: ["359", "7"],
    7: ["223", "7"],
    8: ["147", "2"],
    9: ["288", "8"],
    10: ["779", "3"],
    11: ["357", "5"],
    12: ["356", "4"]
},
"06/01/2024": {
    1: ["259", "6"],
    2: ["569", "0"],
    3: ["235", "0"],
    4: ["366", "5"],
    5: ["236", "1"],
    6: ["569", "0"],
    7: ["489", "1"],
    8: ["777", "1"],
    9: ["777", "1"],
    10: ["123", "6"],
    11: ["555", "5"],
    12: ["477", "8"]
},

"07/01/2024": {
    1: ["300", "3"],
    2: ["330", "6"],
    3: ["555", "5"],
    4: ["458", "7"],
    5: ["458", "7"],
    6: ["789", "4"],
    7: ["999", "7"],
    8: ["230", "5"],
    9: ["125", "8"],
    10: ["890", "7"],
    11: ["900", "9"]
},
"08/01/2024": {
    1: ["456", "5"],
    2: ["255", "2"],
    3: ["800", "8"],
    4: ["699", "4"],
    5: ["149", "4"],
    6: ["899", "6"],
    7: ["458", "7"],
    8: ["125", "8"],
    9: ["358", "6"],
    10: ["147", "2"],
    11: ["100", "1"],
    12: ["355", "3"]
},

"09/01/2024": {
    1: ["389", "0"],
    2: ["880", "6"],
    3: ["668", "0"],
    4: ["778", "2"],
    5: ["699", "4"],
    6: ["555", "5"],
    7: ["333", "9"],
    8: ["140", "5"],
    9: ["220", "4"],
    10: ["558", "8"],
    11: ["115", "7"],
    12: ["249", "5"]
},

"10/01/2024": {
    1: ["127", "0"],
    2: ["236", "1"],
    3: ["226", "0"],
    4: ["247", "3"],
    5: ["368", "7"],
    6: ["566", "7"],
    7: ["117", "9"],
    8: ["338", "4"],
    9: ["555", "5"],
    10: ["128", "1"],
    11: ["888", "4"],
    12: ["258", "5"]
},

"11/01/2024": {
    1: ["0", "0"],
    2: ["558", "8"],
    3: ["115", "7"],
    4: ["445", "3"],
    5: ["228", "2"],
    6: ["999", "7"],
    7: ["145", "0"],
    8: ["446", "4"],
    9: ["558", "8"],
    10: ["115", "7"],
    11: ["889", "5"],
    12: ["236", "1"]
},
"12/01/2024": {
    1: ["458", "7"],
    2: ["123", "6"],
    3: ["258", "5"],
    4: ["156", "2"],
    5: ["666", "8"],
    6: ["128", "1"],
    7: ["456", "5"],
    8: ["159", "5"],
    9: ["127", "0"],
    10: ["459", "8"],
    11: ["699", "4"],
    12: ["359", "7"]
},

"13/01/2024": {
    1: ["115", "7"],
    2: ["238", "3"],
    3: ["115", "7"],
    4: ["169", "6"],
    5: ["159", "5"],
    6: ["369", "8"],
    7: ["368", "7"],
    8: ["567", "8"],
    9: ["470", "1"],
    10: ["690", "5"],
    11: ["123", "6"],
    12: ["569", "0"]
},

"14/01/2024": {
    1: ["460", "0"],
    2: ["129", "2"],
    3: ["357", "5"],
    4: ["125", "8"],
    5: ["369", "8"],
    6: ["115", "7"],
    7: ["456", "5"],
    8: ["890", "7"],
    9: ["789", "4"],
    10: ["250", "7"],
    11: ["359", "7"],
    12: ["255", "2"]
},

"15/01/2024": {
    1: ["359", "7"],
    2: ["380", "1"],
    3: ["159", "5"],
    4: ["789", "4"],
    5: ["458", "7"],
    6: ["147", "2"],
    7: ["569", "0"],
    8: ["890", "7"],
    9: ["458", "7"],
    10: ["156", "2"],
    11: ["479", "0"],
    12: ["590", "4"]
},

"16/01/2024": {
    1: ["456", "5"],
    2: ["789", "4"],
    3: ["590", "4"],
    4: ["159", "5"],
    5: ["125", "8"],
    6: ["489", "1"],
    7: ["359", "7"],
    8: ["256", "3"],
    9: ["690", "5"],
    10: ["569", "0"],
    11: ["358", "6"],
    12: ["123", "6"]
},

"17/01/2024": {
    1: ["460", "0"],
    2: ["123", "6"],
    3: ["456", "5"],
    4: ["236", "1"],
    5: ["890", "7"],
    6: ["479", "0"],
    7: ["249", "5"],
    8: ["128", "1"],
    9: ["359", "7"],
    10: ["689", "3"],
    11: ["129", "2"],
    12: ["230", "5"]
},
"18/01/2024": {
    1: ["123", "6"],
    2: ["268", "6"],
    3: ["230", "5"],
    4: ["789", "4"],
    5: ["125", "8"],
    6: ["156", "2"],
    7: ["569", "0"],
    8: ["890", "7"],
    9: ["489", "1"],
    10: ["359", "7"],
    11: ["250", "7"],
    12: ["159", "5"]
},

"19/01/2024": {
    1: ["458", "7"],
    2: ["149", "4"],
    3: ["258", "5"],
    4: ["489", "1"],
    5: ["169", "6"],
    6: ["250", "7"],
    7: ["458", "7"],
    8: ["125", "8"],
    9: ["348", "5"],
    10: ["189", "8"],
    11: ["789", "4"],
    12: ["348", "5"]
},

"20/01/2024": {
    1: ["123", "6"],
    2: ["478", "9"],
    3: ["369", "8"],
    4: ["159", "5"],
    5: ["357", "5"],
    6: ["446", "4"],
    7: ["150", "6"],
    8: ["489", "1"],
    9: ["358", "6"],
    10: ["157", "3"],
    11: ["258", "5"],
    12: ["280", "0"]
},

"21/01/2024": {
    1: ["357", "5"],
    2: ["147", "2"],
    3: ["236", "1"],
    4: ["469", "9"],
    5: ["189", "8"],
    6: ["257", "4"],
    7: ["458", "7"],
    8: ["169", "6"],
    9: ["360", "9"],
    10: ["190", "0"],
    11: ["157", "3"],
    12: ["890", "7"]
},

"22/01/2024": {
    1: ["456", "5"],
    2: ["125", "8"],
    3: ["249", "5"],
    4: ["577", "9"],
    5: ["248", "4"],
    6: ["149", "4"],
    7: ["349", "6"],
    8: ["580", "3"],
    9: ["356", "4"],
    10: ["140", "5"],
    11: ["269", "7"],
    12: ["489", "1"]
},

"23/01/2024": {
    1: ["249", "5"],
    2: ["349", "6"],
    3: ["679", "2"],
    4: ["228", "2"],
    5: ["137", "1"],
    6: ["380", "1"],
    7: ["568", "9"],
    8: ["238", "3"],
    9: ["368", "7"],
    10: ["369", "8"],
    11: ["689", "3"],
    12: ["246", "2"]
},

"24/01/2024": {
    1: ["358", "6"],
    2: ["450", "9"],
    3: ["249", "5"],
    4: ["120", "3"],
    5: ["380", "1"],
    6: ["125", "8"],
    7: ["570", "2"],
    8: ["127", "0"],
    9: ["260", "8"],
    10: ["256", "3"],
    11: ["689", "3"],
    12: ["148", "3"]
},"25/01/2024": {
    1: ["290", "1"],
    2: ["456", "5"],
    3: ["379", "9"],
    4: ["157", "3"],
    5: ["247", "3"],
    6: ["250", "7"],
    7: ["123", "6"],
    8: ["249", "5"],
    9: ["350", "8"],
    10: ["128", "1"],
    11: ["490", "3"],
    12: ["890", "7"]
},

"26/01/2024": {
    1: ["130", "4"],
    2: ["138", "2"],
    3: ["356", "4"],
    4: ["689", "3"],
    5: ["120", "3"],
    6: ["789", "4"],
    7: ["128", "1"],
    8: ["578", "0"],
    9: ["278", "7"],
    10: ["189", "8"],
    11: ["469", "9"],
    12: ["367", "6"]
},

"27/01/2024": {
    1: ["129", "2"],
    2: ["457", "6"],
    3: ["146", "1"],
    4: ["289", "9"],
    5: ["260", "8"],
    6: ["370", "0"],
    7: ["345", "2"],
    8: ["120", "3"],
    9: ["789", "4"],
    10: ["145", "0"],
    11: ["589", "2"],
    12: ["446", "4"]
},

"28/01/2024": {
    1: ["235", "0"],
    2: ["679", "2"],
    3: ["367", "6"],
    4: ["489", "1"],
    5: ["158", "4"],
    6: ["447", "5"],
    7: ["247", "3"],
    8: ["589", "2"],
    9: ["124", "7"],
    10: ["129", "2"],
    11: ["346", "3"],
    12: ["450", "9"]
},

"29/01/2024": {
    1: ["120", "3"],
    2: ["134", "8"],
    3: ["360", "9"],
    4: ["290", "1"],
    5: ["167", "4"],
    6: ["289", "9"],
    7: ["668", "0"],
    8: ["456", "5"],
    9: ["789", "4"],
    10: ["346", "3"],
    11: ["258", "5"],
    12: ["569", "0"]
},

"30/01/2024": {
    1: ["289", "9"],
    2: ["123", "6"],
    3: ["458", "7"],
    4: ["567", "8"],
    5: ["246", "2"],
    6: ["190", "0"],
    7: ["257", "4"],
    8: ["570", "2"],
    9: ["339", "5"],
    10: ["670", "3"],
    11: ["456", "5"],
    12: ["149", "4"]
},
"31/01/2024": {
    1: ["348", "5"],
    2: ["189", "8"],
    3: ["244", "0"],
    4: ["345", "2"],
    5: ["258", "5"],
    6: ["190", "0"],
    7: ["259", "6"],
    8: ["478", "9"],
    9: ["148", "3"],
    10: ["257", "4"],
    11: ["358", "6"],
    12: ["278", "7"]
},

"01/02/2024": {
    1: ["167", "4"],
    2: ["456", "5"],
    3: ["570", "2"],
    4: ["379", "9"],
    5: ["260", "8"],
    6: ["389", "0"],
    7: ["156", "2"],
    8: ["135", "9"],
    9: ["123", "6"],
    10: ["578", "0"],
    11: ["140", "5"],
    12: ["246", "2"]
},

"02/02/2024": {
    1: ["156", "2"],
    2: ["270", "9"],
    3: ["235", "0"],
    4: ["345", "2"],
    5: ["357", "5"],
    6: ["446", "4"],
    7: ["360", "9"],
    8: ["389", "0"],
    9: ["140", "5"],
    10: ["167", "4"],
    11: ["790", "6"],
    12: ["289", "9"]
},

"03/02/2024": {
    1: ["123", "6"],
    2: ["234", "9"],
    3: ["578", "0"],
    4: ["589", "2"],
    5: ["235", "0"],
    6: ["345", "2"],
    7: ["170", "8"],
    8: ["567", "8"],
    9: ["390", "2"],
    10: ["600", "6"],
    11: ["568", "9"],
    12: ["356", "4"]
},
"04/02/2024": {
    1: ["370", "0"],
    2: ["347", "4"],
    3: ["456", "5"],
    4: ["568", "9"],
    5: ["457", "6"],
    6: ["260", "8"],
    7: ["789", "4"],
    8: ["160", "7"],
    9: ["200", "2"],
    10: ["346", "3"],
    11: ["356", "4"],
    12: ["139", "3"]
},

"05/02/2024": {
    1: ["179", "7"],
    2: ["245", "1"],
    3: ["344", "1"],
    4: ["129", "2"],
    5: ["346", "3"],
    6: ["390", "2"],
    7: ["134", "8"],
    8: ["789", "4"],
    9: ["134", "8"],
    10: ["457", "6"],
    11: ["180", "9"],
    12: ["467", "7"]
},

"06/02/2024": {
    1: ["245", "1"],
    2: ["578", "0"],
    3: ["568", "9"],
    4: ["110", "2"],
    5: ["890", "7"],
    6: ["157", "3"],
    7: ["234", "9"],
    8: ["356", "4"],
    9: ["230", "5"],
    10: ["468", "8"],
    11: ["358", "6"],
    12: ["355", "3"]
},

"07/02/2024": {
    1: ["369", "8"],
    2: ["148", "3"],
    3: ["100", "1"],
    4: ["890", "7"],
    5: ["590", "4"],
    6: ["138", "2"],
    7: ["346", "3"],
    8: ["489", "1"],
    9: ["150", "6"],
    10: ["347", "4"],
    11: ["140", "5"],
    12: ["235", "0"]
},

"08/02/2024": {
    1: ["459", "8"],
    2: ["569", "0"],
    3: ["690", "5"],
    4: ["189", "8"],
    5: ["689", "3"],
    6: ["137", "1"],
    7: ["246", "2"],
    8: ["356", "4"],
    9: ["237", "2"],
    10: ["150", "6"],
    11: ["144", "9"],
    12: ["890", "7"]
},

"09/02/2024": {
    1: ["890", "7"],
    2: ["190", "0"],
    3: ["289", "9"],
    4: ["226", "0"],
    5: ["129", "2"],
    6: ["240", "6"],
    7: ["125", "8"],
    8: ["245", "1"],
    9: ["580", "3"],
    10: ["356", "4"],
    11: ["258", "5"],
    12: ["110", "2"]
},

"10/02/2024": {
    1: ["160", "7"],
    2: ["569", "0"],
    3: ["346", "3"],
    4: ["259", "6"],
    5: ["570", "2"],
    6: ["290", "1"],
    7: ["125", "8"],
    8: ["379", "9"],
    9: ["127", "0"],
    10: ["670", "3"],
    11: ["140", "5"],
    12: ["467", "7"]
},

"11/02/2024": {
    1: ["389", "0"],
    2: ["569", "0"],
    3: ["256", "3"],
    4: ["146", "1"],
    5: ["356", "4"],
    6: ["179", "7"],
    7: ["147", "2"],
    8: ["390", "2"],
    9: ["230", "5"],
    10: ["129", "2"],
    11: ["690", "5"],
    12: ["136", "0"]
},

"12/02/2024": {
    1: ["670", "3"],
    2: ["345", "2"],
    3: ["358", "6"],
    4: ["890", "7"],
    5: ["156", "2"],
    6: ["190", "0"],
    7: ["130", "4"],
    8: ["258", "5"],
    9: ["128", "1"],
    10: ["349", "6"],
    11: ["289", "9"],
    12: ["134", "8"]
},

"13/02/2024": {
    1: ["349", "6"],
    2: ["123", "6"],
    3: ["120", "3"],
    4: ["249", "5"],
    5: ["450", "9"],
    6: ["678", "1"],
    7: ["100", "1"],
    8: ["156", "2"],
    9: ["158", "4"],
    10: ["458", "7"],
    11: ["340", "7"],
    12: ["145", "0"]
},
"14/02/2024": {
    1: ["560", "1"],
    2: ["145", "0"],
    3: ["235", "0"],
    4: ["357", "5"],
    5: ["245", "1"],
    6: ["150", "6"],
    7: ["258", "5"],
    8: ["345", "2"],
    9: ["289", "9"],
    10: ["445", "3"],
    11: ["468", "8"],
    12: ["458", "7"]
},

"15/02/2024": {
    1: ["337", "3"],
    2: ["245", "1"],
    3: ["469", "9"],
    4: ["135", "9"],
    5: ["100", "1"],
    6: ["358", "6"],
    7: ["567", "8"],
    8: ["288", "8"],
    9: ["348", "5"],
    10: ["122", "5"],
    11: ["356", "4"],
    12: ["234", "9"]
},

"16/02/2024": {
    1: ["100", "1"],
    2: ["356", "4"],
    3: ["128", "1"],
    4: ["689", "3"],
    5: ["246", "2"],
    6: ["478", "9"],
    7: ["990", "8"],
    8: ["578", "0"],
    9: ["258", "5"],
    10: ["457", "6"],
    11: ["467", "7"],
    12: ["346", "3"]
},

"17/02/2024": {
    1: ["145", "0"],
    2: ["550", "0"],
    3: ["567", "8"],
    4: ["367", "6"],
    5: ["457", "6"],
    6: ["369", "8"],
    7: ["156", "2"],
    8: ["356", "4"],
    9: ["368", "7"],
    10: ["120", "3"],
    11: ["147", "2"],
    12: ["290", "1"]
},

"18/02/2024": {
    1: ["145", "0"],
    2: ["459", "8"],
    3: ["678", "1"],
    4: ["250", "7"],
    5: ["248", "4"],
    6: ["180", "9"],
    7: ["200", "2"],
    8: ["346", "3"],
    9: ["290", "1"],
    10: ["140", "5"],
    11: ["367", "6"],
    12: ["330", "6"]
},

"19/02/2024": {
    1: ["348", "5"],
    2: ["122", "5"],
    3: ["890", "7"],
    4: ["458", "7"],
    5: ["369", "8"],
    6: ["239", "4"],
    7: ["560", "1"],
    8: ["345", "2"],
    9: ["256", "3"],
    10: ["150", "6"],
    11: ["568", "9"],
    12: ["145", "0"]
},

"20/02/2024": {
    1: ["357", "5"],
    2: ["100", "1"],
    3: ["145", "0"],
    4: ["578", "0"],
    5: ["256", "3"],
    6: ["156", "2"],
    7: ["356", "4"],
    8: ["178", "6"],
    9: ["245", "1"],
    10: ["146", "1"],
    11: ["467", "7"],
    12: ["577", "9"]
},

"21/02/2024": {
    1: ["155", "1"],
    2: ["145", "0"],
    3: ["478", "9"],
    4: ["359", "7"],
    5: ["349", "6"],
    6: ["144", "9"],
    7: ["357", "5"],
    8: ["356", "4"],
    9: ["390", "2"],
    10: ["139", "3"],
    11: ["459", "8"],
    12: ["467", "7"]
},

"22/02/2024": {
    1: ["145", "0"],
    2: ["489", "1"],
    3: ["580", "3"],
    4: ["129", "2"],
    5: ["790", "6"],
    6: ["456", "5"],
    7: ["136", "0"],
    8: ["400", "4"],
    9: ["125", "8"],
    10: ["137", "1"],
    11: ["689", "3"],
    12: ["457", "6"]
},

"23/02/2024": {
    1: ["370", "0"],
    2: ["245", "1"],
    3: ["256", "3"],
    4: ["890", "7"],
    5: ["110", "2"],
    6: ["239", "4"],
    7: ["680", "4"],
    8: ["126", "9"],
    9: ["270", "9"],
    10: ["237", "2"],
    11: ["900", "9"],
    12: ["356", "4"]
},

"24/02/2024": {
    1: ["190", "0"],
    2: ["257", "4"],
    3: ["140", "5"],
    4: ["456", "5"],
    5: ["157", "3"],
    6: ["345", "2"],
    7: ["356", "4"],
    8: ["348", "5"],
    9: ["900", "9"],
    10: ["780", "5"],
    11: ["344", "1"],
    12: ["123", "6"]
},
"25/02/2024": {
    1: ["460", "0"],
    2: ["256", "3"],
    3: ["140", "5"],
    4: ["199", "9"],
    5: ["245", "1"],
    6: ["123", "6"],
    7: ["149", "4"],
    8: ["600", "6"],
    9: ["110", "2"],
    10: ["120", "3"],
    11: ["245", "1"],
    12: ["157", "3"]
},

"26/02/2024": {
    1: ["289", "9"],
    2: ["470", "1"],
    3: ["890", "7"],
    4: ["147", "2"],
    5: ["256", "3"],
    6: ["700", "7"],
    7: ["338", "4"],
    8: ["456", "5"],
    9: ["245", "1"],
    10: ["145", "0"],
    11: ["459", "8"],
    12: ["125", "8"]
},

"27/02/2024": {
    1: ["467", "7"],
    2: ["379", "9"],
    3: ["137", "1"],
    4: ["148", "3"],
    5: ["358", "6"],
    6: ["357", "5"],
    7: ["480", "2"],
    8: ["257", "4"],
    9: ["147", "2"],
    10: ["550", "0"],
    11: ["468", "8"],
    12: ["259", "6"]
},

"28/02/2024": {
    1: ["247", "3"],
    2: ["356", "4"],
    3: ["138", "2"],
    4: ["569", "0"],
    5: ["135", "9"],
    6: ["258", "5"],
    7: ["477", "8"],
    8: ["245", "1"],
    9: ["579", "1"],
    10: ["200", "2"],
    11: ["167", "4"],
    12: ["457", "6"]
},

"29/02/2024": {
    1: ["347", "4"],
    2: ["489", "1"],
    3: ["550", "0"],
    4: ["140", "5"],
    5: ["390", "2"],
    6: ["125", "8"],
    7: ["700", "7"],
    8: ["160", "7"],
    9: ["145", "0"],
    10: ["337", "3"],
    11: ["280", "0"],
    12: ["134", "8"]
},

"01/03/2024": {
    1: ["140", "5"],
    2: ["359", "7"],
    3: ["300", "3"],
    4: ["236", "1"],
    5: ["369", "8"],
    6: ["789", "4"],
    7: ["457", "6"],
    8: ["123", "6"],
    9: ["900", "9"],
    10: ["135", "9"],
    11: ["230", "5"],
    12: ["370", "0"]
},

"02/03/2024": {
    1: ["347", "4"],
    2: ["140", "5"],
    3: ["100", "1"],
    4: ["238", "3"],
    5: ["589", "2"],
    6: ["990", "8"],
    7: ["234", "9"],
    8: ["457", "6"],
    9: ["126", "9"],
    10: ["124", "7"],
    11: ["145", "0"],
    12: ["129", "2"]
},

"03/03/2024": {
    1: ["377", "7"],
    2: ["370", "0"],
    3: ["559", "9"],
    4: ["260", "8"],
    5: ["146", "1"],
    6: ["147", "2"],
    7: ["230", "5"],
    8: ["139", "3"],
    9: ["240", "6"],
    10: ["347", "4"],
    11: ["480", "2"],
    12: ["125", "8"]
},

"04/03/2024": {
    1: ["358", "6"],
    2: ["340", "7"],
    3: ["100", "1"],
    4: ["145", "0"],
    5: ["136", "0"],
    6: ["160", "7"],
    7: ["567", "8"],
    8: ["356", "4"],
    9: ["110", "2"],
    10: ["369", "8"],
    11: ["129", "2"],
    12: ["150", "6"]
},

"05/03/2024": {
    1: ["220", "4"],
    2: ["160", "7"],
    3: ["130", "4"],
    4: ["248", "4"],
    5: ["245", "1"],
    6: ["299", "0"],
    7: ["468", "8"],
    8: ["190", "0"],
    9: ["289", "9"],
    10: ["480", "2"],
    11: ["148", "3"],
    12: ["120", "3"]
},

"06/03/2024": {
    1: ["299", "0"],
    2: ["170", "8"],
    3: ["789", "4"],
    4: ["169", "6"],
    5: ["400", "4"],
    6: ["489", "1"],
    7: ["110", "2"],
    8: ["168", "5"],
    9: ["689", "3"],
    10: ["246", "2"],
    11: ["288", "8"],
    12: ["145", "0"]
},

"07/03/2024": {
    1: ["589", "2"],
    2: ["220", "4"],
    3: ["189", "8"],
    4: ["180", "9"],
    5: ["368", "7"],
    6: ["499", "2"],
    7: ["130", "4"],
    8: ["459", "8"],
    9: ["280", "0"],
    10: ["128", "1"],
    11: ["249", "5"],
    12: ["137", "1"]
},

"08/03/2024": {
    1: ["389", "0"],
    2: ["257", "4"],
    3: ["200", "2"],
    4: ["359", "7"],
    5: ["560", "1"],
    6: ["140", "5"],
    7: ["689", "3"],
    8: ["290", "1"],
    9: ["289", "9"],
    10: ["256", "3"],
    11: ["159", "5"],
    12: ["180", "9"]
},

"09/03/2024": {
    1: ["356", "4"],
    2: ["258", "5"],
    3: ["235", "0"],
    4: ["467", "7"],
    5: ["900", "9"],
    6: ["120", "3"],
    7: ["290", "1"],
    8: ["129", "2"],
    9: ["359", "7"],
    10: ["340", "7"],
    11: ["257", "4"],
    12: ["126", "9"]
},
"10/03/2024": {
    1: ["467", "7"],
    2: ["258", "5"],
    3: ["120", "3"],
    4: ["199", "9"],
    5: ["359", "7"],
    6: ["235", "0"],
    7: ["447", "5"],
    8: ["290", "1"],
    9: ["129", "2"],
    10: ["257", "4"],
    11: ["123", "6"],
    12: ["180", "9"]
},

"11/03/2024": {
    1: ["140", "5"],
    2: ["247", "3"],
    3: ["123", "6"],
    4: ["800", "8"],
    5: ["149", "4"],
    6: ["550", "0"],
    7: ["180", "9"],
    8: ["290", "1"],
    9: ["360", "9"],
    10: ["557", "7"],
    11: ["780", "5"],
    12: ["160", "7"]
},

"12/03/2024": {
    1: ["149", "4"],
    2: ["247", "3"],
    3: ["440", "8"],
    4: ["146", "1"],
    5: ["169", "6"],
    6: ["190", "0"],
    7: ["400", "4"],
    8: ["390", "2"],
    9: ["249", "5"],
    10: ["145", "0"],
    11: ["588", "1"],
    12: ["199", "9"]
},

"13/03/2024": {
    1: ["448", "6"],
    2: ["246", "2"],
    3: ["125", "8"],
    4: ["179", "7"],
    5: ["568", "9"],
    6: ["239", "4"],
    7: ["136", "0"],
    8: ["459", "8"],
    9: ["890", "7"],
    10: ["780", "5"],
    11: ["148", "3"],
    12: ["128", "1"]
},

"14/03/2024": {
    1: ["566", "7"],
    2: ["660", "2"],
    3: ["249", "5"],
    4: ["126", "9"],
    5: ["120", "3"],
    6: ["267", "5"],
    7: ["138", "2"],
    8: ["124", "7"],
    9: ["235", "0"],
    10: ["567", "8"],
    11: ["130", "4"],
    12: ["568", "9"]
},

"15/03/2024": {
    1: ["200", "2"],
    2: ["280", "0"],
    3: ["117", "9"],
    4: ["890", "7"],
    5: ["369", "8"],
    6: ["126", "9"],
    7: ["679", "2"],
    8: ["133", "7"],
    9: ["127", "0"],
    10: ["157", "3"],
    11: ["788", "3"],
    12: ["680", "4"]
},

"16/03/2024": {
    1: ["345", "2"],
    2: ["133", "7"],
    3: ["778", "2"],
    4: ["125", "8"],
    5: ["150", "6"],
    6: ["149", "4"],
    7: ["235", "0"],
    8: ["356", "4"],
    9: ["290", "1"],
    10: ["566", "7"],
    11: ["690", "5"],
    12: ["135", "9"]
},

"17/03/2024": {
    1: ["128", "1"],
    2: ["677", "0"],
    3: ["367", "6"],
    4: ["117", "9"],
    5: ["159", "5"],
    6: ["267", "5"],
    7: ["347", "4"],
    8: ["890", "7"],
    9: ["900", "9"],
    10: ["480", "2"],
    11: ["140", "5"],
    12: ["389", "0"]
},

"18/03/2024": {
    1: ["136", "0"],
    2: ["239", "4"],
    3: ["467", "7"],
    4: ["589", "2"],
    5: ["348", "5"],
    6: ["450", "9"],
    7: ["770", "4"],
    8: ["678", "1"],
    9: ["360", "9"],
    10: ["569", "0"],
    11: ["458", "7"],
    12: ["489", "1"]
},

"19/03/2024": {
    1: ["269", "7"],
    2: ["350", "8"],
    3: ["689", "3"],
    4: ["569", "0"],
    5: ["140", "5"],
    6: ["345", "2"],
    7: ["258", "5"],
    8: ["155", "1"],
    9: ["120", "3"],
    10: ["257", "4"],
    11: ["220", "4"],
    12: ["389", "0"]
},

"20/03/2024": {
    1: ["114", "6"],
    2: ["450", "9"],
    3: ["690", "5"],
    4: ["247", "3"],
    5: ["569", "0"],
    6: ["470", "1"],
    7: ["345", "2"],
    8: ["130", "4"],
    9: ["889", "5"],
    10: ["490", "3"],
    11: ["100", "1"],
    12: ["246", "2"]
},

"21/03/2024": {
    1: ["115", "7"],
    2: ["590", "4"],
    3: ["267", "5"],
    4: ["477", "8"],
    5: ["380", "1"],
    6: ["789", "4"],
    7: ["578", "0"],
    8: ["340", "7"],
    9: ["238", "3"],
    10: ["237", "2"],
    11: ["489", "1"],
    12: ["348", "5"]
},

"22/03/2024": {
    1: ["490", "3"],
    2: ["259", "6"],
    3: ["127", "0"],
    4: ["568", "9"],
    5: ["359", "7"],
    6: ["889", "5"],
    7: ["370", "0"],
    8: ["468", "8"],
    9: ["690", "5"],
    10: ["558", "8"],
    11: ["670", "3"],
    12: ["799", "5"]
},
"23/03/2024": {
    1: ["489", "1"],
    2: ["345", "2"],
    3: ["135", "9"],
    4: ["568", "9"],
    5: ["235", "0"],
    6: ["790", "6"],
    7: ["557", "7"],
    8: ["148", "3"],
    9: ["690", "5"],
    10: ["349", "6"],
    11: ["237", "2"],
    12: ["368", "7"]
},

"24/03/2024": {
    1: ["158", "4"],
    2: ["360", "9"],
    3: ["490", "3"],
    4: ["258", "5"],
    5: ["179", "7"],
    6: ["457", "6"],
    7: ["237", "2"],
    8: ["579", "1"],
    9: ["338", "4"],
    10: ["180", "9"],
    11: ["277", "6"],
    12: ["235", "0"]
},

"25/03/2024": {
    1: ["269", "7"],
    2: ["389", "0"],
    3: ["568", "9"],
    4: ["367", "6"],
    5: ["230", "5"],
    6: ["560", "1"],
    7: ["117", "9"],
    8: ["380", "1"],
    9: ["558", "8"],
    10: ["560", "1"],
    11: ["123", "6"],
    12: ["457", "6"]
},

"26/03/2024": {
    1: ["129", "2"],
    2: ["160", "7"],
    3: ["578", "0"],
    4: ["288", "8"],
    5: ["459", "8"],
    6: ["679", "2"],
    7: ["349", "6"],
    8: ["299", "0"],
    9: ["478", "9"],
    10: ["150", "6"],
    11: ["467", "7"],
    12: ["799", "5"]
},

"27/03/2024": {
    1: ["127", "0"],
    2: ["220", "4"],
    3: ["789", "4"],
    4: ["448", "6"],
    5: ["569", "0"],
    6: ["450", "9"],
    7: ["178", "6"],
    8: ["345", "2"],
    9: ["234", "9"],
    10: ["336", "2"],
    11: ["890", "7"],
    12: ["369", "8"]
},

"28/03/2024": {
    1: ["169", "6"],
    2: ["470", "1"],
    3: ["148", "3"],
    4: ["346", "3"],
    5: ["457", "6"],
    6: ["290", "1"],
    7: ["480", "2"],
    8: ["478", "9"],
    9: ["258", "5"],
    10: ["379", "9"],
    11: ["567", "8"],
    12: ["234", "9"]
},

"29/03/2024": {
    1: ["449", "7"],
    2: ["590", "4"],
    3: ["358", "6"],
    4: ["347", "4"],
    5: ["578", "0"],
    6: ["127", "0"],
    7: ["457", "6"],
    8: ["279", "8"],
    9: ["179", "7"],
    10: ["678", "1"],
    11: ["236", "1"],
    12: ["790", "6"]
},

"30/03/2024": {
    1: ["238", "3"],
    2: ["466", "6"],
    3: ["224", "8"],
    4: ["359", "7"],
    5: ["789", "4"],
    6: ["347", "4"],
    7: ["169", "6"],
    8: ["236", "1"],
    9: ["128", "1"],
    10: ["689", "3"],
    11: ["168", "5"],
    12: ["237", "2"]
},

"31/03/2024": {
    1: ["235", "0"],
    2: ["348", "5"],
    3: ["478", "9"],
    4: ["679", "2"],
    5: ["113", "5"],
    6: ["668", "0"],
    7: ["248", "4"],
    8: ["277", "6"],
    9: ["156", "2"],
    10: ["360", "9"],
    11: ["689", "3"],
    12: ["357", "5"]
},

"01/04/2024": {
    1: ["149", "4"],
    2: ["778", "2"],
    3: ["346", "3"],
    4: ["559", "9"],
    5: ["490", "3"],
    6: ["267", "5"],
    7: ["699", "4"],
    8: ["369", "8"],
    9: ["279", "8"],
    10: ["140", "5"],
    11: ["245", "1"],
    12: ["119", "1"]
},

"02/04/2024": {
    1: ["236", "1"],
    2: ["589", "2"],
    3: ["288", "8"],
    4: ["149", "4"],
    5: ["580", "3"],
    6: ["678", "1"],
    7: ["237", "2"],
    8: ["469", "9"],
    9: ["126", "9"],
    10: ["347", "4"],
    11: ["224", "8"],
    12: ["680", "4"]
},

"03/04/2024": {
    1: ["568", "9"],
    2: ["689", "3"],
    3: ["456", "5"],
    4: ["134", "8"],
    5: ["238", "3"],
    6: ["569", "0"],
    7: ["123", "6"],
    8: ["347", "4"],
    9: ["489", "1"],
    10: ["349", "6"],
    11: ["260", "8"],
    12: ["129", "2"]
},

"04/04/2024": {
    1: ["789", "4"],
    2: ["145", "0"],
    3: ["236", "1"],
    4: ["349", "6"],
    5: ["690", "5"],
    6: ["479", "0"],
    7: ["238", "3"],
    8: ["357", "5"],
    9: ["178", "6"],
    10: ["227", "1"],
    11: ["346", "3"],
    12: ["149", "4"]
},

"05/04/2024": {
    1: ["568", "9"],
    2: ["480", "2"],
    3: ["120", "3"],
    4: ["369", "8"],
    5: ["235", "0"],
    6: ["169", "6"],
    7: ["690", "5"],
    8: ["278", "7"],
    9: ["346", "3"],
    10: ["247", "3"],
    11: ["558", "8"],
    12: ["367", "6"]
},

"06/04/2024": {
    1: ["356", "4"],
    2: ["267", "5"],
    3: ["458", "7"],
    4: ["134", "8"],
    5: ["460", "0"],
    6: ["268", "6"],
    7: ["580", "3"],
    8: ["146", "1"],
    9: ["278", "7"],
    10: ["560", "1"],
    11: ["225", "9"],
    12: ["890", "7"]
},

"07/04/2024": {
    1: ["290", "1"],
    2: ["358", "6"],
    3: ["159", "5"],
    4: ["447", "5"],
    5: ["148", "3"],
    6: ["480", "2"],
    7: ["277", "6"],
    8: ["450", "9"],
    9: ["138", "2"],
    10: ["467", "7"],
    11: ["256", "3"],
    12: ["379", "9"]
}, 
"08/04/2024": {
    1: ["450", "9"],
    2: ["589", "2"],
    3: ["134", "8"],
    4: ["228", "2"],
    5: ["680", "4"],
    6: ["135", "9"],
    7: ["359", "7"],
    8: ["124", "7"],
    9: ["258", "5"],
    10: ["356", "4"],
    11: ["567", "8"],
    12: ["780", "5"]
},

"09/04/2024": {
    1: ["118", "0"],
    2: ["280", "0"],
    3: ["345", "2"],
    4: ["269", "7"],
    5: ["480", "2"],
    6: ["126", "9"],
    7: ["578", "0"],
    8: ["469", "9"],
    9: ["690", "5"],
    10: ["589", "2"],
    11: ["367", "6"],
    12: ["458", "7"]
},

"10/04/2024": {
    1: ["249", "5"],
    2: ["890", "7"],
    3: ["358", "6"],
    4: ["168", "5"],
    5: ["123", "6"],
    6: ["145", "0"],
    7: ["567", "8"],
    8: ["455", "4"],
    9: ["679", "2"],
    10: ["235", "0"],
    11: ["356", "4"],
    12: ["234", "9"]
},

"11/04/2024": {
    1: ["567", "8"],
    2: ["359", "7"],
    3: ["779", "3"],
    4: ["260", "8"],
    5: ["356", "4"],
    6: ["457", "6"],
    7: ["578", "0"],
    8: ["159", "5"],
    9: ["480", "2"],
    10: ["258", "5"],
    11: ["670", "3"],
    12: ["257", "4"]
},

"12/04/2024": {
    1: ["349", "6"],
    2: ["167", "4"],
    3: ["479", "0"],
    4: ["225", "9"],
    5: ["679", "2"],
    6: ["599", "3"],
    7: ["457", "6"],
    8: ["138", "2"],
    9: ["379", "9"],
    10: ["990", "8"],
    11: ["247", "3"],
    12: ["578", "0"]
},

"13/04/2024": {
    1: ["588", "1"],
    2: ["118", "0"],
    3: ["470", "1"],
    4: ["223", "7"],
    5: ["340", "7"],
    6: ["230", "5"],
    7: ["558", "8"],
    8: ["469", "9"],
    9: ["347", "4"],
    10: ["568", "9"],
    11: ["570", "2"],
    12: ["277", "6"]
},

"14/04/2024": {
    1: ["379", "9"],
    2: ["256", "3"],
    3: ["134", "8"],
    4: ["678", "1"],
    5: ["245", "1"],
    6: ["690", "5"],
    7: ["179", "7"],
    8: ["334", "0"],
    9: ["139", "3"],
    10: ["257", "4"],
    11: ["168", "5"],
    12: ["479", "0"]
},

"15/04/2024": {
    1: ["229", "3"],
    2: ["390", "2"],
    3: ["456", "5"],
    4: ["334", "0"],
    5: ["124", "7"],
    6: ["450", "9"],
    7: ["357", "5"],
    8: ["120", "3"],
    9: ["367", "6"],
    10: ["248", "4"],
    11: ["457", "6"],
    12: ["115", "7"]
},

"16/04/2024": {
    1: ["778", "2"],
    2: ["159", "5"],
    3: ["167", "4"],
    4: ["237", "2"],
    5: ["789", "4"],
    6: ["139", "3"],
    7: ["233", "8"],
    8: ["346", "3"],
    9: ["560", "1"],
    10: ["468", "8"],
    11: ["349", "6"],
    12: ["234", "9"]
},

"17/04/2024": {
    1: ["114", "6"],
    2: ["145", "0"],
    3: ["579", "1"],
    4: ["259", "6"],
    5: ["378", "8"],
    6: ["279", "8"],
    7: ["478", "9"],
    8: ["235", "0"],
    9: ["690", "5"],
    10: ["278", "7"],
    11: ["359", "7"],
    12: ["490", "3"]
},

"18/04/2024": {
    1: ["338", "4"],
    2: ["130", "4"],
    3: ["359", "7"],
    4: ["225", "9"],
    5: ["567", "8"],
    6: ["489", "1"],
    7: ["260", "8"],
    8: ["146", "1"],
    9: ["889", "5"],
    10: ["569", "0"],
    11: ["460", "0"],
    12: ["557", "7"]
},

"19/04/2024": {
    1: ["235", "0"],
    2: ["237", "2"],
    3: ["589", "2"],
    4: ["356", "4"],
    5: ["357", "5"],
    6: ["890", "7"],
    7: ["457", "6"],
    8: ["136", "0"],
    9: ["358", "6"],
    10: ["300", "3"],
    11: ["669", "1"],
    12: ["159", "5"]
},"20/04/2024": {
    1: ["189", "8"],
    2: ["350", "8"],
    3: ["148", "3"],
    4: ["550", "0"],
    5: ["470", "1"],
    6: ["235", "0"],
    7: ["456", "5"],
    8: ["388", "9"],
    9: ["670", "3"],
    10: ["379", "9"],
    11: ["468", "8"],
    12: ["245", "1"]
},

"21/04/2024": {
    1: ["690", "5"],
    2: ["330", "6"],
    3: ["259", "6"],
    4: ["458", "7"],
    5: ["389", "0"],
    6: ["140", "5"],
    7: ["237", "2"],
    8: ["158", "4"],
    9: ["789", "4"],
    10: ["480", "2"],
    11: ["137", "1"],
    12: ["245", "1"]
},

"22/04/2024": {
    1: ["379", "9"],
    2: ["590", "4"],
    3: ["678", "1"],
    4: ["247", "3"],
    5: ["779", "3"],
    6: ["168", "5"],
    7: ["560", "1"],
    8: ["449", "7"],
    9: ["230", "5"],
    10: ["589", "2"],
    11: ["790", "6"],
    12: ["129", "2"]
},

"23/04/2024": {
    1: ["490", "3"],
    2: ["124", "7"],
    3: ["224", "8"],
    4: ["168", "5"],
    5: ["257", "4"],
    6: ["350", "8"],
    7: ["579", "1"],
    8: ["347", "4"],
    9: ["668", "0"],
    10: ["479", "0"],
    11: ["268", "6"],
    12: ["780", "5"]
},

"24/04/2024": {
    1: ["460", "0"],
    2: ["699", "4"],
    3: ["569", "0"],
    4: ["245", "1"],
    5: ["790", "6"],
    6: ["178", "6"],
    7: ["500", "5"],
    8: ["388", "9"],
    9: ["357", "5"],
    10: ["478", "9"],
    11: ["560", "1"],
    12: ["140", "5"]
},

"25/04/2024": {
    1: ["136", "0"],
    2: ["270", "9"],
    3: ["448", "6"],
    4: ["370", "0"],
    5: ["568", "9"],
    6: ["377", "7"],
    7: ["367", "6"],
    8: ["589", "2"],
    9: ["336", "2"],
    10: ["236", "1"],
    11: ["468", "8"],
    12: ["457", "6"]
},

"26/04/2024": {
    1: ["480", "2"],
    2: ["779", "3"],
    3: ["680", "4"],
    4: ["455", "4"],
    5: ["256", "3"],
    6: ["145", "0"],
    7: ["360", "9"],
    8: ["168", "5"],
    9: ["247", "3"],
    10: ["389", "0"],
    11: ["456", "5"],
    12: ["360", "9"]
},

"27/04/2024": {
    1: ["178", "6"],
    2: ["570", "2"],
    3: ["239", "4"],
    4: ["168", "5"],
    5: ["348", "5"],
    6: ["123", "6"],
    7: ["789", "4"],
    8: ["224", "8"],
    9: ["279", "8"],
    10: ["115", "7"],
    11: ["158", "4"],
    12: ["359", "7"]
},

"28/04/2024": {
    1: ["230", "5"],
    2: ["229", "3"],
    3: ["379", "9"],
    4: ["150", "6"],
    5: ["236", "1"],
    6: ["119", "1"],
    7: ["470", "1"],
    8: ["240", "6"],
    9: ["250", "7"],
    10: ["556", "6"],
    11: ["680", "4"],
    12: ["480", "2"]
},

"29/04/2024": {
    1: ["466", "6"],
    2: ["170", "8"],
    3: ["180", "9"],
    4: ["258", "5"],
    5: ["128", "1"],
    6: ["124", "7"],
    7: ["569", "0"],
    8: ["278", "7"],
    9: ["349", "6"],
    10: ["568", "9"],
    11: ["458", "7"],
    12: ["346", "3"]
},

"30/04/2024": {
    1: ["450", "9"],
    2: ["129", "2"],
    3: ["357", "5"],
    4: ["467", "7"],
    5: ["270", "9"],
    6: ["478", "9"],
    7: ["260", "8"],
    8: ["168", "5"],
    9: ["157", "3"],
    10: ["389", "0"],
    11: ["677", "0"],
    12: ["780", "5"]
},

"01/05/2024": {
    1: ["360", "9"],
    2: ["140", "5"],
    3: ["455", "4"],
    4: ["778", "2"],
    5: ["567", "8"],
    6: ["669", "1"],
    7: ["248", "4"],
    8: ["347", "4"],
    9: ["234", "9"],
    10: ["378", "8"],
    11: ["340", "7"],
    12: ["116", "8"]
},
"02/05/2024": {
    1: ["250", "7"],
    2: ["129", "2"],
    3: ["468", "8"],
    4: ["356", "4"],
    5: ["234", "9"],
    6: ["135", "9"],
    7: ["469", "9"],
    8: ["336", "2"],
    9: ["678", "1"],
    10: ["138", "2"],
    11: ["226", "0"],
    12: ["146", "1"]
},

"03/05/2024": {
    1: ["156", "2"],
    2: ["556", "6"],
    3: ["457", "6"],
    4: ["366", "5"],
    5: ["680", "4"],
    6: ["256", "3"],
    7: ["790", "6"],
    8: ["577", "9"],
    9: ["246", "2"],
    10: ["139", "3"],
    11: ["379", "9"],
    12: ["257", "4"]
},

"04/05/2024": {
    1: ["600", "6"],
    2: ["137", "1"],
    3: ["140", "5"],
    4: ["247", "3"],
    5: ["337", "3"],
    6: ["234", "9"],
    7: ["169", "6"],
    8: ["237", "2"],
    9: ["459", "8"],
    10: ["189", "8"],
    11: ["340", "7"],
    12: ["470", "1"]
},

"05/05/2024": {
    1: ["340", "7"],
    2: ["136", "0"],
    3: ["678", "1"],
    4: ["260", "8"],
    5: ["147", "2"],
    6: ["360", "9"],
    7: ["257", "4"],
    8: ["228", "2"],
    9: ["130", "4"],
    10: ["478", "9"],
    11: ["267", "5"],
    12: ["889", "5"]
},

"06/05/2024": {
    1: ["357", "5"],
    2: ["567", "8"],
    3: ["277", "6"],
    4: ["350", "8"],
    5: ["356", "4"],
    6: ["125", "8"],
    7: ["229", "3"],
    8: ["679", "2"],
    9: ["340", "7"],
    10: ["237", "2"],
    11: ["110", "2"],
    12: ["245", "1"]
},

"07/05/2024": {
    1: ["390", "2"],
    2: ["489", "1"],
    3: ["244", "0"],
    4: ["137", "1"],
    5: ["279", "8"],
    6: ["468", "8"],
    7: ["890", "7"],
    8: ["258", "5"],
    9: ["780", "5"],
    10: ["690", "5"],
    11: ["259", "6"],
    12: ["370", "0"]
},

"08/05/2024": {
    1: ["135", "9"],
    2: ["460", "0"],
    3: ["789", "4"],
    4: ["139", "3"],
    5: ["590", "4"],
    6: ["344", "1"],
    7: ["170", "8"],
    8: ["240", "6"],
    9: ["456", "5"],
    10: ["468", "8"],
    11: ["457", "6"],
    12: ["359", "7"]
},

"09/05/2024": {
    1: ["379", "9"],
    2: ["258", "5"],
    3: ["228", "2"],
    4: ["458", "7"],
    5: ["179", "7"],
    6: ["445", "3"],
    7: ["390", "2"],
    8: ["268", "6"],
    9: ["480", "2"],
    10: ["588", "1"],
    11: ["579", "1"],
    12: ["490", "3"]
},

"10/05/2024": {
    1: ["890", "7"],
    2: ["367", "6"],
    3: ["668", "0"],
    4: ["250", "7"],
    5: ["125", "8"],
    6: ["289", "9"],
    7: ["770", "4"],
    8: ["356", "4"],
    9: ["149", "4"],
    10: ["115", "7"],
    11: ["249", "5"],
    12: ["113", "5"]
},

"11/05/2024": {
    1: ["150", "6"],
    2: ["346", "3"],
    3: ["336", "2"],
    4: ["689", "3"],
    5: ["459", "8"],
    6: ["460", "0"],
    7: ["480", "2"],
    8: ["290", "1"],
    9: ["129", "2"],
    10: ["227", "1"],
    11: ["155", "1"],
    12: ["120", "3"]
},

"12/05/2024": {
    1: ["790", "6"],
    2: ["130", "4"],
    3: ["199", "9"],
    4: ["450", "9"],
    5: ["224", "8"],
    6: ["550", "0"],
    7: ["590", "4"],
    8: ["136", "0"],
    9: ["129", "2"],
    10: ["560", "1"],
    11: ["127", "0"],
    12: ["125", "8"]
},

"13/05/2024": {
    1: ["450", "9"],
    2: ["780", "5"],
    3: ["136", "0"],
    4: ["157", "3"],
    5: ["569", "0"],
    6: ["233", "8"],
    7: ["566", "7"],
    8: ["350", "8"],
    9: ["459", "8"],
    10: ["389", "0"],
    11: ["268", "6"],
    12: ["114", "6"]
},

"14/05/2024": {
    1: ["288", "8"],
    2: ["145", "0"],
    3: ["169", "6"],
    4: ["356", "4"],
    5: ["268", "6"],
    6: ["480", "2"],
    7: ["378", "8"],
    8: ["119", "1"],
    9: ["150", "6"],
    10: ["389", "0"],
    11: ["468", "8"],
    12: ["370", "0"]
},

"15/05/2024": {
    1: ["449", "7"],
    2: ["128", "1"],
    3: ["590", "4"],
    4: ["346", "3"],
    5: ["225", "9"],
    6: ["140", "5"],
    7: ["469", "9"],
    8: ["268", "6"],
    9: ["220", "4"],
    10: ["680", "4"],
    11: ["126", "9"],
    12: ["139", "3"]
},

"16/05/2024": {
    1: ["138", "2"],
    2: ["169", "6"],
    3: ["790", "6"],
    4: ["680", "4"],
    5: ["278", "7"],
    6: ["120", "3"],
    7: ["157", "3"],
    8: ["339", "5"],
    9: ["223", "7"],
    10: ["489", "1"],
    11: ["460", "0"],
    12: ["134", "8"]
},

"17/05/2024": {
    1: ["990", "8"],
    2: ["160", "7"],
    3: ["569", "0"],
    4: ["250", "7"],
    5: ["579", "1"],
    6: ["379", "9"],
    7: ["239", "4"],
    8: ["135", "9"],
    9: ["347", "4"],
    10: ["289", "9"],
    11: ["125", "8"],
    12: ["178", "6"]
},

"18/05/2024": {
    1: ["459", "8"],
    2: ["110", "2"],
    3: ["157", "3"],
    4: ["226", "0"],
    5: ["159", "5"],
    6: ["124", "7"],
    7: ["389", "0"],
    8: ["345", "2"],
    9: ["460", "0"],
    10: ["456", "5"],
    11: ["230", "5"],
    12: ["458", "7"]
},

"19/05/2024": {
    1: ["129", "2"],
    2: ["235", "0"],
    3: ["269", "7"],
    4: ["150", "6"],
    5: ["358", "6"],
    6: ["360", "9"],
    7: ["489", "1"],
    8: ["280", "0"],
    9: ["334", "0"],
    10: ["112", "4"],
    11: ["347", "4"],
    12: ["688", "2"]
},
"20/05/2024": {
    1: ["168", "5"],
    2: ["338", "4"],
    3: ["134", "8"],
    4: ["300", "3"],
    5: ["279", "8"],
    6: ["348", "5"],
    7: ["267", "5"],
    8: ["590", "4"],
    9: ["344", "1"],
    10: ["245", "1"],
    11: ["158", "4"],
    12: ["488", "0"]
},

"21/05/2024": {
    1: ["350", "8"],
    2: ["268", "6"],
    3: ["115", "7"],
    4: ["680", "4"],
    5: ["480", "2"],
    6: ["233", "8"],
    7: ["129", "2"],
    8: ["358", "6"],
    9: ["259", "6"],
    10: ["124", "7"],
    11: ["299", "0"],
    12: ["113", "5"]
},

"22/05/2024": {
    1: ["468", "8"],
    2: ["389", "0"],
    3: ["150", "6"],
    4: ["270", "9"],
    5: ["456", "5"],
    6: ["689", "3"],
    7: ["128", "1"],
    8: ["249", "5"],
    9: ["660", "2"],
    10: ["257", "4"],
    11: ["148", "3"],
    12: ["568", "9"]
},

"23/05/2024": {
    1: ["234", "9"],
    2: ["147", "2"],
    3: ["449", "7"],
    4: ["349", "6"],
    5: ["479", "0"],
    6: ["140", "5"],
    7: ["338", "4"],
    8: ["245", "1"],
    9: ["126", "9"],
    10: ["477", "8"],
    11: ["238", "3"],
    12: ["578", "0"]
},

"24/05/2024": {
    1: ["289", "9"],
    2: ["368", "7"],
    3: ["240", "6"],
    4: ["579", "1"],
    5: ["345", "2"],
    6: ["236", "1"],
    7: ["247", "3"],
    8: ["789", "4"],
    9: ["479", "0"],
    10: ["780", "5"],
    11: ["237", "2"],
    12: ["340", "7"]
},

"25/05/2024": {
    1: ["479", "0"],
    2: ["160", "7"],
    3: ["348", "5"],
    4: ["270", "9"],
    5: ["119", "1"],
    6: ["346", "3"],
    7: ["233", "8"],
    8: ["690", "5"],
    9: ["589", "2"],
    10: ["157", "3"],
    11: ["125", "8"],
    12: ["246", "2"]
},

"26/05/2024": {
    1: ["116", "8"],
    2: ["178", "6"],
    3: ["280", "0"],
    4: ["470", "1"],
    5: ["347", "4"],
    6: ["300", "3"],
    7: ["679", "2"],
    8: ["334", "0"],
    9: ["346", "3"],
    10: ["560", "1"],
    11: ["590", "4"],
    12: ["369", "8"]
},

"27/05/2024": {
    1: ["478", "9"],
    2: ["226", "0"],
    3: ["356", "4"],
    4: ["223", "7"],
    5: ["159", "5"],
    6: ["146", "1"],
    7: ["578", "0"],
    8: ["490", "3"],
    9: ["450", "9"],
    10: ["330", "6"],
    11: ["258", "5"],
    12: ["358", "6"]
},

"28/05/2024": {
    1: ["227", "1"],
    2: ["240", "6"],
    3: ["589", "2"],
    4: ["479", "0"],
    5: ["367", "6"],
    6: ["249", "5"],
    7: ["115", "7"],
    8: ["480", "2"],
    9: ["135", "9"],
    10: ["239", "4"],
    11: ["116", "8"],
    12: ["270", "9"]
},

"29/05/2024": {
    1: ["578", "0"],
    2: ["166", "3"],
    3: ["167", "4"],
    4: ["358", "6"],
    5: ["247", "3"],
    6: ["449", "7"],
    7: ["189", "8"],
    8: ["234", "9"],
    9: ["236", "1"],
    10: ["468", "8"],
    11: ["380", "1"],
    12: ["122", "5"]
},

"30/05/2024": {
    1: ["480", "2"],
    2: ["125", "8"],
    3: ["190", "0"],
    4: ["149", "4"],
    5: ["550", "0"],
    6: ["335", "1"],
    7: ["246", "2"],
    8: ["150", "6"],
    9: ["389", "0"],
    10: ["225", "9"],
    11: ["790", "6"],
    12: ["469", "9"]
},

"31/05/2024": {
    1: ["469", "9"],
    2: ["248", "4"],
    3: ["500", "5"],
    4: ["290", "1"],
    5: ["468", "8"],
    6: ["359", "7"],
    7: ["148", "3"],
    8: ["269", "7"],
    9: ["134", "8"],
    10: ["370", "0"],
    11: ["458", "7"],
    12: ["589", "2"]
},

"01/06/2024": {
    1: ["240", "6"],
    2: ["117", "9"],
    3: ["226", "0"],
    4: ["246", "2"],
    5: ["169", "6"],
    6: ["560", "1"],
    7: ["234", "9"],
    8: ["770", "4"],
    9: ["129", "2"],
    10: ["369", "8"],
    11: ["123", "6"],
    12: ["390", "2"]
},
"02/06/2024": {
    1: ["237", "2"],
    2: ["470", "1"],
    3: ["458", "7"],
    4: ["358", "6"],
    5: ["145", "0"],
    6: ["580", "3"],
    7: ["166", "3"],
    8: ["460", "0"],
    9: ["233", "8"],
    10: ["136", "0"],
    11: ["167", "4"],
    12: ["367", "6"]
},

"03/06/2024": {
    1: ["179", "7"],
    2: ["279", "8"],
    3: ["388", "9"],
    4: ["113", "5"],
    5: ["456", "5"],
    6: ["189", "8"],
    7: ["880", "6"],
    8: ["345", "2"],
    9: ["780", "5"],
    10: ["356", "4"],
    11: ["480", "2"],
    12: ["120", "3"]
},

"04/06/2024": {
    1: ["578", "0"],
    2: ["159", "5"],
    3: ["123", "6"],
    4: ["440", "8"],
    5: ["149", "4"],
    6: ["369", "8"],
    7: ["377", "7"],
    8: ["155", "1"],
    9: ["560", "1"],
    10: ["178", "6"],
    11: ["336", "2"],
    12: ["137", "1"]
},

"05/06/2024": {
    1: ["570", "2"],
    2: ["134", "8"],
    3: ["229", "3"],
    4: ["170", "8"],
    5: ["490", "3"],
    6: ["388", "9"],
    7: ["580", "3"],
    8: ["144", "9"],
    9: ["469", "9"],
    10: ["890", "7"],
    11: ["556", "6"],
    12: ["678", "1"]
},

"06/06/2024": {
    1: ["180", "9"],
    2: ["245", "1"],
    3: ["359", "7"],
    4: ["238", "3"],
    5: ["357", "5"],
    6: ["140", "5"],
    7: ["300", "3"],
    8: ["446", "4"],
    9: ["590", "4"],
    10: ["258", "5"],
    11: ["347", "4"],
    12: ["779", "3"]
},

"07/06/2024": {
    1: ["689", "3"],
    2: ["366", "5"],
    3: ["467", "7"],
    4: ["116", "8"],
    5: ["299", "0"],
    6: ["168", "5"],
    7: ["150", "6"],
    8: ["235", "0"],
    9: ["127", "0"],
    10: ["248", "4"],
    11: ["336", "2"],
    12: ["568", "9"]
},

"08/06/2024": {
    1: ["578", "0"],
    2: ["344", "1"],
    3: ["245", "1"],
    4: ["124", "7"],
    5: ["249", "5"],
    6: ["569", "0"],
    7: ["350", "8"],
    8: ["225", "9"],
    9: ["138", "2"],
    10: ["667", "9"],
    11: ["459", "8"],
    12: ["288", "8"]
},

"09/06/2024": {
    1: ["360", "9"],
    2: ["125", "8"],
    3: ["300", "3"],
    4: ["689", "3"],
    5: ["127", "0"],
    6: ["246", "2"],
    7: ["489", "1"],
    8: ["239", "4"],
    9: ["128", "1"],
    10: ["346", "3"],
    11: ["126", "9"],
    12: ["280", "0"]
},

"10/06/2024": {
    1: ["460", "0"],
    2: ["188", "7"],
    3: ["260", "8"],
    4: ["579", "1"],
    5: ["225", "9"],
    6: ["178", "6"],
    7: ["257", "4"],
    8: ["348", "5"],
    9: ["189", "8"],
    10: ["390", "2"],
    11: ["238", "3"],
    12: ["679", "2"]
},

"11/06/2024": {
    1: ["110", "2"],
    2: ["167", "4"],
    3: ["259", "6"],
    4: ["160", "7"],
    5: ["389", "0"],
    6: ["450", "9"],
    7: ["558", "8"],
    8: ["335", "1"],
    9: ["150", "6"],
    10: ["378", "8"],
    11: ["367", "6"],
    12: ["770", "4"]
},

"12/06/2024": {
    1: ["478", "9"],
    2: ["588", "1"],
    3: ["280", "0"],
    4: ["339", "5"],
    5: ["445", "3"],
    6: ["135", "9"],
    7: ["358", "6"],
    8: ["260", "8"],
    9: ["379", "9"],
    10: ["490", "3"],
    11: ["236", "1"],
    12: ["589", "2"]
},

"13/06/2024": {
    1: ["190", "0"],
    2: ["160", "7"],
    3: ["469", "9"],
    4: ["169", "6"],
    5: ["590", "4"],
    6: ["448", "6"],
    7: ["235", "0"],
    8: ["399", "1"],
    9: ["580", "3"],
    10: ["256", "3"],
    11: ["134", "8"],
    12: ["289", "9"]
},

"14/06/2024": {
    1: ["139", "3"],
    2: ["799", "5"],
    3: ["149", "4"],
    4: ["890", "7"],
    5: ["577", "9"],
    6: ["267", "5"],
    7: ["336", "2"],
    8: ["237", "2"],
    9: ["330", "6"],
    10: ["246", "2"],
    11: ["350", "8"],
    12: ["889", "5"]
},

"15/06/2024": {
    1: ["348", "5"],
    2: ["127", "0"],
    3: ["500", "5"],
    4: ["689", "3"],
    5: ["580", "3"],
    6: ["470", "1"],
    7: ["788", "3"],
    8: ["230", "5"],
    9: ["100", "1"],
    10: ["349", "6"],
    11: ["128", "1"],
    12: ["190", "0"]
},

"16/06/2024": {
    1: ["157", "3"],
    2: ["499", "2"],
    3: ["225", "9"],
    4: ["147", "2"],
    5: ["380", "1"],
    6: ["112", "4"],
    7: ["579", "1"],
    8: ["149", "4"],
    9: ["240", "6"],
    10: ["389", "0"],
    11: ["159", "5"],
    12: ["250", "7"]
},

"17/06/2024": {
    1: ["569", "0"],
    2: ["169", "6"],
    3: ["245", "1"],
    4: ["158", "4"],
    5: ["699", "4"],
    6: ["230", "5"],
    7: ["688", "2"],
    8: ["557", "7"],
    9: ["233", "8"],
    10: ["130", "4"],
    11: ["124", "7"],
    12: ["390", "2"]
},

"18/06/2024": {
    1: ["369", "8"],
    2: ["458", "7"],
    3: ["145", "0"],
    4: ["228", "2"],
    5: ["166", "3"],
    6: ["460", "0"],
    7: ["690", "5"],
    8: ["448", "6"],
    9: ["256", "3"],
    10: ["588", "1"],
    11: ["136", "0"],
    12: ["239", "4"]
},
"19/06/2024": {
    1: ["237", "2"],
    2: ["338", "4"],
    3: ["144", "9"],
    4: ["135", "9"],
    5: ["669", "1"],
    6: ["568", "9"],
    7: ["368", "7"],
    8: ["169", "6"],
    9: ["236", "1"],
    10: ["235", "0"],
    11: ["137", "1"],
    12: ["889", "5"]
},

"20/06/2024": {
    1: ["477", "8"],
    2: ["136", "0"],
    3: ["360", "9"],
    4: ["129", "2"],
    5: ["178", "6"],
    6: ["588", "1"],
    7: ["120", "3"],
    8: ["144", "9"],
    9: ["779", "3"],
    10: ["490", "3"],
    11: ["156", "2"],
    12: ["569", "0"]
},

"21/06/2024": {
    1: ["139", "3"],
    2: ["455", "4"],
    3: ["156", "2"],
    4: ["490", "3"],
    5: ["380", "1"],
    6: ["267", "5"],
    7: ["159", "5"],
    8: ["126", "9"],
    9: ["458", "7"],
    10: ["589", "2"],
    11: ["389", "0"],
    12: ["450", "9"]
},

"22/06/2024": {
    1: ["457", "6"],
    2: ["346", "3"],
    3: ["260", "8"],
    4: ["780", "5"],
    5: ["116", "8"],
    6: ["358", "6"],
    7: ["280", "0"],
    8: ["449", "7"],
    9: ["370", "0"],
    10: ["356", "4"],
    11: ["179", "7"],
    12: ["249", "5"]
},

"23/06/2024": {
    1: ["127", "0"],
    2: ["270", "9"],
    3: ["267", "5"],
    4: ["380", "1"],
    5: ["230", "5"],
    6: ["235", "0"],
    7: ["137", "1"],
    8: ["340", "7"],
    9: ["346", "3"],
    10: ["460", "0"],
    11: ["238", "3"],
    12: ["279", "8"]
},

"24/06/2024": {
    1: ["490", "3"],
    2: ["235", "0"],
    3: ["567", "8"],
    4: ["679", "2"],
    5: ["233", "8"],
    6: ["590", "4"],
    7: ["114", "6"],
    8: ["145", "0"],
    9: ["589", "2"],
    10: ["377", "7"],
    11: ["140", "5"],
    12: ["367", "6"]
},

"25/06/2024": {
    1: ["289", "9"],
    2: ["135", "9"],
    3: ["344", "1"],
    4: ["237", "2"],
    5: ["889", "5"],
    6: ["277", "6"],
    7: ["456", "5"],
    8: ["112", "4"],
    9: ["378", "8"],
    10: ["168", "5"],
    11: ["347", "4"],
    12: ["236", "1"]
},

"26/06/2024": {
    1: ["149", "4"],
    2: ["190", "0"],
    3: ["249", "5"],
    4: ["790", "6"],
    5: ["468", "8"],
    6: ["380", "1"],
    7: ["778", "2"],
    8: ["568", "9"],
    9: ["479", "0"],
    10: ["569", "0"],
    11: ["138", "2"],
    12: ["268", "6"]
},

"27/06/2024": {
    1: ["114", "6"],
    2: ["679", "2"],
    3: ["457", "6"],
    4: ["599", "3"],
    5: ["127", "0"],
    6: ["478", "9"],
    7: ["280", "0"],
    8: ["355", "3"],
    9: ["223", "7"],
    10: ["450", "9"],
    11: ["167", "4"],
    12: ["233", "8"]
},

"28/06/2024": {
    1: ["118", "0"],
    2: ["130", "4"],
    3: ["366", "5"],
    4: ["380", "1"],
    5: ["239", "4"],
    6: ["379", "9"],
    7: ["267", "5"],
    8: ["680", "4"],
    9: ["139", "3"],
    10: ["567", "8"],
    11: ["689", "3"],
    12: ["346", "3"]
},

"29/06/2024": {
    1: ["237", "2"],
    2: ["147", "2"],
    3: ["160", "7"],
    4: ["169", "6"],
    5: ["156", "2"],
    6: ["150", "6"],
    7: ["370", "0"],
    8: ["114", "6"],
    9: ["179", "7"],
    10: ["590", "4"],
    11: ["125", "8"],
    12: ["130", "4"]
},

"30/06/2024": {
    1: ["458", "7"],
    2: ["260", "8"],
    3: ["229", "3"],
    4: ["168", "5"],
    5: ["480", "2"],
    6: ["148", "3"],
    7: ["220", "4"],
    8: ["780", "5"],
    9: ["357", "5"],
    10: ["244", "0"],
    11: ["467", "7"],
    12: ["345", "2"]
},

"01/07/2024": {
    1: ["189", "8"],
    2: ["560", "1"],
    3: ["448", "6"],
    4: ["225", "9"],
    5: ["140", "5"],
    6: ["277", "6"],
    7: ["457", "6"],
    8: ["690", "5"],
    9: ["279", "8"],
    10: ["469", "9"],
    11: ["566", "7"],
    12: ["226", "0"]
},

"02/07/2024": {
    1: ["369", "8"],
    2: ["589", "2"],
    3: ["180", "9"],
    4: ["278", "7"],
    5: ["167", "4"],
    6: ["556", "6"],
    7: ["148", "3"],
    8: ["668", "0"],
    9: ["446", "4"],
    10: ["370", "0"],
    11: ["166", "3"],
    12: ["257", "4"]
},

"03/07/2024": {
    1: ["230", "5"],
    2: ["346", "3"],
    3: ["179", "7"],
    4: ["350", "8"],
    5: ["117", "9"],
    6: ["569", "0"],
    7: ["130", "4"],
    8: ["349", "6"],
    9: ["679", "2"],
    10: ["680", "4"],
    11: ["169", "6"],
    12: ["467", "7"]
},

"04/07/2024": {
    1: ["269", "7"],
    2: ["245", "1"],
    3: ["115", "7"],
    4: ["580", "3"],
    5: ["389", "0"],
    6: ["170", "8"],
    7: ["126", "9"],
    8: ["289", "9"],
    9: ["390", "2"],
    10: ["224", "8"],
    11: ["458", "7"],
    12: ["136", "0"]
},

"05/07/2024": {
    1: ["338", "4"],
    2: ["157", "3"],
    3: ["366", "5"],
    4: ["249", "5"],
    5: ["145", "0"],
    6: ["348", "5"],
    7: ["570", "2"],
    8: ["378", "8"],
    9: ["227", "1"],
    10: ["357", "5"],
    11: ["579", "1"],
    12: ["160", "7"]
},

"06/07/2024": {
    1: ["144", "9"],
    2: ["330", "6"],
    3: ["178", "6"],
    4: ["558", "8"],
    5: ["168", "5"],
    6: ["258", "5"],
    7: ["336", "2"],
    8: ["457", "6"],
    9: ["129", "2"],
    10: ["550", "0"],
    11: ["570", "2"],
    12: ["279", "8"]
},

"07/07/2024": {
    1: ["130", "4"],
    2: ["400", "4"],
    3: ["166", "3"],
    4: ["470", "1"],
    5: ["680", "4"],
    6: ["357", "5"],
    7: ["245", "1"],
    8: ["160", "7"],
    9: ["258", "5"],
    10: ["137", "1"],
    11: ["140", "5"],
    12: ["120", "3"]
},

"08/07/2024": {
    1: ["123", "6"],
    2: ["229", "3"],
    3: ["127", "0"],
    4: ["780", "5"],
    5: ["458", "7"],
    6: ["117", "9"],
    7: ["124", "7"],
    8: ["239", "4"],
    9: ["157", "3"],
    10: ["467", "7"],
    11: ["669", "1"],
    12: ["478", "9"]
},

"09/07/2024": {
    1: ["366", "5"],
    2: ["679", "2"],
    3: ["388", "9"],
    4: ["233", "8"],
    5: ["159", "5"],
    6: ["445", "3"],
    7: ["268", "6"],
    8: ["356", "4"],
    9: ["378", "8"],
    10: ["578", "0"],
    11: ["367", "6"],
    12: ["256", "3"]
},

"10/07/2024": {
    1: ["220", "4"],
    2: ["350", "8"],
    3: ["557", "7"],
    4: ["259", "6"],
    5: ["678", "1"],
    6: ["134", "8"],
    7: ["148", "3"],
    8: ["240", "6"],
    9: ["580", "3"],
    10: ["234", "9"],
    11: ["459", "8"],
    12: ["159", "5"]
},

"11/07/2024": {
    1: ["390", "2"],
    2: ["248", "4"],
    3: ["670", "3"],
    4: ["133", "7"],
    5: ["457", "6"],
    6: ["360", "9"],
    7: ["560", "1"],
    8: ["279", "8"],
    9: ["230", "5"],
    10: ["149", "4"],
    11: ["368", "7"],
    12: ["237", "2"]
},

"12/07/2024": {
    1: ["340", "7"],
    2: ["144", "9"],
    3: ["150", "6"],
    4: ["356", "4"],
    5: ["488", "0"],
    6: ["349", "6"],
    7: ["244", "0"],
    8: ["790", "6"],
    9: ["166", "3"],
    10: ["339", "5"],
    11: ["136", "0"],
    12: ["257", "4"]
},

"13/07/2024": {
    1: ["370", "0"],
    2: ["233", "8"],
    3: ["169", "6"],
    4: ["290", "1"],
    5: ["356", "4"],
    6: ["110", "2"],
    7: ["560", "1"],
    8: ["200", "2"],
    9: ["579", "1"],
    10: ["470", "1"],
    11: ["499", "2"],
    12: ["116", "8"]
},

"14/07/2024": {
    1: ["889", "5"],
    2: ["137", "1"],
    3: ["680", "4"],
    4: ["223", "7"],
    5: ["467", "7"],
    6: ["578", "0"],
    7: ["170", "8"],
    8: ["348", "5"],
    9: ["147", "2"],
    10: ["270", "9"],
    11: ["456", "5"],
    12: ["269", "7"]
},

"15/07/2024": {
    1: ["157", "3"],
    2: ["250", "7"],
    3: ["336", "2"],
    4: ["134", "8"],
    5: ["470", "1"],
    6: ["389", "0"],
    7: ["225", "9"],
    8: ["167", "4"],
    9: ["447", "5"],
    10: ["158", "4"],
    11: ["235", "0"],
    12: ["168", "5"]
},

"16/07/2024": {
    1: ["450", "9"],
    2: ["569", "0"],
    3: ["259", "6"],
    4: ["367", "6"],
    5: ["160", "7"],
    6: ["246", "2"],
    7: ["380", "1"],
    8: ["566", "7"],
    9: ["224", "8"],
    10: ["128", "1"],
    11: ["445", "3"],
    12: ["112", "4"]
},

"17/07/2024": {
    1: ["339", "5"],
    2: ["390", "2"],
    3: ["489", "1"],
    4: ["266", "4"],
    5: ["469", "9"],
    6: ["347", "4"],
    7: ["145", "0"],
    8: ["378", "8"],
    9: ["157", "3"],
    10: ["346", "3"],
    11: ["789", "4"],
    12: ["156", "2"]
},

"18/07/2024": {
    1: ["139", "3"],
    2: ["468", "8"],
    3: ["360", "9"],
    4: ["236", "1"],
    5: ["179", "7"],
    6: ["690", "5"],
    7: ["457", "6"],
    8: ["125", "8"],
    9: ["136", "0"],
    10: ["460", "0"],
    11: ["279", "8"],
    12: ["347", "4"]
},

"19/07/2024": {
    1: ["340", "7"],
    2: ["126", "9"],
    3: ["490", "3"],
    4: ["355", "3"],
    5: ["578", "0"],
    6: ["990", "8"],
    7: ["289", "9"],
    8: ["389", "0"],
    9: ["137", "1"],
    10: ["227", "1"],
    11: ["447", "5"],
    12: ["140", "5"]
},

"20/07/2024": {
    1: ["146", "1"],
    2: ["280", "0"],
    3: ["379", "9"],
    4: ["258", "5"],
    5: ["349", "6"],
    6: ["457", "6"],
    7: ["580", "3"],
    8: ["133", "7"],
    9: ["234", "9"],
    10: ["368", "7"],
    11: ["578", "0"],
    12: ["240", "6"]
},

"21/07/2024": {
    1: ["137", "1"],
    2: ["159", "5"],
    3: ["180", "9"],
    4: ["779", "3"],
    5: ["188", "7"],
    6: ["477", "8"],
    7: ["390", "2"],
    8: ["469", "9"],
    9: ["378", "8"],
    10: ["359", "7"],
    11: ["500", "5"],
    12: ["270", "9"]
},

"22/07/2024": {
    1: ["226", "0"],
    2: ["179", "7"],
    3: ["233", "8"],
    4: ["567", "8"],
    5: ["346", "3"],
    6: ["230", "5"],
    7: ["148", "3"],
    8: ["480", "2"],
    9: ["150", "6"],
    10: ["139", "3"],
    11: ["459", "8"],
    12: ["368", "7"]
},

"23/07/2024": {
    1: ["245", "1"],
    2: ["488", "0"],
    3: ["556", "6"],
    4: ["126", "9"],
    5: ["450", "9"],
    6: ["224", "8"],
    7: ["135", "9"],
    8: ["337", "3"],
    9: ["236", "1"],
    10: ["258", "5"],
    11: ["350", "8"],
    12: ["456", "5"]
},

"24/07/2024": {
    1: ["189", "8"],
    2: ["223", "7"],
    3: ["479", "0"],
    4: ["268", "6"],
    5: ["370", "0"],
    6: ["589", "2"],
    7: ["156", "2"],
    8: ["790", "6"],
    9: ["489", "1"],
    10: ["238", "3"],
    11: ["270", "9"],
    12: ["168", "5"]
},

"25/07/2024": {
    1: ["780", "5"],
    2: ["568", "9"],
    3: ["128", "1"],
    4: ["678", "1"],
    5: ["229", "3"],
    6: ["478", "9"],
    7: ["250", "7"],
    8: ["334", "0"],
    9: ["169", "6"],
    10: ["228", "7"],
    11: ["379", "9"],
    12: ["270", "9"]
},

"26/07/2024": {
    1: ["126", "9"],
    2: ["237", "2"],
    3: ["670", "3"],
    4: ["350", "8"],
    5: ["269", "7"],
    6: ["248", "4"],
    7: ["124", "7"],
    8: ["225", "9"],
    9: ["112", "4"],
    10: ["289", "9"],
    11: ["580", "3"],
    12: ["448", "6"]
},

"27/07/2024": {
    1: ["800", "8"],
    2: ["280", "0"],
    3: ["566", "7"],
    4: ["267", "5"],
    5: ["289", "9"],
    6: ["245", "1"],
    7: ["447", "5"],
    8: ["159", "5"],
    9: ["147", "2"],
    10: ["336", "2"],
    11: ["677", "0"],
    12: ["110", "2"]
},

"28/07/2024": {
    1: ["380", "1"],
    2: ["559", "9"],
    3: ["135", "9"],
    4: ["345", "2"],
    5: ["880", "6"],
    6: ["489", "1"],
    7: ["170", "8"],
    8: ["680", "4"],
    9: ["257", "4"],
    10: ["358", "6"],
    11: ["155", "4"],
    12: ["355", "3"]
},

"29/07/2024": {
    1: ["590", "4"],
    2: ["359", "7"],
    3: ["255", "2"],
    4: ["260", "8"],
    5: ["458", "7"],
    6: ["579", "1"],
    7: ["237", "2"],
    8: ["677", "0"],
    9: ["240", "6"],
    10: ["140", "5"],
    11: ["556", "6"],
    12: ["137", "1"],
},

};
