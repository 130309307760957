export const SRIDEVI = {
  "12/26/2022 to 01/01/2023": {
    "12/26/2022": ["***", "**", "***"],
    "12/27/2022": ["***", "**", "***"],
    "12/28/2022": ["***", "**", "***"],
    "12/29/2022": ["***", "**", "***"],
    "12/30/2022": ["***", "**", "***"],
    "12/31/2022": ["***", "**", "***"],
    "01/01/2023": [245, 16, 169],
  },
  "01/02/2023 to 01/08/2023": {
    "01/02/2023": [480, 27, 124],
    "01/03/2023": [677, "09", 577],
    "01/04/2023": [120, 36, 448],
    "01/05/2023": [180, 92, 345],
    "01/06/2023": [125, 87, 188],
    "01/07/2023": [338, 41, 236],
    "01/08/2023": [368, 74, 167],
  },
  "01/09/2023 to 01/15/2023": {
    "01/09/2023": [559, 98, 260],
    "01/10/2023": [880, 69, 225],
    "01/11/2023": [489, 12, 200],
    "01/12/2023": [290, 17, 188],
    "01/13/2023": [249, 51, 128],
    "01/14/2023": [460, "03", 256],
    "01/15/2023": [369, 88, 378],
  },
  "01/16/2023 to 01/22/2023": {
    "01/16/2023": [679, 24, 149],
    "01/17/2023": [345, 28, 233],
    "01/18/2023": [380, 13, 689],
    "01/19/2023": [248, 44, 356],
    "01/20/2023": [127, "05", 140],
    "01/21/2023": [568, 90, 578],
    "01/22/2023": [579, 13, 670],
  },
  "01/23/2023 to 01/29/2023": {
    "01/23/2023": [470, 11, 344],
    "01/24/2023": [268, 65, 456],
    "01/25/2023": [147, 20, 145],
    "01/26/2023": ["***", "**", "***"],
    "01/27/2023": ["***", "**", "***"],
    "01/28/2023": [170, 88, 459],
    "01/29/2023": [115, 72, 129],
  },
  "01/30/2023 to 02/05/2023": {
    "01/30/2023": [479, "05", 159],
    "01/31/2023": [189, 84, 220],
    "02/01/2023": [123, 62, 390],
    "02/02/2023": [190, "05", 447],
    "02/03/2023": [670, 32, 138],
    "02/04/2023": [347, 45, 690],
    "02/05/2023": [156, 24, 789],
  },

  "02/06/2023 to 02/12/2023": {
    "02/06/2023": [267, 59, 360],
    "02/07/2023": [249, 54, 356],
    "02/08/2023": [156, 26, 457],
    "02/09/2023": [146, 12, 237],
    "02/10/2023": [288, 80, 668],
    "02/11/2023": [349, 63, 157],
    "02/12/2023": [139, 35, 230],
  },
  "02/13/2023 to 02/19/2023": {
    "02/13/2023": [257, 49, 289],
    "02/14/2023": [778, 29, 135],
    "02/15/2023": [288, 85, 780],
    "02/16/2023": [389, "08", 134],
    "02/17/2023": [125, 86, 600],
    "02/18/2023": [148, 38, 279],
    "02/19/2023": [137, 19, 478],
  },
  "02/20/2023 to 02/26/2023": {
    "02/20/2023": [466, 67, 278],
    "02/21/2023": [457, 65, 177],
    "02/22/2023": [348, 52, 679],
    "02/23/2023": [467, 72, 147],
    "02/24/2023": [235, "07", 368],
    "02/25/2023": [577, 94, 167],
    "02/26/2023": [359, 71, 146],
  },
  "02/27/2023 to 03/05/2023": {
    "02/27/2023": [590, 49, 379],
    "02/28/2023": [358, 68, 468],
    "03/01/2023": [357, 55, 780],
    "03/02/2023": [579, 11, 245],
    "03/03/2023": [570, 20, 460],
    "03/04/2023": [225, 90, 389],
    "03/05/2023": [778, 28, 369],
  },
  "03/06/2023 to 03/12/2023": {
    "03/06/2023": [390, 20, 479],
    "03/07/2023": ["***", "**", "***"],
    "03/08/2023": ["***", "**", "***"],
    "03/09/2023": ["***", "**", "***"],
    "03/10/2023": [278, 76, 169],
    "03/11/2023": [178, 66, 268],
    "03/12/2023": [350, 89, 180],
  },
  "03/13/2023 to 03/19/2023": {
    "03/13/2023": [145, 0, 280],
    "03/14/2023": [337, 39, 234],
    "03/15/2023": [235, "05", 168],
    "03/16/2023": [459, 87, 269],
    "03/17/2023": [345, 28, 125],
    "03/18/2023": [456, 52, 237],
    "03/19/2023": [789, 48, 567],
  },
  "03/20/2023 to 03/26/2023": {
    "03/20/2023": [370, 0, 370],
    "03/21/2023": [158, 46, 790],
    "03/22/2023": [347, 40, 226],
    "03/23/2023": [470, 18, 279],
    "03/24/2023": [138, 23, 580],
    "03/25/2023": [370, "03", 247],
    "03/26/2023": [160, 79, 289],
  },
  "03/27/2023 to 04/02/2023": {
    "03/27/2023": [589, 27, 359],
    "03/28/2023": [137, 13, 247],
    "03/29/2023": [489, 15, 348],
    "03/30/2023": [260, 89, 270],
    "03/31/2023": [158, 42, 147],
    "04/01/2023": [600, 63, 120],
    "04/02/2023": [248, 42, 589],
  },
  "04/03/2023 to 04/09/2023": {
    "04/03/2023": [569, "01", 678],
    "04/04/2023": [370, "01", 399],
    "04/05/2023": [477, 87, 467],
    "04/06/2023": [234, 97, 467],
    "04/07/2023": [366, 55, 230],
    "04/08/2023": [389, "05", 258],
    "04/09/2023": [789, 49, 667],
  },
  "04/10/2023 to 04/16/2023": {
    "04/10/2023": [157, 39, 469],
    "04/11/2023": [134, 80, 280],
    "04/12/2023": [567, 80, 460],
    "04/13/2023": [180, 93, 788],
    "04/14/2023": [126, 97, 458],
    "04/15/2023": [360, 97, 359],
    "04/16/2023": [145, "07", 269],
  },
  "04/17/2023 to 04/23/2023": {
    "04/17/2023": [357, 51, 579],
    "04/18/2023": [680, 45, 348],
    "04/19/2023": [345, 24, 590],
    "04/20/2023": [678, 11, 146],
    "04/21/2023": [167, 40, 190],
    "04/22/2023": [556, 66, 240],
    "04/23/2023": [449, 71, 344],
  },

  "04/24/2023 to 04/30/2023": {
    "04/24/2023": [799, 53, 355],
    "04/25/2023": [477, 88, 116],
    "04/26/2023": [137, 17, 269],
    "04/27/2023": [170, 81, 290],
    "04/28/2023": [578, "07", 377],
    "04/29/2023": [568, 97, 890],
    "04/30/2023": [468, 85, 456],
  },
  "05/01/2023 to 05/07/2023": {
    "05/01/2023": [256, 39, 135],
    "05/02/2023": [260, 88, 378],
    "05/03/2023": [679, 21, 245],
    "05/04/2023": [569, "01", 236],
    "05/05/2023": [239, 40, 389],
    "05/06/2023": [178, 69, 478],
    "05/07/2023": [158, 41, 137],
  },
  "05/08/2023 to 05/14/2023": {
    "05/08/2023": [233, 88, 189],
    "05/09/2023": [159, 51, 128],
    "05/10/2023": [228, 22, 480],
    "05/11/2023": [459, 85, 177],
    "05/12/2023": [700, 72, 129],
    "05/13/2023": [246, 28, 134],
    "05/14/2023": [189, 86, 349],
  },
  "05/15/2023 to 05/21/2023": {
    "05/15/2023": [339, 58, 279],
    "05/16/2023": [356, 40, 136],
    "05/17/2023": [789, 45, 140],
    "05/18/2023": [369, 85, 267],
    "05/19/2023": [448, 66, 259],
    "05/20/2023": [890, 72, 480],
    "05/21/2023": [679, 29, 126],
  },
  "05/22/2023 to 05/28/2023": {
    "05/22/2023": [246, 29, 360],
    "05/23/2023": [460, "03", 689],
    "05/24/2023": [458, 78, 350],
    "05/25/2023": [190, "09", 568],
    "05/26/2023": [128, 18, 468],
    "05/27/2023": [123, 60, 136],
    "05/28/2023": [245, 13, 238],
  },
  "05/29/2023 to 06/04/2023": {
    "05/29/2023": [330, 67, 566],
    "05/30/2023": [256, 30, 145],
    "05/31/2023": [156, 24, 699],
    "06/01/2023": [260, 85, 168],
    "06/02/2023": [680, 42, 129],
    "06/03/2023": [800, 89, 379],
    "06/04/2023": [125, 81, 489],
  },
  "06/05/2023 to 06/11/2023": {
    "06/05/2023": [578, "06", 114],
    "06/06/2023": [239, 47, 278],
    "06/07/2023": [190, "01", 146],
    "06/08/2023": [233, 81, 678],
    "06/09/2023": [890, 75, 140],
    "06/10/2023": [229, 33, 157],
    "06/11/2023": [899, 65, 159],
  },
  "06/12/2023 to 06/18/2023": {
    "06/12/2023": [349, 69, 289],
    "06/13/2023": [234, 98, 260],
    "06/14/2023": [467, 75, 249],
    "06/15/2023": [347, 41, 236],
    "06/16/2023": [377, 78, 369],
    "06/17/2023": [138, 29, 469],
    "06/18/2023": [237, 26, 268],
  },
  "06/19/2023 to 06/25/2023": {
    "06/19/2023": [789, 48, 134],
    "06/20/2023": [357, 55, 267],
    "06/21/2023": [159, 55, 267],
    "06/22/2023": [160, 75, 348],
    "06/23/2023": [178, 67, 278],
    "06/24/2023": [560, 17, 124],
    "06/25/2023": [126, 96, 114],
  },
  "06/26/2023 to 07/02/2023": {
    "06/26/2023": [459, 83, 120],
    "06/27/2023": [479, "08", 125],
    "06/28/2023": [350, 84, 257],
    "06/29/2023": [156, 24, 680],
    "06/30/2023": [147, 23, 346],
    "07/01/2023": [390, 25, 348],
    "07/02/2023": [360, 95, 690],
  },
  "07/03/2023 to 07/09/2023": {
    "07/03/2023": [349, 61, 470],
    "07/04/2023": [667, 94, 239],
    "07/05/2023": [268, 61, 588],
    "07/06/2023": [118, "04", 455],
    "07/07/2023": [779, 32, 110],
    "07/08/2023": [449, 72, 237],
    "07/09/2023": [689, 39, 144],
  },
  "07/10/2023 to 07/16/2023": {
    "07/10/2023": [267, 54, 400],
    "07/11/2023": [379, 94, 149],
    "07/12/2023": [180, 96, 358],
    "07/13/2023": [489, 16, 367],
    "07/14/2023": [469, 96, 457],
    "07/15/2023": [670, 37, 368],
    "07/16/2023": [348, 58, 170],
  },
  "07/17/2023 to 07/23/2023": {
    "07/17/2023": [260, 89, 234],
    "07/18/2023": [599, 33, 148],
    "07/19/2023": [770, 44, 455],
    "07/20/2023": [568, 97, 250],
    "07/21/2023": [678, 19, 126],
    "07/22/2023": [460, "06", 178],
    "07/23/2023": [339, 51, 128],
  },
  "07/24/2023 to 07/30/2023": {
    "07/24/2023": [460, "02", 246],
    "07/25/2023": [677, "07", 160],
    "07/26/2023": [890, 76, 790],
    "07/27/2023": [450, 95, 366],
    "07/28/2023": [247, 38, 134],
    "07/29/2023": [378, 82, 589],
    "07/30/2023": [369, 82, 138],
  },
  "07/31/2023 to 08/06/2023": {
    "07/31/2023": [167, 40, 578],
    "08/01/2023": [269, 79, 135],
    "08/02/2023": [180, 96, 259],
    "08/03/2023": [568, 90, 127],
    "08/04/2023": [258, 55, 690],
    "08/05/2023": [478, 99, 289],
    "08/06/2023": [139, 35, 348],
  },
  "08/07/2023 to 08/13/2023": {
    "08/07/2023": [669, 17, 368],
    "08/08/2023": [124, 70, 280],
    "08/09/2023": [467, 71, 290],
    "08/10/2023": [588, 17, 133],
    "08/11/2023": [234, 93, 157],
    "08/12/2023": [168, 59, 270],
    "08/13/2023": [278, 79, 199],
  },
  "08/14/2023 to 08/20/2023": {
    "08/14/2023": [389, "05", 456],
    "08/15/2023": ["***", "**", "***"],
    "08/16/2023": [340, 73, 490],
    "08/17/2023": [459, 86, 178],
    "08/18/2023": [260, 86, 150],
    "08/19/2023": [479, "08", 189],
    "08/20/2023": [136, "01", 380],
  },
  "08/21/2023 to 08/27/2023": {
    "08/21/2023": [579, 13, 238],
    "08/22/2023": [778, 29, 360],
    "08/23/2023": [235, "06", 466],
    "08/24/2023": [124, 79, 450],
    "08/25/2023": [579, 12, 237],
    "08/26/2023": [390, 29, 379],
    "08/27/2023": [158, 45, 267],
  },
  "08/28/2023 to 09/03/2023": {
    "08/28/2023": [789, 41, 290],
    "08/29/2023": [189, 83, 346],
    "08/30/2023": [359, 70, 280],
    "08/31/2023": [120, 35, 690],
    "09/01/2023": [258, 58, 125],
    "09/02/2023": [257, 46, 268],
    "09/03/2023": [679, 27, 223],
  },
  "09/04/2023 to 09/10/2023": {
    "09/04/2023": [123, 60, 244],
    "09/05/2023": [458, 72, 679],
    "09/06/2023": [266, 41, 137],
    "09/07/2023": [235, "08", 567],
    "09/08/2023": [190, "01", 669],
    "09/09/2023": [370, "08", 350],
    "09/10/2023": [459, 87, 458],
  },
  "09/11/2023 to 09/17/2023": {
    "09/11/2023": [469, 99, 270],
    "09/12/2023": [478, 92, 138],
    "09/13/2023": [189, 82, 129],
    "09/14/2023": [488, "04", 356],
    "09/15/2023": [124, 71, 128],
    "09/16/2023": [345, 22, 480],
    "09/17/2023": [245, 18, 468],
  },
  "09/18/2023 to 09/24/2023": {
    "09/18/2023": [368, 70, 479],
    "09/19/2023": [111, 30, 488],
    "09/20/2023": [236, 16, 169],
    "09/21/2023": [178, 64, 149],
    "09/22/2023": [478, 98, 800],
    "09/23/2023": [335, 13, 346],
    "09/24/2023": [259, 66, 178],
  },
  "09/25/2023 to 10/01/2023": {
    "09/25/2023": [226, "08", 369],
    "09/26/2023": [249, 50, 280],
    "09/27/2023": [147, 29, 234],
    "09/28/2023": [234, 95, 230],
    "09/29/2023": [569, "09", 180],
    "09/30/2023": [157, 38, 125],
    "10/01/2023": [379, 95, 348],
  },
  "10/02/2023 to 10/08/2023": {
    "10/02/2023": [568, 98, 468],
    "10/03/2023": [389, "09", 469],
    "10/04/2023": [447, 58, 378],
    "10/05/2023": [349, 64, 257],
    "10/06/2023": [169, 62, 589],
    "10/07/2023": [367, 62, 156],
    "10/08/2023": [688, 23, 247],
  },
  "10/09/2023 to 10/15/2023": {
    "10/09/2023": [144, 90, 479],
    "10/10/2023": [190, "04", 347],
    "10/11/2023": [167, 49, 126],
    "10/12/2023": [148, 33, 157],
    "10/13/2023": [559, 90, 280],
    "10/14/2023": [158, 42, 570],
    "10/15/2023": [568, 90, 118],
  },
  "10/16/2023 to 10/22/2023": {
    "10/16/2023": [479, "02", 156],
    "10/17/2023": [350, 86, 259],
    "10/18/2023": [140, 57, 179],
    "10/19/2023": [689, 32, 129],
    "10/20/2023": [356, 49, 135],
    "10/21/2023": [169, 63, 247],
    "10/22/2023": [356, 40, 136],
  },
  "10/23/2023 to 10/29/2023": {
    "10/23/2023": [468, 82, 237],
    "10/24/2023": [467, 77, 458],
    "10/25/2023": [127, "03", 120],
    "10/26/2023": [578, "06", 259],
    "10/27/2023": [268, 61, 470],
    "10/28/2023": [340, 71, 236],
    "10/29/2023": [467, 74, 239],
  },
  "10/30/2023 to 11/05/2023": {
    "10/30/2023": [128, 16, 150],
    "10/31/2023": [479, "07", 368],
    "11/01/2023": [690, 54, 699],
    "11/02/2023": [180, 98, 260],
    "11/03/2023": [125, 84, 789],
    "11/04/2023": [459, 84, 590],
    "11/05/2023": [569, "07", 179],
  },
  "11/06/2023 to 11/12/2023": {
    "11/06/2023": [579, 12, 156],
    "11/07/2023": [890, 77, 340],
    "11/08/2023": [179, 71, 489],
    "11/09/2023": [990, 84, 112],
    "11/10/2023": [146, 19, 135],
    "11/11/2023": [357, 58, 567],
    "11/12/2023": [490, 34, 266],
  },
  "11/13/2023 to 11/19/2023": {
    "11/13/2023": [245, 14, 338],
    "11/14/2023": [170, 85, 366],
    "11/15/2023": [170, 80, 226],
    "11/16/2023": [679, 25, 140],
    "11/17/2023": [289, 97, 124],
    "11/18/2023": [689, 30, 145],
    "11/19/2023": [346, 34, 590],
  },
  "11/20/2023 to 11/26/2023": {
    "11/20/2023": [334, "02", 688],
    "11/21/2023": [138, 21, 399],
    "11/22/2023": [479, "06", 899],
    "11/23/2023": [689, 32, 138],
    "11/24/2023": [379, 90, 488],
    "11/25/2023": [147, 29, 126],
    "11/26/2023": [289, 96, 349],
  },
  "11/27/2023 to 12/03/2023": {
    "11/27/2023": [224, 88, 350],
    "11/28/2023": [370, "09", 135],
    "11/29/2023": [127, "06", 367],
    "11/30/2023": [280, "04", 158],
    "12/01/2023": [170, 81, 470],
    "12/02/2023": [347, 45, 348],
    "12/03/2023": [358, 69, 270],
  },
  "12/04/2023 to 12/10/2023": {
    "12/04/2023": [458, 77, 278],
    "12/05/2023": [288, 85, 159],
    "12/06/2023": [246, 23, 779],
    "12/07/2023": [229, 37, 188],
    "12/08/2023": [579, 17, 124],
    "12/09/2023": [299, "05", 267],
    "12/10/2023": [600, 67, 890],
  },
  "12/11/2023 to 12/17/2023": {
    "12/11/2023": [569, "09", 379],
    "12/12/2023": [234, 93, 346],
    "12/13/2023": [136, "05", 447],
    "12/14/2023": [367, 66, 349],
    "12/15/2023": [456, 58, 260],
    "12/16/2023": [137, 17, 368],
    "12/17/2023": [230, 52, 345],
  },
  "12/18/2023 to 12/24/2023": {
    "12/18/2023": [147, 24, 347],
    "12/19/2023": [368, 71, 290],
    "12/20/2023": [180, 93, 157],
    "12/21/2023": [459, 86, 367],
    "12/22/2023": [457, 64, 789],
    "12/23/2023": [280, "04", 167],
    "12/24/2023": [259, 62, 570],
  },
  "12/25/2023 to 12/31/2023": {
    "12/25/2023": [145, "01", 579],
    "12/26/2023": [169, 67, 467],
    "12/27/2023": [158, 44, 130],
    "12/28/2023": [578, "03", 337],
    "12/29/2023": [118, "02", 390],
    "12/30/2023": [220, 41, 588],
    "12/31/2023": [235, "08", 459],
  },
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": [367, 69, 568],
    "01/02/2024": [123, 61, 137],
    "01/03/2024": [336, 27, 124],
    "01/04/2024": [389, "06", 349],
    "01/05/2024": [145, "02", 246],
    "01/06/2024": [358, 61, 128],
    "01/07/2024": [134, 83, 779],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": [578, "06", 367],
    "01/09/2024": [336, 25, 258],
    "01/10/2024": [690, 57, 377],
    "01/11/2024": [370, "03", 689],
    "01/12/2024": [369, 87, 278],
    "01/13/2024": [357, 59, 568],
    "01/14/2024": [178, 66, 556],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": [123, 64, 130],
    "01/16/2024": [115, 72, 390],
    "01/17/2024": [258, 57, 124],
    "01/18/2024": [377, 75, 230],
    "01/19/2024": [247, 38, 378],
    "01/20/2024": [260, 80, 677],
    "01/21/2024": [477, 82, 679],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": [123, 69, 478],
    "01/23/2024": [228, 21, 560],
    "01/24/2024": [350, 83, 247],
    "01/25/2024": [669, 13, 148],
    "01/26/2024": ["***", "**", "***"],
    "01/27/2024": [566, 73, 445],
    "01/28/2024": [470, 13, 256],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": [223, 74, 167],
    "01/30/2024": [136, 0, 389],
    "01/31/2024": [260, 89, 144],
    "02/01/2024": [188, 79, 135],
    "02/02/2024": [146, 17, 359],
    "02/03/2024": [236, 14, 130],
    "02/04/2024": [168, 58, 800],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": [589, 22, 499],
    "02/06/2024": [578, 0, 235],
    "02/07/2024": [568, 95, 456],
    "02/08/2024": [190, "04", 356],
    "02/09/2024": [467, 73, 139],
    "02/10/2024": [250, 74, 590],
    "02/11/2024": [359, 71, 678],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": [290, 32, 467],
    "02/13/2024": [159, 63, 478],
    "02/14/2024": [269, 74, 380],
    "02/15/2024": [578, 83, 465],
    "02/16/2024": [145, "06", 158],
    "02/17/2024": [338, "09", 299],
    "02/18/2024": [570, 17, 333],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": [135, "05", 588],
    "02/20/2024": [568, 92, 189],
    "02/21/2024": [369, "05", 274],
    "02/22/2024": [455, 56, 136],
    "02/23/2024": [245, 99, 187],
    "02/24/2024": [199, 46, 399],
    "02/25/2024": [345, 15, 276],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": [178, "08", 477],
    "02/27/2024": [368, 25, 330],
    "02/28/2024": [379, 21, 689],
    "02/29/2024": [558, 11, 229],
    "03/01/2024": [235, 62, 235],
    "03/02/2024": [479, 38, 179],
    "03/03/2024": [368, 55, 300],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": [127, 86, 370],
    "03/05/2024": [150, 20, 556],
    "03/06/2024": [224, 15, 117],
    "03/07/2024": [570, 62, 222],
    "03/08/2024": [368, 43, 138],
    "03/09/2024": [369, 72, 145],
    "03/10/2024": [146, 53, 800],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": [350, 72, 347],
    "03/12/2024": [259, 96, 699],
    "03/13/2024": [259, 18, 188],
    "03/14/2024": [567, 44, 369],
    "03/15/2024": [139, "05", 199],
    "03/16/2024": [568, 61, 380],
    "03/17/2024": [268, 83, 356],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": [379, 42, 135],
    "03/19/2024": [456, 76, 289],
    "03/20/2024": [347, 95, 279],
    "03/21/2024": [199, 37, 578],
    "03/22/2024": [348, 68, 359],
    "03/23/2024": [357, 17, 126],
    "03/24/2024": [568, 35, 279],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": [458, 99, 144],
    "03/26/2024": [370, 56, 249],
    "03/27/2024": [259, 72, 186],
    "03/28/2024": [589, "02", 168],
    "03/29/2024": [368, 56, 699],
    "03/30/2024": [270, 91, 230],
    "03/31/2024": [479, 67, 145],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": [368, 73, 788],
    "04/02/2024": [133, 72, 147],
    "04/03/2024": [255, 23, 788],
    "04/04/2024": [237, 26, 899],
    "04/05/2024": [899, 63, 247],
    "04/06/2024": [160, 70, 127],
    "04/07/2024": [260, 81, 579],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": [289, 98, 134],
    "04/09/2024": [369, 81, 146],
    "04/10/2024": [150, 61, 245],
    "04/11/2024": [790, 67, 458],
    "04/12/2024": [569, 0, 370],
    "04/13/2024": [168, 52, 570],
    "04/14/2024": [249, 56, 790],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": [357, 52, 156],
    "04/16/2024": [569, "04", 248],
    "04/17/2024": [178, 63, 670],
    "04/18/2024": [468, 87, 250],
    "04/19/2024": [130, 42, 237],
    "04/20/2024": [124, 76, 268],
    "04/21/2024": [233, 89, 270],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": [668, 0, 127],
    "04/23/2024": [370, "08", 134],
    "04/24/2024": [556, 62, 589],
    "04/25/2024": [358, 60, 389],
    "04/26/2024": [600, 68, 567],
    "04/27/2024": [469, 98, 116],
    "04/28/2024": [389, "09", 270],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": [456, 59, 144],
    "04/30/2024": [339, 57, 449],
    "05/01/2024": [158, 48, 477],
    "05/02/2024": [167, 48, 558],
    "05/03/2024": [678, 18, 224],
    "05/04/2024": [479, "03", 689],
    "05/05/2024": [559, 92, 110],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": [169, 65, 780],
    "05/07/2024": [220, 42, 660],
    "05/08/2024": [239, 46, 600],
    "05/09/2024": [366, 57, 458],
    "05/10/2024": [489, 17, 377],
    "05/11/2024": [357, 53, 166],
    "05/12/2024": [360, 95, 159],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": [880, 64, 112],
    "05/14/2024": [570, 29, 577],
    "05/15/2024": [478, 95, 267],
    "05/16/2024": [557, 70, 578],
    "05/17/2024": [346, 37, 467],
    "05/18/2024": [470, 19, 135],
    "05/19/2024": [179, 78, 567],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": [190, "05", 159],
    "05/21/2024": [457, 68, 125],
    "05/22/2024": [245, 13, 580],
    "05/23/2024": [267, 53, 256],
    "05/24/2024": [370, "05", 230],
    "05/25/2024": [236, 13, 599],
    "05/26/2024": [890, 72, 156],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": [147, 23, 346],
    "05/28/2024": [778, 25, 159],
    "05/29/2024": [140, 59, 180],
    "05/30/2024": [669, 16, 448],
    "05/31/2024": [588, 10, 127],
    "06/01/2024": [336, 28, 279],
    "06/02/2024": [179, 76, 259],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": [246, 29, 450],
    "06/04/2024": [678, 16, 367],
    "06/05/2024": [359, 73, 256],
    "06/06/2024": [678, 15, 258],
    "06/07/2024": [690, 51, 290],
    "06/08/2024": [579, 19, 234],
    "06/09/2024": [670, 32, 345],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": [128, 12, 147],
    "06/25/2024": [137, 18, 224],
    "06/26/2024": [147, 28, 350],
    "06/27/2024": [234, 97, 250],
    "06/28/2024": [368, 73, 490],
    "06/29/2024": [250, 76, 178],
    "06/30/2024": [279, 84, 356],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": [780, 51, 560],
    "07/02/2024": [357, 50, 136],
    "07/03/2024": [690, 54, 590],
    "07/04/2024": [147, 21, 155],
    "07/05/2024": [158, 49, 270],
    "07/06/2024": [467, 76, 790],
    "07/07/2024": [140, 57, 269],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": [890, 77, 223],
    "07/09/2024": [800, 88, 468],
    "07/10/2024": [566, 73, 247],
    "07/11/2024": [134, 88, 170],
    "07/12/2024": [360, 91, 579],
    "07/13/2024": [790, 60, 569],
    "07/14/2024": [168, 53, 148],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": [450, 97, 890],
    "07/16/2024": [334, 10, 190],
    "07/17/2024": [459, 81, 669],
    "07/18/2024": [123, 69, 379],
    "07/19/2024": [360, 97, 359],
    "07/20/2024": [168, 57, 269],
    "07/21/2024": [467, 71, 470],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": [168, 51, 579],
    "07/23/2024": [447, 53, 166],
    "07/24/2024": [136, "09", 126],
    "07/25/2024": [170, 83, 157],
    "07/26/2024": [370, "08", 170],
    "07/27/2024": [468, 82, 480],
    "07/28/2024": [356, "48", 369],
  },
};

export const TIMEBAZAR = {
  "01/02/2023 to 01/08/2023": {
    "01/02/2023": [120, 30, 578],
    "01/03/2023": [166, 33, 355],
    "01/04/2023": [390, 25, 447],
    "01/05/2023": [177, 56, 367],
    "01/06/2023": [146, 16, 457],
    "01/07/2023": [120, 31, 290],
    "01/08/2023": ["***", "**", "***"],
  },
  "01/09/2023 to 01/15/2023": {
    "01/09/2023": [450, 98, 350],
    "01/10/2023": [568, 90, 136],
    "01/11/2023": [258, 53, 139],
    "01/12/2023": [457, 66, 358],
    "01/13/2023": [235, "08", 288],
    "01/14/2023": [390, 29, 568],
    "01/15/2023": ["***", "**", "***"],
  },
  "01/16/2023 to 01/22/2023": {
    "01/16/2023": [478, 91, 155],
    "01/17/2023": [589, 25, 267],
    "01/18/2023": [156, 28, 279],
    "01/19/2023": [790, 60, 127],
    "01/20/2023": [456, 55, 249],
    "01/21/2023": [130, 43, 148],
    "01/22/2023": ["***", "**", "***"],
  },
  "01/23/2023 to 01/29/2023": {
    "01/23/2023": [699, 41, 399],
    "01/24/2023": [388, 91, 236],
    "01/25/2023": [256, 33, 120],
    "01/26/2023": ["***", "**", "***"],
    "01/27/2023": [144, 96, 259],
    "01/28/2023": [123, 66, 240],
    "01/29/2023": ["***", "**", "***"],
  },
  "01/30/2023 to 02/05/2023": {
    "01/30/2023": [258, 58, 378],
    "01/31/2023": [468, 89, 126],
    "02/01/2023": [189, 83, 157],
    "02/02/2023": [569, "07", 250],
    "02/03/2023": [236, 13, 238],
    "02/04/2023": [699, 48, 116],
    "02/05/2023": ["***", "**", "***"],
  },
  "02/06/2023 to 02/12/2023": {
    "02/06/2023": [568, 98, 279],
    "02/07/2023": [134, 82, 237],
    "02/08/2023": [126, 94, 789],
    "02/09/2023": [455, 45, 267],
    "02/10/2023": [356, 44, 590],
    "02/11/2023": [140, 56, 600],
    "02/12/2023": ["***", "**", "***"],
  },
  "02/13/2023 to 02/19/2023": {
    "02/13/2023": [278, 73, 148],
    "02/14/2023": [559, 91, 137],
    "02/15/2023": [169, 68, 189],
    "02/16/2023": [389, "01", 470],
    "02/17/2023": [160, 71, 146],
    "02/18/2023": [378, 87, 133],
    "02/19/2023": ["***", "**", "***"],
  },
  "02/20/2023 to 02/26/2023": {
    "02/20/2023": [347, 46, 448],
    "02/21/2023": [380, 11, 560],
    "02/22/2023": [377, 72, 480],
    "02/23/2023": [147, 27, 133],
    "02/24/2023": [336, 26, 556],
    "02/25/2023": [130, 42, 480],
    "02/26/2023": ["***", "**", "***"],
  },
  "02/27/2023 to 03/05/2023": {
    "02/27/2023": [117, 98, 369],
    "02/28/2023": [668, "06", 790],
    "03/01/2023": [246, 24, 158],
    "03/02/2023": [170, 83, 580],
    "03/03/2023": [267, 57, 115],
    "03/04/2023": [468, 87, 890],
    "03/05/2023": ["***", "**", "***"],
  },
  "03/06/2023 to 03/12/2023": {
    "03/06/2023": [369, 84, 239],
    "03/07/2023": [780, 50, 370],
    "03/08/2023": ["***", "**", "***"],
    "03/09/2023": [789, 46, 259],
    "03/10/2023": [780, 53, 256],
    "03/11/2023": [126, 97, 160],
    "03/12/2023": ["***", "**", "***"],
  },
  "03/13/2023 to 03/19/2023": {
    "03/13/2023": [336, 20, 370],
    "03/14/2023": [134, 80, 136],
    "03/15/2023": [668, "09", 289],
    "03/16/2023": [448, 65, 122],
    "03/17/2023": [590, 48, 279],
    "03/18/2023": [669, 10, 118],
    "03/19/2023": ["***", "**", "***"],
  },
  "03/20/2023 to 03/26/2023": {
    "03/20/2023": [479, "04", 158],
    "03/21/2023": [678, 14, 248],
    "03/22/2023": [344, 19, 667],
    "03/23/2023": [390, 20, 118],
    "03/24/2023": [259, 67, 269],
    "03/25/2023": [456, 58, 125],
    "03/26/2023": ["***", "**", "***"],
  },
  "03/27/2023 to 04/02/2023": {
    "03/27/2023": [589, 22, 129],
    "03/28/2023": [677, "05", 780],
    "03/29/2023": [378, 88, 279],
    "03/30/2023": [148, 32, 246],
    "03/31/2023": [280, "09", 577],
    "04/01/2023": [488, 0, 370],
    "04/02/2023": ["***", "**", "***"],
  },
  "04/03/2023 to 04/09/2023": {
    "04/03/2023": [230, 58, 224],
    "04/04/2023": [458, 72, 200],
    "04/05/2023": [377, 73, 346],
    "04/06/2023": [460, "09", 180],
    "04/07/2023": [580, 37, 449],
    "04/08/2023": [457, 64, 789],
    "04/09/2023": ["***", "**", "***"],
  },
  "04/10/2023 to 04/16/2023": {
    "04/10/2023": [146, 67, 240],
    "04/11/2023": [245, 93, 158],
    "04/12/2023": [367, 14, 356],
    "04/13/2023": [390, 70, 217],
    "04/14/2023": [214, 86, 468],
    "04/15/2023": [580, 47, 191],
    "04/16/2023": ["***", "**", "***"],
  },
  "04/17/2023 to 04/23/2023": {
    "04/17/2023": [240, 30, 500],
    "04/18/2023": [569, 56, 246],
    "04/19/2023": [378, 80, 117],
    "04/20/2023": [667, "09", 150],
    "04/21/2023": [458, 83, 284],
    "04/22/2023": [500, 21, 270],
    "04/23/2023": ["***", "**", "***"],
  },
  "04/24/2023 to 04/30/2023": {
    "04/24/2023": [470, 99, 221],
    "04/25/2023": [568, 60, 278],
    "04/26/2023": [354, 52, 220],
    "04/27/2023": [457, "07", 124],
    "04/28/2023": [132, 74, 388],
    "04/29/2023": [569, 81, 137],
    "04/30/2023": ["***", "**", "***"],
  },
  "05/01/2023 to 05/07/2023": {
    "05/01/2023": [460, 19, 400],
    "05/02/2023": [122, 79, 250],
    "05/03/2023": [259, 10, 568],
    "05/04/2023": [500, 37, 482],
    "05/05/2023": [369, 88, 130],
    "05/06/2023": [578, 69, 274],
    "05/07/2023": ["***", "**", "***"],
  },
  "05/08/2023 to 05/14/2023": {
    "05/08/2023": [380, 64, 190],
    "05/09/2023": [490, 59, 278],
    "05/10/2023": [590, 36, 156],
    "05/11/2023": [123, 77, 390],
    "05/12/2023": [468, 84, 230],
    "05/13/2023": [456, 69, 314],
    "05/14/2023": ["***", "**", "***"],
  },
  "05/15/2023 to 05/21/2023": {
    "05/15/2023": [350, 82, 418],
    "05/16/2023": [486, 90, 320],
    "05/17/2023": [459, 72, 138],
    "05/18/2023": [572, 66, 214],
    "05/19/2023": [634, 49, 137],
    "05/20/2023": [468, 58, 244],
    "05/21/2023": ["***", "**", "***"],
  },
  "05/22/2023 to 05/28/2023": {
    "05/22/2023": [246, 91, 279],
    "05/23/2023": [579, 62, 379],
    "05/24/2023": [360, 53, 350],
    "05/25/2023": [420, 89, 286],
    "05/26/2023": [470, 76, 448],
    "05/27/2023": [235, 40, 229],
    "05/28/2023": ["***", "**", "***"],
  },
  "05/29/2023 to 06/04/2023": {
    "05/29/2023": [510, 55, 370],
    "05/30/2023": [250, 70, 310],
    "05/31/2023": [366, 83, 489],
    "06/01/2023": [549, 98, 226],
    "06/02/2023": [468, 69, 369],
    "06/03/2023": [578, 80, 247],
    "06/04/2023": ["***", "**", "***"],
  },
  "06/05/2023 to 06/11/2023": {
    "06/05/2023": [200, 64, 498],
    "06/06/2023": [189, 80, 470],
    "06/07/2023": [370, 29, 379],
    "06/08/2023": [478, 89, 236],
    "06/09/2023": [580, 92, 149],
    "06/10/2023": [489, 74, 269],
    "06/11/2023": ["***", "**", "***"],
  },
  "06/12/2023 to 06/18/2023": {
    "06/12/2023": [420, 84, 350],
    "06/13/2023": [250, 45, 309],
    "06/14/2023": [590, 92, 276],
    "06/15/2023": [144, 55, 280],
    "06/16/2023": [270, 68, 122],
    "06/17/2023": [567, 80, 140],
    "06/18/2023": ["***", "**", "***"],
  },
  "06/19/2023 to 06/25/2023": {
    "06/19/2023": [368, 63, 280],
    "06/20/2023": [230, 79, 299],
    "06/21/2023": [480, 96, 317],
    "06/22/2023": [578, 65, 229],
    "06/23/2023": [340, 45, 489],
    "06/24/2023": [199, 62, 380],
    "06/25/2023": ["***", "**", "***"],
  },
  "06/26/2023 to 07/02/2023": {
    "06/26/2023": [590, 80, 290],
    "06/27/2023": [128, 60, 480],
    "06/28/2023": [250, 94, 367],
    "06/29/2023": [580, 91, 237],
    "06/30/2023": [459, 56, 190],
    "07/01/2023": [498, 77, 258],
    "07/02/2023": ["***", "**", "***"],
  },
  "07/03/2023 to 07/09/2023": {
    "07/03/2023": [320, 99, 400],
    "07/04/2023": [380, 66, 278],
    "07/05/2023": [590, 83, 126],
    "07/06/2023": [590, 92, 170],
    "07/07/2023": [200, 81, 340],
    "07/08/2023": [166, 72, 156],
    "07/09/2023": ["***", "**", "***"],
  },
  "07/10/2023 to 07/16/2023": {
    "07/10/2023": [458, 77, 365],
    "07/11/2023": [233, 95, 490],
    "07/12/2023": [369, 89, 225],
    "07/13/2023": [489, 96, 158],
    "07/14/2023": [350, 62, 319],
    "07/15/2023": [279, 90, 550],
    "07/16/2023": ["***", "**", "***"],
  },
  "07/17/2023 to 07/23/2023": {
    "07/17/2023": [568, 94, 237],
    "07/18/2023": [279, 84, 401],
    "07/19/2023": [154, 72, 298],
    "07/20/2023": [489, 66, 340],
    "07/21/2023": [556, 82, 289],
    "07/22/2023": [366, 95, 252],
    "07/23/2023": ["***", "**", "***"],
  },
  "07/24/2023 to 07/30/2023": {
    "07/24/2023": [278, 89, 490],
    "07/25/2023": [559, 56, 373],
    "07/26/2023": [278, 78, 415],
    "07/27/2023": [489, 69, 280],
    "07/28/2023": [190, 82, 240],
    "07/29/2023": [560, 99, 289],
    "07/30/2023": ["***", "**", "***"],
  },
  "07/31/2023 to 08/06/2023": {
    "07/31/2023": [324, 67, 348],
    "08/01/2023": [490, 95, 268],
    "08/02/2023": [288, 85, 365],
    "08/03/2023": [245, 71, 401],
    "08/04/2023": [378, 56, 299],
    "08/05/2023": [289, 48, 360],
    "08/06/2023": ["***", "**", "***"],
  },
  "08/07/2023 to 08/13/2023": {
    "08/07/2023": [589, 25, 690],
    "08/08/2023": [250, 77, 467],
    "08/09/2023": [399, 10, 235],
    "08/10/2023": [380, 10, 460],
    "08/11/2023": [279, 87, 467],
    "08/12/2023": [580, 32, 147],
    "08/13/2023": ["***", "**", "***"],
  },

  "08/14/2023 to 08/20/2023": {
    "08/14/2023": [134, 86, 178],
    "08/15/2023": ["***", "**", "***"],
    "08/16/2023": [138, 28, 990],
    "08/17/2023": [590, 48, 125],
    "08/18/2023": [156, 25, 249],
    "08/19/2023": [116, 84, 680],
    "08/20/2023": ["***", "**", "***"],
  },

  "08/21/2023 to 08/27/2023": {
    "08/21/2023": ["***", "**", "***"],
    "08/22/2023": [248, 44, 789],
    "08/23/2023": [133, 79, 469],
    "08/24/2023": [229, 35, 456],
    "08/25/2023": [113, 59, 360],
    "08/26/2023": [470, 11, 146],
    "08/27/2023": ["***", "**", "***"],
  },

  "08/28/2023 to 09/03/2023": {
    "08/28/2023": [689, 35, 799],
    "08/29/2023": [378, 82, 246],
    "08/30/2023": [134, 87, 160],
    "08/31/2023": [690, 54, 130],
    "09/01/2023": [139, 31, 489],
    "09/02/2023": [139, 31, 236],
    "09/03/2023": ["***", "**", "***"],
  },

  "09/04/2023 to 09/10/2023": {
    "09/04/2023": [250, 76, 178],
    "09/05/2023": [590, 40, 460],
    "09/06/2023": [489, 13, 256],
    "09/07/2023": [150, 62, 237],
    "09/08/2023": [247, 32, 480],
    "09/09/2023": [180, 97, 377],
    "09/10/2023": ["***", "**", "***"],
  },

  "09/11/2023 to 09/17/2023": {
    "09/11/2023": [357, 59, 234],
    "09/12/2023": [158, 43, 247],
    "09/13/2023": [146, 11, 128],
    "09/14/2023": [279, 84, 167],
    "09/15/2023": [136, "03", 346],
    "09/16/2023": [458, 78, 369],
    "09/17/2023": ["***", "**", "***"],
  },

  "09/18/2023 to 09/24/2023": {
    "09/18/2023": [690, 57, 278],
    "09/19/2023": [114, 64, 680],
    "09/20/2023": [137, 16, 600],
    "09/21/2023": [350, 80, 127],
    "09/22/2023": [116, 81, 128],
    "09/23/2023": [123, 64, 680],
    "09/24/2023": ["***", "**", "***"],
  },

  "09/25/2023 to 10/01/2023": {
    "09/25/2023": [158, 46, 178],
    "09/26/2023": [489, 16, 790],
    "09/27/2023": [370, "08", 279],
    "09/28/2023": [119, 11, 335],
    "09/29/2023": [279, 85, 258],
    "09/30/2023": [150, 60, 460],
    "10/01/2023": ["***", "**", "***"],
  },

  "10/02/2023 to 10/08/2023": {
    "10/02/2023": [170, 80, 299],
    "10/03/2023": [260, 85, 140],
    "10/04/2023": [799, 53, 346],
    "10/05/2023": [459, 84, 347],
    "10/06/2023": [130, 40, 235],
    "10/07/2023": [180, 94, 167],
    "10/08/2023": ["***", "**", "***"],
  },
  "10/09/2023 to 10/15/2023": {
    "10/09/2023": [489, 16, 457],
    "10/10/2023": [469, 92, 390],
    "10/11/2023": [680, 45, 447],
    "10/12/2023": [118, "03", 355],
    "10/13/2023": [244, "06", 899],
    "10/14/2023": [224, 87, 377],
    "10/15/2023": ["***", "**", "***"],
  },

  "10/16/2023 to 10/22/2023": {
    "10/16/2023": [677, "03", 355],
    "10/17/2023": [136, "02", 336],
    "10/18/2023": [137, 18, 134],
    "10/19/2023": [227, 13, 599],
    "10/20/2023": [467, 79, 117],
    "10/21/2023": [235, 0, 569],
    "10/22/2023": ["***", "**", "***"],
  },

  "10/23/2023 to 10/29/2023": {
    "10/23/2023": [889, 56, 268],
    "10/24/2023": [390, 27, 467],
    "10/25/2023": [668, "01", 560],
    "10/26/2023": [157, 36, 259],
    "10/27/2023": [467, 77, 340],
    "10/28/2023": [478, 98, 170],
    "10/29/2023": ["***", "**", "***"],
  },

  "10/30/2023 to 11/05/2023": {
    "10/30/2023": [167, 44, 789],
    "10/31/2023": [389, "01", 236],
    "11/01/2023": [235, "02", 345],
    "11/02/2023": [458, 78, 990],
    "11/03/2023": [700, 71, 470],
    "11/04/2023": [349, 67, 278],
    "11/05/2023": ["***", "**", "***"],
  },

  "11/06/2023 to 11/12/2023": {
    "11/06/2023": [680, 47, 179],
    "11/07/2023": [580, 38, 378],
    "11/08/2023": [228, 26, 349],
    "11/09/2023": [190, 0, 127],
    "11/10/2023": ["***", "**", "***"],
    "11/11/2023": ["***", "**", "***"],
    "11/12/2023": ["***", "**", "***"],
  },

  "11/13/2023 to 11/19/2023": {
    "11/13/2023": ["***", "**", "***"],
    "11/14/2023": ["***", "**", "***"],
    "11/15/2023": ["***", "**", "***"],
    "11/16/2023": ["***", "**", "***"],
    "11/17/2023": ["***", "**", "***"],
    "11/18/2023": ["***", "**", "***"],
    "11/19/2023": ["***", "**", "***"],
  },

  "11/20/2023 to 11/26/2023": {
    "11/20/2023": [269, 78, 125],
    "11/21/2023": [290, 17, 115],
    "11/22/2023": [340, 71, 245],
    "11/23/2023": [500, 50, 280],
    "11/24/2023": [447, 59, 667],
    "11/25/2023": [248, 42, 228],
    "11/26/2023": ["***", "**", "***"],
  },

  "11/27/2023 to 12/03/2023": {
    "11/27/2023": [778, 21, 470],
    "11/28/2023": [356, 44, 220],
    "11/29/2023": [690, 59, 234],
    "11/30/2023": [127, "03", 157],
    "12/01/2023": [280, "03", 157],
    "12/02/2023": [139, 34, 220],
    "12/03/2023": ["***", "**", "***"],
  },

  "12/04/2023 to 12/10/2023": {
    "12/04/2023": [448, 68, 477],
    "12/05/2023": [337, 35, 690],
    "12/06/2023": [290, 11, 489],
    "12/07/2023": [126, 99, 144],
    "12/08/2023": [259, 67, 377],
    "12/09/2023": [114, 69, 126],
    "12/10/2023": ["***", "**", "***"],
  },

  "12/11/2023 to 12/17/2023": {
    "12/11/2023": [120, 33, 346],
    "12/12/2023": [378, 81, 245],
    "12/13/2023": [115, 72, 390],
    "12/14/2023": [460, "02", 129],
    "12/15/2023": [199, 99, 289],
    "12/16/2023": [348, 54, 789],
    "12/17/2023": ["***", "**", "***"],
  },

  "12/18/2023 to 12/24/2023": {
    "12/18/2023": [780, 50, 569],
    "12/19/2023": [145, "09", 234],
    "12/20/2023": [280, "05", 339],
    "12/21/2023": [248, 41, 245],
    "12/22/2023": [800, 84, 257],
    "12/23/2023": [119, 18, 350],
    "12/24/2023": ["***", "**", "***"],
  },

  "12/25/2023 to 12/31/2023": {
    "12/25/2023": [670, 37, 890],
    "12/26/2023": [234, 92, 156],
    "12/27/2023": [167, 40, 190],
    "12/28/2023": [156, 22, 390],
    "12/29/2023": [349, 65, 348],
    "12/30/2023": [156, 27, 359],
    "12/31/2023": ["***", "**", "***"],
  },

  "01/01/2024 to 01/07/2024": {
    "01/01/2024": [146, 13, 166],
    "01/02/2024": [580, 36, 240],
    "01/03/2024": [670, 35, 348],
    "01/04/2024": [249, 59, 225],
    "01/05/2024": [128, 17, 269],
    "01/06/2024": [578, "04", 257],
    "01/07/2024": ["***", "**", "***"],
  },

  "01/08/2024 to 01/14/2024": {
    "01/08/2024": [689, 35, 348],
    "01/09/2024": [280, "06", 240],
    "01/10/2024": [446, 47, 340],
    "01/11/2024": [556, 61, 146],
    "01/12/2024": [156, 23, 670],
    "01/13/2024": [158, 40, 280],
    "01/14/2024": ["***", "**", "***"],
  },

  "01/15/2024 to 01/21/2024": {
    "01/15/2024": [369, 83, 670],
    "01/16/2024": [179, 76, 448],
    "01/17/2024": [370, 0, 578],
    "01/18/2024": [140, 56, 457],
    "01/19/2024": [290, 11, 560],
    "01/20/2024": [356, 44, 257],
    "01/21/2024": ["***", "**", "***"],
  },

  "01/22/2024 to 01/28/2024": {
    "01/22/2024": [119, 17, 449],
    "01/23/2024": [558, 85, 177],
    "01/24/2024": [389, "08", 350],
    "01/25/2024": [569, "03", 229],
    "01/26/2024": ["***", "**", "***"],
    "01/27/2024": [166, 33, 148],
    "01/28/2024": ["***", "**", "***"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": [470, 14, 446],
    "01/30/2024": [166, 32, 679],
    "01/31/2024": [167, 44, 347],
    "02/01/2024": [134, 86, 169],
    "02/02/2024": [238, 37, 467],
    "02/03/2024": [560, 13, 670],
    "02/04/2024": ["***", "**", "***"],
  },

  "02/05/2024 to 02/11/2024": {
    "02/05/2024": [126, 97, 188],
    "02/06/2024": [700, 73, 157],
    "02/07/2024": [227, 16, 330],
    "02/08/2024": [223, 79, 180],
    "02/09/2024": [389, "05", 168],
    "02/10/2024": [578, "08", 134],
    "02/11/2024": ["***", "**", "***"],
  },

  "02/12/2024 to 02/18/2024": {
    "02/12/2024": [190, "07", 458],
    "02/13/2024": [113, 54, 356],
    "02/14/2024": [790, 68, 224],
    "02/15/2024": [457, 67, 359],
    "02/16/2024": [149, 42, 570],
    "02/17/2024": [347, 46, 240],
    "02/18/2024": ["***", "**", "***"],
  },

  "02/19/2024 to 02/25/2024": {
    "02/19/2024": [350, 80, 299],
    "02/20/2024": [557, 71, 344],
    "02/21/2024": [689, 34, 149],
    "02/22/2024": [270, 94, 888],
    "02/23/2024": [119, 16, 259],
    "02/24/2024": [169, 65, 889],
    "02/25/2024": ["***", "**", "***"],
  },

  "02/26/2024 to 03/03/2024": {
    "02/26/2024": [349, 65, 159],
    "02/27/2024": [380, 10, 668],
    "02/28/2024": [347, 43, 148],
    "02/29/2024": [138, 24, 356],
    "03/01/2024": [170, 84, 149],
    "03/02/2024": [145, "03", 689],
    "03/03/2024": ["***", "**", "***"],
  },

  "03/04/2024 to 03/10/2024": {
    "03/04/2024": [379, 94, 167],
    "03/05/2024": [267, 59, 144],
    "03/06/2024": [179, 72, 200],
    "03/07/2024": [458, 71, 146],
    "03/08/2024": [369, 80, 370],
    "03/09/2024": [779, 30, 569],
    "03/10/2024": ["***", "**", "***"],
  },

  "03/11/2024 to 03/17/2024": {
    "03/11/2024": [469, 96, 178],
    "03/12/2024": [249, 50, 280],
    "03/13/2024": [170, 89, 478],
    "03/14/2024": [250, 79, 234],
    "03/15/2024": [449, 71, 344],
    "03/16/2024": [124, 74, 130],
    "03/17/2024": ["***", "**", "***"],
  },

  "03/18/2024 to 03/24/2024": {
    "03/18/2024": [189, 80, 145],
    "03/19/2024": [358, 61, 146],
    "03/20/2024": [149, 40, 118],
    "03/21/2024": [236, 12, 246],
    "03/22/2024": [569, "07", 124],
    "03/23/2024": [258, 59, 559],
    "03/24/2024": ["***", "**", "***"],
  },

  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["***", "**", "***"],
    "03/26/2024": [467, 71, 290],
    "03/27/2024": [160, 74, 257],
    "03/28/2024": [148, 37, 250],
    "03/29/2024": [148, 33, 148],
    "03/30/2024": [130, 49, 360],
    "03/31/2024": ["***", "**", "***"],
  },

  "04/01/2024 to 04/07/2024": {
    "04/01/2024": [480, 24, 239],
    "04/02/2024": [238, 34, 149],
    "04/03/2024": [140, 53, 670],
    "04/04/2024": [155, 12, 499],
    "04/05/2024": [138, 29, 577],
    "04/06/2024": [399, 17, 250],
    "04/07/2024": ["***", "**", "***"],
  },

  "04/08/2024 to 04/14/2024": {
    "04/08/2024": [155, 15, 159],
    "04/09/2024": [247, 38, 279],
    "04/10/2024": [137, 19, 559],
    "04/11/2024": [390, 27, 467],
    "04/12/2024": [270, 95, 230],
    "04/13/2024": [359, 70, 127],
    "04/14/2024": ["***", "**", "***"],
  },

  "04/15/2024 to 04/21/2024": {
    "04/15/2024": [347, 40, 127],
    "04/16/2024": [458, 77, 124],
    "04/17/2024": [234, 96, 169],
    "04/18/2024": [110, 24, 158],
    "04/19/2024": [278, 77, 179],
    "04/20/2024": [456, 55, 357],
    "04/21/2024": ["***", "**", "***"],
  },

  "04/22/2024 to 04/28/2024": {
    "04/22/2024": [250, 70, 280],
    "04/23/2024": [779, 30, 334],
    "04/24/2024": [246, 28, 279],
    "04/25/2024": [489, 12, 200],
    "04/26/2024": [399, 13, 337],
    "04/27/2024": [448, 69, 126],
    "04/28/2024": ["***", "**", "***"],
  },

  "04/29/2024 to 05/05/2024": {
    "04/29/2024": [157, 30, 677],
    "04/30/2024": [189, 85, 140],
    "05/01/2024": [125, 89, 568],
    "05/02/2024": [245, 12, 480],
    "05/03/2024": [268, 64, 347],
    "05/04/2024": [169, 63, 670],
    "05/05/2024": ["***", "**", "***"],
  },

  "05/06/2024 to 05/12/2024": {
    "05/06/2024": [689, 30, 244],
    "05/07/2024": [135, 91, 290],
    "05/08/2024": [255, 25, 357],
    "05/09/2024": [380, 14, 356],
    "05/10/2024": [133, 72, 200],
    "05/11/2024": [389, "04", 347],
    "05/12/2024": ["***", "**", "***"],
  },

  "05/13/2024 to 05/19/2024": {
    "05/13/2024": [469, 95, 339],
    "05/14/2024": [790, 64, 590],
    "05/15/2024": [279, 87, 133],
    "05/16/2024": [137, 17, 890],
    "05/17/2024": [359, 77, 124],
    "05/18/2024": [489, 17, 467],
    "05/19/2024": ["***", "**", "***"],
  },

  "05/20/2024 to 05/26/2024": {
    "05/20/2024": [360, 99, 289],
    "05/21/2024": [500, 59, 135],
    "05/22/2024": [150, 68, 369],
    "05/23/2024": [558, 84, 347],
    "05/24/2024": [445, 31, 146],
    "05/25/2024": [380, 14, 699],
    "05/26/2024": ["***", "**", "***"],
  },

  "05/27/2024 to 06/02/2024": {
    "05/27/2024": [599, 30, 677],
    "05/28/2024": [670, 35, 456],
    "05/29/2024": [118, "02", 147],
    "05/30/2024": [499, 22, 255],
    "05/31/2024": [169, 60, 460],
    "06/01/2024": [179, 73, 139],
    "06/02/2024": ["***", "**", "***"],
  },

  "06/24/2024 to 06/30/2024": {
    "06/24/2024": [169, 69, 450],
    "06/25/2024": [470, 16, 244],
    "06/26/2024": [250, 78, 369],
    "06/27/2024": [356, 45, 140],
    "06/28/2024": [257, 49, 126],
    "06/29/2024": [247, 31, 777],
    "06/30/2024": ["***", "**", "***"],
  },

  "07/01/2024 to 07/07/2024": {
    "07/01/2024": [278, 71, 489],
    "07/02/2024": [238, 31, 335],
    "07/03/2024": [466, 63, 247],
    "07/04/2024": [359, 78, 350],
    "07/05/2024": [590, 49, 117],
    "07/06/2024": [567, 82, 138],
    "07/07/2024": ["***", "**", "***"],
  },

  "07/08/2024 to 07/14/2024": {
    "07/08/2024": [289, 91, 380],
    "07/09/2024": [129, 24, 248],
    "07/10/2024": [157, 30, 190],
    "07/11/2024": [456, 53, 788],
    "07/12/2024": [157, 36, 178],
    "07/13/2024": [245, 12, 660],
    "07/14/2024": ["***", "**", "***"],
  },

  "07/15/2024 to 07/21/2024": {
    "07/15/2024": [679, 20, 190],
    "07/16/2024": [388, 92, 228],
    "07/17/2024": [556, 62, 237],
    "07/18/2024": [199, 95, 456],
    "07/19/2024": [159, 52, 237],
    "07/20/2024": [249, 56, 457],
    "07/21/2024": ["***", "**", "***"],
  },

  "07/22/2024 to 07/28/2024": {
    "07/22/2024": [460, "06", 349],
    "07/23/2024": [400, 43, 788],
    "07/24/2024": [280, "05", 348],
    "07/25/2024": [333, 92, 589],
    "07/26/2024": [788, 34, 455],
    "07/27/2024": [477, 84, 400],
    "07/28/2024": ["***", "**", "***"],
  },
};

export const MADHURDAY = {
  "12/26/2022 to 01/01/2023": {
    "12/26/2022": ["***", "**", "***"],
    "12/27/2022": ["***", "**", "***"],
    "12/28/2022": ["***", "**", "***"],
    "12/29/2022": ["***", "**", "***"],
    "12/30/2022": ["***", "**", "***"],
    "12/31/2022": ["***", "**", "***"],
    "01/01/2023": [270, 92, 679],
  },

  "01/02/2023 to 01/08/2023": {
    "01/02/2023": [450, 90, 145],
    "01/03/2023": [146, 15, 690],
    "01/04/2023": [270, 98, 189],
    "01/05/2023": [289, 90, 370],
    "01/06/2023": [899, 67, 188],
    "01/07/2023": [140, 51, 137],
    "01/08/2023": [579, 14, 680],
  },

  "01/09/2023 to 01/15/2023": {
    "01/09/2023": [166, 32, 336],
    "01/10/2023": [700, 77, 359],
    "01/11/2023": [199, 93, 300],
    "01/12/2023": [134, 89, 117],
    "01/13/2023": [148, 38, 189],
    "01/14/2023": [337, 32, 246],
    "01/15/2023": [148, 37, 557],
  },

  "01/16/2023 to 01/22/2023": {
    "01/16/2023": [236, 19, 117],
    "01/17/2023": [228, 27, 368],
    "01/18/2023": [179, 79, 568],
    "01/19/2023": [255, 24, 220],
    "01/20/2023": [350, 82, 660],
    "01/21/2023": [668, "05", 258],
    "01/22/2023": [345, 28, 369],
  },

  "01/23/2023 to 01/29/2023": {
    "01/23/2023": [190, "03", 247],
    "01/24/2023": [460, "00", 370],
    "01/25/2023": [129, 23, 139],
    "01/26/2023": ["***", "**", "***"],
    "01/27/2023": [556, 63, 779],
    "01/28/2023": [269, 77, 179],
    "01/29/2023": [367, 66, 268],
  },

  "01/30/2023 to 02/05/2023": {
    "01/30/2023": [566, 78, 233],
    "01/31/2023": [890, 70, 136],
    "02/01/2023": [800, 88, 468],
    "02/02/2023": [134, 84, 347],
    "02/03/2023": [357, 56, 268],
    "02/04/2023": [225, 91, 489],
    "02/05/2023": [449, 74, 770],
  },

  "02/06/2023 to 02/12/2023": {
    "02/06/2023": [268, 67, 700],
    "02/07/2023": [469, 95, 122],
    "02/08/2023": [380, 14, 400],
    "02/09/2023": [235, 0, 370],
    "02/10/2023": [459, 88, 170],
    "02/11/2023": [499, 20, 569],
    "02/12/2023": [790, 61, 560],
  },

  "02/13/2023 to 02/19/2023": {
    "02/13/2023": [577, 91, 137],
    "02/14/2023": [370, 0, 578],
    "02/15/2023": [347, 45, 177],
    "02/16/2023": [367, 65, 168],
    "02/17/2023": [457, 65, 348],
    "02/18/2023": [579, 15, 456],
    "02/19/2023": [770, 42, 679],
  },

  "02/20/2023 to 02/26/2023": {
    "02/20/2023": [233, 84, 770],
    "02/21/2023": [360, 97, 124],
    "02/22/2023": [368, 77, 223],
    "02/23/2023": [456, 59, 234],
    "02/24/2023": [357, 56, 448],
    "02/25/2023": [599, 36, 169],
    "02/26/2023": [359, 70, 370],
  },

  "02/27/2023 to 03/05/2023": {
    "02/27/2023": [367, 69, 289],
    "02/28/2023": [567, 82, 390],
    "03/01/2023": [267, 58, 125],
    "03/02/2023": [170, 89, 135],
    "03/03/2023": [137, 17, 188],
    "03/04/2023": [256, 30, 488],
    "03/05/2023": [459, 84, 347],
  },

  "03/06/2023 to 03/12/2023": {
    "03/06/2023": [460, "02", 480],
    "03/07/2023": [114, 68, 233],
    "03/08/2023": ["***", "**", "***"],
    "03/09/2023": [560, 13, 689],
    "03/10/2023": [360, 99, 333],
    "03/11/2023": [449, 75, 159],
    "03/12/2023": [380, 17, 160],
  },

  "03/13/2023 to 03/19/2023": {
    "03/13/2023": [350, 80, 460],
    "03/14/2023": [159, 55, 177],
    "03/15/2023": [366, 52, 147],
    "03/16/2023": [110, 24, 789],
    "03/17/2023": [389, "09", 469],
    "03/18/2023": [146, 13, 139],
    "03/19/2023": [156, 24, 130],
  },
  "03/20/2023 to 03/26/2023": {
    "03/20/2023": [229, 35, 249],
    "03/21/2023": [679, 25, 366],
    "03/22/2023": [139, 32, 156],
    "03/23/2023": [789, 48, 369],
    "03/24/2023": [229, 37, 124],
    "03/25/2023": [349, 60, 578],
    "03/26/2023": [147, 29, 126],
  },

  "03/27/2023 to 04/02/2023": {
    "03/27/2023": [166, 35, 159],
    "03/28/2023": [368, 72, 200],
    "03/29/2023": [348, 53, 599],
    "03/30/2023": [259, 65, 267],
    "03/31/2023": [145, "04", 220],
    "04/01/2023": [569, "02", 336],
    "04/02/2023": [345, 27, 340],
  },

  "04/03/2023 to 04/09/2023": {
    "04/03/2023": [479, "04", 455],
    "04/04/2023": [699, 40, 136],
    "04/05/2023": [789, 49, 568],
    "04/06/2023": [690, 52, 480],
    "04/07/2023": [280, "08", 189],
    "04/08/2023": [125, 80, 389],
    "04/09/2023": [138, 24, 239],
  },

  "04/10/2023 to 04/16/2023": {
    "04/10/2023": [279, 86, 169],
    "04/11/2023": [556, 61, 470],
    "04/12/2023": [227, 17, 124],
    "04/13/2023": [388, 95, 140],
    "04/14/2023": [169, 69, 360],
    "04/15/2023": [180, 93, 148],
    "04/16/2023": [450, 97, 458],
  },

  "04/17/2023 to 04/23/2023": {
    "04/17/2023": [359, 76, 457],
    "04/18/2023": [578, "03", 139],
    "04/19/2023": [670, 35, 780],
    "04/20/2023": [120, 34, 699],
    "04/21/2023": [569, "02", 255],
    "04/22/2023": [245, 19, 568],
    "04/23/2023": [234, 93, 689],
  },

  "04/24/2023 to 04/30/2023": {
    "04/24/2023": [200, 29, 199],
    "04/25/2023": [569, "03", 247],
    "04/26/2023": [335, 10, 136],
    "04/27/2023": [250, 71, 489],
    "04/28/2023": [480, 28, 468],
    "04/29/2023": [340, 77, 467],
    "04/30/2023": [349, 65, 249],
  },

  "05/01/2023 to 05/07/2023": {
    "05/01/2023": [367, 60, 190],
    "05/02/2023": [380, 13, 490],
    "05/03/2023": [229, 39, 388],
    "05/04/2023": [344, 17, 467],
    "05/05/2023": [588, 12, 156],
    "05/06/2023": [112, 42, 237],
    "05/07/2023": [158, 42, 660],
  },

  "05/08/2023 to 05/14/2023": {
    "05/08/2023": [238, 33, 157],
    "05/09/2023": [579, 12, 679],
    "05/10/2023": [689, 33, 247],
    "05/11/2023": [456, 53, 670],
    "05/12/2023": [226, "04", 590],
    "05/13/2023": [579, 16, 150],
    "05/14/2023": [356, 41, 399],
  },

  "05/15/2023 to 05/21/2023": {
    "05/15/2023": [148, 34, 130],
    "05/16/2023": [260, 86, 330],
    "05/17/2023": [148, 32, 246],
    "05/18/2023": [235, "08", 567],
    "05/19/2023": [239, 42, 570],
    "05/20/2023": [256, 30, 136],
    "05/21/2023": [889, 56, 790],
  },

  "05/22/2023 to 05/28/2023": {
    "05/22/2023": [136, "07", 377],
    "05/23/2023": [479, "02", 336],
    "05/24/2023": [670, 35, 168],
    "05/25/2023": [128, 19, 126],
    "05/26/2023": [224, 80, 370],
    "05/27/2023": [127, "09", 234],
    "05/28/2023": [579, 12, 778],
  },

  "05/29/2023 to 06/04/2023": {
    "05/29/2023": [117, 96, 169],
    "05/30/2023": [123, 64, 455],
    "05/31/2023": [779, 39, 469],
    "06/01/2023": [233, 81, 560],
    "06/02/2023": [246, 24, 248],
    "06/03/2023": [390, 28, 468],
    "06/04/2023": [780, 51, 777],
  },

  "06/05/2023 to 06/11/2023": {
    "06/05/2023": [223, 70, 578],
    "06/06/2023": [160, 79, 559],
    "06/07/2023": [149, 42, 660],
    "06/08/2023": [469, 97, 133],
    "06/09/2023": [247, 39, 559],
    "06/10/2023": [166, 32, 480],
    "06/11/2023": [128, 15, 348],
  },

  "06/12/2023 to 06/18/2023": {
    "06/12/2023": [679, 27, 467],
    "06/13/2023": [679, 21, 128],
    "06/14/2023": [244, "07", 278],
    "06/15/2023": [337, 30, 226],
    "06/16/2023": [445, 31, 380],
    "06/17/2023": [379, 95, 500],
    "06/18/2023": [269, 73, 670],
  },

  "06/19/2023 to 06/25/2023": {
    "06/19/2023": [489, 15, 267],
    "06/20/2023": [157, 37, 160],
    "06/21/2023": [233, 89, 234],
    "06/22/2023": [356, 41, 236],
    "06/23/2023": [114, 63, 580],
    "06/24/2023": [148, 39, 117],
    "06/25/2023": [440, 86, 150],
  },

  "06/26/2023 to 07/02/2023": {
    "06/26/2023": [478, 91, 588],
    "06/27/2023": [135, 95, 113],
    "06/28/2023": [679, 22, 499],
    "06/29/2023": [259, 62, 129],
    "06/30/2023": [110, 22, 156],
    "07/01/2023": [458, 79, 135],
    "07/02/2023": [147, 28, 125],
  },

  "07/03/2023 to 07/09/2023": {
    "07/03/2023": [338, 46, 123],
    "07/04/2023": [689, 37, 890],
    "07/05/2023": [356, 44, 167],
    "07/06/2023": [450, 99, 568],
    "07/07/2023": [260, 80, 479],
    "07/08/2023": [990, 84, 789],
    "07/09/2023": [280, "08", 260],
  },

  "07/10/2023 to 07/16/2023": {
    "07/10/2023": [679, 29, 180],
    "07/11/2023": [470, 17, 133],
    "07/12/2023": [460, "03", 580],
    "07/13/2023": [240, 67, 160],
    "07/14/2023": [138, 25, 159],
    "07/15/2023": [158, 40, 578],
    "07/16/2023": [268, 61, 560],
  },

  "07/17/2023 to 07/23/2023": {
    "07/17/2023": [360, 98, 125],
    "07/18/2023": [790, 61, 470],
    "07/19/2023": [268, 68, 440],
    "07/20/2023": [224, 88, 125],
    "07/21/2023": [478, 99, 450],
    "07/22/2023": [600, 67, 124],
    "07/23/2023": [460, "09", 469],
  },

  "07/24/2023 to 07/30/2023": {
    "07/24/2023": [128, 19, 900],
    "07/25/2023": [456, 52, 499],
    "07/26/2023": [799, 52, 589],
    "07/27/2023": [567, 82, 679],
    "07/28/2023": [667, 97, 160],
    "07/29/2023": [130, 42, 147],
    "07/30/2023": [278, 78, 170],
  },

  "07/31/2023 to 08/06/2023": {
    "07/31/2023": [890, 79, 450],
    "08/01/2023": [169, 61, 137],
    "08/02/2023": [589, 23, 238],
    "08/03/2023": [778, 20, 244],
    "08/04/2023": [378, 88, 350],
    "08/05/2023": [690, 51, 489],
    "08/06/2023": [447, 51, 290],
  },

  "08/07/2023 to 08/13/2023": {
    "08/07/2023": [278, 73, 346],
    "08/08/2023": [899, 66, 240],
    "08/09/2023": [489, 15, 555],
    "08/10/2023": [128, 10, 136],
    "08/11/2023": [290, 12, 147],
    "08/12/2023": [220, 43, 689],
    "08/13/2023": [367, 69, 126],
  },

  "08/14/2023 to 08/20/2023": {
    "08/14/2023": [336, 22, 345],
    "08/15/2023": ["***", "**", "***"],
    "08/16/2023": [489, 10, 668],
    "08/17/2023": [880, 69, 577],
    "08/18/2023": [466, 67, 278],
    "08/19/2023": [156, 23, 148],
    "08/20/2023": [457, 67, 250],
  },
  "08/21/2023 to 08/27/2023": {
    "08/21/2023": [180, 96, 466],
    "08/22/2023": [388, 94, 789],
    "08/23/2023": [790, 66, 123],
    "08/24/2023": [677, 0, 136],
    "08/25/2023": [478, 93, 238],
    "08/26/2023": [136, "02", 660],
    "08/27/2023": [140, 59, 469],
  },

  "08/28/2023 to 09/03/2023": {
    "08/28/2023": [688, 27, 133],
    "08/29/2023": [112, 40, 550],
    "08/30/2023": [459, 82, 246],
    "08/31/2023": [456, 54, 590],
    "09/01/2023": [239, 43, 148],
    "09/02/2023": [112, 46, 240],
    "09/03/2023": [688, 20, 226],
  },

  "09/04/2023 to 09/10/2023": {
    "09/04/2023": [559, 92, 589],
    "09/05/2023": [288, 89, 234],
    "09/06/2023": [236, 17, 890],
    "09/07/2023": [489, 17, 890],
    "09/08/2023": [440, 85, 780],
    "09/09/2023": [160, 79, 900],
    "09/10/2023": [350, 88, 369],
  },

  "09/11/2023 to 09/17/2023": {
    "09/11/2023": [670, 31, 137],
    "09/12/2023": [179, 77, 467],
    "09/13/2023": [159, 53, 148],
    "09/14/2023": [456, 58, 224],
    "09/15/2023": [900, 93, 346],
    "09/16/2023": [377, 71, 470],
    "09/17/2023": [578, "03", 120],
  },

  "09/18/2023 to 09/24/2023": {
    "09/18/2023": [370, "05", 348],
    "09/19/2023": [277, 60, 244],
    "09/20/2023": [258, 59, 126],
    "09/21/2023": [135, 92, 570],
    "09/22/2023": [144, 97, 458],
    "09/23/2023": [480, 22, 237],
    "09/24/2023": [255, 29, 234],
  },

  "09/25/2023 to 10/01/2023": {
    "09/25/2023": [569, "01", 100],
    "09/26/2023": [568, 93, 490],
    "09/27/2023": [235, "08", 125],
    "09/28/2023": [789, 42, 129],
    "09/29/2023": [137, 14, 239],
    "09/30/2023": [268, 67, 160],
    "10/01/2023": [267, 54, 356],
  },

  "10/02/2023 to 10/08/2023": {
    "10/02/2023": [669, 15, 159],
    "10/03/2023": [678, 14, 220],
    "10/04/2023": [336, 21, 155],
    "10/05/2023": [170, 86, 349],
    "10/06/2023": [146, 13, 779],
    "10/07/2023": [780, 56, 268],
    "10/08/2023": [579, 13, 148],
  },

  "10/09/2023 to 10/15/2023": {
    "10/09/2023": [118, "06", 448],
    "10/10/2023": [239, 42, 228],
    "10/11/2023": [345, 28, 125],
    "10/12/2023": [249, 58, 125],
    "10/13/2023": [680, 48, 170],
    "10/14/2023": [114, 69, 135],
    "10/15/2023": [139, 34, 248],
  },

  "10/16/2023 to 10/22/2023": {
    "10/16/2023": [145, "06", 448],
    "10/17/2023": [333, 93, 157],
    "10/18/2023": [168, 54, 356],
    "10/19/2023": [379, 91, 335],
    "10/20/2023": [357, 59, 667],
    "10/21/2023": [688, 24, 446],
    "10/22/2023": [679, 23, 355],
  },

  "10/23/2023 to 10/29/2023": {
    "10/23/2023": [389, "06", 169],
    "10/24/2023": [455, 42, 147],
    "10/25/2023": [149, 41, 128],
    "10/26/2023": [356, 48, 125],
    "10/27/2023": [235, "02", 156],
    "10/28/2023": [260, 80, 550],
    "10/29/2023": [347, 46, 259],
  },

  "10/30/2023 to 11/05/2023": {
    "10/30/2023": [190, "04", 680],
    "10/31/2023": [169, 64, 356],
    "11/01/2023": [178, 60, 280],
    "11/02/2023": [570, 26, 240],
    "11/03/2023": [166, 35, 500],
    "11/04/2023": [170, 85, 799],
    "11/05/2023": [458, 70, 460],
  },

  "11/06/2023 to 11/12/2023": {
    "11/06/2023": [699, 43, 779],
    "11/07/2023": [123, 68, 567],
    "11/08/2023": [334, "07", 250],
    "11/09/2023": [166, 37, 179],
    "11/10/2023": ["***", "**", "***"],
    "11/11/2023": ["***", "**", "***"],
    "11/12/2023": ["***", "**", "***"],
  },

  "11/13/2023 to 11/19/2023": {
    "11/13/2023": ["***", "**", "***"],
    "11/14/2023": ["***", "**", "***"],
    "11/15/2023": ["***", "**", "***"],
    "11/16/2023": ["***", "**", "***"],
    "11/17/2023": ["***", "**", "***"],
    "11/18/2023": ["***", "**", "***"],
    "11/19/2023": ["***", "**", "***"],
  },

  "11/20/2023 to 11/26/2023": {
    "11/20/2023": [359, 71, 245],
    "11/21/2023": [489, 11, 470],
    "11/22/2023": [679, 24, 789],
    "11/23/2023": [124, 74, 149],
    "11/24/2023": [125, 86, 358],
    "11/25/2023": [566, 78, 440],
    "11/26/2023": [360, 91, 470],
  },

  "11/27/2023 to 12/03/2023": {
    "11/27/2023": [688, 23, 670],
    "11/28/2023": [468, 88, 459],
    "11/29/2023": [370, "07", 269],
    "11/30/2023": [347, 49, 379],
    "12/01/2023": [257, 45, 267],
    "12/02/2023": [160, 70, 479],
    "12/03/2023": [577, 90, 127],
  },

  "12/04/2023 to 12/10/2023": {
    "12/04/2023": [455, 49, 225],
    "12/05/2023": [357, 56, 790],
    "12/06/2023": [138, 22, 255],
    "12/07/2023": [225, 91, 245],
    "12/08/2023": [279, 81, 146],
    "12/09/2023": [789, 44, 112],
    "12/10/2023": [390, 20, 370],
  },

  "12/11/2023 to 12/17/2023": {
    "12/11/2023": [237, 22, 147],
    "12/12/2023": [899, 67, 700],
    "12/13/2023": [457, 68, 116],
    "12/14/2023": [368, 72, 129],
    "12/15/2023": [468, 81, 669],
    "12/16/2023": [669, 12, 570],
    "12/17/2023": [260, 86, 367],
  },

  "12/18/2023 to 12/24/2023": {
    "12/18/2023": [478, 98, 224],
    "12/19/2023": [447, 50, 136],
    "12/20/2023": [225, 95, 456],
    "12/21/2023": [145, "08", 468],
    "12/22/2023": [145, "03", 238],
    "12/23/2023": [289, 95, 348],
    "12/24/2023": [126, 95, 258],
  },

  "12/25/2023 to 12/31/2023": {
    "12/25/2023": [489, 15, 230],
    "12/26/2023": [235, "05", 258],
    "12/27/2023": [167, 42, 156],
    "12/28/2023": [110, 21, 146],
    "12/29/2023": [138, 20, 668],
    "12/30/2023": [378, 82, 679],
    "12/31/2023": [227, 11, 137],
  },

  "01/01/2024 to 01/07/2024": {
    "01/01/2024": [568, 93, 300],
    "01/02/2024": [233, 82, 480],
    "01/03/2024": [130, 44, 590],
    "01/04/2024": [790, 69, 667],
    "01/05/2024": [457, 60, 235],
    "01/06/2024": [129, 29, 450],
    "01/07/2024": [277, 64, 130],
  },

  "01/08/2024 to 01/14/2024": {
    "01/08/2024": [188, 75, 339],
    "01/09/2024": [188, 73, 148],
    "01/10/2024": [170, 88, 134],
    "01/11/2024": [289, 95, 168],
    "01/12/2024": [190, "01", 678],
    "01/13/2024": [778, 21, 137],
    "01/14/2024": [467, 76, 457],
  },

  "01/15/2024 to 01/21/2024": {
    "01/15/2024": [560, 12, 688],
    "01/16/2024": [288, 85, 889],
    "01/17/2024": [137, 16, 349],
    "01/18/2024": [156, 23, 166],
    "01/19/2024": [158, 42, 147],
    "01/20/2024": [280, "01", 146],
    "01/21/2024": [157, 38, 260],
  },

  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["000", "01", 245],
    "01/23/2024": [445, 30, 244],
    "01/24/2024": [450, 91, 155],
    "01/25/2024": [568, 97, 890],
    "01/26/2024": ["***", "**", "***"],
    "01/28/2024": [590, 40, 145],
    "01/27/2024": [356, 48, 279],
  },

  "01/29/2024 to 02/04/2024": {
    "01/29/2024": [669, 14, 149],
    "01/30/2024": [599, 32, 246],
    "01/31/2024": [137, 14, 356],
    "02/01/2024": [347, 47, 250],
    "02/02/2024": [349, 66, 790],
    "02/03/2024": [116, 83, 490],
    "02/04/2024": [155, 10, 136],
  },

  "02/05/2024 to 02/11/2024": {
    "02/05/2024": [350, 88, 990],
    "02/06/2024": [499, 27, 133],
    "02/07/2024": [589, 21, 470],
    "02/08/2024": [336, 24, 347],
    "02/09/2024": [456, 59, 289],
    "02/10/2024": [190, "09", 469],
    "02/11/2024": [336, 27, 278],
  },

  "02/12/2024 to 02/18/2024": {
    "02/12/2024": [377, 72, 480],
    "02/13/2024": [367, 63, 689],
    "02/14/2024": [579, 10, 190],
    "02/15/2024": [299, "08", 288],
    "02/16/2024": [345, 24, 257],
    "02/17/2024": [369, 81, 137],
    "02/18/2024": [157, 35, 780],
  },

  "02/19/2024 to 02/25/2024": {
    "02/19/2024": [148, 31, 470],
    "02/20/2024": [125, 80, 389],
    "02/21/2024": [489, 17, 223],
    "02/22/2024": [356, 49, 360],
    "02/23/2024": [239, 42, 147],
    "02/24/2024": [260, 88, 369],
    "02/25/2024": [350, 86, 259],
  },

  "02/26/2024 to 03/03/2024": {
    "02/26/2024": [247, 34, 239],
    "02/27/2024": [130, 44, 239],
    "02/28/2024": [346, 30, 479],
    "02/29/2024": [127, "05", 159],
    "03/01/2024": [350, 88, 279],
    "03/02/2024": [550, "02", 156],
    "03/03/2024": [367, 64, 248],
  },

  "03/04/2024 to 03/10/2024": {
    "03/04/2024": [139, 34, 455],
    "03/05/2024": [560, 10, 460],
    "03/06/2024": [349, 62, 156],
    "03/07/2024": [690, 59, 180],
    "03/08/2024": [245, 19, 360],
    "03/09/2024": [667, 98, 233],
    "03/10/2024": [349, 66, 880],
  },

  "03/11/2024 to 03/17/2024": {
    "03/11/2024": [369, 80, 370],
    "03/12/2024": [290, 16, 899],
    "03/13/2024": [457, 69, 234],
    "03/14/2024": [355, 32, 138],
    "03/15/2024": [126, 94, 158],
    "03/16/2024": [447, 56, 349],
    "03/17/2024": [112, 44, 356],
  },

  "03/18/2024 to 03/24/2024": {
    "03/18/2024": [470, 11, 137],
    "03/19/2024": [790, 65, 140],
    "03/20/2024": [477, 80, 127],
    "03/21/2024": [678, 16, 259],
    "03/22/2024": [146, 13, 238],
    "03/23/2024": [890, 73, 166],
    "03/24/2024": [249, 54, 167],
  },

  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["***", "**", "***"],
    "03/27/2024": [360, 91, 137],
    "03/28/2024": [455, 41, 146],
    "03/29/2024": [479, "03", 580],
    "03/30/2024": [299, "09", 135],
    "03/31/2024": [289, 93, 670],
    "03/26/2024": [479, "07", 179],
  },

  "04/01/2024 to 04/07/2024": {
    "04/01/2024": [267, 50, 190],
    "04/02/2024": [124, 71, 245],
    "04/03/2024": [160, 77, 223],
    "04/04/2024": [900, 94, 446],
    "04/05/2024": [990, 87, 269],
    "04/06/2024": [890, 77, 467],
    "04/07/2024": [134, 80, 235],
  },

  "04/08/2024 to 04/14/2024": {
    "04/08/2024": [380, 13, 238],
    "04/09/2024": [249, 52, 147],
    "04/10/2024": [158, 47, 467],
    "04/11/2024": [589, 23, 111],
    "04/12/2024": [256, 32, 345],
    "04/13/2024": [349, 68, 990],
    "04/14/2024": [790, 64, 248],
  },

  "04/15/2024 to 04/21/2024": {
    "04/15/2024": [289, 94, 167],
    "04/16/2024": [245, 19, 199],
    "04/17/2024": [588, 10, 145],
    "04/18/2024": [228, 23, 346],
    "04/19/2024": [180, 92, 499],
    "04/20/2024": [800, 86, 600],
    "04/21/2024": [120, 39, 135],
  },

  "04/22/2024 to 04/28/2024": {
    "04/22/2024": [299, "02", 679],
    "04/23/2024": [138, 25, 249],
    "04/24/2024": [349, 68, 800],
    "04/25/2024": [489, 16, 367],
    "04/26/2024": [479, 0, 677],
    "04/27/2024": [237, 24, 356],
    "04/28/2024": [359, 74, 347],
  },

  "04/29/2024 to 05/05/2024": {
    "04/29/2024": [577, 94, 770],
    "04/30/2024": [266, 45, 249],
    "05/01/2024": [139, 38, 800],
    "05/02/2024": [112, 42, 138],
    "05/03/2024": [567, 87, 458],
    "05/04/2024": [579, 19, 379],
    "05/05/2024": [339, 51, 290],
  },

  "05/06/2024 to 05/12/2024": {
    "05/06/2024": [269, 71, 399],
    "05/07/2024": [688, 21, 560],
    "05/08/2024": [118, "08", 459],
    "05/09/2024": [450, 97, 269],
    "05/10/2024": [356, 46, 899],
    "05/11/2024": [112, 44, 239],
    "05/12/2024": [578, "08", 567],
  },

  "05/13/2024 to 05/19/2024": {
    "05/13/2024": [377, 72, 570],
    "05/14/2024": [356, 47, 124],
    "05/15/2024": [226, "09", 568],
    "05/16/2024": [558, 86, 240],
    "05/17/2024": [178, 67, 700],
    "05/18/2024": [790, 65, 500],
    "05/19/2024": [389, "07", 188],
  },

  "05/20/2024 to 05/26/2024": {
    "05/20/2024": [600, 65, 348],
    "05/21/2024": [188, 79, 117],
    "05/22/2024": [380, 12, 778],
    "05/23/2024": [156, 21, 678],
    "05/24/2024": [677, "06", 169],
    "05/25/2024": [133, 77, 359],
    "05/26/2024": [799, 51, 146],
  },

  "05/27/2024 to 06/02/2024": {
    "05/27/2024": [290, 11, 128],
    "05/28/2024": [148, 31, 489],
    "05/29/2024": [125, 82, 147],
    "05/30/2024": [468, 82, 156],
    "05/31/2024": [357, 51, 344],
    "06/01/2024": [356, 46, 367],
    "06/02/2024": [125, 85, 690],
  },

  "06/03/2024 to 06/09/2024": {
    "06/03/2024": [260, 87, 269],
    "06/04/2024": [350, 82, 570],
    "06/05/2024": [678, 12, 390],
    "06/06/2024": [490, 32, 480],
    "06/07/2024": [360, 91, 236],
    "06/08/2024": [239, 42, 679],
    "06/09/2024": [470, 14, 257],
  },

  "06/24/2024 to 06/30/2024": {
    "06/24/2024": [478, 90, 569],
    "06/25/2024": [250, 71, 334],
    "06/26/2024": [457, 69, 234],
    "06/27/2024": [118, "08", 558],
    "06/28/2024": [125, 82, 688],
    "06/29/2024": [134, 81, 119],
    "06/30/2024": [268, 68, 480],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["688", "27", "566"],
    "07/02/2024": ["679", "23", "157"],
    "07/03/2024": ["130", "42", "480"],
    "07/04/2024": ["900", "99", "180"],
    "07/05/2024": ["367", "68", "468"],
    "07/06/2024": ["568", "97", "467"],
    "07/07/2024": ["250", "79", "289"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["578", "01", "334"],
    "07/09/2024": ["788", "36", "367"],
    "07/10/2024": ["129", "26", "556"],
    "07/11/2024": ["789", "48", "116"],
    "07/12/2024": ["389", "05", "357"],
    "07/13/2024": ["370", "08", "477"],
    "07/14/2024": ["499", "27", "278"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["680", "42", "138"],
    "07/16/2024": ["588", "10", "235"],
    "07/17/2024": ["500", "51", "470"],
    "07/18/2024": ["238", "30", "145"],
    "07/19/2024": ["466", "66", "244"],
    "07/20/2024": ["180", "93", "337"],
    "07/21/2024": ["237", "24", "149"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["249", "53", "670"],
    "07/23/2024": ["267", "58", "170"],
    "07/24/2024": ["190", "03", "580"],
    "07/25/2024": ["134", "81", "579"],
    "07/26/2024": ["800", "81", "588"],
    "07/27/2024": ["899", "63", "120"],
    "07/28/2024": ["459", "80", "226"],
  },
};
export const MADHURNIGHT = {
  "01/02/2023 to 01/08/2023": {
    "01/02/2023": ["355", "32", "255"],
    "01/03/2023": ["450", "93", "490"],
    "01/04/2023": ["147", "26", "358"],
    "01/05/2023": ["450", "98", "369"],
    "01/06/2023": ["115", "77", "269"],
    "01/07/2023": ["124", "73", "238"],
    "01/08/2023": ["***", "**", "***"],
  },
  "01/09/2023 to 01/15/2023": {
    "01/09/2023": ["335", "17", "467"],
    "01/10/2023": ["467", "76", "899"],
    "01/11/2023": ["234", "98", "567"],
    "01/12/2023": ["125", "88", "116"],
    "01/13/2023": ["190", "04", "239"],
    "01/14/2023": ["400", "47", "269"],
    "01/15/2023": ["***", "**", "***"],
  },
  "01/16/2023 to 01/22/2023": {
    "01/16/2023": ["368", "72", "147"],
    "01/17/2023": ["899", "69", "180"],
    "01/18/2023": ["256", "31", "290"],
    "01/19/2023": ["146", "15", "122"],
    "01/20/2023": ["570", "20", "389"],
    "01/21/2023": ["399", "16", "268"],
    "01/22/2023": ["***", "**", "***"],
  },
  "01/23/2023 to 01/29/2023": {
    "01/23/2023": ["489", "12", "589"],
    "01/24/2023": ["389", "01", "137"],
    "01/25/2023": ["100", "10", "460"],
    "01/26/2023": ["***", "**", "***"],
    "01/27/2023": ["490", "36", "330"],
    "01/28/2023": ["190", "03", "148"],
    "01/29/2023": ["***", "**", "***"],
  },
  "01/30/2023 to 02/05/2023": {
    "01/30/2023": ["236", "12", "138"],
    "01/31/2023": ["169", "61", "335"],
    "02/01/2023": ["770", "45", "267"],
    "02/02/2023": ["338", "40", "479"],
    "02/03/2023": ["689", "38", "440"],
    "02/04/2023": ["455", "44", "239"],
    "02/05/2023": ["***", "**", "***"],
  },
  "02/06/2023 to 02/12/2023": {
    "02/06/2023": ["457", "66", "790"],
    "02/07/2023": ["990", "88", "279"],
    "02/08/2023": ["356", "45", "447"],
    "02/09/2023": ["125", "86", "150"],
    "02/10/2023": ["299", "06", "556"],
    "02/11/2023": ["160", "71", "137"],
    "02/12/2023": ["***", "**", "***"],
  },
  "02/13/2023 to 02/19/2023": {
    "02/13/2023": ["230", "50", "389"],
    "02/14/2023": ["239", "49", "270"],
    "02/15/2023": ["800", "84", "239"],
    "02/16/2023": ["168", "52", "246"],
    "02/17/2023": ["340", "71", "470"],
    "02/18/2023": ["480", "24", "167"],
    "02/19/2023": ["***", "**", "***"],
  },
  "02/20/2023 to 02/26/2023": {
    "02/20/2023": ["237", "23", "580"],
    "02/21/2023": ["256", "31", "245"],
    "02/22/2023": ["488", "07", "890"],
    "02/23/2023": ["569", "05", "258"],
    "02/24/2023": ["346", "37", "124"],
    "02/25/2023": ["889", "50", "569"],
    "02/26/2023": ["***", "**", "***"],
  },
  "02/27/2023 to 03/05/2023": {
    "02/27/2023": ["146", "16", "123"],
    "02/28/2023": ["477", "82", "138"],
    "03/01/2023": ["669", "15", "447"],
    "03/02/2023": ["158", "42", "688"],
    "03/03/2023": ["367", "62", "138"],
    "03/04/2023": ["130", "49", "667"],
    "03/05/2023": ["***", "**", "***"],
  },
  "03/06/2023 to 03/12/2023": {
    "03/06/2023": ["146", "16", "123"],
    "03/07/2023": ["477", "82", "138"],
    "03/08/2023": ["***", "**", "***"],
    "03/09/2023": ["238", "32", "129"],
    "03/10/2023": ["126", "90", "569"],
    "03/11/2023": ["458", "79", "180"],
    "03/12/2023": ["***", "**", "***"],
  },
  "03/13/2023 to 03/19/2023": {
    "03/13/2023": ["990", "83", "120"],
    "03/14/2023": ["458", "78", "369"],
    "03/15/2023": ["147", "25", "168"],
    "03/16/2023": ["377", "75", "799"],
    "03/17/2023": ["348", "59", "126"],
    "03/18/2023": ["350", "88", "459"],
    "03/19/2023": ["***", "**", "***"],
  },
  "03/20/2023 to 03/26/2023": {
    "03/20/2023": ["389", "01", "669"],
    "03/21/2023": ["570", "25", "799"],
    "03/22/2023": ["446", "43", "670"],
    "03/23/2023": ["690", "58", "170"],
    "03/24/2023": ["170", "84", "130"],
    "03/25/2023": ["679", "21", "470"],
    "03/26/2023": ["***", "**", "***"],
  },
  "03/27/2023 to 04/02/2023": {
    "03/27/2023": ["248", "40", "479"],
    "03/28/2023": ["258", "56", "259"],
    "03/29/2023": ["390", "20", "370"],
    "03/30/2023": ["130", "44", "356"],
    "03/31/2023": ["240", "62", "129"],
    "04/01/2023": ["356", "40", "677"],
    "04/02/2023": ["***", "**", "***"],
  },
  "04/03/2023 to 04/09/2023": {
    "04/03/2023": ["389", "07", "700"],
    "04/04/2023": ["248", "45", "357"],
    "04/05/2023": ["158", "40", "136"],
    "04/06/2023": ["389", "04", "130"],
    "04/07/2023": ["360", "97", "890"],
    "04/08/2023": ["477", "80", "460"],
    "04/09/2023": ["***", "**", "***"],
  },
  "04/10/2023 to 04/16/2023": {
    "04/10/2023": ["234", "95", "249"],
    "04/11/2023": ["559", "92", "679"],
    "04/12/2023": ["348", "51", "470"],
    "04/13/2023": ["115", "79", "379"],
    "04/14/2023": ["337", "31", "380"],
    "04/15/2023": ["249", "56", "349"],
    "04/16/2023": ["***", "**", "***"],
  },
  "04/17/2023 to 04/23/2023": {
    "04/17/2023": ["380", "15", "113"],
    "04/18/2023": ["166", "32", "589"],
    "04/19/2023": ["133", "78", "350"],
    "04/20/2023": ["129", "22", "336"],
    "04/21/2023": ["677", "09", "478"],
    "04/22/2023": ["579", "13", "238"],
    "04/23/2023": ["***", "**", "***"],
  },
  "04/24/2023 to 04/30/2023": {
    "04/24/2023": ["577", "97", "890"],
    "04/25/2023": ["225", "92", "660"],
    "04/26/2023": ["335", "16", "268"],
    "04/27/2023": ["357", "50", "578"],
    "04/28/2023": ["445", "33", "247"],
    "04/29/2023": ["780", "50", "226"],
    "04/30/2023": ["***", "**", "***"],
  },
  "05/01/2023 to 05/07/2023": {
    "05/01/2023": ["260", "85", "168"],
    "05/02/2023": ["169", "67", "377"],
    "05/03/2023": ["134", "83", "120"],
    "05/04/2023": ["357", "54", "112"],
    "05/05/2023": ["478", "91", "489"],
    "05/06/2023": ["246", "20", "389"],
    "05/07/2023": ["***", "**", "***"],
  },
  "05/08/2023 to 05/14/2023": {
    "05/08/2023": ["335", "18", "279"],
    "05/09/2023": ["368", "71", "155"],
    "05/10/2023": ["499", "22", "129"],
    "05/11/2023": ["690", "51", "155"],
    "05/12/2023": ["690", "55", "258"],
    "05/13/2023": ["458", "73", "670"],
    "05/14/2023": ["***", "**", "***"],
  },
  "05/15/2023 to 05/21/2023": {
    "05/15/2023": ["368", "77", "223"],
    "05/16/2023": ["230", "58", "468"],
    "05/17/2023": ["239", "42", "237"],
    "05/18/2023": ["149", "42", "147"],
    "05/19/2023": ["146", "15", "456"],
    "05/20/2023": ["468", "87", "890"],
    "05/21/2023": ["***", "**", "***"],
  },
  "05/22/2023 to 05/28/2023": {
    "05/22/2023": ["289", "91", "380"],
    "05/23/2023": ["115", "76", "240"],
    "05/24/2023": ["589", "20", "677"],
    "05/25/2023": ["460", "02", "778"],
    "05/26/2023": ["679", "23", "148"],
    "05/27/2023": ["168", "53", "148"],
    "05/28/2023": ["***", "**", "***"],
  },
  "05/29/2023 to 06/04/2023": {
    "05/29/2023": ["478", "99", "117"],
    "05/30/2023": ["480", "25", "267"],
    "05/31/2023": ["789", "44", "590"],
    "06/01/2023": ["479", "05", "140"],
    "06/02/2023": ["330", "67", "133"],
    "06/03/2023": ["458", "77", "124"],
    "06/04/2023": ["***", "**", "***"],
  },
  "06/05/2023 to 06/11/2023": {
    "06/05/2023": ["399", "12", "147"],
    "06/06/2023": ["669", "18", "170"],
    "06/07/2023": ["367", "63", "148"],
    "06/08/2023": ["279", "83", "300"],
    "06/09/2023": ["489", "17", "458"],
    "06/10/2023": ["224", "81", "470"],
    "06/11/2023": ["***", "**", "***"],
  },
  "06/12/2023 to 06/18/2023": {
    "06/12/2023": ["237", "22", "345"],
    "06/13/2023": ["557", "73", "247"],
    "06/14/2023": ["490", "37", "368"],
    "06/15/2023": ["779", "32", "255"],
    "06/16/2023": ["570", "27", "467"],
    "06/17/2023": ["348", "50", "127"],
    "06/18/2023": ["***", "**", "***"],
  },
  "06/19/2023 to 06/25/2023": {
    "06/19/2023": ["128", "10", "389"],
    "06/20/2023": ["160", "77", "467"],
    "06/21/2023": ["790", "63", "346"],
    "06/22/2023": ["567", "85", "780"],
    "06/23/2023": ["590", "44", "158"],
    "06/24/2023": ["580", "34", "149"],
    "06/25/2023": ["***", "**", "***"],
  },
  "06/26/2023 to 07/02/2023": {
    "06/26/2023": ["677", "08", "459"],
    "06/27/2023": ["290", "19", "360"],
    "06/28/2023": ["235", "00", "127"],
    "06/29/2023": ["680", "49", "234"],
    "06/30/2023": ["230", "55", "447"],
    "07/01/2023": ["120", "34", "680"],
    "07/02/2023": ["***", "**", "***"],
  },
  "07/03/2023 to 07/09/2023": {
    "07/03/2023": ["799", "56", "169"],
    "07/04/2023": ["235", "03", "788"],
    "07/05/2023": ["578", "07", "557"],
    "07/06/2023": ["388", "98", "260"],
    "07/07/2023": ["128", "19", "225"],
    "07/08/2023": ["457", "66", "240"],
    "07/09/2023": ["***", "**", "***"],
  },
  "07/10/2023 to 07/16/2023": {
    "07/10/2023": ["550", "03", "157"],
    "07/11/2023": ["390", "28", "125"],
    "07/12/2023": ["448", "64", "590"],
    "07/13/2023": ["150", "67", "160"],
    "07/14/2023": ["450", "91", "236"],
    "07/15/2023": ["234", "97", "124"],
    "07/16/2023": ["***", "**", "***"],
  },
  "07/17/2023 to 07/23/2023": {
    "07/17/2023": ["180", "99", "270"],
    "07/18/2023": ["690", "59", "568"],
    "07/19/2023": ["360", "93", "139"],
    "07/20/2023": ["360", "90", "488"],
    "07/21/2023": ["358", "68", "260"],
    "07/22/2023": ["479", "01", "245"],
    "07/23/2023": ["***", "**", "***"],
  },
  "07/24/2023 to 07/30/2023": {
    "07/24/2023": ["129", "22", "246"],
    "07/25/2023": ["233", "86", "556"],
    "07/26/2023": ["267", "51", "678"],
    "07/27/2023": ["440", "88", "459"],
    "07/28/2023": ["244", "06", "349"],
    "07/29/2023": ["239", "48", "558"],
    "07/30/2023": ["***", "**", "***"],
  },
  "07/31/2023 to 08/06/2023": {
    "07/31/2023": ["388", "90", "235"],
    "08/01/2023": ["134", "80", "145"],
    "08/02/2023": ["226", "09", "126"],
    "08/03/2023": ["348", "54", "167"],
    "08/04/2023": ["557", "76", "457"],
    "08/05/2023": ["468", "87", "160"],
    "08/06/2023": ["***", "**", "***"],
  },
  "08/07/2023 to 08/13/2023": {
    "08/07/2023": ["679", "22", "228"],
    "08/08/2023": ["700", "71", "669"],
    "08/09/2023": ["238", "32", "390"],
    "08/10/2023": ["780", "58", "224"],
    "08/11/2023": ["248", "43", "238"],
    "08/12/2023": ["146", "13", "139"],
    "08/13/2023": ["***", "**", "***"],
  },
  "08/14/2023 to 08/20/2023": {
    "08/14/2023": ["289", "97", "133"],
    "08/15/2023": ["***", "**", "***"],
    "08/16/2023": ["147", "21", "344"],
    "08/17/2023": ["146", "16", "150"],
    "08/18/2023": ["225", "95", "159"],
    "08/19/2023": ["350", "86", "600"],
    "08/20/2023": ["***", "**", "***"],
  },
  "08/21/2023 to 08/27/2023": {
    "08/21/2023": ["358", "61", "137"],
    "08/22/2023": ["456", "54", "248"],
    "08/23/2023": ["880", "62", "570"],
    "08/24/2023": ["136", "08", "288"],
    "08/25/2023": ["467", "77", "458"],
    "08/26/2023": ["569", "04", "257"],
    "08/27/2023": ["***", "**", "***"],
  },
  "08/28/2023 to 09/03/2023": {
    "08/28/2023": ["149", "46", "457"],
    "08/29/2023": ["138", "20", "550"],
    "08/30/2023": ["148", "33", "788"],
    "08/31/2023": ["160", "73", "139"],
    "09/01/2023": ["115", "77", "115"],
    "09/02/2023": ["125", "83", "689"],
    "09/03/2023": ["***", "**", "***"],
  },
  "09/04/2023 to 09/10/2023": {
    "09/04/2023": ["130", "45", "348"],
    "09/05/2023": ["123", "60", "578"],
    "09/06/2023": ["369", "86", "123"],
    "09/07/2023": ["270", "94", "400"],
    "09/08/2023": ["125", "88", "459"],
    "09/09/2023": ["449", "71", "678"],
    "09/10/2023": ["***", "**", "***"],
  },
  "09/11/2023 to 09/17/2023": {
    "09/11/2023": ["488", "06", "358"],
    "09/12/2023": ["380", "18", "134"],
    "09/13/2023": ["560", "18", "134"],
    "09/14/2023": ["690", "59", "450"],
    "09/15/2023": ["156", "20", "190"],
    "09/16/2023": ["188", "79", "135"],
    "09/17/2023": ["***", "**", "***"],
  },
  "09/18/2023 to 09/24/2023": {
    "09/18/2023": ["160", "77", "340"],
    "09/19/2023": ["689", "35", "348"],
    "09/20/2023": ["129", "25", "456"],
    "09/21/2023": ["688", "21", "380"],
    "09/22/2023": ["278", "73", "670"],
    "09/23/2023": ["367", "63", "148"],
    "09/24/2023": ["***", "**", "***"],
  },
  "09/25/2023 to 10/01/2023": {
    "09/25/2023": ["126", "93", "148"],
    "09/26/2023": ["136", "00", "299"],
    "09/27/2023": ["256", "38", "279"],
    "09/28/2023": ["237", "22", "228"],
    "09/29/2023": ["346", "33", "148"],
    "09/30/2023": ["560", "16", "358"],
    "10/01/2023": ["***", "**", "***"],
  },
  "10/02/2023 to 10/08/2023": {
    "10/02/2023": ["228", "27", "179"],
    "10/03/2023": ["600", "66", "277"],
    "10/04/2023": ["158", "46", "349"],
    "10/05/2023": ["300", "31", "236"],
    "10/06/2023": ["670", "35", "339"],
    "10/07/2023": ["167", "44", "167"],
    "10/08/2023": ["***", "**", "***"],
  },
  "10/09/2023 to 10/15/2023": {
    "10/09/2023": ["160", "78", "134"],
    "10/10/2023": ["568", "92", "138"],
    "10/11/2023": ["330", "66", "169"],
    "10/12/2023": ["457", "62", "345"],
    "10/13/2023": ["440", "87", "890"],
    "10/14/2023": ["135", "98", "260"],
    "10/15/2023": ["***", "**", "***"],
  },
  "10/16/2023 to 10/22/2023": {
    "10/16/2023": ["144", "91", "380"],
    "10/17/2023": ["445", "33", "256"],
    "10/18/2023": ["134", "82", "778"],
    "10/19/2023": ["389", "00", "488"],
    "10/20/2023": ["260", "83", "148"],
    "10/21/2023": ["346", "35", "140"],
    "10/22/2023": ["***", "**", "***"],
  },
  "10/23/2023 to 10/29/2023": {
    "10/23/2023": ["140", "53", "120"],
    "10/24/2023": ["129", "23", "256"],
    "10/25/2023": ["160", "74", "356"],
    "10/26/2023": ["378", "85", "267"],
    "10/27/2023": ["380", "15", "249"],
    "10/28/2023": ["370", "02", "679"],
    "10/29/2023": ["***", "**", "***"],
  },
  "10/30/2023 to 11/05/2023": {
    "10/30/2023": ["138", "23", "238"],
    "10/31/2023": ["358", "69", "900"],
    "11/01/2023": ["580", "33", "139"],
    "11/02/2023": ["350", "85", "140"],
    "11/03/2023": ["688", "26", "277"],
    "11/04/2023": ["350", "81", "678"],
    "11/05/2023": ["***", "**", "***"],
  },
  "11/06/2023 to 11/12/2023": {
    "11/06/2023": ["266", "42", "660"],
    "11/07/2023": ["235", "05", "249"],
    "11/08/2023": ["670", "32", "246"],
    "11/09/2023": ["238", "34", "130"],
    "11/10/2023": ["***", "**", "***"],
    "11/11/2023": ["***", "**", "***"],
    "11/12/2023": ["***", "**", "***"],
  },
  "11/13/2023 to 11/19/2023": {
    "11/13/2023": ["***", "**", "***"],
    "11/14/2023": ["***", "**", "***"],
    "11/15/2023": ["***", "**", "***"],
    "11/16/2023": ["***", "**", "***"],
    "11/17/2023": ["***", "**", "***"],
    "11/18/2023": ["***", "**", "***"],
    "11/19/2023": ["***", "**", "***"],
  },
  "11/20/2023 to 11/26/2023": {
    "11/20/2023": ["250", "71", "128"],
    "11/21/2023": ["366", "52", "129"],
    "11/22/2023": ["379", "94", "789"],
    "11/23/2023": ["799", "53", "670"],
    "11/24/2023": ["348", "57", "269"],
    "11/25/2023": ["578", "09", "360"],
    "11/26/2023": ["***", "**", "***"],
  },
  "11/27/2023 to 12/03/2023": {
    "11/27/2023": ["680", "41", "579"],
    "11/28/2023": ["400", "49", "469"],
    "11/29/2023": ["578", "07", "160"],
    "11/30/2023": ["130", "45", "159"],
    "12/01/2023": ["679", "24", "149"],
    "12/02/2023": ["568", "99", "234"],
    "12/03/2023": ["***", "**", "***"],
  },
  "12/04/2023 to 12/10/2023": {
    "12/04/2023": ["780", "52", "200"],
    "12/05/2023": ["117", "96", "277"],
    "12/06/2023": ["114", "66", "123"],
    "12/07/2023": ["180", "97", "368"],
    "12/08/2023": ["255", "20", "569"],
    "12/09/2023": ["278", "71", "489"],
    "12/10/2023": ["***", "**", "***"],
  },
  "12/11/2023 to 12/17/2023": {
    "12/11/2023": ["356", "44", "112"],
    "12/12/2023": ["468", "83", "490"],
    "12/13/2023": ["269", "74", "338"],
    "12/14/2023": ["157", "33", "166"],
    "12/15/2023": ["790", "65", "690"],
    "12/16/2023": ["237", "28", "468"],
    "12/17/2023": ["***", "**", "***"],
  },
  "12/18/2023 to 12/24/2023": {
    "12/18/2023": ["158", "46", "123"],
    "12/19/2023": ["348", "59", "388"],
    "12/20/2023": ["380", "17", "377"],
    "12/21/2023": ["249", "54", "149"],
    "12/22/2023": ["129", "29", "126"],
    "12/23/2023": ["244", "02", "570"],
    "12/24/2023": ["***", "**", "***"],
  },
  "12/25/2023 to 12/31/2023": {
    "12/25/2023": ["125", "82", "147"],
    "12/26/2023": ["100", "19", "450"],
    "12/27/2023": ["259", "63", "346"],
    "12/28/2023": ["270", "92", "156"],
    "12/29/2023": ["246", "21", "588"],
    "12/30/2023": ["114", "67", "890"],
    "12/31/2023": ["***", "**", "***"],
  },
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["890", "75", "168"],
    "01/02/2024": ["159", "54", "699"],
    "01/03/2024": ["357", "50", "127"],
    "01/04/2024": ["349", "69", "126"],
    "01/05/2024": ["780", "56", "240"],
    "01/06/2024": ["880", "62", "480"],
    "01/07/2024": ["***", "**", "***"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["660", "23", "445"],
    "01/09/2024": ["560", "15", "249"],
    "01/10/2024": ["379", "98", "378"],
    "01/11/2024": ["345", "26", "114"],
    "01/12/2024": ["249", "55", "230"],
    "01/13/2024": ["569", "05", "456"],
    "01/14/2024": ["***", "**", "***"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["558", "89", "360"],
    "01/16/2024": ["457", "60", "578"],
    "01/17/2024": ["290", "12", "679"],
    "01/18/2024": ["346", "31", "579"],
    "01/19/2024": ["269", "74", "699"],
    "01/20/2024": ["346", "39", "234"],
    "01/21/2024": ["***", "**", "***"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["449", "76", "457"],
    "01/23/2024": ["236", "16", "259"],
    "01/24/2024": ["477", "80", "226"],
    "01/25/2024": ["234", "92", "138"],
    "01/26/2024": ["***", "**", "***"],
    "01/27/2024": ["120", "31", "560"],
    "01/28/2024": ["***", "**", "***"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["147", "23", "139"],
    "01/30/2024": ["480", "25", "258"],
    "01/31/2024": ["899", "67", "340"],
    "02/01/2024": ["116", "88", "233"],
    "02/02/2024": ["230", "58", "125"],
    "02/03/2024": ["580", "36", "150"],
    "02/04/2024": ["***", "**", "***"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["368", "72", "345"],
    "02/06/2024": ["589", "20", "460"],
    "02/07/2024": ["358", "61", "290"],
    "02/08/2024": ["128", "19", "450"],
    "02/09/2024": ["380", "13", "670"],
    "02/10/2024": ["116", "86", "259"],
    "02/11/2024": ["***", "**", "***"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["590", "46", "448"],
    "02/13/2024": ["337", "37", "700"],
    "02/14/2024": ["890", "72", "345"],
    "02/15/2024": ["280", "08", "378"],
    "02/16/2024": ["599", "37", "160"],
    "02/17/2024": ["670", "35", "456"],
    "02/18/2024": ["***", "**", "***"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["778", "20", "190"],
    "02/20/2024": ["123", "63", "148"],
    "02/21/2024": ["550", "01", "245"],
    "02/22/2024": ["190", "02", "138"],
    "02/23/2024": ["226", "06", "240"],
    "02/24/2024": ["348", "56", "123"],
    "02/25/2024": ["***", "**", "***"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["147", "27", "124"],
    "02/27/2024": ["667", "94", "257"],
    "02/28/2024": ["129", "21", "236"],
    "02/29/2024": ["350", "83", "337"],
    "03/01/2024": ["789", "44", "149"],
    "03/02/2024": ["480", "21", "399"],
    "03/03/2024": ["***", "**", "***"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["556", "60", "479"],
    "03/05/2024": ["359", "73", "346"],
    "03/06/2024": ["550", "09", "225"],
    "03/07/2024": ["467", "70", "235"],
    "03/08/2024": ["359", "75", "456"],
    "03/09/2024": ["689", "37", "188"],
    "03/10/2024": ["***", "**", "***"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["366", "59", "126"],
    "03/12/2024": ["468", "88", "350"],
    "03/13/2024": ["699", "45", "168"],
    "03/14/2024": ["189", "87", "133"],
    "03/15/2024": ["159", "59", "450"],
    "03/16/2024": ["570", "23", "157"],
    "03/17/2024": ["***", "**", "***"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["600", "65", "168"],
    "03/19/2024": ["125", "84", "347"],
    "03/20/2024": ["289", "94", "149"],
    "03/21/2024": ["357", "55", "348"],
    "03/22/2024": ["188", "71", "245"],
    "03/23/2024": ["890", "77", "340"],
    "03/24/2024": ["***", "**", "***"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["***", "**", "***"],
    "03/26/2024": ["156", "27", "368"],
    "03/27/2024": ["278", "78", "279"],
    "03/28/2024": ["246", "28", "260"],
    "03/29/2024": ["556", "62", "345"],
    "03/30/2024": ["579", "10", "235"],
    "03/31/2024": ["***", "**", "***"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["335", "14", "590"],
    "04/02/2024": ["467", "73", "256"],
    "04/03/2024": ["224", "86", "150"],
    "04/04/2024": ["190", "08", "279"],
    "04/05/2024": ["240", "69", "144"],
    "04/06/2024": ["260", "83", "247"],
    "04/07/2024": ["***", "**", "***"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["156", "28", "170"],
    "04/09/2024": ["788", "31", "128"],
    "04/10/2024": ["270", "96", "349"],
    "04/11/2024": ["128", "14", "257"],
    "04/12/2024": ["234", "90", "280"],
    "04/13/2024": ["690", "53", "346"],
    "04/14/2024": ["***", "**", "***"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["237", "20", "145"],
    "04/16/2024": ["148", "39", "478"],
    "04/17/2024": ["778", "24", "257"],
    "04/18/2024": ["188", "70", "578"],
    "04/19/2024": ["140", "55", "168"],
    "04/20/2024": ["114", "67", "124"],
    "04/21/2024": ["***", "**", "***"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["130", "45", "159"],
    "04/23/2024": ["590", "44", "789"],
    "04/24/2024": ["489", "12", "255"],
    "04/25/2024": ["249", "52", "147"],
    "04/26/2024": ["557", "73", "238"],
    "04/27/2024": ["380", "15", "456"],
    "04/28/2024": ["***", "**", "***"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["778", "26", "240"],
    "04/30/2024": ["880", "60", "389"],
    "05/01/2024": ["470", "10", "578"],
    "05/02/2024": ["360", "94", "400"],
    "05/03/2024": ["560", "12", "228"],
    "05/04/2024": ["589", "21", "128"],
    "05/05/2024": ["***", "**", "***"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["267", "53", "247"],
    "05/07/2024": ["700", "72", "147"],
    "05/08/2024": ["568", "93", "490"],
    "05/09/2024": ["449", "75", "447"],
    "05/10/2024": ["469", "90", "244"],
    "05/11/2024": ["378", "81", "236"],
    "05/12/2024": ["***", "**", "***"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["468", "81", "137"],
    "05/14/2024": ["700", "77", "223"],
    "05/15/2024": ["660", "29", "234"],
    "05/16/2024": ["246", "22", "570"],
    "05/17/2024": ["489", "11", "579"],
    "05/18/2024": ["117", "94", "257"],
    "05/19/2024": ["***", "**", "***"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["133", "71", "579"],
    "05/21/2024": ["235", "03", "490"],
    "05/22/2024": ["168", "50", "460"],
    "05/23/2024": ["267", "56", "268"],
    "05/24/2024": ["139", "31", "128"],
    "05/25/2024": ["335", "18", "990"],
    "05/26/2024": ["***", "**", "***"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["899", "63", "779"],
    "05/28/2024": ["235", "02", "390"],
    "05/29/2024": ["289", "91", "560"],
    "05/30/2024": ["690", "58", "170"],
    "05/31/2024": ["157", "30", "668"],
    "06/01/2024": ["278", "77", "449"],
    "06/02/2024": ["***", "**", "***"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["157", "36", "169"],
    "06/04/2024": ["477", "87", "449"],
    "06/05/2024": ["240", "63", "120"],
    "06/06/2024": ["190", "00", "145"],
    "06/07/2024": ["589", "25", "267"],
    "06/08/2024": ["599", "31", "119"],
    "06/09/2024": ["***", "**", "***"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["670", "38", "350"],
    "06/11/2024": ["380", "14", "338"],
    "06/12/2024": ["445", "36", "880"],
    "06/13/2024": ["290", "12", "390"],
    "06/14/2024": ["156", "28", "134"],
    "06/15/2024": ["469", "98", "800"],
    "06/16/2024": ["***", "**", "***"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["145", "08", "260"],
    "06/18/2024": ["488", "07", "179"],
    "06/19/2024": ["369", "82", "237"],
    "06/20/2024": ["110", "24", "167"],
    "06/21/2024": ["240", "61", "236"],
    "06/22/2024": ["249", "59", "469"],
    "06/23/2024": ["***", "**", "***"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["279", "88", "567"],
    "06/25/2024": ["113", "52", "660"],
    "06/26/2024": ["700", "75", "258"],
    "06/27/2024": ["127", "07", "368"],
    "06/28/2024": ["237", "28", "125"],
    "06/29/2024": ["230", "58", "260"],
    "06/30/2024": ["***", "**", "***"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["356", "43", "580"],
    "07/02/2024": ["558", "87", "359"],
    "07/03/2024": ["560", "10", "145"],
    "07/04/2024": ["150", "62", "589"],
    "07/05/2024": ["127", "01", "579"],
    "07/06/2024": ["244", "04", "248"],
    "07/07/2024": ["***", "**", "***"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["889", "59", "450"],
    "07/09/2024": ["589", "26", "259"],
    "07/10/2024": ["479", "07", "566"],
    "07/11/2024": ["457", "60", "370"],
    "07/12/2024": ["237", "27", "160"],
    "07/13/2024": ["469", "94", "590"],
    "07/14/2024": ["***", "**", "***"],
  },
};
export const RAJDHANIDAY = {
  "01/02/2023 to 01/08/2023": {
    "01/02/2023": ["469", "98", "170"],
    "01/03/2023": ["150", "60", "280"],
    "01/04/2023": ["556", "64", "789"],
    "01/05/2023": ["156", "23", "779"],
    "01/06/2023": ["130", "43", "157"],
    "01/07/2023": ["560", "11", "380"],
    "01/08/2023": ["***", "**", "***"],
  },
  "01/09/2023 to 01/15/2023": {
    "01/09/2023": ["278", "74", "167"],
    "01/10/2023": ["700", "78", "134"],
    "01/11/2023": ["360", "98", "440"],
    "01/12/2023": ["233", "86", "240"],
    "01/13/2023": ["145", "01", "146"],
    "01/14/2023": ["560", "13", "490"],
    "01/15/2023": ["***", "**", "***"],
  },
  "01/16/2023 to 01/22/2023": {
    "01/16/2023": ["589", "22", "390"],
    "01/17/2023": ["179", "78", "350"],
    "01/18/2023": ["260", "89", "234"],
    "01/19/2023": ["660", "22", "156"],
    "01/20/2023": ["244", "04", "680"],
    "01/21/2023": ["345", "27", "377"],
    "01/22/2023": ["***", "**", "***"],
  },
  "01/23/2023 to 01/29/2023": {
    "01/23/2023": ["890", "75", "140"],
    "01/24/2023": ["340", "73", "490"],
    "01/25/2023": ["236", "19", "379"],
    "01/26/2023": ["***", "**", "***"],
    "01/27/2023": ["900", "91", "588"],
    "01/28/2023": ["359", "78", "134"],
    "01/29/2023": ["***", "**", "***"],
  },
  "01/30/2023 to 02/05/2023": {
    "01/30/2023": ["347", "40", "668"],
    "01/31/2023": ["459", "86", "330"],
    "02/01/2023": ["579", "11", "470"],
    "02/02/2023": ["240", "69", "469"],
    "02/03/2023": ["350", "84", "257"],
    "02/04/2023": ["378", "89", "180"],
    "02/05/2023": ["***", "**", "***"],
  },
  "02/06/2023 to 02/12/2023": {
    "02/06/2023": ["670", "37", "359"],
    "02/07/2023": ["169", "69", "135"],
    "02/08/2023": ["669", "10", "334"],
    "02/09/2023": ["245", "15", "168"],
    "02/10/2023": ["339", "57", "269"],
    "02/11/2023": ["359", "72", "129"],
    "02/12/2023": ["***", "**", "***"],
  },
  "02/13/2023 to 02/19/2023": {
    "02/13/2023": ["440", "86", "556"],
    "02/14/2023": ["460", "02", "570"],
    "02/15/2023": ["690", "52", "390"],
    "02/16/2023": ["578", "05", "230"],
    "02/17/2023": ["236", "13", "256"],
    "02/18/2023": ["334", "01", "560"],
    "02/19/2023": ["***", "**", "***"],
  },
  "02/20/2023 to 02/26/2023": {
    "02/20/2023": ["789", "45", "159"],
    "02/21/2023": ["469", "95", "357"],
    "02/22/2023": ["112", "46", "240"],
    "02/23/2023": ["280", "00", "136"],
    "02/24/2023": ["129", "21", "588"],
    "02/25/2023": ["125", "89", "135"],
    "02/26/2023": ["***", "**", "***"],
  },
  "02/27/2023 to 03/05/2023": {
    "02/27/2023": ["450", "92", "138"],
    "02/28/2023": ["668", "09", "270"],
    "03/01/2023": ["123", "64", "158"],
    "03/02/2023": ["189", "87", "269"],
    "03/03/2023": ["489", "12", "237"],
    "03/04/2023": ["114", "60", "145"],
    "03/05/2023": ["***", "**", "***"],
  },
  "03/06/2023 to 03/12/2023": {
    "03/06/2023": ["146", "15", "780"],
    "03/07/2023": ["356", "41", "560"],
    "03/08/2023": ["***", "**", "***"],
    "03/09/2023": ["690", "53", "337"],
    "03/10/2023": ["137", "10", "668"],
    "03/11/2023": ["379", "96", "349"],
    "03/12/2023": ["***", "**", "***"],
  },
  "03/13/2023 to 03/19/2023": {
    "03/13/2023": ["689", "32", "138"],
    "03/14/2023": ["900", "99", "135"],
    "03/15/2023": ["159", "50", "244"],
    "03/16/2023": ["479", "05", "249"],
    "03/17/2023": ["688", "23", "490"],
    "03/18/2023": ["158", "45", "690"],
    "03/19/2023": ["***", "**", "***"],
  },
  "03/20/2023 to 03/26/2023": {
    "03/20/2023": ["344", "12", "570"],
    "03/21/2023": ["156", "26", "169"],
    "03/22/2023": ["456", "53", "120"],
    "03/23/2023": ["990", "87", "467"],
    "03/24/2023": ["157", "34", "149"],
    "03/25/2023": ["578", "03", "166"],
    "03/26/2023": ["***", "**", "***"],
  },
  "03/27/2023 to 04/02/2023": {
    "03/27/2023": ["570", "27", "458"],
    "03/28/2023": ["177", "50", "370"],
    "03/29/2023": ["135", "93", "599"],
    "03/30/2023": ["567", "84", "158"],
    "03/31/2023": ["378", "81", "137"],
    "04/01/2023": ["669", "14", "356"],
    "04/02/2023": ["***", "**", "***"],
  },
  "04/03/2023 to 04/09/2023": {
    "04/03/2023": ["256", "39", "568"],
    "04/04/2023": ["340", "75", "113"],
    "04/05/2023": ["299", "00", "190"],
    "04/06/2023": ["145", "05", "168"],
    "04/07/2023": ["356", "40", "569"],
    "04/08/2023": ["680", "47", "359"],
    "04/09/2023": ["***", "**", "***"],
  },
  "04/10/2023 to 04/16/2023": {
    "04/10/2023": ["558", "83", "490"],
    "04/11/2023": ["120", "37", "340"],
    "04/12/2023": ["239", "40", "127"],
    "04/13/2023": ["449", "71", "155"],
    "04/14/2023": ["236", "16", "358"],
    "04/15/2023": ["247", "33", "346"],
    "04/16/2023": ["***", "**", "***"],
  },
  "04/17/2023 to 04/23/2023": {
    "04/17/2023": ["169", "65", "249"],
    "04/18/2023": ["168", "50", "334"],
    "04/19/2023": ["335", "12", "679"],
    "04/20/2023": ["159", "52", "345"],
    "04/21/2023": ["677", "01", "588"],
    "04/22/2023": ["270", "99", "360"],
    "04/23/2023": ["***", "**", "***"],
  },
  "04/24/2023 to 04/30/2023": {
    "04/24/2023": ["130", "45", "780"],
    "04/25/2023": ["350", "89", "180"],
    "04/26/2023": ["248", "43", "157"],
    "04/27/2023": ["670", "34", "347"],
    "04/28/2023": ["135", "97", "269"],
    "04/29/2023": ["789", "48", "134"],
    "04/30/2023": ["***", "**", "***"],
  },
  "05/01/2023 to 05/07/2023": {
    "05/01/2023": ["224", "80", "460"],
    "05/02/2023": ["227", "13", "148"],
    "05/03/2023": ["256", "33", "157"],
    "05/04/2023": ["230", "58", "440"],
    "05/05/2023": ["128", "17", "160"],
    "05/06/2023": ["299", "00", "668"],
    "05/07/2023": ["***", "**", "***"],
  },
  "05/08/2023 to 05/14/2023": {
    "05/08/2023": ["157", "33", "490"],
    "05/09/2023": ["469", "94", "699"],
    "05/10/2023": ["147", "20", "127"],
    "05/11/2023": ["139", "34", "149"],
    "05/12/2023": ["136", "01", "245"],
    "05/13/2023": ["440", "80", "479"],
    "05/14/2023": ["***", "**", "***"],
  },
  "05/15/2023 to 05/21/2023": {
    "05/15/2023": ["450", "97", "133"],
    "05/16/2023": ["249", "55", "456"],
    "05/17/2023": ["359", "71", "335"],
    "05/18/2023": ["233", "80", "578"],
    "05/19/2023": ["156", "22", "345"],
    "05/20/2023": ["990", "83", "346"],
    "05/21/2023": ["***", "**", "***"],
  },
  "05/22/2023 to 05/28/2023": {
    "05/22/2023": ["239", "41", "579"],
    "05/23/2023": ["359", "71", "290"],
    "05/24/2023": ["345", "22", "480"],
    "05/25/2023": ["479", "08", "990"],
    "05/26/2023": ["128", "15", "230"],
    "05/27/2023": ["489", "18", "369"],
    "05/28/2023": ["***", "**", "***"],
  },
  "05/29/2023 to 06/04/2023": {
    "05/29/2023": ["166", "35", "159"],
    "05/30/2023": ["136", "07", "269"],
    "05/31/2023": ["570", "21", "380"],
    "06/01/2023": ["390", "28", "189"],
    "06/02/2023": ["280", "04", "356"],
    "06/03/2023": ["160", "70", "677"],
    "06/04/2023": ["***", "**", "***"],
  },
  "06/05/2023 to 06/11/2023": {
    "06/05/2023": ["234", "99", "667"],
    "06/06/2023": ["460", "03", "157"],
    "06/07/2023": ["135", "97", "340"],
    "06/08/2023": ["244", "04", "789"],
    "06/09/2023": ["257", "42", "129"],
    "06/10/2023": ["117", "96", "466"],
    "06/11/2023": ["***", "**", "***"],
  },
  "06/12/2023 to 06/18/2023": {
    "06/12/2023": ["290", "16", "150"],
    "06/13/2023": ["235", "06", "349"],
    "06/14/2023": ["577", "94", "590"],
    "06/15/2023": ["247", "37", "557"],
    "06/16/2023": ["190", "02", "390"],
    "06/17/2023": ["144", "99", "126"],
    "06/18/2023": ["***", "**", "***"],
  },
  "06/19/2023 to 06/25/2023": {
    "06/19/2023": ["167", "42", "147"],
    "06/20/2023": ["680", "49", "469"],
    "06/21/2023": ["899", "60", "145"],
    "06/22/2023": ["260", "88", "567"],
    "06/23/2023": ["349", "61", "669"],
    "06/24/2023": ["128", "16", "123"],
    "06/25/2023": ["***", "**", "***"],
  },
  "06/26/2023 to 07/02/2023": {
    "06/26/2023": ["560", "13", "120"],
    "06/27/2023": ["360", "97", "115"],
    "06/28/2023": ["130", "42", "679"],
    "06/29/2023": ["238", "31", "489"],
    "06/30/2023": ["166", "31", "588"],
    "07/01/2023": ["358", "67", "368"],
    "07/02/2023": ["***", "**", "***"],
  },
  "07/03/2023 to 07/09/2023": {
    "07/03/2023": ["488", "08", "378"],
    "07/04/2023": ["370", "07", "250"],
    "07/05/2023": ["150", "60", "145"],
    "07/06/2023": ["270", "97", "160"],
    "07/07/2023": ["119", "19", "469"],
    "07/08/2023": ["334", "08", "260"],
    "07/09/2023": ["***", "**", "***"],
  },
  "07/10/2023 to 07/16/2023": {
    "07/10/2023": ["469", "90", "479"],
    "07/11/2023": ["568", "98", "134"],
    "07/12/2023": ["266", "46", "114"],
    "07/13/2023": ["248", "48", "260"],
    "07/14/2023": ["480", "25", "258"],
    "07/15/2023": ["360", "92", "147"],
    "07/16/2023": ["***", "**", "***"],
  },
  "07/17/2023 to 07/23/2023": {
    "07/17/2023": ["157", "35", "690"],
    "07/18/2023": ["268", "60", "145"],
    "07/19/2023": ["122", "51", "678"],
    "07/20/2023": ["490", "35", "500"],
    "07/21/2023": ["267", "59", "270"],
    "07/22/2023": ["380", "15", "230"],
    "07/23/2023": ["***", "**", "***"],
  },
  "07/24/2023 to 07/30/2023": {
    "07/24/2023": ["459", "87", "269"],
    "07/25/2023": ["134", "89", "360"],
    "07/26/2023": ["245", "16", "268"],
    "07/27/2023": ["124", "78", "125"],
    "07/28/2023": ["556", "63", "139"],
    "07/29/2023": ["229", "31", "236"],
    "07/30/2023": ["***", "**", "***"],
  },
  "07/31/2023 to 08/06/2023": {
    "07/31/2023": ["390", "28", "567"],
    "08/01/2023": ["580", "36", "259"],
    "08/02/2023": ["233", "89", "478"],
    "08/03/2023": ["456", "59", "289"],
    "08/04/2023": ["360", "94", "130"],
    "08/05/2023": ["370", "00", "370"],
    "08/06/2023": ["***", "**", "***"],
  },
  "08/07/2023 to 08/13/2023": {
    "08/07/2023": ["239", "48", "800"],
    "08/08/2023": ["268", "62", "246"],
    "08/09/2023": ["567", "87", "340"],
    "08/10/2023": ["233", "86", "178"],
    "08/11/2023": ["115", "73", "670"],
    "08/12/2023": ["990", "84", "789"],
    "08/13/2023": ["***", "**", "***"],
  },
  "08/14/2023 to 08/20/2023": {
    "08/14/2023": ["578", "02", "138"],
    "08/15/2023": ["***", "**", "***"],
    "08/16/2023": ["670", "30", "677"],
    "08/17/2023": ["550", "09", "126"],
    "08/18/2023": ["248", "49", "135"],
    "08/19/2023": ["690", "55", "780"],
    "08/20/2023": ["***", "**", "***"],
  },
  "08/21/2023 to 08/27/2023": {
    "08/21/2023": ["269", "72", "138"],
    "08/22/2023": ["447", "50", "136"],
    "08/23/2023": ["330", "64", "248"],
    "08/24/2023": ["469", "94", "130"],
    "08/25/2023": ["260", "81", "245"],
    "08/26/2023": ["446", "45", "230"],
    "08/27/2023": ["***", "**", "***"],
  },
  "08/28/2023 to 09/03/2023": {
    "08/28/2023": ["490", "34", "112"],
    "08/29/2023": ["460", "03", "256"],
    "08/30/2023": ["580", "32", "129"],
    "08/31/2023": ["990", "82", "228"],
    "09/01/2023": ["159", "57", "458"],
    "09/02/2023": ["110", "22", "390"],
    "09/03/2023": ["***", "**", "***"],
  },
  "09/04/2023 to 09/10/2023": {
    "09/04/2023": ["130", "41", "380"],
    "09/05/2023": ["133", "77", "250"],
    "09/06/2023": ["158", "49", "180"],
    "09/07/2023": ["689", "35", "168"],
    "09/08/2023": ["228", "23", "148"],
    "09/09/2023": ["234", "93", "580"],
    "09/10/2023": ["***", "**", "***"],
  },
  "09/11/2023 to 09/17/2023": {
    "09/11/2023": ["136", "07", "467"],
    "09/12/2023": ["167", "49", "199"],
    "09/13/2023": ["450", "99", "388"],
    "09/14/2023": ["467", "70", "479"],
    "09/15/2023": ["155", "15", "690"],
    "09/16/2023": ["246", "29", "234"],
    "09/17/2023": ["***", "**", "***"],
  },
  "09/18/2023 to 09/24/2023": {
    "09/18/2023": ["668", "07", "160"],
    "09/19/2023": ["140", "55", "339"],
    "09/20/2023": ["267", "51", "470"],
    "09/21/2023": ["400", "40", "190"],
    "09/22/2023": ["137", "17", "359"],
    "09/23/2023": ["550", "01", "579"],
    "09/24/2023": ["***", "**", "***"],
  },
  "09/25/2023 to 10/01/2023": {
    "09/25/2023": ["180", "97", "467"],
    "09/26/2023": ["244", "08", "288"],
    "09/27/2023": ["235", "05", "159"],
    "09/28/2023": ["238", "34", "112"],
    "09/29/2023": ["168", "50", "190"],
    "09/30/2023": ["146", "14", "248"],
    "10/01/2023": ["***", "**", "***"],
  },
  "10/02/2023 to 10/08/2023": {
    "10/02/2023": ["340", "79", "450"],
    "10/03/2023": ["118", "05", "168"],
    "10/04/2023": ["189", "84", "220"],
    "10/05/2023": ["120", "36", "169"],
    "10/06/2023": ["470", "17", "160"],
    "10/07/2023": ["133", "72", "156"],
    "10/08/2023": ["***", "**", "***"],
  },
  "10/09/2023 to 10/15/2023": {
    "10/09/2023": ["490", "36", "268"],
    "10/10/2023": ["479", "07", "179"],
    "10/11/2023": ["558", "82", "237"],
    "10/12/2023": ["267", "59", "568"],
    "10/13/2023": ["360", "90", "280"],
    "10/14/2023": ["234", "91", "560"],
    "10/15/2023": ["***", "**", "***"],
  },
  "10/16/2023 to 10/22/2023": {
    "10/16/2023": ["122", "54", "239"],
    "10/17/2023": ["890", "75", "113"],
    "10/18/2023": ["260", "86", "114"],
    "10/19/2023": ["479", "07", "359"],
    "10/20/2023": ["678", "18", "378"],
    "10/21/2023": ["137", "10", "127"],
    "10/22/2023": ["***", "**", "***"],
  },
  "10/23/2023 to 10/29/2023": {
    "10/23/2023": ["389", "04", "356"],
    "10/24/2023": ["360", "98", "116"],
    "10/25/2023": ["139", "35", "348"],
    "10/26/2023": ["238", "31", "137"],
    "10/27/2023": ["589", "23", "157"],
    "10/28/2023": ["110", "24", "257"],
    "10/29/2023": ["***", "**", "***"],
  },
  "10/30/2023 to 11/05/2023": {
    "10/30/2023": ["370", "07", "368"],
    "10/31/2023": ["128", "15", "140"],
    "11/01/2023": ["266", "41", "236"],
    "11/02/2023": ["239", "46", "150"],
    "11/03/2023": ["129", "22", "237"],
    "11/04/2023": ["136", "01", "344"],
    "11/05/2023": ["***", "**", "***"],
  },
  "11/06/2023 to 11/12/2023": {
    "11/06/2023": ["579", "12", "589"],
    "11/07/2023": ["600", "68", "378"],
    "11/08/2023": ["460", "08", "440"],
    "11/09/2023": ["790", "64", "338"],
    "11/10/2023": ["***", "**", "***"],
    "11/11/2023": ["***", "**", "***"],
    "11/12/2023": ["***", "**", "***"],
  },
  "11/13/2023 to 11/19/2023": {
    "11/13/2023": ["***", "**", "***"],
    "11/14/2023": ["***", "**", "***"],
    "11/15/2023": ["***", "**", "***"],
    "11/16/2023": ["***", "**", "***"],
    "11/17/2023": ["***", "**", "***"],
    "11/18/2023": ["***", "**", "***"],
    "11/19/2023": ["***", "**", "***"],
  },
  "11/20/2023 to 11/26/2023": {
    "11/20/2023": ["226", "06", "349"],
    "11/21/2023": ["123", "63", "148"],
    "11/22/2023": ["290", "11", "579"],
    "11/23/2023": ["459", "86", "240"],
    "11/24/2023": ["257", "41", "344"],
    "11/25/2023": ["167", "40", "677"],
    "11/26/2023": ["***", "**", "***"],
  },
  "11/27/2023 to 12/03/2023": {
    "11/27/2023": ["235", "09", "379"],
    "11/28/2023": ["234", "94", "347"],
    "11/29/2023": ["268", "66", "790"],
    "11/30/2023": ["138", "24", "158"],
    "12/01/2023": ["478", "97", "467"],
    "12/02/2023": ["189", "80", "226"],
    "12/03/2023": ["***", "**", "***"],
  },
  "12/04/2023 to 12/10/2023": {
    "12/04/2023": ["346", "33", "689"],
    "12/05/2023": ["178", "64", "149"],
    "12/06/2023": ["344", "16", "367"],
    "12/07/2023": ["245", "15", "456"],
    "12/08/2023": ["379", "91", "128"],
    "12/09/2023": ["177", "59", "180"],
    "12/10/2023": ["***", "**", "***"],
  },
  "12/11/2023 to 12/17/2023": {
    "12/11/2023": ["257", "46", "178"],
    "12/12/2023": ["169", "63", "337"],
    "12/13/2023": ["599", "39", "450"],
    "12/14/2023": ["569", "07", "124"],
    "12/15/2023": ["167", "48", "350"],
    "12/16/2023": ["250", "79", "568"],
    "12/17/2023": ["***", "**", "***"],
  },
  "12/18/2023 to 12/24/2023": {
    "12/18/2023": ["379", "97", "160"],
    "12/19/2023": ["127", "02", "778"],
    "12/20/2023": ["126", "94", "149"],
    "12/21/2023": ["358", "66", "150"],
    "12/22/2023": ["390", "27", "179"],
    "12/23/2023": ["118", "03", "256"],
    "12/24/2023": ["***", "**", "***"],
  },
  "12/25/2023 to 12/31/2023": {
    "12/25/2023": ["137", "14", "239"],
    "12/26/2023": ["189", "87", "115"],
    "12/27/2023": ["780", "59", "180"],
    "12/28/2023": ["900", "92", "390"],
    "12/29/2023": ["124", "75", "366"],
    "12/30/2023": ["137", "11", "335"],
    "12/31/2023": ["***", "**", "***"],
  },
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["129", "23", "148"],
    "01/02/2024": ["135", "99", "126"],
    "01/03/2024": ["179", "70", "479"],
    "01/04/2024": ["389", "05", "339"],
    "01/05/2024": ["246", "21", "678"],
    "01/06/2024": ["135", "99", "900"],
    "01/07/2024": ["***", "**", "***"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["255", "26", "123"],
    "01/09/2024": ["790", "64", "347"],
    "01/10/2024": ["118", "08", "350"],
    "01/11/2024": ["258", "50", "479"],
    "01/12/2024": ["180", "99", "577"],
    "01/13/2024": ["447", "50", "280"],
    "01/14/2024": ["***", "**", "***"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["678", "16", "790"],
    "01/16/2024": ["489", "17", "188"],
    "01/17/2024": ["179", "70", "460"],
    "01/18/2024": ["134", "83", "580"],
    "01/19/2024": ["770", "40", "389"],
    "01/20/2024": ["360", "91", "380"],
    "01/21/2024": ["***", "**", "***"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["790", "63", "355"],
    "01/23/2024": ["680", "45", "140"],
    "01/24/2024": ["150", "62", "147"],
    "01/25/2024": ["900", "95", "168"],
    "01/26/2024": ["***", "**", "***"],
    "01/27/2024": ["570", "21", "344"],
    "01/28/2024": ["***", "**", "***"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["247", "33", "148"],
    "01/30/2024": ["680", "46", "268"],
    "01/31/2024": ["158", "44", "130"],
    "02/01/2024": ["457", "60", "479"],
    "02/02/2024": ["677", "02", "480"],
    "02/03/2024": ["268", "62", "246"],
    "02/04/2024": ["***", "**", "***"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["126", "96", "358"],
    "02/06/2024": ["448", "67", "890"],
    "02/07/2024": ["125", "88", "477"],
    "02/08/2024": ["369", "81", "380"],
    "02/09/2024": ["127", "02", "345"],
    "02/10/2024": ["355", "31", "128"],
    "02/11/2024": ["***", "**", "***"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["149", "44", "356"],
    "02/13/2024": ["679", "23", "148"],
    "02/14/2024": ["446", "47", "359"],
    "02/15/2024": ["457", "64", "455"],
    "02/16/2024": ["580", "31", "236"],
    "02/17/2024": ["368", "70", "668"],
    "02/18/2024": ["***", "**", "***"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["479", "08", "459"],
    "02/20/2024": ["259", "66", "240"],
    "02/21/2024": ["278", "78", "558"],
    "02/22/2024": ["145", "03", "599"],
    "02/23/2024": ["577", "93", "580"],
    "02/24/2024": ["127", "00", "569"],
    "02/25/2024": ["***", "**", "***"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["289", "93", "120"],
    "02/27/2024": ["500", "57", "368"],
    "02/28/2024": ["380", "19", "568"],
    "02/29/2024": ["588", "13", "256"],
    "03/01/2024": ["360", "96", "178"],
    "03/02/2024": ["570", "28", "224"],
    "03/03/2024": ["***", "**", "***"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["300", "34", "220"],
    "03/05/2024": ["269", "73", "247"],
    "03/06/2024": ["499", "23", "689"],
    "03/07/2024": ["246", "28", "369"],
    "03/08/2024": ["257", "41", "678"],
    "03/09/2024": ["369", "84", "239"],
    "03/10/2024": ["***", "**", "***"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["133", "71", "236"],
    "03/12/2024": ["340", "77", "557"],
    "03/13/2024": ["200", "28", "189"],
    "03/14/2024": ["149", "42", "679"],
    "03/15/2024": ["359", "76", "349"],
    "03/16/2024": ["800", "89", "450"],
    "03/17/2024": ["***", "**", "***"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["289", "95", "168"],
    "03/19/2024": ["123", "66", "114"],
    "03/20/2024": ["880", "61", "128"],
    "03/21/2024": ["260", "80", "190"],
    "03/22/2024": ["145", "05", "456"],
    "03/23/2024": ["116", "83", "256"],
    "03/24/2024": ["***", "**", "***"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["***", "**", "***"],
    "03/26/2024": ["590", "45", "140"],
    "03/27/2024": ["122", "58", "260"],
    "03/28/2024": ["669", "13", "166"],
    "03/29/2024": ["236", "10", "479"],
    "03/30/2024": ["190", "07", "890"],
    "03/31/2024": ["***", "**", "***"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["149", "41", "245"],
    "04/02/2024": ["689", "33", "300"],
    "04/03/2024": ["136", "09", "270"],
    "04/04/2024": ["680", "41", "146"],
    "04/05/2024": ["136", "03", "139"],
    "04/06/2024": ["166", "38", "288"],
    "04/07/2024": ["***", "**", "***"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["289", "99", "568"],
    "04/09/2024": ["460", "08", "189"],
    "04/10/2024": ["445", "37", "269"],
    "04/11/2024": ["349", "68", "369"],
    "04/12/2024": ["170", "81", "669"],
    "04/13/2024": ["146", "11", "227"],
    "04/14/2024": ["***", "**", "***"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["240", "63", "689"],
    "04/16/2024": ["300", "37", "179"],
    "04/17/2024": ["279", "82", "589"],
    "04/18/2024": ["127", "06", "457"],
    "04/19/2024": ["380", "15", "690"],
    "04/20/2024": ["155", "16", "169"],
    "04/21/2024": ["***", "**", "***"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["467", "75", "113"],
    "04/23/2024": ["449", "72", "570"],
    "04/24/2024": ["357", "53", "490"],
    "04/25/2024": ["125", "84", "680"],
    "04/26/2024": ["226", "05", "230"],
    "04/27/2024": ["229", "33", "670"],
    "04/28/2024": ["***", "**", "***"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["267", "56", "457"],
    "04/30/2024": ["136", "02", "679"],
    "05/01/2024": ["116", "88", "170"],
    "05/02/2024": ["357", "59", "126"],
    "05/03/2024": ["660", "22", "138"],
    "05/04/2024": ["336", "24", "347"],
    "05/05/2024": ["***", "**", "***"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["128", "12", "138"],
    "05/07/2024": ["389", "05", "140"],
    "05/08/2024": ["470", "14", "239"],
    "05/09/2024": ["448", "61", "146"],
    "05/10/2024": ["125", "80", "299"],
    "05/11/2024": ["189", "80", "578"],
    "05/12/2024": ["***", "**", "***"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["579", "19", "379"],
    "05/14/2024": ["330", "65", "348"],
    "05/15/2024": ["149", "40", "334"],
    "05/16/2024": ["489", "14", "167"],
    "05/17/2024": ["790", "67", "269"],
    "05/18/2024": ["477", "80", "389"],
    "05/19/2024": ["***", "**", "***"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["128", "16", "178"],
    "05/21/2024": ["189", "83", "238"],
    "05/22/2024": ["115", "71", "579"],
    "05/23/2024": ["340", "74", "590"],
    "05/24/2024": ["468", "84", "220"],
    "05/25/2024": ["110", "22", "156"],
    "05/26/2024": ["***", "**", "***"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["236", "15", "230"],
    "05/28/2024": ["790", "63", "670"],
    "05/29/2024": ["559", "97", "340"],
    "05/30/2024": ["580", "33", "247"],
    "05/31/2024": ["180", "95", "177"],
    "06/01/2024": ["122", "54", "347"],
    "06/02/2024": ["***", "**", "***"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["490", "31", "560"],
    "06/04/2024": ["579", "18", "440"],
    "06/05/2024": ["570", "22", "480"],
    "06/06/2024": ["246", "28", "260"],
    "06/07/2024": ["279", "87", "223"],
    "06/08/2024": ["700", "76", "150"],
    "06/09/2024": ["***", "**", "***"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["580", "33", "256"],
    "06/25/2024": ["158", "46", "448"],
    "06/26/2024": ["123", "64", "590"],
    "06/27/2024": ["240", "63", "247"],
    "06/28/2024": ["668", "08", "990"],
    "06/29/2024": ["480", "29", "270"],
    "06/30/2024": ["***", "**", "***"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["489", "19", "469"],
    "07/02/2024": ["236", "13", "139"],
    "07/03/2024": ["156", "29", "199"],
    "07/04/2024": ["780", "56", "123"],
    "07/05/2024": ["179", "75", "159"],
    "07/06/2024": ["557", "79", "478"],
    "07/07/2024": ["***", "**", "***"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["259", "68", "468"],
    "07/09/2024": ["113", "53", "238"],
    "07/10/2024": ["159", "57", "133"],
    "07/11/2024": ["588", "14", "248"],
    "07/12/2024": ["468", "81", "290"],
    "07/13/2024": ["480", "26", "457"],
    "07/14/2024": ["***", "**", "***"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["580", "30", "550"],
    "07/16/2024": ["579", "14", "590"],
    "07/17/2024": ["339", "54", "149"],
    "07/18/2024": ["489", "12", "156"],
    "07/19/2024": ["356", "40", "479"],
    "07/20/2024": ["233", "82", "570"],
    "07/21/2024": ["***", "**", "***"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["458", "70", "370"],
    "07/23/2024": ["348", "54", "590"],
    "07/24/2024": ["288", "85", "258"],
    "07/25/2024": ["158", "41", "678"],
    "07/26/2024": ["340", "74", "446"],
    "07/27/2024": ["570", "29", "469"],
    "07/28/2024": ["***", "**", "***"],
  },
};

export const MILANDAY = {
  "01/02/2023 to 01/08/2023": {
    "01/02/2023": ["260", "86", "330"],
    "01/03/2023": ["477", "85", "357"],
    "01/04/2023": ["235", "02", "688"],
    "01/05/2023": ["460", "05", "168"],
    "01/06/2023": ["177", "52", "480"],
    "01/07/2023": ["123", "61", "290"],
    "01/08/2023": ["***", "**", "***"],
  },
  "01/09/2023 to 01/15/2023": {
    "01/09/2023": ["388", "95", "249"],
    "01/10/2023": ["339", "53", "120"],
    "01/11/2023": ["380", "18", "800"],
    "01/12/2023": ["127", "09", "379"],
    "01/13/2023": ["168", "53", "300"],
    "01/14/2023": ["299", "06", "268"],
    "01/15/2023": ["***", "**", "***"],
  },
  "01/16/2023 to 01/22/2023": {
    "01/16/2023": ["138", "26", "600"],
    "01/17/2023": ["137", "12", "156"],
    "01/18/2023": ["340", "70", "299"],
    "01/19/2023": ["669", "12", "336"],
    "01/20/2023": ["269", "73", "599"],
    "01/21/2023": ["590", "48", "224"],
    "01/22/2023": ["***", "**", "***"],
  },
  "01/23/2023 to 01/29/2023": {
    "01/23/2023": ["359", "77", "359"],
    "01/24/2023": ["770", "44", "130"],
    "01/25/2023": ["145", "09", "289"],
    "01/26/2023": ["***", "**", "***"],
    "01/27/2023": ["127", "09", "478"],
    "01/28/2023": ["460", "03", "779"],
    "01/29/2023": ["***", "**", "***"],
  },
  "01/30/2023 to 02/05/2023": {
    "01/30/2023": ["459", "88", "125"],
    "01/31/2023": ["239", "48", "567"],
    "02/01/2023": ["590", "49", "469"],
    "02/02/2023": ["789", "44", "257"],
    "02/03/2023": ["668", "07", "449"],
    "02/04/2023": ["248", "49", "117"],
    "02/05/2023": ["***", "**", "***"],
  },
  "02/06/2023 to 02/12/2023": {
    "02/06/2023": ["133", "74", "248"],
    "02/07/2023": ["570", "26", "240"],
    "02/08/2023": ["478", "96", "349"],
    "02/09/2023": ["230", "57", "115"],
    "02/10/2023": ["225", "97", "449"],
    "02/11/2023": ["123", "62", "499"],
    "02/12/2023": ["***", "**", "***"],
  },
  "02/13/2023 to 02/19/2023": {
    "02/13/2023": ["259", "62", "156"],
    "02/14/2023": ["150", "68", "567"],
    "02/15/2023": ["223", "77", "278"],
    "02/16/2023": ["123", "64", "789"],
    "02/17/2023": ["470", "14", "680"],
    "02/18/2023": ["234", "94", "130"],
    "02/19/2023": ["***", "**", "***"],
  },
  "02/20/2023 to 02/26/2023": {
    "02/20/2023": ["890", "72", "345"],
    "02/21/2023": ["589", "23", "580"],
    "02/22/2023": ["380", "16", "790"],
    "02/23/2023": ["158", "49", "360"],
    "02/24/2023": ["128", "15", "799"],
    "02/25/2023": ["115", "77", "377"],
    "02/26/2023": ["***", "**", "***"],
  },
  "02/27/2023 to 03/05/2023": {
    "02/27/2023": ["488", "00", "460"],
    "02/28/2023": ["336", "22", "589"],
    "03/01/2023": ["779", "34", "130"],
    "03/02/2023": ["147", "27", "890"],
    "03/03/2023": ["169", "66", "457"],
    "03/04/2023": ["129", "29", "568"],
    "03/05/2023": ["***", "**", "***"],
  },
  "03/06/2023 to 03/12/2023": {
    "03/06/2023": ["249", "53", "346"],
    "03/07/2023": ["248", "45", "258"],
    "03/08/2023": ["***", "**", "***"],
    "03/09/2023": ["499", "21", "470"],
    "03/10/2023": ["367", "63", "300"],
    "03/11/2023": ["670", "35", "348"],
    "03/12/2023": ["***", "**", "***"],
  },
  "03/13/2023 to 03/19/2023": {
    "03/13/2023": ["679", "27", "700"],
    "03/14/2023": ["148", "30", "479"],
    "03/15/2023": ["460", "00", "460"],
    "03/16/2023": ["249", "57", "458"],
    "03/17/2023": ["258", "51", "489"],
    "03/18/2023": ["247", "33", "166"],
    "03/19/2023": ["***", "**", "***"],
  },
  "03/20/2023 to 03/26/2023": {
    "03/20/2023": ["266", "40", "389"],
    "03/21/2023": ["123", "60", "370"],
    "03/22/2023": ["127", "01", "146"],
    "03/23/2023": ["124", "75", "267"],
    "03/24/2023": ["239", "44", "149"],
    "03/25/2023": ["347", "42", "679"],
    "03/26/2023": ["***", "**", "***"],
  },
  "03/27/2023 to 04/02/2023": {
    "03/27/2023": ["257", "42", "480"],
    "03/28/2023": ["568", "96", "240"],
    "03/29/2023": ["589", "24", "699"],
    "03/30/2023": ["240", "63", "139"],
    "03/31/2023": ["124", "76", "123"],
    "04/01/2023": ["130", "41", "290"],
    "04/02/2023": ["***", "**", "***"],
  },
  "04/03/2023 to 04/09/2023": {
    "04/03/2023": ["112", "41", "470"],
    "04/04/2023": ["677", "09", "388"],
    "04/05/2023": ["225", "93", "157"],
    "04/06/2023": ["478", "96", "169"],
    "04/07/2023": ["679", "20", "569"],
    "04/08/2023": ["456", "59", "289"],
    "04/09/2023": ["***", "**", "***"],
  },
  "04/10/2023 to 04/16/2023": {
    "04/10/2023": ["588", "11", "100"],
    "04/11/2023": ["300", "31", "560"],
    "04/12/2023": ["355", "36", "277"],
    "04/13/2023": ["269", "79", "289"],
    "04/14/2023": ["479", "07", "179"],
    "04/15/2023": ["149", "41", "146"],
    "04/16/2023": ["***", "**", "***"],
  },
  "04/17/2023 to 04/23/2023": {
    "04/17/2023": ["588", "10", "235"],
    "04/18/2023": ["780", "52", "480"],
    "04/19/2023": ["890", "76", "899"],
    "04/20/2023": ["146", "15", "159"],
    "04/21/2023": ["133", "74", "239"],
    "04/22/2023": ["477", "83", "670"],
    "04/23/2023": ["***", "**", "***"],
  },
  "04/24/2023 to 04/30/2023": {
    "04/24/2023": ["137", "10", "136"],
    "04/25/2023": ["499", "21", "245"],
    "04/26/2023": ["670", "34", "680"],
    "04/27/2023": ["169", "64", "167"],
    "04/28/2023": ["370", "07", "269"],
    "04/29/2023": ["234", "90", "370"],
    "04/30/2023": ["***", "**", "***"],
  },
  "05/01/2023 to 05/07/2023": {
    "05/01/2023": ["179", "72", "688"],
    "05/02/2023": ["448", "68", "116"],
    "05/03/2023": ["679", "29", "577"],
    "05/04/2023": ["480", "28", "378"],
    "05/05/2023": ["139", "31", "335"],
    "05/06/2023": ["117", "93", "355"],
    "05/07/2023": ["***", "**", "***"],
  },
  "05/08/2023 to 05/14/2023": {
    "05/08/2023": ["560", "19", "478"],
    "05/09/2023": ["347", "47", "449"],
    "05/10/2023": ["278", "73", "120"],
    "05/11/2023": ["600", "65", "500"],
    "05/12/2023": ["990", "83", "670"],
    "05/13/2023": ["450", "98", "116"],
    "05/14/2023": ["***", "**", "***"],
  },
  "05/15/2023 to 05/21/2023": {
    "05/15/2023": ["770", "41", "344"],
    "05/16/2023": ["150", "67", "250"],
    "05/17/2023": ["122", "58", "800"],
    "05/18/2023": ["220", "41", "146"],
    "05/19/2023": ["129", "23", "788"],
    "05/20/2023": ["124", "74", "590"],
    "05/21/2023": ["***", "**", "***"],
  },
  "05/22/2023 to 05/28/2023": {
    "05/22/2023": ["267", "58", "260"],
    "05/23/2023": ["889", "50", "488"],
    "05/24/2023": ["335", "12", "589"],
    "05/25/2023": ["279", "80", "578"],
    "05/26/2023": ["260", "82", "480"],
    "05/27/2023": ["348", "58", "279"],
    "05/28/2023": ["***", "**", "***"],
  },
  "05/29/2023 to 06/04/2023": {
    "05/29/2023": ["225", "99", "199"],
    "05/30/2023": ["179", "74", "770"],
    "05/31/2023": ["578", "09", "478"],
    "06/01/2023": ["566", "76", "358"],
    "06/02/2023": ["125", "83", "238"],
    "06/03/2023": ["167", "42", "679"],
    "06/04/2023": ["***", "**", "***"],
  },
  "06/05/2023 to 06/11/2023": {
    "06/05/2023": ["567", "89", "135"],
    "06/06/2023": ["268", "65", "230"],
    "06/07/2023": ["790", "68", "189"],
    "06/08/2023": ["578", "08", "369"],
    "06/09/2023": ["236", "10", "389"],
    "06/10/2023": ["680", "49", "388"],
    "06/11/2023": ["***", "**", "***"],
  },
  "06/12/2023 to 06/18/2023": {
    "06/12/2023": ["440", "82", "147"],
    "06/13/2023": ["299", "00", "389"],
    "06/14/2023": ["347", "47", "566"],
    "06/15/2023": ["269", "70", "127"],
    "06/16/2023": ["127", "02", "345"],
    "06/17/2023": ["170", "81", "236"],
    "06/18/2023": ["***", "**", "***"],
  },
  "06/19/2023 to 06/25/2023": {
    "06/19/2023": ["399", "19", "379"],
    "06/20/2023": ["346", "33", "788"],
    "06/21/2023": ["567", "88", "170"],
    "06/22/2023": ["467", "77", "133"],
    "06/23/2023": ["124", "76", "169"],
    "06/24/2023": ["567", "88", "125"],
    "06/25/2023": ["***", "**", "***"],
  },
  "06/26/2023 to 07/02/2023": {
    "06/26/2023": ["249", "57", "223"],
    "06/27/2023": ["668", "02", "228"],
    "06/28/2023": ["660", "23", "689"],
    "06/29/2023": ["344", "18", "116"],
    "06/30/2023": ["380", "12", "156"],
    "07/01/2023": ["124", "73", "120"],
    "07/02/2023": ["***", "**", "***"],
  },
  "07/03/2023 to 07/09/2023": {
    "07/03/2023": ["234", "99", "568"],
    "07/04/2023": ["345", "26", "600"],
    "07/05/2023": ["467", "71", "119"],
    "07/06/2023": ["230", "54", "888"],
    "07/07/2023": ["799", "50", "578"],
    "07/08/2023": ["468", "86", "899"],
    "07/09/2023": ["***", "**", "***"],
  },
  "07/10/2023 to 07/16/2023": {
    "07/10/2023": ["115", "71", "335"],
    "07/11/2023": ["134", "89", "478"],
    "07/12/2023": ["136", "03", "229"],
    "07/13/2023": ["788", "32", "336"],
    "07/14/2023": ["590", "43", "148"],
    "07/15/2023": ["137", "12", "345"],
    "07/16/2023": ["***", "**", "***"],
  },
  "07/17/2023 to 07/23/2023": {
    "07/17/2023": ["359", "74", "167"],
    "07/18/2023": ["148", "35", "249"],
    "07/19/2023": ["157", "33", "779"],
    "07/20/2023": ["166", "39", "450"],
    "07/21/2023": ["126", "98", "279"],
    "07/22/2023": ["670", "33", "490"],
    "07/23/2023": ["***", "**", "***"],
  },
  "07/24/2023 to 07/30/2023": {
    "07/24/2023": ["170", "81", "470"],
    "07/25/2023": ["446", "42", "679"],
    "07/26/2023": ["238", "30", "190"],
    "07/27/2023": ["199", "96", "790"],
    "07/28/2023": ["145", "07", "250"],
    "07/29/2023": ["145", "02", "246"],
    "07/30/2023": ["***", "**", "***"],
  },
  "07/31/2023 to 08/06/2023": {
    "07/31/2023": ["557", "70", "235"],
    "08/01/2023": ["123", "63", "238"],
    "08/02/2023": ["158", "45", "168"],
    "08/03/2023": ["280", "07", "115"],
    "08/04/2023": ["369", "84", "239"],
    "08/05/2023": ["490", "38", "134"],
    "08/06/2023": ["***", "**", "***"],
  },
  "08/07/2023 to 08/13/2023": {
    "08/07/2023": ["369", "89", "234"],
    "08/08/2023": ["347", "43", "670"],
    "08/09/2023": ["568", "90", "136"],
    "08/10/2023": ["440", "83", "599"],
    "08/11/2023": ["120", "36", "880"],
    "08/12/2023": ["349", "66", "240"],
    "08/13/2023": ["***", "**", "***"],
  },
  "08/14/2023 to 08/20/2023": {
    "08/14/2023": ["357", "57", "223"],
    "08/15/2023": ["***", "**", "***"],
    "08/16/2023": ["137", "11", "245"],
    "08/17/2023": ["167", "46", "790"],
    "08/18/2023": ["456", "55", "357"],
    "08/19/2023": ["270", "92", "246"],
    "08/20/2023": ["***", "**", "***"],
  },
  "08/21/2023 to 08/27/2023": {
    "08/21/2023": ["***", "**", "***"],
    "08/22/2023": ["223", "71", "236"],
    "08/23/2023": ["890", "70", "190"],
    "08/24/2023": ["125", "87", "269"],
    "08/25/2023": ["559", "98", "567"],
    "08/26/2023": ["245", "15", "249"],
    "08/27/2023": ["***", "**", "***"],
  },
  "08/28/2023 to 09/03/2023": {
    "08/28/2023": ["224", "88", "279"],
    "08/29/2023": ["134", "88", "125"],
    "08/30/2023": ["160", "70", "389"],
    "08/31/2023": ["670", "31", "470"],
    "09/01/2023": ["113", "51", "128"],
    "09/02/2023": ["668", "05", "249"],
    "09/03/2023": ["***", "**", "***"],
  },
  "09/04/2023 to 09/10/2023": {
    "09/04/2023": ["357", "52", "589"],
    "09/05/2023": ["160", "72", "480"],
    "09/06/2023": ["233", "80", "190"],
    "09/07/2023": ["689", "31", "678"],
    "09/08/2023": ["166", "39", "180"],
    "09/09/2023": ["135", "94", "266"],
    "09/10/2023": ["***", "**", "***"],
  },
  "09/11/2023 to 09/17/2023": {
    "09/11/2023": ["440", "87", "557"],
    "09/12/2023": ["470", "16", "349"],
    "09/13/2023": ["470", "17", "458"],
    "09/14/2023": ["259", "69", "360"],
    "09/15/2023": ["128", "15", "690"],
    "09/16/2023": ["347", "49", "388"],
    "09/17/2023": ["***", "**", "***"],
  },
  "09/18/2023 to 09/24/2023": {
    "09/18/2023": ["122", "50", "668"],
    "09/19/2023": ["440", "80", "299"],
    "09/20/2023": ["399", "11", "137"],
    "09/21/2023": ["178", "62", "237"],
    "09/22/2023": ["340", "78", "125"],
    "09/23/2023": ["699", "43", "337"],
    "09/24/2023": ["***", "**", "***"],
  },
  "09/25/2023 to 10/01/2023": {
    "09/25/2023": ["235", "02", "237"],
    "09/26/2023": ["356", "48", "468"],
    "09/27/2023": ["166", "31", "146"],
    "09/28/2023": ["289", "95", "177"],
    "09/29/2023": ["355", "38", "116"],
    "09/30/2023": ["340", "72", "129"],
    "10/01/2023": ["***", "**", "***"],
  },
  "10/02/2023 to 10/08/2023": {
    "10/02/2023": ["355", "30", "299"],
    "10/03/2023": ["456", "55", "230"],
    "10/04/2023": ["380", "12", "480"],
    "10/05/2023": ["155", "18", "260"],
    "10/06/2023": ["136", "01", "678"],
    "10/07/2023": ["258", "54", "770"],
    "10/08/2023": ["***", "**", "***"],
  },
  "10/09/2023 to 10/15/2023": {
    "10/09/2023": ["458", "72", "589"],
    "10/10/2023": ["570", "27", "160"],
    "10/11/2023": ["788", "33", "120"],
    "10/12/2023": ["370", "09", "135"],
    "10/13/2023": ["469", "96", "457"],
    "10/14/2023": ["127", "04", "266"],
    "10/15/2023": ["***", "**", "***"],
  },
  "10/16/2023 to 10/22/2023": {
    "10/16/2023": ["800", "82", "778"],
    "10/17/2023": ["450", "99", "126"],
    "10/18/2023": ["357", "51", "678"],
    "10/19/2023": ["240", "63", "788"],
    "10/20/2023": ["146", "11", "678"],
    "10/21/2023": ["567", "86", "367"],
    "10/22/2023": ["***", "**", "***"],
  },
  "10/23/2023 to 10/29/2023": {
    "10/23/2023": ["240", "68", "224"],
    "10/24/2023": ["259", "60", "235"],
    "10/25/2023": ["170", "83", "148"],
    "10/26/2023": ["125", "80", "479"],
    "10/27/2023": ["890", "78", "170"],
    "10/28/2023": ["779", "32", "390"],
    "10/29/2023": ["***", "**", "***"],
  },
  "10/30/2023 to 11/05/2023": {
    "10/30/2023": ["369", "82", "336"],
    "10/31/2023": ["447", "56", "349"],
    "11/01/2023": ["336", "29", "117"],
    "11/02/2023": ["128", "14", "158"],
    "11/03/2023": ["135", "92", "156"],
    "11/04/2023": ["145", "04", "220"],
    "11/05/2023": ["***", "**", "***"],
  },
  "11/06/2023 to 11/12/2023": {
    "11/06/2023": ["146", "16", "268"],
    "11/07/2023": ["120", "33", "445"],
    "11/08/2023": ["389", "09", "289"],
    "11/09/2023": ["248", "42", "138"],
    "11/10/2023": ["***", "**", "***"],
    "11/11/2023": ["***", "**", "***"],
    "11/12/2023": ["***", "**", "***"],
  },
  "11/13/2023 to 11/19/2023": {
    "11/13/2023": ["***", "**", "***"],
    "11/14/2023": ["***", "**", "***"],
    "11/15/2023": ["***", "**", "***"],
    "11/16/2023": ["***", "**", "***"],
    "11/17/2023": ["***", "**", "***"],
    "11/18/2023": ["***", "**", "***"],
    "11/19/2023": ["***", "**", "***"],
  },
  "11/20/2023 to 11/26/2023": {
    "11/20/2023": ["337", "39", "478"],
    "11/21/2023": ["119", "15", "366"],
    "11/22/2023": ["770", "49", "360"],
    "11/23/2023": ["248", "46", "358"],
    "11/24/2023": ["334", "06", "349"],
    "11/25/2023": ["134", "89", "478"],
    "11/26/2023": ["***", "**", "***"],
  },
  "11/27/2023 to 12/03/2023": {
    "11/27/2023": ["236", "10", "569"],
    "11/28/2023": ["127", "03", "247"],
    "11/29/2023": ["299", "01", "678"],
    "11/30/2023": ["690", "52", "589"],
    "12/01/2023": ["780", "51", "399"],
    "12/02/2023": ["123", "62", "147"],
    "12/03/2023": ["***", "**", "***"],
  },
  "12/04/2023 to 12/10/2023": {
    "12/04/2023": ["345", "23", "120"],
    "12/05/2023": ["130", "49", "270"],
    "12/06/2023": ["799", "55", "500"],
    "12/07/2023": ["450", "99", "126"],
    "12/08/2023": ["340", "70", "226"],
    "12/09/2023": ["580", "39", "450"],
    "12/10/2023": ["***", "**", "***"],
  },
  "12/11/2023 to 12/17/2023": {
    "12/11/2023": ["148", "35", "339"],
    "12/12/2023": ["558", "84", "789"],
    "12/13/2023": ["400", "49", "225"],
    "12/14/2023": ["160", "74", "130"],
    "12/15/2023": ["799", "54", "455"],
    "12/16/2023": ["288", "81", "155"],
    "12/17/2023": ["***", "**", "***"],
  },
  "12/18/2023 to 12/24/2023": {
    "12/18/2023": ["366", "53", "120"],
    "12/19/2023": ["347", "43", "337"],
    "12/20/2023": ["390", "23", "670"],
    "12/21/2023": ["447", "58", "800"],
    "12/22/2023": ["267", "58", "279"],
    "12/23/2023": ["446", "44", "356"],
    "12/24/2023": ["***", "**", "***"],
  },
  "12/25/2023 to 12/31/2023": {
    "12/25/2023": ["380", "11", "380"],
    "12/26/2023": ["240", "63", "247"],
    "12/27/2023": ["348", "55", "357"],
    "12/28/2023": ["199", "91", "137"],
    "12/29/2023": ["357", "58", "378"],
    "12/30/2023": ["260", "81", "119"],
    "12/31/2023": ["***", "**", "***"],
  },
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["399", "15", "168"],
    "01/02/2024": ["458", "72", "138"],
    "01/03/2024": ["246", "26", "899"],
    "01/04/2024": ["450", "96", "358"],
    "01/05/2024": ["290", "16", "150"],
    "01/06/2024": ["357", "55", "249"],
    "01/07/2024": ["***", "**", "***"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["269", "76", "114"],
    "01/09/2024": ["115", "74", "149"],
    "01/10/2024": ["125", "88", "279"],
    "01/11/2024": ["188", "76", "899"],
    "01/12/2024": ["135", "93", "120"],
    "01/13/2024": ["469", "98", "459"],
    "01/14/2024": ["***", "**", "***"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["470", "19", "126"],
    "01/16/2024": ["159", "56", "169"],
    "01/17/2024": ["477", "81", "669"],
    "01/18/2024": ["146", "17", "458"],
    "01/19/2024": ["389", "02", "156"],
    "01/20/2024": ["289", "96", "330"],
    "01/21/2024": ["***", "**", "***"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["247", "39", "568"],
    "01/23/2024": ["134", "87", "133"],
    "01/24/2024": ["140", "52", "147"],
    "01/25/2024": ["448", "65", "780"],
    "01/26/2024": ["***", "**", "***"],
    "01/27/2024": ["118", "02", "129"],
    "01/28/2024": ["***", "**", "***"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["560", "17", "700"],
    "01/30/2024": ["358", "64", "356"],
    "01/31/2024": ["235", "07", "278"],
    "02/01/2024": ["237", "26", "330"],
    "02/02/2024": ["469", "91", "245"],
    "02/03/2024": ["270", "98", "260"],
    "02/04/2024": ["***", "**", "***"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["289", "98", "125"],
    "02/06/2024": ["157", "30", "668"],
    "02/07/2024": ["240", "62", "345"],
    "02/08/2024": ["128", "13", "689"],
    "02/09/2024": ["137", "17", "179"],
    "02/10/2024": ["389", "01", "560"],
    "02/11/2024": ["***", "**", "***"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["677", "06", "367"],
    "02/13/2024": ["288", "80", "578"],
    "02/14/2024": ["379", "95", "140"],
    "02/15/2024": ["338", "43", "229"],
    "02/16/2024": ["889", "55", "690"],
    "02/17/2024": ["359", "78", "279"],
    "02/18/2024": ["***", "**", "***"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["240", "63", "337"],
    "02/20/2024": ["159", "51", "245"],
    "02/21/2024": ["380", "15", "267"],
    "02/22/2024": ["257", "41", "236"],
    "02/23/2024": ["449", "71", "146"],
    "02/24/2024": ["347", "40", "578"],
    "02/25/2024": ["***", "**", "***"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["144", "97", "223"],
    "02/27/2024": ["118", "08", "477"],
    "02/28/2024": ["145", "09", "234"],
    "02/29/2024": ["480", "26", "123"],
    "03/01/2024": ["345", "27", "368"],
    "03/02/2024": ["160", "76", "600"],
    "03/03/2024": ["***", "**", "***"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["188", "76", "114"],
    "03/05/2024": ["359", "73", "779"],
    "03/06/2024": ["188", "78", "116"],
    "03/07/2024": ["144", "99", "577"],
    "03/08/2024": ["160", "71", "137"],
    "03/09/2024": ["278", "71", "236"],
    "03/10/2024": ["***", "**", "***"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["468", "83", "355"],
    "03/12/2024": ["245", "16", "114"],
    "03/13/2024": ["500", "59", "117"],
    "03/14/2024": ["370", "08", "224"],
    "03/15/2024": ["447", "54", "400"],
    "03/16/2024": ["169", "65", "140"],
    "03/17/2024": ["***", "**", "***"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["269", "77", "458"],
    "03/19/2024": ["250", "75", "447"],
    "03/20/2024": ["236", "11", "399"],
    "03/21/2024": ["379", "98", "260"],
    "03/22/2024": ["124", "70", "280"],
    "03/23/2024": ["280", "01", "399"],
    "03/24/2024": ["***", "**", "***"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["***", "**", "***"],
    "03/26/2024": ["577", "94", "347"],
    "03/27/2024": ["369", "83", "120"],
    "03/28/2024": ["479", "04", "266"],
    "03/29/2024": ["248", "49", "360"],
    "03/30/2024": ["458", "79", "234"],
    "03/31/2024": ["***", "**", "***"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["489", "10", "244"],
    "04/02/2024": ["270", "92", "480"],
    "04/03/2024": ["568", "91", "335"],
    "04/04/2024": ["190", "03", "779"],
    "04/05/2024": ["588", "18", "233"],
    "04/06/2024": ["123", "63", "247"],
    "04/07/2024": ["***", "**", "***"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["246", "25", "249"],
    "04/09/2024": ["448", "60", "226"],
    "04/10/2024": ["249", "56", "268"],
    "04/11/2024": ["148", "37", "557"],
    "04/12/2024": ["138", "21", "579"],
    "04/13/2024": ["138", "26", "259"],
    "04/14/2024": ["***", "**", "***"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["269", "70", "668"],
    "04/16/2024": ["340", "72", "570"],
    "04/17/2024": ["778", "25", "177"],
    "04/18/2024": ["125", "88", "350"],
    "04/19/2024": ["567", "86", "178"],
    "04/20/2024": ["370", "04", "167"],
    "04/21/2024": ["***", "**", "***"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["246", "27", "179"],
    "04/23/2024": ["235", "07", "700"],
    "04/24/2024": ["358", "66", "358"],
    "04/25/2024": ["226", "06", "240"],
    "04/26/2024": ["445", "31", "119"],
    "04/27/2024": ["260", "80", "235"],
    "04/28/2024": ["***", "**", "***"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["346", "33", "139"],
    "04/30/2024": ["189", "89", "126"],
    "05/01/2024": ["469", "95", "357"],
    "05/02/2024": ["369", "89", "379"],
    "05/03/2024": ["177", "56", "169"],
    "05/04/2024": ["268", "66", "178"],
    "05/05/2024": ["***", "**", "***"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["600", "64", "158"],
    "05/07/2024": ["179", "79", "135"],
    "05/08/2024": ["357", "54", "347"],
    "05/09/2024": ["688", "29", "199"],
    "05/10/2024": ["900", "94", "112"],
    "05/11/2024": ["124", "77", "269"],
    "05/12/2024": ["***", "**", "***"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["279", "82", "138"],
    "05/14/2024": ["147", "29", "234"],
    "05/15/2024": ["167", "49", "379"],
    "05/16/2024": ["457", "65", "140"],
    "05/17/2024": ["334", "08", "170"],
    "05/18/2024": ["599", "37", "115"],
    "05/19/2024": ["***", "**", "***"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["114", "65", "690"],
    "05/21/2024": ["160", "77", "340"],
    "05/22/2024": ["135", "94", "789"],
    "05/23/2024": ["257", "43", "670"],
    "05/24/2024": ["689", "35", "267"],
    "05/25/2024": ["238", "38", "170"],
    "05/26/2024": ["***", "**", "***"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["339", "59", "270"],
    "05/28/2024": ["479", "02", "246"],
    "05/29/2024": ["990", "86", "259"],
    "05/30/2024": ["380", "16", "178"],
    "05/31/2024": ["344", "10", "136"],
    "06/01/2024": ["577", "98", "440"],
    "06/02/2024": ["***", "**", "***"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["127", "01", "146"],
    "06/04/2024": ["240", "62", "147"],
    "06/05/2024": ["349", "69", "333"],
    "06/06/2024": ["345", "25", "267"],
    "06/07/2024": ["588", "12", "480"],
    "06/08/2024": ["234", "94", "257"],
    "06/09/2024": ["***", "**", "***"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["468", "86", "880"],
    "06/11/2024": ["167", "44", "158"],
    "06/12/2024": ["146", "15", "258"],
    "06/13/2024": ["670", "39", "667"],
    "06/14/2024": ["178", "60", "370"],
    "06/15/2024": ["340", "78", "558"],
    "06/16/2024": ["***", "**", "***"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["334", "00", "235"],
    "06/18/2024": ["290", "14", "158"],
    "06/19/2024": ["490", "32", "147"],
    "06/20/2024": ["149", "47", "467"],
    "06/21/2024": ["128", "10", "235"],
    "06/22/2024": ["445", "31", "128"],
    "06/23/2024": ["***", "**", "***"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["112", "40", "145"],
    "06/25/2024": ["880", "66", "123"],
    "06/26/2024": ["337", "34", "167"],
    "06/27/2024": ["236", "16", "880"],
    "06/28/2024": ["260", "88", "260"],
    "06/29/2024": ["257", "49", "180"],
    "06/30/2024": ["***", "**", "***"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["115", "71", "399"],
    "07/02/2024": ["237", "21", "560"],
    "07/03/2024": ["230", "50", "389"],
    "07/04/2024": ["113", "54", "400"],
    "07/05/2024": ["280", "08", "224"],
    "07/06/2024": ["144", "91", "245"],
    "07/07/2024": ["***", "**", "***"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["456", "50", "299"],
    "07/09/2024": ["568", "96", "466"],
    "07/10/2024": ["789", "41", "245"],
    "07/11/2024": ["229", "38", "468"],
    "07/12/2024": ["150", "67", "890"],
    "07/13/2024": ["369", "83", "120"],
    "07/14/2024": ["***", "**", "***"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["660", "26", "150"],
    "07/16/2024": ["360", "97", "278"],
    "07/17/2024": ["270", "99", "379"],
    "07/18/2024": ["115", "78", "224"],
    "07/19/2024": ["567", "87", "467"],
    "07/20/2024": ["267", "58", "468"],
    "07/21/2024": ["***", "**", "***"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["470", "17", "467"],
    "07/23/2024": ["899", "67", "467"],
    "07/24/2024": ["140", "57", "160"],
    "07/25/2024": ["369", "89", "568"],
    "07/26/2024": ["480", "20", "370"],
    "07/27/2024": ["480", "28", "170"],
    "07/28/2024": ["***", "**", "***"],
  },
};

export const KALYAN = {
  "01/02/2023 to 01/08/2023": {
    "01/02/2023": ["260", "87", "359"],
    "01/03/2023": ["137", "11", "588"],
    "01/04/2023": ["678", "11", "380"],
    "01/05/2023": ["567", "85", "348"],
    "01/06/2023": ["579", "17", "467"],
    "01/07/2023": ["470", "19", "126"],
    "01/08/2023": ["***", "**", "***"],
  },
  "01/09/2023 to 01/15/2023": {
    "01/09/2023": ["469", "97", "368"],
    "01/10/2023": ["247", "35", "230"],
    "01/11/2023": ["359", "79", "180"],
    "01/12/2023": ["690", "57", "124"],
    "01/13/2023": ["179", "72", "246"],
    "01/14/2023": ["247", "33", "670"],
    "01/15/2023": ["***", "**", "***"],
  },
  "01/16/2023 to 01/22/2023": {
    "01/16/2023": ["460", "02", "110"],
    "01/17/2023": ["669", "15", "122"],
    "01/18/2023": ["245", "15", "168"],
    "01/19/2023": ["899", "69", "450"],
    "01/20/2023": ["360", "96", "790"],
    "01/21/2023": ["459", "82", "246"],
    "01/22/2023": ["***", "**", "***"],
  },
  "01/23/2023 to 01/29/2023": {
    "01/23/2023": ["225", "94", "167"],
    "01/24/2023": ["177", "56", "123"],
    "01/25/2023": ["378", "80", "389"],
    "01/26/2023": ["***", "**", "***"],
    "01/27/2023": ["279", "89", "568"],
    "01/28/2023": ["137", "15", "230"],
    "01/29/2023": ["***", "**", "***"],
  },
  "01/30/2023 to 02/05/2023": {
    "01/30/2023": ["338", "48", "170"],
    "01/31/2023": ["256", "38", "125"],
    "02/01/2023": ["130", "48", "378"],
    "02/02/2023": ["580", "30", "569"],
    "02/03/2023": ["400", "47", "368"],
    "02/04/2023": ["236", "12", "570"],
    "02/05/2023": ["***", "**", "***"],
  },
  "02/06/2023 to 02/12/2023": {
    "02/06/2023": ["266", "44", "130"],
    "02/07/2023": ["199", "95", "168"],
    "02/08/2023": ["100", "11", "470"],
    "02/09/2023": ["129", "22", "679"],
    "02/10/2023": ["129", "22", "679"],
    "02/11/2023": ["270", "92", "589"],
    "02/12/2023": ["***", "**", "***"],
  },
  "02/13/2023 to 02/19/2023": {
    "02/13/2023": ["279", "80", "127"],
    "02/14/2023": ["599", "39", "469"],
    "02/15/2023": ["889", "52", "336"],
    "02/16/2023": ["330", "68", "189"],
    "02/17/2023": ["790", "67", "566"],
    "02/18/2023": ["700", "73", "355"],
    "02/19/2023": ["***", "**", "***"],
  },
  "02/20/2023 to 02/26/2023": {
    "02/20/2023": ["680", "43", "670"],
    "02/21/2023": ["137", "11", "579"],
    "02/22/2023": ["780", "50", "244"],
    "02/23/2023": ["380", "12", "336"],
    "02/24/2023": ["589", "26", "268"],
    "02/25/2023": ["113", "56", "240"],
    "02/26/2023": ["***", "**", "***"],
  },
  "02/27/2023 to 03/05/2023": {
    "02/27/2023": ["168", "51", "489"],
    "02/28/2023": ["199", "94", "130"],
    "03/01/2023": ["567", "85", "366"],
    "03/02/2023": ["259", "60", "334"],
    "03/03/2023": ["256", "37", "467"],
    "03/04/2023": ["579", "16", "259"],
    "03/05/2023": ["***", "**", "***"],
  },
  "03/06/2023 to 03/12/2023": {
    "03/06/2023": ["227", "14", "590"],
    "03/07/2023": ["229", "34", "590"],
    "03/08/2023": ["***", "**", "***"],
    "03/09/2023": ["568", "93", "238"],
    "03/10/2023": ["367", "61", "470"],
    "03/11/2023": ["358", "64", "248"],
    "03/12/2023": ["***", "**", "***"],
  },
  "03/13/2023 to 03/19/2023": {
    "03/13/2023": ["135", "96", "169"],
    "03/14/2023": ["479", "00", "145"],
    "03/15/2023": ["157", "30", "145"],
    "03/16/2023": ["599", "38", "378"],
    "03/17/2023": ["567", "89", "450"],
    "03/18/2023": ["459", "83", "256"],
    "03/19/2023": ["***", "**", "***"],
  },
  "03/20/2023 to 03/26/2023": {
    "03/20/2023": ["124", "76", "150"],
    "03/21/2023": ["570", "22", "679"],
    "03/22/2023": ["550", "07", "566"],
    "03/23/2023": ["126", "97", "340"],
    "03/24/2023": ["280", "01", "380"],
    "03/25/2023": ["456", "58", "134"],
    "03/26/2023": ["***", "**", "***"],
  },
  "03/27/2023 to 04/02/2023": {
    "03/27/2023": ["779", "36", "169"],
    "03/28/2023": ["567", "87", "359"],
    "03/29/2023": ["247", "37", "700"],
    "03/30/2023": ["450", "95", "168"],
    "03/31/2023": ["448", "68", "350"],
    "04/01/2023": ["145", "07", "160"],
    "04/02/2023": ["***", "**", "***"],
  },
  "04/03/2023 to 04/09/2023": {
    "04/03/2023": ["150", "60", "127"],
    "04/04/2023": ["369", "81", "137"],
    "04/05/2023": ["257", "43", "355"],
    "04/06/2023": ["269", "72", "660"],
    "04/07/2023": ["390", "26", "150"],
    "04/08/2023": ["126", "98", "567"],
    "04/09/2023": ["***", "**", "***"],
  },
  "04/10/2023 to 04/16/2023": {
    "04/10/2023": ["278", "70", "460"],
    "04/11/2023": ["235", "04", "590"],
    "04/12/2023": ["248", "42", "679"],
    "04/13/2023": ["236", "17", "250"],
    "04/14/2023": ["230", "58", "567"],
    "04/15/2023": ["300", "35", "780"],
    "04/16/2023": ["***", "**", "***"],
  },
  "04/17/2023 to 04/23/2023": {
    "04/17/2023": ["236", "12", "345"],
    "04/18/2023": ["359", "76", "169"],
    "04/19/2023": ["366", "51", "560"],
    "04/20/2023": ["589", "27", "368"],
    "04/21/2023": ["780", "52", "345"],
    "04/22/2023": ["389", "04", "257"],
    "04/23/2023": ["***", "**", "***"],
  },
  "04/24/2023 to 04/30/2023": {
    "04/24/2023": ["160", "71", "560"],
    "04/25/2023": ["266", "41", "128"],
    "04/26/2023": ["480", "28", "378"],
    "04/27/2023": ["170", "83", "139"],
    "04/28/2023": ["580", "38", "378"],
    "04/29/2023": ["280", "00", "127"],
    "04/30/2023": ["***", "**", "***"],
  },
  "05/01/2023 to 05/07/2023": {
    "05/01/2023": ["288", "88", "170"],
    "05/02/2023": ["249", "55", "456"],
    "05/03/2023": ["880", "63", "148"],
    "05/04/2023": ["557", "79", "469"],
    "05/05/2023": ["368", "71", "489"],
    "05/06/2023": ["240", "62", "589"],
    "05/07/2023": ["***", "**", "***"],
  },
  "05/08/2023 to 05/14/2023": {
    "05/08/2023": ["247", "38", "459"],
    "05/09/2023": ["229", "37", "124"],
    "05/10/2023": ["229", "35", "267"],
    "05/11/2023": ["899", "63", "670"],
    "05/12/2023": ["390", "22", "570"],
    "05/13/2023": ["456", "51", "137"],
    "05/14/2023": ["***", "**", "***"],
  },
  "05/15/2023 to 05/21/2023": {
    "05/15/2023": ["566", "71", "669"],
    "05/16/2023": ["399", "15", "357"],
    "05/17/2023": ["389", "00", "145"],
    "05/18/2023": ["239", "42", "129"],
    "05/19/2023": ["389", "06", "358"],
    "05/20/2023": ["149", "45", "456"],
    "05/21/2023": ["***", "**", "***"],
  },
  "05/22/2023 to 05/28/2023": {
    "05/22/2023": ["668", "05", "357"],
    "05/23/2023": ["337", "36", "178"],
    "05/24/2023": ["569", "06", "367"],
    "05/25/2023": ["799", "56", "240"],
    "05/26/2023": ["348", "56", "169"],
    "05/27/2023": ["569", "06", "349"],
    "05/28/2023": ["***", "**", "***"],
  },
  "05/29/2023 to 06/04/2023": {
    "05/29/2023": ["122", "54", "257"],
    "05/30/2023": ["889", "58", "459"],
    "05/31/2023": ["345", "20", "479"],
    "06/01/2023": ["459", "86", "240"],
    "06/02/2023": ["157", "37", "179"],
    "06/03/2023": ["346", "32", "138"],
    "06/04/2023": ["***", "**", "***"],
  },
  "06/05/2023 to 06/11/2023": {
    "06/05/2023": ["447", "58", "800"],
    "06/06/2023": ["267", "58", "224"],
    "06/07/2023": ["499", "20", "460"],
    "06/08/2023": ["336", "24", "248"],
    "06/09/2023": ["569", "00", "479"],
    "06/10/2023": ["237", "29", "270"],
    "06/11/2023": ["***", "**", "***"],
  },
  "06/12/2023 to 06/18/2023": {
    "06/12/2023": ["469", "92", "688"],
    "06/13/2023": ["368", "78", "459"],
    "06/14/2023": ["778", "24", "158"],
    "06/15/2023": ["137", "19", "360"],
    "06/16/2023": ["677", "01", "588"],
    "06/17/2023": ["690", "52", "200"],
    "06/18/2023": ["***", "**", "***"],
  },
  "06/19/2023 to 06/25/2023": {
    "06/19/2023": ["445", "30", "550"],
    "06/20/2023": ["179", "76", "448"],
    "06/21/2023": ["280", "03", "346"],
    "06/22/2023": ["259", "61", "588"],
    "06/23/2023": ["346", "33", "139"],
    "06/24/2023": ["359", "73", "490"],
    "06/25/2023": ["***", "**", "***"],
  },
  "06/26/2023 to 07/02/2023": {
    "06/26/2023": ["122", "55", "889"],
    "06/27/2023": ["167", "41", "344"],
    "06/28/2023": ["570", "24", "167"],
    "06/29/2023": ["345", "20", "190"],
    "06/30/2023": ["237", "26", "240"],
    "07/01/2023": ["580", "31", "128"],
    "07/02/2023": ["***", "**", "***"],
  },
  "07/03/2023 to 07/09/2023": {
    "07/03/2023": ["690", "53", "256"],
    "07/04/2023": ["167", "43", "689"],
    "07/05/2023": ["168", "56", "123"],
    "07/06/2023": ["168", "51", "146"],
    "07/07/2023": ["568", "95", "230"],
    "07/08/2023": ["567", "80", "460"],
    "07/09/2023": ["***", "**", "***"],
  },
  "07/10/2023 to 07/16/2023": {
    "07/10/2023": ["570", "27", "278"],
    "07/11/2023": ["159", "53", "157"],
    "07/12/2023": ["178", "61", "470"],
    "07/13/2023": ["568", "94", "167"],
    "07/14/2023": ["150", "61", "236"],
    "07/15/2023": ["130", "46", "899"],
    "07/16/2023": ["***", "**", "***"],
  },
  "07/17/2023 to 07/23/2023": {
    "07/17/2023": ["469", "92", "480"],
    "07/18/2023": ["700", "74", "266"],
    "07/19/2023": ["399", "14", "590"],
    "07/20/2023": ["149", "43", "490"],
    "07/21/2023": ["689", "31", "137"],
    "07/22/2023": ["488", "03", "139"],
    "07/23/2023": ["***", "**", "***"],
  },
  "07/24/2023 to 07/30/2023": {
    "07/24/2023": ["600", "63", "779"],
    "07/25/2023": ["379", "92", "237"],
    "07/26/2023": ["224", "83", "157"],
    "07/27/2023": ["700", "74", "239"],
    "07/28/2023": ["459", "86", "880"],
    "07/29/2023": ["789", "40", "668"],
    "07/30/2023": ["***", "**", "***"],
  },
  "07/31/2023 to 08/06/2023": {
    "07/31/2023": ["770", "41", "588"],
    "08/01/2023": ["300", "39", "388"],
    "08/02/2023": ["157", "35", "456"],
    "08/03/2023": ["599", "30", "136"],
    "08/04/2023": ["489", "18", "288"],
    "08/05/2023": ["778", "26", "259"],
    "08/06/2023": ["***", "**", "***"],
  },
  "08/07/2023 to 08/13/2023": {
    "08/07/2023": ["450", "96", "178"],
    "08/08/2023": ["789", "48", "170"],
    "08/09/2023": ["380", "10", "488"],
    "08/10/2023": ["140", "59", "450"],
    "08/11/2023": ["269", "78", "990"],
    "08/12/2023": ["348", "57", "359"],
    "08/13/2023": ["***", "**", "***"],
  },
  "08/14/2023 to 08/20/2023": {
    "08/14/2023": ["469", "95", "339"],
    "08/15/2023": ["***", "**", "***"],
    "08/16/2023": ["270", "98", "125"],
    "08/17/2023": ["278", "74", "248"],
    "08/18/2023": ["367", "64", "248"],
    "08/19/2023": ["233", "84", "167"],
    "08/20/2023": ["***", "**", "***"],
  },
  "08/21/2023 to 08/27/2023": {
    "08/21/2023": ["236", "13", "229"],
    "08/22/2023": ["136", "00", "578"],
    "08/23/2023": ["135", "92", "679"],
    "08/24/2023": ["578", "01", "344"],
    "08/25/2023": ["269", "76", "367"],
    "08/26/2023": ["240", "67", "269"],
    "08/27/2023": ["***", "**", "***"],
  },
  "08/28/2023 to 09/03/2023": {
    "08/28/2023": ["899", "62", "336"],
    "08/29/2023": ["110", "24", "266"],
    "08/30/2023": ["589", "22", "480"],
    "08/31/2023": ["128", "12", "246"],
    "09/01/2023": ["559", "99", "289"],
    "09/02/2023": ["790", "60", "578"],
    "09/03/2023": ["***", "**", "***"],
  },
  "09/04/2023 to 09/10/2023": {
    "09/04/2023": ["290", "18", "459"],
    "09/05/2023": ["345", "29", "559"],
    "09/06/2023": ["336", "25", "889"],
    "09/07/2023": ["380", "19", "234"],
    "09/08/2023": ["245", "18", "468"],
    "09/09/2023": ["780", "51", "236"],
    "09/10/2023": ["***", "**", "***"],
  },
  "09/11/2023 to 09/17/2023": {
    "09/11/2023": ["568", "95", "357"],
    "09/12/2023": ["188", "77", "890"],
    "09/13/2023": ["600", "69", "568"],
    "09/14/2023": ["346", "37", "700"],
    "09/15/2023": ["567", "81", "560"],
    "09/16/2023": ["290", "12", "237"],
    "09/17/2023": ["***", "**", "***"],
  },
  "09/18/2023 to 09/24/2023": {
    "09/18/2023": ["230", "57", "467"],
    "09/19/2023": ["580", "32", "480"],
    "09/20/2023": ["136", "03", "247"],
    "09/21/2023": ["368", "70", "136"],
    "09/22/2023": ["144", "95", "159"],
    "09/23/2023": ["249", "54", "789"],
    "09/24/2023": ["***", "**", "***"],
  },
  "09/25/2023 to 10/01/2023": {
    "09/25/2023": ["368", "78", "990"],
    "09/26/2023": ["367", "60", "235"],
    "09/27/2023": ["399", "10", "145"],
    "09/28/2023": ["677", "06", "790"],
    "09/29/2023": ["570", "28", "288"],
    "09/30/2023": ["148", "39", "450"],
    "10/01/2023": ["***", "**", "***"],
  },
  "10/02/2023 to 10/08/2023": {
    "10/02/2023": ["114", "68", "170"],
    "10/03/2023": ["355", "33", "256"],
    "10/04/2023": ["460", "00", "226"],
    "10/05/2023": ["266", "45", "168"],
    "10/06/2023": ["690", "52", "255"],
    "10/07/2023": ["479", "01", "236"],
    "10/08/2023": ["***", "**", "***"],
  },
  "10/09/2023 to 10/15/2023": {
    "10/09/2023": ["368", "75", "456"],
    "10/10/2023": ["490", "37", "133"],
    "10/11/2023": ["178", "67", "467"],
    "10/12/2023": ["156", "26", "367"],
    "10/13/2023": ["180", "90", "136"],
    "10/14/2023": ["459", "89", "180"],
    "10/15/2023": ["***", "**", "***"],
  },
  "10/16/2023 to 10/22/2023": {
    "10/16/2023": ["268", "67", "179"],
    "10/17/2023": ["667", "90", "145"],
    "10/18/2023": ["199", "95", "357"],
    "10/19/2023": ["680", "44", "158"],
    "10/20/2023": ["229", "35", "447"],
    "10/21/2023": ["566", "72", "237"],
    "10/22/2023": ["***", "**", "***"],
  },
  "10/23/2023 to 10/29/2023": {
    "10/23/2023": ["880", "67", "566"],
    "10/24/2023": ["223", "75", "799"],
    "10/25/2023": ["689", "39", "379"],
    "10/26/2023": ["160", "73", "779"],
    "10/27/2023": ["368", "77", "278"],
    "10/28/2023": ["135", "93", "580"],
    "10/29/2023": ["***", "**", "***"],
  },
  "10/30/2023 to 11/05/2023": {
    "10/30/2023": ["479", "04", "158"],
    "10/31/2023": ["468", "83", "337"],
    "11/01/2023": ["668", "08", "288"],
    "11/02/2023": ["113", "51", "470"],
    "11/03/2023": ["167", "41", "380"],
    "11/04/2023": ["460", "00", "370"],
    "11/05/2023": ["***", "**", "***"],
  },
  "11/06/2023 to 11/12/2023": {
    "11/06/2023": ["269", "74", "167"],
    "11/07/2023": ["156", "28", "189"],
    "11/08/2023": ["148", "31", "489"],
    "11/09/2023": ["257", "40", "479"],
    "11/10/2023": ["***", "**", "***"],
    "11/11/2023": ["***", "**", "***"],
    "11/12/2023": ["***", "**", "***"],
  },
  "11/13/2023 to 11/19/2023": {
    "11/13/2023": ["***", "**", "***"],
    "11/14/2023": ["***", "**", "***"],
    "11/15/2023": ["***", "**", "***"],
    "11/16/2023": ["***", "**", "***"],
    "11/17/2023": ["***", "**", "***"],
    "11/18/2023": ["***", "**", "***"],
    "11/19/2023": ["***", "**", "***"],
  },
  "11/20/2023 to 11/26/2023": {
    "11/20/2023": ["159", "53", "148"],
    "11/21/2023": ["348", "53", "120"],
    "11/22/2023": ["357", "51", "137"],
    "11/23/2023": ["289", "98", "378"],
    "11/24/2023": ["257", "48", "260"],
    "11/25/2023": ["179", "70", "389"],
    "11/26/2023": ["***", "**", "***"],
  },
  "11/27/2023 to 12/03/2023": {
    "11/27/2023": ["269", "76", "268"],
    "11/28/2023": ["146", "13", "139"],
    "11/29/2023": ["230", "56", "169"],
    "11/30/2023": ["235", "06", "790"],
    "12/01/2023": ["580", "33", "139"],
    "12/02/2023": ["120", "31", "489"],
    "12/03/2023": ["***", "**", "***"],
  },
  "12/04/2023 to 12/10/2023": {
    "12/04/2023": ["469", "90", "460"],
    "12/05/2023": ["270", "97", "340"],
    "12/06/2023": ["256", "35", "447"],
    "12/07/2023": ["690", "56", "178"],
    "12/08/2023": ["588", "13", "445"],
    "12/09/2023": ["124", "72", "129"],
    "12/10/2023": ["***", "**", "***"],
  },
  "12/11/2023 to 12/17/2023": {
    "12/11/2023": ["259", "69", "234"],
    "12/12/2023": ["257", "46", "600"],
    "12/13/2023": ["450", "96", "349"],
    "12/14/2023": ["120", "38", "224"],
    "12/15/2023": ["112", "48", "134"],
    "12/16/2023": ["150", "65", "230"],
    "12/17/2023": ["***", "**", "***"],
  },
  "12/18/2023 to 12/24/2023": {
    "12/18/2023": ["138", "22", "237"],
    "12/19/2023": ["249", "59", "379"],
    "12/20/2023": ["589", "29", "478"],
    "12/21/2023": ["248", "44", "356"],
    "12/22/2023": ["470", "14", "680"],
    "12/23/2023": ["678", "16", "349"],
    "12/24/2023": ["***", "**", "***"],
  },
  "12/25/2023 to 12/31/2023": {
    "12/25/2023": ["158", "48", "288"],
    "12/26/2023": ["677", "02", "589"],
    "12/27/2023": ["589", "24", "455"],
    "12/28/2023": ["267", "53", "580"],
    "12/29/2023": ["690", "54", "347"],
    "12/30/2023": ["135", "97", "250"],
    "12/31/2023": ["***", "**", "***"],
  },
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["446", "41", "560"],
    "01/02/2024": ["169", "60", "280"],
    "01/03/2024": ["135", "95", "267"],
    "01/04/2024": ["240", "63", "355"],
    "01/05/2024": ["480", "25", "690"],
    "01/06/2024": ["245", "15", "140"],
    "01/07/2024": ["***", "**", "***"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["467", "73", "238"],
    "01/09/2024": ["112", "42", "138"],
    "01/10/2024": ["200", "29", "577"],
    "01/11/2024": ["790", "63", "355"],
    "01/12/2024": ["256", "38", "134"],
    "01/13/2024": ["290", "12", "345"],
    "01/14/2024": ["***", "**", "***"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["449", "75", "339"],
    "01/16/2024": ["890", "75", "140"],
    "01/17/2024": ["345", "27", "890"],
    "01/18/2024": ["280", "05", "690"],
    "01/19/2024": ["589", "25", "339"],
    "01/20/2024": ["490", "37", "160"],
    "01/21/2024": ["***", "**", "***"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["689", "30", "226"],
    "01/23/2024": ["300", "37", "269"],
    "01/24/2024": ["230", "51", "380"],
    "01/25/2024": ["137", "14", "680"],
    "01/26/2024": ["***", "**", "***"],
    "01/27/2024": ["237", "20", "145"],
    "01/28/2024": ["***", "**", "***"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["568", "91", "489"],
    "01/30/2024": ["124", "72", "589"],
    "01/31/2024": ["139", "30", "668"],
    "02/01/2024": ["167", "45", "690"],
    "02/02/2024": ["334", "04", "149"],
    "02/03/2024": ["455", "48", "378"],
    "02/04/2024": ["***", "**", "***"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["137", "16", "268"],
    "02/06/2024": ["556", "62", "688"],
    "02/07/2024": ["770", "45", "159"],
    "02/08/2024": ["368", "72", "499"],
    "02/09/2024": ["189", "87", "700"],
    "02/10/2024": ["379", "98", "170"],
    "02/11/2024": ["***", "**", "***"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["578", "01", "344"],
    "02/13/2024": ["126", "97", "124"],
    "02/14/2024": ["578", "06", "150"],
    "02/15/2024": ["226", "07", "278"],
    "02/16/2024": ["447", "52", "237"],
    "02/17/2024": ["390", "25", "168"],
    "02/18/2024": ["***", "**", "***"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["446", "44", "266"],
    "02/20/2024": ["479", "04", "257"],
    "02/21/2024": ["159", "52", "129"],
    "02/22/2024": ["112", "40", "460"],
    "02/23/2024": ["569", "03", "779"],
    "02/24/2024": ["558", "89", "478"],
    "02/25/2024": ["***", "**", "***"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["779", "32", "390"],
    "02/27/2024": ["668", "08", "378"],
    "02/28/2024": ["348", "58", "170"],
    "02/29/2024": ["168", "56", "457"],
    "03/01/2024": ["699", "47", "340"],
    "03/02/2024": ["450", "96", "178"],
    "03/03/2024": ["***", "**", "***"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["234", "93", "670"],
    "03/05/2024": ["467", "76", "358"],
    "03/06/2024": ["120", "37", "467"],
    "03/07/2024": ["124", "72", "110"],
    "03/08/2024": ["226", "03", "689"],
    "03/09/2024": ["479", "03", "256"],
    "03/10/2024": ["***", "**", "***"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["599", "39", "360"],
    "03/12/2024": ["480", "27", "458"],
    "03/13/2024": ["580", "39", "225"],
    "03/14/2024": ["145", "08", "288"],
    "03/15/2024": ["589", "28", "189"],
    "03/16/2024": ["289", "96", "457"],
    "03/17/2024": ["***", "**", "***"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["267", "59", "568"],
    "03/19/2024": ["790", "60", "370"],
    "03/20/2024": ["237", "27", "359"],
    "03/21/2024": ["580", "33", "256"],
    "03/22/2024": ["123", "66", "790"],
    "03/23/2024": ["446", "40", "235"],
    "03/24/2024": ["***", "**", "***"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["***", "**", "***"],
    "03/26/2024": ["577", "97", "250"],
    "03/27/2024": ["348", "55", "249"],
    "03/28/2024": ["256", "39", "234"],
    "03/29/2024": ["677", "08", "990"],
    "03/30/2024": ["478", "91", "560"],
    "03/31/2024": ["***", "**", "***"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["900", "96", "448"],
    "04/02/2024": ["880", "64", "338"],
    "04/03/2024": ["667", "97", "359"],
    "04/04/2024": ["455", "41", "470"],
    "04/05/2024": ["680", "49", "289"],
    "04/06/2024": ["148", "36", "123"],
    "04/07/2024": ["***", "**", "***"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["347", "44", "338"],
    "04/09/2024": ["355", "32", "255"],
    "04/10/2024": ["233", "88", "558"],
    "04/11/2024": ["170", "83", "229"],
    "04/12/2024": ["600", "64", "347"],
    "04/13/2024": ["367", "67", "223"],
    "04/14/2024": ["***", "**", "***"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["112", "46", "790"],
    "04/16/2024": ["270", "97", "160"],
    "04/17/2024": ["357", "52", "679"],
    "04/18/2024": ["990", "81", "137"],
    "04/19/2024": ["370", "02", "345"],
    "04/20/2024": ["235", "04", "680"],
    "04/21/2024": ["***", "**", "***"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["569", "03", "256"],
    "04/23/2024": ["248", "49", "577"],
    "04/24/2024": ["257", "42", "147"],
    "04/25/2024": ["160", "78", "468"],
    "04/26/2024": ["358", "67", "890"],
    "04/27/2024": ["569", "02", "390"],
    "04/28/2024": ["***", "**", "***"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["779", "33", "580"],
    "04/30/2024": ["568", "92", "138"],
    "05/01/2024": ["230", "58", "189"],
    "05/02/2024": ["349", "60", "136"],
    "05/03/2024": ["145", "08", "468"],
    "05/04/2024": ["390", "24", "257"],
    "05/05/2024": ["***", "**", "***"],
  },

  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["147", "27", "250"],
    "05/07/2024": ["589", "21", "335"],
    "05/08/2024": ["116", "83", "670"],
    "05/09/2024": ["359", "79", "180"],
    "05/10/2024": ["668", "01", "489"],
    "05/11/2024": ["158", "44", "789"],
    "05/12/2024": ["***", "**", "***"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["234", "98", "369"],
    "05/14/2024": ["149", "40", "569"],
    "05/15/2024": ["347", "45", "249"],
    "05/16/2024": ["568", "96", "457"],
    "05/17/2024": ["267", "53", "779"],
    "05/18/2024": ["238", "30", "190"],
    "05/19/2024": ["***", "**", "***"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["156", "26", "150"],
    "05/21/2024": ["349", "66", "114"],
    "05/22/2024": ["133", "70", "118"],
    "05/23/2024": ["136", "02", "589"],
    "05/24/2024": ["399", "16", "240"],
    "05/25/2024": ["347", "42", "147"],
    "05/26/2024": ["***", "**", "***"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["370", "00", "578"],
    "05/28/2024": ["259", "63", "120"],
    "05/29/2024": ["126", "94", "130"],
    "05/30/2024": ["455", "44", "338"],
    "05/31/2024": ["400", "46", "178"],
    "06/01/2024": ["157", "34", "680"],
    "06/02/2024": ["***", "**", "***"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["688", "27", "890"],
    "06/04/2024": ["178", "63", "599"],
    "06/05/2024": ["799", "50", "127"],
    "06/06/2024": ["113", "53", "599"],
    "06/07/2024": ["269", "72", "390"],
    "06/08/2024": ["277", "62", "390"],
    "06/09/2024": ["***", "**", "***"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["789", "45", "168"],
    "06/25/2024": ["660", "26", "268"],
    "06/26/2024": ["349", "64", "149"],
    "06/27/2024": ["130", "47", "115"],
    "06/28/2024": ["880", "66", "457"],
    "06/29/2024": ["560", "15", "690"],
    "06/30/2024": ["***", "**", "***"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["467", "77", "458"],
    "07/02/2024": ["123", "66", "358"],
    "07/03/2024": ["138", "24", "167"],
    "07/04/2024": ["268", "64", "347"],
    "07/05/2024": ["489", "13", "445"],
    "07/06/2024": ["468", "84", "356"],
    "07/07/2024": ["***", "**", "***"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["679", "26", "367"],
    "07/09/2024": ["450", "91", "335"],
    "07/10/2024": ["129", "29", "900"],
    "07/11/2024": ["260", "88", "116"],
    "07/12/2024": ["578", "04", "400"],
    "07/13/2024": ["177", "55", "168"],
    "07/14/2024": ["***", "**", "***"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["450", "95", "690"],
    "07/16/2024": ["148", "37", "467"],
    "07/17/2024": ["126", "92", "480"],
    "07/18/2024": ["900", "99", "289"],
    "07/19/2024": ["458", "70", "226"],
    "07/20/2024": ["459", "82", "679"],
    "07/21/2024": ["***", "**", "***"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["690", "55", "267"],
    "07/23/2024": ["560", "15", "267"],
    "07/24/2024": ["689", "34", "266"],
    "07/25/2024": ["346", "31", "560"],
    "07/26/2024": ["256", "39", "234"],
    "07/27/2024": ["125", "89", "135"],
    "07/28/2024": ["***", "**", "***"],
  },
};

export const KALYANNIGHT = {
  "01/02/2023 to 01/08/2023": {
    "01/02/2023": ["790", "68", "459"],
    "01/03/2023": ["380", "12", "336"],
    "01/04/2023": ["469", "97", "890"],
    "01/05/2023": ["699", "43", "157"],
    "01/06/2023": ["778", "21", "399"],
    "01/07/2023": ["***", "**", "***"],
    "01/08/2023": ["***", "**", "***"],
  },
  "01/09/2023 to 01/15/2023": {
    "01/09/2023": ["120", "36", "457"],
    "01/10/2023": ["459", "87", "359"],
    "01/11/2023": ["278", "70", "569"],
    "01/12/2023": ["566", "74", "257"],
    "01/13/2023": ["690", "51", "678"],
    "01/14/2023": ["***", "**", "***"],
    "01/15/2023": ["***", "**", "***"],
  },
  "01/16/2023 to 01/22/2023": {
    "01/16/2023": ["370", "04", "149"],
    "01/17/2023": ["448", "68", "378"],
    "01/18/2023": ["169", "60", "370"],
    "01/19/2023": ["600", "60", "334"],
    "01/20/2023": ["458", "78", "134"],
    "01/21/2023": ["***", "**", "***"],
    "01/22/2023": ["***", "**", "***"],
  },
  "01/23/2023 to 01/29/2023": {
    "01/23/2023": ["580", "36", "790"],
    "01/24/2023": ["349", "69", "289"],
    "01/25/2023": ["355", "39", "469"],
    "01/26/2023": ["***", "**", "***"],
    "01/27/2023": ["257", "42", "480"],
    "01/28/2023": ["***", "**", "***"],
    "01/29/2023": ["***", "**", "***"],
  },
  "01/30/2023 to 02/05/2023": {
    "01/30/2023": ["790", "67", "124"],
    "01/31/2023": ["677", "03", "788"],
    "02/01/2023": ["346", "32", "147"],
    "02/02/2023": ["360", "94", "590"],
    "02/03/2023": ["137", "11", "146"],
    "02/04/2023": ["***", "**", "***"],
    "02/05/2023": ["***", "**", "***"],
  },
  "02/06/2023 to 02/12/2023": {
    "02/06/2023": ["447", "57", "188"],
    "02/07/2023": ["149", "44", "400"],
    "02/08/2023": ["259", "61", "380"],
    "02/09/2023": ["117", "98", "224"],
    "02/10/2023": ["780", "50", "677"],
    "02/11/2023": ["***", "**", "***"],
    "02/12/2023": ["***", "**", "***"],
  },
  "02/13/2023 to 02/19/2023": {
    "02/13/2023": ["140", "57", "458"],
    "02/14/2023": ["116", "84", "400"],
    "02/15/2023": ["120", "32", "390"],
    "02/16/2023": ["488", "02", "679"],
    "02/17/2023": ["369", "81", "128"],
    "02/18/2023": ["***", "**", "***"],
    "02/19/2023": ["***", "**", "***"],
  },
  "02/20/2023 to 02/26/2023": {
    "02/20/2023": ["459", "84", "356"],
    "02/21/2023": ["670", "37", "890"],
    "02/22/2023": ["120", "34", "158"],
    "02/23/2023": ["178", "64", "789"],
    "02/24/2023": ["670", "37", "269"],
    "02/25/2023": ["***", "**", "***"],
    "02/26/2023": ["***", "**", "***"],
  },
  "02/27/2023 to 03/05/2023": {
    "02/27/2023": ["245", "15", "230"],
    "02/28/2023": ["569", "05", "357"],
    "03/01/2023": ["690", "59", "135"],
    "03/02/2023": ["249", "55", "168"],
    "03/03/2023": ["568", "97", "124"],
    "03/04/2023": ["***", "**", "***"],
    "03/05/2023": ["***", "**", "***"],
  },
  "03/06/2023 to 03/12/2023": {
    "03/06/2023": ["780", "58", "134"],
    "03/07/2023": ["136", "06", "457"],
    "03/08/2023": ["***", "**", "***"],
    "03/09/2023": ["247", "30", "677"],
    "03/10/2023": ["469", "99", "379"],
    "03/11/2023": ["***", "**", "***"],
    "03/12/2023": ["***", "**", "***"],
  },
  "03/13/2023 to 03/19/2023": {
    "03/13/2023": ["147", "29", "478"],
    "03/14/2023": ["789", "40", "136"],
    "03/15/2023": ["346", "38", "378"],
    "03/16/2023": ["135", "99", "117"],
    "03/17/2023": ["129", "29", "568"],
    "03/18/2023": ["***", "**", "***"],
    "03/19/2023": ["***", "**", "***"],
  },
  "03/20/2023 to 03/26/2023": {
    "03/20/2023": ["469", "96", "457"],
    "03/21/2023": ["780", "53", "779"],
    "03/22/2023": ["358", "65", "177"],
    "03/23/2023": ["480", "21", "678"],
    "03/24/2023": ["358", "65", "113"],
    "03/25/2023": ["***", "**", "***"],
    "03/26/2023": ["***", "**", "***"],
  },
  "03/27/2023 to 04/02/2023": {
    "03/27/2023": ["668", "03", "238"],
    "03/28/2023": ["116", "88", "990"],
    "03/29/2023": ["467", "71", "579"],
    "03/30/2023": ["135", "94", "266"],
    "03/31/2023": ["247", "38", "468"],
    "04/01/2023": ["***", "**", "***"],
    "04/02/2023": ["***", "**", "***"],
  },
  "04/03/2023 to 04/09/2023": {
    "04/03/2023": ["478", "98", "233"],
    "04/04/2023": ["468", "84", "257"],
    "04/05/2023": ["449", "75", "799"],
    "04/06/2023": ["788", "33", "148"],
    "04/07/2023": ["299", "04", "220"],
    "04/08/2023": ["***", "**", "***"],
    "04/09/2023": ["***", "**", "***"],
  },
  "04/10/2023 to 04/16/2023": {
    "04/10/2023": ["488", "00", "370"],
    "04/11/2023": ["460", "04", "248"],
    "04/12/2023": ["266", "41", "146"],
    "04/13/2023": ["130", "47", "269"],
    "04/14/2023": ["588", "13", "247"],
    "04/15/2023": ["***", "**", "***"],
    "04/16/2023": ["***", "**", "***"],
  },
  "04/17/2023 to 04/23/2023": {
    "04/17/2023": ["167", "41", "399"],
    "04/18/2023": ["489", "12", "688"],
    "04/19/2023": ["679", "21", "579"],
    "04/20/2023": ["446", "40", "578"],
    "04/21/2023": ["158", "42", "138"],
    "04/22/2023": ["***", "**", "***"],
    "04/23/2023": ["***", "**", "***"],
  },
  "04/24/2023 to 04/30/2023": {
    "04/24/2023": ["340", "76", "358"],
    "04/25/2023": ["589", "20", "145"],
    "04/26/2023": ["488", "04", "356"],
    "04/27/2023": ["127", "09", "478"],
    "04/28/2023": ["458", "74", "239"],
    "04/29/2023": ["***", "**", "***"],
    "04/30/2023": ["***", "**", "***"],
  },
  "05/01/2023 to 05/07/2023": {
    "05/01/2023": ["116", "85", "140"],
    "05/02/2023": ["136", "08", "233"],
    "05/03/2023": ["223", "74", "699"],
    "05/04/2023": ["137", "19", "270"],
    "05/05/2023": ["146", "18", "224"],
    "05/06/2023": ["***", "**", "***"],
    "05/07/2023": ["***", "**", "***"],
  },
  "05/08/2023 to 05/14/2023": {
    "05/08/2023": ["146", "15", "168"],
    "05/09/2023": ["260", "82", "660"],
    "05/10/2023": ["458", "77", "223"],
    "05/11/2023": ["559", "92", "156"],
    "05/12/2023": ["379", "93", "670"],
    "05/13/2023": ["***", "**", "***"],
    "05/14/2023": ["***", "**", "***"],
  },
  "05/15/2023 to 05/21/2023": {
    "05/15/2023": ["225", "98", "350"],
    "05/16/2023": ["188", "77", "700"],
    "05/17/2023": ["237", "26", "358"],
    "05/18/2023": ["334", "09", "270"],
    "05/19/2023": ["677", "04", "112"],
    "05/20/2023": ["***", "**", "***"],
    "05/21/2023": ["***", "**", "***"],
  },
  "05/22/2023 to 05/28/2023": {
    "05/22/2023": ["269", "79", "469"],
    "05/23/2023": ["370", "00", "136"],
    "05/24/2023": ["356", "44", "167"],
    "05/25/2023": ["145", "06", "600"],
    "05/26/2023": ["156", "28", "800"],
    "05/27/2023": ["***", "**", "***"],
    "05/28/2023": ["***", "**", "***"],
  },
  "05/29/2023 to 06/04/2023": {
    "05/29/2023": ["899", "63", "157"],
    "05/30/2023": ["124", "73", "166"],
    "05/31/2023": ["124", "76", "240"],
    "06/01/2023": ["800", "85", "168"],
    "06/02/2023": ["133", "78", "260"],
    "06/03/2023": ["***", "**", "***"],
    "06/04/2023": ["***", "**", "***"],
  },
  "06/05/2023 to 06/11/2023": {
    "06/05/2023": ["599", "39", "289"],
    "06/06/2023": ["249", "56", "330"],
    "06/07/2023": ["166", "34", "149"],
    "06/08/2023": ["120", "31", "146"],
    "06/09/2023": ["156", "26", "349"],
    "06/10/2023": ["***", "**", "***"],
    "06/11/2023": ["***", "**", "***"],
  },
  "06/12/2023 to 06/18/2023": {
    "06/12/2023": ["277", "68", "125"],
    "06/13/2023": ["277", "64", "266"],
    "06/14/2023": ["233", "80", "370"],
    "06/15/2023": ["147", "22", "129"],
    "06/16/2023": ["120", "31", "470"],
    "06/17/2023": ["***", "**", "***"],
    "06/18/2023": ["***", "**", "***"],
  },
  "06/19/2023 to 06/25/2023": {
    "06/19/2023": ["390", "21", "579"],
    "06/20/2023": ["778", "22", "156"],
    "06/21/2023": ["450", "92", "570"],
    "06/22/2023": ["280", "04", "248"],
    "06/23/2023": ["113", "56", "790"],
    "06/24/2023": ["***", "**", "***"],
    "06/25/2023": ["***", "**", "***"],
  },
  "06/26/2023 to 07/02/2023": {
    "06/26/2023": ["267", "56", "457"],
    "06/27/2023": ["570", "22", "390"],
    "06/28/2023": ["256", "37", "179"],
    "06/29/2023": ["237", "25", "140"],
    "06/30/2023": ["358", "69", "478"],
    "07/01/2023": ["***", "**", "***"],
    "07/02/2023": ["***", "**", "***"],
  },
  "07/03/2023 to 07/09/2023": {
    "07/03/2023": ["168", "58", "440"],
    "07/04/2023": ["127", "03", "247"],
    "07/05/2023": ["268", "60", "578"],
    "07/06/2023": ["270", "96", "367"],
    "07/07/2023": ["255", "21", "380"],
    "07/08/2023": ["***", "**", "***"],
    "07/09/2023": ["***", "**", "***"],
  },
  "07/10/2023 to 07/16/2023": {
    "07/10/2023": ["450", "90", "244"],
    "07/11/2023": ["269", "70", "299"],
    "07/12/2023": ["233", "85", "159"],
    "07/13/2023": ["290", "15", "113"],
    "07/14/2023": ["170", "86", "790"],
    "07/15/2023": ["***", "**", "***"],
    "07/16/2023": ["***", "**", "***"],
  },
  "07/17/2023 to 07/23/2023": {
    "07/17/2023": ["117", "98", "134"],
    "07/18/2023": ["148", "38", "378"],
    "07/19/2023": ["236", "16", "277"],
    "07/20/2023": ["378", "82", "589"],
    "07/21/2023": ["160", "70", "226"],
    "07/22/2023": ["***", "**", "***"],
    "07/23/2023": ["***", "**", "***"],
  },
  "07/24/2023 to 07/30/2023": {
    "07/24/2023": ["230", "59", "126"],
    "07/25/2023": ["335", "17", "160"],
    "07/26/2023": ["290", "17", "160"],
    "07/27/2023": ["669", "15", "230"],
    "07/28/2023": ["367", "66", "268"],
    "07/29/2023": ["***", "**", "***"],
    "07/30/2023": ["***", "**", "***"],
  },
  "07/31/2023 to 08/06/2023": {
    "07/31/2023": ["560", "19", "126"],
    "08/01/2023": ["789", "40", "479"],
    "08/02/2023": ["590", "40", "244"],
    "08/03/2023": ["199", "97", "115"],
    "08/04/2023": ["378", "89", "379"],
    "08/05/2023": ["***", "**", "***"],
    "08/06/2023": ["***", "**", "***"],
  },
  "08/07/2023 to 08/13/2023": {
    "08/07/2023": ["380", "17", "467"],
    "08/08/2023": ["458", "77", "340"],
    "08/09/2023": ["570", "24", "455"],
    "08/10/2023": ["100", "14", "257"],
    "08/11/2023": ["360", "96", "259"],
    "08/12/2023": ["***", "**", "***"],
    "08/13/2023": ["***", "**", "***"],
  },
  "08/14/2023 to 08/20/2023": {
    "08/14/2023": ["336", "22", "660"],
    "08/16/2023": ["***", "**", "***"],
    "08/17/2023": ["477", "89", "379"],
    "08/18/2023": ["339", "55", "249"],
    "08/19/2023": ["477", "89", "900"],
    "08/15/2023": ["***", "**", "***"],
    "08/20/2023": ["***", "**", "***"],
  },
  "08/21/2023 to 08/27/2023": {
    "08/21/2023": ["345", "28", "125"],
    "08/22/2023": ["190", "02", "138"],
    "08/23/2023": ["110", "28", "134"],
    "08/24/2023": ["580", "32", "110"],
    "08/25/2023": ["159", "50", "145"],
    "08/26/2023": ["***", "**", "***"],
    "08/27/2023": ["***", "**", "***"],
  },
  "08/28/2023 to 09/03/2023": {
    "08/28/2023": ["147", "29", "450"],
    "08/29/2023": ["168", "55", "230"],
    "08/30/2023": ["378", "84", "770"],
    "08/31/2023": ["558", "86", "457"],
    "09/01/2023": ["189", "81", "678"],
    "09/02/2023": ["***", "**", "***"],
    "09/03/2023": ["***", "**", "***"],
  },
  "09/04/2023 to 09/10/2023": {
    "09/04/2023": ["234", "97", "458"],
    "09/05/2023": ["668", "00", "460"],
    "09/06/2023": ["134", "80", "226"],
    "09/07/2023": ["146", "16", "448"],
    "09/08/2023": ["280", "01", "245"],
    "09/09/2023": ["***", "**", "***"],
    "09/10/2023": ["***", "**", "***"],
  },
  "09/11/2023 to 09/17/2023": {
    "09/11/2023": ["378", "87", "890"],
    "09/12/2023": ["269", "78", "134"],
    "09/13/2023": ["280", "07", "179"],
    "09/14/2023": ["189", "84", "680"],
    "09/15/2023": ["566", "71", "489"],
    "09/16/2023": ["***", "**", "***"],
    "09/17/2023": ["***", "**", "***"],
  },
  "09/18/2023 to 09/24/2023": {
    "09/18/2023": ["335", "18", "440"],
    "09/19/2023": ["122", "57", "359"],
    "09/20/2023": ["169", "61", "579"],
    "09/21/2023": ["578", "01", "128"],
    "09/22/2023": ["114", "60", "668"],
    "09/23/2023": ["***", "**", "***"],
    "09/24/2023": ["***", "**", "***"],
  },
  "09/25/2023 to 10/01/2023": {
    "09/25/2023": ["238", "35", "140"],
    "09/26/2023": ["177", "58", "125"],
    "09/27/2023": ["660", "29", "559"],
    "09/28/2023": ["267", "55", "348"],
    "09/29/2023": ["118", "09", "135"],
    "09/30/2023": ["***", "**", "***"],
    "10/01/2023": ["***", "**", "***"],
  },
  "10/02/2023 to 10/08/2023": {
    "10/02/2023": ["345", "23", "256"],
    "10/03/2023": ["125", "81", "290"],
    "10/04/2023": ["557", "78", "170"],
    "10/05/2023": ["470", "11", "470"],
    "10/06/2023": ["138", "23", "346"],
    "10/07/2023": ["***", "**", "***"],
    "10/08/2023": ["***", "**", "***"],
  },
  "10/09/2023 to 10/15/2023": {
    "10/09/2023": ["256", "31", "245"],
    "10/10/2023": ["237", "25", "690"],
    "10/11/2023": ["267", "50", "136"],
    "10/12/2023": ["188", "77", "449"],
    "10/13/2023": ["390", "21", "236"],
    "10/14/2023": ["***", "**", "***"],
    "10/15/2023": ["***", "**", "***"],
  },
  "10/16/2023 to 10/22/2023": {
    "10/16/2023": ["150", "66", "790"],
    "10/17/2023": ["480", "28", "369"],
    "10/18/2023": ["390", "29", "289"],
    "10/19/2023": ["224", "83", "689"],
    "10/20/2023": ["267", "59", "450"],
    "10/21/2023": ["***", "**", "***"],
    "10/22/2023": ["***", "**", "***"],
  },
  "10/23/2023 to 10/29/2023": {
    "10/23/2023": ["127", "02", "246"],
    "10/24/2023": ["237", "24", "239"],
    "10/25/2023": ["457", "65", "177"],
    "10/26/2023": ["790", "65", "140"],
    "10/27/2023": ["239", "44", "347"],
    "10/28/2023": ["***", "**", "***"],
    "10/29/2023": ["***", "**", "***"],
  },
  "10/30/2023 to 11/05/2023": {
    "10/30/2023": ["780", "54", "130"],
    "10/31/2023": ["248", "40", "127"],
    "11/01/2023": ["257", "46", "123"],
    "11/02/2023": ["248", "41", "380"],
    "11/03/2023": ["246", "23", "580"],
    "11/04/2023": ["***", "**", "***"],
    "11/05/2023": ["***", "**", "***"],
  },
  "11/06/2023 to 11/12/2023": {
    "11/06/2023": ["457", "63", "599"],
    "11/07/2023": ["489", "10", "479"],
    "11/08/2023": ["123", "69", "568"],
    "11/09/2023": ["568", "93", "148"],
    "11/10/2023": ["***", "**", "***"],
    "11/11/2023": ["***", "**", "***"],
    "11/12/2023": ["***", "**", "***"],
  },
  "11/13/2023 to 11/19/2023": {
    "11/13/2023": ["***", "**", "***"],
    "11/14/2023": ["***", "**", "***"],
    "11/15/2023": ["***", "**", "***"],
    "11/16/2023": ["***", "**", "***"],
    "11/17/2023": ["***", "**", "***"],
    "11/18/2023": ["***", "**", "***"],
    "11/19/2023": ["***", "**", "***"],
  },
  "11/20/2023 to 11/26/2023": {
    "11/20/2023": ["256", "39", "135"],
    "11/21/2023": ["170", "86", "367"],
    "11/22/2023": ["150", "66", "367"],
    "11/23/2023": ["478", "94", "590"],
    "11/24/2023": ["780", "58", "378"],
    "11/25/2023": ["***", "**", "***"],
    "11/26/2023": ["***", "**", "***"],
  },
  "11/27/2023 to 12/03/2023": {
    "11/27/2023": ["256", "37", "368"],
    "11/28/2023": ["280", "08", "567"],
    "11/29/2023": ["259", "67", "160"],
    "11/30/2023": ["223", "76", "466"],
    "12/01/2023": ["234", "95", "177"],
    "12/02/2023": ["***", "**", "***"],
    "12/03/2023": ["***", "**", "***"],
  },
  "12/04/2023 to 12/10/2023": {
    "12/04/2023": ["127", "04", "239"],
    "12/05/2023": ["158", "41", "489"],
    "12/06/2023": ["580", "37", "269"],
    "12/07/2023": ["339", "59", "117"],
    "12/08/2023": ["235", "03", "337"],
    "12/09/2023": ["***", "**", "***"],
    "12/10/2023": ["***", "**", "***"],
  },
  "12/11/2023 to 12/17/2023": {
    "12/11/2023": ["270", "98", "558"],
    "12/12/2023": ["169", "67", "223"],
    "12/13/2023": ["459", "82", "147"],
    "12/14/2023": ["116", "87", "133"],
    "12/15/2023": ["800", "82", "390"],
    "12/16/2023": ["***", "**", "***"],
    "12/17/2023": ["***", "**", "***"],
  },
  "12/18/2023 to 12/24/2023": {
    "12/18/2023": ["379", "90", "136"],
    "12/19/2023": ["239", "41", "560"],
    "12/20/2023": ["279", "86", "349"],
    "12/21/2023": ["140", "53", "346"],
    "12/22/2023": ["130", "45", "249"],
    "12/23/2023": ["***", "**", "***"],
    "12/24/2023": ["***", "**", "***"],
  },
  "12/25/2023 to 12/31/2023": {
    "12/25/2023": ["160", "79", "360"],
    "12/26/2023": ["190", "03", "148"],
    "12/27/2023": ["457", "60", "578"],
    "12/28/2023": ["140", "53", "157"],
    "12/29/2023": ["122", "59", "199"],
    "12/30/2023": ["***", "**", "***"],
    "12/31/2023": ["***", "**", "***"],
  },
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["568", "93", "779"],
    "01/02/2024": ["770", "42", "345"],
    "01/03/2024": ["360", "98", "116"],
    "01/04/2024": ["700", "76", "880"],
    "01/05/2024": ["257", "42", "570"],
    "01/06/2024": ["***", "**", "***"],
    "01/07/2024": ["***", "**", "***"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["128", "19", "126"],
    "01/09/2024": ["356", "47", "160"],
    "01/10/2024": ["158", "47", "223"],
    "01/11/2024": ["124", "75", "456"],
    "01/12/2024": ["166", "36", "790"],
    "01/13/2024": ["***", "**", "***"],
    "01/14/2024": ["***", "**", "***"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["139", "36", "448"],
    "01/16/2024": ["679", "29", "568"],
    "01/17/2024": ["278", "79", "234"],
    "01/18/2024": ["144", "97", "458"],
    "01/19/2024": ["690", "51", "236"],
    "01/20/2024": ["***", "**", "***"],
    "01/21/2024": ["***", "**", "***"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["160", "79", "478"],
    "01/23/2024": ["268", "68", "468"],
    "01/24/2024": ["355", "35", "447"],
    "01/25/2024": ["120", "32", "589"],
    "01/26/2024": ["***", "**", "***"],
    "01/27/2024": ["***", "**", "***"],
    "01/28/2024": ["***", "**", "***"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["467", "70", "118"],
    "01/30/2024": ["250", "70", "280"],
    "01/31/2024": ["148", "34", "338"],
    "02/01/2024": ["139", "30", "235"],
    "02/02/2024": ["455", "42", "156"],
    "02/03/2024": ["***", "**", "***"],
    "02/04/2024": ["***", "**", "***"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["289", "95", "230"],
    "02/06/2024": ["459", "85", "122"],
    "02/07/2024": ["790", "62", "480"],
    "02/08/2024": ["279", "80", "569"],
    "02/09/2024": ["568", "91", "579"],
    "02/10/2024": ["***", "**", "***"],
    "02/11/2024": ["***", "**", "***"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["679", "28", "459"],
    "02/13/2024": ["238", "31", "227"],
    "02/14/2024": ["457", "63", "238"],
    "02/15/2024": ["447", "51", "146"],
    "02/16/2024": ["168", "54", "400"],
    "02/17/2024": ["***", "**", "***"],
    "02/18/2024": ["***", "**", "***"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["499", "28", "170"],
    "02/20/2024": ["580", "35", "258"],
    "02/21/2024": ["778", "24", "699"],
    "02/22/2024": ["170", "83", "148"],
    "02/23/2024": ["113", "52", "200"],
    "02/24/2024": ["***", "**", "***"],
    "02/25/2024": ["***", "**", "***"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["289", "90", "677"],
    "02/27/2024": ["499", "28", "125"],
    "02/28/2024": ["440", "87", "340"],
    "02/29/2024": ["268", "66", "457"],
    "03/01/2024": ["336", "28", "477"],
    "03/02/2024": ["***", "**", "***"],
    "03/03/2024": ["***", "**", "***"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["770", "44", "699"],
    "03/05/2024": ["226", "06", "790"],
    "03/06/2024": ["135", "96", "358"],
    "03/07/2024": ["670", "33", "256"],
    "03/08/2024": ["456", "52", "480"],
    "03/09/2024": ["***", "**", "***"],
    "03/10/2024": ["***", "**", "***"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["467", "77", "458"],
    "03/12/2024": ["350", "80", "668"],
    "03/13/2024": ["570", "21", "128"],
    "03/14/2024": ["178", "67", "368"],
    "03/15/2024": ["147", "25", "249"],
    "03/16/2024": ["***", "**", "***"],
    "03/17/2024": ["***", "**", "***"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["890", "74", "130"],
    "03/19/2024": ["370", "05", "456"],
    "03/20/2024": ["348", "59", "577"],
    "03/21/2024": ["788", "33", "689"],
    "03/22/2024": ["259", "63", "120"],
    "03/23/2024": ["***", "**", "***"],
    "03/24/2024": ["***", "**", "***"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["***", "**", "***"],
    "03/26/2024": ["356", "44", "239"],
    "03/27/2024": ["599", "31", "100"],
    "03/28/2024": ["445", "32", "246"],
    "03/29/2024": ["679", "29", "469"],
    "03/30/2024": ["***", "**", "***"],
    "03/31/2024": ["***", "**", "***"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["367", "64", "770"],
    "04/02/2024": ["445", "33", "670"],
    "04/03/2024": ["380", "15", "357"],
    "04/04/2024": ["370", "03", "120"],
    "04/05/2024": ["789", "45", "249"],
    "04/06/2024": ["***", "**", "***"],
    "04/07/2024": ["***", "**", "***"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["244", "01", "470"],
    "04/09/2024": ["280", "01", "137"],
    "04/10/2024": ["389", "07", "449"],
    "04/11/2024": ["367", "65", "348"],
    "04/12/2024": ["560", "16", "268"],
    "04/13/2024": ["***", "**", "***"],
    "04/14/2024": ["***", "**", "***"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["679", "29", "568"],
    "04/16/2024": ["560", "16", "457"],
    "04/17/2024": ["469", "96", "457"],
    "04/18/2024": ["340", "71", "579"],
    "04/19/2024": ["250", "75", "258"],
    "04/20/2024": ["***", "**", "***"],
    "04/21/2024": ["***", "**", "***"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["150", "64", "680"],
    "04/23/2024": ["137", "12", "499"],
    "04/24/2024": ["167", "42", "336"],
    "04/25/2024": ["457", "66", "349"],
    "04/26/2024": ["169", "61", "290"],
    "04/27/2024": ["***", "**", "***"],
    "04/28/2024": ["***", "**", "***"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["560", "16", "268"],
    "04/30/2024": ["259", "62", "688"],
    "05/01/2024": ["147", "26", "240"],
    "05/02/2024": ["490", "32", "138"],
    "05/03/2024": ["339", "50", "479"],
    "05/04/2024": ["***", "**", "***"],
    "05/05/2024": ["***", "**", "***"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["590", "49", "559"],
    "05/07/2024": ["138", "22", "570"],
    "05/08/2024": ["149", "46", "240"],
    "05/09/2024": ["680", "42", "237"],
    "05/10/2024": ["159", "55", "140"],
    "05/11/2024": ["***", "**", "***"],
    "05/12/2024": ["***", "**", "***"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["349", "64", "130"],
    "05/14/2024": ["668", "07", "566"],
    "05/15/2024": ["569", "05", "159"],
    "05/16/2024": ["688", "24", "789"],
    "05/17/2024": ["345", "20", "479"],
    "05/18/2024": ["***", "**", "***"],
    "05/19/2024": ["***", "**", "***"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["160", "75", "159"],
    "05/21/2024": ["357", "51", "227"],
    "05/22/2024": ["117", "95", "799"],
    "05/23/2024": ["223", "77", "359"],
    "05/24/2024": ["150", "60", "677"],
    "05/25/2024": ["***", "**", "***"],
    "05/26/2024": ["***", "**", "***"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["188", "71", "146"],
    "05/28/2024": ["490", "33", "490"],
    "05/29/2024": ["136", "07", "124"],
    "05/30/2024": ["249", "59", "126"],
    "05/31/2024": ["330", "68", "170"],
    "06/01/2024": ["***", "**", "***"],
    "06/02/2024": ["***", "**", "***"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["157", "30", "569"],
    "06/04/2024": ["780", "59", "144"],
    "06/05/2024": ["788", "34", "338"],
    "06/06/2024": ["349", "60", "569"],
    "06/07/2024": ["370", "02", "679"],
    "06/08/2024": ["***", "**", "***"],
    "06/09/2024": ["***", "**", "***"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["449", "78", "125"],
    "06/11/2024": ["150", "67", "269"],
    "06/12/2024": ["250", "70", "460"],
    "06/13/2024": ["259", "60", "488"],
    "06/14/2024": ["589", "23", "445"],
    "06/15/2024": ["***", "**", "***"],
    "06/16/2024": ["***", "**", "***"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["668", "07", "115"],
    "06/18/2024": ["278", "76", "268"],
    "06/19/2024": ["229", "36", "448"],
    "06/20/2024": ["358", "61", "470"],
    "06/21/2024": ["169", "68", "440"],
    "06/22/2024": ["***", "**", "***"],
    "06/23/2024": ["***", "**", "***"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["567", "81", "470"],
    "06/25/2024": ["116", "84", "130"],
    "06/26/2024": ["459", "84", "239"],
    "06/27/2024": ["360", "91", "137"],
    "06/28/2024": ["137", "18", "170"],
    "06/29/2024": ["***", "**", "***"],
    "06/30/2024": ["***", "**", "***"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["368", "74", "248"],
    "07/02/2024": ["680", "43", "256"],
    "07/03/2024": ["368", "79", "469"],
    "07/04/2024": ["112", "47", "359"],
    "07/05/2024": ["578", "05", "799"],
    "07/06/2024": ["***", "**", "***"],
    "07/07/2024": ["***", "**", "***"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["127", "00", "370"],
    "07/09/2024": ["169", "60", "460"],
    "07/10/2024": ["588", "12", "255"],
    "07/11/2024": ["238", "31", "236"],
    "07/12/2024": ["128", "12", "147"],
    "07/13/2024": ["***", "**", "***"],
    "07/14/2024": ["***", "**", "***"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["690", "57", "557"],
    "07/16/2024": ["269", "77", "179"],
    "07/17/2024": ["470", "15", "348"],
    "07/18/2024": ["225", "97", "278"],
    "07/19/2024": ["368", "77", "467"],
    "07/20/2024": ["***", "**", "***"],
    "07/21/2024": ["***", "**", "***"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["340", "78", "378"],
    "07/23/2024": ["157", "37", "890"],
    "07/24/2024": ["134", "84", "455"],
    "07/25/2024": ["560", "13", "580"],
    "07/26/2024": ["669", "13", "139"],
    "07/27/2024": ["***", "**", "***"],
    "07/28/2024": ["***", "**", "***"],
  },
  "07/29/2024 to 08/04/2024": {
    "07/29/2024": ["689", "39", "270"],
    "07/30/2024": ["148", "32", "345"],
    "07/31/2024": ["367", "69", "289"],
    "08/01/2024": ["234", "90", "145"],
    "08/02/2024": ["238", "36", "114"],
    "08/03/2024": ["***", "**", "***"],
    "08/04/2024": ["***", "**", "***"],
  },
  "08/05/2024 to 08/11/2024": {
    "08/05/2024": ["158", "46", "150"],
    "08/06/2024": ["128", "16", "330"],
    "08/07/2024": ["334", "02", "570"],
    "08/08/2024": ["458", "70", "235"],
    "08/09/2024": ["379", "97", "179"],
    "08/10/2024": ["***", "**", "***"],
    "08/11/2024": ["***", "**", "***"],
  },
  "08/12/2024 to 08/18/2024": {
    "08/12/2024": ["157", "34", "356"],
    "08/13/2024": ["459", "85", "168"],
    "08/14/2024": ["880", "61", "470"],
    "08/15/2024": ["***", "**", "***"],
    "08/16/2024": ["258", "52", "246"],
    "08/17/2024": ["***", "**", "***"],
    "08/18/2024": ["***", "**", "***"],
  },
  "08/19/2024 to 08/25/2024": {
    "08/19/2024": ["279", "81", "669"],
    "08/20/2024": ["146", "14", "789"],
    "08/21/2024": ["690", "52", "336"],
    "08/22/2024": ["257", "41", "489"],
    "08/23/2024": ["229", "38", "459"],
    "08/24/2024": ["***", "**", "***"],
    "08/25/2024": ["***", "**", "***"],
  },
  "08/26/2024 to 09/01/2024": {
    "08/26/2024": ["390", "21", "119"],
    "08/27/2024": ["790", "61", "380"],
    "08/28/2024": ["134", "82", "390"],
    "08/29/2024": ["490", "31", "227"],
    "08/30/2024": ["460", "01", "236"],
    "08/31/2024": ["***", "**", "***"],
    "09/01/2024": ["***", "**", "***"],
  },
};

export const SRIDEVINIGHT = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["800", "87", "377"],
    "01/02/2024": ["467", "70", "334"],
    "01/03/2024": ["477", "86", "169"],
    "01/04/2024": ["890", "70", "244"],
    "01/05/2024": ["228", "26", "899"],
    "01/06/2024": ["450", "93", "490"],
    "01/07/2024": ["122", "56", "268"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["448", "61", "236"],
    "01/09/2024": ["668", "04", "248"],
    "01/10/2024": ["147", "22", "129"],
    "01/11/2024": ["488", "01", "128"],
    "01/12/2024": ["119", "18", "459"],
    "01/13/2024": ["257", "40", "190"],
    "01/14/2024": ["699", "41", "489"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["146", "19", "289"],
    "01/16/2024": ["180", "94", "347"],
    "01/17/2024": ["568", "93", "490"],
    "01/18/2024": ["134", "82", "138"],
    "01/19/2024": ["457", "68", "567"],
    "01/20/2024": ["236", "11", "290"],
    "01/21/2024": ["458", "71", "245"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["266", "47", "188"],
    "01/23/2024": ["134", "85", "447"],
    "01/24/2024": ["448", "65", "348"],
    "01/25/2024": ["478", "98", "189"],
    "01/26/2024": ["***", "**", "***"],
    "01/27/2024": ["129", "21", "146"],
    "01/28/2024": ["346", "39", "360"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["678", "19", "225"],
    "01/30/2024": ["156", "23", "670"],
    "01/31/2024": ["369", "87", "179"],
    "02/01/2024": ["125", "82", "679"],
    "02/02/2024": ["167", "40", "569"],
    "02/03/2024": ["690", "59", "180"],
    "02/04/2024": ["259", "64", "699"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["338", "44", "239"],
    "02/06/2024": ["279", "88", "990"],
    "02/07/2024": ["367", "60", "578"],
    "02/08/2024": ["334", "08", "350"],
    "02/09/2024": ["459", "83", "346"],
    "02/10/2024": ["660", "22", "390"],
    "02/11/2024": ["356", "48", "477"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["246", "22", "237"],
    "02/13/2024": ["125", "88", "260"],
    "02/14/2024": ["446", "45", "267"],
    "02/15/2024": ["790", "60", "136"],
    "02/16/2024": ["359", "77", "890"],
    "02/17/2024": ["577", "92", "255"],
    "02/18/2024": ["559", "90", "370"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["789", "48", "369"],
    "02/20/2024": ["600", "60", "190"],
    "02/21/2024": ["357", "51", "470"],
    "02/22/2024": ["169", "65", "230"],
    "02/23/2024": ["179", "79", "126"],
    "02/24/2024": ["788", "33", "256"],
    "02/25/2024": ["246", "26", "457"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["255", "27", "359"],
    "02/27/2024": ["137", "17", "115"],
    "02/28/2024": ["260", "80", "226"],
    "02/29/2024": ["140", "58", "477"],
    "03/01/2024": ["267", "58", "279"],
    "03/02/2024": ["478", "92", "679"],
    "03/03/2024": ["290", "15", "258"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["880", "69", "270"],
    "03/05/2024": ["479", "00", "668"],
    "03/06/2024": ["334", "08", "134"],
    "03/07/2024": ["579", "19", "568"],
    "03/08/2024": ["250", "79", "289"],
    "03/09/2024": ["128", "15", "249"],
    "03/10/2024": ["157", "39", "568"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["578", "07", "160"],
    "03/12/2024": ["255", "28", "189"],
    "03/13/2024": ["127", "09", "180"],
    "03/14/2024": ["470", "16", "880"],
    "03/15/2024": ["557", "74", "770"],
    "03/16/2024": ["669", "16", "178"],
    "03/17/2024": ["589", "28", "279"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["122", "55", "258"],
    "03/19/2024": ["455", "48", "459"],
    "03/20/2024": ["344", "15", "267"],
    "03/21/2024": ["800", "81", "227"],
    "03/22/2024": ["226", "09", "117"],
    "03/23/2024": ["677", "05", "249"],
    "03/24/2024": ["457", "61", "155"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["***", "**", "***"],
    "03/26/2024": ["447", "51", "236"],
    "03/27/2024": ["156", "27", "223"],
    "03/28/2024": ["160", "74", "699"],
    "03/29/2024": ["167", "49", "559"],
    "03/30/2024": ["247", "39", "379"],
    "03/31/2024": ["467", "78", "125"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["370", "07", "250"],
    "04/02/2024": ["299", "00", "668"],
    "04/03/2024": ["289", "97", "557"],
    "04/04/2024": ["400", "48", "567"],
    "04/05/2024": ["388", "95", "122"],
    "04/06/2024": ["158", "43", "670"],
    "04/07/2024": ["236", "12", "570"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["334", "07", "458"],
    "04/09/2024": ["112", "44", "257"],
    "04/10/2024": ["500", "56", "349"],
    "04/11/2024": ["990", "80", "668"],
    "04/12/2024": ["289", "90", "479"],
    "04/13/2024": ["260", "81", "290"],
    "04/14/2024": ["689", "35", "113"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["180", "97", "467"],
    "04/16/2024": ["499", "21", "245"],
    "04/17/2024": ["135", "94", "266"],
    "04/18/2024": ["236", "14", "257"],
    "04/19/2024": ["139", "36", "358"],
    "04/20/2024": ["158", "41", "560"],
    "04/21/2024": ["567", "83", "490"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["138", "24", "149"],
    "04/23/2024": ["566", "73", "445"],
    "04/24/2024": ["269", "78", "233"],
    "04/25/2024": ["369", "86", "240"],
    "04/26/2024": ["168", "55", "159"],
    "04/27/2024": ["137", "14", "239"],
    "04/28/2024": ["169", "60", "127"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["140", "55", "258"],
    "04/30/2024": ["156", "21", "489"],
    "05/01/2024": ["679", "20", "190"],
    "05/02/2024": ["125", "85", "159"],
    "05/03/2024": ["490", "35", "690"],
    "05/04/2024": ["579", "14", "130"],
    "05/05/2024": ["128", "13", "238"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["457", "64", "446"],
    "05/07/2024": ["230", "57", "269"],
    "05/08/2024": ["679", "23", "580"],
    "05/09/2024": ["258", "51", "678"],
    "05/10/2024": ["138", "22", "345"],
    "05/11/2024": ["128", "17", "467"],
    "05/12/2024": ["990", "84", "257"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["259", "61", "489"],
    "05/14/2024": ["290", "16", "259"],
    "05/15/2024": ["440", "80", "569"],
    "05/16/2024": ["239", "44", "158"],
    "05/17/2024": ["479", "07", "890"],
    "05/18/2024": ["127", "01", "678"],
    "05/19/2024": ["270", "93", "346"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["450", "98", "233"],
    "05/21/2024": ["340", "77", "377"],
    "05/22/2024": ["458", "72", "480"],
    "05/23/2024": ["347", "41", "344"],
    "05/24/2024": ["667", "90", "145"],
    "05/25/2024": ["244", "07", "223"],
    "05/26/2024": ["257", "47", "269"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["800", "89", "117"],
    "05/28/2024": ["446", "41", "236"],
    "05/29/2024": ["136", "02", "237"],
    "05/30/2024": ["257", "45", "249"],
    "05/31/2024": ["369", "88", "459"],
    "06/01/2024": ["166", "35", "690"],
    "06/02/2024": ["789", "40", "668"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["266", "45", "113"],
    "06/04/2024": ["150", "62", "138"],
    "06/05/2024": ["259", "60", "389"],
    "06/06/2024": ["580", "35", "267"],
    "06/07/2024": ["358", "62", "660"],
    "06/08/2024": ["578", "00", "479"],
    "06/09/2024": ["269", "72", "688"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["299", "03", "689"],
    "06/25/2024": ["145", "03", "157"],
    "06/26/2024": ["158", "44", "789"],
    "06/27/2024": ["237", "25", "249"],
    "06/28/2024": ["239", "49", "135"],
    "06/29/2024": ["259", "64", "130"],
    "06/30/2024": ["238", "39", "270"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["890", "78", "224"],
    "07/02/2024": ["345", "21", "579"],
    "07/03/2024": ["479", "04", "158"],
    "07/04/2024": ["445", "36", "600"],
    "07/05/2024": ["337", "39", "360"],
    "07/06/2024": ["145", "00", "479"],
    "07/07/2024": ["556", "60", "578"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["370", "01", "146"],
    "07/09/2024": ["778", "29", "126"],
    "07/10/2024": ["349", "61", "678"],
    "07/11/2024": ["689", "31", "489"],
    "07/12/2024": ["580", "31", "290"],
    "07/13/2024": ["368", "79", "469"],
    "07/14/2024": ["458", "76", "240"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["159", "53", "580"],
    "07/16/2024": ["679", "22", "246"],
    "07/17/2024": ["557", "78", "558"],
    "07/18/2024": ["229", "30", "677"],
    "07/19/2024": ["448", "61", "146"],
    "07/20/2024": ["126", "90", "235"],
    "07/21/2024": ["229", "30", "389"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["367", "61", "119"],
    "07/23/2024": ["247", "35", "339"],
    "07/24/2024": ["133", "78", "189"],
    "07/25/2024": ["120", "37", "340"],
    "07/26/2024": ["113", "59", "234"],
    "07/27/2024": ["400", "41", "579"],
    "07/28/2024": ["110", "23", "256"],
  },
};

export const MILANNIGHT = {
  "01/02/2023 to 01/08/2023": {
    "01/02/2023": ["345", "26", "268"],
    "01/03/2023": ["370", "04", "167"],
    "01/04/2023": ["124", "70", "488"],
    "01/05/2023": ["900", "96", "268"],
    "01/06/2023": ["459", "81", "588"],
    "01/07/2023": ["136", "07", "467"],
    "01/08/2023": ["***", "**", "***"],
  },
  "01/09/2023 to 01/15/2023": {
    "01/09/2023": ["177", "59", "234"],
    "01/10/2023": ["127", "07", "890"],
    "01/11/2023": ["479", "03", "346"],
    "01/12/2023": ["456", "54", "356"],
    "01/13/2023": ["478", "92", "246"],
    "01/14/2023": ["568", "97", "160"],
    "01/15/2023": ["***", "**", "***"],
  },
  "01/16/2023 to 01/22/2023": {
    "01/16/2023": ["460", "00", "569"],
    "01/17/2023": ["246", "25", "500"],
    "01/18/2023": ["220", "43", "779"],
    "01/19/2023": ["160", "71", "380"],
    "01/20/2023": ["336", "29", "180"],
    "01/21/2023": ["370", "06", "899"],
    "01/22/2023": ["***", "**", "***"],
  },
  "01/23/2023 to 01/29/2023": {
    "01/23/2023": ["246", "21", "128"],
    "01/24/2023": ["680", "48", "468"],
    "01/25/2023": ["577", "91", "678"],
    "01/26/2023": ["***", "**", "***"],
    "01/27/2023": ["459", "80", "370"],
    "01/28/2023": ["114", "69", "117"],
    "01/29/2023": ["***", "**", "***"],
  },
  "01/30/2023 to 02/05/2023": {
    "01/30/2023": ["224", "81", "100"],
    "01/31/2023": ["259", "67", "467"],
    "02/01/2023": ["799", "53", "599"],
    "02/02/2023": ["224", "82", "778"],
    "02/03/2023": ["370", "03", "148"],
    "02/04/2023": ["246", "26", "790"],
    "02/05/2023": ["***", "**", "***"],
  },
  "02/06/2023 to 02/12/2023": {
    "02/06/2023": ["568", "98", "440"],
    "02/07/2023": ["669", "13", "490"],
    "02/08/2023": ["239", "42", "345"],
    "02/09/2023": ["447", "51", "669"],
    "02/10/2023": ["170", "89", "388"],
    "02/11/2023": ["455", "44", "248"],
    "02/12/2023": ["***", "**", "***"],
  },
  "02/13/2023 to 02/19/2023": {
    "02/13/2023": ["240", "60", "334"],
    "02/14/2023": ["600", "67", "269"],
    "02/15/2023": ["780", "51", "236"],
    "02/16/2023": ["236", "19", "900"],
    "02/17/2023": ["368", "71", "470"],
    "02/18/2023": ["237", "25", "230"],
    "02/19/2023": ["***", "**", "***"],
  },
  "02/20/2023 to 02/26/2023": {
    "02/20/2023": ["117", "91", "290"],
    "02/21/2023": ["266", "45", "799"],
    "02/22/2023": ["458", "75", "456"],
    "02/23/2023": ["136", "02", "147"],
    "02/24/2023": ["334", "01", "146"],
    "02/25/2023": ["367", "63", "148"],
    "02/26/2023": ["***", "**", "***"],
  },
  "02/27/2023 to 03/05/2023": {
    "02/27/2023": ["237", "26", "114"],
    "02/28/2023": ["590", "47", "449"],
    "03/01/2023": ["335", "17", "133"],
    "03/02/2023": ["270", "98", "224"],
    "03/03/2023": ["459", "80", "235"],
    "03/04/2023": ["267", "53", "689"],
    "03/05/2023": ["***", "**", "***"],
  },
  "03/06/2023 to 03/12/2023": {
    "03/06/2023": ["113", "54", "770"],
    "03/07/2023": ["130", "49", "126"],
    "03/08/2023": ["***", "**", "***"],
    "03/09/2023": ["240", "61", "470"],
    "03/10/2023": ["358", "65", "366"],
    "03/11/2023": ["245", "17", "278"],
    "03/12/2023": ["***", "**", "***"],
  },
  "03/13/2023 to 03/19/2023": {
    "03/13/2023": ["600", "66", "556"],
    "03/14/2023": ["499", "28", "567"],
    "03/15/2023": ["290", "12", "237"],
    "03/16/2023": ["228", "23", "355"],
    "03/17/2023": ["446", "41", "119"],
    "03/18/2023": ["345", "25", "122"],
    "03/19/2023": ["***", "**", "***"],
  },
  "03/20/2023 to 03/26/2023": {
    "03/20/2023": ["440", "88", "990"],
    "03/21/2023": ["123", "66", "600"],
    "03/22/2023": ["139", "30", "299"],
    "03/23/2023": ["256", "38", "468"],
    "03/24/2023": ["137", "14", "680"],
    "03/25/2023": ["149", "44", "130"],
    "03/26/2023": ["***", "**", "***"],
  },
  "01/02/2023 to 01/08/2023": {
    "01/02/2023": ["345", "26", "268"],
    "01/03/2023": ["370", "04", "167"],
    "01/04/2023": ["124", "70", "488"],
    "01/05/2023": ["900", "96", "268"],
    "01/06/2023": ["459", "81", "588"],
    "01/07/2023": ["136", "07", "467"],
    "01/08/2023": ["***", "**", "***"],
  },
  "01/09/2023 to 01/15/2023": {
    "01/09/2023": ["177", "59", "234"],
    "01/10/2023": ["127", "07", "890"],
    "01/11/2023": ["479", "03", "346"],
    "01/12/2023": ["456", "54", "356"],
    "01/13/2023": ["478", "92", "246"],
    "01/14/2023": ["568", "97", "160"],
    "01/15/2023": ["***", "**", "***"],
  },
  "01/16/2023 to 01/22/2023": {
    "01/16/2023": ["460", "00", "569"],
    "01/17/2023": ["246", "25", "500"],
    "01/18/2023": ["220", "43", "779"],
    "01/19/2023": ["160", "71", "380"],
    "01/20/2023": ["336", "29", "180"],
    "01/21/2023": ["370", "06", "899"],
    "01/22/2023": ["***", "**", "***"],
  },
  "01/23/2023 to 01/29/2023": {
    "01/23/2023": ["246", "21", "128"],
    "01/24/2023": ["680", "48", "468"],
    "01/25/2023": ["577", "91", "678"],
    "01/26/2023": ["***", "**", "***"],
    "01/27/2023": ["459", "80", "370"],
    "01/28/2023": ["114", "69", "117"],
    "01/29/2023": ["***", "**", "***"],
  },
  "01/30/2023 to 02/05/2023": {
    "01/30/2023": ["224", "81", "100"],
    "01/31/2023": ["259", "67", "467"],
    "02/01/2023": ["799", "53", "599"],
    "02/02/2023": ["224", "82", "778"],
    "02/03/2023": ["370", "03", "148"],
    "02/04/2023": ["246", "26", "790"],
    "02/05/2023": ["***", "**", "***"],
  },
  "02/06/2023 to 02/12/2023": {
    "02/06/2023": ["568", "98", "440"],
    "02/07/2023": ["669", "13", "490"],
    "02/08/2023": ["239", "42", "345"],
    "02/09/2023": ["447", "51", "669"],
    "02/10/2023": ["170", "89", "388"],
    "02/11/2023": ["455", "44", "248"],
    "02/12/2023": ["***", "**", "***"],
  },
  "02/13/2023 to 02/19/2023": {
    "02/13/2023": ["240", "60", "334"],
    "02/14/2023": ["600", "67", "269"],
    "02/15/2023": ["780", "51", "236"],
    "02/16/2023": ["236", "19", "900"],
    "02/17/2023": ["368", "71", "470"],
    "02/18/2023": ["237", "25", "230"],
    "02/19/2023": ["***", "**", "***"],
  },
  "02/20/2023 to 02/26/2023": {
    "02/20/2023": ["117", "91", "290"],
    "02/21/2023": ["266", "45", "799"],
    "02/22/2023": ["458", "75", "456"],
    "02/23/2023": ["136", "02", "147"],
    "02/24/2023": ["334", "01", "146"],
    "02/25/2023": ["367", "63", "148"],
    "02/26/2023": ["***", "**", "***"],
  },
  "02/27/2023 to 03/05/2023": {
    "02/27/2023": ["237", "26", "114"],
    "02/28/2023": ["590", "47", "449"],
    "03/01/2023": ["335", "17", "133"],
    "03/02/2023": ["270", "98", "224"],
    "03/03/2023": ["459", "80", "235"],
    "03/04/2023": ["267", "53", "689"],
    "03/05/2023": ["***", "**", "***"],
  },
  "03/06/2023 to 03/12/2023": {
    "03/06/2023": ["113", "54", "770"],
    "03/07/2023": ["130", "49", "126"],
    "03/08/2023": ["***", "**", "***"],
    "03/09/2023": ["240", "61", "470"],
    "03/10/2023": ["358", "65", "366"],
    "03/11/2023": ["245", "17", "278"],
    "03/12/2023": ["***", "**", "***"],
  },
  "03/13/2023 to 03/19/2023": {
    "03/13/2023": ["600", "66", "556"],
    "03/14/2023": ["499", "28", "567"],
    "03/15/2023": ["290", "12", "237"],
    "03/16/2023": ["228", "23", "355"],
    "03/17/2023": ["446", "41", "119"],
    "03/18/2023": ["345", "25", "122"],
    "03/19/2023": ["***", "**", "***"],
  },
  "03/20/2023 to 03/26/2023": {
    "03/20/2023": ["440", "88", "990"],
    "03/21/2023": ["123", "66", "600"],
    "03/22/2023": ["139", "30", "299"],
    "03/23/2023": ["256", "38", "468"],
    "03/24/2023": ["137", "14", "680"],
    "03/25/2023": ["149", "44", "130"],
    "03/26/2023": ["***", "**", "***"],
  },
  "03/27/2023 to 04/02/2023": {
    "03/27/2023": ["125", "80", "578"],
    "03/28/2023": ["229", "36", "150"],
    "03/29/2023": ["277", "63", "229"],
    "03/30/2023": ["125", "86", "169"],
    "03/31/2023": ["200", "20", "569"],
    "04/01/2023": ["135", "90", "569"],
    "04/02/2023": ["***", "**", "***"],
  },
  "04/03/2023 to 04/09/2023": {
    "04/03/2023": ["570", "27", "124"],
    "04/04/2023": ["249", "57", "115"],
    "04/05/2023": ["580", "30", "226"],
    "04/06/2023": ["334", "03", "139"],
    "04/07/2023": ["338", "45", "113"],
    "04/08/2023": ["169", "62", "246"],
    "04/09/2023": ["***", "**", "***"],
  },
  "04/10/2023 to 04/16/2023": {
    "04/10/2023": ["669", "12", "110"],
    "04/11/2023": ["288", "85", "140"],
    "04/12/2023": ["257", "46", "150"],
    "04/13/2023": ["345", "21", "560"],
    "04/14/2023": ["335", "16", "268"],
    "04/15/2023": ["560", "13", "157"],
    "04/16/2023": ["***", "**", "***"],
  },
  "04/17/2023 to 04/23/2023": {
    "04/17/2023": ["457", "69", "667"],
    "04/18/2023": ["457", "64", "770"],
    "04/19/2023": ["168", "51", "290"],
    "04/20/2023": ["458", "71", "245"],
    "04/21/2023": ["160", "72", "129"],
    "04/22/2023": ["237", "29", "568"],
    "04/23/2023": ["***", "**", "***"],
  },
  "04/24/2023 to 04/30/2023": {
    "04/24/2023": ["567", "89", "270"],
    "04/25/2023": ["245", "10", "389"],
    "04/26/2023": ["130", "45", "357"],
    "04/27/2023": ["129", "29", "388"],
    "04/28/2023": ["366", "56", "240"],
    "04/29/2023": ["139", "34", "338"],
    "04/30/2023": ["***", "**", "***"],
  },
  "05/01/2023 to 05/07/2023": {
    "05/01/2023": ["124", "72", "147"],
    "05/02/2023": ["335", "10", "578"],
    "05/03/2023": ["578", "05", "889"],
    "05/04/2023": ["570", "26", "123"],
    "05/05/2023": ["128", "16", "358"],
    "05/06/2023": ["225", "93", "788"],
    "05/07/2023": ["***", "**", "***"],
  },
  "05/08/2023 to 05/14/2023": {
    "05/08/2023": ["139", "30", "127"],
    "05/09/2023": ["237", "26", "150"],
    "05/10/2023": ["278", "76", "790"],
    "05/11/2023": ["455", "48", "170"],
    "05/12/2023": ["233", "82", "147"],
    "05/13/2023": ["249", "55", "249"],
    "05/14/2023": ["***", "**", "***"],
  },
  "05/15/2023 to 05/21/2023": {
    "05/15/2023": ["490", "32", "147"],
    "05/16/2023": ["790", "62", "345"],
    "05/17/2023": ["259", "64", "699"],
    "05/18/2023": ["668", "09", "117"],
    "05/19/2023": ["100", "13", "238"],
    "05/20/2023": ["236", "19", "568"],
    "05/21/2023": ["***", "**", "***"],
  },
  "05/22/2023 to 05/28/2023": {
    "05/22/2023": ["126", "99", "126"],
    "05/23/2023": ["200", "28", "990"],
    "05/24/2023": ["680", "49", "577"],
    "05/25/2023": ["358", "68", "233"],
    "05/26/2023": ["258", "57", "269"],
    "05/27/2023": ["460", "09", "270"],
    "05/28/2023": ["***", "**", "***"],
  },
  "05/29/2023 to 06/04/2023": {
    "05/29/2023": ["577", "90", "370"],
    "05/30/2023": ["116", "88", "567"],
    "05/31/2023": ["450", "98", "558"],
    "06/01/2023": ["770", "49", "379"],
    "06/02/2023": ["259", "67", "160"],
    "06/03/2023": ["240", "64", "347"],
    "06/04/2023": ["***", "**", "***"],
  },
  "06/05/2023 to 06/11/2023": {
    "06/05/2023": ["234", "93", "490"],
    "06/06/2023": ["588", "19", "450"],
    "06/07/2023": ["255", "23", "337"],
    "06/08/2023": ["349", "60", "370"],
    "06/09/2023": ["567", "85", "258"],
    "06/10/2023": ["589", "24", "680"],
    "06/11/2023": ["***", "**", "***"],
  },
  "01/02/2023 to 01/08/2023": {
    "01/02/2023": ["345", "26", "268"],
    "01/03/2023": ["370", "04", "167"],
    "01/04/2023": ["124", "70", "488"],
    "01/05/2023": ["900", "96", "268"],
    "01/06/2023": ["459", "81", "588"],
    "01/07/2023": ["136", "07", "467"],
    "01/08/2023": ["***", "**", "***"],
  },
  "01/09/2023 to 01/15/2023": {
    "01/09/2023": ["177", "59", "234"],
    "01/10/2023": ["127", "07", "890"],
    "01/11/2023": ["479", "03", "346"],
    "01/12/2023": ["456", "54", "356"],
    "01/13/2023": ["478", "92", "246"],
    "01/14/2023": ["568", "97", "160"],
    "01/15/2023": ["***", "**", "***"],
  },
  "01/16/2023 to 01/22/2023": {
    "01/16/2023": ["460", "00", "569"],
    "01/17/2023": ["246", "25", "500"],
    "01/18/2023": ["220", "43", "779"],
    "01/19/2023": ["160", "71", "380"],
    "01/20/2023": ["336", "29", "180"],
    "01/21/2023": ["370", "06", "899"],
    "01/22/2023": ["***", "**", "***"],
  },
  "01/23/2023 to 01/29/2023": {
    "01/23/2023": ["246", "21", "128"],
    "01/24/2023": ["680", "48", "468"],
    "01/25/2023": ["577", "91", "678"],
    "01/26/2023": ["***", "**", "***"],
    "01/27/2023": ["459", "80", "370"],
    "01/28/2023": ["114", "69", "117"],
    "01/29/2023": ["***", "**", "***"],
  },
  "01/30/2023 to 02/05/2023": {
    "01/30/2023": ["224", "81", "100"],
    "01/31/2023": ["259", "67", "467"],
    "02/01/2023": ["799", "53", "599"],
    "02/02/2023": ["224", "82", "778"],
    "02/03/2023": ["370", "03", "148"],
    "02/04/2023": ["246", "26", "790"],
    "02/05/2023": ["***", "**", "***"],
  },
  "02/06/2023 to 02/12/2023": {
    "02/06/2023": ["568", "98", "440"],
    "02/07/2023": ["669", "13", "490"],
    "02/08/2023": ["239", "42", "345"],
    "02/09/2023": ["447", "51", "669"],
    "02/10/2023": ["170", "89", "388"],
    "02/11/2023": ["455", "44", "248"],
    "02/12/2023": ["***", "**", "***"],
  },
  "02/13/2023 to 02/19/2023": {
    "02/13/2023": ["240", "60", "334"],
    "02/14/2023": ["600", "67", "269"],
    "02/15/2023": ["780", "51", "236"],
    "02/16/2023": ["236", "19", "900"],
    "02/17/2023": ["368", "71", "470"],
    "02/18/2023": ["237", "25", "230"],
    "02/19/2023": ["***", "**", "***"],
  },
  "02/20/2023 to 02/26/2023": {
    "02/20/2023": ["117", "91", "290"],
    "02/21/2023": ["266", "45", "799"],
    "02/22/2023": ["458", "75", "456"],
    "02/23/2023": ["136", "02", "147"],
    "02/24/2023": ["334", "01", "146"],
    "02/25/2023": ["367", "63", "148"],
    "02/26/2023": ["***", "**", "***"],
  },
  "02/27/2023 to 03/05/2023": {
    "02/27/2023": ["237", "26", "114"],
    "02/28/2023": ["590", "47", "449"],
    "03/01/2023": ["335", "17", "133"],
    "03/02/2023": ["270", "98", "224"],
    "03/03/2023": ["459", "80", "235"],
    "03/04/2023": ["267", "53", "689"],
    "03/05/2023": ["***", "**", "***"],
  },
  "03/06/2023 to 03/12/2023": {
    "03/06/2023": ["113", "54", "770"],
    "03/07/2023": ["130", "49", "126"],
    "03/08/2023": ["***", "**", "***"],
    "03/09/2023": ["240", "61", "470"],
    "03/10/2023": ["358", "65", "366"],
    "03/11/2023": ["245", "17", "278"],
    "03/12/2023": ["***", "**", "***"],
  },
  "03/13/2023 to 03/19/2023": {
    "03/13/2023": ["600", "66", "556"],
    "03/14/2023": ["499", "28", "567"],
    "03/15/2023": ["290", "12", "237"],
    "03/16/2023": ["228", "23", "355"],
    "03/17/2023": ["446", "41", "119"],
    "03/18/2023": ["345", "25", "122"],
    "03/19/2023": ["***", "**", "***"],
  },
  "03/20/2023 to 03/26/2023": {
    "03/20/2023": ["440", "88", "990"],
    "03/21/2023": ["123", "66", "600"],
    "03/22/2023": ["139", "30", "299"],
    "03/23/2023": ["256", "38", "468"],
    "03/24/2023": ["137", "14", "680"],
    "03/25/2023": ["149", "44", "130"],
    "03/26/2023": ["***", "**", "***"],
  },
  "03/27/2023 to 04/02/2023": {
    "03/27/2023": ["125", "80", "578"],
    "03/28/2023": ["229", "36", "150"],
    "03/29/2023": ["277", "63", "229"],
    "03/30/2023": ["125", "86", "169"],
    "03/31/2023": ["200", "20", "569"],
    "04/01/2023": ["135", "90", "569"],
    "04/02/2023": ["***", "**", "***"],
  },
  "04/03/2023 to 04/09/2023": {
    "04/03/2023": ["570", "27", "124"],
    "04/04/2023": ["249", "57", "115"],
    "04/05/2023": ["580", "30", "226"],
    "04/06/2023": ["334", "03", "139"],
    "04/07/2023": ["338", "45", "113"],
    "04/08/2023": ["169", "62", "246"],
    "04/09/2023": ["***", "**", "***"],
  },
  "04/10/2023 to 04/16/2023": {
    "04/10/2023": ["669", "12", "110"],
    "04/11/2023": ["288", "85", "140"],
    "04/12/2023": ["257", "46", "150"],
    "04/13/2023": ["345", "21", "560"],
    "04/14/2023": ["335", "16", "268"],
    "04/15/2023": ["560", "13", "157"],
    "04/16/2023": ["***", "**", "***"],
  },
  "04/17/2023 to 04/23/2023": {
    "04/17/2023": ["457", "69", "667"],
    "04/18/2023": ["457", "64", "770"],
    "04/19/2023": ["168", "51", "290"],
    "04/20/2023": ["458", "71", "245"],
    "04/21/2023": ["160", "72", "129"],
    "04/22/2023": ["237", "29", "568"],
    "04/23/2023": ["***", "**", "***"],
  },
  "04/24/2023 to 04/30/2023": {
    "04/24/2023": ["567", "89", "270"],
    "04/25/2023": ["245", "10", "389"],
    "04/26/2023": ["130", "45", "357"],
    "04/27/2023": ["129", "29", "388"],
    "04/28/2023": ["366", "56", "240"],
    "04/29/2023": ["139", "34", "338"],
    "04/30/2023": ["***", "**", "***"],
  },
  "05/01/2023 to 05/07/2023": {
    "05/01/2023": ["124", "72", "147"],
    "05/02/2023": ["335", "10", "578"],
    "05/03/2023": ["578", "05", "889"],
    "05/04/2023": ["570", "26", "123"],
    "05/05/2023": ["128", "16", "358"],
    "05/06/2023": ["225", "93", "788"],
    "05/07/2023": ["***", "**", "***"],
  },
  "05/08/2023 to 05/14/2023": {
    "05/08/2023": ["139", "30", "127"],
    "05/09/2023": ["237", "26", "150"],
    "05/10/2023": ["278", "76", "790"],
    "05/11/2023": ["455", "48", "170"],
    "05/12/2023": ["233", "82", "147"],
    "05/13/2023": ["249", "55", "249"],
    "05/14/2023": ["***", "**", "***"],
  },
  "05/15/2023 to 05/21/2023": {
    "05/15/2023": ["490", "32", "147"],
    "05/16/2023": ["790", "62", "345"],
    "05/17/2023": ["259", "64", "699"],
    "05/18/2023": ["668", "09", "117"],
    "05/19/2023": ["100", "13", "238"],
    "05/20/2023": ["236", "19", "568"],
    "05/21/2023": ["***", "**", "***"],
  },
  "05/22/2023 to 05/28/2023": {
    "05/22/2023": ["126", "99", "126"],
    "05/23/2023": ["200", "28", "990"],
    "05/24/2023": ["680", "49", "577"],
    "05/25/2023": ["358", "68", "233"],
    "05/26/2023": ["258", "57", "269"],
    "05/27/2023": ["460", "09", "270"],
    "05/28/2023": ["***", "**", "***"],
  },
  "05/29/2023 to 06/04/2023": {
    "05/29/2023": ["577", "90", "370"],
    "05/30/2023": ["116", "88", "567"],
    "05/31/2023": ["450", "98", "558"],
    "06/01/2023": ["770", "49", "379"],
    "06/02/2023": ["259", "67", "160"],
    "06/03/2023": ["240", "64", "347"],
    "06/04/2023": ["***", "**", "***"],
  },
  "06/05/2023 to 06/11/2023": {
    "06/05/2023": ["234", "93", "490"],
    "06/06/2023": ["588", "19", "450"],
    "06/07/2023": ["255", "23", "337"],
    "06/08/2023": ["349", "60", "370"],
    "06/09/2023": ["567", "85", "258"],
    "06/10/2023": ["589", "24", "680"],
    "06/11/2023": ["***", "**", "***"],
  },
  "06/12/2023 to 06/18/2023": {
    "06/12/2023": ["899", "62", "200"],
    "06/13/2023": ["667", "90", "578"],
    "06/14/2023": ["240", "65", "177"],
    "06/15/2023": ["799", "52", "246"],
    "06/16/2023": ["148", "34", "699"],
    "06/17/2023": ["148", "32", "345"],
    "06/18/2023": ["***", "**", "***"],
  },
  "06/19/2023 to 06/25/2023": {
    "06/19/2023": ["229", "33", "670"],
    "06/20/2023": ["445", "32", "246"],
    "06/21/2023": ["349", "69", "450"],
    "06/22/2023": ["129", "29", "135"],
    "06/23/2023": ["348", "57", "223"],
    "06/24/2023": ["346", "31", "119"],
    "06/25/2023": ["***", "**", "***"],
  },
  "06/26/2023 to 07/02/2023": {
    "06/26/2023": ["144", "99", "289"],
    "06/27/2023": ["477", "82", "390"],
    "06/28/2023": ["489", "16", "457"],
    "06/29/2023": ["335", "16", "790"],
    "06/30/2023": ["167", "42", "570"],
    "07/01/2023": ["345", "27", "458"],
    "07/02/2023": ["***", "**", "***"],
  },
  "07/03/2023 to 07/09/2023": {
    "07/03/2023": ["148", "38", "369"],
    "07/04/2023": ["456", "56", "880"],
    "07/05/2023": ["780", "59", "478"],
    "07/06/2023": ["689", "33", "238"],
    "07/07/2023": ["112", "44", "167"],
    "07/08/2023": ["170", "85", "690"],
    "07/09/2023": ["***", "**", "***"],
  },
  "07/10/2023 to 07/16/2023": {
    "07/10/2023": ["220", "44", "347"],
    "07/11/2023": ["666", "85", "339"],
    "07/12/2023": ["125", "85", "267"],
    "07/13/2023": ["550", "01", "399"],
    "07/14/2023": ["120", "38", "350"],
    "07/15/2023": ["368", "71", "245"],
    "07/16/2023": ["***", "**", "***"],
  },
  "07/17/2023 to 07/23/2023": {
    "07/17/2023": ["130", "49", "270"],
    "07/18/2023": ["334", "07", "566"],
    "07/19/2023": ["489", "11", "560"],
    "07/20/2023": ["889", "53", "238"],
    "07/21/2023": ["234", "93", "157"],
    "07/22/2023": ["289", "95", "456"],
    "07/23/2023": ["***", "**", "***"],
  },
  "07/24/2023 to 07/30/2023": {
    "07/24/2023": ["246", "20", "136"],
    "07/25/2023": ["550", "04", "266"],
    "07/26/2023": ["225", "99", "289"],
    "07/27/2023": ["146", "18", "233"],
    "07/28/2023": ["248", "45", "267"],
    "07/29/2023": ["578", "04", "590"],
    "07/30/2023": ["***", "**", "***"],
  },
  "07/31/2023 to 08/06/2023": {
    "07/31/2023": ["260", "88", "468"],
    "08/01/2023": ["128", "17", "133"],
    "08/02/2023": ["126", "91", "380"],
    "08/03/2023": ["359", "79", "667"],
    "08/04/2023": ["470", "10", "145"],
    "08/05/2023": ["115", "72", "679"],
    "08/06/2023": ["***", "**", "***"],
  },
  "08/07/2023 to 08/13/2023": {
    "08/07/2023": ["180", "98", "558"],
    "08/08/2023": ["467", "72", "129"],
    "08/09/2023": ["378", "85", "168"],
    "08/10/2023": ["256", "33", "490"],
    "08/11/2023": ["128", "19", "568"],
    "08/12/2023": ["370", "05", "249"],
    "08/13/2023": ["***", "**", "***"],
  },
  "08/14/2023 to 08/20/2023": {
    "08/14/2023": ["129", "24", "257"],
    "08/15/2023": ["***", "**", "***"],
    "08/16/2023": ["130", "43", "247"],
    "08/17/2023": ["678", "11", "380"],
    "08/18/2023": ["590", "44", "257"],
    "08/19/2023": ["459", "82", "237"],
    "08/20/2023": ["***", "**", "***"],
  },
  "08/21/2023 to 08/27/2023": {
    "08/21/2023": ["***", "**", "***"],
    "08/22/2023": ["499", "28", "260"],
    "08/23/2023": ["377", "78", "567"],
    "08/24/2023": ["227", "16", "457"],
    "08/25/2023": ["770", "47", "133"],
    "08/26/2023": ["770", "40", "488"],
    "08/27/2023": ["***", "**", "***"],
  },
  "08/28/2023 to 09/03/2023": {
    "08/28/2023": ["789", "42", "345"],
    "08/29/2023": ["788", "39", "234"],
    "08/30/2023": ["490", "37", "467"],
    "08/31/2023": ["240", "60", "389"],
    "09/01/2023": ["689", "36", "367"],
    "09/02/2023": ["136", "09", "180"],
    "09/03/2023": ["***", "**", "***"],
  },
  "03/27/2023 to 04/02/2023": {
    "03/27/2023": ["125", "80", "578"],
    "03/28/2023": ["229", "36", "150"],
    "03/29/2023": ["277", "63", "229"],
    "03/30/2023": ["125", "86", "169"],
    "03/31/2023": ["200", "20", "569"],
    "04/01/2023": ["135", "90", "569"],
    "04/02/2023": ["***", "**", "***"],
  },
  "04/03/2023 to 04/09/2023": {
    "04/03/2023": ["570", "27", "124"],
    "04/04/2023": ["249", "57", "115"],
    "04/05/2023": ["580", "30", "226"],
    "04/06/2023": ["334", "03", "139"],
    "04/07/2023": ["338", "45", "113"],
    "04/08/2023": ["169", "62", "246"],
    "04/09/2023": ["***", "**", "***"],
  },
  "04/10/2023 to 04/16/2023": {
    "04/10/2023": ["669", "12", "110"],
    "04/11/2023": ["288", "85", "140"],
    "04/12/2023": ["257", "46", "150"],
    "04/13/2023": ["345", "21", "560"],
    "04/14/2023": ["335", "16", "268"],
    "04/15/2023": ["560", "13", "157"],
    "04/16/2023": ["***", "**", "***"],
  },
  "04/17/2023 to 04/23/2023": {
    "04/17/2023": ["457", "69", "667"],
    "04/18/2023": ["457", "64", "770"],
    "04/19/2023": ["168", "51", "290"],
    "04/20/2023": ["458", "71", "245"],
    "04/21/2023": ["160", "72", "129"],
    "04/22/2023": ["237", "29", "568"],
    "04/23/2023": ["***", "**", "***"],
  },
  "04/24/2023 to 04/30/2023": {
    "04/24/2023": ["567", "89", "270"],
    "04/25/2023": ["245", "10", "389"],
    "04/26/2023": ["130", "45", "357"],
    "04/27/2023": ["129", "29", "388"],
    "04/28/2023": ["366", "56", "240"],
    "04/29/2023": ["139", "34", "338"],
    "04/30/2023": ["***", "**", "***"],
  },
  "05/01/2023 to 05/07/2023": {
    "05/01/2023": ["124", "72", "147"],
    "05/02/2023": ["335", "10", "578"],
    "05/03/2023": ["578", "05", "889"],
    "05/04/2023": ["570", "26", "123"],
    "05/05/2023": ["128", "16", "358"],
    "05/06/2023": ["225", "93", "788"],
    "05/07/2023": ["***", "**", "***"],
  },
  "05/08/2023 to 05/14/2023": {
    "05/08/2023": ["139", "30", "127"],
    "05/09/2023": ["237", "26", "150"],
    "05/10/2023": ["278", "76", "790"],
    "05/11/2023": ["455", "48", "170"],
    "05/12/2023": ["233", "82", "147"],
    "05/13/2023": ["249", "55", "249"],
    "05/14/2023": ["***", "**", "***"],
  },
  "05/15/2023 to 05/21/2023": {
    "05/15/2023": ["490", "32", "147"],
    "05/16/2023": ["790", "62", "345"],
    "05/17/2023": ["259", "64", "699"],
    "05/18/2023": ["668", "09", "117"],
    "05/19/2023": ["100", "13", "238"],
    "05/20/2023": ["236", "19", "568"],
    "05/21/2023": ["***", "**", "***"],
  },
  "05/22/2023 to 05/28/2023": {
    "05/22/2023": ["126", "99", "126"],
    "05/23/2023": ["200", "28", "990"],
    "05/24/2023": ["680", "49", "577"],
    "05/25/2023": ["358", "68", "233"],
    "05/26/2023": ["258", "57", "269"],
    "05/27/2023": ["460", "09", "270"],
    "05/28/2023": ["***", "**", "***"],
  },
  "05/29/2023 to 06/04/2023": {
    "05/29/2023": ["577", "90", "370"],
    "05/30/2023": ["116", "88", "567"],
    "05/31/2023": ["450", "98", "558"],
    "06/01/2023": ["770", "49", "379"],
    "06/02/2023": ["259", "67", "160"],
    "06/03/2023": ["240", "64", "347"],
    "06/04/2023": ["***", "**", "***"],
  },
  "06/05/2023 to 06/11/2023": {
    "06/05/2023": ["234", "93", "490"],
    "06/06/2023": ["588", "19", "450"],
    "06/07/2023": ["255", "23", "337"],
    "06/08/2023": ["349", "60", "370"],
    "06/09/2023": ["567", "85", "258"],
    "06/10/2023": ["589", "24", "680"],
    "06/11/2023": ["***", "**", "***"],
  },
  "06/12/2023 to 06/18/2023": {
    "06/12/2023": ["899", "62", "200"],
    "06/13/2023": ["667", "90", "578"],
    "06/14/2023": ["240", "65", "177"],
    "06/15/2023": ["799", "52", "246"],
    "06/16/2023": ["148", "34", "699"],
    "06/17/2023": ["148", "32", "345"],
    "06/18/2023": ["***", "**", "***"],
  },
  "06/19/2023 to 06/25/2023": {
    "06/19/2023": ["229", "33", "670"],
    "06/20/2023": ["445", "32", "246"],
    "06/21/2023": ["349", "69", "450"],
    "06/22/2023": ["129", "29", "135"],
    "06/23/2023": ["348", "57", "223"],
    "06/24/2023": ["346", "31", "119"],
    "06/25/2023": ["***", "**", "***"],
  },
  "06/26/2023 to 07/02/2023": {
    "06/26/2023": ["144", "99", "289"],
    "06/27/2023": ["477", "82", "390"],
    "06/28/2023": ["489", "16", "457"],
    "06/29/2023": ["335", "16", "790"],
    "06/30/2023": ["167", "42", "570"],
    "07/01/2023": ["345", "27", "458"],
    "07/02/2023": ["***", "**", "***"],
  },
  "07/03/2023 to 07/09/2023": {
    "07/03/2023": ["148", "38", "369"],
    "07/04/2023": ["456", "56", "880"],
    "07/05/2023": ["780", "59", "478"],
    "07/06/2023": ["689", "33", "238"],
    "07/07/2023": ["112", "44", "167"],
    "07/08/2023": ["170", "85", "690"],
    "07/09/2023": ["***", "**", "***"],
  },
  "07/10/2023 to 07/16/2023": {
    "07/10/2023": ["220", "44", "347"],
    "07/11/2023": ["666", "85", "339"],
    "07/12/2023": ["125", "85", "267"],
    "07/13/2023": ["550", "01", "399"],
    "07/14/2023": ["120", "38", "350"],
    "07/15/2023": ["368", "71", "245"],
    "07/16/2023": ["***", "**", "***"],
  },
  "07/17/2023 to 07/23/2023": {
    "07/17/2023": ["130", "49", "270"],
    "07/18/2023": ["334", "07", "566"],
    "07/19/2023": ["489", "11", "560"],
    "07/20/2023": ["889", "53", "238"],
    "07/21/2023": ["234", "93", "157"],
    "07/22/2023": ["289", "95", "456"],
    "07/23/2023": ["***", "**", "***"],
  },
  "07/24/2023 to 07/30/2023": {
    "07/24/2023": ["246", "20", "136"],
    "07/25/2023": ["550", "04", "266"],
    "07/26/2023": ["225", "99", "289"],
    "07/27/2023": ["146", "18", "233"],
    "07/28/2023": ["248", "45", "267"],
    "07/29/2023": ["578", "04", "590"],
    "07/30/2023": ["***", "**", "***"],
  },
  "07/31/2023 to 08/06/2023": {
    "07/31/2023": ["260", "88", "468"],
    "08/01/2023": ["128", "17", "133"],
    "08/02/2023": ["126", "91", "380"],
    "08/03/2023": ["359", "79", "667"],
    "08/04/2023": ["470", "10", "145"],
    "08/05/2023": ["115", "72", "679"],
    "08/06/2023": ["***", "**", "***"],
  },
  "08/07/2023 to 08/13/2023": {
    "08/07/2023": ["180", "98", "558"],
    "08/08/2023": ["467", "72", "129"],
    "08/09/2023": ["378", "85", "168"],
    "08/10/2023": ["256", "33", "490"],
    "08/11/2023": ["128", "19", "568"],
    "08/12/2023": ["370", "05", "249"],
    "08/13/2023": ["***", "**", "***"],
  },
  "08/14/2023 to 08/20/2023": {
    "08/14/2023": ["129", "24", "257"],
    "08/15/2023": ["***", "**", "***"],
    "08/16/2023": ["130", "43", "247"],
    "08/17/2023": ["678", "11", "380"],
    "08/18/2023": ["590", "44", "257"],
    "08/19/2023": ["459", "82", "237"],
    "08/20/2023": ["***", "**", "***"],
  },
  "08/21/2023 to 08/27/2023": {
    "08/21/2023": ["***", "**", "***"],
    "08/22/2023": ["499", "28", "260"],
    "08/23/2023": ["377", "78", "567"],
    "08/24/2023": ["227", "16", "457"],
    "08/25/2023": ["770", "47", "133"],
    "08/26/2023": ["770", "40", "488"],
    "08/27/2023": ["***", "**", "***"],
  },
  "08/28/2023 to 09/03/2023": {
    "08/28/2023": ["789", "42", "345"],
    "08/29/2023": ["788", "39", "234"],
    "08/30/2023": ["490", "37", "467"],
    "08/31/2023": ["240", "60", "389"],
    "09/01/2023": ["689", "36", "367"],
    "09/02/2023": ["136", "09", "180"],
    "09/03/2023": ["***", "**", "***"],
  },
  "09/04/2023 to 09/10/2023": {
    "09/04/2023": ["399", "19", "360"],
    "09/05/2023": ["348", "57", "250"],
    "09/06/2023": ["240", "60", "569"],
    "09/07/2023": ["147", "20", "299"],
    "09/08/2023": ["569", "00", "235"],
    "09/09/2023": ["378", "84", "789"],
    "09/10/2023": ["***", "**", "***"],
  },
  "09/11/2023 to 09/17/2023": {
    "09/11/2023": ["890", "70", "677"],
    "09/12/2023": ["237", "26", "349"],
    "09/13/2023": ["889", "51", "290"],
    "09/14/2023": ["568", "93", "229"],
    "09/15/2023": ["180", "97", "377"],
    "09/16/2023": ["458", "78", "116"],
    "09/17/2023": ["***", "**", "***"],
  },
  "09/18/2023 to 09/24/2023": {
    "09/18/2023": ["147", "25", "348"],
    "09/19/2023": ["125", "89", "450"],
    "09/20/2023": ["234", "97", "160"],
    "09/21/2023": ["460", "04", "680"],
    "09/22/2023": ["490", "34", "266"],
    "09/23/2023": ["112", "43", "490"],
    "09/24/2023": ["***", "**", "***"],
  },
  "09/25/2023 to 10/01/2023": {
    "09/25/2023": ["458", "73", "247"],
    "09/26/2023": ["380", "15", "780"],
    "09/27/2023": ["136", "05", "500"],
    "09/28/2023": ["256", "39", "117"],
    "09/29/2023": ["269", "77", "340"],
    "09/30/2023": ["468", "80", "578"],
    "10/01/2023": ["***", "**", "***"],
  },
  "10/02/2023 to 10/08/2023": {
    "10/02/2023": ["670", "32", "129"],
    "10/03/2023": ["257", "47", "340"],
    "10/04/2023": ["233", "85", "348"],
    "10/05/2023": ["247", "32", "570"],
    "10/06/2023": ["158", "43", "670"],
    "10/07/2023": ["130", "40", "190"],
    "10/08/2023": ["***", "**", "***"],
  },
  "10/09/2023 to 10/15/2023": {
    "10/09/2023": ["470", "15", "249"],
    "10/10/2023": ["279", "86", "457"],
    "10/11/2023": ["126", "99", "360"],
    "10/12/2023": ["580", "38", "567"],
    "10/13/2023": ["469", "99", "450"],
    "10/14/2023": ["120", "38", "558"],
    "10/15/2023": ["***", "**", "***"],
  },
  "10/16/2023 to 10/22/2023": {
    "10/16/2023": ["560", "13", "599"],
    "10/17/2023": ["170", "84", "158"],
    "10/18/2023": ["578", "06", "448"],
    "10/19/2023": ["240", "65", "168"],
    "10/20/2023": ["360", "98", "260"],
    "10/21/2023": ["669", "16", "268"],
    "10/22/2023": ["***", "**", "***"],
  },
  "10/23/2023 to 10/29/2023": {
    "10/23/2023": ["689", "38", "125"],
    "10/24/2023": ["345", "26", "123"],
    "10/25/2023": ["334", "02", "778"],
    "10/26/2023": ["578", "02", "336"],
    "10/27/2023": ["580", "39", "126"],
    "10/28/2023": ["348", "57", "160"],
    "10/29/2023": ["***", "**", "***"],
  },
  "10/30/2023 to 11/05/2023": {
    "10/30/2023": ["126", "90", "479"],
    "10/31/2023": ["137", "19", "289"],
    "11/01/2023": ["889", "56", "240"],
    "11/02/2023": ["790", "69", "117"],
    "11/03/2023": ["345", "23", "139"],
    "11/04/2023": ["466", "62", "480"],
    "11/05/2023": ["***", "**", "***"],
  },
  "11/06/2023 to 11/12/2023": {
    "11/06/2023": ["114", "65", "249"],
    "11/07/2023": ["128", "10", "578"],
    "11/08/2023": ["678", "19", "379"],
    "11/09/2023": ["268", "68", "189"],
    "11/10/2023": ["***", "**", "***"],
    "11/11/2023": ["***", "**", "***"],
    "11/12/2023": ["***", "**", "***"],
  },
  "11/13/2023 to 11/19/2023": {
    "11/13/2023": ["***", "**", "***"],
    "11/14/2023": ["***", "**", "***"],
    "11/15/2023": ["***", "**", "***"],
    "11/16/2023": ["***", "**", "***"],
    "11/17/2023": ["***", "**", "***"],
    "11/18/2023": ["***", "**", "***"],
    "11/19/2023": ["***", "**", "***"],
  },
  "11/20/2023 to 11/26/2023": {
    "11/20/2023": ["189", "82", "255"],
    "11/21/2023": ["120", "34", "248"],
    "11/22/2023": ["447", "51", "588"],
    "11/23/2023": ["449", "77", "467"],
    "11/24/2023": ["356", "45", "348"],
    "11/25/2023": ["669", "15", "348"],
    "11/26/2023": ["***", "**", "***"],
  },
  "11/27/2023 to 12/03/2023": {
    "11/27/2023": ["399", "14", "446"],
    "11/28/2023": ["234", "99", "568"],
    "11/29/2023": ["378", "84", "239"],
    "11/30/2023": ["457", "64", "400"],
    "12/01/2023": ["334", "08", "800"],
    "12/02/2023": ["237", "23", "256"],
    "12/03/2023": ["***", "**", "***"],
  },
  "12/04/2023 to 12/10/2023": {
    "12/04/2023": ["144", "90", "578"],
    "12/05/2023": ["156", "24", "446"],
    "12/06/2023": ["680", "48", "990"],
    "12/07/2023": ["268", "68", "125"],
    "12/08/2023": ["159", "57", "368"],
    "12/09/2023": ["359", "71", "146"],
    "12/10/2023": ["***", "**", "***"],
  },
  "12/11/2023 to 12/17/2023": {
    "12/11/2023": ["559", "94", "789"],
    "12/12/2023": ["234", "95", "267"],
    "12/13/2023": ["467", "76", "268"],
    "12/14/2023": ["890", "79", "135"],
    "12/15/2023": ["249", "50", "389"],
    "12/16/2023": ["359", "76", "123"],
    "12/17/2023": ["***", "**", "***"],
  },
  "12/18/2023 to 12/24/2023": {
    "12/18/2023": ["340", "77", "890"],
    "12/19/2023": ["167", "41", "678"],
    "12/20/2023": ["267", "53", "256"],
    "12/21/2023": ["113", "54", "347"],
    "12/22/2023": ["255", "21", "137"],
    "12/23/2023": ["268", "63", "148"],
    "12/24/2023": ["***", "**", "***"],
  },
  "12/25/2023 to 12/31/2023": {
    "12/25/2023": ["400", "42", "778"],
    "12/26/2023": ["467", "75", "113"],
    "12/27/2023": ["588", "14", "248"],
    "12/28/2023": ["359", "79", "126"],
    "12/29/2023": ["600", "67", "467"],
    "12/30/2023": ["560", "15", "267"],
    "12/31/2023": ["***", "**", "***"],
  },
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["230", "50", "280"],
    "01/02/2024": ["279", "88", "288"],
    "01/03/2024": ["459", "80", "488"],
    "01/04/2024": ["568", "96", "349"],
    "01/05/2024": ["150", "69", "270"],
    "01/06/2024": ["340", "79", "478"],
    "01/07/2024": ["***", "**", "***"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["790", "61", "777"],
    "01/09/2024": ["299", "02", "480"],
    "01/10/2024": ["889", "50", "190"],
    "01/11/2024": ["349", "68", "468"],
    "01/12/2024": ["127", "02", "345"],
    "01/13/2024": ["156", "27", "250"],
    "01/14/2024": ["***", "**", "***"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["890", "77", "188"],
    "01/16/2024": ["145", "08", "189"],
    "01/17/2024": ["369", "88", "440"],
    "01/18/2024": ["244", "01", "380"],
    "01/19/2024": ["890", "77", "458"],
    "01/20/2024": ["290", "18", "369"],
    "01/21/2024": ["***", "**", "***"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["568", "91", "146"],
    "01/23/2024": ["169", "61", "380"],
    "01/24/2024": ["570", "28", "279"],
    "01/25/2024": ["123", "64", "149"],
    "01/26/2024": ["***", "**", "***"],
    "01/27/2024": ["789", "44", "789"],
    "01/28/2024": ["***", "**", "***"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["137", "12", "480"],
    "01/30/2024": ["456", "50", "145"],
    "01/31/2024": ["226", "05", "456"],
    "02/01/2024": ["135", "99", "478"],
    "02/02/2024": ["550", "05", "357"],
    "02/03/2024": ["145", "04", "257"],
    "02/04/2024": ["***", "**", "***"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["250", "70", "190"],
    "02/06/2024": ["180", "95", "159"],
    "02/07/2024": ["134", "87", "368"],
    "02/08/2024": ["134", "81", "245"],
    "02/09/2024": ["239", "41", "678"],
    "02/10/2024": ["249", "58", "189"],
    "02/11/2024": ["***", "**", "***"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["149", "40", "578"],
    "02/13/2024": ["123", "66", "178"],
    "02/14/2024": ["158", "44", "149"],
    "02/15/2024": ["456", "52", "480"],
    "02/16/2024": ["118", "05", "230"],
    "02/17/2024": ["358", "62", "246"],
    "02/18/2024": ["***", "**", "***"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["225", "97", "115"],
    "02/20/2024": ["156", "20", "479"],
    "02/21/2024": ["455", "40", "479"],
    "02/22/2024": ["235", "08", "666"],
    "02/23/2024": ["790", "67", "449"],
    "02/24/2024": ["670", "36", "358"],
    "02/25/2024": ["***", "**", "***"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["460", "01", "155"],
    "02/27/2024": ["569", "05", "780"],
    "02/28/2024": ["699", "46", "178"],
    "02/29/2024": ["234", "94", "455"],
    "03/01/2024": ["129", "27", "250"],
    "03/02/2024": ["450", "92", "570"],
    "03/03/2024": ["***", "**", "***"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["560", "17", "368"],
    "03/05/2024": ["449", "70", "280"],
    "03/06/2024": ["600", "63", "229"],
    "03/07/2024": ["248", "47", "890"],
    "03/08/2024": ["568", "95", "258"],
    "03/09/2024": ["237", "24", "699"],
    "03/10/2024": ["***", "**", "***"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["678", "13", "166"],
    "03/12/2024": ["180", "98", "260"],
    "03/13/2024": ["370", "01", "579"],
    "03/14/2024": ["160", "78", "350"],
    "03/15/2024": ["159", "53", "670"],
    "03/16/2024": ["236", "19", "180"],
    "03/17/2024": ["***", "**", "***"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["347", "44", "248"],
    "03/19/2024": ["578", "01", "245"],
    "03/20/2024": ["146", "12", "156"],
    "03/21/2024": ["399", "16", "240"],
    "03/22/2024": ["238", "34", "347"],
    "03/23/2024": ["240", "66", "349"],
    "03/24/2024": ["***", "**", "***"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["***", "**", "***"],
    "03/26/2024": ["157", "37", "340"],
    "03/27/2024": ["378", "84", "257"],
    "03/28/2024": ["670", "30", "569"],
    "03/29/2024": ["267", "57", "250"],
    "03/30/2024": ["257", "46", "358"],
    "03/31/2024": ["***", "**", "***"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["169", "67", "269"],
    "04/02/2024": ["178", "62", "228"],
    "04/03/2024": ["689", "39", "270"],
    "04/04/2024": ["244", "04", "680"],
    "04/05/2024": ["689", "32", "138"],
    "04/06/2024": ["800", "88", "224"],
    "04/07/2024": ["***", "**", "***"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["126", "91", "399"],
    "04/09/2024": ["160", "78", "567"],
    "04/10/2024": ["230", "51", "560"],
    "04/11/2024": ["280", "04", "347"],
    "04/12/2024": ["578", "06", "358"],
    "04/13/2024": ["139", "35", "168"],
    "04/14/2024": ["***", "**", "***"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["455", "41", "137"],
    "04/16/2024": ["479", "03", "238"],
    "04/17/2024": ["467", "73", "490"],
    "04/18/2024": ["670", "39", "478"],
    "04/19/2024": ["779", "39", "360"],
    "04/20/2024": ["669", "10", "578"],
    "04/21/2024": ["***", "**", "***"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["126", "90", "569"],
    "04/23/2024": ["179", "71", "560"],
    "04/24/2024": ["178", "69", "478"],
    "04/25/2024": ["148", "31", "669"],
    "04/26/2024": ["240", "65", "168"],
    "04/27/2024": ["146", "13", "148"],
    "04/28/2024": ["***", "**", "***"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["278", "78", "459"],
    "04/30/2024": ["155", "19", "379"],
    "05/01/2024": ["256", "39", "900"],
    "05/02/2024": ["336", "29", "180"],
    "05/03/2024": ["235", "02", "129"],
    "05/04/2024": ["280", "09", "450"],
    "05/05/2024": ["***", "**", "***"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["468", "82", "129"],
    "05/07/2024": ["400", "40", "244"],
    "05/08/2024": ["137", "17", "449"],
    "05/09/2024": ["239", "43", "247"],
    "05/10/2024": ["279", "89", "478"],
    "05/11/2024": ["478", "91", "137"],
    "05/12/2024": ["***", "**", "***"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["445", "35", "140"],
    "05/14/2024": ["167", "43", "346"],
    "05/15/2024": ["167", "46", "880"],
    "05/16/2024": ["590", "42", "589"],
    "05/17/2024": ["350", "84", "158"],
    "05/18/2024": ["149", "47", "557"],
    "05/19/2024": ["***", "**", "***"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["590", "40", "127"],
    "05/21/2024": ["246", "25", "690"],
    "05/22/2024": ["350", "86", "349"],
    "05/23/2024": ["249", "57", "160"],
    "05/24/2024": ["789", "43", "139"],
    "05/25/2024": ["249", "57", "250"],
    "05/26/2024": ["***", "**", "***"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["380", "19", "478"],
    "05/28/2024": ["170", "89", "379"],
    "05/29/2024": ["157", "35", "230"],
    "05/30/2024": ["148", "32", "679"],
    "05/31/2024": ["269", "76", "268"],
    "06/01/2024": ["230", "59", "568"],
    "06/02/2024": ["***", "**", "***"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["567", "85", "267"],
    "06/04/2024": ["358", "63", "148"],
    "06/05/2024": ["680", "43", "788"],
    "06/06/2024": ["148", "30", "280"],
    "06/07/2024": ["137", "15", "889"],
    "06/08/2024": ["557", "79", "360"],
    "06/09/2024": ["***", "**", "***"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["369", "82", "237"],
    "06/25/2024": ["170", "86", "790"],
    "06/26/2024": ["779", "31", "588"],
    "06/27/2024": ["337", "33", "346"],
    "06/28/2024": ["126", "95", "113"],
    "06/29/2024": ["778", "23", "689"],
    "06/30/2024": ["***", "**", "***"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["688", "25", "230"],
    "07/02/2024": ["358", "68", "279"],
    "07/03/2024": ["380", "14", "112"],
    "07/04/2024": ["660", "28", "558"],
    "07/05/2024": ["350", "88", "468"],
    "07/06/2024": ["156", "26", "268"],
    "07/07/2024": ["***", "**", "***"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["358", "62", "156"],
    "07/09/2024": ["790", "62", "228"],
    "07/10/2024": ["179", "72", "147"],
    "07/11/2024": ["269", "76", "123"],
    "07/12/2024": ["140", "54", "239"],
    "07/13/2024": ["790", "68", "170"],
    "07/14/2024": ["***", "**", "***"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["789", "48", "260"],
    "07/16/2024": ["239", "43", "256"],
    "07/17/2024": ["157", "36", "349"],
    "07/18/2024": ["347", "40", "677"],
    "07/19/2024": ["458", "72", "679"],
    "07/20/2024": ["226", "08", "125"],
    "07/21/2024": ["***", "**", "***"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["459", "88", "369"],
    "07/23/2024": ["136", "06", "268"],
    "07/24/2024": ["236", "11", "678"],
    "07/25/2024": ["789", "41", "236"],
    "07/26/2024": ["450", "90", "190"],
    "07/27/2024": ["780", "55", "159"],
    "07/28/2024": ["***", "**", "***"],
  },
};

export const RAJDHANINIGHT = {
  "01/02/2023 to 01/08/2023": {
    "01/02/2023": ["334", "02", "336"],
    "01/03/2023": ["169", "67", "179"],
    "01/04/2023": ["490", "38", "378"],
    "01/05/2023": ["147", "23", "355"],
    "01/06/2023": ["137", "17", "890"],
    "01/07/2023": ["***", "**", "***"],
    "01/08/2023": ["***", "**", "***"],
  },
  "01/09/2023 to 01/15/2023": {
    "01/09/2023": ["689", "34", "158"],
    "01/10/2023": ["177", "58", "468"],
    "01/11/2023": ["150", "60", "127"],
    "01/12/2023": ["145", "06", "457"],
    "01/13/2023": ["799", "56", "349"],
    "01/14/2023": ["***", "**", "***"],
    "01/15/2023": ["***", "**", "***"],
  },
  "01/16/2023 to 01/22/2023": {
    "01/16/2023": ["117", "97", "557"],
    "01/17/2023": ["179", "72", "228"],
    "01/18/2023": ["360", "98", "134"],
    "01/19/2023": ["470", "18", "224"],
    "01/20/2023": ["179", "75", "339"],
    "01/21/2023": ["***", "**", "***"],
    "01/22/2023": ["***", "**", "***"],
  },
  "01/23/2023 to 01/29/2023": {
    "01/23/2023": ["125", "89", "289"],
    "01/24/2023": ["133", "76", "349"],
    "01/25/2023": ["255", "21", "560"],
    "01/26/2023": ["***", "**", "***"],
    "01/27/2023": ["167", "40", "370"],
    "01/28/2023": ["***", "**", "***"],
    "01/29/2023": ["***", "**", "***"],
  },
  "01/30/2023 to 02/05/2023": {
    "01/30/2023": ["129", "24", "770"],
    "01/31/2023": ["566", "73", "580"],
    "02/01/2023": ["239", "40", "280"],
    "02/02/2023": ["340", "78", "170"],
    "02/03/2023": ["138", "23", "148"],
    "02/04/2023": ["***", "**", "***"],
    "02/05/2023": ["***", "**", "***"],
  },
  "02/06/2023 to 02/12/2023": {
    "02/06/2023": ["458", "76", "790"],
    "02/07/2023": ["590", "46", "600"],
    "02/08/2023": ["288", "87", "890"],
    "02/09/2023": ["155", "15", "159"],
    "02/10/2023": ["138", "28", "134"],
    "02/11/2023": ["***", "**", "***"],
    "02/12/2023": ["***", "**", "***"],
  },
  "02/13/2023 to 02/19/2023": {
    "02/13/2023": ["258", "53", "148"],
    "02/14/2023": ["345", "21", "119"],
    "02/15/2023": ["136", "08", "116"],
    "02/16/2023": ["688", "21", "245"],
    "02/17/2023": ["790", "69", "289"],
    "02/18/2023": ["***", "**", "***"],
    "02/19/2023": ["***", "**", "***"],
  },
  "02/20/2023 to 02/26/2023": {
    "02/20/2023": ["458", "70", "370"],
    "02/21/2023": ["189", "80", "226"],
    "02/22/2023": ["125", "84", "680"],
    "02/23/2023": ["138", "21", "489"],
    "02/24/2023": ["800", "82", "480"],
    "02/25/2023": ["***", "**", "***"],
    "02/26/2023": ["***", "**", "***"],
  },
  "02/27/2023 to 03/05/2023": {
    "02/27/2023": ["146", "18", "189"],
    "02/28/2023": ["569", "01", "579"],
    "03/01/2023": ["147", "23", "229"],
    "03/02/2023": ["279", "82", "147"],
    "03/03/2023": ["126", "93", "247"],
    "03/04/2023": ["***", "**", "***"],
    "03/05/2023": ["***", "**", "***"],
  },
  "03/06/2023 to 03/12/2023": {
    "03/06/2023": ["246", "23", "256"],
    "03/07/2023": ["550", "09", "379"],
    "03/08/2023": ["***", "**", "***"],
    "03/09/2023": ["566", "78", "468"],
    "03/10/2023": ["456", "53", "148"],
    "03/11/2023": ["***", "**", "***"],
    "03/12/2023": ["***", "**", "***"],
  },
  "03/13/2023 to 03/19/2023": {
    "03/13/2023": ["289", "98", "233"],
    "03/14/2023": ["160", "74", "590"],
    "03/15/2023": ["256", "37", "133"],
    "03/16/2023": ["348", "56", "358"],
    "03/17/2023": ["115", "70", "479"],
    "03/18/2023": ["***", "**", "***"],
    "03/19/2023": ["***", "**", "***"],
  },
  "03/20/2023 to 03/26/2023": {
    "03/20/2023": ["146", "19", "270"],
    "03/21/2023": ["340", "74", "149"],
    "03/22/2023": ["468", "85", "177"],
    "03/23/2023": ["389", "03", "490"],
    "03/24/2023": ["130", "42", "129"],
    "03/25/2023": ["***", "**", "***"],
    "03/26/2023": ["***", "**", "***"],
  },
  "03/27/2023 to 04/02/2023": {
    "03/27/2023": ["578", "04", "266"],
    "03/28/2023": ["490", "34", "680"],
    "03/29/2023": ["170", "87", "566"],
    "03/30/2023": ["127", "03", "139"],
    "03/31/2023": ["136", "05", "690"],
    "04/01/2023": ["***", "**", "***"],
    "04/02/2023": ["***", "**", "***"],
  },
  "04/03/2023 to 04/09/2023": {
    "04/03/2023": ["900", "94", "266"],
    "04/04/2023": ["459", "85", "447"],
    "04/05/2023": ["677", "09", "379"],
    "04/06/2023": ["270", "94", "130"],
    "04/07/2023": ["780", "59", "289"],
    "04/08/2023": ["***", "**", "***"],
    "04/09/2023": ["***", "**", "***"],
  },
  "04/10/2023 to 04/16/2023": {
    "04/10/2023": ["250", "77", "269"],
    "04/11/2023": ["389", "04", "149"],
    "04/12/2023": ["223", "72", "688"],
    "04/13/2023": ["469", "92", "499"],
    "04/14/2023": ["457", "65", "780"],
    "04/15/2023": ["***", "**", "***"],
    "04/16/2023": ["***", "**", "***"],
  },
  "04/17/2023 to 04/23/2023": {
    "04/17/2023": ["680", "44", "257"],
    "04/18/2023": ["335", "19", "360"],
    "04/19/2023": ["379", "95", "267"],
    "04/20/2023": ["358", "68", "567"],
    "04/21/2023": ["249", "51", "290"],
    "04/22/2023": ["***", "**", "***"],
    "04/23/2023": ["***", "**", "***"],
  },
  "04/24/2023 to 04/30/2023": {
    "04/24/2023": ["789", "42", "679"],
    "04/25/2023": ["228", "22", "147"],
    "04/26/2023": ["245", "12", "228"],
    "04/27/2023": ["189", "83", "300"],
    "04/28/2023": ["358", "63", "157"],
    "04/29/2023": ["***", "**", "***"],
    "04/30/2023": ["***", "**", "***"],
  },
  "05/01/2023 to 05/07/2023": {
    "05/01/2023": ["339", "51", "678"],
    "05/02/2023": ["358", "67", "250"],
    "05/03/2023": ["567", "83", "120"],
    "05/04/2023": ["147", "25", "799"],
    "05/05/2023": ["129", "25", "159"],
    "05/06/2023": ["***", "**", "***"],
    "05/07/2023": ["***", "**", "***"],
  },
  "05/08/2023 to 05/14/2023": {
    "05/08/2023": ["677", "02", "110"],
    "05/09/2023": ["145", "00", "235"],
    "05/10/2023": ["340", "73", "139"],
    "05/11/2023": ["158", "46", "114"],
    "05/12/2023": ["245", "14", "239"],
    "05/13/2023": ["***", "**", "***"],
    "05/14/2023": ["***", "**", "***"],
  },
  "05/15/2023 to 05/21/2023": {
    "05/15/2023": ["288", "84", "789"],
    "05/16/2023": ["456", "59", "469"],
    "05/17/2023": ["678", "19", "234"],
    "05/18/2023": ["369", "86", "330"],
    "05/19/2023": ["110", "24", "149"],
    "05/20/2023": ["***", "**", "***"],
    "05/21/2023": ["***", "**", "***"],
  },
  "05/22/2023 to 05/28/2023": {
    "05/22/2023": ["145", "05", "780"],
    "05/23/2023": ["457", "67", "160"],
    "05/24/2023": ["290", "13", "256"],
    "05/25/2023": ["245", "17", "359"],
    "05/26/2023": ["345", "20", "389"],
    "05/27/2023": ["***", "**", "***"],
    "05/28/2023": ["***", "**", "***"],
  },
  "05/29/2023 to 06/04/2023": {
    "05/29/2023": ["224", "88", "459"],
    "05/30/2023": ["236", "10", "677"],
    "05/31/2023": ["355", "39", "469"],
    "06/01/2023": ["389", "00", "479"],
    "06/02/2023": ["556", "65", "357"],
    "06/03/2023": ["***", "**", "***"],
    "06/04/2023": ["***", "**", "***"],
  },
  "06/05/2023 to 06/11/2023": {
    "06/05/2023": ["379", "95", "230"],
    "06/06/2023": ["179", "77", "160"],
    "06/07/2023": ["589", "24", "167"],
    "06/08/2023": ["588", "18", "134"],
    "06/09/2023": ["560", "13", "247"],
    "06/10/2023": ["***", "**", "***"],
    "06/11/2023": ["***", "**", "***"],
  },
  "06/12/2023 to 06/18/2023": {
    "06/12/2023": ["246", "21", "489"],
    "06/13/2023": ["289", "93", "337"],
    "06/14/2023": ["123", "61", "245"],
    "06/15/2023": ["477", "83", "788"],
    "06/16/2023": ["150", "60", "190"],
    "06/17/2023": ["***", "**", "***"],
    "06/18/2023": ["***", "**", "***"],
  },
  "06/19/2023 to 06/25/2023": {
    "06/19/2023": ["688", "23", "490"],
    "06/20/2023": ["356", "40", "299"],
    "06/21/2023": ["690", "57", "467"],
    "06/22/2023": ["679", "26", "259"],
    "06/23/2023": ["220", "45", "348"],
    "06/24/2023": ["***", "**", "***"],
    "06/25/2023": ["***", "**", "***"],
  },
  "06/26/2023 to 07/02/2023": {
    "06/26/2023": ["367", "61", "678"],
    "06/27/2023": ["150", "67", "160"],
    "06/28/2023": ["557", "78", "134"],
    "06/29/2023": ["155", "11", "245"],
    "06/30/2023": ["689", "33", "689"],
    "07/01/2023": ["***", "**", "***"],
    "07/02/2023": ["***", "**", "***"],
  },
  "07/03/2023 to 07/09/2023": {
    "07/03/2023": ["569", "09", "379"],
    "07/04/2023": ["560", "11", "128"],
    "07/05/2023": ["890", "72", "246"],
    "07/06/2023": ["579", "14", "167"],
    "07/07/2023": ["660", "20", "127"],
    "07/08/2023": ["***", "**", "***"],
    "07/09/2023": ["***", "**", "***"],
  },
  "07/10/2023 to 07/16/2023": {
    "07/10/2023": ["346", "39", "568"],
    "07/11/2023": ["245", "13", "148"],
    "07/12/2023": ["110", "27", "133"],
    "07/13/2023": ["190", "07", "377"],
    "07/14/2023": ["188", "78", "260"],
    "07/15/2023": ["***", "**", "***"],
    "07/16/2023": ["***", "**", "***"],
  },
  "07/17/2023 to 07/23/2023": {
    "07/17/2023": ["256", "37", "269"],
    "07/18/2023": ["346", "39", "667"],
    "07/19/2023": ["670", "30", "460"],
    "07/20/2023": ["478", "93", "120"],
    "07/21/2023": ["577", "91", "489"],
    "07/22/2023": ["***", "**", "***"],
    "07/23/2023": ["***", "**", "***"],
  },
  "07/24/2023 to 07/30/2023": {
    "07/24/2023": ["240", "68", "800"],
    "07/25/2023": ["468", "89", "568"],
    "07/26/2023": ["366", "57", "467"],
    "07/27/2023": ["267", "56", "178"],
    "07/28/2023": ["568", "99", "144"],
    "07/29/2023": ["***", "**", "***"],
    "07/30/2023": ["***", "**", "***"],
  },
  "07/31/2023 to 08/06/2023": {
    "07/31/2023": ["356", "49", "135"],
    "08/01/2023": ["279", "81", "245"],
    "08/02/2023": ["670", "33", "157"],
    "08/03/2023": ["160", "71", "290"],
    "08/04/2023": ["450", "91", "128"],
    "08/05/2023": ["***", "**", "***"],
    "08/06/2023": ["***", "**", "***"],
  },
  "08/07/2023 to 08/13/2023": {
    "08/07/2023": ["144", "99", "135"],
    "08/08/2023": ["269", "76", "457"],
    "08/09/2023": ["188", "70", "136"],
    "08/10/2023": ["159", "55", "366"],
    "08/11/2023": ["133", "78", "567"],
    "08/12/2023": ["***", "**", "***"],
    "08/13/2023": ["***", "**", "***"],
  },
  "08/14/2023 to 08/20/2023": {
    "08/14/2023": ["235", "06", "790"],
    "08/15/2023": ["***", "**", "***"],
    "08/16/2023": ["140", "52", "147"],
    "08/17/2023": ["400", "44", "680"],
    "08/18/2023": ["488", "09", "577"],
    "08/19/2023": ["***", "**", "***"],
    "08/20/2023": ["***", "**", "***"],
  },
  "08/21/2023 to 08/27/2023": {
    "08/21/2023": ["357", "58", "288"],
    "08/22/2023": ["880", "65", "159"],
    "08/23/2023": ["690", "59", "270"],
    "08/24/2023": ["160", "78", "369"],
    "08/25/2023": ["468", "89", "568"],
    "08/26/2023": ["***", "**", "***"],
    "08/27/2023": ["***", "**", "***"],
  },
  "08/28/2023 to 09/03/2023": {
    "08/28/2023": ["366", "57", "467"],
    "08/29/2023": ["267", "56", "178"],
    "08/30/2023": ["349", "68", "189"],
    "08/31/2023": ["148", "36", "169"],
    "09/01/2023": ["299", "04", "248"],
    "09/02/2023": ["***", "**", "***"],
    "09/03/2023": ["***", "**", "***"],
  },
  "09/04/2023 to 09/10/2023": {
    "09/04/2023": ["400", "43", "256"],
    "09/05/2023": ["180", "99", "478"],
    "09/06/2023": ["134", "86", "556"],
    "09/07/2023": ["790", "67", "188"],
    "09/08/2023": ["900", "99", "469"],
    "09/09/2023": ["***", "**", "***"],
    "09/10/2023": ["***", "**", "***"],
  },
  "09/11/2023 to 09/17/2023": {
    "09/11/2023": ["566", "72", "138"],
    "09/12/2023": ["114", "61", "579"],
    "09/13/2023": ["450", "94", "149"],
    "09/14/2023": ["469", "99", "126"],
    "09/15/2023": ["145", "01", "579"],
    "09/16/2023": ["***", "**", "***"],
    "09/17/2023": ["***", "**", "***"],
  },
  "09/18/2023 to 09/24/2023": {
    "09/18/2023": ["289", "98", "990"],
    "09/19/2023": ["128", "16", "448"],
    "09/20/2023": ["468", "87", "269"],
    "09/21/2023": ["236", "12", "156"],
    "09/22/2023": ["225", "96", "457"],
    "09/23/2023": ["***", "**", "***"],
    "09/24/2023": ["***", "**", "***"],
  },
  "09/25/2023 to 10/01/2023": {
    "09/25/2023": ["129", "20", "578"],
    "09/26/2023": ["114", "63", "238"],
    "09/27/2023": ["180", "99", "568"],
    "09/28/2023": ["338", "45", "780"],
    "09/29/2023": ["279", "81", "245"],
    "09/30/2023": ["***", "**", "***"],
    "10/01/2023": ["***", "**", "***"],
  },
  "10/02/2023 to 10/08/2023": {
    "10/02/2023": ["559", "94", "112"],
    "10/03/2023": ["340", "73", "689"],
    "10/04/2023": ["158", "47", "223"],
    "10/05/2023": ["258", "53", "247"],
    "10/06/2023": ["357", "53", "599"],
    "10/07/2023": ["***", "**", "***"],
    "10/08/2023": ["***", "**", "***"],
  },
  "10/09/2023 to 10/15/2023": {
    "10/09/2023": ["144", "91", "678"],
    "10/10/2023": ["370", "04", "158"],
    "10/11/2023": ["400", "41", "137"],
    "10/12/2023": ["390", "27", "467"],
    "10/13/2023": ["259", "65", "447"],
    "10/14/2023": ["***", "**", "***"],
    "10/15/2023": ["***", "**", "***"],
  },
  "10/16/2023 to 10/22/2023": {
    "10/16/2023": ["445", "37", "368"],
    "10/17/2023": ["190", "02", "246"],
    "10/18/2023": ["126", "91", "560"],
    "10/19/2023": ["440", "89", "270"],
    "10/20/2023": ["130", "49", "180"],
    "10/21/2023": ["***", "**", "***"],
    "10/22/2023": ["***", "**", "***"],
  },
  "10/23/2023 to 10/29/2023": {
    "10/23/2023": ["445", "33", "247"],
    "10/24/2023": ["130", "40", "190"],
    "10/25/2023": ["880", "61", "470"],
    "10/26/2023": ["399", "12", "138"],
    "10/27/2023": ["168", "53", "238"],
    "10/28/2023": ["***", "**", "***"],
    "10/29/2023": ["***", "**", "***"],
  },
  "10/30/2023 to 11/05/2023": {
    "10/30/2023": ["146", "16", "556"],
    "10/31/2023": ["145", "02", "589"],
    "11/01/2023": ["170", "84", "158"],
    "11/02/2023": ["235", "01", "470"],
    "11/03/2023": ["480", "20", "136"],
    "11/04/2023": ["***", "**", "***"],
    "11/05/2023": ["***", "**", "***"],
  },
  "11/06/2023 to 11/12/2023": {
    "11/06/2023": ["779", "38", "350"],
    "11/07/2023": ["256", "34", "590"],
    "11/08/2023": ["347", "42", "237"],
    "11/09/2023": ["447", "56", "150"],
    "11/10/2023": ["***", "**", "***"],
    "11/11/2023": ["***", "**", "***"],
    "11/12/2023": ["***", "**", "***"],
  },
  "11/13/2023 to 11/19/2023": {
    "11/13/2023": ["***", "**", "***"],
    "11/14/2023": ["***", "**", "***"],
    "11/15/2023": ["***", "**", "***"],
    "11/16/2023": ["***", "**", "***"],
    "11/17/2023": ["***", "**", "***"],
    "11/18/2023": ["***", "**", "***"],
    "11/19/2023": ["***", "**", "***"],
  },
  "11/20/2023 to 11/26/2023": {
    "11/20/2023": ["258", "54", "130"],
    "11/21/2023": ["135", "93", "148"],
    "11/22/2023": ["589", "29", "478"],
    "11/23/2023": ["780", "51", "335"],
    "11/24/2023": ["136", "08", "468"],
    "11/25/2023": ["***", "**", "***"],
    "11/26/2023": ["***", "**", "***"],
  },
  "11/27/2023 to 12/03/2023": {
    "11/27/2023": ["345", "24", "149"],
    "11/28/2023": ["158", "49", "289"],
    "11/29/2023": ["690", "54", "167"],
    "11/30/2023": ["126", "99", "379"],
    "12/01/2023": ["366", "55", "168"],
    "12/02/2023": ["***", "**", "***"],
    "12/03/2023": ["***", "**", "***"],
  },
  "12/04/2023 to 12/10/2023": {
    "12/04/2023": ["458", "74", "400"],
    "12/05/2023": ["570", "26", "600"],
    "12/06/2023": ["248", "47", "179"],
    "12/07/2023": ["570", "24", "257"],
    "12/08/2023": ["247", "37", "340"],
    "12/09/2023": ["***", "**", "***"],
    "12/10/2023": ["***", "**", "***"],
  },
  "12/11/2023 to 12/17/2023": {
    "12/11/2023": ["478", "94", "347"],
    "12/12/2023": ["288", "83", "445"],
    "12/13/2023": ["238", "34", "347"],
    "12/14/2023": ["350", "87", "278"],
    "12/15/2023": ["156", "28", "477"],
    "12/16/2023": ["***", "**", "***"],
    "12/17/2023": ["***", "**", "***"],
  },
  "12/18/2023 to 12/24/2023": {
    "12/18/2023": ["144", "94", "680"],
    "12/19/2023": ["167", "47", "377"],
    "12/20/2023": ["689", "30", "145"],
    "12/21/2023": ["678", "15", "500"],
    "12/22/2023": ["247", "38", "189"],
    "12/23/2023": ["***", "**", "***"],
    "12/24/2023": ["***", "**", "***"],
  },
  "12/25/2023 to 12/31/2023": {
    "12/25/2023": ["445", "37", "179"],
    "12/26/2023": ["129", "28", "170"],
    "12/27/2023": ["136", "09", "234"],
    "12/28/2023": ["689", "30", "389"],
    "12/29/2023": ["699", "42", "200"],
    "12/30/2023": ["***", "**", "***"],
    "12/31/2023": ["***", "**", "***"],
  },
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["138", "22", "499"],
    "01/02/2024": ["990", "86", "169"],
    "01/03/2024": ["170", "85", "357"],
    "01/04/2024": ["236", "18", "189"],
    "01/05/2024": ["589", "22", "660"],
    "01/06/2024": ["***", "**", "***"],
    "01/07/2024": ["***", "**", "***"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["699", "47", "368"],
    "01/09/2024": ["167", "41", "678"],
    "01/10/2024": ["399", "17", "458"],
    "01/11/2024": ["280", "09", "135"],
    "01/12/2024": ["255", "25", "500"],
    "01/13/2024": ["***", "**", "***"],
    "01/14/2024": ["***", "**", "***"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["234", "90", "235"],
    "01/16/2024": ["380", "12", "228"],
    "01/17/2024": ["235", "01", "344"],
    "01/18/2024": ["570", "21", "678"],
    "01/19/2024": ["348", "52", "679"],
    "01/20/2024": ["***", "**", "***"],
    "01/21/2024": ["***", "**", "***"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["120", "33", "256"],
    "01/23/2024": ["178", "64", "789"],
    "01/24/2024": ["166", "31", "579"],
    "01/25/2024": ["150", "68", "170"],
    "01/26/2024": ["***", "**", "***"],
    "01/27/2024": ["***", "**", "***"],
    "01/28/2024": ["***", "**", "***"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["779", "38", "170"],
    "01/30/2024": ["117", "95", "230"],
    "01/31/2024": ["589", "24", "789"],
    "02/01/2024": ["270", "90", "479"],
    "02/02/2024": ["259", "60", "299"],
    "02/03/2024": ["***", "**", "***"],
    "02/04/2024": ["***", "**", "***"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["340", "77", "359"],
    "02/06/2024": ["660", "22", "679"],
    "02/07/2024": ["567", "83", "670"],
    "02/08/2024": ["480", "28", "260"],
    "02/09/2024": ["359", "72", "129"],
    "02/10/2024": ["***", "**", "***"],
    "02/11/2024": ["***", "**", "***"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["389", "03", "580"],
    "02/13/2024": ["556", "60", "677"],
    "02/14/2024": ["120", "36", "880"],
    "02/15/2024": ["370", "07", "449"],
    "02/16/2024": ["347", "45", "357"],
    "02/17/2024": ["***", "**", "***"],
    "02/18/2024": ["***", "**", "***"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["123", "60", "460"],
    "02/20/2024": ["579", "11", "669"],
    "02/21/2024": ["349", "60", "226"],
    "02/22/2024": ["126", "91", "155"],
    "02/23/2024": ["179", "77", "467"],
    "02/24/2024": ["***", "**", "***"],
    "02/25/2024": ["***", "**", "***"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["346", "38", "459"],
    "02/27/2024": ["279", "84", "590"],
    "02/28/2024": ["490", "36", "367"],
    "02/29/2024": ["368", "79", "144"],
    "03/01/2024": ["469", "91", "137"],
    "03/02/2024": ["***", "**", "***"],
    "03/03/2024": ["***", "**", "***"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["458", "79", "270"],
    "03/05/2024": ["570", "23", "247"],
    "03/06/2024": ["800", "86", "600"],
    "03/07/2024": ["278", "74", "338"],
    "03/08/2024": ["170", "86", "556"],
    "03/09/2024": ["***", "**", "***"],
    "03/10/2024": ["***", "**", "***"],
  },
  "09/18/2023 to 09/24/2023": {
    "09/18/2023": ["289", "98", "990"],
    "09/19/2023": ["128", "16", "448"],
    "09/20/2023": ["468", "87", "269"],
    "09/21/2023": ["236", "12", "156"],
    "09/22/2023": ["225", "96", "457"],
    "09/23/2023": ["***", "**", "***"],
    "09/24/2023": ["***", "**", "***"],
  },
  "09/25/2023 to 10/01/2023": {
    "09/25/2023": ["129", "20", "578"],
    "09/26/2023": ["114", "63", "238"],
    "09/27/2023": ["180", "99", "568"],
    "09/28/2023": ["338", "45", "780"],
    "09/29/2023": ["279", "81", "245"],
    "09/30/2023": ["***", "**", "***"],
    "10/01/2023": ["***", "**", "***"],
  },
  "10/02/2023 to 10/08/2023": {
    "10/02/2023": ["559", "94", "112"],
    "10/03/2023": ["340", "73", "689"],
    "10/04/2023": ["158", "47", "223"],
    "10/05/2023": ["258", "53", "247"],
    "10/06/2023": ["357", "53", "599"],
    "10/07/2023": ["***", "**", "***"],
    "10/08/2023": ["***", "**", "***"],
  },
  "10/09/2023 to 10/15/2023": {
    "10/09/2023": ["144", "91", "678"],
    "10/10/2023": ["370", "04", "158"],
    "10/11/2023": ["400", "41", "137"],
    "10/12/2023": ["390", "27", "467"],
    "10/13/2023": ["259", "65", "447"],
    "10/14/2023": ["***", "**", "***"],
    "10/15/2023": ["***", "**", "***"],
  },
  "10/16/2023 to 10/22/2023": {
    "10/16/2023": ["445", "37", "368"],
    "10/17/2023": ["190", "02", "246"],
    "10/18/2023": ["126", "91", "560"],
    "10/19/2023": ["440", "89", "270"],
    "10/20/2023": ["130", "49", "180"],
    "10/21/2023": ["***", "**", "***"],
    "10/22/2023": ["***", "**", "***"],
  },
  "10/23/2023 to 10/29/2023": {
    "10/23/2023": ["445", "33", "247"],
    "10/24/2023": ["130", "40", "190"],
    "10/25/2023": ["880", "61", "470"],
    "10/26/2023": ["399", "12", "138"],
    "10/27/2023": ["168", "53", "238"],
    "10/28/2023": ["***", "**", "***"],
    "10/29/2023": ["***", "**", "***"],
  },
  "10/30/2023 to 11/05/2023": {
    "10/30/2023": ["146", "16", "556"],
    "10/31/2023": ["145", "02", "589"],
    "11/01/2023": ["170", "84", "158"],
    "11/02/2023": ["235", "01", "470"],
    "11/03/2023": ["480", "20", "136"],
    "11/04/2023": ["***", "**", "***"],
    "11/05/2023": ["***", "**", "***"],
  },
  "11/06/2023 to 11/12/2023": {
    "11/06/2023": ["779", "38", "350"],
    "11/07/2023": ["256", "34", "590"],
    "11/08/2023": ["347", "42", "237"],
    "11/09/2023": ["447", "56", "150"],
    "11/10/2023": ["***", "**", "***"],
    "11/11/2023": ["***", "**", "***"],
    "11/12/2023": ["***", "**", "***"],
  },
  "11/13/2023 to 11/19/2023": {
    "11/13/2023": ["***", "**", "***"],
    "11/14/2023": ["***", "**", "***"],
    "11/15/2023": ["***", "**", "***"],
    "11/16/2023": ["***", "**", "***"],
    "11/17/2023": ["***", "**", "***"],
    "11/18/2023": ["***", "**", "***"],
    "11/19/2023": ["***", "**", "***"],
  },
  "11/20/2023 to 11/26/2023": {
    "11/20/2023": ["258", "54", "130"],
    "11/21/2023": ["135", "93", "148"],
    "11/22/2023": ["589", "29", "478"],
    "11/23/2023": ["780", "51", "335"],
    "11/24/2023": ["136", "08", "468"],
    "11/25/2023": ["***", "**", "***"],
    "11/26/2023": ["***", "**", "***"],
  },
  "11/27/2023 to 12/03/2023": {
    "11/27/2023": ["345", "24", "149"],
    "11/28/2023": ["158", "49", "289"],
    "11/29/2023": ["690", "54", "167"],
    "11/30/2023": ["126", "99", "379"],
    "12/01/2023": ["366", "55", "168"],
    "12/02/2023": ["***", "**", "***"],
    "12/03/2023": ["***", "**", "***"],
  },
  "12/04/2023 to 12/10/2023": {
    "12/04/2023": ["458", "74", "400"],
    "12/05/2023": ["570", "26", "600"],
    "12/06/2023": ["248", "47", "179"],
    "12/07/2023": ["570", "24", "257"],
    "12/08/2023": ["247", "37", "340"],
    "12/09/2023": ["***", "**", "***"],
    "12/10/2023": ["***", "**", "***"],
  },
  "12/11/2023 to 12/17/2023": {
    "12/11/2023": ["478", "94", "347"],
    "12/12/2023": ["288", "83", "445"],
    "12/13/2023": ["238", "34", "347"],
    "12/14/2023": ["350", "87", "278"],
    "12/15/2023": ["156", "28", "477"],
    "12/16/2023": ["***", "**", "***"],
    "12/17/2023": ["***", "**", "***"],
  },
  "12/18/2023 to 12/24/2023": {
    "12/18/2023": ["144", "94", "680"],
    "12/19/2023": ["167", "47", "377"],
    "12/20/2023": ["689", "30", "145"],
    "12/21/2023": ["678", "15", "500"],
    "12/22/2023": ["247", "38", "189"],
    "12/23/2023": ["***", "**", "***"],
    "12/24/2023": ["***", "**", "***"],
  },
  "12/25/2023 to 12/31/2023": {
    "12/25/2023": ["445", "37", "179"],
    "12/26/2023": ["129", "28", "170"],
    "12/27/2023": ["136", "09", "234"],
    "12/28/2023": ["689", "30", "389"],
    "12/29/2023": ["699", "42", "200"],
    "12/30/2023": ["***", "**", "***"],
    "12/31/2023": ["***", "**", "***"],
  },
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["138", "22", "499"],
    "01/02/2024": ["990", "86", "169"],
    "01/03/2024": ["170", "85", "357"],
    "01/04/2024": ["236", "18", "189"],
    "01/05/2024": ["589", "22", "660"],
    "01/06/2024": ["***", "**", "***"],
    "01/07/2024": ["***", "**", "***"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["699", "47", "368"],
    "01/09/2024": ["167", "41", "678"],
    "01/10/2024": ["399", "17", "458"],
    "01/11/2024": ["280", "09", "135"],
    "01/12/2024": ["255", "25", "500"],
    "01/13/2024": ["***", "**", "***"],
    "01/14/2024": ["***", "**", "***"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["234", "90", "235"],
    "01/16/2024": ["380", "12", "228"],
    "01/17/2024": ["235", "01", "344"],
    "01/18/2024": ["570", "21", "678"],
    "01/19/2024": ["348", "52", "679"],
    "01/20/2024": ["***", "**", "***"],
    "01/21/2024": ["***", "**", "***"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["120", "33", "256"],
    "01/23/2024": ["178", "64", "789"],
    "01/24/2024": ["166", "31", "579"],
    "01/25/2024": ["150", "68", "170"],
    "01/26/2024": ["***", "**", "***"],
    "01/27/2024": ["***", "**", "***"],
    "01/28/2024": ["***", "**", "***"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["779", "38", "170"],
    "01/30/2024": ["117", "95", "230"],
    "01/31/2024": ["589", "24", "789"],
    "02/01/2024": ["270", "90", "479"],
    "02/02/2024": ["259", "60", "299"],
    "02/03/2024": ["***", "**", "***"],
    "02/04/2024": ["***", "**", "***"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["340", "77", "359"],
    "02/06/2024": ["660", "22", "679"],
    "02/07/2024": ["567", "83", "670"],
    "02/08/2024": ["480", "28", "260"],
    "02/09/2024": ["359", "72", "129"],
    "02/10/2024": ["***", "**", "***"],
    "02/11/2024": ["***", "**", "***"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["389", "03", "580"],
    "02/13/2024": ["556", "60", "677"],
    "02/14/2024": ["120", "36", "880"],
    "02/15/2024": ["370", "07", "449"],
    "02/16/2024": ["347", "45", "357"],
    "02/17/2024": ["***", "**", "***"],
    "02/18/2024": ["***", "**", "***"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["123", "60", "460"],
    "02/20/2024": ["579", "11", "669"],
    "02/21/2024": ["349", "60", "226"],
    "02/22/2024": ["126", "91", "155"],
    "02/23/2024": ["179", "77", "467"],
    "02/24/2024": ["***", "**", "***"],
    "02/25/2024": ["***", "**", "***"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["346", "38", "459"],
    "02/27/2024": ["279", "84", "590"],
    "02/28/2024": ["490", "36", "367"],
    "02/29/2024": ["368", "79", "144"],
    "03/01/2024": ["469", "91", "137"],
    "03/02/2024": ["***", "**", "***"],
    "03/03/2024": ["***", "**", "***"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["458", "79", "270"],
    "03/05/2024": ["570", "23", "247"],
    "03/06/2024": ["800", "86", "600"],
    "03/07/2024": ["278", "74", "338"],
    "03/08/2024": ["170", "86", "556"],
    "03/09/2024": ["***", "**", "***"],
    "03/10/2024": ["***", "**", "***"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["150", "68", "260"],
    "03/12/2024": ["147", "25", "690"],
    "03/13/2024": ["567", "85", "258"],
    "03/14/2024": ["890", "79", "450"],
    "03/15/2024": ["470", "17", "179"],
    "03/16/2024": ["***", "**", "***"],
    "03/17/2024": ["***", "**", "***"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["133", "76", "358"],
    "03/19/2024": ["470", "13", "490"],
    "03/20/2024": ["247", "34", "239"],
    "03/21/2024": ["267", "56", "169"],
    "03/22/2024": ["889", "51", "579"],
    "03/23/2024": ["***", "**", "***"],
    "03/24/2024": ["***", "**", "***"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["***", "**", "***"],
    "03/26/2024": ["128", "18", "116"],
    "03/27/2024": ["157", "31", "470"],
    "03/28/2024": ["170", "83", "120"],
    "03/29/2024": ["366", "55", "140"],
    "03/30/2024": ["***", "**", "***"],
    "03/31/2024": ["***", "**", "***"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["347", "48", "350"],
    "04/02/2024": ["236", "14", "400"],
    "04/03/2024": ["360", "91", "489"],
    "04/04/2024": ["223", "77", "458"],
    "04/05/2024": ["460", "09", "667"],
    "04/06/2024": ["***", "**", "***"],
    "04/07/2024": ["***", "**", "***"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["680", "43", "238"],
    "04/09/2024": ["167", "41", "380"],
    "04/10/2024": ["122", "54", "239"],
    "04/11/2024": ["136", "02", "345"],
    "04/12/2024": ["268", "63", "670"],
    "04/13/2024": ["***", "**", "***"],
    "04/14/2024": ["***", "**", "***"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["236", "16", "240"],
    "04/16/2024": ["338", "40", "280"],
    "04/17/2024": ["247", "32", "480"],
    "04/18/2024": ["170", "80", "145"],
    "04/19/2024": ["344", "13", "238"],
    "04/20/2024": ["***", "**", "***"],
    "04/21/2024": ["***", "**", "***"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["480", "28", "459"],
    "04/23/2024": ["290", "18", "189"],
    "04/24/2024": ["390", "29", "450"],
    "04/25/2024": ["446", "48", "279"],
    "04/26/2024": ["334", "05", "267"],
    "04/27/2024": ["***", "**", "***"],
    "04/28/2024": ["***", "**", "***"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["246", "21", "245"],
    "04/30/2024": ["248", "49", "360"],
    "05/01/2024": ["560", "14", "446"],
    "05/02/2024": ["140", "56", "466"],
    "05/03/2024": ["168", "52", "156"],
    "05/04/2024": ["***", "**", "***"],
    "05/05/2024": ["***", "**", "***"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["370", "05", "230"],
    "05/07/2024": ["149", "46", "178"],
    "05/08/2024": ["577", "96", "349"],
    "05/09/2024": ["245", "18", "134"],
    "05/10/2024": ["169", "64", "266"],
    "05/11/2024": ["***", "**", "***"],
    "05/12/2024": ["***", "**", "***"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["130", "42", "570"],
    "05/14/2024": ["177", "50", "127"],
    "05/15/2024": ["125", "80", "190"],
    "05/16/2024": ["389", "08", "558"],
    "05/17/2024": ["147", "20", "280"],
    "05/18/2024": ["***", "**", "***"],
    "05/19/2024": ["***", "**", "***"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["789", "40", "136"],
    "05/21/2024": ["499", "21", "489"],
    "05/22/2024": ["480", "25", "140"],
    "05/23/2024": ["679", "27", "278"],
    "05/24/2024": ["466", "63", "247"],
    "05/25/2024": ["***", "**", "***"],
    "05/26/2024": ["***", "**", "***"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["235", "08", "125"],
    "05/28/2024": ["267", "51", "579"],
    "05/29/2024": ["134", "87", "278"],
    "05/30/2024": ["122", "57", "467"],
    "05/31/2024": ["459", "81", "227"],
    "06/01/2024": ["***", "**", "***"],
    "06/02/2024": ["***", "**", "***"],
  },

  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["179", "72", "138"],
    "06/04/2024": ["390", "20", "370"],
    "06/05/2024": ["780", "53", "670"],
    "06/06/2024": ["455", "41", "137"],
    "06/07/2024": ["168", "55", "267"],
    "06/08/2024": ["***", "**", "***"],
    "06/09/2024": ["***", "**", "***"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["688", "25", "249"],
    "06/25/2024": ["900", "93", "580"],
    "06/26/2024": ["366", "57", "250"],
    "06/27/2024": ["579", "13", "300"],
    "06/28/2024": ["480", "23", "670"],
    "06/29/2024": ["***", "**", "***"],
    "06/30/2024": ["***", "**", "***"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["256", "37", "188"],
    "07/02/2024": ["240", "67", "890"],
    "07/03/2024": ["290", "11", "678"],
    "07/04/2024": ["457", "61", "579"],
    "07/05/2024": ["359", "78", "369"],
    "07/06/2024": ["***", "**", "***"],
    "07/07/2024": ["***", "**", "***"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["470", "12", "228"],
    "07/09/2024": ["568", "90", "460"],
    "07/10/2024": ["467", "77", "890"],
    "07/11/2024": ["778", "23", "490"],
    "07/12/2024": ["247", "33", "256"],
    "07/13/2024": ["***", "**", "***"],
    "07/14/2024": ["***", "**", "***"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["160", "77", "278"],
    "07/16/2024": ["445", "34", "158"],
    "07/17/2024": ["590", "49", "478"],
    "07/18/2024": ["480", "27", "340"],
    "07/19/2024": ["588", "17", "890"],
    "07/20/2024": ["***", "**", "***"],
    "07/21/2024": ["***", "**", "***"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["134", "81", "579"],
    "07/23/2024": ["279", "02", "138"],
    "07/24/2024": ["690", "51", "489"],
    "07/25/2024": ["400", "45", "140"],
    "07/26/2024": ["379", "98", "369"],
    "07/27/2024": ["***", "**", "***"],
    "07/28/2024": ["***", "**", "***"],
  },
};

export const MAINBAZAR = {
  "01/02/2023 to 01/08/2023": {
    "01/02/2023": ["144", "93", "238"],
    "01/03/2023": ["239", "49", "577"],
    "01/04/2023": ["489", "15", "258"],
    "01/05/2023": ["457", "62", "345"],
    "01/06/2023": ["233", "88", "134"],
    "01/07/2023": ["***", "**", "***"],
    "01/08/2023": ["***", "**", "***"],
  },
  "01/09/2023 to 01/15/2023": {
    "01/09/2023": ["890", "74", "266"],
    "01/10/2023": ["139", "31", "146"],
    "01/11/2023": ["258", "56", "259"],
    "01/12/2023": ["339", "53", "490"],
    "01/13/2023": ["490", "31", "380"],
    "01/14/2023": ["***", "**", "***"],
    "01/15/2023": ["***", "**", "***"],
  },
  "01/16/2023 to 01/22/2023": {
    "01/16/2023": ["788", "31", "489"],
    "01/17/2023": ["249", "52", "336"],
    "01/18/2023": ["688", "24", "590"],
    "01/19/2023": ["367", "60", "299"],
    "01/20/2023": ["800", "84", "257"],
    "01/21/2023": ["***", "**", "***"],
    "01/22/2023": ["***", "**", "***"],
  },
  "01/23/2023 to 01/29/2023": {
    "01/23/2023": ["245", "18", "800"],
    "01/24/2023": ["150", "64", "680"],
    "01/25/2023": ["367", "63", "355"],
    "01/26/2023": ["***", "**", "***"],
    "01/27/2023": ["248", "40", "668"],
    "01/28/2023": ["***", "**", "***"],
    "01/29/2023": ["***", "**", "***"],
  },
  "01/30/2023 to 02/05/2023": {
    "01/30/2023": ["137", "10", "460"],
    "01/31/2023": ["348", "53", "300"],
    "02/01/2023": ["125", "89", "379"],
    "02/02/2023": ["189", "85", "140"],
    "02/03/2023": ["113", "52", "237"],
    "02/04/2023": ["***", "**", "***"],
    "02/05/2023": ["***", "**", "***"],
  },
  "02/06/2023 to 02/12/2023": {
    "02/06/2023": ["377", "76", "367"],
    "02/07/2023": ["780", "53", "238"],
    "02/08/2023": ["270", "92", "237"],
    "02/09/2023": ["257", "48", "990"],
    "02/10/2023": ["470", "19", "478"],
    "02/11/2023": ["***", "**", "***"],
    "02/12/2023": ["***", "**", "***"],
  },
  "02/13/2023 to 02/19/2023": {
    "02/13/2023": ["245", "19", "379"],
    "02/14/2023": ["490", "36", "448"],
    "02/15/2023": ["234", "97", "359"],
    "02/16/2023": ["560", "12", "345"],
    "02/17/2023": ["229", "36", "466"],
    "02/18/2023": ["***", "**", "***"],
    "02/19/2023": ["***", "**", "***"],
  },
  "02/20/2023 to 02/26/2023": {
    "02/20/2023": ["456", "57", "160"],
    "02/21/2023": ["449", "79", "126"],
    "02/22/2023": ["890", "76", "790"],
    "02/23/2023": ["126", "96", "240"],
    "02/24/2023": ["248", "44", "248"],
    "02/25/2023": ["***", "**", "***"],
    "02/26/2023": ["***", "**", "***"],
  },
  "02/27/2023 to 03/05/2023": {
    "02/27/2023": ["278", "75", "780"],
    "02/28/2023": ["134", "84", "248"],
    "03/01/2023": ["590", "45", "267"],
    "03/02/2023": ["170", "88", "170"],
    "03/03/2023": ["680", "43", "346"],
    "03/04/2023": ["***", "**", "***"],
    "03/05/2023": ["***", "**", "***"],
  },
  "03/06/2023 to 03/12/2023": {
    "03/06/2023": ["247", "32", "390"],
    "03/07/2023": ["189", "88", "233"],
    "03/08/2023": ["***", "**", "***"],
    "03/09/2023": ["140", "51", "579"],
    "03/10/2023": ["268", "65", "339"],
    "03/11/2023": ["***", "**", "***"],
    "03/12/2023": ["***", "**", "***"],
  },
  "03/13/2023 to 03/19/2023": {
    "03/13/2023": ["369", "89", "225"],
    "03/14/2023": ["234", "92", "679"],
    "03/15/2023": ["249", "58", "134"],
    "03/16/2023": ["168", "57", "359"],
    "03/17/2023": ["399", "13", "337"],
    "03/18/2023": ["***", "**", "***"],
    "03/19/2023": ["***", "**", "***"],
  },
  "03/20/2023 to 03/26/2023": {
    "03/20/2023": ["157", "32", "110"],
    "03/21/2023": ["179", "71", "236"],
    "03/22/2023": ["134", "88", "125"],
    "03/23/2023": ["458", "76", "457"],
    "03/24/2023": ["369", "81", "128"],
    "03/25/2023": ["***", "**", "***"],
    "03/26/2023": ["***", "**", "***"],
  },
  "03/27/2023 to 04/02/2023": {
    "03/27/2023": ["680", "44", "699"],
    "03/28/2023": ["458", "71", "678"],
    "03/29/2023": ["114", "60", "127"],
    "03/30/2023": ["289", "93", "355"],
    "03/31/2023": ["490", "30", "460"],
    "04/01/2023": ["***", "**", "***"],
    "04/02/2023": ["***", "**", "***"],
  },
  "04/03/2023 to 04/09/2023": {
    "04/03/2023": ["260", "88", "567"],
    "04/04/2023": ["377", "72", "390"],
    "04/05/2023": ["456", "57", "368"],
    "04/06/2023": ["338", "40", "334"],
    "04/07/2023": ["144", "90", "668"],
    "04/08/2023": ["***", "**", "***"],
    "04/09/2023": ["***", "**", "***"],
  },
  "04/10/2023 to 04/16/2023": {
    "04/10/2023": ["128", "13", "256"],
    "04/11/2023": ["335", "10", "479"],
    "04/12/2023": ["289", "95", "230"],
    "04/13/2023": ["478", "90", "479"],
    "04/14/2023": ["577", "95", "249"],
    "04/15/2023": ["***", "**", "***"],
    "04/16/2023": ["***", "**", "***"],
  },
  "04/17/2023 to 04/23/2023": {
    "04/17/2023": ["255", "28", "350"],
    "04/18/2023": ["246", "28", "134"],
    "04/19/2023": ["380", "11", "236"],
    "04/20/2023": ["799", "56", "880"],
    "04/21/2023": ["129", "27", "458"],
    "04/22/2023": ["***", "**", "***"],
    "04/23/2023": ["***", "**", "***"],
  },
  "04/24/2023 to 04/30/2023": {
    "04/24/2023": ["100", "12", "390"],
    "04/25/2023": ["160", "72", "336"],
    "04/26/2023": ["138", "26", "150"],
    "04/27/2023": ["278", "77", "160"],
    "04/28/2023": ["469", "93", "300"],
    "04/29/2023": ["***", "**", "***"],
    "04/30/2023": ["***", "**", "***"],
  },
  "05/01/2023 to 05/07/2023": {
    "05/01/2023": ["249", "58", "378"],
    "05/02/2023": ["279", "87", "340"],
    "05/03/2023": ["570", "24", "130"],
    "05/04/2023": ["159", "56", "600"],
    "05/05/2023": ["679", "26", "349"],
    "05/06/2023": ["***", "**", "***"],
    "05/07/2023": ["***", "**", "***"],
  },
  "05/08/2023 to 05/14/2023": {
    "05/08/2023": ["178", "64", "699"],
    "05/09/2023": ["238", "34", "158"],
    "05/10/2023": ["459", "87", "179"],
    "05/11/2023": ["336", "24", "455"],
    "05/12/2023": ["134", "80", "460"],
    "05/13/2023": ["***", "**", "***"],
    "05/14/2023": ["***", "**", "***"],
  },
  "05/15/2023 to 05/21/2023": {
    "05/15/2023": ["149", "47", "340"],
    "05/16/2023": ["236", "15", "230"],
    "05/17/2023": ["126", "93", "148"],
    "05/18/2023": ["134", "82", "660"],
    "05/19/2023": ["178", "64", "239"],
    "05/20/2023": ["***", "**", "***"],
    "05/21/2023": ["***", "**", "***"],
  },
  "05/22/2023 to 05/28/2023": {
    "05/22/2023": ["169", "62", "110"],
    "05/23/2023": ["170", "85", "690"],
    "05/24/2023": ["166", "37", "124"],
    "05/25/2023": ["790", "61", "399"],
    "05/26/2023": ["678", "13", "238"],
    "05/27/2023": ["***", "**", "***"],
    "05/28/2023": ["***", "**", "***"],
  },
  "05/29/2023 to 06/04/2023": {
    "05/29/2023": ["270", "95", "780"],
    "05/30/2023": ["290", "14", "789"],
    "05/31/2023": ["460", "03", "247"],
    "06/01/2023": ["180", "98", "116"],
    "06/02/2023": ["688", "20", "569"],
    "06/03/2023": ["***", "**", "***"],
    "06/04/2023": ["***", "**", "***"],
  },
  "06/05/2023 to 06/11/2023": {
    "06/05/2023": ["489", "10", "488"],
    "06/06/2023": ["278", "77", "467"],
    "06/07/2023": ["460", "05", "799"],
    "06/08/2023": ["150", "69", "126"],
    "06/09/2023": ["114", "63", "120"],
    "06/10/2023": ["***", "**", "***"],
    "06/11/2023": ["***", "**", "***"],
  },
  "06/12/2023 to 06/18/2023": {
    "06/12/2023": ["349", "61", "344"],
    "06/13/2023": ["158", "43", "229"],
    "06/14/2023": ["389", "05", "168"],
    "06/15/2023": ["119", "13", "779"],
    "06/16/2023": ["379", "94", "400"],
    "06/17/2023": ["***", "**", "***"],
    "06/18/2023": ["***", "**", "***"],
  },
  "06/19/2023 to 06/25/2023": {
    "06/19/2023": ["580", "35", "348"],
    "06/20/2023": ["160", "73", "779"],
    "06/21/2023": ["147", "20", "677"],
    "06/22/2023": ["578", "07", "890"],
    "06/23/2023": ["128", "14", "149"],
    "06/24/2023": ["***", "**", "***"],
    "06/25/2023": ["***", "**", "***"],
  },
  "06/26/2023 to 07/02/2023": {
    "06/26/2023": ["568", "99", "225"],
    "06/27/2023": ["345", "26", "358"],
    "06/28/2023": ["577", "93", "580"],
    "06/29/2023": ["456", "50", "145"],
    "06/30/2023": ["289", "99", "180"],
    "07/01/2023": ["***", "**", "***"],
    "07/02/2023": ["***", "**", "***"],
  },
  "07/03/2023 to 07/09/2023": {
    "07/03/2023": ["123", "65", "113"],
    "07/04/2023": ["400", "43", "247"],
    "07/05/2023": ["789", "42", "138"],
    "07/06/2023": ["170", "81", "245"],
    "07/07/2023": ["334", "05", "357"],
    "07/08/2023": ["***", "**", "***"],
    "07/09/2023": ["***", "**", "***"],
  },
  "07/10/2023 to 07/16/2023": {
    "07/10/2023": ["456", "52", "129"],
    "07/11/2023": ["569", "03", "599"],
    "07/12/2023": ["238", "31", "588"],
    "07/13/2023": ["699", "48", "800"],
    "07/14/2023": ["699", "48", "800"],
    "07/15/2023": ["***", "**", "***"],
    "07/16/2023": ["***", "**", "***"],
  },
  "07/17/2023 to 07/23/2023": {
    "07/17/2023": ["270", "91", "137"],
    "07/18/2023": ["155", "11", "470"],
    "07/19/2023": ["590", "42", "679"],
    "07/20/2023": ["227", "11", "290"],
    "07/21/2023": ["259", "69", "469"],
    "07/22/2023": ["***", "**", "***"],
    "07/23/2023": ["***", "**", "***"],
  },
  "07/24/2023 to 07/30/2023": {
    "07/24/2023": ["247", "30", "677"],
    "07/25/2023": ["270", "94", "266"],
    "07/26/2023": ["246", "24", "239"],
    "07/27/2023": ["129", "25", "348"],
    "07/28/2023": ["457", "61", "489"],
    "07/29/2023": ["***", "**", "***"],
    "07/30/2023": ["***", "**", "***"],
  },
  "07/31/2023 to 08/06/2023": {
    "07/31/2023": ["289", "94", "239"],
    "08/01/2023": ["160", "77", "458"],
    "08/02/2023": ["227", "18", "378"],
    "08/03/2023": ["233", "88", "224"],
    "08/04/2023": ["390", "24", "356"],
    "08/05/2023": ["***", "**", "***"],
    "08/06/2023": ["***", "**", "***"],
  },
  "08/07/2023 to 08/13/2023": {
    "08/07/2023": ["368", "75", "267"],
    "08/08/2023": ["126", "92", "147"],
    "08/09/2023": ["150", "69", "144"],
    "08/10/2023": ["239", "40", "569"],
    "08/11/2023": ["126", "92", "390"],
    "08/12/2023": ["***", "**", "***"],
    "08/13/2023": ["***", "**", "***"],
  },
  "08/14/2023 to 08/20/2023": {
    "08/14/2023": ["239", "44", "266"],
    "08/15/2023": ["***", "**", "***"],
    "08/16/2023": ["259", "61", "128"],
    "08/17/2023": ["457", "67", "250"],
    "08/18/2023": ["679", "20", "235"],
    "08/19/2023": ["***", "**", "***"],
    "08/20/2023": ["***", "**", "***"],
  },
  "08/21/2023 to 08/27/2023": {
    "08/21/2023": ["390", "22", "390"],
    "08/22/2023": ["467", "75", "889"],
    "08/23/2023": ["280", "05", "690"],
    "08/24/2023": ["180", "94", "158"],
    "08/25/2023": ["590", "44", "257"],
    "08/26/2023": ["***", "**", "***"],
    "08/27/2023": ["***", "**", "***"],
  },
  "08/28/2023 to 09/03/2023": {
    "08/28/2023": ["790", "60", "677"],
    "08/29/2023": ["237", "22", "129"],
    "08/30/2023": ["170", "89", "469"],
    "08/31/2023": ["116", "81", "146"],
    "09/01/2023": ["400", "47", "250"],
    "09/02/2023": ["***", "**", "***"],
    "09/03/2023": ["***", "**", "***"],
  },
  "09/04/2023 to 09/10/2023": {
    "09/04/2023": ["158", "41", "290"],
    "09/05/2023": ["157", "37", "278"],
    "09/06/2023": ["155", "14", "356"],
    "09/07/2023": ["360", "98", "567"],
    "09/08/2023": ["250", "74", "699"],
    "09/09/2023": ["***", "**", "***"],
    "09/10/2023": ["***", "**", "***"],
  },
  "09/11/2023 to 09/17/2023": {
    "09/11/2023": ["336", "26", "169"],
    "09/12/2023": ["300", "31", "290"],
    "09/13/2023": ["377", "78", "170"],
    "09/14/2023": ["339", "59", "559"],
    "09/15/2023": ["780", "59", "270"],
    "09/16/2023": ["***", "**", "***"],
    "09/17/2023": ["***", "**", "***"],
  },
  "09/18/2023 to 09/24/2023": {
    "09/18/2023": ["125", "88", "369"],
    "09/19/2023": ["469", "97", "160"],
    "09/20/2023": ["158", "44", "770"],
    "09/21/2023": ["679", "25", "249"],
    "09/22/2023": ["366", "51", "245"],
    "09/23/2023": ["***", "**", "***"],
    "09/24/2023": ["***", "**", "***"],
  },
  "09/25/2023 to 10/01/2023": {
    "09/25/2023": ["790", "69", "469"],
    "09/26/2023": ["134", "86", "169"],
    "09/27/2023": ["457", "68", "233"],
    "09/28/2023": ["139", "38", "558"],
    "09/29/2023": ["269", "77", "368"],
    "09/30/2023": ["***", "**", "***"],
    "10/01/2023": ["***", "**", "***"],
  },
  "10/02/2023 to 10/08/2023": {
    "10/02/2023": ["279", "83", "580"],
    "10/03/2023": ["369", "83", "337"],
    "10/04/2023": ["346", "35", "140"],
    "10/05/2023": ["570", "21", "290"],
    "10/06/2023": ["480", "26", "259"],
    "10/07/2023": ["***", "**", "***"],
    "10/08/2023": ["***", "**", "***"],
  },
  "10/09/2023 to 10/15/2023": {
    "10/09/2023": ["369", "80", "668"],
    "10/10/2023": ["123", "61", "380"],
    "10/11/2023": ["580", "39", "144"],
    "10/12/2023": ["249", "56", "178"],
    "10/13/2023": ["300", "30", "488"],
    "10/14/2023": ["***", "**", "***"],
    "10/15/2023": ["***", "**", "***"],
  },
  "10/16/2023 to 10/22/2023": {
    "10/16/2023": ["660", "25", "456"],
    "10/17/2023": ["390", "24", "590"],
    "10/18/2023": ["770", "40", "578"],
    "10/19/2023": ["345", "25", "159"],
    "10/20/2023": ["145", "04", "789"],
    "10/21/2023": ["***", "**", "***"],
    "10/22/2023": ["***", "**", "***"],
  },
  "10/23/2023 to 10/29/2023": {
    "10/23/2023": ["699", "47", "160"],
    "10/24/2023": ["158", "40", "145"],
    "10/25/2023": ["246", "24", "130"],
    "10/26/2023": ["247", "35", "159"],
    "10/27/2023": ["279", "84", "266"],
    "10/28/2023": ["***", "**", "***"],
    "10/29/2023": ["***", "**", "***"],
  },
  "10/30/2023 to 11/05/2023": {
    "10/30/2023": ["348", "50", "578"],
    "10/31/2023": ["889", "55", "889"],
    "11/01/2023": ["558", "87", "179"],
    "11/02/2023": ["445", "30", "136"],
    "11/03/2023": ["138", "28", "279"],
    "11/04/2023": ["***", "**", "***"],
    "11/05/2023": ["***", "**", "***"],
  },
  "11/06/2023 to 11/12/2023": {
    "11/06/2023": ["246", "29", "199"],
    "11/07/2023": ["240", "67", "115"],
    "11/08/2023": ["158", "41", "137"],
    "11/09/2023": ["256", "30", "460"],
    "11/10/2023": ["***", "**", "***"],
    "11/11/2023": ["***", "**", "***"],
    "11/12/2023": ["***", "**", "***"],
  },
  "11/13/2023 to 11/19/2023": {
    "11/13/2023": ["***", "**", "***"],
    "11/14/2023": ["***", "**", "***"],
    "11/15/2023": ["***", "**", "***"],
    "11/16/2023": ["***", "**", "***"],
    "11/17/2023": ["***", "**", "***"],
    "11/18/2023": ["***", "**", "***"],
    "11/19/2023": ["***", "**", "***"],
  },
  "11/20/2023 to 11/26/2023": {
    "11/20/2023": ["122", "59", "180"],
    "11/21/2023": ["238", "34", "257"],
    "11/22/2023": ["179", "72", "156"],
    "11/23/2023": ["290", "15", "339"],
    "11/24/2023": ["116", "83", "337"],
    "11/25/2023": ["***", "**", "***"],
    "11/26/2023": ["***", "**", "***"],
  },
  "11/27/2023 to 12/03/2023": {
    "11/27/2023": ["690", "55", "357"],
    "11/28/2023": ["169", "66", "268"],
    "11/29/2023": ["259", "69", "180"],
    "11/30/2023": ["478", "91", "227"],
    "12/01/2023": ["180", "97", "188"],
    "12/02/2023": ["***", "**", "***"],
    "12/03/2023": ["***", "**", "***"],
  },
  "12/04/2023 to 12/10/2023": {
    "12/04/2023": ["345", "27", "269"],
    "12/05/2023": ["477", "89", "559"],
    "12/06/2023": ["480", "28", "350"],
    "12/07/2023": ["390", "26", "178"],
    "12/08/2023": ["146", "10", "578"],
    "12/09/2023": ["***", "**", "***"],
    "12/10/2023": ["***", "**", "***"],
  },
  "12/11/2023 to 12/17/2023": {
    "12/11/2023": ["457", "62", "688"],
    "12/12/2023": ["334", "09", "900"],
    "12/13/2023": ["268", "66", "358"],
    "12/14/2023": ["155", "14", "149"],
    "12/15/2023": ["479", "03", "346"],
    "12/16/2023": ["***", "**", "***"],
    "12/17/2023": ["***", "**", "***"],
  },
  "12/18/2023 to 12/24/2023": {
    "12/18/2023": ["340", "71", "489"],
    "12/19/2023": ["358", "66", "880"],
    "12/20/2023": ["357", "51", "678"],
    "12/21/2023": ["670", "34", "220"],
    "12/22/2023": ["246", "29", "577"],
    "12/23/2023": ["***", "**", "***"],
    "12/24/2023": ["***", "**", "***"],
  },
  "12/25/2023 to 12/31/2023": {
    "12/25/2023": ["157", "39", "234"],
    "12/26/2023": ["220", "48", "459"],
    "12/27/2023": ["489", "19", "234"],
    "12/28/2023": ["270", "91", "236"],
    "12/29/2023": ["166", "34", "112"],
    "12/30/2023": ["***", "**", "***"],
    "12/31/2023": ["***", "**", "***"],
  },
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["170", "81", "137"],
    "01/02/2024": ["279", "85", "159"],
    "01/03/2024": ["237", "25", "159"],
    "01/04/2024": ["440", "82", "688"],
    "01/05/2024": ["670", "34", "338"],
    "01/06/2024": ["***", "**", "***"],
    "01/07/2024": ["***", "**", "***"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["279", "85", "500"],
    "01/09/2024": ["390", "21", "560"],
    "01/10/2024": ["235", "02", "237"],
    "01/11/2024": ["667", "90", "460"],
    "01/12/2024": ["345", "23", "238"],
    "01/13/2024": ["***", "**", "***"],
    "01/14/2024": ["***", "**", "***"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["789", "45", "267"],
    "01/16/2024": ["245", "11", "470"],
    "01/17/2024": ["124", "78", "170"],
    "01/18/2024": ["570", "25", "555"],
    "01/19/2024": ["133", "74", "167"],
    "01/20/2024": ["***", "**", "***"],
    "01/21/2024": ["***", "**", "***"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["990", "89", "469"],
    "01/23/2024": ["338", "47", "368"],
    "01/24/2024": ["118", "04", "446"],
    "01/25/2024": ["236", "16", "358"],
    "01/26/2024": ["***", "**", "***"],
    "01/27/2024": ["***", "**", "***"],
    "01/28/2024": ["***", "**", "***"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["249", "58", "233"],
    "01/30/2024": ["223", "70", "280"],
    "01/31/2024": ["357", "55", "159"],
    "02/01/2024": ["125", "87", "359"],
    "02/02/2024": ["160", "72", "129"],
    "02/03/2024": ["***", "**", "***"],
    "02/04/2024": ["***", "**", "***"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["589", "28", "477"],
    "02/06/2024": ["469", "99", "469"],
    "02/07/2024": ["467", "72", "570"],
    "02/08/2024": ["460", "01", "380"],
    "02/09/2024": ["123", "65", "357"],
    "02/10/2024": ["***", "**", "***"],
    "02/11/2024": ["***", "**", "***"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["668", "04", "338"],
    "02/13/2024": ["890", "77", "133"],
    "02/14/2024": ["348", "55", "140"],
    "02/15/2024": ["456", "52", "336"],
    "02/16/2024": ["124", "77", "223"],
    "02/17/2024": ["***", "**", "***"],
    "02/18/2024": ["***", "**", "***"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["160", "70", "145"],
    "02/20/2024": ["126", "96", "150"],
    "02/21/2024": ["267", "54", "400"],
    "02/22/2024": ["180", "94", "167"],
    "02/23/2024": ["489", "14", "356"],
    "02/24/2024": ["***", "**", "***"],
    "02/25/2024": ["***", "**", "***"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["249", "50", "190"],
    "02/27/2024": ["460", "04", "257"],
    "02/28/2024": ["259", "68", "288"],
    "02/29/2024": ["550", "03", "670"],
    "03/01/2024": ["456", "55", "168"],
    "03/02/2024": ["***", "**", "***"],
    "03/03/2024": ["***", "**", "***"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["355", "31", "399"],
    "03/05/2024": ["459", "88", "279"],
    "03/06/2024": ["237", "20", "280"],
    "03/07/2024": ["290", "11", "344"],
    "03/08/2024": ["800", "82", "246"],
    "03/09/2024": ["***", "**", "***"],
    "03/10/2024": ["***", "**", "***"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["588", "15", "230"],
    "03/12/2024": ["245", "15", "690"],
    "03/13/2024": ["700", "71", "669"],
    "03/14/2024": ["135", "90", "389"],
    "03/15/2024": ["680", "41", "335"],
    "03/16/2024": ["***", "**", "***"],
    "03/17/2024": ["***", "**", "***"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["144", "98", "288"],
    "03/19/2024": ["356", "42", "228"],
    "03/20/2024": ["457", "63", "599"],
    "03/21/2024": ["269", "71", "470"],
    "03/22/2024": ["578", "02", "156"],
    "03/23/2024": ["***", "**", "***"],
    "03/24/2024": ["***", "**", "***"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["***", "**", "***"],
    "03/26/2024": ["470", "12", "589"],
    "03/27/2024": ["890", "71", "560"],
    "03/28/2024": ["369", "84", "770"],
    "03/29/2024": ["460", "08", "378"],
    "03/30/2024": ["***", "**", "***"],
    "03/31/2024": ["***", "**", "***"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["123", "67", "377"],
    "04/02/2024": ["889", "54", "680"],
    "04/03/2024": ["124", "71", "560"],
    "04/04/2024": ["190", "03", "256"],
    "04/05/2024": ["147", "22", "688"],
    "04/06/2024": ["***", "**", "***"],
    "04/07/2024": ["***", "**", "***"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["169", "61", "489"],
    "04/09/2024": ["144", "97", "340"],
    "04/10/2024": ["355", "38", "189"],
    "04/11/2024": ["578", "01", "489"],
    "04/12/2024": ["250", "79", "289"],
    "04/13/2024": ["***", "**", "***"],
    "04/14/2024": ["***", "**", "***"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["790", "63", "670"],
    "04/16/2024": ["559", "90", "136"],
    "04/17/2024": ["116", "85", "249"],
    "04/18/2024": ["240", "64", "590"],
    "04/19/2024": ["799", "58", "189"],
    "04/20/2024": ["***", "**", "***"],
    "04/21/2024": ["***", "**", "***"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["267", "55", "456"],
    "04/23/2024": ["347", "44", "257"],
    "04/24/2024": ["489", "11", "470"],
    "04/25/2024": ["233", "87", "160"],
    "04/26/2024": ["344", "14", "257"],
    "04/27/2024": ["***", "**", "***"],
    "04/28/2024": ["***", "**", "***"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["245", "11", "236"],
    "04/30/2024": ["358", "66", "178"],
    "05/01/2024": ["356", "40", "569"],
    "05/02/2024": ["347", "44", "130"],
    "05/03/2024": ["167", "40", "488"],
    "05/04/2024": ["***", "**", "***"],
    "05/05/2024": ["***", "**", "***"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["349", "67", "269"],
    "05/07/2024": ["688", "20", "280"],
    "05/08/2024": ["670", "32", "200"],
    "05/09/2024": ["189", "86", "268"],
    "05/10/2024": ["358", "67", "359"],
    "05/11/2024": ["***", "**", "***"],
    "05/12/2024": ["***", "**", "***"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["136", "03", "148"],
    "05/14/2024": ["117", "98", "260"],
    "05/15/2024": ["125", "80", "127"],
    "05/16/2024": ["345", "20", "127"],
    "05/17/2024": ["138", "29", "469"],
    "05/18/2024": ["***", "**", "***"],
    "05/19/2024": ["***", "**", "***"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["159", "54", "789"],
    "05/21/2024": ["189", "81", "236"],
    "05/22/2024": ["145", "04", "680"],
    "05/23/2024": ["270", "97", "368"],
    "05/24/2024": ["347", "46", "330"],
    "05/25/2024": ["***", "**", "***"],
    "05/26/2024": ["***", "**", "***"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["490", "39", "559"],
    "05/28/2024": ["346", "36", "240"],
    "05/29/2024": ["447", "58", "233"],
    "05/30/2024": ["139", "32", "156"],
    "05/31/2024": ["359", "74", "789"],
    "06/01/2024": ["***", "**", "***"],
    "06/02/2024": ["***", "**", "***"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["335", "19", "234"],
    "06/04/2024": ["466", "67", "458"],
    "06/05/2024": ["277", "68", "224"],
    "06/06/2024": ["238", "32", "200"],
    "06/07/2024": ["330", "65", "348"],
    "06/08/2024": ["***", "**", "***"],
    "06/09/2024": ["***", "**", "***"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["590", "48", "369"],
    "06/25/2024": ["270", "93", "445"],
    "06/26/2024": ["780", "52", "589"],
    "06/27/2024": ["144", "97", "458"],
    "06/28/2024": ["144", "97", "700"],
    "06/29/2024": ["***", "**", "***"],
    "06/30/2024": ["***", "**", "***"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["148", "32", "200"],
    "07/02/2024": ["159", "50", "578"],
    "07/03/2024": ["356", "47", "179"],
    "07/04/2024": ["369", "89", "568"],
    "07/05/2024": ["245", "12", "138"],
    "07/06/2024": ["***", "**", "***"],
    "07/07/2024": ["***", "**", "***"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["389", "09", "469"],
    "07/09/2024": ["169", "66", "150"],
    "07/10/2024": ["228", "25", "366"],
    "07/11/2024": ["579", "13", "490"],
    "07/12/2024": ["279", "81", "146"],
    "07/13/2024": ["***", "**", "***"],
    "07/14/2024": ["***", "**", "***"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["459", "82", "156"],
    "07/16/2024": ["150", "67", "179"],
    "07/17/2024": ["360", "95", "267"],
    "07/18/2024": ["169", "68", "170"],
    "07/19/2024": ["789", "44", "248"],
    "07/20/2024": ["***", "**", "***"],
    "07/21/2024": ["***", "**", "***"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["770", "40", "136"],
    "07/23/2024": ["356", "45", "267"],
    "07/24/2024": ["130", "40", "370"],
    "07/25/2024": ["125", "82", "778"],
    "07/26/2024": ["588", "13", "148"],
    "07/27/2024": ["***", "**", "***"],
    "07/28/2024": ["***", "**", "***"],
  },
};

export const MAYAMORNING = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": [245, 16, 169],
    "01/02/2024": [480, 27, 124],
    "01/03/2024": [677, "09", 577],
    "01/04/2024": [120, 36, 448],
    "01/05/2024": [180, 92, 345],
    "01/06/2024": [125, 87, 188],
    "01/07/2024": [338, 41, 236],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": [368, 74, 167],
    "01/09/2024": [559, 98, 260],
    "01/10/2024": [880, 69, 225],
    "01/11/2024": [489, 12, 200],
    "01/12/2024": [290, 17, 188],
    "01/13/2024": [249, 51, 128],
    "01/14/2024": [460, "03", 256],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": [369, 88, 378],
    "01/16/2024": [679, 24, 149],
    "01/17/2024": [345, 28, 233],
    "01/18/2024": [380, 13, 689],
    "01/19/2024": [248, 44, 356],
    "01/20/2024": [127, "05", 140],
    "01/21/2024": [568, 90, 578],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": [579, 13, 670],
    "01/23/2024": [470, 11, 344],
    "01/24/2024": [268, 65, 456],
    "01/25/2024": [147, 20, 145],
    "01/16/2024": [679, 24, 149],
    "01/11/2024": [489, 12, 200],
    "01/28/2024": [170, 88, 459],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": [115, 72, 129],
    "01/30/2024": [479, "05", 159],
    "01/31/2024": [189, 84, 220],
    "02/01/2024": [123, 62, 390],
    "02/02/2024": [190, "05", 447],
    "02/03/2024": [670, 32, 138],
    "02/04/2024": [347, 45, 690],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": [156, 24, 789],
    "02/06/2024": [267, 59, 360],
    "02/07/2024": [249, 54, 356],
    "02/08/2024": [156, 26, 457],
    "02/09/2024": [146, 12, 237],
    "02/10/2024": [288, 80, 668],
    "02/11/2024": [349, 63, 157],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": [139, 35, 230],
    "02/13/2024": [257, 49, 289],
    "02/14/2024": [778, 29, 135],
    "02/15/2024": [288, 85, 780],
    "02/16/2024": [389, "08", 134],
    "02/17/2024": [125, 86, 600],
    "02/18/2024": [148, 38, 279],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": [137, 19, 478],
    "02/20/2024": [466, 67, 278],
    "02/21/2024": [457, 65, 177],
    "02/22/2024": [348, 52, 679],
    "02/23/2024": [467, 72, 147],
    "02/24/2024": [235, "07", 368],
    "02/25/2024": [577, 94, 167],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": [359, 71, 146],
    "02/27/2024": [590, 49, 379],
    "02/28/2024": [358, 68, 468],
    "02/29/2024": [357, 55, 780],
    "03/01/2024": [579, 11, 245],
    "03/02/2024": [570, 20, 460],
    "03/03/2024": [225, 90, 389],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": [778, 28, 369],
    "03/05/2024": [350, 89, 180],
    "03/06/2024": [390, 20, 479],
    "04/03/2024": [156, 28, 279],
    "03/21/2024": [158, 46, 790],
    "04/14/2024": ["259", "63", "256"],
    "03/10/2024": [278, 76, 169],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": [178, 66, 268],
    "03/12/2024": [350, 89, 180],
    "03/13/2024": [145, 0, 280],
    "03/14/2024": [337, 39, 234],
    "03/15/2024": [235, "05", 168],
    "03/16/2024": [459, 87, 269],
    "03/17/2024": [345, 28, 125],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": [456, 52, 237],
    "03/19/2024": [789, 48, 567],
    "03/20/2024": [370, 0, 370],
    "03/21/2024": [158, 46, 790],
    "03/22/2024": [347, 40, 226],
    "03/23/2024": [470, 18, 279],
    "03/24/2024": [138, 23, 580],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": [370, "03", 247],
    "03/26/2024": [160, 79, 289],
    "03/27/2024": [589, 27, 359],
    "03/28/2024": [137, 13, 247],
    "03/29/2024": [489, 15, 348],
    "03/30/2024": [260, 89, 270],
    "03/31/2024": [158, 42, 147],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": [478, 91, 155],
    "04/02/2024": [589, 25, 267],
    "04/03/2024": [156, 28, 279],
    "04/04/2024": [790, 60, 127],
    "04/05/2024": [456, 55, 249],
    "04/06/2024": [130, 43, 148],
    "04/07/2024": ["148", "30", "127"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": [699, 41, 399],
    "04/09/2024": [388, 91, 236],
    "04/10/2024": [256, 33, 120],
    "05/06/2024": [117, 98, 369],

    "04/12/2024": [144, 96, 259],
    "04/13/2024": [123, 66, 240],
    "04/14/2024": ["259", "93", "256"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": [450, 98, 350],
    "04/16/2024": [568, 90, 136],
    "04/17/2024": [258, 53, 139],
    "04/18/2024": [457, 66, 358],
    "04/19/2024": [235, "08", 288],
    "04/20/2024": [390, 29, 568],
    "04/21/2024": ["450", "96", "358"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": [278, 73, 148],
    "04/23/2024": [559, 91, 137],
    "04/24/2024": [169, 68, 189],
    "04/25/2024": [389, "01", 470],
    "04/26/2024": [160, 71, 146],
    "04/27/2024": [378, 87, 133],
    "04/28/2024": ["559", "91", "470"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": [347, 46, 448],
    "04/30/2024": [380, 11, 560],
    "05/01/2024": [377, 72, 480],
    "05/02/2024": [147, 27, 133],
    "05/03/2024": [336, 26, 556],
    "05/04/2024": [130, 42, 480],
    "05/05/2024": ["377", "74", "130"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": [117, 98, 369],
    "05/07/2024": [668, "06", 790],
    "05/08/2024": [246, 24, 158],
    "05/09/2024": [170, 83, 580],
    "05/10/2024": [267, 57, 115],
    "05/11/2024": [468, 87, 890],
    "05/12/2024": ["668", "07", "115"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": [369, 84, 239],
    "05/14/2024": [780, 50, 370],
    "05/27/2024": [117, 98, 369],
    "05/16/2024": [789, 46, 259],
    "05/17/2024": [780, 53, 256],
    "05/18/2024": [126, 97, 160],
    "05/19/2024": ["468", "88", "369"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": [336, 20, 370],
    "05/21/2024": [134, 80, 136],
    "05/22/2024": [668, "09", 289],
    "05/23/2024": [448, 65, 122],
    "05/24/2024": [590, 48, 279],
    "05/25/2024": [669, 10, 118],
    "05/29/2024": [246, 24, 158],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": [117, 98, 369],
    "05/28/2024": [668, "06", 790],
    "05/29/2024": [246, 24, 158],
    "05/30/2024": [170, 83, 580],
    "05/31/2024": [267, 57, 115],
    "06/01/2024": [468, 87, 890],
    "05/23/2024": [448, 65, 122],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": [369, 84, 239],
    "06/04/2024": [780, 50, 370],
    "06/12/2024": [668, "09", 289],
    "06/06/2024": [789, 46, 259],
    "06/07/2024": [780, 53, 256],
    "06/08/2024": [126, 97, 160],
    "06/09/2024": [668, "09", 289],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": [336, 20, 370],
    "06/11/2024": [134, 80, 136],
    "06/12/2024": [668, "09", 289],
    "06/13/2024": [448, 65, 122],
    "06/14/2024": [590, 48, 279],
    "06/15/2024": [669, 10, 118],
    "05/30/2024": [170, 83, 580],
  },

  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["200", "22", "345"],
    "06/18/2024": ["250", "76", "178"],
    "06/19/2024": ["144", "94", "356"],
    "06/20/2024": ["122", "57", "278"],
    "06/21/2024": ["238", "34", "248"],
    "06/22/2024": ["128", "12", "156"],
    "06/11/2024": ["134", "80", "136"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["180", "90", "280"],
    "06/25/2024": ["239", "45", "258"],
    "06/26/2024": ["188", "72", "345"],
    "06/27/2024": ["268", "68", "279"],
    "06/28/2024": ["247", "33", "256"],
    "06/29/2024": ["290", "18", "990"],
    "07/29/2024": ["150", "66", "330"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["167", "44", "699"],
    "07/02/2024": ["189", "85", "159"],
    "07/03/2024": ["146", "13", "148"],
    "07/04/2024": ["900", "90", "127"],
    "07/05/2024": ["560", "40", "320"],
    "07/06/2024": ["167", "55", "889"],
    "05/23/2024": ["448", "65", "122"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["129", "22", "778"],
    "07/09/2024": ["123", "67", "179"],
    "07/10/2024": ["680", "48", "567"],
    "07/11/2024": ["160", "77", "449"],
    "07/12/2024": ["247", "23", "355"],
    "07/13/2024": ["355", "39", "289"],
    "06/24/2024": ["577", "90", "280"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["990", "88", "288"],
    "07/16/2024": ["146", "12", "255"],
    "07/17/2024": ["115", "77", "250"],
    "07/18/2024": ["120", "33", "300"],
    "07/19/2024": ["448", "64", "220"],
    "07/20/2024": ["360", "90", "000"],
    "06/07/2024": ["140", "53", "670"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["459", "88", "369"],
    "07/23/2024": ["136", "06", "268"],
    "07/24/2024": ["236", "11", "678"],
    "07/25/2024": ["789", "41", "236"],
    "07/26/2024": ["450", "90", "190"],
    "07/27/2024": ["780", "55", "159"],
    "07/04/2024": ["126", "90", "244"],
  },
  "07/29/2024 to 08/04/2024": {
    "07/29/2024": ["169", "66", "899"],
    "07/30/2024": ["356", "47", "124"],
    "07/31/2024": ["469", "93", "355"],
    "08/01/2024": ["137", "15", "500"],
    "08/02/2024": ["120", "34", "158"],
    "08/03/2024": ["122", "58", "558"],
    "07/23/2024": ["000", "06", "114"],
  },
  "08/05/2024 to 08/11/2024": {
    "08/05/2024": ["778", "25", "177"],
    "08/06/2024": ["270", "93", "166"],
    "08/07/2024": ["689", "39", "126"],
    "08/08/2024": ["299", "03", "337"],
    "08/09/2024": ["145", "02", "679"],
    "08/10/2024": ["190", "09", "379"],
    "08/22/2024": ["155", "14", "220"],
  },
  "08/12/2024 to 08/18/2024": {
    "08/12/2024": ["258", "89", "370"],
    "08/13/2024": ["456", "64", "245"],
    "08/14/2024": ["170", "30", "389"],
    "08/15/2024": ["289", "75", "472"],
    "08/16/2024": ["304", "52", "278"],
    "08/17/2024": ["496", "11", "355"],
    "09/02/2024": [457, 64, 287],
  },
  "08/19/2024 to 08/25/2024": {
    "08/19/2024": ["239", "90", "377"],
    "08/20/2024": ["478", "35", "256"],
    "08/21/2024": ["192", "83", "460"],
    "08/22/2024": ["357", "14", "328"],
    "08/23/2024": ["496", "22", "178"],
    "08/24/2024": ["278", "99", "462"],
    "07/18/2024": ["148", "33", "445"],
  },
  "08/26/2024 to 09/01/2024": {
    "08/26/2024": ["356", "67", "220"],
    "08/27/2024": ["299", "88", "431"],
    "08/28/2024": ["175", "43", "379"],
    "08/29/2024": ["489", "56", "293"],
    "08/30/2024": ["214", "90", "457"],
    "08/31/2024": ["388", "27", "345"],
    "08/13/2024": ["240", "64", "139"],
  },
  "09/02/2024 to 09/08/2024": {
    "09/02/2024": ["312", "64", "287"],
    "09/03/2024": ["489", "23", "358"],
    "09/04/2024": ["157", "82", "274"],
    "09/05/2024": ["236", "47", "190"],
    "09/06/2024": ["413", "55", "369"],
    "09/07/2024": ["278", "68", "305"],
    "08/26/2024": ["169", "67", "269"],
  },
};

export const INDRAMORNING = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["880", "66", "600"],
    "01/02/2024": ["467", "77", "179"],
    "01/03/2024": ["888", "42", "246"],
    "01/04/2024": ["226", "09", "568"],
    "01/05/2024": ["177", "53", "120"],
    "01/06/2024": ["577", "96", "466"],
    "01/07/2024": ["111", "39", "333"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["110", "23", "337"],
    "01/09/2024": ["240", "66", "169"],
    "01/10/2024": ["479", "03", "355"],
    "01/11/2024": ["689", "30", "677"],
    "01/12/2024": ["249", "50", "488"],
    "01/13/2024": ["160", "71", "489"],
    "01/14/2024": ["140", "54", "158"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["236", "14", "158"],
    "01/16/2024": ["880", "60", "550"],
    "01/17/2024": ["349", "61", "100"],
    "01/18/2024": ["358", "60", "226"],
    "01/19/2024": ["590", "41", "678"],
    "01/20/2024": ["349", "68", "990"],
    "01/21/2024": ["136", "03", "337"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["338", "49", "180"],
    "01/23/2024": ["589", "27", "250"],
    "01/24/2024": ["488", "05", "230"],
    "01/25/2024": ["290", "17", "188"],
    "01/26/2024": ["355", "32", "156"],
    "01/27/2024": ["112", "42", "228"],
    "01/28/2024": ["777", "14", "220"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["112", "41", "100"],
    "01/30/2024": ["138", "29", "117"],
    "01/31/2024": ["579", "10", "370"],
    "02/01/2024": ["347", "45", "168"],
    "02/02/2024": ["267", "54", "338"],
    "02/03/2024": ["138", "22", "390"],
    "02/04/2024": ["259", "64", "455"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["445", "33", "139"],
    "02/06/2024": ["136", "00", "550"],
    "02/07/2024": ["258", "59", "289"],
    "02/08/2024": ["158", "41", "560"],
    "02/09/2024": ["456", "58", "224"],
    "02/10/2024": ["777", "15", "249"],
    "02/11/2024": ["468", "80", "226"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["236", "15", "357"],
    "02/13/2024": ["446", "47", "124"],
    "02/14/2024": ["337", "31", "146"],
    "02/15/2024": ["990", "83", "689"],
    "02/16/2024": ["357", "51", "236"],
    "02/17/2024": ["199", "92", "570"],
    "02/18/2024": ["146", "15", "780"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["900", "92", "336"],
    "02/20/2024": ["139", "31", "128"],
    "02/21/2024": ["680", "42", "110"],
    "02/22/2024": ["256", "32", "345"],
    "02/23/2024": ["149", "42", "660"],
    "02/24/2024": ["119", "10", "145"],
    "02/25/2024": ["577", "95", "113"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["489", "13", "346"],
    "02/27/2024": ["125", "80", "488"],
    "02/28/2024": ["139", "35", "140"],
    "02/29/2024": ["456", "55", "348"],
    "03/01/2024": ["669", "19", "379"],
    "03/02/2024": ["489", "10", "389"],
    "03/03/2024": ["390", "23", "599"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["127", "04", "149"],
    "03/05/2024": ["468", "89", "577"],
    "03/06/2024": ["356", "49", "360"],
    "03/07/2024": ["370", "02", "336"],
    "03/08/2024": ["459", "83", "670"],
    "03/09/2024": ["660", "21", "245"],
    "03/10/2024": ["356", "43", "337"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["550", "01", "489"],
    "03/12/2024": ["244", "08", "558"],
    "03/13/2024": ["455", "49", "289"],
    "03/14/2024": ["299", "05", "357"],
    "03/15/2024": ["246", "22", "499"],
    "03/16/2024": ["189", "88", "170"],
    "03/17/2024": ["339", "52", "589"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["456", "52", "345"],
    "03/19/2024": ["128", "11", "470"],
    "03/20/2024": ["268", "65", "366"],
    "03/21/2024": ["467", "78", "279"],
    "03/22/2024": ["120", "30", "550"],
    "03/23/2024": ["223", "70", "488"],
    "03/24/2024": ["236", "14", "167"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["117", "90", "226"],
    "03/26/2024": ["470", "18", "990"],
    "03/27/2024": ["117", "90", "000"],
    "03/28/2024": ["337", "39", "135"],
    "03/29/2024": ["346", "31", "579"],
    "03/30/2024": ["500", "55", "168"],
    "03/31/2024": ["457", "66", "150"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["556", "67", "250"],
    "04/02/2024": ["300", "31", "380"],
    "04/03/2024": ["289", "90", "118"],
    "04/04/2024": ["180", "94", "680"],
    "04/05/2024": ["368", "79", "450"],
    "04/06/2024": ["356", "46", "259"],
    "04/07/2024": ["440", "82", "345"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["150", "64", "347"],
    "04/09/2024": ["560", "12", "110"],
    "04/10/2024": ["358", "69", "135"],
    "04/11/2024": ["348", "53", "157"],
    "04/12/2024": ["145", "03", "247"],
    "04/13/2024": ["340", "78", "189"],
    "04/14/2024": ["338", "42", "129"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["679", "27", "890"],
    "04/16/2024": ["260", "83", "139"],
    "04/17/2024": ["179", "70", "190"],
    "04/18/2024": ["569", "05", "339"],
    "04/19/2024": ["126", "98", "170"],
    "04/20/2024": ["233", "82", "255"],
    "04/21/2024": ["667", "99", "478"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["990", "80", "460"],
    "04/23/2024": ["300", "32", "589"],
    "04/24/2024": ["469", "96", "448"],
    "04/25/2024": ["459", "86", "277"],
    "04/26/2024": ["349", "67", "223"],
    "04/27/2024": ["566", "77", "458"],
    "04/28/2024": ["220", "47", "368"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["223", "79", "117"],
    "04/30/2024": ["370", "09", "568"],
    "05/01/2024": ["228", "29", "900"],
    "05/02/2024": ["359", "74", "130"],
    "05/03/2024": ["148", "39", "234"],
    "05/04/2024": ["348", "56", "150"],
    "05/05/2024": ["270", "95", "140"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["239", "45", "456"],
    "05/07/2024": ["378", "84", "356"],
    "05/08/2024": ["127", "08", "459"],
    "05/09/2024": ["168", "55", "690"],
    "05/10/2024": ["269", "75", "258"],
    "05/11/2024": ["360", "94", "789"],
    "05/12/2024": ["590", "40", "145"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["156", "23", "120"],
    "05/14/2024": ["136", "07", "890"],
    "05/15/2024": ["345", "24", "257"],
    "05/16/2024": ["455", "48", "459"],
    "05/17/2024": ["159", "51", "489"],
    "05/18/2024": ["117", "99", "126"],
    "05/19/2024": ["600", "63", "355"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["579", "19", "225"],
    "05/21/2024": ["448", "68", "567"],
    "05/22/2024": ["570", "22", "679"],
    "05/23/2024": ["127", "01", "560"],
    "05/24/2024": ["667", "96", "899"],
    "05/25/2024": ["399", "17", "124"],
    "05/26/2024": ["668", "08", "440"],
  },

  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["880", "61", "380"],
    "05/28/2024": ["470", "10", "226"],
    "05/29/2024": ["134", "83", "346"],
    "05/30/2024": ["360", "91", "137"],
    "05/31/2024": ["238", "35", "456"],
    "06/01/2024": ["467", "79", "379"],
    "06/02/2024": ["390", "27", "278"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["170", "88", "224"],
    "06/04/2024": ["466", "67", "890"],
    "06/05/2024": ["340", "77", "124"],
    "06/06/2024": ["156", "22", "679"],
    "06/07/2024": ["125", "82", "228"],
    "06/08/2024": ["157", "30", "136"],
    "06/09/2024": ["560", "18", "369"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["780", "58", "170"],
    "06/11/2024": ["138", "25", "168"],
    "06/12/2024": ["459", "82", "246"],
    "06/13/2024": ["340", "75", "690"],
    "06/14/2024": ["479", "08", "233"],
    "06/15/2024": ["457", "62", "156"],
    "06/16/2024": ["200", "20", "127"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["260", "80", "145"],
    "06/18/2024": ["259", "64", "248"],
    "06/19/2024": ["245", "15", "889"],
    "06/20/2024": ["234", "97", "377"],
    "06/21/2024": ["169", "65", "348"],
    "06/22/2024": ["369", "87", "160"],
    "06/23/2024": ["379", "96", "330"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["457", "61", "128"],
    "06/25/2024": ["379", "99", "289"],
    "06/26/2024": ["245", "15", "889"],
    "06/27/2024": ["256", "39", "270"],
    "06/28/2024": ["477", "81", "119"],
    "06/29/2024": ["679", "25", "168"],
    "06/30/2024": ["179", "77", "340"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["177", "58", "459"],
    "07/02/2024": ["680", "49", "360"],
    "07/03/2024": ["579", "17", "124"],
    "07/04/2024": ["990", "80", "569"],
    "07/05/2024": ["289", "93", "247"],
    "07/06/2024": ["300", "30", "460"],
    "07/07/2024": ["129", "22", "228"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["180", "99", "126"],
    "07/09/2024": ["589", "21", "344"],
    "07/10/2024": ["358", "67", "115"],
    "07/11/2024": ["244", "07", "278"],
    "07/12/2024": ["113", "51", "470"],
    "07/13/2024": ["340", "74", "149"],
    "07/14/2024": ["146", "13", "247"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["440", "89", "900"],
    "07/16/2024": ["113", "55", "249"],
    "07/17/2024": ["689", "31", "137"],
    "07/18/2024": ["559", "97", "890"],
    "07/19/2024": ["279", "88", "350"],
    "07/20/2024": ["490", "30", "389"],
    "07/21/2024": ["135", "96", "899"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["557", "73", "148"],
    "07/23/2024": ["478", "93", "346"],
    "07/24/2024": ["259", "67", "133"],
    "07/25/2024": ["799", "50", "460"],
    "07/26/2024": ["678", "15", "357"],
    "07/27/2024": ["247", "32", "138"],
    "07/28/2024": ["560", "19", "388"],
  },
  "07/29/2024 to 08/04/2024": {
    "07/29/2024": ["258", "51", "669"],
    "07/30/2024": ["490", "34", "789"],
    "07/31/2024": ["568", "99", "126"],
    "08/01/2024": ["670", "35", "348"],
    "08/02/2024": ["566", "70", "479"],
    "08/03/2024": ["129", "28", "260"],
    "08/04/2024": ["347", "44", "158"],
  },
  "08/05/2024 to 08/11/2024": {
    "08/05/2024": ["570", "27", "467"],
    "08/06/2024": ["190", "05", "339"],
    "08/07/2024": ["266", "45", "122"],
    "07/31/2024": ["568", "99", "126"],
    "08/09/2024": ["170", "84", "699"],
    "08/10/2024": ["377", "75", "447"],
    "08/11/2024": ["470", "17", "133"],
  },
  "08/12/2024 to 08/18/2024": {
    "08/12/2024": ["150", "67", "557"],
    "08/13/2024": ["568", "96", "178"],
    "08/14/2024": ["680", "40", "145"],
    "08/15/2024": ["489", "13", "670"],
    "08/16/2024": ["358", "60", "235"],
    "08/17/2024": ["290", "11", "579"],
    "08/18/2024": ["346", "34", "130"],
  },
  "08/19/2024 to 08/25/2024": {
    "08/19/2024": ["148", "30", "127"],
    "08/20/2024": ["570", "23", "256"],
    "08/21/2024": ["679", "21", "470"],
    "08/22/2024": ["125", "88", "378"],
    "08/23/2024": ["789", "41", "380"],
    "08/24/2024": ["458", "78", "134"],
    "08/25/2024": ["238", "37", "890"],
  },
  "08/26/2024 to 09/01/2024": {
    "08/26/2024": ["130", "49", "270"],
    "08/27/2024": ["399", "10", "578"],
    "08/28/2024": ["589", "21", "146"],
    "08/29/2024": ["460", "02", "688"],
    "08/30/2024": ["566", "70", "280"],
    "08/31/2024": ["245", "16", "358"],
    "09/01/2024": ["679", "25", "140"],
  },
  "09/02/2024 to 09/08/2024": {
    "09/02/2024": ["457", "66", "880"],
    "09/03/2024": ["239", "41", "344"],
    "09/04/2024": ["478", "99", "180"],
    "09/05/2024": ["579", "15", "366"],
    "09/06/2024": ["267", "59", "568"],
    "09/07/2024": ["170", "89", "900"],
    "09/08/2024": ["138", "20", "370"],
  },
  "09/09/2024 to 09/15/2024": {
    "09/09/2024": ["499", "24", "158"],
    "09/10/2024": ["260", "80", "569"],
    "09/11/2024": ["440", "86", "358"],
    "09/12/2024": ["188", "71", "236"],
    "09/13/2024": ["799", "54", "347"],
    "09/14/2024": ["259", "66", "150"],
    "09/15/2024": ["137", "17", "458"],
  },
};
export const INDRADAY = {
  "01/01/2024 to 07/01/2024": {
    "01/01/2024": ["278", "73", "139"],
    "02/01/2024": ["150", "61", "290"],
    "03/01/2024": ["235", "04", "220"],
    "04/01/2024": ["448", "69", "559"],
    "05/01/2024": ["278", "76", "367"],
    "06/01/2024": ["280", "04", "266"],
    "07/01/2024": ["288", "85", "122"],
  },
  "08/01/2024 to 14/01/2024": {
    "08/01/2024": ["160", "77", "179"],
    "09/01/2024": ["379", "96", "367"],
    "10/01/2024": ["377", "73", "779"],
    "11/01/2024": ["680", "42", "589"],
    "12/01/2024": ["347", "43", "670"],
    "13/01/2024": ["148", "35", "690"],
    "14/01/2024": ["379", "96", "466"],
  },
  "15/01/2024 to 21/01/2024": {
    "15/01/2024": ["689", "37", "467"],
    "16/01/2024": ["668", "03", "599"],
    "17/01/2024": ["299", "01", "678"],
    "18/01/2024": ["389", "04", "789"],
    "19/01/2024": ["234", "97", "890"],
    "20/01/2024": ["577", "95", "168"],
    "21/01/2024": ["666", "83", "779"],
  },
  "22/01/2024 to 28/01/2024": {
    "22/01/2024": ["357", "54", "455"],
    "23/01/2024": ["339", "58", "378"],
    "24/01/2024": ["669", "14", "347"],
    "25/01/2024": ["389", "07", "223"],
    "26/01/2024": ["690", "51", "669"],
    "27/01/2024": ["479", "07", "467"],
    "28/01/2024": ["266", "46", "448"],
  },
  "29/01/2024 to 04/02/2024": {
    "29/01/2024": ["679", "24", "789"],
    "30/01/2024": ["360", "99", "900"],
    "31/01/2024": ["260", "85", "113"],
    "01/02/2024": ["279", "82", "228"],
    "02/02/2024": ["578", "00", "389"],
    "03/02/2024": ["269", "79", "577"],
    "04/02/2024": ["288", "86", "899"],
  },
  "05/02/2024 to 11/02/2024": {
    "05/02/2024": ["339", "51", "678"],
    "06/02/2024": ["159", "57", "188"],
    "07/02/2024": ["268", "62", "589"],
    "08/02/2024": ["240", "62", "228"],
    "09/02/2024": ["379", "94", "149"],
    "10/02/2024": ["690", "58", "233"],
    "11/02/2024": ["388", "97", "115"],
  },
  "12/02/2024 to 18/02/2024": {
    "12/02/2024": ["260", "87", "269"],
    "13/02/2024": ["668", "00", "578"],
    "14/02/2024": ["367", "64", "789"],
    "15/02/2024": ["189", "82", "129"],
    "16/02/2024": ["240", "67", "179"],
    "17/02/2024": ["677", "05", "889"],
    "18/02/2024": ["589", "26", "330"],
  },
  "19/02/2024 to 25/02/2024": {
    "19/02/2024": ["578", "06", "358"],
    "20/02/2024": ["127", "02", "147"],
    "21/02/2024": ["390", "27", "124"],
    "22/02/2024": ["126", "96", "790"],
    "14/02/2024": ["367", "64", "789"],
    "24/02/2024": ["156", "22", "480"],
    "25/02/2024": ["446", "40", "334"],
  },
  "26/02/2024 to 03/03/2024": {
    "26/02/2024": ["790", "67", "458"],
    "27/02/2024": ["679", "25", "159"],
    "28/02/2024": ["137", "19", "135"],
    "29/02/2024": ["289", "98", "440"],
    "01/03/2024": ["234", "95", "780"],
    "02/03/2024": ["234", "95", "780"],
    "03/03/2024": ["899", "68", "558"],
  },
  "04/03/2024 to 10/03/2024": {
    "04/03/2024": ["560", "19", "379"],
    "05/03/2024": ["167", "46", "790"],
    "06/03/2024": ["358", "68", "567"],
    "07/03/2024": ["177", "59", "667"],
    "08/03/2024": ["116", "87", "467"],
    "09/03/2024": ["157", "32", "156"],
    "10/03/2024": ["336", "24", "266"],
  },
  "11/03/2024 to 17/03/2024": {
    "11/03/2024": ["157", "34", "167"],
    "12/03/2024": ["190", "09", "270"],
    "13/03/2024": ["180", "94", "680"],
    "14/03/2024": ["257", "46", "358"],
    "15/03/2024": ["569", "09", "126"],
    "16/03/2024": ["356", "49", "469"],
    "17/03/2024": ["257", "47", "359"],
  },
  "19/03/2024 to 25/03/2024": {
    "19/03/2024": ["389", "01", "588"],
    "20/03/2024": ["230", "51", "678"],
    "21/03/2024": ["149", "47", "359"],
    "22/03/2024": ["579", "19", "478"],
    "23/03/2024": ["220", "48", "477"],
    "24/03/2024": ["357", "57", "890"],
    "25/03/2024": ["559", "97", "368"],
  },
  "26/03/2024 to 01/04/2024": {
    "26/03/2024": ["189", "89", "135"],
    "27/03/2024": ["123", "64", "158"],
    "28/03/2024": ["125", "88", "260"],
    "29/03/2024": ["260", "84", "257"],
    "30/03/2024": ["570", "29", "478"],
    "31/03/2024": ["480", "26", "600"],
    "01/04/2024": ["899", "62", "255"],
  },
  "02/04/2024 to 08/04/2024": {
    "02/04/2024": ["159", "54", "167"],
    "03/04/2024": ["123", "60", "460"],
    "04/04/2024": ["380", "14", "130"],
    "05/04/2024": ["234", "94", "770"],
    "06/04/2024": ["126", "94", "680"],
    "07/04/2024": ["267", "59", "478"],
    "08/04/2024": ["189", "87", "467"],
  },
  "09/04/2024 to 15/04/2024": {
    "09/04/2024": ["157", "39", "577"],
    "10/04/2024": ["247", "35", "690"],
    "11/04/2024": ["113", "57", "278"],
    "12/04/2024": ["270", "96", "240"],
    "13/04/2024": ["578", "01", "489"],
    "14/04/2024": ["579", "14", "112"],
    "15/04/2024": ["459", "82", "228"],
  },
  "16/04/2024 to 22/04/2024": {
    "16/04/2024": ["168", "55", "357"],
    "17/04/2024": ["770", "45", "456"],
    "18/04/2024": ["147", "25", "140"],
    "19/04/2024": ["347", "49", "180"],
    "20/04/2024": ["456", "58", "468"],
    "21/04/2024": ["129", "22", "480"],
    "22/04/2024": ["146", "19", "379"],
  },
  "23/04/2024 to 29/04/2024": {
    "23/04/2024": ["235", "01", "678"],
    "24/04/2024": ["178", "60", "127"],
    "25/04/2024": ["670", "38", "800"],
    "26/04/2024": ["235", "04", "239"],
    "27/04/2024": ["880", "60", "190"],
    "28/04/2024": ["700", "79", "568"],
    "29/04/2024": ["278", "72", "390"],
  },
  "30/04/2024 to 06/05/2024": {
    "30/04/2024": ["680", "47", "890"],
    "01/05/2024": ["145", "09", "135"],
    "02/05/2024": ["280", "08", "369"],
    "03/05/2024": ["479", "09", "135"],
    "04/05/2024": ["167", "49", "469"],
    "05/05/2024": ["334", "06", "268"],
    "06/05/2024": ["126", "94", "130"],
  },
  "07/05/2024 to 13/05/2024": {
    "02/05/2024": ["280", "08", "369"],
    "08/05/2024": ["369", "81", "560"],
    "09/05/2024": ["368", "71", "128"],
    "10/05/2024": ["179", "71", "678"],
    "11/05/2024": ["245", "18", "567"],
    "12/05/2024": ["130", "48", "189"],
    "13/05/2024": ["138", "29", "469"],
  },
  "14/05/2024 to 20/05/2024": {
    "14/05/2024": ["459", "86", "150"],
    "15/05/2024": ["236", "13", "139"],
    "16/05/2024": ["568", "97", "359"],
    "17/05/2024": ["678", "17", "458"],
    "18/05/2024": ["230", "56", "268"],
    "19/05/2024": ["189", "84", "590"],
    "20/05/2024": ["579", "16", "169"],
  },
  "21/05/2024 to 27/05/2024": {
    "21/05/2024": ["367", "64", "167"],
    "22/05/2024": ["270", "90", "235"],
    "23/05/2024": ["139", "35", "230"],
    "24/05/2024": ["367", "62", "679"],
    "25/05/2024": ["579", "18", "468"],
    "26/05/2024": ["268", "63", "346"],
    "27/05/2024": ["688", "21", "227"],
  },
  "28/05/2024 to 03/06/2024": {
    "28/05/2024": ["178", "60", "578"],
    "29/05/2024": ["350", "82", "246"],
    "30/05/2024": ["240", "69", "126"],
    "31/05/2024": ["124", "79", "450"],
    "01/06/2024": ["578", "01", "137"],
    "02/06/2024": ["350", "85", "267"],
    "03/06/2024": ["289", "98", "260"],
  },
  "04/06/2024 to 10/06/2024": {
    "04/06/2024": ["244", "05", "780"],
    "05/06/2024": ["150", "61", "227"],
    "06/06/2024": ["359", "70", "578"],
    "07/06/2024": ["269", "70", "370"],
    "08/06/2024": ["299", "03", "788"],
    "09/06/2024": ["123", "60", "578"],
    "10/06/2024": ["257", "45", "177"],
  },
  "11/06/2024 to 17/06/2024": {
    "11/06/2024": ["290", "14", "130"],
    "12/06/2024": ["358", "68", "990"],
    "13/06/2024": ["389", "00", "569"],
    "14/06/2024": ["445", "32", "200"],
    "15/06/2024": ["479", "02", "778"],
    "16/06/2024": ["190", "06", "448"],
    "17/06/2024": ["138", "22", "147"],
  },
  "18/06/2024 to 24/06/2024": {
    "18/06/2024": ["699", "43", "599"],
    "19/06/2024": ["689", "36", "259"],
    "20/06/2024": ["240", "64", "789"],
    "21/06/2024": ["179", "79", "199"],
    "22/06/2024": ["160", "79", "568"],
    "23/06/2024": ["667", "91", "579"],
    "24/06/2024": ["890", "77", "179"],
  },
  "25/06/2024 to 01/07/2024": {
    "25/06/2024": ["140", "58", "125"],
    "26/06/2024": ["460", "04", "590"],
    "27/06/2024": ["690", "50", "677"],
    "28/06/2024": ["134", "86", "349"],
    "29/06/2024": ["379", "92", "679"],
    "30/06/2024": ["380", "11", "236"],
    "01/07/2024": ["670", "30", "299"],
  },
  "02/07/2024 to 08/07/2024": {
    "02/07/2024": ["688", "28", "369"],
    "03/07/2024": ["137", "19", "289"],
    "04/07/2024": ["168", "53", "229"],
    "05/07/2024": ["239", "46", "114"],
    "06/07/2024": ["167", "47", "278"],
    "07/07/2024": ["188", "76", "367"],
    "08/07/2024": ["137", "12", "336"],
  },
  "09/07/2024 to 15/07/2024": {
    "09/07/2024": ["559", "92", "255"],
    "10/07/2024": ["457", "63", "157"],
    "11/07/2024": ["460", "07", "250"],
    "12/07/2024": ["158", "41", "245"],
    "13/07/2024": ["249", "57", "250"],
    "14/07/2024": ["128", "13", "580"],
    "15/07/2024": ["369", "81", "560"],
  },
  "16/07/2024 to 22/07/2024": {
    "16/07/2024": ["269", "79", "379"],
    "17/07/2024": ["780", "54", "167"],
    "18/07/2024": ["170", "80", "145"],
    "19/07/2024": ["489", "13", "689"],
    "20/07/2024": ["670", "37", "467"],
    "21/07/2024": ["122", "59", "559"],
    "22/07/2024": ["136", "08", "279"],
  },
  "23/07/2024 to 29/07/2024": {
    "23/07/2024": ["450", "98", "125"],
    "24/07/2024": ["260", "82", "679"],
    "25/07/2024": ["880", "64", "257"],
    "26/07/2024": ["248", "40", "280"],
    "27/07/2024": ["130", "48", "468"],
    "28/07/2024": ["267", "58", "378"],
    "29/07/2024": ["990", "89", "117"],
  },
  "30/07/2024 to 05/08/2024": {
    "30/07/2024": ["560", "15", "366"],
    "31/07/2024": ["339", "51", "227"],
    "01/08/2024": ["179", "71", "137"],
    "02/08/2024": ["170", "88", "116"],
    "03/08/2024": ["258", "59", "577"],
    "04/08/2024": ["158", "44", "770"],
    "05/08/2024": ["489", "17", "368"],
  },
  "06/08/2024 to 12/08/2024": {
    "06/08/2024": ["288", "84", "239"],
    "07/08/2024": ["479", "06", "240"],
    "08/08/2024": ["249", "58", "116"],
    "09/08/2024": ["179", "75", "168"],
    "10/08/2024": ["889", "55", "267"],
    "11/08/2024": ["560", "13", "599"],
    "12/08/2024": ["480", "26", "259"],
  },
  "13/08/2024 to 19/08/2024": {
    "13/08/2024": ["347", "46", "123"],
    "14/08/2024": ["788", "35", "168"],
    "15/08/2024": ["569", "05", "348"],
    "07/08/2024": ["479", "06", "240"],
    "17/08/2024": ["559", "97", "278"],
    "18/08/2024": ["156", "21", "128"],
    "19/08/2024": ["149", "44", "257"],
  },
  "20/08/2024 to 26/08/2024": {
    "20/08/2024": ["579", "17", "467"],
    "21/08/2024": ["688", "22", "345"],
    "22/08/2024": ["350", "85", "113"],
    "23/08/2024": ["236", "19", "225"],
    "24/08/2024": ["124", "72", "129"],
    "25/08/2024": ["244", "04", "266"],
    "26/08/2024": ["236", "10", "244"],
  },
  "27/08/2024 to 02/09/2024": {
    "27/08/2024": ["123", "65", "258"],
    "28/08/2024": ["346", "33", "490"],
    "29/08/2024": ["277", "65", "113"],
    "30/08/2024": ["770", "41", "146"],
    "31/08/2024": ["126", "92", "390"],
    "01/09/2024": ["344", "17", "458"],
    "02/09/2024": ["569", "09", "568"],
  },
  "03/09/2024 to 09/09/2024": {
    "03/09/2024": ["234", "94", "220"],
    "04/09/2024": ["122", "54", "356"],
    "05/09/2024": ["126", "98", "350"],
    "06/09/2024": ["123", "63", "166"],
    "07/09/2024": ["680", "44", "770"],
    "08/09/2024": ["134", "89", "117"],
    "09/09/2024": ["225", "94", "590"],
  },
  "10/09/2024 to 15/09/2024": {
    "10/09/2024": ["400", "43", "157"],
    "11/09/2024": ["240", "62", "778"],
    "12/09/2024": ["227", "11", "678"],
    "13/09/2024": ["500", "52", "156"],
    "14/09/2024": ["136", "02", "345"],
    "15/09/2024": ["134", "82", "147"],
    "16/09/2024": ["790", "64", "356"],
  },
};

export const INDRANIGHT = {
  "01/01/2024 to 07/01/2024": {
    "01/01/2024": ["100", "16", "448"],
    "02/01/2024": ["122", "51", "227"],
    "03/01/2024": ["589", "24", "248"],
    "04/01/2024": ["115", "71", "579"],
    "05/01/2024": ["667", "97", "160"],
    "06/01/2024": ["166", "39", "568"],
    "07/01/2024": ["246", "23", "337"],
  },
  "08/01/2024 to 14/01/2024": {
    "08/01/2024": ["148", "39", "333"],
    "09/01/2024": ["468", "88", "170"],
    "10/01/2024": ["800", "82", "444"],
    "11/01/2024": ["278", "76", "556"],
    "12/01/2024": ["134", "82", "444"],
    "13/01/2024": ["133", "78", "440"],
    "14/01/2024": ["168", "59", "225"],
  },
  "15/01/2024 to 21/01/2024": {
    "15/01/2024": ["134", "86", "457"],
    "16/01/2024": ["559", "95", "122"],
    "17/01/2024": ["334", "03", "157"],
    "18/01/2024": ["770", "43", "300"],
    "19/01/2024": ["267", "55", "357"],
    "20/01/2024": ["477", "81", "489"],
    "21/01/2024": ["250", "78", "260"],
  },
  "22/01/2024 to 28/01/2024": {
    "22/01/2024": ["600", "65", "348"],
    "23/01/2024": ["390", "22", "147"],
    "24/01/2024": ["278", "70", "569"],
    "25/01/2024": ["257", "45", "177"],
    "26/01/2024": ["280", "08", "260"],
    "27/01/2024": ["288", "82", "589"],
    "28/01/2024": ["269", "71", "588"],
  },
  "29/01/2024 to 04/02/2024": {
    "29/01/2024": ["339", "58", "666"],
    "30/01/2024": ["179", "75", "889"],
    "31/01/2024": ["660", "23", "490"],
    "01/02/2024": ["280", "09", "478"],
    "02/02/2024": ["111", "35", "889"],
    "03/02/2024": ["578", "07", "340"],
    "04/02/2024": ["256", "36", "899"],
  },
  "05/02/2024 to 11/02/2024": {
    "05/02/2024": ["556", "65", "122"],
    "06/02/2024": ["259", "68", "666"],
    "07/02/2024": ["000", "08", "558"],
    "08/02/2024": ["700", "73", "247"],
    "09/02/2024": ["499", "28", "279"],
    "10/02/2024": ["790", "60", "460"],
    "11/02/2024": ["157", "36", "240"],
  },
  "12/02/2024 to 18/02/2024": {
    "12/02/2024": ["990", "83", "445"],
    "13/02/2024": ["590", "41", "588"],
    "14/02/2024": ["223", "70", "668"],
    "15/02/2024": ["470", "16", "790"],
    "16/02/2024": ["244", "06", "358"],
    "17/02/2024": ["367", "69", "199"],
    "18/02/2024": ["599", "32", "660"],
  },
  "19/02/2024 to 25/02/2024": {
    "19/02/2024": ["889", "52", "660"],
    "20/02/2024": ["123", "62", "390"],
    "21/02/2024": ["888", "49", "333"],
    "22/02/2024": ["578", "07", "890"],
    "23/02/2024": ["488", "03", "779"],
    "24/02/2024": ["880", "67", "368"],
    "25/02/2024": ["344", "18", "477"],
  },
  "26/02/2024 to 03/03/2024": {
    "26/02/2024": ["477", "86", "790"],
    "27/02/2024": ["158", "48", "350"],
    "28/02/2024": ["138", "28", "116"],
    "29/02/2024": ["360", "94", "149"],
    "01/03/2024": ["290", "12", "499"],
    "02/03/2024": ["669", "12", "129"],
    "03/03/2024": ["289", "91", "290"],
  },
  "04/03/2024 to 10/03/2024": {
    "04/03/2024": ["116", "86", "259"],
    "05/03/2024": ["550", "08", "800"],
    "06/03/2024": ["777", "11", "128"],
    "07/03/2024": ["119", "11", "669"],
    "08/03/2024": ["227", "17", "160"],
    "09/03/2024": ["366", "55", "267"],
    "10/03/2024": ["157", "37", "458"],
  },
  "11/03/2024 to 17/03/2024": {
    "11/03/2024": ["245", "14", "590"],
    "12/03/2024": ["338", "45", "230"],
    "13/03/2024": ["666", "87", "278"],
    "14/03/2024": ["230", "58", "189"],
    "15/03/2024": ["990", "89", "559"],
    "16/03/2024": ["180", "98", "224"],
    "17/03/2024": ["150", "64", "149"],
  },
  "18/03/2024 to 24/03/2024": {
    "18/03/2024": ["666", "89", "225"],
    "19/03/2024": ["167", "45", "339"],
    "20/03/2024": ["223", "74", "167"],
    "21/03/2024": ["567", "83", "247"],
    "22/03/2024": ["168", "50", "569"],
    "23/03/2024": ["358", "62", "255"],
    "24/03/2024": ["100", "11", "290"],
  },
  "25/03/2024 to 31/03/2024": {
    "25/03/2024": ["350", "87", "359"],
    "26/03/2024": ["446", "44", "888"],
    "27/03/2024": ["990", "81", "579"],
    "28/03/2024": ["680", "43", "256"],
    "29/03/2024": ["257", "43", "157"],
    "30/03/2024": ["199", "98", "369"],
    "31/03/2024": ["339", "56", "222"],
  },
  "01/04/2024 to 07/04/2024": {
    "01/04/2024": ["377", "78", "369"],
    "02/04/2024": ["135", "91", "678"],
    "03/04/2024": ["579", "19", "333"],
    "04/04/2024": ["479", "08", "477"],
    "05/04/2024": ["660", "26", "169"],
    "06/04/2024": ["115", "73", "139"],
    "07/04/2024": ["237", "20", "488"],
  },
  "08/04/2024 to 14/04/2024": {
    "08/04/2024": ["455", "43", "346"],
    "09/04/2024": ["200", "20", "460"],
    "10/04/2024": ["478", "95", "348"],
    "11/04/2024": ["235", "07", "467"],
    "12/04/2024": ["389", "01", "380"],
    "13/04/2024": ["227", "11", "344"],
    "14/04/2024": ["189", "88", "558"],
  },
  "15/04/2024 to 21/04/2024": {
    "15/04/2024": ["112", "49", "469"],
    "16/04/2024": ["270", "90", "389"],
    "17/04/2024": ["440", "83", "580"],
    "18/04/2024": ["579", "16", "899"],
    "19/04/2024": ["138", "24", "167"],
    "20/04/2024": ["118", "04", "400"],
    "21/04/2024": ["256", "30", "460"],
  },
  "22/04/2024 to 28/04/2024": {
    "22/04/2024": ["290", "13", "346"],
    "23/04/2024": ["589", "21", "335"],
    "24/04/2024": ["788", "39", "577"],
    "25/04/2024": ["225", "99", "180"],
    "26/04/2024": ["469", "93", "580"],
    "27/04/2024": ["366", "52", "228"],
    "28/04/2024": ["345", "22", "156"],
  },
  "29/04/2024 to 05/05/2024": {
    "29/04/2024": ["130", "46", "457"],
    "30/04/2024": ["178", "60", "479"],
    "01/05/2024": ["370", "01", "137"],
    "02/05/2024": ["200", "20", "127"],
    "03/05/2024": ["359", "74", "149"],
    "04/05/2024": ["567", "84", "158"],
    "05/05/2024": ["330", "67", "890"],
  },
  "06/05/2024 to 12/05/2024": {
    "06/05/2024": ["299", "04", "257"],
    "07/05/2024": ["133", "79", "667"],
    "08/05/2024": ["134", "86", "169"],
    "09/05/2024": ["590", "41", "470"],
    "10/05/2024": ["229", "37", "269"],
    "11/05/2024": ["289", "97", "115"],
    "12/05/2024": ["136", "00", "578"],
  },
  "13/05/2024 to 19/05/2024": {
    "13/05/2024": ["558", "81", "128"],
    "14/05/2024": ["138", "26", "150"],
    "15/05/2024": ["250", "73", "166"],
    "16/05/2024": ["157", "31", "399"],
    "17/05/2024": ["126", "96", "169"],
    "18/05/2024": ["167", "43", "238"],
    "19/05/2024": ["236", "18", "477"],
  },
  "11/03/2024 to 17/03/2024": {
    "11/03/2024": ["245", "14", "590"],
    "12/03/2024": ["338", "45", "230"],
    "13/03/2024": ["666", "87", "278"],
    "14/03/2024": ["230", "58", "189"],
    "15/03/2024": ["990", "89", "559"],
    "16/03/2024": ["180", "98", "224"],
    "17/03/2024": ["150", "64", "149"],
  },
  "18/03/2024 to 24/03/2024": {
    "18/03/2024": ["666", "89", "225"],
    "19/03/2024": ["167", "45", "339"],
    "20/03/2024": ["223", "74", "167"],
    "21/03/2024": ["567", "83", "247"],
    "22/03/2024": ["168", "50", "569"],
    "23/03/2024": ["358", "62", "255"],
    "24/03/2024": ["100", "11", "290"],
  },
  "25/03/2024 to 31/03/2024": {
    "25/03/2024": ["350", "87", "359"],
    "26/03/2024": ["446", "44", "888"],
    "27/03/2024": ["990", "81", "579"],
    "28/03/2024": ["680", "43", "256"],
    "29/03/2024": ["257", "43", "157"],
    "30/03/2024": ["199", "98", "369"],
    "31/03/2024": ["339", "56", "222"],
  },
  "01/04/2024 to 07/04/2024": {
    "01/04/2024": ["377", "78", "369"],
    "02/04/2024": ["135", "91", "678"],
    "03/04/2024": ["579", "19", "333"],
    "04/04/2024": ["479", "08", "477"],
    "05/04/2024": ["660", "26", "169"],
    "06/04/2024": ["115", "73", "139"],
    "07/04/2024": ["237", "20", "488"],
  },
  "08/04/2024 to 14/04/2024": {
    "08/04/2024": ["455", "43", "346"],
    "09/04/2024": ["200", "20", "460"],
    "10/04/2024": ["478", "95", "348"],
    "11/04/2024": ["235", "07", "467"],
    "12/04/2024": ["389", "01", "380"],
    "13/04/2024": ["227", "11", "344"],
    "14/04/2024": ["189", "88", "558"],
  },
  "15/04/2024 to 21/04/2024": {
    "15/04/2024": ["112", "49", "469"],
    "16/04/2024": ["270", "90", "389"],
    "17/04/2024": ["440", "83", "580"],
    "18/04/2024": ["579", "16", "899"],
    "19/04/2024": ["138", "24", "167"],
    "20/04/2024": ["118", "04", "400"],
    "21/04/2024": ["256", "30", "460"],
  },
  "22/04/2024 to 28/04/2024": {
    "22/04/2024": ["290", "13", "346"],
    "23/04/2024": ["589", "21", "335"],
    "24/04/2024": ["788", "39", "577"],
    "25/04/2024": ["225", "99", "180"],
    "26/04/2024": ["469", "93", "580"],
    "27/04/2024": ["366", "52", "228"],
    "28/04/2024": ["345", "22", "156"],
  },
  "29/04/2024 to 05/05/2024": {
    "29/04/2024": ["130", "46", "457"],
    "30/04/2024": ["178", "60", "479"],
    "01/05/2024": ["370", "01", "137"],
    "02/05/2024": ["200", "20", "127"],
    "03/05/2024": ["359", "74", "149"],
    "04/05/2024": ["567", "84", "158"],
    "05/05/2024": ["330", "67", "890"],
  },
  "06/05/2024 to 12/05/2024": {
    "06/05/2024": ["299", "04", "257"],
    "07/05/2024": ["133", "79", "667"],
    "08/05/2024": ["134", "86", "169"],
    "09/05/2024": ["590", "41", "470"],
    "10/05/2024": ["229", "37", "269"],
    "11/05/2024": ["289", "97", "115"],
    "12/05/2024": ["136", "00", "578"],
  },
  "13/05/2024 to 19/05/2024": {
    "13/05/2024": ["558", "81", "128"],
    "14/05/2024": ["138", "26", "150"],
    "15/05/2024": ["250", "73", "166"],
    "16/05/2024": ["157", "31", "399"],
    "17/05/2024": ["126", "96", "169"],
    "18/05/2024": ["167", "43", "238"],
    "19/05/2024": ["236", "18", "477"],
  },
  "20/05/2024 to 26/05/2024": {
    "20/05/2024": ["399", "17", "467"],
    "21/05/2024": ["126", "95", "690"],
    "22/05/2024": ["480", "29", "568"],
    "23/05/2024": ["120", "34", "266"],
    "24/05/2024": ["389", "08", "468"],
    "25/05/2024": ["890", "73", "229"],
    "26/05/2024": ["567", "87", "359"],
  },
  "27/05/2024 to 02/06/2024": {
    "27/05/2024": ["589", "24", "130"],
    "28/05/2024": ["233", "86", "349"],
    "29/05/2024": ["490", "31", "119"],
    "30/05/2024": ["370", "04", "257"],
    "31/05/2024": ["790", "61", "119"],
    "01/06/2024": ["339", "52", "156"],
    "02/06/2024": ["579", "12", "499"],
  },
  "03/06/2024 to 09/06/2024": {
    "03/06/2024": ["334", "09", "559"],
    "04/06/2024": ["140", "51", "380"],
    "05/06/2024": ["126", "96", "277"],
    "06/06/2024": ["279", "82", "390"],
    "07/06/2024": ["550", "03", "157"],
    "08/06/2024": ["155", "18", "224"],
    "09/06/2024": ["890", "74", "347"],
  },
  "10/06/2024 to 16/06/2024": {
    "10/06/2024": ["470", "17", "377"],
    "11/06/2024": ["667", "92", "246"],
    "12/06/2024": ["138", "20", "370"],
    "13/06/2024": ["880", "66", "178"],
    "14/06/2024": ["579", "19", "180"],
    "15/06/2024": ["468", "83", "247"],
    "16/06/2024": ["670", "30", "127"],
  },
  "17/06/2024 to 23/06/2024": {
    "17/06/2024": ["567", "81", "380"],
    "18/06/2024": ["145", "08", "459"],
    "19/06/2024": ["689", "39", "234"],
    "20/06/2024": ["348", "51", "245"],
    "21/06/2024": ["136", "06", "349"],
    "22/06/2024": ["167", "46", "259"],
    "23/06/2024": ["780", "54", "356"],
  },
  "24/06/2024 to 30/06/2024": {
    "24/06/2024": ["670", "30", "578"],
    "25/06/2024": ["147", "26", "240"],
    "26/06/2024": ["460", "02", "156"],
    "27/06/2024": ["450", "97", "557"],
    "28/06/2024": ["377", "71", "236"],
    "29/06/2024": ["468", "84", "789"],
    "30/06/2024": ["118", "07", "566"],
  },
  "01/07/2024 to 07/07/2024": {
    "01/07/2024": ["360", "98", "189"],
    "02/07/2024": ["145", "04", "446"],
    "03/07/2024": ["337", "39", "469"],
    "04/07/2024": ["680", "42", "138"],
    "05/07/2024": ["150", "67", "340"],
    "06/07/2024": ["400", "44", "347"],
    "07/07/2024": ["270", "91", "236"],
  },
  "08/07/2024 to 14/07/2024": {
    "08/07/2024": ["790", "63", "157"],
    "09/07/2024": ["256", "32", "589"],
    "10/07/2024": ["239", "48", "170"],
    "11/07/2024": ["780", "50", "479"],
    "12/07/2024": ["180", "93", "346"],
    "13/07/2024": ["370", "07", "467"],
    "14/07/2024": ["190", "03", "779"],
  },
  "15/07/2024 to 21/07/2024": {
    "15/07/2024": ["179", "71", "678"],
    "16/07/2024": ["234", "98", "459"],
    "17/07/2024": ["149", "42", "660"],
    "18/07/2024": ["468", "81", "155"],
    "19/07/2024": ["178", "63", "689"],
    "20/07/2024": ["168", "57", "250"],
    "21/07/2024": ["289", "91", "380"],
  },
  "22/07/2024 to 28/07/2024": {
    "22/07/2024": ["459", "88", "369"],
    "23/07/2024": ["136", "06", "268"],
    "24/07/2024": ["236", "11", "678"],
    "25/07/2024": ["789", "41", "236"],
    "26/07/2024": ["450", "90", "190"],
    "27/07/2024": ["780", "55", "159"],
    "28/07/2024": ["337", "39", "469"],
  },
  "29/07/2024 to 04/08/2024": {
    "29/07/2024": ["567", "81", "380"],
    "30/07/2024": ["145", "08", "459"],
    "31/07/2024": ["689", "39", "234"],
    "01/08/2024": ["348", "51", "245"],
    "02/08/2024": ["136", "06", "349"],
    "03/08/2024": ["167", "46", "259"],
    "04/08/2024": ["780", "54", "356"],
  },
  "05/08/2024 to 11/08/2024": {
    "05/08/2024": ["778", "25", "177"],
    "06/08/2024": ["270", "93", "166"],
    "07/08/2024": ["689", "39", "126"],
    "08/08/2024": ["299", "03", "337"],
    "09/08/2024": ["145", "02", "679"],
    "10/08/2024": ["190", "09", "379"],
    "11/08/2024": ["167", "46", "259"],
  },
  "12/08/2024 to 18/08/2024": {
    "12/08/2024": ["247", "32", "778"],
    "13/08/2024": ["126", "99", "289"],
    "14/08/2024": ["128", "16", "268"],
    "15/08/2024": ["246", "25", "168"],
    "16/08/2024": ["119", "10", "136"],
    "17/08/2024": ["170", "77", "700"],
    "18/08/2024": ["134", "83", "580"],
  },
  "19/08/2024 to 25/08/2024": {
    "19/08/2024": ["236", "14", "130"],
    "20/08/2024": ["337", "31", "489"],
    "21/08/2024": ["170", "88", "189"],
    "22/08/2024": ["167", "43", "247"],
    "23/08/2024": ["255", "29", "225"],
    "24/08/2024": ["678", "12", "390"],
    "25/08/2024": ["357", "56", "114"],
  },
  "26/08/2024 to 01/09/2024": {
    "26/08/2024": ["179", "70", "000"],
    "27/08/2024": ["489", "19", "469"],
    "28/08/2024": ["580", "32", "444"],
    "29/08/2024": ["170", "84", "220"],
    "30/08/2024": ["266", "49", "289"],
    "31/08/2024": ["469", "93", "300"],
    "01/09/2024": ["588", "10", "280"],
  },
  "02/09/2024 to 08/09/2024": {
    "02/09/2024": ["227", "13", "599"],
    "03/09/2024": ["456", "58", "288"],
    "04/09/2024": ["337", "30", "479"],
    "05/09/2024": ["356", "45", "690"],
    "06/09/2024": ["199", "90", "460"],
    "07/09/2024": ["119", "18", "800"],
    "08/09/2024": ["255", "24", "770"],
  },
  "09/09/2024 to 15/09/2024": {
    "09/09/2024": ["600", "62", "147"],
    "10/09/2024": ["112", "40", "145"],
    "11/09/2024": ["288", "88", "800"],
    "12/09/2024": ["489", "15", "366"],
    "13/09/2024": ["457", "67", "377"],
    "14/09/2024": ["246", "21", "227"],
    "15/09/2024": ["467", "73", "166"],
  },
};

export const MAYADAY = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["379", "93", "670"],
    "01/02/2024": ["357", "50", "370"],
    "01/03/2024": ["336", "27", "124"],
    "01/04/2024": ["150", "65", "249"],
    "01/05/2024": ["780", "53", "670"],
    "01/06/2024": ["259", "62", "345"],
    "01/07/2024": ["780", "54", "455"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["690", "51", "344"],
    "01/09/2024": ["300", "39", "469"],
    "01/10/2024": ["390", "26", "169"],
    "01/11/2024": ["489", "10", "569"],
    "01/12/2024": ["360", "91", "380"],
    "01/13/2024": ["115", "72", "778"],
    "01/14/2024": ["880", "67", "269"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["140", "54", "789"],
    "01/16/2024": ["138", "28", "288"],
    "01/17/2024": ["250", "79", "568"],
    "01/18/2024": ["200", "29", "568"],
    "01/19/2024": ["339", "51", "678"],
    "01/20/2024": ["159", "57", "188"],
    "01/21/2024": ["268", "62", "589"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["458", "77", "890"],
    "01/23/2024": ["224", "81", "128"],
    "01/24/2024": ["356", "48", "189"],
    "01/25/2024": ["199", "93", "166"],
    "01/26/2024": ["260", "87", "269"],
    "01/27/2024": ["668", "00", "578"],
    "01/28/2024": ["367", "64", "789"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["470", "14", "248"],
    "01/30/2024": ["689", "30", "479"],
    "01/31/2024": ["234", "94", "680"],
    "02/01/2024": ["146", "14", "248"],
    "02/02/2024": ["189", "85", "159"],
    "02/03/2024": ["379", "95", "690"],
    "02/04/2024": ["360", "90", "677"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["177", "50", "136"],
    "02/06/2024": ["440", "87", "467"],
    "02/07/2024": ["778", "23", "599"],
    "02/08/2024": ["244", "04", "167"],
    "02/09/2024": ["135", "95", "113"],
    "02/10/2024": ["479", "06", "466"],
    "02/11/2024": ["234", "93", "256"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["280", "06", "330"],
    "02/13/2024": ["123", "68", "350"],
    "02/14/2024": ["122", "50", "136"],
    "02/15/2024": ["789", "42", "237"],
    "02/16/2024": ["367", "62", "345"],
    "02/17/2024": ["669", "16", "358"],
    "02/18/2024": ["226", "03", "490"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["120", "31", "380"],
    "02/20/2024": ["570", "26", "330"],
    "02/21/2024": ["115", "78", "134"],
    "02/22/2024": ["130", "49", "450"],
    "02/23/2024": ["469", "91", "489"],
    "02/24/2024": ["589", "20", "145"],
    "02/25/2024": ["246", "29", "234"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["250", "73", "670"],
    "02/27/2024": ["567", "82", "570"],
    "02/28/2024": ["124", "79", "135"],
    "02/29/2024": ["139", "37", "566"],
    "03/01/2024": ["450", "95", "690"],
    "03/02/2024": ["590", "46", "367"],
    "03/03/2024": ["900", "95", "249"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["234", "90", "677"],
    "03/05/2024": ["167", "40", "479"],
    "03/06/2024": ["359", "74", "257"],
    "03/07/2024": ["570", "28", "459"],
    "03/08/2024": ["135", "93", "229"],
    "03/09/2024": ["479", "07", "359"],
    "03/10/2024": ["367", "69", "667"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["478", "90", "136"],
    "03/12/2024": ["689", "32", "237"],
    "03/13/2024": ["268", "63", "148"],
    "03/14/2024": ["139", "37", "368"],
    "03/15/2024": ["234", "97", "269"],
    "03/16/2024": ["230", "55", "267"],
    "03/17/2024": ["557", "72", "129"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["237", "23", "300"],
    "03/19/2024": ["135", "97", "458"],
    "03/20/2024": ["577", "92", "345"],
    "03/21/2024": ["480", "28", "378"],
    "03/22/2024": ["159", "53", "599"],
    "03/23/2024": ["550", "03", "157"],
    "03/24/2024": ["128", "15", "555"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["400", "43", "157"],
    "03/26/2024": ["240", "62", "778"],
    "03/27/2024": ["227", "11", "678"],
    "03/28/2024": ["500", "52", "156"],
    "03/29/2024": ["136", "02", "345"],
    "03/30/2024": ["134", "82", "147"],
    "03/31/2024": ["790", "64", "356"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["790", "66", "349"],
    "04/02/2024": ["270", "98", "260"],
    "04/03/2024": ["470", "14", "248"],
    "04/04/2024": ["789", "40", "299"],
    "04/05/2024": ["457", "65", "168"],
    "04/06/2024": ["789", "45", "348"],
    "04/07/2024": ["559", "98", "477"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["469", "96", "358"],
    "04/09/2024": ["136", "09", "126"],
    "04/10/2024": ["138", "25", "690"],
    "04/11/2024": ["123", "61", "290"],
    "04/12/2024": ["116", "84", "158"],
    "04/13/2024": ["100", "13", "300"],
    "04/14/2024": ["259", "67", "160"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["146", "11", "245"],
    "04/16/2024": ["689", "37", "890"],
    "04/17/2024": ["235", "09", "135"],
    "04/18/2024": ["477", "88", "279"],
    "04/19/2024": ["357", "54", "149"],
    "04/20/2024": ["299", "06", "240"],
    "04/21/2024": ["225", "93", "599"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["157", "38", "459"],
    "04/23/2024": ["668", "07", "124"],
    "04/24/2024": ["244", "00", "488"],
    "04/25/2024": ["123", "65", "113"],
    "04/26/2024": ["256", "31", "380"],
    "04/27/2024": ["112", "45", "113"],
    "04/28/2024": ["478", "95", "249"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["334", "04", "455"],
    "04/30/2024": ["459", "86", "330"],
    "05/01/2024": ["357", "50", "280"],
    "05/02/2024": ["224", "80", "280"],
    "05/03/2024": ["144", "93", "256"],
    "05/04/2024": ["590", "44", "590"],
    "05/05/2024": ["440", "86", "880"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["334", "04", "455"],
    "05/07/2024": ["459", "86", "330"],
    "05/08/2024": ["357", "50", "280"],
    "05/09/2024": ["224", "80", "280"],
    "05/10/2024": ["144", "93", "256"],
    "05/11/2024": ["590", "44", "590"],
    "05/12/2024": ["440", "86", "880"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["440", "81", "335"],
    "05/14/2024": ["460", "02", "589"],
    "05/15/2024": ["179", "76", "880"],
    "05/16/2024": ["680", "44", "167"],
    "05/17/2024": ["230", "51", "146"],
    "05/18/2024": ["459", "80", "389"],
    "05/19/2024": ["557", "73", "490"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["148", "39", "360"],
    "05/21/2024": ["600", "61", "579"],
    "05/22/2024": ["127", "02", "660"],
    "05/23/2024": ["678", "10", "235"],
    "05/24/2024": ["166", "39", "577"],
    "05/25/2024": ["180", "90", "460"],
    "05/26/2024": ["228", "26", "150"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["135", "94", "347"],
    "05/28/2024": ["480", "22", "778"],
    "05/29/2024": ["157", "34", "356"],
    "05/30/2024": ["380", "19", "289"],
    "05/31/2024": ["568", "93", "346"],
    "06/01/2024": ["400", "41", "669"],
    "06/02/2024": ["668", "04", "789"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["178", "66", "457"],
    "06/04/2024": ["447", "56", "790"],
    "06/05/2024": ["160", "74", "149"],
    "06/06/2024": ["188", "75", "267"],
    "06/07/2024": ["137", "19", "135"],
    "06/08/2024": ["233", "89", "469"],
    "06/09/2024": ["468", "89", "180"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["144", "95", "177"],
    "06/11/2024": ["189", "85", "339"],
    "06/12/2024": ["455", "47", "250"],
    "06/13/2024": ["336", "20", "299"],
    "06/14/2024": ["370", "06", "899"],
    "06/15/2024": ["349", "60", "479"],
    "06/16/2024": ["134", "80", "280"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["590", "47", "458"],
    "06/18/2024": ["337", "31", "245"],
    "06/19/2024": ["170", "81", "579"],
    "06/20/2024": ["448", "65", "456"],
    "06/21/2024": ["667", "92", "156"],
    "06/22/2024": ["224", "81", "560"],
    "06/23/2024": ["250", "71", "245"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["890", "73", "120"],
    "06/25/2024": ["168", "50", "569"],
    "06/26/2024": ["400", "43", "670"],
    "06/27/2024": ["690", "51", "146"],
    "06/28/2024": ["249", "55", "159"],
    "06/29/2024": ["229", "38", "369"],
    "06/30/2024": ["480", "22", "138"],
  },
  "07/01/2024 to 07/07/2024": {
    "07/01/2024": ["279", "83", "580"],
    "07/02/2024": ["678", "18", "459"],
    "07/03/2024": ["336", "25", "140"],
    "07/04/2024": ["247", "30", "578"],
    "07/05/2024": ["124", "70", "460"],
    "07/06/2024": ["678", "14", "239"],
    "07/07/2024": ["355", "31", "489"],
  },
  "07/08/2024 to 07/14/2024": {
    "07/08/2024": ["799", "54", "680"],
    "07/09/2024": ["344", "10", "136"],
    "07/10/2024": ["158", "40", "334"],
    "07/11/2024": ["556", "68", "189"],
    "06/08/2024": ["233", "89", "469"],
    "07/13/2024": ["235", "08", "378"],
    "07/14/2024": ["379", "94", "446"],
  },
  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["127", "03", "256"],
    "07/16/2024": ["479", "08", "134"],
    "07/17/2024": ["266", "48", "440"],
    "07/18/2024": ["570", "22", "390"],
    "07/19/2024": ["260", "84", "356"],
    "07/20/2024": ["899", "62", "688"],
    "07/21/2024": ["566", "79", "270"],
  },
  "07/22/2024 to 07/28/2024": {
    "07/22/2024": ["448", "69", "379"],
    "07/23/2024": ["390", "22", "246"],
    "07/24/2024": ["119", "17", "160"],
    "07/25/2024": ["488", "08", "369"],
    "07/26/2024": ["250", "70", "145"],
    "07/27/2024": ["490", "35", "799"],
    "07/28/2024": ["127", "01", "344"],
  },
  "07/29/2024 to 08/04/2024": {
    "07/29/2024": ["459", "87", "368"],
    "07/30/2024": ["180", "96", "277"],
    "07/31/2024": ["347", "43", "337"],
    "08/01/2024": ["148", "39", "568"],
    "08/02/2024": ["125", "87", "188"],
    "08/03/2024": ["366", "51", "236"],
    "08/04/2024": ["469", "99", "270"],
  },
  "08/05/2024 to 08/11/2024": {
    "08/05/2024": ["778", "25", "177"],
    "08/06/2024": ["270", "93", "166"],
    "08/07/2024": ["689", "39", "126"],
    "08/08/2024": ["299", "03", "337"],
    "08/09/2024": ["145", "02", "679"],
    "08/10/2024": ["190", "09", "379"],
    "07/23/2024": ["390", "22", "246"],
  },
  "08/12/2024 to 08/18/2024": {
    "08/12/2024": ["337", "33", "445"],
    "08/13/2024": ["188", "77", "299"],
    "08/04/2024": ["469", "99", "270"],
    "08/10/2024": ["190", "09", "379"],
    "08/11/2024": ["689", "39", "126"],
    "08/17/2024": ["146", "11", "236"],
    "08/18/2024": ["366", "51", "236"],
  },
  "08/19/2024 to 08/25/2024": {
    "08/19/2024": ["123", "66", "114"],
    "08/20/2024": ["126", "90", "280"],
    "08/21/2024": ["188", "78", "288"],
    "08/22/2024": ["190", "09", "379"],
    "08/23/2024": ["110", "21", "236"],
    "08/24/2024": ["288", "88", "990"],
    "08/25/2024": ["470", "13", "337"],
  },
  "08/26/2024 to 09/01/2024": {
    "08/26/2024": ["124", "72", "200"],
    "08/27/2024": ["122", "55", "690"],
    "08/28/2024": ["380", "16", "169"],
    "08/29/2024": ["257", "44", "158"],
    "08/30/2024": ["120", "33", "148"],
    "08/31/2024": ["349", "61", "489"],
    "09/01/2024": ["480", "29", "469"],
  },
  "09/02/2024 to 09/08/2024": {
    "09/02/2024": ["278", "78", "279"],
    "09/03/2024": ["448", "67", "377"],
    "09/04/2024": ["170", "82", "499"],
    "09/05/2024": ["270", "93", "445"],
    "09/06/2024": ["680", "41", "335"],
    "09/07/2024": ["119", "19", "289"],
    "09/08/2024": ["178", "64", "400"],
  },
  "09/09/2024 to 09/15/2024": {
    "09/09/2024": ["239", "45", "140"],
    "09/10/2024": ["300", "38", "800"],
    "09/11/2024": ["260", "81", "155"],
    "09/12/2024": ["110", "29", "388"],
    "09/13/2024": ["225", "96", "880"],
    "09/14/2024": ["233", "84", "680"],
    "08/27/2024": ["122", "55", "690"],
  },
};
export const MAYANIGHT = {
  "01/01/2024 to 01/07/2024": {
    "01/01/2024": ["120", "31", "380"],
    "01/02/2024": ["118", "02", "480"],
    "01/03/2024": ["160", "75", "140"],
    "01/04/2024": ["280", "09", "270"],
    "01/05/2024": ["150", "64", "220"],
    "01/06/2024": ["120", "32", "480"],
    "01/07/2024": ["336", "21", "290"],
  },
  "01/08/2024 to 01/14/2024": {
    "01/08/2024": ["223", "74", "220"],
    "01/09/2024": ["245", "11", "335"],
    "01/10/2024": ["135", "94", "130"],
    "01/11/2024": ["490", "31", "137"],
    "01/12/2024": ["455", "43", "120"],
    "01/13/2024": ["390", "26", "114"],
    "01/14/2024": ["170", "89", "270"],
  },
  "01/15/2024 to 01/21/2024": {
    "01/15/2024": ["560", "12", "336"],
    "01/16/2024": ["115", "72", "480"],
    "01/17/2024": ["455", "47", "250"],
    "01/18/2024": ["240", "60", "226"],
    "01/19/2024": ["120", "33", "490"],
    "01/20/2024": ["113", "52", "336"],
    "01/21/2024": ["280", "04", "338"],
  },
  "01/22/2024 to 01/28/2024": {
    "01/22/2024": ["344", "14", "130"],
    "01/23/2024": ["133", "70", "280"],
    "01/24/2024": ["150", "68", "116"],
    "01/25/2024": ["480", "21", "227"],
    "01/26/2024": ["350", "86", "240"],
    "01/27/2024": ["225", "90", "280"],
    "01/28/2024": ["160", "73", "120"],
  },
  "01/29/2024 to 02/04/2024": {
    "01/29/2024": ["139", "32", "110"],
    "01/30/2024": ["150", "64", "130"],
    "01/31/2024": ["122", "51", "380"],
    "02/01/2024": ["390", "20", "118"],
    "02/02/2024": ["380", "12", "480"],
    "02/03/2024": ["490", "33", "157"],
    "02/04/2024": ["170", "86", "880"],
  },
  "02/05/2024 to 02/11/2024": {
    "02/05/2024": ["280", "05", "122"],
    "02/06/2024": ["110", "21", "137"],
    "02/07/2024": ["120", "36", "330"],
    "02/08/2024": ["339", "54", "220"],
    "02/09/2024": ["113", "59", "135"],
    "02/10/2024": ["240", "66", "330"],
    "02/11/2024": ["220", "43", "120"],
  },
  "02/12/2024 to 02/18/2024": {
    "02/12/2024": ["130", "47", "250"],
    "02/13/2024": ["123", "62", "110"],
    "02/14/2024": ["124", "70", "118"],
    "02/15/2024": ["440", "83", "120"],
    "02/16/2024": ["590", "45", "230"],
    "02/17/2024": ["120", "30", "280"],
    "02/18/2024": ["380", "13", "490"],
  },
  "02/19/2024 to 02/25/2024": {
    "02/19/2024": ["220", "42", "390"],
    "02/20/2024": ["334", "08", "350"],
    "02/21/2024": ["200", "23", "120"],
    "02/22/2024": ["126", "99", "270"],
    "02/23/2024": ["350", "82", "480"],
    "02/24/2024": ["270", "97", "124"],
    "02/25/2024": ["360", "91", "380"],
  },
  "02/26/2024 to 03/03/2024": {
    "02/26/2024": ["100", "10", "370"],
    "02/27/2024": ["240", "64", "112"],
    "02/28/2024": ["330", "66", "123"],
    "02/29/2024": ["240", "60", "280"],
    "03/01/2024": ["360", "98", "350"],
    "03/02/2024": ["368", "71", "146"],
    "03/03/2024": ["400", "47", "340"],
  },
  "03/04/2024 to 03/10/2024": {
    "03/04/2024": ["330", "62", "156"],
    "03/05/2024": ["580", "36", "330"],
    "03/06/2024": ["117", "91", "380"],
    "03/07/2024": ["490", "37", "160"],
    "03/08/2024": ["220", "43", "120"],
    "03/09/2024": ["440", "83", "490"],
    "03/10/2024": ["200", "25", "230"],
  },
  "03/11/2024 to 03/17/2024": {
    "03/11/2024": ["550", "09", "450"],
    "03/12/2024": ["270", "96", "330"],
    "03/13/2024": ["116", "89", "450"],
    "03/14/2024": ["123", "68", "260"],
    "03/15/2024": ["490", "32", "480"],
    "03/16/2024": ["230", "55", "780"],
    "03/17/2024": ["550", "01", "380"],
  },
  "03/18/2024 to 03/24/2024": {
    "03/18/2024": ["129", "25", "113"],
    "03/19/2024": ["230", "51", "128"],
    "03/20/2024": ["120", "31", "380"],
    "03/21/2024": ["240", "63", "490"],
    "03/22/2024": ["780", "51", "290"],
    "03/23/2024": ["110", "20", "460"],
    "03/24/2024": ["220", "47", "160"],
  },
  "03/25/2024 to 03/31/2024": {
    "03/25/2024": ["140", "55", "500"],
    "03/26/2024": ["125", "80", "190"],
    "03/27/2024": ["110", "29", "270"],
    "03/28/2024": ["127", "02", "480"],
    "03/29/2024": ["139", "33", "120"],
    "03/30/2024": ["123", "65", "140"],
    "03/31/2024": ["260", "81", "380"],
  },
  "04/01/2024 to 04/07/2024": {
    "04/01/2024": ["113", "59", "135"],
    "04/02/2024": ["112", "40", "190"],
    "04/03/2024": ["390", "24", "220"],
    "04/04/2024": ["225", "92", "110"],
    "04/05/2024": ["500", "52", "345"],
    "04/06/2024": ["260", "86", "178"],
    "04/07/2024": ["226", "02", "156"],
  },
  "04/08/2024 to 04/14/2024": {
    "04/08/2024": ["380", "16", "330"],
    "04/09/2024": ["350", "87", "160"],
    "04/10/2024": ["330", "65", "230"],
    "04/11/2024": ["690", "58", "260"],
    "04/12/2024": ["270", "95", "113"],
    "04/13/2024": ["220", "49", "225"],
    "04/14/2024": ["300", "35", "140"],
  },
  "04/15/2024 to 04/21/2024": {
    "04/15/2024": ["480", "22", "660"],
    "04/16/2024": ["550", "08", "260"],
    "04/17/2024": ["680", "44", "220"],
    "04/18/2024": ["123", "65", "230"],
    "04/19/2024": ["160", "79", "270"],
    "04/20/2024": ["580", "36", "123"],
    "04/21/2024": ["680", "42", "570"],
  },
  "04/22/2024 to 04/28/2024": {
    "04/22/2024": ["123", "64", "112"],
    "04/23/2024": ["260", "80", "280"],
    "04/24/2024": ["113", "56", "240"],
    "04/25/2024": ["550", "02", "390"],
    "04/26/2024": ["340", "78", "350"],
    "04/27/2024": ["220", "46", "150"],
    "04/28/2024": ["233", "85", "230"],
  },
  "04/29/2024 to 05/05/2024": {
    "04/29/2024": ["146", "15", "780"],
    "04/30/2024": ["350", "84", "590"],
    "05/01/2024": ["160", "77", "133"],
    "05/02/2024": ["660", "21", "470"],
    "05/03/2024": ["190", "04", "130"],
    "05/04/2024": ["100", "19", "180"],
    "05/05/2024": ["270", "94", "220"],
  },
  "05/06/2024 to 05/12/2024": {
    "05/06/2024": ["124", "79", "360"],
    "05/07/2024": ["120", "30", "370"],
    "05/08/2024": ["180", "95", "230"],
    "05/09/2024": ["330", "61", "100"],
    "05/10/2024": ["220", "42", "570"],
    "05/11/2024": ["138", "23", "247"],
    "05/12/2024": ["126", "90", "280"],
  },
  "05/13/2024 to 05/19/2024": {
    "05/13/2024": ["190", "01", "380"],
    "05/14/2024": ["400", "41", "335"],
    "05/15/2024": ["490", "38", "350"],
    "05/16/2024": ["660", "25", "140"],
    "05/17/2024": ["239", "49", "568"],
    "05/18/2024": ["168", "52", "390"],
    "05/19/2024": ["578", "06", "123"],
  },
  "05/20/2024 to 05/26/2024": {
    "05/20/2024": ["220", "44", "130"],
    "05/21/2024": ["450", "90", "370"],
    "05/22/2024": ["560", "15", "230"],
    "05/23/2024": ["114", "69", "234"],
    "05/24/2024": ["123", "65", "690"],
    "05/25/2024": ["224", "81", "146"],
    "05/26/2024": ["478", "98", "233"],
  },
  "05/27/2024 to 06/02/2024": {
    "05/27/2024": ["150", "61", "560"],
    "05/28/2024": ["117", "97", "160"],
    "05/29/2024": ["280", "03", "120"],
    "05/30/2024": ["660", "28", "350"],
    "05/31/2024": ["158", "43", "238"],
    "06/01/2024": ["780", "53", "229"],
    "06/02/2024": ["456", "57", "188"],
  },
  "06/03/2024 to 06/09/2024": {
    "06/03/2024": ["120", "37", "340"],
    "06/04/2024": ["380", "18", "170"],
    "06/05/2024": ["470", "14", "220"],
    "06/06/2024": ["260", "89", "450"],
    "06/07/2024": ["700", "77", "359"],
    "06/08/2024": ["440", "84", "789"],
    "06/09/2024": ["689", "32", "147"],
  },
  "06/10/2024 to 06/16/2024": {
    "06/10/2024": ["230", "52", "570"],
    "06/11/2024": ["225", "93", "120"],
    "06/12/2024": ["220", "49", "450"],
    "06/13/2024": ["200", "25", "140"],
    "06/14/2024": ["157", "33", "788"],
    "06/15/2024": ["340", "79", "234"],
    "06/16/2024": ["148", "38", "279"],
  },
  "06/17/2024 to 06/23/2024": {
    "06/17/2024": ["480", "27", "250"],
    "06/18/2024": ["550", "00", "190"],
    "06/19/2024": ["455", "48", "260"],
    "06/20/2024": ["160", "73", "490"],
    "06/21/2024": ["189", "81", "344"],
    "06/22/2024": ["770", "43", "256"],
    "06/23/2024": ["136", "08", "279"],
  },
  "06/24/2024 to 06/30/2024": {
    "06/24/2024": ["134", "81", "236"],
    "06/25/2024": ["456", "52", "138"],
    "06/26/2024": ["130", "49", "360"],
    "06/27/2024": ["180", "96", "268"],
    "06/28/2024": ["136", "07", "340"],
    "06/29/2024": ["113", "59", "234"],
    "06/30/2024": ["233", "80", "145"],
  },

  "07/15/2024 to 07/21/2024": {
    "07/15/2024": ["690", "51", "344"],
    "07/16/2024": ["300", "39", "469"],
    "07/17/2024": ["390", "26", "169"],
    "07/18/2024": ["489", "10", "569"],
    "07/19/2024": ["360", "91", "380"],
    "07/20/2024": ["115", "72", "778"],
    "07/21/2024": ["880", "67", "269"],
  },
  "07/22/2024 to 07/28/2024": {
    "22-Jul-2024": ["899", "60", "569"],
    "23-Jul-2024": ["900", "98", "116"],
    "24-Jul-2024": ["666", "88", "134"],
    "25-Jul-2024": ["228", "25", "168"],
    "26-Jul-2024": ["379", "90", "389"],
    "27-Jul-2024": ["456", "56", "349"],
    "28-Jul-2024": ["124", "74", "266"],
  },
  "07/29/2024 to 08/04/2024": {
    "29-Jul-2024": ["148", "33", "337"],
    "30-Jul-2024": ["667", "98", "134"],
    "31-Jul-2024": ["256", "30", "235"],
    "01-Aug-2024": ["234", "91", "146"],
    "02-Aug-2024": ["335", "16", "114"],
    "03-Aug-2024": ["580", "37", "890"],
    "04-Aug-2024": ["500", "56", "880"],
  },
  "08/05/2024 to 08/11/2024": {
    "05-Aug-2024": ["237", "25", "267"],
    "06-Aug-2024": ["120", "31", "380"],
    "07-Aug-2024": ["240", "63", "490"],
    "08-Aug-2024": ["255", "24", "220"],
    "09-Aug-2024": ["235", "03", "580"],
    "10-Aug-2024": ["300", "36", "330"],
    "11-Aug-2024": ["120", "38", "440"],
  },
  "08/12/2024 to 08/18/2024": {
    "12-Aug-2024": ["567", "82", "138"],
    "13-Aug-2024": ["100", "12", "110"],
    "14-Aug-2024": ["126", "91", "380"],
    "15-Aug-2024": ["570", "25", "500"],
    "16-Aug-2024": ["112", "44", "680"],
    "17-Aug-2024": ["560", "14", "680"],
    "18-Aug-2024": ["120", "30", "280"],
  },
  "08/19/2024 to 08/25/2024": {
    "19-Aug-2024": ["220", "44", "770"],
    "20-Aug-2024": ["350", "80", "460"],
    "21-Aug-2024": ["112", "47", "250"],
    "22-Aug-2024": ["780", "52", "679"],
    "23-Aug-2024": ["220", "44", "770"],
    "24-Aug-2024": ["350", "80", "460"],
    "25-Aug-2024": ["300", "38", "224"],
  },
  "08/26/2024 to 09/01/2024": {
    "26-Aug-2024": ["126", "90", "460"],
    "27-Aug-2024": ["550", "03", "490"],
    "28-Aug-2024": ["260", "86", "114"],
    "29-Aug-2024": ["389", "06", "880"],
    "30-Aug-2024": ["126", "90", "460"],
    "31-Aug-2024": ["550", "03", "490"],
    "01-Sep-2024": ["270", "91", "380"],
  },
  "09/02/2024 to 09/08/2024": {
    "02-Sep-2024": ["120", "30", "280"],
    "03-Sep-2024": ["150", "61", "380"],
    "04-Sep-2024": ["150", "62", "147"],
    "05-Sep-2024": ["888", "46", "330"],
    "06-Sep-2024": ["160", "70", "280"],
    "07-Sep-2024": ["780", "54", "266"],
    "08-Sep-2024": ["230", "55", "267"],
  },
  "09/09/2024 to 09/15/2024": {
    "09-Sep-2024": ["300", "38", "224"],
    "10-Sep-2024": ["366", "51", "128"],
    "11-Sep-2024": ["344", "16", "330"],
    "12-Sep-2024": ["122", "53", "580"],
    "13-Sep-2024": ["125", "81", "128"],
    "14-Sep-2024": ["200", "22", "390"],
    "15-Sep-2024": ["550", "03", "157"],
  },
};

export const SUPREMEDAY = {
  "01/01/2024 to 07/01/2024": {
    "01/01/2024": ["569", "00", "389"],
    "02/01/2024": ["240", "68", "189"],
    "03/01/2024": ["356", "46", "790"],
    "04/01/2024": ["578", "01", "128"],
    "05/01/2024": ["349", "66", "358"],
    "06/01/2024": ["568", "97", "160"],
    "07/01/2024": ["246", "28", "170"],
  },
  "08/01/2024 to 14/01/2024": {
    "08/01/2024": ["250", "79", "225"],
    "09/01/2024": ["569", "08", "440"],
    "10/01/2024": ["589", "20", "244"],
    "11/01/2024": ["458", "79", "568"],
    "12/01/2024": ["139", "36", "178"],
    "13/01/2024": ["138", "26", "123"],
    "14/01/2024": ["678", "10", "118"],
  },
  "15/01/2024 to 21/01/2024": {
    "15/01/2024": ["158", "46", "169"],
    "16/01/2024": ["247", "38", "378"],
    "17/01/2024": ["577", "97", "700"],
    "18/01/2024": ["138", "20", "235"],
    "19/01/2024": ["189", "85", "690"],
    "20/01/2024": ["179", "70", "668"],
    "21/01/2024": ["280", "06", "259"],
  },
  "22/01/2024 to 28/01/2024": {
    "22/01/2024": ["127", "09", "144"],
    "23/01/2024": ["139", "39", "180"],
    "24/01/2024": ["230", "52", "129"],
    "25/01/2024": ["560", "11", "236"],
    "01/02/2024": ["459", "81", "236"],
    "27/01/2024": ["136", "01", "344"],
    "28/01/2024": ["550", "07", "458"],
  },
  "29/01/2024 to 04/02/2024": {
    "29/01/2024": ["135", "91", "128"],
    "30/01/2024": ["120", "32", "390"],
    "31/01/2024": ["157", "35", "159"],
    "01/02/2024": ["459", "81", "236"],
    "02/02/2024": ["239", "45", "780"],
    "03/02/2024": ["566", "79", "667"],
    "04/02/2024": ["338", "45", "780"],
  },
  "05/02/2024 to 11/02/2024": {
    "05/02/2024": ["224", "86", "268"],
    "06/02/2024": ["677", "09", "199"],
    "07/02/2024": ["700", "71", "155"],
    "08/02/2024": ["289", "93", "779"],
    "09/02/2024": ["134", "82", "589"],
    "10/02/2024": ["223", "75", "267"],
    "11/02/2024": ["667", "93", "580"],
  },
  "12/02/2024 to 18/02/2024": {
    "12/02/2024": ["177", "59", "450"],
    "13/02/2024": ["889", "55", "122"],
    "14/02/2024": ["139", "33", "670"],
    "15/02/2024": ["145", "08", "134"],
    "16/02/2024": ["136", "06", "169"],
    "17/02/2024": ["236", "15", "780"],
    "18/02/2024": ["170", "81", "119"],
  },
  "19/02/2024 to 25/02/2024": {
    "19/02/2024": ["377", "74", "149"],
    "20/02/2024": ["128", "11", "344"],
    "21/02/2024": ["688", "22", "480"],
    "22/02/2024": ["167", "49", "135"],
    "23/02/2024": ["123", "65", "889"],
    "24/02/2024": ["226", "02", "480"],
    "25/02/2024": ["334", "05", "168"],
  },
  "26/02/2024 to 03/03/2024": {
    "26/02/2024": ["770", "41", "669"],
    "27/02/2024": ["779", "35", "113"],
    "28/02/2024": ["440", "86", "123"],
    "29/02/2024": ["179", "76", "899"],
    "01/03/2024": ["469", "92", "237"],
    "02/03/2024": ["560", "15", "780"],
    "03/03/2024": ["567", "86", "880"],
  },
  "04/03/2024 to 10/03/2024": {
    "04/03/2024": ["225", "96", "268"],
    "05/03/2024": ["168", "55", "267"],
    "06/03/2024": ["147", "21", "560"],
    "07/03/2024": ["469", "94", "347"],
    "08/03/2024": ["234", "92", "336"],
    "09/03/2024": ["280", "04", "590"],
    "10/03/2024": ["138", "21", "290"],
  },
  "11/03/2024 to 17/03/2024": {
    "11/03/2024": ["148", "35", "249"],
    "12/03/2024": ["160", "76", "123"],
    "13/03/2024": ["268", "64", "257"],
    "14/03/2024": ["490", "35", "500"],
    "15/03/2024": ["356", "45", "348"],
    "16/03/2024": ["229", "33", "788"],
    "17/03/2024": ["459", "84", "149"],
  },
  "18/03/2024 to 24/03/2024": {
    "18/03/2024": ["150", "67", "179"],
    "19/03/2024": ["278", "71", "128"],
    "20/03/2024": ["358", "62", "660"],
    "21/03/2024": ["133", "75", "140"],
    "22/03/2024": ["590", "40", "235"],
    "23/03/2024": ["360", "90", "127"],
    "24/03/2024": ["368", "75", "177"],
  },
  "25/03/2024 to 31/03/2024": {
    "24/04/2024": ["130", "48", "125"],
    "26/03/2024": ["228", "23", "247"],
    "27/03/2024": ["139", "34", "149"],
    "28/03/2024": ["349", "62", "110"],
    "29/03/2024": ["299", "09", "199"],
    "30/03/2024": ["350", "89", "568"],
    "31/03/2024": ["149", "43", "157"],
  },
  "01/04/2024 to 07/04/2024": {
    "01/04/2024": ["113", "54", "770"],
    "02/04/2024": ["550", "08", "288"],
    "03/04/2024": ["389", "08", "125"],
    "04/04/2024": ["112", "46", "466"],
    "05/04/2024": ["270", "91", "119"],
    "06/04/2024": ["489", "11", "137"],
    "07/04/2024": ["250", "70", "479"],
  },
  "08/04/2024 to 14/04/2024": {
    "08/04/2024": ["390", "25", "357"],
    "09/04/2024": ["178", "64", "257"],
    "10/04/2024": ["578", "02", "589"],
    "11/04/2024": ["247", "39", "135"],
    "12/04/2024": ["120", "31", "290"],
    "13/04/2024": ["245", "15", "447"],
    "14/04/2024": ["137", "13", "445"],
  },
  "15/04/2024 to 21/04/2024": {
    "15/04/2024": ["278", "75", "366"],
    "16/04/2024": ["250", "76", "330"],
    "17/04/2024": ["126", "98", "459"],
    "18/04/2024": ["199", "98", "477"],
    "19/04/2024": ["356", "41", "227"],
    "20/04/2024": ["136", "05", "339"],
    "21/04/2024": ["580", "35", "249"],
  },
  "22/04/2024 to 28/04/2024": {
    "22/04/2024": ["456", "55", "889"],
    "23/04/2024": ["700", "71", "380"],
    "24/04/2024": ["130", "48", "125"],
    "25/04/2024": ["399", "16", "790"],
    "26/04/2024": ["880", "66", "367"],
    "27/04/2024": ["190", "05", "357"],
    "28/04/2024": ["349", "61", "380"],
  },
  "29/04/2024 to 05/05/2024": {
    "29/04/2024": ["478", "98", "558"],
    "30/04/2024": ["256", "37", "458"],
    "01/05/2024": ["149", "47", "160"],
    "02/05/2024": ["678", "12", "156"],
    "03/05/2024": ["114", "66", "457"],
    "04/05/2024": ["345", "25", "168"],
    "05/05/2024": ["468", "88", "233"],
  },
  "06/05/2024 to 12/05/2024": {
    "06/05/2024": ["244", "01", "669"],
    "07/05/2024": ["670", "38", "279"],
    "08/05/2024": ["290", "12", "228"],
    "09/05/2024": ["236", "11", "227"],
    "10/05/2024": ["379", "90", "190"],
    "11/05/2024": ["126", "90", "280"],
    "12/05/2024": ["568", "97", "179"],
  },
  "13/05/2024 to 19/05/2024": {
    "13/05/2024": ["500", "55", "113"],
    "14/05/2024": ["116", "81", "227"],
    "15/05/2024": ["680", "47", "124"],
    "16/05/2024": ["156", "22", "138"],
    "17/05/2024": ["490", "38", "125"],
    "18/05/2024": ["149", "45", "230"],
    "19/05/2024": ["124", "73", "157"],
  },
  "20/05/2024 to 26/05/2024": {
    "20/05/2024": ["366", "59", "117"],
    "21/05/2024": ["458", "78", "990"],
    "22/05/2024": ["244", "06", "150"],
    "23/05/2024": ["137", "15", "168"],
    "24/05/2024": ["128", "10", "136"],
    "25/05/2024": ["679", "22", "499"],
    "26/05/2024": ["100", "10", "334"],
  },
  "27/05/2024 to 02/06/2024": {
    "27/05/2024": ["269", "77", "890"],
    "28/05/2024": ["234", "96", "114"],
    "29/05/2024": ["244", "00", "145"],
    "30/05/2024": ["449", "73", "157"],
    "31/05/2024": ["350", "84", "266"],
    "01/06/2024": ["470", "19", "559"],
    "02/06/2024": ["578", "07", "124"],
  },
  "03/06/2024 to 09/06/2024": {
    "03/06/2024": ["370", "03", "139"],
    "04/06/2024": ["278", "72", "589"],
    "05/06/2024": ["128", "11", "236"],
    "06/06/2024": ["160", "77", "179"],
    "07/06/2024": ["378", "89", "469"],
    "08/06/2024": ["789", "44", "680"],
    "09/06/2024": ["160", "79", "180"],
  },
  "10/06/2024 to 16/06/2024": {
    "10/06/2024": ["120", "33", "148"],
    "11/06/2024": ["158", "48", "134"],
    "12/06/2024": ["689", "32", "688"],
    "13/06/2024": ["890", "75", "357"],
    "14/06/2024": ["345", "26", "178"],
    "15/06/2024": ["227", "15", "267"],
    "16/06/2024": ["470", "19", "289"],
  },
  "17/06/2024 to 23/06/2024": {
    "17/06/2024": ["990", "85", "780"],
    "18/06/2024": ["679", "20", "550"],
    "19/06/2024": ["559", "94", "446"],
    "20/06/2024": ["477", "88", "125"],
    "21/06/2024": ["457", "69", "144"],
    "22/06/2024": ["188", "78", "350"],
    "23/06/2024": ["990", "88", "369"],
  },
  "24/06/2024 to 30/06/2024": {
    "24/06/2024": ["800", "88", "134"],
    "25/06/2024": ["366", "55", "348"],
    "26/06/2024": ["169", "69", "135"],
    "27/06/2024": ["668", "09", "450"],
    "28/06/2024": ["135", "98", "189"],
    "29/06/2024": ["357", "52", "237"],
    "30/06/2024": ["256", "34", "789"],
  },
  "01/07/2024 to 07/07/2024": {
    "01/07/2024": ["667", "98", "170"],
    "02/07/2024": ["138", "26", "169"],
    "03/07/2024": ["570", "26", "448"],
    "04/07/2024": ["368", "71", "146"],
    "05/07/2024": ["136", "00", "235"],
    "06/07/2024": ["469", "95", "159"],
    "07/07/2024": ["569", "02", "660"],
  },
  "08/07/2024 to 14/07/2024": {
    "08/07/2024": ["460", "08", "567"],
    "09/07/2024": ["239", "42", "480"],
    "10/07/2024": ["178", "66", "367"],
    "11/07/2024": ["223", "77", "269"],
    "12/07/2024": ["467", "77", "124"],
    "13/07/2024": ["135", "95", "456"],
    "14/07/2024": ["245", "15", "780"],
  },
  "15/07/2024 to 21/07/2024": {
    "15/07/2024": ["280", "03", "139"],
    "16/07/2024": ["600", "69", "667"],
    "17/07/2024": ["348", "54", "167"],
    "18/07/2024": ["136", "07", "160"],
    "19/07/2024": ["178", "62", "345"],
    "20/07/2024": ["990", "81", "119"],
    "21/07/2024": ["680", "45", "267"],
  },
  "22/07/2024 to 28/07/2024": {
    "22/07/2024": ["569", "03", "256"],
    "23/07/2024": ["460", "08", "558"],
    "24/07/2024": ["248", "44", "149"],
    "25/07/2024": ["569", "05", "357"],
    "26/07/2024": ["478", "92", "129"],
    "27/07/2024": ["499", "29", "270"],
    "28/07/2024": ["577", "92", "660"],
  },
  "29/07/2024 to 04/08/2024": {
    "29/07/2024": ["377", "78", "567"],
    "30/07/2024": ["257", "46", "150"],
    "31/07/2024": ["227", "12", "589"],
    "01/08/2024": ["899", "64", "158"],
    "02/08/2024": ["220", "45", "140"],
    "03/08/2024": ["118", "09", "135"],
    "04/08/2024": ["779", "37", "449"],
  },
  "05/08/2024 to 11/08/2024": {
    "05/08/2024": ["558", "88", "125"],
    "06/08/2024": ["145", "01", "128"],
    "07/08/2024": ["157", "33", "689"],
    "08/08/2024": ["460", "03", "247"],
    "09/08/2024": ["128", "16", "114"],
    "10/08/2024": ["388", "90", "370"],
    "11/08/2024": ["458", "71", "128"],
  },
  "12/08/2024 to 18/08/2024": {
    "12/08/2024": ["680", "41", "588"],
    "13/08/2024": ["145", "05", "230"],
    "14/08/2024": ["134", "83", "238"],
    "15/08/2024": ["***", "*", "***"],
    "16/08/2024": ["127", "09", "144"],
    "17/08/2024": ["456", "59", "577"],
    "18/08/2024": ["260", "84", "590"],
  },
  "19/08/2024 to 25/08/2024": {
    "19/08/2024": ["560", "14", "167"],
    "20/08/2024": ["157", "36", "600"],
    "21/08/2024": ["470", "16", "114"],
    "22/08/2024": ["560", "14", "680"],
    "23/08/2024": ["113", "52", "660"],
    "24/08/2024": ["390", "22", "246"],
    "25/08/2024": ["580", "34", "149"],
  },
  "26/08/2024 to 01/09/2024": {
    "26/08/2024": ["100", "11", "344"],
    "27/08/2024": ["689", "36", "169"],
    "28/08/2024": ["349", "60", "118"],
    "29/08/2024": ["488", "06", "114"],
    "30/08/2024": ["136", "08", "477"],
    "31/08/2024": ["124", "79", "126"],
    "01/09/2024": ["369", "83", "247"],
  },
  "02/09/2024 to 08/09/2024": {
    "02/09/2024": ["489", "12", "129"],
    "03/09/2024": ["160", "77", "368"],
    "04/09/2024": ["336", "25", "168"],
    "05/09/2024": ["468", "80", "389"],
    "06/09/2024": ["460", "08", "125"],
    "07/09/2024": ["379", "98", "189"],
    "08/09/2024": ["250", "71", "290"],
  },
  "09/09/2024 to 15/09/2024": {
    "09/09/2024": ["450", "97", "133"],
    "10/09/2024": ["119", "16", "150"],
    "11/09/2024": ["790", "65", "168"],
    "12/09/2024": ["236", "10", "460"],
    "13/09/2024": ["200", "25", "500"],
    "14/09/2024": ["188", "76", "277"],
    "15/09/2024": ["160", "70", "460"],
  },
  "16/09/2024 to 22/09/2024": {
    "16/09/2024": ["990", "89", "225"],
    "17/09/2024": ["200", "21", "560"],
    "18/09/2024": ["133", "70", "569"],
    "19/09/2024": ["350", "84", "266"],
    "20/09/2024": ["799", "54", "112"],
    "21/09/2024": ["133", "73", "580"],
    "22/09/2024": ["157", "39", "199"],
  },
  "23/09/2024 to 29/09/2024": {
    "23/09/2024": ["577", "94", "356"],
    "24/09/2024": ["770", "47", "223"],
    "25/09/2024": ["557", "79", "270"],
    "26/09/2024": ["480", "25", "230"],
    "27/09/2024": ["460", "06", "367"],
    "28/09/2024": ["670", "33", "256"],
    "29/09/2024": ["180", "90", "299"],
  },
  "30/09/2024 to 06/10/2024": {
    "30/09/2024": ["279", "88", "990"],
    "01/10/2024": ["778", "20", "118"],
    "02/10/2024": ["149", "42", "237"],
    "03/10/2024": ["257", "41", "236"],
    "04/10/2024": ["126", "98", "170"],
    "05/10/2024": ["679", "29", "450"],
    "06/10/2024": ["139", "30", "370"],
  },
  "07/10/2024 to 13/10/2024": {
    "07/10/2024": ["490", "34", "158"],
    "08/10/2024": ["190", "00", "479"],
    "09/10/2024": ["467", "79", "568"],
    "10/10/2024": ["570", "22", "200"],
    "11/10/2024": ["567", "89", "450"],
    "12/10/2024": ["136", "08", "189"],
    "13/10/2024": ["115", "77", "269"],
  },
  "14/10/2024 to 20/10/2024": {
    "14/10/2024": ["224", "81", "678"],
    "15/10/2024": ["134", "83", "337"],
    "16/10/2024": ["790", "66", "259"],
    "17/10/2024": ["238", "39", "135"],
    "18/10/2024": ["255", "20", "127"],
    "19/10/2024": ["357", "56", "277"],
    "20/10/2024": ["156", "22", "589"],
  },
};

export const SUPREMENIGHT = {
  "01/01/2024 to 07/01/2024": {
    "01/01/2024": ["137", "16", "123"],
    "02/01/2024": ["178", "61", "236"],
    "03/01/2024": ["289", "93", "256"],
    "04/01/2024": ["369", "88", "378"],
    "05/01/2024": ["280", "07", "890"],
    "06/01/2024": ["467", "73", "580"],
    "07/01/2024": ["360", "98", "567"],
  },
  "08/01/2024 to 14/01/2024": {
    "08/01/2024": ["124", "76", "268"],
    "09/01/2024": ["270", "99", "234"],
    "10/01/2024": ["356", "49", "117"],
    "11/01/2024": ["168", "58", "224"],
    "12/01/2024": ["235", "03", "157"],
    "13/01/2024": ["145", "00", "244"],
    "14/01/2024": ["578", "01", "155"],
  },
  "15/01/2024 to 21/01/2024": {
    "15/01/2024": ["256", "34", "680"],
    "16/01/2024": ["236", "11", "489"],
    "17/01/2024": ["120", "36", "169"],
    "18/01/2024": ["349", "69", "289"],
    "19/01/2024": ["450", "97", "160"],
    "20/01/2024": ["158", "48", "125"],
    "21/01/2024": ["122", "53", "139"],
  },
  "22/01/2024 to 28/01/2024": {
    "22/01/2024": ["150", "69", "199"],
    "23/01/2024": ["133", "70", "668"],
    "24/01/2024": ["450", "96", "178"],
    "25/01/2024": ["469", "90", "389"],
    "28/07/2024": ["236", "17", "449"],
    "27/01/2024": ["155", "11", "579"],
    "28/01/2024": ["469", "97", "368"],
  },
  "29/01/2024 to 04/02/2024": {
    "29/01/2024": ["124", "77", "890"],
    "30/01/2024": ["127", "07", "160"],
    "31/01/2024": ["589", "28", "134"],
    "01/02/2024": ["139", "32", "480"],
    "02/02/2024": ["567", "88", "189"],
    "03/02/2024": ["144", "91", "678"],
    "04/02/2024": ["880", "60", "145"],
  },
  "05/02/2024 to 11/02/2024": {
    "05/02/2024": ["447", "51", "290"],
    "06/02/2024": ["260", "89", "234"],
    "07/02/2024": ["446", "42", "255"],
    "08/02/2024": ["299", "01", "119"],
    "09/02/2024": ["249", "52", "778"],
    "10/02/2024": ["158", "44", "789"],
    "11/02/2024": ["156", "21", "344"],
  },
  "12/02/2024 to 18/02/2024": {
    "12/02/2024": ["445", "33", "229"],
    "13/02/2024": ["266", "42", "336"],
    "14/02/2024": ["470", "10", "235"],
    "15/02/2024": ["115", "73", "247"],
    "16/02/2024": ["126", "93", "238"],
    "17/02/2024": ["230", "55", "348"],
    "18/02/2024": ["389", "00", "280"],
  },
  "19/02/2024 to 25/02/2024": {
    "19/02/2024": ["244", "06", "123"],
    "20/02/2024": ["450", "95", "357"],
    "21/02/2024": ["250", "75", "168"],
    "22/02/2024": ["350", "87", "160"],
    "23/02/2024": ["138", "23", "166"],
    "24/02/2024": ["670", "39", "559"],
    "25/02/2024": ["179", "76", "240"],
  },
  "26/02/2024 to 03/03/2024": {
    "26/02/2024": ["190", "05", "357"],
    "27/02/2024": ["356", "41", "470"],
    "28/02/2024": ["568", "92", "147"],
    "29/02/2024": ["680", "46", "240"],
    "01/03/2024": ["789", "41", "146"],
    "02/03/2024": ["278", "73", "779"],
    "03/03/2024": ["479", "08", "440"],
  },
  "04/03/2024 to 10/03/2024": {
    "04/03/2024": ["136", "06", "457"],
    "05/03/2024": ["160", "72", "390"],
    "06/03/2024": ["460", "00", "235"],
    "07/03/2024": ["368", "73", "157"],
    "08/03/2024": ["300", "39", "135"],
    "09/03/2024": ["379", "95", "348"],
    "10/03/2024": ["189", "81", "146"],
  },
  "11/03/2024 to 17/03/2024": {
    "11/03/2024": ["225", "99", "126"],
    "12/03/2024": ["368", "76", "114"],
    "13/03/2024": ["447", "56", "466"],
    "14/03/2024": ["158", "41", "128"],
    "15/03/2024": ["248", "41", "560"],
    "16/03/2024": ["677", "02", "129"],
    "17/03/2024": ["166", "35", "780"],
  },
  "18/03/2024 to 24/03/2024": {
    "18/03/2024": ["138", "25", "168"],
    "19/03/2024": ["678", "19", "469"],
    "20/03/2024": ["579", "13", "445"],
    "21/03/2024": ["233", "87", "377"],
    "22/03/2024": ["126", "93", "580"],
    "23/03/2024": ["156", "21", "146"],
    "24/03/2024": ["570", "20", "479"],
  },
  "25/03/2024 to 31/03/2024": {
    "28/07/2024": ["236", "17", "449"],
    "26/03/2024": ["770", "46", "268"],
    "27/03/2024": ["790", "65", "690"],
    "28/03/2024": ["678", "10", "488"],
    "29/03/2024": ["670", "37", "890"],
    "30/03/2024": ["225", "98", "440"],
    "31/03/2024": ["168", "53", "120"],
  },
  "01/04/2024 to 07/04/2024": {
    "01/04/2024": ["300", "37", "449"],
    "02/04/2024": ["345", "29", "469"],
    "03/04/2024": ["268", "60", "145"],
    "04/04/2024": ["459", "80", "578"],
    "05/04/2024": ["340", "74", "257"],
    "06/04/2024": ["689", "33", "256"],
    "07/04/2024": ["455", "46", "240"],
  },
  "08/04/2024 to 14/04/2024": {
    "08/04/2024": ["456", "51", "128"],
    "09/04/2024": ["370", "07", "269"],
    "10/04/2024": ["220", "47", "566"],
    "11/04/2024": ["268", "68", "134"],
    "12/04/2024": ["178", "68", "369"],
    "13/04/2024": ["278", "75", "500"],
    "14/04/2024": ["237", "24", "130"],
  },
  "15/04/2024 to 21/04/2024": {
    "15/04/2024": ["450", "99", "270"],
    "16/04/2024": ["167", "44", "266"],
    "17/04/2024": ["160", "74", "158"],
    "18/04/2024": ["799", "55", "267"],
    "19/04/2024": ["567", "87", "124"],
    "20/04/2024": ["470", "11", "669"],
    "21/04/2024": ["278", "79", "469"],
  },
  "22/04/2024 to 28/04/2024": {
    "22/04/2024": ["123", "66", "556"],
    "23/04/2024": ["127", "05", "177"],
    "24/04/2024": ["567", "81", "128"],
    "25/04/2024": ["680", "46", "123"],
    "26/04/2024": ["138", "26", "178"],
    "27/04/2024": ["446", "45", "780"],
    "28/04/2024": ["356", "49", "180"],
  },
  "29/04/2024 to 05/05/2024": {
    "29/04/2024": ["358", "66", "600"],
    "30/04/2024": ["124", "78", "369"],
    "01/05/2024": ["337", "38", "279"],
    "02/05/2024": ["460", "09", "126"],
    "03/05/2024": ["177", "53", "689"],
    "04/05/2024": ["128", "11", "146"],
    "05/05/2024": ["590", "46", "259"],
  },
  "06/05/2024 to 12/05/2024": {
    "06/05/2024": ["480", "27", "269"],
    "07/05/2024": ["225", "90", "136"],
    "08/05/2024": ["280", "06", "556"],
    "09/05/2024": ["357", "54", "338"],
    "10/05/2024": ["440", "83", "256"],
    "11/05/2024": ["149", "40", "136"],
    "12/05/2024": ["240", "62", "570"],
  },
  "13/05/2024 to 19/05/2024": {
    "13/05/2024": ["256", "39", "135"],
    "14/05/2024": ["278", "77", "188"],
    "15/05/2024": ["155", "18", "170"],
    "16/05/2024": ["117", "95", "799"],
    "17/05/2024": ["678", "14", "789"],
    "18/05/2024": ["150", "64", "455"],
    "19/05/2024": ["559", "94", "239"],
  },
  "20/05/2024 to 26/05/2024": {
    "20/05/2024": ["228", "20", "550"],
    "21/05/2024": ["678", "12", "336"],
    "22/05/2024": ["123", "65", "230"],
    "23/05/2024": ["467", "73", "580"],
    "24/05/2024": ["112", "48", "260"],
    "25/05/2024": ["144", "97", "890"],
    "26/05/2024": ["389", "01", "137"],
  },
  "27/05/2024 to 02/06/2024": {
    "27/05/2024": ["120", "31", "128"],
    "28/05/2024": ["179", "75", "690"],
    "29/05/2024": ["459", "85", "357"],
    "30/05/2024": ["367", "66", "123"],
    "31/05/2024": ["159", "58", "369"],
    "01/06/2024": ["360", "99", "667"],
    "02/06/2024": ["147", "28", "189"],
  },
  "03/06/2024 to 09/06/2024": {
    "03/06/2024": ["449", "79", "360"],
    "04/06/2024": ["470", "12", "570"],
    "05/06/2024": ["379", "95", "690"],
    "06/06/2024": ["120", "33", "346"],
    "07/06/2024": ["248", "48", "125"],
    "08/06/2024": ["778", "20", "190"],
    "09/06/2024": ["400", "47", "223"],
  },
  "10/06/2024 to 16/06/2024": {
    "10/06/2024": ["456", "51", "678"],
    "11/06/2024": ["179", "72", "345"],
    "12/06/2024": ["146", "11", "489"],
    "13/06/2024": ["558", "84", "149"],
    "14/06/2024": ["368", "73", "670"],
    "15/06/2024": ["258", "57", "359"],
    "16/06/2024": ["135", "99", "126"],
  },
  "22/07/2024 to 28/07/2024": {
    "22/07/2024": ["238", "34", "257"],
    "23/07/2024": ["233", "89", "180"],
    "24/07/2024": ["138", "20", "299"],
    "25/07/2024": ["448", "65", "113"],
    "26/07/2024": ["149", "44", "680"],
    "27/07/2024": ["150", "63", "355"],
    "28/07/2024": ["236", "17", "449"],
  },
  "29/07/2024 to 04/08/2024": {
    "29/07/2024": ["237", "20", "118"],
    "30/07/2024": ["567", "86", "277"],
    "31/07/2024": ["690", "53", "157"],
    "01/08/2024": ["145", "02", "570"],
    "02/08/2024": ["359", "71", "344"],
    "03/08/2024": ["123", "62", "129"],
    "04/08/2024": ["260", "84", "680"],
  },
  "05/08/2024 to 11/08/2024": {
    "05/08/2024": ["336", "22", "237"],
    "06/08/2024": ["478", "90", "370"],
    "07/08/2024": ["137", "15", "168"],
    "08/08/2024": ["467", "75", "456"],
    "09/08/2024": ["178", "64", "158"],
    "10/08/2024": ["378", "88", "189"],
    "11/08/2024": ["459", "84", "590"],
  },
  "12/08/2024 to 18/08/2024": {
    "12/08/2024": ["458", "74", "338"],
    "13/08/2024": ["689", "33", "157"],
    "14/08/2024": ["146", "17", "467"],
    "01/08/2024": ["145", "02", "570"],
    "16/08/2024": ["150", "62", "480"],
    "17/08/2024": ["135", "93", "445"],
    "18/08/2024": ["290", "17", "250"],
  },
  "19/08/2024 to 25/08/2024": {
    "19/08/2024": ["267", "55", "159"],
    "20/08/2024": ["189", "85", "690"],
    "21/08/2024": ["199", "90", "118"],
    "22/08/2024": ["139", "30", "578"],
    "23/08/2024": ["148", "33", "788"],
    "24/08/2024": ["158", "41", "245"],
    "25/08/2024": ["135", "90", "299"],
  },
  "26/08/2024 to 01/09/2024": {
    "26/08/2024": ["157", "35", "780"],
    "27/08/2024": ["377", "72", "570"],
    "28/08/2024": ["560", "19", "135"],
    "29/08/2024": ["345", "27", "179"],
    "30/08/2024": ["116", "86", "457"],
    "31/08/2024": ["127", "06", "178"],
    "01/09/2024": ["279", "87", "377"],
  },
  "02/09/2024 to 08/09/2024": {
    "02/09/2024": ["447", "59", "234"],
    "03/09/2024": ["770", "44", "338"],
    "04/09/2024": ["146", "11", "100"],
    "05/09/2024": ["457", "69", "667"],
    "06/09/2024": ["157", "34", "347"],
    "07/09/2024": ["560", "13", "139"],
    "08/09/2024": ["349", "63", "120"],
  },
  "09/09/2024 to 15/09/2024": {
    "09/09/2024": ["700", "75", "168"],
    "10/09/2024": ["134", "80", "668"],
    "11/09/2024": ["347", "44", "149"],
    "12/09/2024": ["368", "73", "238"],
    "13/09/2024": ["137", "15", "456"],
    "14/09/2024": ["138", "25", "348"],
    "15/09/2024": ["689", "37", "368"],
  },
  "16/09/2024 to 22/09/2024": {
    "16/09/2024": ["178", "63", "670"],
    "17/09/2024": ["128", "18", "279"],
    "18/09/2024": ["159", "50", "136"],
    "19/09/2024": ["349", "66", "240"],
    "20/09/2024": ["479", "01", "470"],
    "21/09/2024": ["148", "36", "240"],
    "22/09/2024": ["348", "52", "589"],
  },
  "23/09/2024 to 29/09/2024": {
    "23/09/2024": ["445", "32", "246"],
    "24/09/2024": ["379", "98", "125"],
    "25/09/2024": ["377", "76", "169"],
    "26/09/2024": ["668", "01", "380"],
    "27/09/2024": ["117", "93", "166"],
    "28/09/2024": ["237", "25", "447"],
    "29/09/2024": ["350", "80", "488"],
  },
  "30/09/2024 to 06/10/2024": {
    "30/09/2024": ["278", "76", "556"],
    "01/10/2024": ["128", "14", "149"],
    "02/10/2024": ["450", "98", "134"],
    "03/10/2024": ["400", "48", "477"],
    "04/10/2024": ["570", "22", "246"],
    "05/10/2024": ["135", "91", "146"],
    "06/10/2024": ["468", "80", "569"],
  },
  "07/10/2024 to 13/10/2024": {
    "07/10/2024": ["377", "72", "660"],
    "08/10/2024": ["238", "34", "130"],
    "09/10/2024": ["446", "40", "235"],
    "10/10/2024": ["289", "96", "367"],
    "11/10/2024": ["170", "84", "590"],
    "12/10/2024": ["240", "65", "159"],
    "13/10/2024": ["155", "11", "669"],
  },
  "14/10/2024 to 20/10/2024": {
    "14/10/2024": ["579", "17", "458"],
    "15/10/2024": ["469", "93", "148"],
    "16/10/2024": ["336", "23", "599"],
    "17/10/2024": ["460", "05", "140"],
    "18/10/2024": ["446", "44", "257"],
    "19/10/2024": ["337", "39", "360"],
    "20/10/2024": ["500", "56", "899"],
  },
};

// export const SUPREMEDAY ={}

// export const SUPREMENIGHT = {};
