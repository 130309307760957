export const ShreeDevi = [
  [
    { id: 1, value: "51" },
    { id: 2, value: "78" },
    { id: 3, value: "70" },
    { id: 4, value: "67" },
    { id: 5, value: "42" },
    { id: 6, value: "06" },
    { id: 7, value: "48" },
  ],
  [
    { id: 8, value: "57" },
    { id: 9, value: "23" },
    { id: 10, value: "90" },
    { id: 11, value: "85" },
    { id: 12, value: "21" },
    { id: 13, value: "06" },
    { id: 14, value: "25" },
  ],
  [
    { id: 15, value: "68" },
    { id: 16, value: "30" },
    { id: 17, value: "17" },
    { id: 18, value: "42" },
    { id: 19, value: "67" },
    { id: 20, value: "97" },
    { id: 21, value: "59" },
  ],
  [
    { id: 22, value: "45" },
    { id: 23, value: "04" },
    { id: 24, value: "07" },
    { id: 25, value: "87" },
    { id: 26, value: "96" },
    { id: 27, value: "13" },
    { id: 28, value: "61" },
  ],
  [
    { id: 29, value: "18" },
    { id: 30, value: "80" },
    { id: 31, value: "49" },
    { id: 32, value: "69" },
    { id: 33, value: "69" },
    { id: 34, value: "81" },
    { id: 35, value: "69" },
  ],
  [
    { id: 36, value: "04" },
    { id: 37, value: "84" },
    { id: 38, value: "95" },
    { id: 39, value: "19" },
    { id: 40, value: "68" },
    { id: 41, value: "96" },
    { id: 42, value: "57" },
  ],
  [
    { id: 43, value: "97" },
    { id: 44, value: "09" },
    { id: 45, value: "59" },
    { id: 46, value: "49" },
    { id: 47, value: "15" },
    { id: 48, value: "96" },
    { id: 49, value: "48" },
  ],
  [
    { id: 50, value: "40" },
    { id: 51, value: "12" },
    { id: 52, value: "28" },
    { id: 53, value: "56" },
    { id: 54, value: "28" },
    { id: 55, value: "52" },
    { id: 56, value: "64" },
  ],
  [
    { id: 57, value: "59" },
    { id: 58, value: "44" },
    { id: 59, value: "19" },
    { id: 60, value: "74" },
    { id: 61, value: "22" },
    { id: 62, value: "59" },
    { id: 63, value: "70" },
  ],
  [
    { id: 64, value: "58" },
    { id: 65, value: "05" },
    { id: 66, value: "57" },
    { id: 67, value: "81" },
    { id: 68, value: "65" },
    { id: 69, value: "31" },
    { id: 70, value: "16" },
  ],
  [
    { id: 71, value: "94" },
    { id: 72, value: "59" },
    { id: 73, value: "85" },
    { id: 74, value: "16" },
    { id: 75, value: "86" },
    { id: 76, value: "09" },
    { id: 77, value: "39" },
  ],
  [
    { id: 78, value: "43" },
    { id: 79, value: "79" },
    { id: 80, value: "12" },
    { id: 81, value: "39" },
    { id: 82, value: "52" },
    { id: 83, value: "78" },
    { id: 84, value: "56" },
  ],
  [
    { id: 85, value: "45" },
    { id: 86, value: "54" },
    { id: 87, value: "58" },
    { id: 88, value: "87" },
    { id: 89, value: "79" },
    { id: 90, value: "30" },
    { id: 91, value: "54" },
  ],
  [
    { id: 92, value: "50" },
    { id: 93, value: "68" },
    { id: 94, value: "97" },
    { id: 95, value: "97" },
    { id: 96, value: "93" },
    { id: 97, value: "81" },
    { id: 98, value: "53" },
  ],
  [
    { id: 99, value: "76" },
    { id: 100, value: "19" },
    { id: 101, value: "05" },
    { id: 102, value: "02" },
    { id: 103, value: "97" },
    { id: 104, value: "09" },
    { id: 105, value: "54" },
  ],
  [
    { id: 106, value: "44" },
    { id: 107, value: "88" },
    { id: 108, value: "58" },
    { id: 109, value: "58" },
    { id: 110, value: "28" },
    { id: 111, value: "12" },
    { id: 112, value: "19" },
  ],
  [
    { id: 113, value: "64" },
    { id: 114, value: "58" },
    { id: 115, value: "81" },
    { id: 116, value: "37" },
    { id: 117, value: "18" },
    { id: 118, value: "34" },
    { id: 119, value: "62" },
  ],
  [
    { id: 120, value: "82" },
    { id: 121, value: "78" },
    { id: 122, value: "35" },
    { id: 123, value: "95" },
    { id: 124, value: "13" },
    { id: 125, value: "89" },
    { id: 126, value: "76" },
  ],
  [
    { id: 127, value: "88" },
    { id: 128, value: "02" },
    { id: 129, value: "36" },
    { id: 130, value: "16" },
    { id: 131, value: "22" },
    { id: 132, value: "52" },
    { id: 133, value: "85" },
  ],
  [
    { id: 134, value: "67" },
    { id: 135, value: "38" },
    { id: 136, value: "05" },
    { id: 137, value: "39" },
    { id: 138, value: "98" },
    { id: 139, value: "61" },
    { id: 140, value: "29" },
  ],
  [
    { id: 141, value: "61" },
    { id: 142, value: "04" },
    { id: 143, value: "89" },
    { id: 144, value: "43" },
    { id: 145, value: "02" },
    { id: 146, value: "61" },
    { id: 147, value: "07" },
  ],
  [
    { id: 148, value: "75" },
    { id: 149, value: "00" },
    { id: 150, value: "48" },
    { id: 151, value: "26" },
    { id: 152, value: "27" },
    { id: 153, value: "46" },
    { id: 154, value: "92" },
  ],
  [
    { id: 155, value: "49" },
    { id: 156, value: "30" },
    { id: 157, value: "76" },
    { id: 158, value: "52" },
    { id: 159, value: "22" },
    { id: 160, value: "04" },
    { id: 161, value: "09" },
  ],
  [
    { id: 162, value: "15" },
    { id: 163, value: "45" },
    { id: 164, value: "13" },
    { id: 165, value: "46" },
    { id: 166, value: "10" },
    { id: 167, value: "63" },
    { id: 168, value: "16" },
  ],
  [
    { id: 169, value: "37" },
    { id: 170, value: "92" },
    { id: 171, value: "41" },
    { id: 172, value: "85" },
    { id: 173, value: "06" },
    { id: 174, value: "82" },
    { id: 175, value: "54" },
  ],
  [
    { id: 176, value: "12" },
    { id: 177, value: "18" },
    { id: 178, value: "28" },
    { id: 179, value: "97" },
    { id: 180, value: "73" },
    { id: 181, value: "76" },
    { id: 182, value: "84" },
  ],
  [
    { id: 183, value: "51" },
    { id: 184, value: "50" },
    { id: 185, value: "54" },
    { id: 186, value: "21" },
    { id: 187, value: "49" },
    { id: 188, value: "76" },
    { id: 189, value: "57" },
  ],
  [
    { id: 190, value: "77" },
    { id: 191, value: "88" },
    { id: 192, value: "73" },
    { id: 193, value: "88" },
    { id: 194, value: "91" },
    { id: 195, value: "60" },
    { id: 196, value: "53" },
  ],
  [
    { id: 197, value: "97" },
    { id: 198, value: "10" },
    { id: 199, value: "81" },
    { id: 200, value: "69" },
    { id: 201, value: "97" },
    { id: 202, value: "57" },
    { id: 203, value: "71" },
  ],
  [
    { id: 218, value: "47" },
    { id: 219, value: "77" },
    { id: 220, value: "55" },
    { id: 221, value: "63" },
    { id: 222, value: "62" },
    { id: 223, value: "85" },
    { id: 224, value: "59" },
  ],
  [
    { id: 225, value: "13" },
    { id: 226, value: "59" },
    { id: 227, value: "14" },
    { id: 228, value: "62" },
    { id: 229, value: "60" },
    { id: 230, value: "87" },
    { id: 231, value: "06" },
  ],

  [
    { id: 204, value: "51" },
    { id: 205, value: "53" },
    { id: 206, value: "09" },
    { id: 207, value: "83" },
    { id: 208, value: "08" },
    { id: 209, value: "82" },
    { id: 210, value: "48" },
  ],
];

//  time bazar
export const TimeBazar = [
  [
    { id: 1, value: "30" },
    { id: 2, value: "33" },
    { id: 3, value: "25" },
    { id: 4, value: "56" },
    { id: 5, value: "16" },
    { id: 6, value: "31" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "98" },
    { id: 9, value: "90" },
    { id: 10, value: "53" },
    { id: 11, value: "66" },
    { id: 12, value: "08" },
    { id: 13, value: "29" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "91" },
    { id: 16, value: "25" },
    { id: 17, value: "28" },
    { id: 18, value: "60" },
    { id: 19, value: "55" },
    { id: 20, value: "43" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "41" },
    { id: 23, value: "91" },
    { id: 24, value: "33" },
    { id: 25, value: "**" },
    { id: 26, value: "96" },
    { id: 27, value: "66" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "58" },
    { id: 30, value: "89" },
    { id: 31, value: "83" },
    { id: 32, value: "07" },
    { id: 33, value: "13" },
    { id: 34, value: "48" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "98" },
    { id: 37, value: "82" },
    { id: 38, value: "94" },
    { id: 39, value: "45" },
    { id: 40, value: "44" },
    { id: 41, value: "56" },
    { id: 42, value: "**" },
  ],
  [
    { id: 43, value: "73" },
    { id: 44, value: "91" },
    { id: 45, value: "68" },
    { id: 46, value: "01" },
    { id: 47, value: "71" },
    { id: 48, value: "87" },
    { id: 49, value: "**" },
  ],
  [
    { id: 50, value: "46" },
    { id: 51, value: "11" },
    { id: 52, value: "72" },
    { id: 53, value: "27" },
    { id: 54, value: "26" },
    { id: 55, value: "42" },
    { id: 56, value: "**" },
  ],
  [
    { id: 57, value: "98" },
    { id: 58, value: "06" },
    { id: 59, value: "24" },
    { id: 60, value: "83" },
    { id: 61, value: "57" },
    { id: 62, value: "87" },
    { id: 63, value: "**" },
  ],
  [
    { id: 64, value: "84" },
    { id: 65, value: "50" },
    { id: 66, value: "**" },
    { id: 67, value: "46" },
    { id: 68, value: "53" },
    { id: 69, value: "97" },
    { id: 70, value: "**" },
  ],
  [
    { id: 71, value: "20" },
    { id: 72, value: "80" },
    { id: 73, value: "09" },
    { id: 74, value: "65" },
    { id: 75, value: "48" },
    { id: 76, value: "10" },
    { id: 77, value: "**" },
  ],
  [
    { id: 78, value: "04" },
    { id: 79, value: "14" },
    { id: 80, value: "19" },
    { id: 81, value: "20" },
    { id: 82, value: "67" },
    { id: 83, value: "58" },
    { id: 84, value: "**" },
  ],
  [
    { id: 85, value: "22" },
    { id: 86, value: "05" },
    { id: 87, value: "88" },
    { id: 88, value: "32" },
    { id: 89, value: "09" },
    { id: 90, value: "00" },
    { id: 91, value: "**" },
  ],
  [
    { id: 92, value: "58" },
    { id: 93, value: "72" },
    { id: 94, value: "73" },
    { id: 95, value: "09" },
    { id: 96, value: "37" },
    { id: 97, value: "64" },
    { id: 98, value: "**" },
  ],
  [
    { id: 99, value: "77" },
    { id: 100, value: "26" },
    { id: 101, value: "36" },
    { id: 102, value: "33" },
    { id: 103, value: "15" },
    { id: 104, value: "37" },
    { id: 105, value: "**" },
  ],
  [
    { id: 106, value: "72" },
    { id: 107, value: "34" },
    { id: 108, value: "45" },
    { id: 109, value: "73" },
    { id: 110, value: "02" },
    { id: 111, value: "03" },
    { id: 112, value: "**" },
  ],
  [
    { id: 113, value: "60" },
    { id: 114, value: "88" },
    { id: 115, value: "47" },
    { id: 116, value: "55" },
    { id: 117, value: "14" },
    { id: 118, value: "72" },
    { id: 119, value: "**" },
  ],
  [
    { id: 120, value: "73" },
    { id: 121, value: "26" },
    { id: 122, value: "40" },
    { id: 123, value: "04" },
    { id: 124, value: "25" },
    { id: 125, value: "06" },
    { id: 126, value: "**" },
  ],
  [
    { id: 127, value: "78" },
    { id: 128, value: "02" },
    { id: 129, value: "26" },
    { id: 130, value: "73" },
    { id: 131, value: "61" },
    { id: 132, value: "84" },
    { id: 133, value: "**" },
  ],
  [
    { id: 134, value: "55" },
    { id: 135, value: "64" },
    { id: 136, value: "13" },
    { id: 137, value: "23" },
    { id: 138, value: "67" },
    { id: 139, value: "05" },
    { id: 140, value: "**" },
  ],
  [
    { id: 141, value: "83" },
    { id: 142, value: "77" },
    { id: 143, value: "44" },
    { id: 144, value: "46" },
    { id: 145, value: "59" },
    { id: 146, value: "69" },
    { id: 147, value: "**" },
  ],
  [
    { id: 148, value: "41" },
    { id: 149, value: "87" },
    { id: 150, value: "79" },
    { id: 151, value: "77" },
    { id: 152, value: "11" },
    { id: 153, value: "84" },
    { id: 154, value: "**" },
  ],
  [
    { id: 155, value: "83" },
    { id: 156, value: "30" },
    { id: 157, value: "50" },
    { id: 158, value: "52" },
    { id: 159, value: "09" },
    { id: 160, value: "62" },
    { id: 161, value: "**" },
  ],
  [
    { id: 162, value: "56" },
    { id: 163, value: "28" },
    { id: 164, value: "61" },
    { id: 165, value: "04" },
    { id: 166, value: "29" },
    { id: 167, value: "75" },
    { id: 168, value: "**" },
  ],
  [
    { id: 169, value: "06" },
    { id: 170, value: "97" },
    { id: 171, value: "23" },
    { id: 172, value: "13" },
    { id: 173, value: "93" },
    { id: 174, value: "50" },
    { id: 175, value: "**" },
  ],
  [
    { id: 176, value: "10" },
    { id: 177, value: "63" },
    { id: 178, value: "68" },
    { id: 179, value: "04" },
    { id: 180, value: "54" },
    { id: 181, value: "57" },
    { id: 182, value: "**" },
  ],
  [
    { id: 183, value: "55" },
    { id: 184, value: "47" },
    { id: 185, value: "08" },
    { id: 186, value: "09" },
    { id: 187, value: "86" },
    { id: 188, value: "14" },
    { id: 189, value: "**" },
  ],
  [
    { id: 190, value: "21" },
    { id: 191, value: "20" },
    { id: 192, value: "78" },
    { id: 193, value: "24" },
    { id: 194, value: "24" },
    { id: 195, value: "03" },
    { id: 196, value: "**" },
  ],
  [
    { id: 197, value: "96" },
    { id: 198, value: "19" },
    { id: 199, value: "77" },
    { id: 200, value: "53" },
    { id: 201, value: "42" },
    { id: 202, value: "88" },
    { id: 203, value: "**" },
  ],
  [
    { id: 204, value: "90" },
    { id: 205, value: "52" },
    { id: 206, value: "11" },
    { id: 207, value: "37" },
    { id: 208, value: "20" },
    { id: 209, value: "81" },
    { id: 210, value: "**" },
  ],
  [
    { id: 211, value: "24" },
    { id: 212, value: "42" },
    { id: 213, value: "59" },
    { id: 214, value: "40" },
    { id: 215, value: "28" },
    { id: 216, value: "84" },
    { id: 217, value: "**" },
  ],
  [
    { id: 218, value: "25" },
    { id: 219, value: "77" },
    { id: 220, value: "10" },
    { id: 221, value: "10" },
    { id: 222, value: "87" },
    { id: 223, value: "32" },
    { id: 224, value: "**" },
  ],
  [
    { id: 225, value: "86" },
    { id: 226, value: "**" },
    { id: 227, value: "28" },
    { id: 228, value: "48" },
    { id: 229, value: "25" },
    { id: 230, value: "84" },
    { id: 231, value: "**" },
  ],
  [
    { id: 237, value: "**" },
    { id: 232, value: "44" },
    { id: 233, value: "79" },
    { id: 234, value: "35" },
    { id: 235, value: "59" },
    { id: 236, value: "11" },
    { id: 237, value: "**" },
  ],
  [
    { id: 238, value: "35" },
    { id: 239, value: "82" },
    { id: 240, value: "87" },
    { id: 241, value: "54" },
    { id: 242, value: "31" },
    { id: 243, value: "31" },
    { id: 244, value: "**" },
  ],
  [
    { id: 245, value: "76" },
    { id: 246, value: "40" },
    { id: 247, value: "13" },
    { id: 248, value: "62" },
    { id: 249, value: "32" },
    { id: 250, value: "97" },
    { id: 251, value: "**" },
  ],
  [
    { id: 252, value: "59" },
    { id: 253, value: "43" },
    { id: 254, value: "11" },
    { id: 255, value: "84" },
    { id: 256, value: "03" },
    { id: 257, value: "78" },
    { id: 258, value: "**" },
  ],
  [
    { id: 259, value: "57" },
    { id: 260, value: "64" },
    { id: 261, value: "16" },
    { id: 262, value: "80" },
    { id: 263, value: "81" },
    { id: 264, value: "64" },
    { id: 265, value: "**" },
  ],
  [
    { id: 266, value: "46" },
    { id: 267, value: "16" },
    { id: 268, value: "08" },
    { id: 269, value: "11" },
    { id: 270, value: "85" },
    { id: 271, value: "60" },
    { id: 272, value: "**" },
  ],
  [
    { id: 273, value: "80" },
    { id: 274, value: "85" },
    { id: 275, value: "53" },
    { id: 276, value: "84" },
    { id: 277, value: "40" },
    { id: 278, value: "94" },
    { id: 279, value: "**" },
  ],
  [
    { id: 280, value: "16" },
    { id: 281, value: "92" },
    { id: 282, value: "45" },
    { id: 283, value: "03" },
    { id: 284, value: "06" },
    { id: 285, value: "87" },
    { id: 286, value: "**" },
  ],
  [
    { id: 287, value: "03" },
    { id: 288, value: "02" },
    { id: 289, value: "18" },
    { id: 290, value: "13" },
    { id: 291, value: "79" },
    { id: 292, value: "00" },
    { id: 293, value: "**" },
  ],
  [
    { id: 294, value: "56" },
    { id: 295, value: "27" },
    { id: 296, value: "01" },
    { id: 297, value: "36" },
    { id: 298, value: "77" },
    { id: 299, value: "98" },
    { id: 300, value: "**" },
  ],
  [
    { id: 301, value: "44" },
    { id: 302, value: "01" },
    { id: 303, value: "02" },
    { id: 304, value: "78" },
    { id: 305, value: "71" },
    { id: 306, value: "67" },
    { id: 307, value: "**" },
  ],
  [
    { id: 308, value: "47" },
    { id: 309, value: "38" },
    { id: 310, value: "26" },
    { id: 311, value: "00" },
    { id: 312, value: "**" },
    { id: 313, value: "**" },
    { id: 314, value: "**" },
  ],
  [
    { id: 315, value: "**" },
    { id: 316, value: "**" },
    { id: 317, value: "**" },
    { id: 318, value: "**" },
    { id: 319, value: "**" },
    { id: 320, value: "**" },
    { id: 321, value: "**" },
  ],
  [
    { id: 322, value: "78" },
    { id: 323, value: "17" },
    { id: 324, value: "71" },
    { id: 325, value: "50" },
    { id: 326, value: "59" },
    { id: 327, value: "42" },
    { id: 328, value: "**" },
  ],
  [
    { id: 329, value: "21" },
    { id: 330, value: "44" },
    { id: 331, value: "59" },
    { id: 332, value: "03" },
    { id: 333, value: "03" },
    { id: 334, value: "34" },
    { id: 335, value: "**" },
  ],
  [
    { id: 336, value: "68" },
    { id: 337, value: "35" },
    { id: 338, value: "11" },
    { id: 339, value: "99" },
    { id: 340, value: "67" },
    { id: 341, value: "69" },
    { id: 342, value: "**" },
  ],
  [
    { id: 343, value: "33" },
    { id: 344, value: "81" },
    { id: 345, value: "72" },
    { id: 346, value: "02" },
    { id: 347, value: "99" },
    { id: 348, value: "54" },
    { id: 349, value: "**" },
  ],
  [
    { id: 350, value: "50" },
    { id: 351, value: "09" },
    { id: 352, value: "05" },
    { id: 353, value: "41" },
    { id: 354, value: "84" },
    { id: 355, value: "18" },
    { id: 356, value: "**" },
  ],
  [
    { id: 357, value: "37" },
    { id: 358, value: "92" },
    { id: 359, value: "40" },
    { id: 360, value: "22" },
    { id: 361, value: "65" },
    { id: 362, value: "27" },
    { id: 363, value: "**" },
  ],
  [
    { id: 364, value: "13" },
    { id: 365, value: "36" },
    { id: 366, value: "35" },
    { id: 367, value: "59" },
    { id: 368, value: "17" },
    { id: 369, value: "04" },
    { id: 370, value: "**" },
  ],
  [
    { id: 371, value: "35" },
    { id: 372, value: "06" },
    { id: 373, value: "47" },
    { id: 374, value: "61" },
    { id: 375, value: "23" },
    { id: 376, value: "40" },
    { id: 377, value: "**" },
  ],
  [
    { id: 378, value: "83" },
    { id: 379, value: "76" },
    { id: 380, value: "00" },
    { id: 381, value: "56" },
    { id: 382, value: "11" },
    { id: 383, value: "44" },
    { id: 384, value: "**" },
  ],
  [
    { id: 385, value: "17" },
    { id: 386, value: "85" },
    { id: 387, value: "08" },
    { id: 388, value: "03" },
    { id: 389, value: "**" },
    { id: 390, value: "**" },
    { id: 391, value: "**" },
  ],
  [
    { id: 392, value: "14" },
    { id: 393, value: "32" },
    { id: 394, value: "44" },
    { id: 395, value: "86" },
    { id: 396, value: "37" },
    { id: 397, value: "13" },
    { id: 398, value: "**" },
  ],
  [
    { id: 399, value: "97" },
    { id: 400, value: "73" },
    { id: 401, value: "16" },
    { id: 402, value: "79" },
    { id: 403, value: "05" },
    { id: 404, value: "08" },
    { id: 405, value: "**" },
  ],
  [
    { id: 406, value: "07" },
    { id: 407, value: "54" },
    { id: 408, value: "68" },
    { id: 409, value: "67" },
    { id: 410, value: "42" },
    { id: 411, value: "46" },
    { id: 412, value: "**" },
  ],
  [
    { id: 413, value: "80" },
    { id: 414, value: "71" },
    { id: 415, value: "34" },
    { id: 416, value: "94" },
    { id: 417, value: "16" },
    { id: 418, value: "65" },
    { id: 419, value: "**" },
  ],
  [
    { id: 420, value: "65" },
    { id: 421, value: "10" },
    { id: 422, value: "43" },
    { id: 423, value: "24" },
    { id: 424, value: "84" },
    { id: 425, value: "03" },
    { id: 426, value: "**" },
  ],
  [
    { id: 427, value: "94" },
    { id: 428, value: "59" },
    { id: 429, value: "72" },
    { id: 430, value: "71" },
    { id: 431, value: "80" },
    { id: 432, value: "30" },
    { id: 433, value: "**" },
  ],
  [
    { id: 434, value: "96" },
    { id: 435, value: "50" },
    { id: 436, value: "89" },
    { id: 437, value: "79" },
    { id: 438, value: "71" },
    { id: 439, value: "74" },
    { id: 440, value: "**" },
  ],
  [
    { id: 441, value: "80" },
    { id: 442, value: "61" },
    { id: 443, value: "40" },
    { id: 444, value: "12" },
    { id: 445, value: "07" },
    { id: 446, value: "59" },
    { id: 447, value: "**" },
  ],
  [
    { id: 449, value: "**" },
    { id: 450, value: "71" },
    { id: 451, value: "74" },
    { id: 452, value: "37" },
    { id: 453, value: "33" },
    { id: 454, value: "49" },
    { id: 455, value: "**" },
  ],
  [
    { id: 456, value: "24" },
    { id: 457, value: "34" },
    { id: 458, value: "53" },
    { id: 459, value: "12" },
    { id: 460, value: "29" },
    { id: 461, value: "17" },
    { id: 462, value: "**" },
  ],
  [
    { id: 463, value: "69" },
    { id: 464, value: "16" },
    { id: 465, value: "78" },
    { id: 466, value: "45" },
    { id: 467, value: "49" },
    { id: 468, value: "31" },
    { id: 469, value: "**" },
  ],
  [
    { id: 470, value: "71" },
    { id: 471, value: "31" },
    { id: 472, value: "63" },
    { id: 473, value: "78" },
    { id: 474, value: "49" },
    { id: 475, value: "82" },
    { id: 476, value: "**" },
  ],
  [
    { id: 477, value: "91" },
    { id: 478, value: "24" },
    { id: 479, value: "30" },
    { id: 480, value: "53" },
    { id: 481, value: "36" },
    { id: 482, value: "12" },
    { id: 483, value: "**" },
  ],
  [
    { id: 484, value: "20" },
    { id: 485, value: "92" },
    { id: 486, value: "62" },
    { id: 487, value: "95" },
    { id: 488, value: "52" },
    { id: 489, value: "56" },
    { id: 490, value: "**" },
  ],
  [
    { id: 491, value: "06" },
    { id: 492, value: "43" },
    { id: 493, value: "05" },
    { id: 494, value: "92" },
    { id: 495, value: "34" },
    { id: 496, value: "84" },
    { id: 497, value: "**" },
  ],
];

//  Madhur Day

export const MadhurDay = [
  [
    { id: 1, value: "**" },
    { id: 2, value: "**" },
    { id: 3, value: "**" },
    { id: 4, value: "**" },
    { id: 5, value: "**" },
    { id: 6, value: "**" },
    { id: 7, value: "92" },
  ],
  [
    { id: 8, value: "90" },
    { id: 9, value: "15" },
    { id: 10, value: "98" },
    { id: 11, value: "90" },
    { id: 12, value: "67" },
    { id: 13, value: "51" },
    { id: 14, value: "14" },
  ],
  [
    { id: 15, value: "32" },
    { id: 16, value: "77" },
    { id: 17, value: "93" },
    { id: 18, value: "89" },
    { id: 19, value: "38" },
    { id: 20, value: "32" },
    { id: 21, value: "37" },
  ],
  [
    { id: 22, value: "19" },
    { id: 23, value: "27" },
    { id: 24, value: "79" },
    { id: 25, value: "24" },
    { id: 26, value: "82" },
    { id: 27, value: "05" },
    { id: 28, value: "28" },
  ],
  [
    { id: 29, value: "03" },
    { id: 30, value: "00" },
    { id: 31, value: "23" },
    { id: 32, value: "**" },
    { id: 33, value: "63" },
    { id: 34, value: "77" },
    { id: 35, value: "66" },
  ],
  [
    { id: 36, value: "78" },
    { id: 37, value: "70" },
    { id: 38, value: "88" },
    { id: 39, value: "84" },
    { id: 40, value: "56" },
    { id: 41, value: "91" },
    { id: 42, value: "74" },
  ],
  [
    { id: 43, value: "67" },
    { id: 44, value: "95" },
    { id: 45, value: "14" },
    { id: 46, value: "00" },
    { id: 47, value: "88" },
    { id: 48, value: "20" },
    { id: 49, value: "61" },
  ],
  [
    { id: 50, value: "91" },
    { id: 51, value: "00" },
    { id: 52, value: "45" },
    { id: 53, value: "65" },
    { id: 54, value: "65" },
    { id: 55, value: "15" },
    { id: 56, value: "42" },
  ],
  [
    { id: 57, value: "84" },
    { id: 58, value: "97" },
    { id: 59, value: "77" },
    { id: 60, value: "59" },
    { id: 61, value: "56" },
    { id: 62, value: "36" },
    { id: 63, value: "70" },
  ],
  [
    { id: 64, value: "69" },
    { id: 65, value: "82" },
    { id: 66, value: "58" },
    { id: 67, value: "89" },
    { id: 68, value: "17" },
    { id: 69, value: "30" },
    { id: 70, value: "84" },
  ],
  [
    { id: 71, value: "02" },
    { id: 72, value: "68" },
    { id: 73, value: "**" },
    { id: 74, value: "13" },
    { id: 75, value: "99" },
    { id: 76, value: "75" },
    { id: 77, value: "17" },
  ],
  [
    { id: 78, value: "80" },
    { id: 79, value: "55" },
    { id: 80, value: "52" },
    { id: 81, value: "24" },
    { id: 82, value: "09" },
    { id: 83, value: "13" },
    { id: 84, value: "24" },
  ],
  [
    { id: 85, value: "35" },
    { id: 86, value: "25" },
    { id: 87, value: "32" },
    { id: 88, value: "48" },
    { id: 89, value: "37" },
    { id: 90, value: "60" },
    { id: 91, value: "29" },
  ],
  [
    { id: 92, value: "35" },
    { id: 93, value: "72" },
    { id: 94, value: "53" },
    { id: 95, value: "65" },
    { id: 96, value: "04" },
    { id: 97, value: "02" },
    { id: 98, value: "27" },
  ],
  [
    { id: 99, value: "04" },
    { id: 100, value: "40" },
    { id: 101, value: "49" },
    { id: 102, value: "52" },
    { id: 103, value: "08" },
    { id: 104, value: "80" },
    { id: 105, value: "24" },
  ],
  [
    { id: 106, value: "86" },
    { id: 107, value: "61" },
    { id: 108, value: "17" },
    { id: 109, value: "95" },
    { id: 110, value: "69" },
    { id: 111, value: "93" },
    { id: 112, value: "97" },
  ],
  [
    { id: 113, value: "76" },
    { id: 114, value: "03" },
    { id: 115, value: "35" },
    { id: 116, value: "34" },
    { id: 117, value: "02" },
    { id: 118, value: "19" },
    { id: 119, value: "93" },
  ],
  [
    { id: 120, value: "29" },
    { id: 121, value: "03" },
    { id: 122, value: "10" },
    { id: 123, value: "71" },
    { id: 124, value: "28" },
    { id: 125, value: "77" },
    { id: 126, value: "65" },
  ],
  [
    { id: 127, value: "60" },
    { id: 128, value: "13" },
    { id: 129, value: "39" },
    { id: 130, value: "17" },
    { id: 131, value: "12" },
    { id: 132, value: "42" },
    { id: 133, value: "42" },
  ],
  [
    { id: 134, value: "33" },
    { id: 135, value: "12" },
    { id: 136, value: "33" },
    { id: 137, value: "53" },
    { id: 138, value: "04" },
    { id: 139, value: "16" },
    { id: 140, value: "41" },
  ],
  [
    { id: 141, value: "34" },
    { id: 142, value: "86" },
    { id: 143, value: "32" },
    { id: 144, value: "08" },
    { id: 145, value: "42" },
    { id: 146, value: "30" },
    { id: 147, value: "56" },
  ],
  [
    { id: 148, value: "07" },
    { id: 149, value: "02" },
    { id: 150, value: "35" },
    { id: 151, value: "19" },
    { id: 152, value: "80" },
    { id: 153, value: "09" },
    { id: 154, value: "12" },
  ],
  [
    { id: 155, value: "96" },
    { id: 156, value: "64" },
    { id: 157, value: "39" },
    { id: 158, value: "81" },
    { id: 159, value: "24" },
    { id: 160, value: "28" },
    { id: 161, value: "51" },
  ],
  [
    { id: 162, value: "70" },
    { id: 163, value: "79" },
    { id: 164, value: "42" },
    { id: 165, value: "97" },
    { id: 166, value: "39" },
    { id: 167, value: "32" },
    { id: 168, value: "15" },
  ],
  [
    { id: 169, value: "27" },
    { id: 170, value: "21" },
    { id: 171, value: "07" },
    { id: 172, value: "30" },
    { id: 173, value: "31" },
    { id: 174, value: "95" },
    { id: 175, value: "73" },
  ],
  [
    { id: 176, value: "15" },
    { id: 177, value: "37" },
    { id: 178, value: "89" },
    { id: 179, value: "41" },
    { id: 180, value: "63" },
    { id: 181, value: "39" },
    { id: 182, value: "86" },
  ],
  [
    { id: 183, value: "91" },
    { id: 184, value: "95" },
    { id: 185, value: "22" },
    { id: 186, value: "62" },
    { id: 187, value: "22" },
    { id: 188, value: "79" },
    { id: 189, value: "28" },
  ],
  [
    { id: 190, value: "46" },
    { id: 191, value: "37" },
    { id: 192, value: "44" },
    { id: 193, value: "99" },
    { id: 194, value: "80" },
    { id: 195, value: "84" },
    { id: 196, value: "08" },
  ],
  [
    { id: 197, value: "29" },
    { id: 198, value: "17" },
    { id: 199, value: "03" },
    { id: 200, value: "67" },
    { id: 201, value: "25" },
    { id: 202, value: "40" },
    { id: 203, value: "61" },
  ],
  [
    { id: 204, value: "98" },
    { id: 205, value: "61" },
    { id: 206, value: "68" },
    { id: 207, value: "88" },
    { id: 208, value: "99" },
    { id: 209, value: "67" },
    { id: 210, value: "09" },
  ],
  [
    { id: 211, value: "19" },
    { id: 212, value: "52" },
    { id: 213, value: "52" },
    { id: 214, value: "82" },
    { id: 215, value: "97" },
    { id: 216, value: "42" },
    { id: 217, value: "78" },
  ],
  [
    { id: 218, value: "79" },
    { id: 219, value: "61" },
    { id: 220, value: "23" },
    { id: 221, value: "20" },
    { id: 222, value: "88" },
    { id: 223, value: "51" },
    { id: 224, value: "51" },
  ],
  [
    { id: 225, value: "73" },
    { id: 226, value: "66" },
    { id: 227, value: "15" },
    { id: 228, value: "10" },
    { id: 229, value: "12" },
    { id: 230, value: "43" },
    { id: 231, value: "69" },
  ],
  [
    { id: 232, value: "22" },
    { id: 233, value: "**" },
    { id: 234, value: "10" },
    { id: 235, value: "69" },
    { id: 236, value: "67" },
    { id: 237, value: "23" },
    { id: 238, value: "67" },
  ],
  [
    { id: 239, value: "96" },
    { id: 240, value: "94" },
    { id: 241, value: "66" },
    { id: 242, value: "00" },
    { id: 243, value: "93" },
    { id: 244, value: "02" },
    { id: 245, value: "59" },
  ],
  [
    { id: 246, value: "27" },
    { id: 247, value: "40" },
    { id: 248, value: "82" },
    { id: 249, value: "54" },
    { id: 250, value: "43" },
    { id: 251, value: "46" },
    { id: 252, value: "20" },
  ],
  [
    { id: 253, value: "92" },
    { id: 254, value: "89" },
    { id: 255, value: "17" },
    { id: 256, value: "17" },
    { id: 257, value: "85" },
    { id: 258, value: "79" },
    { id: 259, value: "88" },
  ],
  [
    { id: 260, value: "31" },
    { id: 261, value: "77" },
    { id: 262, value: "53" },
    { id: 263, value: "58" },
    { id: 264, value: "93" },
    { id: 265, value: "71" },
    { id: 266, value: "03" },
  ],
  [
    { id: 267, value: "05" },
    { id: 268, value: "60" },
    { id: 269, value: "59" },
    { id: 270, value: "92" },
    { id: 271, value: "97" },
    { id: 272, value: "22" },
    { id: 273, value: "29" },
  ],
  [
    { id: 274, value: "01" },
    { id: 275, value: "93" },
    { id: 276, value: "08" },
    { id: 277, value: "42" },
    { id: 278, value: "14" },
    { id: 279, value: "67" },
    { id: 280, value: "54" },
  ],
  [
    { id: 281, value: "15" },
    { id: 282, value: "14" },
    { id: 283, value: "21" },
    { id: 284, value: "86" },
    { id: 285, value: "13" },
    { id: 286, value: "56" },
    { id: 287, value: "13" },
  ],
  [
    { id: 288, value: "06" },
    { id: 289, value: "42" },
    { id: 290, value: "28" },
    { id: 291, value: "58" },
    { id: 292, value: "48" },
    { id: 293, value: "69" },
    { id: 294, value: "34" },
  ],
  [
    { id: 295, value: "06" },
    { id: 296, value: "93" },
    { id: 297, value: "54" },
    { id: 298, value: "91" },
    { id: 299, value: "59" },
    { id: 300, value: "24" },
    { id: 301, value: "23" },
  ],
  [
    { id: 302, value: "06" },
    { id: 303, value: "42" },
    { id: 304, value: "41" },
    { id: 305, value: "34" },
    { id: 306, value: "48" },
    { id: 307, value: "02" },
    { id: 308, value: "80" },
  ],
  [
    { id: 309, value: "46" },
    { id: 310, value: "04" },
    { id: 311, value: "64" },
    { id: 312, value: "60" },
    { id: 313, value: "26" },
    { id: 314, value: "35" },
    { id: 315, value: "85" },
  ],
  [
    { id: 316, value: "70" },
    { id: 317, value: "43" },
    { id: 318, value: "68" },
    { id: 319, value: "07" },
    { id: 320, value: "37" },
    { id: 321, value: "**" },
    { id: 322, value: "**" },
  ],
  [
    { id: 323, value: "**" },
    { id: 324, value: "**" },
    { id: 325, value: "**" },
    { id: 326, value: "**" },
    { id: 327, value: "**" },
    { id: 328, value: "**" },
    { id: 329, value: "**" },
  ],
  [
    { id: 330, value: "71" },
    { id: 331, value: "11" },
    { id: 332, value: "68" },
    { id: 333, value: "29" },
    { id: 334, value: "91" },
    { id: 335, value: "51" },
    { id: 336, value: "42" },
  ],
  [
    { id: 337, value: "23" },
    { id: 338, value: "88" },
    { id: 339, value: "07" },
    { id: 340, value: "49" },
    { id: 341, value: "45" },
    { id: 342, value: "70" },
    { id: 343, value: "90" },
  ],
  [
    { id: 344, value: "49" },
    { id: 345, value: "56" },
    { id: 346, value: "22" },
    { id: 347, value: "91" },
    { id: 348, value: "81" },
    { id: 349, value: "44" },
    { id: 350, value: "20" },
  ],
  [
    { id: 351, value: "22" },
    { id: 352, value: "67" },
    { id: 353, value: "68" },
    { id: 354, value: "72" },
    { id: 355, value: "81" },
    { id: 356, value: "12" },
    { id: 357, value: "86" },
  ],
  [
    { id: 358, value: "98" },
    { id: 359, value: "50" },
    { id: 360, value: "95" },
    { id: 361, value: "08" },
    { id: 362, value: "03" },
    { id: 363, value: "95" },
    { id: 364, value: "95" },
  ],
  [
    { id: 365, value: "15" },
    { id: 366, value: "05" },
    { id: 367, value: "42" },
    { id: 368, value: "21" },
    { id: 369, value: "20" },
    { id: 370, value: "82" },
    { id: 371, value: "11" },
  ],
  [
    { id: 372, value: "93" },
    { id: 373, value: "82" },
    { id: 374, value: "44" },
    { id: 375, value: "69" },
    { id: 376, value: "60" },
    { id: 377, value: "29" },
    { id: 378, value: "64" },
  ],
  [
    { id: 379, value: "75" },
    { id: 380, value: "73" },
    { id: 381, value: "88" },
    { id: 382, value: "95" },
    { id: 383, value: "01" },
    { id: 384, value: "21" },
    { id: 385, value: "76" },
  ],
  [
    { id: 386, value: "12" },
    { id: 387, value: "85" },
    { id: 388, value: "16" },
    { id: 389, value: "23" },
    { id: 390, value: "42" },
    { id: 391, value: "01" },
    { id: 392, value: "38" },
  ],
  [
    { id: 393, value: "01" },
    { id: 394, value: "30" },
    { id: 395, value: "91" },
    { id: 396, value: "97" },
    { id: 397, value: "**" },
    { id: 398, value: "40" },
    { id: 399, value: "48" },
  ],
  [
    { id: 400, value: "14" },
    { id: 401, value: "32" },
    { id: 402, value: "14" },
    { id: 403, value: "47" },
    { id: 404, value: "66" },
    { id: 405, value: "83" },
    { id: 406, value: "10" },
  ],
  [
    { id: 407, value: "88" },
    { id: 408, value: "27" },
    { id: 409, value: "21" },
    { id: 410, value: "24" },
    { id: 411, value: "59" },
    { id: 412, value: "09" },
    { id: 413, value: "27" },
  ],
  [
    { id: 414, value: "72" },
    { id: 415, value: "63" },
    { id: 416, value: "10" },
    { id: 417, value: "08" },
    { id: 418, value: "24" },
    { id: 419, value: "81" },
    { id: 420, value: "35" },
  ],
  [
    { id: 421, value: "31" },
    { id: 422, value: "80" },
    { id: 423, value: "17" },
    { id: 424, value: "49" },
    { id: 425, value: "42" },
    { id: 426, value: "88" },
    { id: 427, value: "86" },
  ],
  [
    { id: 428, value: "34" },
    { id: 429, value: "44" },
    { id: 430, value: "30" },
    { id: 431, value: "05" },
    { id: 432, value: "88" },
    { id: 433, value: "02" },
    { id: 434, value: "64" },
  ],
  [
    { id: 435, value: "34" },
    { id: 436, value: "10" },
    { id: 437, value: "62" },
    { id: 438, value: "59" },
    { id: 439, value: "19" },
    { id: 440, value: "98" },
    { id: 441, value: "66" },
  ],
  [
    { id: 442, value: "80" },
    { id: 443, value: "16" },
    { id: 444, value: "69" },
    { id: 445, value: "32" },
    { id: 446, value: "94" },
    { id: 447, value: "56" },
    { id: 448, value: "44" },
  ],
  [
    { id: 449, value: "11" },
    { id: 450, value: "65" },
    { id: 451, value: "80" },
    { id: 452, value: "16" },
    { id: 453, value: "13" },
    { id: 454, value: "73" },
    { id: 455, value: "54" },
  ],
  [
    { id: 456, value: "**" },
    { id: 457, value: "91" },
    { id: 458, value: "41" },
    { id: 459, value: "03" },
    { id: 460, value: "09" },
    { id: 461, value: "93" },
    { id: 462, value: "07" },
  ],
  [
    { id: 463, value: "50" },
    { id: 464, value: "71" },
    { id: 465, value: "77" },
    { id: 466, value: "94" },
    { id: 467, value: "87" },
    { id: 468, value: "77" },
    { id: 469, value: "80" },
  ],
  [
    { id: 470, value: "90" },
    { id: 471, value: "71" },
    { id: 472, value: "69" },
    { id: 473, value: "08" },
    { id: 474, value: "82" },
    { id: 475, value: "81" },
    { id: 476, value: "62" },
  ],
  [
    { id: 477, value: "27" },
    { id: 478, value: "23" },
    { id: 479, value: "42" },
    { id: 480, value: "99" },
    { id: 481, value: "68" },
    { id: 482, value: "97" },
    { id: 483, value: "79" },
  ],
  [
    { id: 484, value: "01" },
    { id: 485, value: "36" },
    { id: 486, value: "26" },
    { id: 487, value: "48" },
    { id: 488, value: "05" },
    { id: 489, value: "08" },
    { id: 490, value: "27" },
  ],
  [
    { id: 491, value: "42" },
    { id: 492, value: "10" },
    { id: 493, value: "51" },
    { id: 494, value: "30" },
    { id: 495, value: "66" },
    { id: 496, value: "93" },
    { id: 497, value: "24" },
  ],
  [
    { id: 498, value: "53" },
    { id: 499, value: "58" },
    { id: 500, value: "03" },
    { id: 501, value: "81" },
    { id: 502, value: "81" },
    { id: 503, value: "63" },
    { id: 504, value: "80" },
  ],
];

export const MadhurNight = [
  [
    { id: 1, value: "32" },
    { id: 2, value: "93" },
    { id: 3, value: "26" },
    { id: 4, value: "98" },
    { id: 5, value: "77" },
    { id: 6, value: "73" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "17" },
    { id: 9, value: "76" },
    { id: 10, value: "98" },
    { id: 11, value: "88" },
    { id: 12, value: "04" },
    { id: 13, value: "47" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "72" },
    { id: 16, value: "69" },
    { id: 17, value: "31" },
    { id: 18, value: "15" },
    { id: 19, value: "20" },
    { id: 20, value: "16" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "12" },
    { id: 23, value: "01" },
    { id: 24, value: "10" },
    { id: 25, value: "**" },
    { id: 26, value: "36" },
    { id: 27, value: "03" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "12" },
    { id: 30, value: "61" },
    { id: 31, value: "45" },
    { id: 32, value: "40" },
    { id: 33, value: "38" },
    { id: 34, value: "44" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "66" },
    { id: 37, value: "88" },
    { id: 38, value: "45" },
    { id: 39, value: "86" },
    { id: 40, value: "06" },
    { id: 41, value: "71" },
    { id: 42, value: "**" },
  ],
  [
    { id: 1, value: "32" },
    { id: 2, value: "93" },
    { id: 3, value: "26" },
    { id: 4, value: "98" },
    { id: 5, value: "77" },
    { id: 6, value: "73" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "17" },
    { id: 9, value: "76" },
    { id: 10, value: "98" },
    { id: 11, value: "88" },
    { id: 12, value: "04" },
    { id: 13, value: "47" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "72" },
    { id: 16, value: "69" },
    { id: 17, value: "31" },
    { id: 18, value: "15" },
    { id: 19, value: "20" },
    { id: 20, value: "16" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "12" },
    { id: 23, value: "01" },
    { id: 24, value: "10" },
    { id: 25, value: "**" },
    { id: 26, value: "36" },
    { id: 27, value: "03" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "12" },
    { id: 30, value: "61" },
    { id: 31, value: "45" },
    { id: 32, value: "40" },
    { id: 33, value: "38" },
    { id: 34, value: "44" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "66" },
    { id: 37, value: "88" },
    { id: 38, value: "45" },
    { id: 39, value: "86" },
    { id: 40, value: "06" },
    { id: 41, value: "71" },
    { id: 42, value: "**" },
  ],
  [
    { id: 43, value: "50" },
    { id: 44, value: "49" },
    { id: 45, value: "84" },
    { id: 46, value: "52" },
    { id: 47, value: "71" },
    { id: 48, value: "24" },
    { id: 49, value: "**" },
  ],
  [
    { id: 50, value: "23" },
    { id: 51, value: "31" },
    { id: 52, value: "07" },
    { id: 53, value: "05" },
    { id: 54, value: "37" },
    { id: 55, value: "50" },
    { id: 56, value: "**" },
  ],
  [
    { id: 57, value: "16" },
    { id: 58, value: "82" },
    { id: 59, value: "15" },
    { id: 60, value: "42" },
    { id: 61, value: "62" },
    { id: 62, value: "49" },
    { id: 63, value: "**" },
  ],
  [
    { id: 64, value: "16" },
    { id: 65, value: "82" },
    { id: 66, value: "**" },
    { id: 67, value: "32" },
    { id: 68, value: "90" },
    { id: 69, value: "79" },
    { id: 70, value: "**" },
  ],
  [
    { id: 71, value: "83" },
    { id: 72, value: "78" },
    { id: 73, value: "25" },
    { id: 74, value: "75" },
    { id: 75, value: "59" },
    { id: 76, value: "88" },
    { id: 77, value: "**" },
  ],
  [
    { id: 78, value: "01" },
    { id: 79, value: "25" },
    { id: 80, value: "43" },
    { id: 81, value: "58" },
    { id: 82, value: "84" },
    { id: 83, value: "21" },
    { id: 84, value: "**" },
  ],
  [
    { id: 85, value: "40" },
    { id: 86, value: "56" },
    { id: 87, value: "20" },
    { id: 88, value: "44" },
    { id: 89, value: "62" },
    { id: 90, value: "40" },
    { id: 91, value: "**" },
  ],
  [
    { id: 92, value: "07" },
    { id: 93, value: "45" },
    { id: 94, value: "40" },
    { id: 95, value: "04" },
    { id: 96, value: "97" },
    { id: 97, value: "80" },
    { id: 98, value: "**" },
  ],
  [
    { id: 99, value: "95" },
    { id: 100, value: "92" },
    { id: 101, value: "51" },
    { id: 102, value: "79" },
    { id: 103, value: "31" },
    { id: 104, value: "56" },
    { id: 105, value: "**" },
  ],
  [
    { id: 1, value: "32" },
    { id: 2, value: "93" },
    { id: 3, value: "26" },
    { id: 4, value: "98" },
    { id: 5, value: "77" },
    { id: 6, value: "73" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "17" },
    { id: 9, value: "76" },
    { id: 10, value: "98" },
    { id: 11, value: "88" },
    { id: 12, value: "04" },
    { id: 13, value: "47" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "72" },
    { id: 16, value: "69" },
    { id: 17, value: "31" },
    { id: 18, value: "15" },
    { id: 19, value: "20" },
    { id: 20, value: "16" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "12" },
    { id: 23, value: "01" },
    { id: 24, value: "10" },
    { id: 25, value: "**" },
    { id: 26, value: "36" },
    { id: 27, value: "03" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "12" },
    { id: 30, value: "61" },
    { id: 31, value: "45" },
    { id: 32, value: "40" },
    { id: 33, value: "38" },
    { id: 34, value: "44" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "66" },
    { id: 37, value: "88" },
    { id: 38, value: "45" },
    { id: 39, value: "86" },
    { id: 40, value: "06" },
    { id: 41, value: "71" },
    { id: 42, value: "**" },
  ],
  [
    { id: 43, value: "50" },
    { id: 44, value: "49" },
    { id: 45, value: "84" },
    { id: 46, value: "52" },
    { id: 47, value: "71" },
    { id: 48, value: "24" },
    { id: 49, value: "**" },
  ],
  [
    { id: 50, value: "23" },
    { id: 51, value: "31" },
    { id: 52, value: "07" },
    { id: 53, value: "05" },
    { id: 54, value: "37" },
    { id: 55, value: "50" },
    { id: 56, value: "**" },
  ],
  [
    { id: 57, value: "16" },
    { id: 58, value: "82" },
    { id: 59, value: "15" },
    { id: 60, value: "42" },
    { id: 61, value: "62" },
    { id: 62, value: "49" },
    { id: 63, value: "**" },
  ],
  [
    { id: 64, value: "16" },
    { id: 65, value: "82" },
    { id: 66, value: "**" },
    { id: 67, value: "32" },
    { id: 68, value: "90" },
    { id: 69, value: "79" },
    { id: 70, value: "**" },
  ],
  [
    { id: 71, value: "83" },
    { id: 72, value: "78" },
    { id: 73, value: "25" },
    { id: 74, value: "75" },
    { id: 75, value: "59" },
    { id: 76, value: "88" },
    { id: 77, value: "**" },
  ],
  [
    { id: 78, value: "01" },
    { id: 79, value: "25" },
    { id: 80, value: "43" },
    { id: 81, value: "58" },
    { id: 82, value: "84" },
    { id: 83, value: "21" },
    { id: 84, value: "**" },
  ],
  [
    { id: 85, value: "40" },
    { id: 86, value: "56" },
    { id: 87, value: "20" },
    { id: 88, value: "44" },
    { id: 89, value: "62" },
    { id: 90, value: "40" },
    { id: 91, value: "**" },
  ],
  [
    { id: 92, value: "07" },
    { id: 93, value: "45" },
    { id: 94, value: "40" },
    { id: 95, value: "04" },
    { id: 96, value: "97" },
    { id: 97, value: "80" },
    { id: 98, value: "**" },
  ],
  [
    { id: 99, value: "95" },
    { id: 100, value: "92" },
    { id: 101, value: "51" },
    { id: 102, value: "79" },
    { id: 103, value: "31" },
    { id: 104, value: "56" },
    { id: 105, value: "**" },
  ],
  [
    { id: 106, value: "15" },
    { id: 107, value: "32" },
    { id: 108, value: "78" },
    { id: 109, value: "22" },
    { id: 110, value: "09" },
    { id: 111, value: "13" },
    { id: 112, value: "**" },
  ],
  [
    { id: 113, value: "97" },
    { id: 114, value: "92" },
    { id: 115, value: "16" },
    { id: 116, value: "50" },
    { id: 117, value: "33" },
    { id: 118, value: "50" },
    { id: 119, value: "**" },
  ],
  [
    { id: 120, value: "85" },
    { id: 121, value: "67" },
    { id: 122, value: "83" },
    { id: 123, value: "54" },
    { id: 124, value: "91" },
    { id: 125, value: "20" },
    { id: 126, value: "**" },
  ],
  [
    { id: 127, value: "18" },
    { id: 128, value: "71" },
    { id: 129, value: "22" },
    { id: 130, value: "51" },
    { id: 131, value: "55" },
    { id: 132, value: "73" },
    { id: 133, value: "**" },
  ],
  [
    { id: 134, value: "77" },
    { id: 135, value: "58" },
    { id: 136, value: "42" },
    { id: 137, value: "42" },
    { id: 138, value: "15" },
    { id: 139, value: "87" },
    { id: 140, value: "**" },
  ],
  [
    { id: 141, value: "91" },
    { id: 142, value: "76" },
    { id: 143, value: "20" },
    { id: 144, value: "02" },
    { id: 145, value: "23" },
    { id: 146, value: "53" },
    { id: 147, value: "**" },
  ],
  [
    { id: 148, value: "99" },
    { id: 149, value: "25" },
    { id: 150, value: "44" },
    { id: 151, value: "05" },
    { id: 152, value: "67" },
    { id: 153, value: "77" },
    { id: 154, value: "**" },
  ],
  [
    { id: 155, value: "12" },
    { id: 156, value: "18" },
    { id: 157, value: "63" },
    { id: 158, value: "83" },
    { id: 159, value: "17" },
    { id: 160, value: "81" },
    { id: 161, value: "**" },
  ],
  [
    { id: 162, value: "22" },
    { id: 163, value: "73" },
    { id: 164, value: "37" },
    { id: 165, value: "32" },
    { id: 166, value: "27" },
    { id: 167, value: "50" },
    { id: 168, value: "**" },
  ],
  [
    { id: 169, value: "10" },
    { id: 170, value: "77" },
    { id: 171, value: "63" },
    { id: 172, value: "85" },
    { id: 173, value: "44" },
    { id: 174, value: "34" },
    { id: 175, value: "**" },
  ],
  [
    { id: 176, value: "08" },
    { id: 177, value: "19" },
    { id: 178, value: "00" },
    { id: 179, value: "49" },
    { id: 180, value: "55" },
    { id: 181, value: "34" },
    { id: 182, value: "**" },
  ],
  [
    { id: 183, value: "56" },
    { id: 184, value: "03" },
    { id: 185, value: "07" },
    { id: 186, value: "98" },
    { id: 187, value: "19" },
    { id: 188, value: "66" },
    { id: 189, value: "**" },
  ],
  [
    { id: 190, value: "03" },
    { id: 191, value: "28" },
    { id: 192, value: "64" },
    { id: 193, value: "67" },
    { id: 194, value: "91" },
    { id: 195, value: "97" },
    { id: 196, value: "**" },
  ],
  [
    { id: 197, value: "99" },
    { id: 198, value: "59" },
    { id: 199, value: "93" },
    { id: 200, value: "90" },
    { id: 201, value: "68" },
    { id: 202, value: "01" },
    { id: 203, value: "**" },
  ],
  [
    { id: 204, value: "22" },
    { id: 205, value: "86" },
    { id: 206, value: "51" },
    { id: 207, value: "88" },
    { id: 208, value: "06" },
    { id: 209, value: "48" },
    { id: 210, value: "**" },
  ],
  [
    { id: 211, value: "90" },
    { id: 212, value: "80" },
    { id: 213, value: "09" },
    { id: 214, value: "54" },
    { id: 215, value: "76" },
    { id: 216, value: "87" },
    { id: 217, value: "**" },
  ],
  [
    { id: 218, value: "22" },
    { id: 219, value: "71" },
    { id: 220, value: "32" },
    { id: 221, value: "58" },
    { id: 222, value: "43" },
    { id: 223, value: "13" },
    { id: 224, value: "**" },
  ],
  [
    { id: 316, value: "97" },
    { id: 317, value: "**" },
    { id: 318, value: "21" },
    { id: 319, value: "16" },
    { id: 320, value: "95" },
    { id: 321, value: "86" },
    { id: 322, value: "**" },
  ],
  [
    { id: 323, value: "61" },
    { id: 324, value: "54" },
    { id: 325, value: "62" },
    { id: 326, value: "08" },
    { id: 327, value: "77" },
    { id: 328, value: "04" },
    { id: 329, value: "**" },
  ],
  [
    { id: 330, value: "46" },
    { id: 331, value: "20" },
    { id: 332, value: "33" },
    { id: 333, value: "73" },
    { id: 334, value: "77" },
    { id: 335, value: "83" },
    { id: 336, value: "**" },
  ],
  [
    { id: 337, value: "45" },
    { id: 338, value: "60" },
    { id: 339, value: "86" },
    { id: 340, value: "94" },
    { id: 341, value: "88" },
    { id: 342, value: "71" },
    { id: 343, value: "**" },
  ],
  [
    { id: 344, value: "06" },
    { id: 345, value: "18" },
    { id: 346, value: "18" },
    { id: 347, value: "59" },
    { id: 348, value: "20" },
    { id: 349, value: "79" },
    { id: 350, value: "**" },
  ],
  [
    { id: 351, value: "77" },
    { id: 352, value: "35" },
    { id: 353, value: "25" },
    { id: 354, value: "21" },
    { id: 355, value: "73" },
    { id: 356, value: "63" },
    { id: 357, value: "**" },
  ],
  [
    { id: 358, value: "93" },
    { id: 359, value: "00" },
    { id: 360, value: "38" },
    { id: 361, value: "22" },
    { id: 362, value: "33" },
    { id: 363, value: "16" },
    { id: 364, value: "**" },
  ],
  [
    { id: 365, value: "27" },
    { id: 366, value: "66" },
    { id: 367, value: "46" },
    { id: 368, value: "31" },
    { id: 369, value: "35" },
    { id: 370, value: "44" },
    { id: 371, value: "**" },
  ],
  [
    { id: 372, value: "78" },
    { id: 373, value: "92" },
    { id: 374, value: "66" },
    { id: 375, value: "62" },
    { id: 376, value: "87" },
    { id: 377, value: "98" },
    { id: 378, value: "**" },
  ],
  [
    { id: 379, value: "91" },
    { id: 380, value: "33" },
    { id: 381, value: "82" },
    { id: 382, value: "00" },
    { id: 383, value: "83" },
    { id: 384, value: "35" },
    { id: 385, value: "**" },
  ],
  [
    { id: 386, value: "53" },
    { id: 387, value: "23" },
    { id: 388, value: "74" },
    { id: 389, value: "85" },
    { id: 390, value: "15" },
    { id: 391, value: "02" },
    { id: 392, value: "**" },
  ],
  [
    { id: 393, value: "23" },
    { id: 394, value: "69" },
    { id: 395, value: "33" },
    { id: 396, value: "85" },
    { id: 397, value: "26" },
    { id: 398, value: "81" },
    { id: 399, value: "**" },
  ],
  [
    { id: 400, value: "42" },
    { id: 401, value: "05" },
    { id: 402, value: "32" },
    { id: 403, value: "34" },
    { id: 404, value: "**" },
    { id: 405, value: "**" },
  ],
  [
    { id: 406, value: "71" },
    { id: 407, value: "52" },
    { id: 408, value: "94" },
    { id: 409, value: "53" },
    { id: 410, value: "57" },
    { id: 411, value: "09" },
    { id: 412, value: "**" },
  ],
  [
    { id: 413, value: "41" },
    { id: 414, value: "49" },
    { id: 415, value: "07" },
    { id: 416, value: "45" },
    { id: 417, value: "24" },
    { id: 418, value: "99" },
    { id: 419, value: "**" },
  ],
  [
    { id: 420, value: "52" },
    { id: 421, value: "96" },
    { id: 422, value: "66" },
    { id: 423, value: "97" },
    { id: 424, value: "20" },
    { id: 425, value: "71" },
    { id: 426, value: "**" },
  ],
  [
    { id: 427, value: "44" },
    { id: 428, value: "83" },
    { id: 429, value: "74" },
    { id: 430, value: "33" },
    { id: 431, value: "65" },
    { id: 432, value: "28" },
    { id: 433, value: "**" },
  ],
  [
    { id: 434, value: "46" },
    { id: 435, value: "59" },
    { id: 436, value: "17" },
    { id: 437, value: "54" },
    { id: 438, value: "29" },
    { id: 439, value: "02" },
    { id: 440, value: "**" },
  ],
  [
    { id: 441, value: "82" },
    { id: 442, value: "19" },
    { id: 443, value: "63" },
    { id: 444, value: "92" },
    { id: 445, value: "21" },
    { id: 446, value: "67" },
    { id: 447, value: "**" },
  ],
  [
    { id: 448, value: "75" },
    { id: 449, value: "54" },
    { id: 450, value: "50" },
    { id: 451, value: "69" },
    { id: 452, value: "56" },
    { id: 453, value: "62" },
    { id: 454, value: "**" },
  ],
  [
    { id: 455, value: "23" },
    { id: 456, value: "15" },
    { id: 457, value: "98" },
    { id: 458, value: "26" },
    { id: 459, value: "55" },
    { id: 460, value: "05" },
    { id: 461, value: "**" },
  ],
  [
    { id: 462, value: "89" },
    { id: 463, value: "60" },
    { id: 464, value: "12" },
    { id: 465, value: "31" },
    { id: 466, value: "74" },
    { id: 467, value: "39" },
    { id: 468, value: "**" },
  ],
  [
    { id: 469, value: "76" },
    { id: 470, value: "16" },
    { id: 471, value: "80" },
    { id: 472, value: "92" },
    { id: 473, value: "**" },
    { id: 474, value: "**" },
    { id: 475, value: "31" },
  ],

  [
    { id: 517, value: "23" },
    { id: 518, value: "25" },
    { id: 519, value: "67" },
    { id: 520, value: "88" },
    { id: 521, value: "58" },
    { id: 522, value: "36" },
    { id: 523, value: "**" },
  ],
  [
    { id: 524, value: "72" },
    { id: 525, value: "20" },
    { id: 526, value: "61" },
    { id: 527, value: "19" },
    { id: 528, value: "13" },
    { id: 529, value: "86" },
    { id: 530, value: "**" },
  ],
  [
    { id: 531, value: "46" },
    { id: 532, value: "37" },
    { id: 533, value: "72" },
    { id: 534, value: "08" },
    { id: 535, value: "37" },
    { id: 536, value: "35" },
    { id: 537, value: "**" },
  ],
  [
    { id: 538, value: "20" },
    { id: 539, value: "63" },
    { id: 540, value: "01" },
    { id: 541, value: "02" },
    { id: 542, value: "06" },
    { id: 543, value: "56" },
    { id: 544, value: "**" },
  ],
  [
    { id: 545, value: "27" },
    { id: 546, value: "94" },
    { id: 547, value: "21" },
    { id: 548, value: "83" },
    { id: 549, value: "44" },
    { id: 550, value: "21" },
    { id: 551, value: "**" },
  ],
  [
    { id: 552, value: "60" },
    { id: 553, value: "73" },
    { id: 554, value: "09" },
    { id: 555, value: "70" },
    { id: 556, value: "75" },
    { id: 557, value: "37" },
    { id: 558, value: "**" },
  ],
  [
    { id: 559, value: "59" },
    { id: 560, value: "88" },
    { id: 561, value: "45" },
    { id: 562, value: "87" },
    { id: 563, value: "59" },
    { id: 564, value: "23" },
    { id: 565, value: "**" },
  ],
  [
    { id: 566, value: "65" },
    { id: 567, value: "84" },
    { id: 568, value: "94" },
    { id: 569, value: "55" },
    { id: 570, value: "71" },
    { id: 571, value: "77" },
    { id: 572, value: "**" },
  ],
  [
    { id: 601, value: "**" },
    { id: 602, value: "27" },
    { id: 603, value: "78" },
    { id: 604, value: "28" },
    { id: 605, value: "62" },
    { id: 606, value: "10" },
    { id: 607, value: "**" },
  ],
  [
    { id: 608, value: "14" },
    { id: 609, value: "73" },
    { id: 610, value: "86" },
    { id: 611, value: "08" },
    { id: 612, value: "69" },
    { id: 613, value: "83" },
    { id: 614, value: "**" },
  ],
  [
    { id: 615, value: "28" },
    { id: 616, value: "31" },
    { id: 617, value: "96" },
    { id: 618, value: "14" },
    { id: 619, value: "90" },
    { id: 620, value: "53" },
    { id: 621, value: "**" },
  ],
  [
    { id: 622, value: "20" },
    { id: 623, value: "39" },
    { id: 624, value: "24" },
    { id: 625, value: "70" },
    { id: 626, value: "55" },
    { id: 627, value: "67" },
    { id: 628, value: "**" },
  ],
  [
    { id: 629, value: "45" },
    { id: 630, value: "44" },
    { id: 631, value: "12" },
    { id: 632, value: "52" },
    { id: 633, value: "73" },
    { id: 634, value: "15" },
    { id: 635, value: "**" },
  ],
  [
    { id: 636, value: "26" },
    { id: 637, value: "60" },
    { id: 638, value: "10" },
    { id: 639, value: "94" },
    { id: 640, value: "12" },
    { id: 641, value: "21" },
    { id: 642, value: "**" },
  ],
  [
    { id: 643, value: "53" },
    { id: 644, value: "72" },
    { id: 645, value: "93" },
    { id: 646, value: "75" },
    { id: 647, value: "90" },
    { id: 648, value: "81" },
    { id: 649, value: "**" },
  ],
  [
    { id: 650, value: "81" },
    { id: 651, value: "77" },
    { id: 652, value: "29" },
    { id: 653, value: "22" },
    { id: 654, value: "11" },
    { id: 655, value: "94" },
    { id: 656, value: "**" },
  ],
  [
    { id: 657, value: "71" },
    { id: 658, value: "03" },
    { id: 659, value: "50" },
    { id: 660, value: "56" },
    { id: 661, value: "31" },
    { id: 662, value: "18" },
    { id: 663, value: "**" },
  ],
  [
    { id: 664, value: "63" },
    { id: 665, value: "02" },
    { id: 666, value: "91" },
    { id: 667, value: "58" },
    { id: 668, value: "30" },
    { id: 669, value: "77" },
    { id: 670, value: "**" },
  ],
  [
    { id: 671, value: "36" },
    { id: 672, value: "87" },
    { id: 673, value: "63" },
    { id: 674, value: "00" },
    { id: 675, value: "25" },
    { id: 676, value: "31" },
    { id: 677, value: "**" },
  ],
  [
    { id: 678, value: "38" },
    { id: 679, value: "14" },
    { id: 680, value: "36" },
    { id: 681, value: "12" },
    { id: 682, value: "28" },
    { id: 683, value: "98" },
    { id: 684, value: "**" },
  ],
  [
    { id: 685, value: "08" },
    { id: 686, value: "07" },
    { id: 687, value: "82" },
    { id: 688, value: "24" },
    { id: 689, value: "61" },
    { id: 690, value: "59" },
    { id: 691, value: "**" },
  ],
  [
    { id: 692, value: "88" },
    { id: 693, value: "52" },
    { id: 694, value: "75" },
    { id: 695, value: "07" },
    { id: 696, value: "28" },
    { id: 697, value: "58" },
    { id: 698, value: "**" },
  ],
  [
    { id: 699, value: "43" },
    { id: 700, value: "87" },
    { id: 701, value: "10" },
    { id: 702, value: "62" },
    { id: 703, value: "01" },
    { id: 704, value: "04" },
    { id: 705, value: "**" },
  ],
  [
    { id: 706, value: "59" },
    { id: 707, value: "26" },
    { id: 708, value: "07" },
    { id: 709, value: "60" },
    { id: 710, value: "27" },
    { id: 711, value: "94" },
    { id: 712, value: "**" },
  ],
  [
    { id: 713, value: "08" },
    { id: 714, value: "94" },
    { id: 715, value: "65" },
    { id: 716, value: "01" },
    { id: 717, value: "42" },
    { id: 718, value: "50" },
    { id: 719, value: "**" },
  ],
  [
    { id: 720, value: "74" },
    { id: 721, value: "71" },
    { id: 722, value: "18" },
    { id: 723, value: "05" },
    { id: 724, value: "43" },
    { id: 725, value: "66" },
    { id: 726, value: "**" },
  ],
  [
    { id: 727, value: "32" },
    { id: 728, value: "61" },
    { id: 729, value: "54" },
    { id: 730, value: "20" },
    { id: 731, value: "81" },
    { id: 732, value: "60" },
    { id: 733, value: "**" },
  ],
  [
    { id: 734, value: "98" },
    { id: 735, value: "30" },
    { id: 736, value: "86" },
    { id: 737, value: "64" },
    { id: 738, value: "41" },
    { id: 739, value: "94" },
    { id: 740, value: "**" },
  ],
  [
    { id: 741, value: "55" },
    { id: 742, value: "73" },
    { id: 743, value: "89" },
    { id: 744, value: "**" },
    { id: 745, value: "61" },
    { id: 746, value: "71" },
    { id: 747, value: "**" },
  ],
  [
    { id: 748, value: "67" },
    { id: 749, value: "19" },
    { id: 750, value: "14" },
    { id: 751, value: "04" },
    { id: 752, value: "15" },
    { id: 753, value: "76" },
    { id: 754, value: "**" },
  ],
  [
    { id: 755, value: "20" },
    { id: 756, value: "65" },
    { id: 757, value: "63" },
    { id: 758, value: "42" },
    { id: 759, value: "34" },
    { id: 760, value: "30" },
    { id: 761, value: "**" },
  ],
];
// rajdhaniday

export const RajdhaniDay = [
  [
    { id: 1, value: "98" },
    { id: 2, value: "60" },
    { id: 3, value: "64" },
    { id: 4, value: "23" },
    { id: 5, value: "43" },
    { id: 6, value: "11" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "74" },
    { id: 9, value: "78" },
    { id: 10, value: "98" },
    { id: 11, value: "86" },
    { id: 12, value: "01" },
    { id: 13, value: "13" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "22" },
    { id: 16, value: "78" },
    { id: 17, value: "89" },
    { id: 18, value: "22" },
    { id: 19, value: "04" },
    { id: 20, value: "27" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "75" },
    { id: 23, value: "73" },
    { id: 24, value: "19" },
    { id: 25, value: "**" },
    { id: 26, value: "91" },
    { id: 27, value: "78" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "40" },
    { id: 30, value: "86" },
    { id: 31, value: "11" },
    { id: 32, value: "69" },
    { id: 33, value: "84" },
    { id: 34, value: "89" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "37" },
    { id: 37, value: "69" },
    { id: 38, value: "10" },
    { id: 39, value: "15" },
    { id: 40, value: "57" },
    { id: 41, value: "72" },
    { id: 42, value: "**" },
  ],
  [
    { id: 43, value: "86" },
    { id: 44, value: "02" },
    { id: 45, value: "52" },
    { id: 46, value: "05" },
    { id: 47, value: "13" },
    { id: 48, value: "01" },
    { id: 49, value: "**" },
  ],
  [
    { id: 50, value: "45" },
    { id: 51, value: "95" },
    { id: 52, value: "46" },
    { id: 53, value: "00" },
    { id: 54, value: "21" },
    { id: 55, value: "89" },
    { id: 56, value: "**" },
  ],
  [
    { id: 57, value: "92" },
    { id: 58, value: "09" },
    { id: 59, value: "64" },
    { id: 60, value: "87" },
    { id: 61, value: "12" },
    { id: 62, value: "60" },
    { id: 63, value: "**" },
  ],
  [
    { id: 64, value: "15" },
    { id: 65, value: "41" },
    { id: 66, value: "**" },
    { id: 67, value: "53" },
    { id: 68, value: "10" },
    { id: 69, value: "96" },
    { id: 70, value: "**" },
  ],
  [
    { id: 71, value: "32" },
    { id: 72, value: "99" },
    { id: 73, value: "50" },
    { id: 74, value: "05" },
    { id: 75, value: "23" },
    { id: 76, value: "45" },
    { id: 77, value: "**" },
  ],
  [
    { id: 78, value: "12" },
    { id: 79, value: "26" },
    { id: 80, value: "53" },
    { id: 81, value: "87" },
    { id: 82, value: "34" },
    { id: 83, value: "03" },
    { id: 84, value: "**" },
  ],
  [
    { id: 85, value: "27" },
    { id: 86, value: "50" },
    { id: 87, value: "93" },
    { id: 88, value: "84" },
    { id: 89, value: "81" },
    { id: 90, value: "14" },
    { id: 91, value: "**" },
  ],
  [
    { id: 92, value: "39" },
    { id: 93, value: "75" },
    { id: 94, value: "00" },
    { id: 95, value: "05" },
    { id: 96, value: "40" },
    { id: 97, value: "47" },
    { id: 98, value: "**" },
  ],
  [
    { id: 99, value: "83" },
    { id: 100, value: "37" },
    { id: 101, value: "40" },
    { id: 102, value: "71" },
    { id: 103, value: "16" },
    { id: 104, value: "33" },
    { id: 105, value: "**" },
  ],
  [
    { id: 106, value: "65" },
    { id: 107, value: "50" },
    { id: 108, value: "12" },
    { id: 109, value: "52" },
    { id: 110, value: "01" },
    { id: 111, value: "99" },
    { id: 112, value: "**" },
  ],
  [
    { id: 113, value: "45" },
    { id: 114, value: "89" },
    { id: 115, value: "43" },
    { id: 116, value: "34" },
    { id: 117, value: "97" },
    { id: 118, value: "48" },
    { id: 119, value: "**" },
  ],
  [
    { id: 120, value: "80" },
    { id: 121, value: "13" },
    { id: 122, value: "33" },
    { id: 123, value: "58" },
    { id: 124, value: "17" },
    { id: 125, value: "00" },
    { id: 126, value: "**" },
  ],
  [
    { id: 127, value: "33" },
    { id: 128, value: "94" },
    { id: 129, value: "20" },
    { id: 130, value: "34" },
    { id: 131, value: "01" },
    { id: 132, value: "80" },
    { id: 133, value: "**" },
  ],
  [
    { id: 134, value: "97" },
    { id: 135, value: "55" },
    { id: 136, value: "71" },
    { id: 137, value: "80" },
    { id: 138, value: "22" },
    { id: 139, value: "83" },
    { id: 140, value: "**" },
  ],
  [
    { id: 141, value: "41" },
    { id: 142, value: "71" },
    { id: 143, value: "22" },
    { id: 144, value: "08" },
    { id: 145, value: "15" },
    { id: 146, value: "18" },
    { id: 147, value: "**" },
  ],
  [
    { id: 148, value: "35" },
    { id: 149, value: "07" },
    { id: 150, value: "21" },
    { id: 151, value: "28" },
    { id: 152, value: "04" },
    { id: 153, value: "70" },
    { id: 154, value: "**" },
  ],
  [
    { id: 155, value: "99" },
    { id: 156, value: "03" },
    { id: 157, value: "97" },
    { id: 158, value: "04" },
    { id: 159, value: "42" },
    { id: 160, value: "96" },
    { id: 161, value: "**" },
  ],
  [
    { id: 162, value: "16" },
    { id: 163, value: "06" },
    { id: 164, value: "94" },
    { id: 165, value: "37" },
    { id: 166, value: "02" },
    { id: 167, value: "99" },
    { id: 168, value: "**" },
  ],
  [
    { id: 169, value: "42" },
    { id: 170, value: "49" },
    { id: 171, value: "60" },
    { id: 172, value: "88" },
    { id: 173, value: "61" },
    { id: 174, value: "16" },
    { id: 175, value: "**" },
  ],
  [
    { id: 176, value: "13" },
    { id: 177, value: "97" },
    { id: 178, value: "42" },
    { id: 179, value: "31" },
    { id: 180, value: "31" },
    { id: 181, value: "67" },
    { id: 182, value: "**" },
  ],
  [
    { id: 183, value: "08" },
    { id: 184, value: "07" },
    { id: 185, value: "60" },
    { id: 186, value: "97" },
    { id: 187, value: "19" },
    { id: 188, value: "08" },
    { id: 189, value: "**" },
  ],
  [
    { id: 190, value: "90" },
    { id: 191, value: "98" },
    { id: 192, value: "46" },
    { id: 193, value: "48" },
    { id: 194, value: "25" },
    { id: 195, value: "92" },
    { id: 196, value: "**" },
  ],
  [
    { id: 197, value: "35" },
    { id: 198, value: "60" },
    { id: 199, value: "51" },
    { id: 200, value: "35" },
    { id: 201, value: "59" },
    { id: 202, value: "15" },
    { id: 203, value: "**" },
  ],
  [
    { id: 204, value: "87" },
    { id: 205, value: "89" },
    { id: 206, value: "16" },
    { id: 207, value: "78" },
    { id: 208, value: "63" },
    { id: 209, value: "31" },
    { id: 210, value: "**" },
  ],
  [
    { id: 211, value: "28" },
    { id: 212, value: "36" },
    { id: 213, value: "89" },
    { id: 214, value: "59" },
    { id: 215, value: "94" },
    { id: 216, value: "00" },
    { id: 217, value: "**" },
  ],
  [
    { id: 218, value: "48" },
    { id: 219, value: "62" },
    { id: 220, value: "87" },
    { id: 221, value: "86" },
    { id: 222, value: "73" },
    { id: 223, value: "84" },
    { id: 224, value: "**" },
  ],
  [
    { id: 225, value: "02" },
    { id: 226, value: "**" },
    { id: 227, value: "30" },
    { id: 228, value: "09" },
    { id: 229, value: "49" },
    { id: 230, value: "55" },
    { id: 231, value: "**" },
  ],
  [
    { id: 232, value: "72" },
    { id: 233, value: "50" },
    { id: 234, value: "64" },
    { id: 235, value: "94" },
    { id: 236, value: "81" },
    { id: 237, value: "45" },
    { id: 238, value: "**" },
  ],
  [
    { id: 239, value: "34" },
    { id: 240, value: "03" },
    { id: 241, value: "32" },
    { id: 242, value: "82" },
    { id: 243, value: "57" },
    { id: 244, value: "22" },
    { id: 245, value: "**" },
  ],
  [
    { id: 246, value: "41" },
    { id: 247, value: "77" },
    { id: 248, value: "49" },
    { id: 249, value: "35" },
    { id: 250, value: "23" },
    { id: 251, value: "93" },
    { id: 252, value: "**" },
  ],
  [
    { id: 253, value: "07" },
    { id: 254, value: "49" },
    { id: 255, value: "99" },
    { id: 256, value: "70" },
    { id: 257, value: "15" },
    { id: 258, value: "29" },
    { id: 259, value: "**" },
  ],
  [
    { id: 260, value: "07" },
    { id: 261, value: "55" },
    { id: 262, value: "51" },
    { id: 263, value: "40" },
    { id: 264, value: "17" },
    { id: 265, value: "01" },
    { id: 266, value: "**" },
  ],
  [
    { id: 267, value: "97" },
    { id: 268, value: "08" },
    { id: 269, value: "05" },
    { id: 270, value: "34" },
    { id: 271, value: "50" },
    { id: 272, value: "14" },
    { id: 273, value: "**" },
  ],
  [
    { id: 274, value: "79" },
    { id: 275, value: "05" },
    { id: 276, value: "84" },
    { id: 277, value: "36" },
    { id: 278, value: "17" },
    { id: 279, value: "72" },
    { id: 280, value: "**" },
  ],
  [
    { id: 281, value: "36" },
    { id: 282, value: "07" },
    { id: 283, value: "82" },
    { id: 284, value: "59" },
    { id: 285, value: "90" },
    { id: 286, value: "91" },
    { id: 287, value: "**" },
  ],
  [
    { id: 288, value: "54" },
    { id: 289, value: "75" },
    { id: 290, value: "86" },
    { id: 291, value: "07" },
    { id: 292, value: "18" },
    { id: 293, value: "10" },
    { id: 294, value: "**" },
  ],
  [
    { id: 295, value: "04" },
    { id: 296, value: "98" },
    { id: 297, value: "35" },
    { id: 298, value: "31" },
    { id: 299, value: "23" },
    { id: 300, value: "24" },
    { id: 301, value: "**" },
  ],
  [
    { id: 302, value: "07" },
    { id: 303, value: "15" },
    { id: 304, value: "41" },
    { id: 305, value: "46" },
    { id: 306, value: "22" },
    { id: 307, value: "01" },
    { id: 308, value: "**" },
  ],
  [
    { id: 309, value: "12" },
    { id: 310, value: "68" },
    { id: 311, value: "08" },
    { id: 312, value: "64" },
    { id: 313, value: "**" },
    { id: 314, value: "**" },
    { id: 315, value: "**" },
  ],
  [
    { id: 316, value: "**" },
    { id: 317, value: "**" },
    { id: 318, value: "**" },
    { id: 319, value: "**" },
    { id: 320, value: "**" },
    { id: 321, value: "**" },
    { id: 322, value: "**" },
  ],

  [
    { id: 330, value: "06" },
    { id: 331, value: "63" },
    { id: 332, value: "11" },
    { id: 333, value: "86" },
    { id: 334, value: "93" },
    { id: 335, value: "36" },
    { id: 336, value: "**" },
  ],
  [
    { id: 337, value: "09" },
    { id: 338, value: "94" },
    { id: 339, value: "66" },
    { id: 340, value: "24" },
    { id: 341, value: "97" },
    { id: 342, value: "80" },
    { id: 343, value: "**" },
  ],
  [
    { id: 344, value: "33" },
    { id: 345, value: "64" },
    { id: 346, value: "16" },
    { id: 347, value: "15" },
    { id: 348, value: "91" },
    { id: 349, value: "59" },
    { id: 350, value: "**" },
  ],
  [
    { id: 351, value: "46" },
    { id: 352, value: "63" },
    { id: 353, value: "39" },
    { id: 354, value: "07" },
    { id: 355, value: "48" },
    { id: 356, value: "79" },
    { id: 357, value: "**" },
  ],
  [
    { id: 358, value: "97" },
    { id: 359, value: "02" },
    { id: 360, value: "94" },
    { id: 361, value: "66" },
    { id: 362, value: "27" },
    { id: 363, value: "03" },
    { id: 364, value: "**" },
  ],
  [
    { id: 365, value: "14" },
    { id: 366, value: "87" },
    { id: 367, value: "59" },
    { id: 368, value: "92" },
    { id: 369, value: "75" },
    { id: 370, value: "11" },
    { id: 371, value: "**" },
  ],
  [
    { id: 372, value: "23" },
    { id: 373, value: "99" },
    { id: 374, value: "70" },
    { id: 375, value: "05" },
    { id: 376, value: "21" },
    { id: 377, value: "99" },
    { id: 378, value: "**" },
  ],
  [
    { id: 379, value: "26" },
    { id: 380, value: "64" },
    { id: 381, value: "08" },
    { id: 382, value: "50" },
    { id: 383, value: "99" },
    { id: 384, value: "50" },
    { id: 385, value: "**" },
  ],
  [
    { id: 386, value: "16" },
    { id: 387, value: "17" },
    { id: 388, value: "70" },
    { id: 389, value: "83" },
    { id: 390, value: "40" },
    { id: 391, value: "91" },
    { id: 392, value: "**" },
  ],
  [
    { id: 393, value: "63" },
    { id: 394, value: "45" },
    { id: 395, value: "62" },
    { id: 396, value: "95" },
    { id: 398, value: "**" },
    { id: 397, value: "21" },
    { id: 398, value: "**" },
  ],
  [
    { id: 399, value: "33" },
    { id: 400, value: "46" },
    { id: 401, value: "44" },
    { id: 402, value: "60" },
    { id: 403, value: "02" },
    { id: 404, value: "62" },
    { id: 405, value: "**" },
  ],
  [
    { id: 406, value: "96" },
    { id: 407, value: "67" },
    { id: 408, value: "88" },
    { id: 409, value: "81" },
    { id: 410, value: "02" },
    { id: 411, value: "31" },
    { id: 412, value: "**" },
  ],
  [
    { id: 413, value: "44" },
    { id: 414, value: "23" },
    { id: 415, value: "47" },
    { id: 416, value: "64" },
    { id: 417, value: "31" },
    { id: 418, value: "70" },
    { id: 419, value: "**" },
  ],
  [
    { id: 420, value: "08" },
    { id: 421, value: "66" },
    { id: 422, value: "78" },
    { id: 423, value: "03" },
    { id: 424, value: "93" },
    { id: 425, value: "00" },
    { id: 426, value: "**" },
  ],
  [
    { id: 427, value: "93" },
    { id: 428, value: "57" },
    { id: 429, value: "19" },
    { id: 430, value: "13" },
    { id: 431, value: "96" },
    { id: 432, value: "28" },
    { id: 433, value: "**" },
  ],
  [
    { id: 434, value: "34" },
    { id: 435, value: "73" },
    { id: 436, value: "23" },
    { id: 437, value: "28" },
    { id: 438, value: "41" },
    { id: 439, value: "84" },
    { id: 440, value: "**" },
  ],
  [
    { id: 441, value: "71" },
    { id: 442, value: "77" },
    { id: 443, value: "28" },
    { id: 444, value: "42" },
    { id: 445, value: "76" },
    { id: 446, value: "89" },
    { id: 447, value: "**" },
  ],
  [
    { id: 448, value: "95" },
    { id: 449, value: "66" },
    { id: 450, value: "61" },
    { id: 451, value: "80" },
    { id: 452, value: "05" },
    { id: 453, value: "83" },
    { id: 454, value: "**" },
  ],
  [
    { id: 460, value: "**" },
    { id: 455, value: "45" },
    { id: 456, value: "58" },
    { id: 457, value: "13" },
    { id: 458, value: "10" },
    { id: 459, value: "07" },
    { id: 460, value: "**" },
  ],
  [
    { id: 461, value: "41" },
    { id: 462, value: "33" },
    { id: 463, value: "09" },
    { id: 464, value: "41" },
    { id: 465, value: "03" },
    { id: 466, value: "38" },
    { id: 467, value: "**" },
  ],
  [
    { id: 468, value: "33" },
    { id: 469, value: "46" },
    { id: 470, value: "64" },
    { id: 471, value: "63" },
    { id: 472, value: "08" },
    { id: 473, value: "29" },
    { id: 474, value: "**" },
  ],
  [
    { id: 475, value: "19" },
    { id: 476, value: "13" },
    { id: 477, value: "29" },
    { id: 478, value: "56" },
    { id: 479, value: "75" },
    { id: 480, value: "79" },
    { id: 481, value: "**" },
  ],
  [
    { id: 482, value: "68" },
    { id: 483, value: "53" },
    { id: 484, value: "57" },
    { id: 485, value: "14" },
    { id: 486, value: "81" },
    { id: 487, value: "26" },
    { id: 488, value: "**" },
  ],
  [
    { id: 489, value: "30" },
    { id: 490, value: "14" },
    { id: 491, value: "54" },
    { id: 492, value: "12" },
    { id: 493, value: "40" },
    { id: 494, value: "82" },
    { id: 495, value: "**" },
  ],
  [
    { id: 496, value: "70" },
    { id: 497, value: "54" },
    { id: 498, value: "85" },
    { id: 499, value: "41" },
    { id: 500, value: "74" },
    { id: 501, value: "29" },
    { id: 501, value: "**" },
  ],
];

// milanday

export const MilanDay = [
  [
    { id: 1, value: "86" },
    { id: 2, value: "85" },
    { id: 3, value: "02" },
    { id: 4, value: "05" },
    { id: 5, value: "52" },
    { id: 6, value: "61" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "95" },
    { id: 9, value: "53" },
    { id: 10, value: "18" },
    { id: 11, value: "09" },
    { id: 12, value: "53" },
    { id: 13, value: "06" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "26" },
    { id: 16, value: "12" },
    { id: 17, value: "70" },
    { id: 18, value: "12" },
    { id: 19, value: "73" },
    { id: 20, value: "48" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "77" },
    { id: 23, value: "44" },
    { id: 24, value: "09" },
    { id: 25, value: "**" },
    { id: 26, value: "09" },
    { id: 27, value: "03" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "88" },
    { id: 30, value: "48" },
    { id: 31, value: "49" },
    { id: 32, value: "44" },
    { id: 33, value: "07" },
    { id: 34, value: "49" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "74" },
    { id: 37, value: "26" },
    { id: 38, value: "96" },
    { id: 39, value: "57" },
    { id: 40, value: "97" },
    { id: 41, value: "62" },
    { id: 42, value: "**" },
  ],
  [
    { id: 43, value: "62" },
    { id: 44, value: "68" },
    { id: 45, value: "77" },
    { id: 46, value: "64" },
    { id: 47, value: "14" },
    { id: 48, value: "94" },
    { id: 49, value: "**" },
  ],
  [
    { id: 50, value: "72" },
    { id: 51, value: "23" },
    { id: 52, value: "16" },
    { id: 53, value: "49" },
    { id: 54, value: "15" },
    { id: 55, value: "77" },
    { id: 56, value: "**" },
  ],
  [
    { id: 57, value: "00" },
    { id: 58, value: "22" },
    { id: 59, value: "34" },
    { id: 60, value: "27" },
    { id: 61, value: "66" },
    { id: 62, value: "29" },
    { id: 63, value: "**" },
  ],
  [
    { id: 64, value: "53" },
    { id: 65, value: "45" },
    { id: 66, value: "**" },
    { id: 67, value: "21" },
    { id: 68, value: "63" },
    { id: 69, value: "35" },
    { id: 70, value: "**" },
  ],
  [
    { id: 71, value: "27" },
    { id: 72, value: "30" },
    { id: 73, value: "00" },
    { id: 74, value: "57" },
    { id: 75, value: "51" },
    { id: 76, value: "33" },
    { id: 77, value: "**" },
  ],
  [
    { id: 78, value: "40" },
    { id: 79, value: "60" },
    { id: 80, value: "01" },
    { id: 81, value: "75" },
    { id: 82, value: "44" },
    { id: 83, value: "42" },
    { id: 84, value: "**" },
  ],
  [
    { id: 85, value: "42" },
    { id: 86, value: "96" },
    { id: 87, value: "24" },
    { id: 88, value: "63" },
    { id: 89, value: "76" },
    { id: 90, value: "41" },
    { id: 91, value: "**" },
  ],
  [
    { id: 92, value: "41" },
    { id: 93, value: "09" },
    { id: 94, value: "93" },
    { id: 95, value: "96" },
    { id: 96, value: "20" },
    { id: 97, value: "59" },
    { id: 98, value: "**" },
  ],
  [
    { id: 99, value: "11" },
    { id: 100, value: "31" },
    { id: 101, value: "36" },
    { id: 102, value: "79" },
    { id: 103, value: "07" },
    { id: 104, value: "41" },
    { id: 105, value: "**" },
  ],
  [
    { id: 106, value: "10" },
    { id: 107, value: "52" },
    { id: 108, value: "76" },
    { id: 109, value: "15" },
    { id: 110, value: "74" },
    { id: 111, value: "83" },
    { id: 112, value: "**" },
  ],
  [
    { id: 113, value: "10" },
    { id: 114, value: "21" },
    { id: 115, value: "34" },
    { id: 116, value: "64" },
    { id: 117, value: "07" },
    { id: 118, value: "90" },
    { id: 119, value: "**" },
  ],
  [
    { id: 120, value: "72" },
    { id: 121, value: "68" },
    { id: 122, value: "29" },
    { id: 123, value: "28" },
    { id: 124, value: "31" },
    { id: 125, value: "93" },
    { id: 126, value: "**" },
  ],
  [
    { id: 127, value: "19" },
    { id: 128, value: "47" },
    { id: 129, value: "73" },
    { id: 130, value: "65" },
    { id: 131, value: "83" },
    { id: 132, value: "98" },
    { id: 133, value: "**" },
  ],
  [
    { id: 134, value: "41" },
    { id: 135, value: "67" },
    { id: 136, value: "58" },
    { id: 137, value: "41" },
    { id: 138, value: "23" },
    { id: 139, value: "74" },
    { id: 140, value: "**" },
  ],
  [
    { id: 141, value: "41" },
    { id: 142, value: "67" },
    { id: 143, value: "58" },
    { id: 144, value: "41" },
    { id: 145, value: "23" },
    { id: 146, value: "74" },
    { id: 147, value: "**" },
  ],
  [
    { id: 148, value: "58" },
    { id: 149, value: "50" },
    { id: 150, value: "12" },
    { id: 151, value: "80" },
    { id: 152, value: "82" },
    { id: 153, value: "58" },
    { id: 154, value: "**" },
  ],
  [
    { id: 155, value: "99" },
    { id: 156, value: "74" },
    { id: 157, value: "09" },
    { id: 158, value: "76" },
    { id: 159, value: "83" },
    { id: 160, value: "42" },
    { id: 161, value: "**" },
  ],
  [
    { id: 162, value: "89" },
    { id: 163, value: "65" },
    { id: 164, value: "68" },
    { id: 165, value: "08" },
    { id: 166, value: "10" },
    { id: 167, value: "49" },
    { id: 168, value: "**" },
  ],
  [
    { id: 169, value: "82" },
    { id: 170, value: "00" },
    { id: 171, value: "47" },
    { id: 172, value: "70" },
    { id: 173, value: "02" },
    { id: 174, value: "81" },
    { id: 175, value: "**" },
  ],
  [
    { id: 176, value: "19" },
    { id: 177, value: "33" },
    { id: 178, value: "88" },
    { id: 179, value: "77" },
    { id: 180, value: "76" },
    { id: 181, value: "88" },
    { id: 182, value: "**" },
  ],
  [
    { id: 183, value: "57" },
    { id: 184, value: "02" },
    { id: 185, value: "23" },
    { id: 186, value: "18" },
    { id: 187, value: "12" },
    { id: 188, value: "73" },
    { id: 189, value: "**" },
  ],
  [
    { id: 190, value: "99" },
    { id: 191, value: "26" },
    { id: 192, value: "71" },
    { id: 193, value: "54" },
    { id: 194, value: "50" },
    { id: 195, value: "86" },
    { id: 196, value: "**" },
  ],
  [
    { id: 197, value: "71" },
    { id: 198, value: "89" },
    { id: 199, value: "03" },
    { id: 200, value: "32" },
    { id: 201, value: "43" },
    { id: 202, value: "12" },
    { id: 203, value: "**" },
  ],
  [
    { id: 204, value: "74" },
    { id: 205, value: "35" },
    { id: 206, value: "33" },
    { id: 207, value: "39" },
    { id: 208, value: "98" },
    { id: 209, value: "33" },
    { id: 210, value: "**" },
  ],
  [
    { id: 211, value: "81" },
    { id: 212, value: "42" },
    { id: 213, value: "30" },
    { id: 214, value: "96" },
    { id: 215, value: "07" },
    { id: 216, value: "02" },
    { id: 217, value: "**" },
  ],
  [
    { id: 218, value: "70" },
    { id: 219, value: "63" },
    { id: 220, value: "45" },
    { id: 221, value: "07" },
    { id: 222, value: "84" },
    { id: 223, value: "38" },
    { id: 224, value: "**" },
  ],
  [
    { id: 225, value: "89" },
    { id: 226, value: "43" },
    { id: 227, value: "90" },
    { id: 228, value: "83" },
    { id: 229, value: "36" },
    { id: 230, value: "66" },
    { id: 231, value: "**" },
  ],
  [
    { id: 232, value: "57" },
    { id: 233, value: "**" },
    { id: 234, value: "11" },
    { id: 235, value: "46" },
    { id: 236, value: "55" },
    { id: 237, value: "92" },
    { id: 238, value: "**" },
  ],
  [
    { id: 239, value: "**" },
    { id: 240, value: "71" },
    { id: 241, value: "70" },
    { id: 242, value: "87" },
    { id: 243, value: "98" },
    { id: 244, value: "15" },
    { id: 245, value: "**" },
  ],
  [
    { id: 246, value: "88" },
    { id: 247, value: "88" },
    { id: 248, value: "70" },
    { id: 249, value: "31" },
    { id: 250, value: "51" },
    { id: 251, value: "05" },
    { id: 252, value: "**" },
  ],
  [
    { id: 253, value: "52" },
    { id: 254, value: "72" },
    { id: 255, value: "80" },
    { id: 256, value: "31" },
    { id: 257, value: "39" },
    { id: 258, value: "94" },
    { id: 259, value: "**" },
  ],
  [
    { id: 260, value: "87" },
    { id: 261, value: "16" },
    { id: 262, value: "17" },
    { id: 263, value: "69" },
    { id: 264, value: "15" },
    { id: 265, value: "49" },
    { id: 266, value: "**" },
  ],
  [
    { id: 267, value: "50" },
    { id: 268, value: "80" },
    { id: 269, value: "11" },
    { id: 270, value: "62" },
    { id: 271, value: "78" },
    { id: 272, value: "43" },
    { id: 273, value: "**" },
  ],
  [
    { id: 274, value: "02" },
    { id: 275, value: "48" },
    { id: 276, value: "31" },
    { id: 277, value: "95" },
    { id: 278, value: "38" },
    { id: 279, value: "72" },
    { id: 280, value: "**" },
  ],
  [
    { id: 281, value: "30" },
    { id: 282, value: "55" },
    { id: 283, value: "12" },
    { id: 284, value: "18" },
    { id: 285, value: "01" },
    { id: 286, value: "54" },
    { id: 287, value: "**" },
  ],
  [
    { id: 288, value: "72" },
    { id: 289, value: "27" },
    { id: 290, value: "33" },
    { id: 291, value: "09" },
    { id: 292, value: "96" },
    { id: 293, value: "04" },
    { id: 294, value: "**" },
  ],
  [
    { id: 295, value: "82" },
    { id: 296, value: "99" },
    { id: 297, value: "51" },
    { id: 298, value: "63" },
    { id: 299, value: "11" },
    { id: 300, value: "86" },
    { id: 301, value: "**" },
  ],
  [
    { id: 302, value: "68" },
    { id: 303, value: "60" },
    { id: 304, value: "83" },
    { id: 305, value: "80" },
    { id: 306, value: "78" },
    { id: 307, value: "32" },
    { id: 308, value: "**" },
  ],
  [
    { id: 309, value: "82" },
    { id: 310, value: "56" },
    { id: 311, value: "29" },
    { id: 312, value: "14" },
    { id: 313, value: "92" },
    { id: 314, value: "04" },
    { id: 315, value: "**" },
  ],
  [
    { id: 316, value: "16" },
    { id: 317, value: "33" },
    { id: 318, value: "09" },
    { id: 319, value: "42" },
    { id: 320, value: "**" },
    { id: 321, value: "**" },
    { id: 322, value: "**" },
  ],
  [
    { id: 330, value: "**" },
    { id: 331, value: "**" },
    { id: 332, value: "**" },
    { id: 333, value: "**" },
    { id: 334, value: "**" },
    { id: 335, value: "**" },
    { id: 336, value: "**" },
  ],
  [
    { id: 337, value: "39" },
    { id: 338, value: "15" },
    { id: 339, value: "49" },
    { id: 340, value: "46" },
    { id: 341, value: "06" },
    { id: 342, value: "89" },
    { id: 343, value: "**" },
  ],
  [
    { id: 344, value: "10" },
    { id: 345, value: "03" },
    { id: 346, value: "01" },
    { id: 347, value: "52" },
    { id: 348, value: "51" },
    { id: 349, value: "62" },
    { id: 350, value: "**" },
  ],
  [
    { id: 351, value: "23" },
    { id: 352, value: "49" },
    { id: 353, value: "55" },
    { id: 354, value: "99" },
    { id: 355, value: "70" },
    { id: 356, value: "39" },
    { id: 357, value: "**" },
  ],
  [
    { id: 358, value: "35" },
    { id: 359, value: "84" },
    { id: 360, value: "49" },
    { id: 361, value: "74" },
    { id: 362, value: "54" },
    { id: 363, value: "81" },
    { id: 364, value: "**" },
  ],
  [
    { id: 365, value: "53" },
    { id: 366, value: "43" },
    { id: 367, value: "23" },
    { id: 368, value: "58" },
    { id: 369, value: "58" },
    { id: 370, value: "44" },
    { id: 371, value: "**" },
  ],
  [
    { id: 372, value: "11" },
    { id: 373, value: "63" },
    { id: 374, value: "55" },
    { id: 375, value: "91" },
    { id: 376, value: "58" },
    { id: 377, value: "81" },
    { id: 378, value: "**" },
  ],
  [
    { id: 379, value: "15" },
    { id: 380, value: "72" },
    { id: 381, value: "26" },
    { id: 382, value: "96" },
    { id: 383, value: "16" },
    { id: 384, value: "55" },
    { id: 385, value: "**" },
  ],
  [
    { id: 386, value: "76" },
    { id: 387, value: "74" },
    { id: 388, value: "88" },
    { id: 389, value: "76" },
    { id: 390, value: "93" },
    { id: 391, value: "98" },
    { id: 392, value: "**" },
  ],
  [
    { id: 393, value: "19" },
    { id: 394, value: "56" },
    { id: 395, value: "81" },
    { id: 396, value: "17" },
    { id: 397, value: "02" },
    { id: 398, value: "96" },
    { id: 399, value: "**" },
  ],
  [
    { id: 400, value: "39" },
    { id: 401, value: "87" },
    { id: 402, value: "52" },
    { id: 403, value: "65" },
    { id: 404, value: "**" },
    { id: 405, value: "02" },
    { id: 406, value: "**" },
  ],
  [
    { id: 407, value: "17" },
    { id: 408, value: "64" },
    { id: 409, value: "07" },
    { id: 410, value: "26" },
    { id: 411, value: "91" },
    { id: 412, value: "98" },
    { id: 413, value: "**" },
  ],
  [
    { id: 414, value: "98" },
    { id: 415, value: "30" },
    { id: 416, value: "62" },
    { id: 417, value: "13" },
    { id: 418, value: "17" },
    { id: 419, value: "01" },
    { id: 420, value: "**" },
  ],
  [
    { id: 421, value: "06" },
    { id: 422, value: "80" },
    { id: 423, value: "95" },
    { id: 424, value: "43" },
    { id: 425, value: "55" },
    { id: 426, value: "78" },
    { id: 427, value: "**" },
  ],
  [
    { id: 428, value: "63" },
    { id: 429, value: "51" },
    { id: 430, value: "15" },
    { id: 431, value: "41" },
    { id: 432, value: "71" },
    { id: 433, value: "40" },
    { id: 434, value: "**" },
  ],
  [
    { id: 435, value: "97" },
    { id: 436, value: "08" },
    { id: 437, value: "09" },
    { id: 438, value: "26" },
    { id: 439, value: "27" },
    { id: 440, value: "76" },
    { id: 441, value: "**" },
  ],
  [
    { id: 442, value: "76" },
    { id: 443, value: "73" },
    { id: 444, value: "78" },
    { id: 445, value: "99" },
    { id: 446, value: "71" },
    { id: 447, value: "71" },
    { id: 448, value: "**" },
  ],
  [
    { id: 449, value: "83" },
    { id: 450, value: "16" },
    { id: 451, value: "59" },
    { id: 452, value: "08" },
    { id: 453, value: "54" },
    { id: 454, value: "65" },
    { id: 455, value: "**" },
  ],
  [
    { id: 456, value: "77" },
    { id: 457, value: "75" },
    { id: 458, value: "11" },
    { id: 459, value: "98" },
    { id: 460, value: "70" },
    { id: 461, value: "01" },
    { id: 462, value: "**" },
  ],
  [
    { id: 463, value: "**" },
    { id: 464, value: "94" },
    { id: 465, value: "83" },
    { id: 466, value: "04" },
    { id: 467, value: "49" },
    { id: 468, value: "79" },
    { id: 469, value: "**" },
  ],
  [
    { id: 1, value: "10" },
    { id: 2, value: "92" },
    { id: 3, value: "91" },
    { id: 4, value: "03" },
    { id: 5, value: "18" },
    { id: 6, value: "63" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "25" },
    { id: 9, value: "60" },
    { id: 10, value: "56" },
    { id: 11, value: "37" },
    { id: 12, value: "21" },
    { id: 13, value: "26" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "70" },
    { id: 16, value: "72" },
    { id: 17, value: "25" },
    { id: 18, value: "88" },
    { id: 19, value: "86" },
    { id: 20, value: "04" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "27" },
    { id: 23, value: "07" },
    { id: 24, value: "66" },
    { id: 25, value: "06" },
    { id: 26, value: "31" },
    { id: 27, value: "80" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "33" },
    { id: 30, value: "89" },
    { id: 31, value: "95" },
    { id: 32, value: "89" },
    { id: 33, value: "56" },
    { id: 34, value: "66" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "64" },
    { id: 37, value: "79" },
    { id: 38, value: "54" },
    { id: 39, value: "29" },
    { id: 40, value: "94" },
    { id: 41, value: "77" },
    { id: 42, value: "**" },
  ],
  [
    { id: 43, value: "82" },
    { id: 44, value: "29" },
    { id: 45, value: "49" },
    { id: 46, value: "65" },
    { id: 47, value: "08" },
    { id: 48, value: "37" },
    { id: 49, value: "**" },
  ],
  [
    { id: 50, value: "65" },
    { id: 51, value: "77" },
    { id: 52, value: "94" },
    { id: 53, value: "43" },
    { id: 54, value: "35" },
    { id: 55, value: "38" },
    { id: 56, value: "**" },
  ],
  [
    { id: 57, value: "59" },
    { id: 58, value: "02" },
    { id: 59, value: "86" },
    { id: 60, value: "16" },
    { id: 61, value: "10" },
    { id: 62, value: "98" },
    { id: 63, value: "**" },
  ],
  [
    { id: 64, value: "01" },
    { id: 65, value: "62" },
    { id: 66, value: "69" },
    { id: 67, value: "25" },
    { id: 68, value: "12" },
    { id: 69, value: "94" },
    { id: 70, value: "**" },
  ],
  [
    { id: 71, value: "86" },
    { id: 72, value: "44" },
    { id: 73, value: "15" },
    { id: 74, value: "39" },
    { id: 75, value: "60" },
    { id: 76, value: "78" },
    { id: 77, value: "**" },
  ],
  [
    { id: 78, value: "00" },
    { id: 79, value: "14" },
    { id: 80, value: "32" },
    { id: 81, value: "47" },
    { id: 82, value: "10" },
    { id: 83, value: "31" },
    { id: 84, value: "**" },
  ],
  [
    { id: 85, value: "40" },
    { id: 86, value: "66" },
    { id: 87, value: "34" },
    { id: 88, value: "16" },
    { id: 89, value: "88" },
    { id: 90, value: "49" },
    { id: 91, value: "**" },
  ],
  [
    { id: 92, value: "71" },
    { id: 93, value: "21" },
    { id: 94, value: "50" },
    { id: 95, value: "54" },
    { id: 96, value: "08" },
    { id: 97, value: "91" },
    { id: 98, value: "**" },
  ],
  [
    { id: 99, value: "50" },
    { id: 100, value: "96" },
    { id: 101, value: "41" },
    { id: 102, value: "38" },
    { id: 103, value: "67" },
    { id: 104, value: "83" },
    { id: 105, value: "**" },
  ],
  [
    { id: 106, value: "26" },
    { id: 107, value: "97" },
    { id: 108, value: "99" },
    { id: 109, value: "78" },
    { id: 110, value: "87" },
    { id: 111, value: "58" },
    { id: 112, value: "**" },
  ],
  [
    { id: 113, value: "17" },
    { id: 114, value: "65" },
    { id: 115, value: "57" },
    { id: 116, value: "89" },
    { id: 117, value: "20" },
    { id: 118, value: "28" },
    { id: 119, value: "**" },
  ],
  [
    { id: 120, value: "23" },
    { id: 121, value: "48" },
    { id: 122, value: "94" },
    { id: 123, value: "68" },
    { id: 124, value: "09" },
    { id: 125, value: "63" },
    { id: 126, value: "**" },
  ],
  [
    { id: 127, value: "26" },
    { id: 128, value: "30" },
    { id: 129, value: "94" },
    { id: 130, value: "11" },
    { id: 131, value: "79" },
    { id: 132, value: "40" },
    { id: 476, value: "**" },
  ],
];

// kalyan

export const Kalyan = [
  [
    { id: 1, value: "87" },
    { id: 2, value: "70" },
    { id: 3, value: "86" },
    { id: 4, value: "70" },
    { id: 5, value: "26" },
    { id: 6, value: "93" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "56" },
    { id: 9, value: "61" },
    { id: 10, value: "04" },
    { id: 11, value: "22" },
    { id: 12, value: "01" },
    { id: 13, value: "18" },
    { id: 14, value: "**" },
  ],

  [
    { id: 15, value: "02" },
    { id: 16, value: "15" },
    { id: 17, value: "15" },
    { id: 18, value: "69" },
    { id: 19, value: "96" },
    { id: 20, value: "82" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "94" },
    { id: 23, value: "56" },
    { id: 24, value: "80" },
    { id: 25, value: "**" },
    { id: 26, value: "89" },
    { id: 27, value: "15" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "48" },
    { id: 30, value: "38" },
    { id: 31, value: "48" },
    { id: 32, value: "30" },
    { id: 33, value: "47" },
    { id: 34, value: "12" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "44" },
    { id: 37, value: "95" },
    { id: 38, value: "11" },
    { id: 39, value: "22" },
    { id: 40, value: "22" },
    { id: 41, value: "92" },
    { id: 42, value: "**" },
  ],
  [
    { id: 43, value: "80" },
    { id: 44, value: "39" },
    { id: 45, value: "52" },
    { id: 46, value: "68" },
    { id: 47, value: "67" },
    { id: 48, value: "73" },
    { id: 49, value: "**" },
  ],
  [
    { id: 50, value: "43" },
    { id: 51, value: "11" },
    { id: 52, value: "50" },
    { id: 53, value: "12" },
    { id: 54, value: "26" },
    { id: 55, value: "56" },
    { id: 56, value: "**" },
  ],
  [
    { id: 57, value: "51" },
    { id: 58, value: "94" },
    { id: 59, value: "85" },
    { id: 60, value: "60" },
    { id: 61, value: "37" },
    { id: 62, value: "16" },
    { id: 63, value: "**" },
  ],
  [
    { id: 64, value: "14" },
    { id: 65, value: "34" },
    { id: 66, value: "**" },
    { id: 67, value: "93" },
    { id: 68, value: "61" },
    { id: 69, value: "64" },
    { id: 70, value: "**" },
  ],
  [
    { id: 71, value: "96" },
    { id: 72, value: "00" },
    { id: 73, value: "30" },
    { id: 74, value: "38" },
    { id: 75, value: "89" },
    { id: 76, value: "83" },
    { id: 77, value: "**" },
  ],
  [
    { id: 78, value: "76" },
    { id: 79, value: "22" },
    { id: 80, value: "07" },
    { id: 81, value: "97" },
    { id: 82, value: "01" },
    { id: 83, value: "58" },
    { id: 84, value: "**" },
  ],
  [
    { id: 85, value: "36" },
    { id: 86, value: "87" },
    { id: 87, value: "37" },
    { id: 88, value: "95" },
    { id: 89, value: "68" },
    { id: 90, value: "07" },
    { id: 91, value: "**" },
  ],
  [
    { id: 92, value: "60" },
    { id: 93, value: "81" },
    { id: 94, value: "43" },
    { id: 95, value: "72" },
    { id: 96, value: "26" },
    { id: 97, value: "98" },
    { id: 98, value: "**" },
  ],
  [
    { id: 99, value: "70" },
    { id: 100, value: "04" },
    { id: 101, value: "42" },
    { id: 102, value: "17" },
    { id: 103, value: "58" },
    { id: 104, value: "35" },
    { id: 105, value: "**" },
  ],
  [
    { id: 106, value: "12" },
    { id: 107, value: "76" },
    { id: 108, value: "51" },
    { id: 109, value: "27" },
    { id: 110, value: "52" },
    { id: 111, value: "04" },
    { id: 112, value: "**" },
  ],
  [
    { id: 113, value: "71" },
    { id: 114, value: "41" },
    { id: 115, value: "28" },
    { id: 116, value: "83" },
    { id: 117, value: "38" },
    { id: 118, value: "00" },
    { id: 119, value: "**" },
  ],
  [
    { id: 120, value: "88" },
    { id: 121, value: "55" },
    { id: 122, value: "63" },
    { id: 123, value: "79" },
    { id: 124, value: "71" },
    { id: 125, value: "62" },
    { id: 126, value: "**" },
  ],
  [
    { id: 127, value: "38" },
    { id: 128, value: "37" },
    { id: 129, value: "35" },
    { id: 130, value: "63" },
    { id: 131, value: "22" },
    { id: 132, value: "51" },
    { id: 133, value: "**" },
  ],
  [
    { id: 134, value: "71" },
    { id: 135, value: "15" },
    { id: 136, value: "00" },
    { id: 137, value: "42" },
    { id: 138, value: "06" },
    { id: 139, value: "45" },
    { id: 140, value: "**" },
  ],
  [
    { id: 141, value: "05" },
    { id: 142, value: "36" },
    { id: 143, value: "06" },
    { id: 144, value: "56" },
    { id: 145, value: "56" },
    { id: 146, value: "06" },
    { id: 147, value: "**" },
  ],
  [
    { id: 148, value: "54" },
    { id: 149, value: "58" },
    { id: 150, value: "20" },
    { id: 151, value: "86" },
    { id: 152, value: "37" },
    { id: 153, value: "32" },
    { id: 154, value: "**" },
  ],
  [
    { id: 155, value: "58" },
    { id: 156, value: "58" },
    { id: 157, value: "20" },
    { id: 158, value: "24" },
    { id: 159, value: "00" },
    { id: 160, value: "29" },
    { id: 161, value: "**" },
  ],
  [
    { id: 162, value: "92" },
    { id: 163, value: "78" },
    { id: 164, value: "24" },
    { id: 165, value: "19" },
    { id: 166, value: "01" },
    { id: 167, value: "52" },
    { id: 168, value: "**" },
  ],
  [
    { id: 169, value: "30" },
    { id: 170, value: "76" },
    { id: 171, value: "03" },
    { id: 172, value: "61" },
    { id: 173, value: "33" },
    { id: 174, value: "73" },
    { id: 175, value: "**" },
  ],
  [
    { id: 176, value: "55" },
    { id: 177, value: "41" },
    { id: 178, value: "24" },
    { id: 179, value: "20" },
    { id: 180, value: "26" },
    { id: 181, value: "31" },
    { id: 182, value: "**" },
  ],

  [
    { id: 183, value: "53" },
    { id: 184, value: "43" },
    { id: 185, value: "56" },
    { id: 186, value: "51" },
    { id: 187, value: "95" },
    { id: 188, value: "80" },
    { id: 189, value: "**" },
  ],
  [
    { id: 190, value: "27" },
    { id: 191, value: "53" },
    { id: 192, value: "61" },
    { id: 193, value: "94" },
    { id: 194, value: "61" },
    { id: 195, value: "46" },
    { id: 196, value: "**" },
  ],
  [
    { id: 197, value: "92" },
    { id: 198, value: "74" },
    { id: 199, value: "14" },
    { id: 200, value: "43" },
    { id: 201, value: "31" },
    { id: 202, value: "03" },
    { id: 203, value: "**" },
  ],
  [
    { id: 204, value: "63" },
    { id: 205, value: "92" },
    { id: 206, value: "83" },
    { id: 207, value: "74" },
    { id: 208, value: "86" },
    { id: 209, value: "40" },
    { id: 210, value: "**" },
  ],
  [
    { id: 211, value: "41" },
    { id: 212, value: "39" },
    { id: 213, value: "35" },
    { id: 214, value: "30" },
    { id: 215, value: "18" },
    { id: 216, value: "26" },
    { id: 217, value: "**" },
  ],
  [
    { id: 218, value: "96" },
    { id: 219, value: "48" },
    { id: 220, value: "10" },
    { id: 221, value: "59" },
    { id: 222, value: "78" },
    { id: 223, value: "57" },
    { id: 224, value: "**" },
  ],
  [
    { id: 225, value: "95" },
    { id: 226, value: "**" },
    { id: 227, value: "98" },
    { id: 228, value: "74" },
    { id: 229, value: "64" },
    { id: 230, value: "84" },
    { id: 231, value: "**" },
  ],
  [
    { id: 232, value: "13" },
    { id: 233, value: "00" },
    { id: 234, value: "92" },
    { id: 235, value: "01" },
    { id: 236, value: "76" },
    { id: 237, value: "67" },
    { id: 238, value: "**" },
  ],
  [
    { id: 239, value: "62" },
    { id: 240, value: "24" },
    { id: 241, value: "22" },
    { id: 242, value: "12" },
    { id: 243, value: "99" },
    { id: 244, value: "60" },
    { id: 245, value: "**" },
  ],
  [
    { id: 246, value: "18" },
    { id: 247, value: "29" },
    { id: 248, value: "25" },
    { id: 249, value: "19" },
    { id: 250, value: "18" },
    { id: 251, value: "51" },
    { id: 252, value: "**" },
  ],
  [
    { id: 253, value: "95" },
    { id: 254, value: "77" },
    { id: 255, value: "69" },
    { id: 256, value: "37" },
    { id: 257, value: "81" },
    { id: 258, value: "12" },
    { id: 259, value: "**" },
  ],
  [
    { id: 260, value: "57" },
    { id: 261, value: "32" },
    { id: 262, value: "03" },
    { id: 263, value: "70" },
    { id: 264, value: "95" },
    { id: 265, value: "54" },
    { id: 266, value: "**" },
  ],
  [
    { id: 267, value: "78" },
    { id: 268, value: "60" },
    { id: 269, value: "10" },
    { id: 270, value: "06" },
    { id: 271, value: "28" },
    { id: 272, value: "39" },
    { id: 273, value: "**" },
  ],
  [
    { id: 274, value: "68" },
    { id: 275, value: "33" },
    { id: 276, value: "00" },
    { id: 277, value: "45" },
    { id: 278, value: "52" },
    { id: 279, value: "01" },
    { id: 280, value: "**" },
  ],
  [
    { id: 281, value: "75" },
    { id: 282, value: "37" },
    { id: 283, value: "67" },
    { id: 284, value: "26" },
    { id: 285, value: "90" },
    { id: 286, value: "89" },
    { id: 287, value: "**" },
  ],
  [
    { id: 288, value: "67" },
    { id: 289, value: "90" },
    { id: 290, value: "95" },
    { id: 291, value: "44" },
    { id: 292, value: "35" },
    { id: 293, value: "72" },
    { id: 294, value: "**" },
  ],
  [
    { id: 295, value: "67" },
    { id: 296, value: "75" },
    { id: 297, value: "39" },
    { id: 298, value: "73" },
    { id: 299, value: "77" },
    { id: 300, value: "93" },
    { id: 301, value: "**" },
  ],
  [
    { id: 302, value: "04" },
    { id: 303, value: "83" },
    { id: 304, value: "08" },
    { id: 305, value: "51" },
    { id: 306, value: "41" },
    { id: 307, value: "00" },
    { id: 308, value: "**" },
  ],
  [
    { id: 309, value: "74" },
    { id: 310, value: "28" },
    { id: 311, value: "31" },
    { id: 312, value: "40" },
    { id: 313, value: "**" },
    { id: 313, value: "**" },
    { id: 313, value: "**" },
  ],
  [
    { id: 314, value: "**" },
    { id: 315, value: "**" },
    { id: 316, value: "**" },
    { id: 317, value: "**" },
    { id: 318, value: "**" },
    { id: 319, value: "**" },
    { id: 319, value: "**" },
  ],
  [
    { id: 320, value: "53" },
    { id: 321, value: "53" },
    { id: 322, value: "51" },
    { id: 323, value: "98" },
    { id: 324, value: "48" },
    { id: 325, value: "70" },
    { id: 326, value: "**" },
  ],
  [
    { id: 327, value: "76" },
    { id: 328, value: "13" },
    { id: 329, value: "56" },
    { id: 330, value: "06" },
    { id: 331, value: "33" },
    { id: 332, value: "31" },
    { id: 333, value: "**" },
  ],
  [
    { id: 334, value: "90" },
    { id: 335, value: "97" },
    { id: 336, value: "35" },
    { id: 337, value: "56" },
    { id: 338, value: "13" },
    { id: 339, value: "72" },
    { id: 340, value: "**" },
  ],
  [
    { id: 341, value: "69" },
    { id: 342, value: "46" },
    { id: 343, value: "96" },
    { id: 344, value: "38" },
    { id: 345, value: "48" },
    { id: 346, value: "65" },
    { id: 347, value: "**" },
  ],
  [
    { id: 348, value: "22" },
    { id: 349, value: "59" },
    { id: 350, value: "29" },
    { id: 351, value: "44" },
    { id: 352, value: "14" },
    { id: 353, value: "16" },
    { id: 354, value: "**" },
  ],
  [
    { id: 355, value: "48" },
    { id: 356, value: "02" },
    { id: 357, value: "24" },
    { id: 358, value: "53" },
    { id: 359, value: "54" },
    { id: 360, value: "97" },
    { id: 361, value: "**" },
  ],
  [
    { id: 362, value: "41" },
    { id: 363, value: "60" },
    { id: 364, value: "95" },
    { id: 365, value: "63" },
    { id: 366, value: "25" },
    { id: 367, value: "15" },
    { id: 368, value: "**" },
  ],
  [
    { id: 369, value: "73" },
    { id: 370, value: "42" },
    { id: 371, value: "29" },
    { id: 372, value: "63" },
    { id: 373, value: "38" },
    { id: 374, value: "12" },
    { id: 375, value: "**" },
  ],
  [
    { id: 376, value: "75" },
    { id: 377, value: "75" },
    { id: 378, value: "27" },
    { id: 379, value: "05" },
    { id: 380, value: "25" },
    { id: 381, value: "37" },
    { id: 382, value: "**" },
  ],
  [
    { id: 383, value: "30" },
    { id: 384, value: "37" },
    { id: 385, value: "51" },
    { id: 386, value: "14" },
    { id: 387, value: "**" },
    { id: 388, value: "20" },
    { id: 389, value: "**" },
  ],
  [
    { id: 390, value: "91" },
    { id: 391, value: "72" },
    { id: 392, value: "30" },
    { id: 393, value: "45" },
    { id: 394, value: "04" },
    { id: 395, value: "48" },
    { id: 396, value: "**" },
  ],
  [
    { id: 397, value: "16" },
    { id: 398, value: "62" },
    { id: 399, value: "45" },
    { id: 400, value: "72" },
    { id: 401, value: "87" },
    { id: 402, value: "98" },
    { id: 403, value: "**" },
  ],
  [
    { id: 404, value: "01" },
    { id: 405, value: "97" },
    { id: 406, value: "06" },
    { id: 407, value: "07" },
    { id: 408, value: "52" },
    { id: 409, value: "25" },
    { id: 410, value: "**" },
  ],
  [
    { id: 411, value: "44" },
    { id: 412, value: "04" },
    { id: 413, value: "52" },
    { id: 414, value: "40" },
    { id: 415, value: "03" },
    { id: 416, value: "89" },
    { id: 417, value: "**" },
  ],
  [
    { id: 418, value: "32" },
    { id: 419, value: "08" },
    { id: 420, value: "58" },
    { id: 421, value: "56" },
    { id: 422, value: "47" },
    { id: 423, value: "96" },
    { id: 424, value: "**" },
  ],
  [
    { id: 425, value: "93" },
    { id: 426, value: "76" },
    { id: 427, value: "37" },
    { id: 428, value: "72" },
    { id: 429, value: "03" },
    { id: 430, value: "03" },
    { id: 431, value: "**" },
  ],
  [
    { id: 432, value: "39" },
    { id: 433, value: "27" },
    { id: 434, value: "39" },
    { id: 435, value: "08" },
    { id: 436, value: "28" },
    { id: 437, value: "96" },
    { id: 438, value: "**" },
  ],
  [
    { id: 439, value: "59" },
    { id: 440, value: "60" },
    { id: 441, value: "27" },
    { id: 442, value: "33" },
    { id: 443, value: "66" },
    { id: 444, value: "40" },
    { id: 445, value: "**" },
  ],
  [
    { id: 446, value: "**" },
    { id: 447, value: "97" },
    { id: 448, value: "55" },
    { id: 449, value: "39" },
    { id: 450, value: "08" },
    { id: 451, value: "91" },
    { id: 452, value: "**" },
  ],
  [
    { id: 453, value: "96" },
    { id: 454, value: "64" },
    { id: 455, value: "97" },
    { id: 456, value: "41" },
    { id: 457, value: "49" },
    { id: 458, value: "36" },
    { id: 459, value: "**" },
  ],
  [
    { id: 460, value: "45" },
    { id: 461, value: "26" },
    { id: 462, value: "64" },
    { id: 463, value: "47" },
    { id: 464, value: "66" },
    { id: 465, value: "15" },
    { id: 466, value: "**" },
  ],
  [
    { id: 467, value: "77" },
    { id: 468, value: "66" },
    { id: 469, value: "24" },
    { id: 470, value: "64" },
    { id: 471, value: "13" },
    { id: 472, value: "84" },
    { id: 473, value: "**" },
  ],
  [
    { id: 474, value: "26" },
    { id: 475, value: "91" },
    { id: 476, value: "29" },
    { id: 477, value: "88" },
    { id: 478, value: "04" },
    { id: 479, value: "55" },
    { id: 480, value: "**" },
  ],
  [
    { id: 481, value: "95" },
    { id: 482, value: "37" },
    { id: 483, value: "92" },
    { id: 484, value: "99" },
    { id: 485, value: "70" },
    { id: 486, value: "82" },
    { id: 487, value: "**" },
  ],
  [
    { id: 488, value: "55" },
    { id: 489, value: "15" },
    { id: 490, value: "34" },
    { id: 491, value: "31" },
    { id: 492, value: "39" },
    { id: 493, value: "89" },
    { id: 493, value: "**" },
  ],
];
export const KalyanNight = [
  [
    { id: 1, value: "68" },
    { id: 2, value: "12" },
    { id: 3, value: "97" },
    { id: 4, value: "43" },
    { id: 5, value: "21" },
    { id: 6, value: "**" },
    { id: 7, value: "**" },
  ],
  [
    { id: 1, value: "36" },
    { id: 2, value: "87" },
    { id: 3, value: "70" },
    { id: 4, value: "74" },
    { id: 5, value: "51" },
    { id: 6, value: "**" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "04" },
    { id: 9, value: "68" },
    { id: 10, value: "60" },
    { id: 11, value: "60" },
    { id: 12, value: "78" },
    { id: 13, value: "**" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "36" },
    { id: 16, value: "69" },
    { id: 17, value: "39" },
    { id: 14, value: "**" },
    { id: 18, value: "42" },
    { id: 20, value: "**" },
    { id: 21, value: "**" },
  ],
  [
    { id: 19, value: "67" },
    { id: 22, value: "03" },
    { id: 23, value: "32" },
    { id: 24, value: "94" },
    { id: 25, value: "11" },
    { id: 27, value: "**" },
    { id: 28, value: "**" },
  ],
  [
    { id: 26, value: "57" },
    { id: 29, value: "44" },
    { id: 30, value: "61" },
    { id: 31, value: "98" },
    { id: 32, value: "50" },
    { id: 34, value: "**" },
    { id: 35, value: "**" },
  ],
  [
    { id: 43, value: "84" },
    { id: 44, value: "37" },
    { id: 45, value: "34" },
    { id: 46, value: "64" },
    { id: 47, value: "37" },
    { id: 48, value: "**" },
    { id: 49, value: "**" },
  ],
  [
    { id: 50, value: "15" },
    { id: 51, value: "05" },
    { id: 52, value: "59" },
    { id: 53, value: "55" },
    { id: 54, value: "97" },
    { id: 55, value: "**" },
    { id: 56, value: "**" },
  ],
  [
    { id: 57, value: "58" },
    { id: 58, value: "06" },
    { id: 62, value: "**" },
    { id: 59, value: "30" },
    { id: 60, value: "99" },
    { id: 61, value: "**" },
    { id: 63, value: "**" },
  ],
  [
    { id: 64, value: "29" },
    { id: 65, value: "40" },
    { id: 66, value: "38" },
    { id: 67, value: "99" },
    { id: 68, value: "29" },
    { id: 69, value: "**" },
    { id: 70, value: "**" },
  ],
  [
    { id: 71, value: "96" },
    { id: 72, value: "53" },
    { id: 73, value: "65" },
    { id: 74, value: "21" },
    { id: 75, value: "65" },
    { id: 76, value: "**" },
    { id: 77, value: "**" },
  ],
  [
    { id: 78, value: "03" },
    { id: 79, value: "88" },
    { id: 80, value: "71" },
    { id: 81, value: "94" },
    { id: 82, value: "38" },
    { id: 83, value: "**" },
    { id: 84, value: "**" },
  ],
  [
    { id: 85, value: "98" },
    { id: 86, value: "84" },
    { id: 87, value: "75" },
    { id: 88, value: "33" },
    { id: 89, value: "04" },
    { id: 90, value: "**" },
    { id: 91, value: "**" },
  ],
  [
    { id: 92, value: "00" },
    { id: 93, value: "04" },
    { id: 94, value: "41" },
    { id: 95, value: "47" },
    { id: 96, value: "13" },
    { id: 90, value: "**" },
    { id: 91, value: "**" },
  ],
  [
    { id: 99, value: "41" },
    { id: 99, value: "12" },
    { id: 100, value: "21" },
    { id: 101, value: "40" },
    { id: 102, value: "42" },
    { id: 104, value: "**" },
    { id: 105, value: "**" },
  ],
  [
    { id: 103, value: "76" },
    { id: 106, value: "20" },
    { id: 107, value: "04" },
    { id: 108, value: "09" },
    { id: 109, value: "74" },
    { id: 111, value: "**" },
    { id: 112, value: "**" },
  ],
  [
    { id: 110, value: "85" },
    { id: 113, value: "08" },
    { id: 114, value: "74" },
    { id: 115, value: "19" },
    { id: 116, value: "18" },
    { id: 118, value: "**" },
    { id: 119, value: "**" },
  ],
  [
    { id: 117, value: "15" },
    { id: 120, value: "82" },
    { id: 121, value: "77" },
    { id: 122, value: "92" },
    { id: 123, value: "93" },
    { id: 125, value: "**" },
    { id: 126, value: "**" },
  ],
  [
    { id: 124, value: "98" },
    { id: 127, value: "77" },
    { id: 128, value: "26" },
    { id: 129, value: "09" },
    { id: 130, value: "04" },
    { id: 132, value: "**" },
    { id: 133, value: "**" },
  ],
  [
    { id: 131, value: "79" },
    { id: 134, value: "00" },
    { id: 135, value: "44" },
    { id: 136, value: "06" },
    { id: 137, value: "28" },
    { id: 139, value: "**" },
    { id: 140, value: "**" },
  ],
  [
    { id: 138, value: "63" },
    { id: 141, value: "73" },
    { id: 142, value: "76" },
    { id: 143, value: "85" },
    { id: 144, value: "78" },
    { id: 139, value: "**" },
    { id: 140, value: "**" },
  ],
  [
    { id: 141, value: "39" },
    { id: 142, value: "56" },
    { id: 143, value: "34" },
    { id: 144, value: "31" },
    { id: 145, value: "26" },
    { id: 146, value: "**" },
    { id: 147, value: "**" },
  ],
  [
    { id: 148, value: "68" },
    { id: 149, value: "64" },
    { id: 150, value: "80" },
    { id: 151, value: "22" },
    { id: 152, value: "31" },
    { id: 153, value: "**" },
    { id: 154, value: "**" },
  ],
  [
    { id: 155, value: "21" },
    { id: 156, value: "22" },
    { id: 157, value: "92" },
    { id: 158, value: "04" },
    { id: 159, value: "56" },
    { id: 160, value: "**" },
    { id: 161, value: "**" },
  ],
  [
    { id: 162, value: "56" },
    { id: 163, value: "22" },
    { id: 164, value: "37" },
    { id: 165, value: "25" },
    { id: 166, value: "69" },
    { id: 167, value: "**" },
    { id: 168, value: "**" },
  ],
  [
    { id: 169, value: "58" },
    { id: 170, value: "03" },
    { id: 171, value: "60" },
    { id: 172, value: "96" },
    { id: 173, value: "21" },
    { id: 174, value: "**" },
    { id: 175, value: "**" },
  ],
  [
    { id: 176, value: "90" },
    { id: 177, value: "70" },
    { id: 178, value: "85" },
    { id: 179, value: "15" },
    { id: 180, value: "86" },
    { id: 181, value: "**" },
    { id: 182, value: "**" },
  ],
  [
    { id: 183, value: "98" },
    { id: 184, value: "38" },
    { id: 185, value: "16" },
    { id: 186, value: "82" },
    { id: 187, value: "70" },
    { id: 181, value: "**" },
    { id: 182, value: "**" },
  ],
  [
    { id: 183, value: "59" },
    { id: 184, value: "17" },
    { id: 185, value: "17" },
    { id: 186, value: "15" },
    { id: 187, value: "66" },
    { id: 188, value: "**" },
    { id: 189, value: "**" },
  ],
  [
    { id: 190, value: "19" },
    { id: 191, value: "40" },
    { id: 192, value: "40" },
    { id: 193, value: "97" },
    { id: 194, value: "89" },
    { id: 195, value: "**" },
    { id: 196, value: "**" },
  ],
  [
    { id: 197, value: "17" },
    { id: 198, value: "77" },
    { id: 199, value: "24" },
    { id: 200, value: "14" },
    { id: 201, value: "96" },
    { id: 195, value: "**" },
    { id: 196, value: "**" },
  ],
  [
    { id: 202, value: "22" },
    { id: 203, value: "**" },
    { id: 204, value: "89" },
    { id: 205, value: "55" },
    { id: 206, value: "89" },
    { id: 195, value: "**" },
    { id: 196, value: "**" },
  ],
  [
    { id: 207, value: "28" },
    { id: 208, value: "02" },
    { id: 209, value: "28" },
    { id: 210, value: "32" },
    { id: 211, value: "50" },
    { id: 212, value: "**" },
    { id: 213, value: "**" },
  ],
  [
    { id: 214, value: "29" },
    { id: 215, value: "55" },
    { id: 216, value: "84" },
    { id: 217, value: "86" },
    { id: 218, value: "81" },
    { id: 219, value: "**" },
    { id: 220, value: "**" },
  ],
  [
    { id: 221, value: "97" },
    { id: 222, value: "00" },
    { id: 223, value: "80" },
    { id: 224, value: "16" },
    { id: 225, value: "01" },
    { id: 226, value: "**" },
    { id: 227, value: "**" },
  ],
  [
    { id: 228, value: "87" },
    { id: 229, value: "78" },
    { id: 230, value: "07" },
    { id: 231, value: "84" },
    { id: 232, value: "71" },
    { id: 233, value: "**" },
    { id: 234, value: "**" },
  ],
  [
    { id: 235, value: "18" },
    { id: 236, value: "57" },
    { id: 237, value: "61" },
    { id: 238, value: "01" },
    { id: 239, value: "60" },
    { id: 240, value: "**" },
    { id: 241, value: "**" },
  ],
  [
    { id: 242, value: "35" },
    { id: 243, value: "58" },
    { id: 244, value: "29" },
    { id: 245, value: "55" },
    { id: 246, value: "09" },
    { id: 247, value: "**" },
    { id: 248, value: "**" },
  ],
  [
    { id: 249, value: "23" },
    { id: 250, value: "81" },
    { id: 251, value: "78" },
    { id: 252, value: "11" },
    { id: 253, value: "23" },
    { id: 254, value: "**" },
    { id: 255, value: "**" },
  ],
  [
    { id: 256, value: "31" },
    { id: 257, value: "25" },
    { id: 258, value: "50" },
    { id: 259, value: "77" },
    { id: 260, value: "21" },
    { id: 261, value: "**" },
    { id: 262, value: "**" },
  ],
  [
    { id: 263, value: "66" },
    { id: 264, value: "28" },
    { id: 265, value: "29" },
    { id: 266, value: "83" },
    { id: 267, value: "59" },
    { id: 268, value: "**" },
    { id: 269, value: "**" },
  ],
  [
    { id: 270, value: "02" },
    { id: 271, value: "24" },
    { id: 272, value: "65" },
    { id: 273, value: "65" },
    { id: 274, value: "44" },
    { id: 275, value: "**" },
    { id: 276, value: "**" },
  ],
  [
    { id: 277, value: "54" },
    { id: 278, value: "40" },
    { id: 279, value: "46" },
    { id: 280, value: "41" },
    { id: 281, value: "23" },
    { id: 275, value: "**" },
    { id: 276, value: "**" },
  ],
  [
    { id: 282, value: "63" },
    { id: 283, value: "10" },
    { id: 284, value: "69" },
    { id: 285, value: "93" },
    { id: 286, value: "**" },
    { id: 287, value: "**" },
    { id: 288, value: "**" },
  ],
  [
    { id: 289, value: "**" },
    { id: 289, value: "**" },
    { id: 289, value: "**" },
    { id: 289, value: "**" },
    { id: 289, value: "**" },
    { id: 289, value: "**" },
    { id: 289, value: "**" },
  ],
  [
    { id: 290, value: "39" },
    { id: 291, value: "86" },
    { id: 292, value: "66" },
    { id: 293, value: "94" },
    { id: 294, value: "58" },
    { id: 295, value: "**" },
    { id: 296, value: "**" },
  ],
  [
    { id: 297, value: "37" },
    { id: 298, value: "08" },
    { id: 299, value: "67" },
    { id: 300, value: "76" },
    { id: 301, value: "95" },
    { id: 302, value: "**" },
    { id: 303, value: "**" },
  ],
  [
    { id: 304, value: "04" },
    { id: 305, value: "41" },
    { id: 306, value: "37" },
    { id: 307, value: "59" },
    { id: 308, value: "03" },
    { id: 309, value: "**" },
    { id: 310, value: "**" },
  ],
  [
    { id: 311, value: "98" },
    { id: 312, value: "67" },
    { id: 313, value: "82" },
    { id: 314, value: "87" },
    { id: 315, value: "82" },
    { id: 316, value: "**" },
    { id: 317, value: "**" },
  ],
  [
    { id: 318, value: "90" },
    { id: 319, value: "41" },
    { id: 320, value: "86" },
    { id: 321, value: "53" },
    { id: 322, value: "45" },
    { id: 323, value: "**" },
    { id: 324, value: "**" },
  ],
  [
    { id: 325, value: "79" },
    { id: 326, value: "03" },
    { id: 327, value: "60" },
    { id: 328, value: "53" },
    { id: 329, value: "59" },
    { id: 330, value: "**" },
    { id: 331, value: "**" },
  ],
  [
    { id: 332, value: "93" },
    { id: 333, value: "42" },
    { id: 334, value: "98" },
    { id: 335, value: "76" },
    { id: 336, value: "42" },
    { id: 337, value: "**" },
    { id: 338, value: "**" },
  ],
  [
    { id: 339, value: "19" },
    { id: 340, value: "47" },
    { id: 341, value: "47" },
    { id: 342, value: "75" },
    { id: 343, value: "36" },
    { id: 344, value: "**" },
    { id: 345, value: "**" },
  ],
  [
    { id: 346, value: "36" },
    { id: 347, value: "29" },
    { id: 348, value: "79" },
    { id: 349, value: "97" },
    { id: 350, value: "51" },
    { id: 344, value: "**" },
    { id: 345, value: "**" },
  ],
  [
    { id: 347, value: "79" },
    { id: 348, value: "68" },
    { id: 349, value: "35" },
    { id: 350, value: "32" },
    { id: 351, value: "**" },
    { id: 351, value: "**" },
    { id: 352, value: "**" },
  ],
  [
    { id: 353, value: "70" },
    { id: 354, value: "70" },
    { id: 355, value: "34" },
    { id: 356, value: "30" },
    { id: 357, value: "42" },
    { id: 360, value: "**" },
    { id: 361, value: "**" },
  ],
  [
    { id: 358, value: "95" },
    { id: 359, value: "85" },
    { id: 362, value: "62" },
    { id: 363, value: "80" },
    { id: 364, value: "91" },
    { id: 367, value: "**" },
    { id: 368, value: "**" },
  ],
  [
    { id: 365, value: "28" },
    { id: 366, value: "31" },
    { id: 369, value: "63" },
    { id: 370, value: "51" },
    { id: 371, value: "54" },
    { id: 374, value: "**" },
    { id: 375, value: "**" },
  ],
  [
    { id: 372, value: "28" },
    { id: 373, value: "35" },
    { id: 376, value: "24" },
    { id: 377, value: "83" },
    { id: 378, value: "52" },
    { id: 381, value: "**" },
    { id: 382, value: "**" },
  ],
  [
    { id: 379, value: "90" },
    { id: 380, value: "28" },
    { id: 383, value: "87" },
    { id: 384, value: "66" },
    { id: 385, value: "28" },
    { id: 388, value: "**" },
    { id: 389, value: "**" },
  ],
  [
    { id: 386, value: "44" },
    { id: 387, value: "06" },
    { id: 390, value: "96" },
    { id: 391, value: "33" },
    { id: 392, value: "52" },
    { id: 395, value: "**" },
    { id: 396, value: "**" },
  ],
  [
    { id: 533, value: "77" },
    { id: 534, value: "80" },
    { id: 535, value: "21" },
    { id: 536, value: "67" },
    { id: 537, value: "25" },
    { id: 538, value: "**" },
    { id: 539, value: "**" },
  ],
  [
    { id: 540, value: "74" },
    { id: 541, value: "05" },
    { id: 542, value: "59" },
    { id: 543, value: "33" },
    { id: 544, value: "63" },
    { id: 545, value: "**" },
    { id: 546, value: "**" },
  ],
  [
    { id: 552, value: "**" },
    { id: 547, value: "44" },
    { id: 548, value: "31" },
    { id: 549, value: "32" },
    { id: 550, value: "29" },
    { id: 552, value: "**" },
    { id: 553, value: "**" },
  ],
  [
    { id: 533, value: "64" },
    { id: 534, value: "33" },
    { id: 535, value: "15" },
    { id: 536, value: "03" },
    { id: 537, value: "45" },
    { id: 538, value: "**" },
    { id: 539, value: "**" },
  ],
  [
    { id: 540, value: "01" },
    { id: 541, value: "01" },
    { id: 542, value: "07" },
    { id: 543, value: "65" },
    { id: 544, value: "16" },
    { id: 545, value: "**" },
    { id: 546, value: "**" },
  ],
  [
    { id: 547, value: "29" },
    { id: 548, value: "16" },
    { id: 549, value: "96" },
    { id: 550, value: "71" },
    { id: 551, value: "75" },
    { id: 552, value: "**" },
    { id: 553, value: "**" },
  ],
  [
    { id: 554, value: "64" },
    { id: 555, value: "12" },
    { id: 556, value: "42" },
    { id: 557, value: "66" },
    { id: 558, value: "61" },
    { id: 559, value: "**" },
    { id: 560, value: "**" },
  ],
  [
    { id: 561, value: "16" },
    { id: 562, value: "62" },
    { id: 563, value: "26" },
    { id: 564, value: "32" },
    { id: 565, value: "50" },
    { id: 566, value: "**" },
    { id: 567, value: "**" },
  ],
  [
    { id: 568, value: "49" },
    { id: 569, value: "22" },
    { id: 570, value: "46" },
    { id: 571, value: "42" },
    { id: 572, value: "55" },
    { id: 573, value: "**" },
    { id: 574, value: "**" },
  ],
  [
    { id: 575, value: "64" },
    { id: 576, value: "07" },
    { id: 577, value: "05" },
    { id: 578, value: "24" },
    { id: 579, value: "20" },
    { id: 580, value: "**" },
    { id: 581, value: "**" },
  ],
  [
    { id: 582, value: "75" },
    { id: 583, value: "51" },
    { id: 584, value: "95" },
    { id: 585, value: "77" },
    { id: 586, value: "60" },
    { id: 587, value: "**" },
    { id: 588, value: "**" },
  ],
  [
    { id: 589, value: "71" },
    { id: 590, value: "33" },
    { id: 591, value: "07" },
    { id: 592, value: "59" },
    { id: 593, value: "68" },
    { id: 594, value: "**" },
    { id: 595, value: "**" },
  ],
  [
    { id: 596, value: "30" },
    { id: 597, value: "59" },
    { id: 598, value: "34" },
    { id: 599, value: "60" },
    { id: 600, value: "02" },
    { id: 601, value: "**" },
    { id: 602, value: "**" },
  ],
  [
    { id: 603, value: "78" },
    { id: 604, value: "67" },
    { id: 605, value: "70" },
    { id: 606, value: "60" },
    { id: 607, value: "23" },
    { id: 608, value: "**" },
    { id: 609, value: "**" },
  ],
  [
    { id: 610, value: "07" },
    { id: 611, value: "76" },
    { id: 612, value: "36" },
    { id: 613, value: "61" },
    { id: 614, value: "68" },
    { id: 615, value: "**" },
    { id: 616, value: "**" },
  ],
  [
    { id: 617, value: "81" },
    { id: 618, value: "84" },
    { id: 619, value: "84" },
    { id: 620, value: "91" },
    { id: 621, value: "18" },
    { id: 622, value: "**" },
    { id: 623, value: "**" },
  ],
  [
    { id: 624, value: "74" },
    { id: 625, value: "43" },
    { id: 626, value: "79" },
    { id: 627, value: "47" },
    { id: 628, value: "05" },
    { id: 629, value: "**" },
    { id: 630, value: "**" },
  ],
  [
    { id: 631, value: "00" },
    { id: 632, value: "60" },
    { id: 633, value: "12" },
    { id: 634, value: "31" },
    { id: 635, value: "12" },
    { id: 636, value: "**" },
    { id: 637, value: "**" },
  ],
  [
    { id: 638, value: "57" },
    { id: 639, value: "77" },
    { id: 640, value: "15" },
    { id: 641, value: "97" },
    { id: 642, value: "77" },
    { id: 643, value: "**" },
    { id: 644, value: "**" },
  ],
  [
    { id: 533, value: "34" },
    { id: 534, value: "85" },
    { id: 535, value: "61" },
    { id: 536, value: "**" },
    { id: 537, value: "52" },

    { id: 545, value: "**" },
    { id: 546, value: "**" },
  ],
  [
    { id: 538, value: "81" },
    { id: 539, value: "14" },
    { id: 540, value: "52" },
    { id: 541, value: "41" },
    { id: 542, value: "38" },
    { id: 545, value: "**" },
    { id: 546, value: "**" },
  ],
  [
    { id: 543, value: "21" },
    { id: 544, value: "61" },
    { id: 547, value: "82" },
    { id: 548, value: "31" },
    { id: 549, value: "01" },
    { id: 553, value: "**" },
    { id: 553, value: "**" },
  ],
];
// sridevinight

export const ShreeDeviNight = [
  [
    { id: 1, value: "87" },
    { id: 2, value: "70" },
    { id: 3, value: "86" },
    { id: 4, value: "70" },
    { id: 5, value: "26" },
    { id: 6, value: "93" },
    { id: 8, value: "56" },
  ],
  [
    { id: 9, value: "61" },
    { id: 10, value: "04" },
    { id: 11, value: "22" },
    { id: 12, value: "01" },
    { id: 13, value: "18" },
    { id: 14, value: "40" },
    { id: 14, value: "41" },
  ],
  [
    { id: 1, value: "19" },
    { id: 2, value: "94" },
    { id: 3, value: "93" },
    { id: 4, value: "82" },
    { id: 5, value: "68" },
    { id: 6, value: "11" },
    { id: 7, value: "71" },
  ],
  [
    { id: 8, value: "47" },
    { id: 9, value: "85" },
    { id: 10, value: "65" },
    { id: 11, value: "98" },
    { id: 12, value: "**" },
    { id: 13, value: "21" },
    { id: 14, value: "39" },
  ],
  [
    { id: 15, value: "19" },
    { id: 16, value: "23" },
    { id: 17, value: "87" },
    { id: 18, value: "82" },
    { id: 19, value: "40" },
    { id: 20, value: "59" },
    { id: 21, value: "64" },
  ],
  [
    { id: 22, value: "44" },
    { id: 23, value: "88" },
    { id: 24, value: "60" },
    { id: 25, value: "08" },
    { id: 26, value: "83" },
    { id: 27, value: "22" },
    { id: 28, value: "48" },
  ],
  [
    { id: 29, value: "22" },
    { id: 30, value: "88" },
    { id: 31, value: "45" },
    { id: 32, value: "60" },
    { id: 33, value: "77" },
    { id: 34, value: "92" },
    { id: 35, value: "90" },
  ],
  [
    { id: 36, value: "48" },
    { id: 37, value: "60" },
    { id: 38, value: "51" },
    { id: 39, value: "65" },
    { id: 40, value: "79" },
    { id: 41, value: "33" },
    { id: 42, value: "26" },
  ],
  [
    { id: 43, value: "27" },
    { id: 44, value: "17" },
    { id: 45, value: "80" },
    { id: 46, value: "58" },
    { id: 47, value: "58" },
    { id: 48, value: "92" },
    { id: 49, value: "15" },
  ],
  [
    { id: 50, value: "69" },
    { id: 51, value: "00" },
    { id: 52, value: "08" },
    { id: 53, value: "19" },
    { id: 54, value: "79" },
    { id: 55, value: "15" },
    { id: 56, value: "39" },
  ],
  [
    { id: 57, value: "07" },
    { id: 58, value: "28" },
    { id: 59, value: "09" },
    { id: 60, value: "16" },
    { id: 61, value: "74" },
    { id: 62, value: "16" },
    { id: 63, value: "28" },
  ],
  [
    { id: 64, value: "55" },
    { id: 65, value: "48" },
    { id: 66, value: "15" },
    { id: 67, value: "81" },
    { id: 68, value: "09" },
    { id: 69, value: "05" },
    { id: 70, value: "61" },
  ],
  [
    { id: 71, value: "**" },
    { id: 72, value: "51" },
    { id: 73, value: "27" },
    { id: 74, value: "74" },
    { id: 75, value: "49" },
    { id: 76, value: "39" },
    { id: 77, value: "78" },
  ],
  [
    { id: 78, value: "07" },
    { id: 79, value: "00" },
    { id: 80, value: "97" },
    { id: 81, value: "48" },
    { id: 82, value: "95" },
    { id: 83, value: "43" },
    { id: 84, value: "12" },
  ],
  [
    { id: 85, value: "07" },
    { id: 86, value: "44" },
    { id: 87, value: "56" },
    { id: 88, value: "80" },
    { id: 89, value: "90" },
    { id: 90, value: "81" },
    { id: 91, value: "35" },
  ],
  [
    { id: 92, value: "97" },
    { id: 93, value: "21" },
    { id: 94, value: "94" },
    { id: 95, value: "14" },
    { id: 96, value: "36" },
    { id: 97, value: "41" },
    { id: 98, value: "83" },
  ],
  [
    { id: 99, value: "24" },
    { id: 100, value: "73" },
    { id: 101, value: "78" },
    { id: 102, value: "86" },
    { id: 103, value: "55" },
    { id: 104, value: "14" },
    { id: 105, value: "60" },
  ],
  [
    { id: 106, value: "55" },
    { id: 107, value: "21" },
    { id: 108, value: "20" },
    { id: 109, value: "85" },
    { id: 110, value: "35" },
    { id: 111, value: "14" },
    { id: 112, value: "13" },
  ],
  [
    { id: 113, value: "64" },
    { id: 114, value: "57" },
    { id: 115, value: "23" },
    { id: 116, value: "51" },
    { id: 117, value: "22" },
    { id: 118, value: "17" },
    { id: 119, value: "84" },
  ],
  [
    { id: 120, value: "61" },
    { id: 121, value: "16" },
    { id: 122, value: "80" },
    { id: 123, value: "44" },
    { id: 124, value: "07" },
    { id: 125, value: "01" },
    { id: 126, value: "93" },
  ],
  [
    { id: 127, value: "98" },
    { id: 128, value: "77" },
    { id: 129, value: "72" },
    { id: 130, value: "41" },
    { id: 131, value: "90" },
    { id: 132, value: "07" },
    { id: 133, value: "47" },
  ],
  [
    { id: 134, value: "89" },
    { id: 135, value: "41" },
    { id: 136, value: "02" },
    { id: 137, value: "45" },
    { id: 138, value: "88" },
    { id: 139, value: "35" },
    { id: 140, value: "40" },
  ],
  [
    { id: 141, value: "45" },
    { id: 142, value: "62" },
    { id: 143, value: "60" },
    { id: 144, value: "35" },
    { id: 145, value: "62" },
    { id: 146, value: "00" },
    { id: 147, value: "72" },
  ],
  [
    { id: 148, value: "98" },
    { id: 149, value: "82" },
    { id: 150, value: "92" },
    { id: 151, value: "96" },
    { id: 152, value: "13" },
    { id: 153, value: "13" },
    { id: 154, value: "64" },
  ],
  [
    { id: 155, value: "77" },
    { id: 156, value: "44" },
    { id: 157, value: "94" },
    { id: 158, value: "85" },
    { id: 159, value: "01" },
    { id: 160, value: "62" },
    { id: 161, value: "87" },
  ],
  [
    { id: 162, value: "03" },
    { id: 163, value: "03" },
    { id: 164, value: "44" },
    { id: 165, value: "24" },
    { id: 166, value: "69" },
    { id: 167, value: "64" },
    { id: 168, value: "39" },
  ],
  [
    { id: 169, value: "78" },
    { id: 170, value: "21" },
    { id: 171, value: "04" },
    { id: 172, value: "36" },
    { id: 173, value: "39" },
    { id: 174, value: "00" },
    { id: 175, value: "60" },
  ],
  [
    { id: 176, value: "01" },
    { id: 177, value: "29" },
    { id: 178, value: "61" },
    { id: 179, value: "31" },
    { id: 180, value: "31" },
    { id: 181, value: "79" },
    { id: 182, value: "76" },
  ],
  [
    { id: 183, value: "53" },
    { id: 184, value: "22" },
    { id: 185, value: "78" },
    { id: 186, value: "30" },
    { id: 187, value: "61" },
    { id: 188, value: "90" },
    { id: 189, value: "30" },
  ],
  [
    { id: 190, value: "61" },
    { id: 191, value: "35" },
    { id: 192, value: "78" },
    { id: 193, value: "33" },
    { id: 194, value: "44" },
    { id: 195, value: "27" },
    { id: 196, value: "23" },
  ],
];

// MILAN NIGHT

export const MilanNight = [
  [
    { id: 1, value: "26" },
    { id: 2, value: "04" },
    { id: 3, value: "70" },
    { id: 4, value: "96" },
    { id: 5, value: "81" },
    { id: 6, value: "07" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "59" },
    { id: 9, value: "07" },
    { id: 10, value: "03" },
    { id: 11, value: "54" },
    { id: 12, value: "92" },
    { id: 13, value: "97" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "00" },
    { id: 16, value: "25" },
    { id: 17, value: "43" },
    { id: 18, value: "71" },
    { id: 19, value: "29" },
    { id: 20, value: "06" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "21" },
    { id: 23, value: "48" },
    { id: 24, value: "91" },
    { id: 25, value: "**" },
    { id: 26, value: "80" },
    { id: 27, value: "69" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "81" },
    { id: 30, value: "67" },
    { id: 31, value: "53" },
    { id: 32, value: "82" },
    { id: 33, value: "03" },
    { id: 34, value: "26" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "98" },
    { id: 37, value: "13" },
    { id: 38, value: "42" },
    { id: 39, value: "51" },
    { id: 40, value: "89" },
    { id: 41, value: "44" },
    { id: 42, value: "**" },
  ],
  [
    { id: 43, value: "60" },
    { id: 44, value: "67" },
    { id: 45, value: "51" },
    { id: 46, value: "19" },
    { id: 47, value: "71" },
    { id: 48, value: "25" },
    { id: 49, value: "**" },
  ],
  [
    { id: 50, value: "91" },
    { id: 51, value: "45" },
    { id: 52, value: "75" },
    { id: 53, value: "02" },
    { id: 54, value: "01" },
    { id: 55, value: "63" },
    { id: 56, value: "**" },
  ],
  [
    { id: 57, value: "26" },
    { id: 58, value: "47" },
    { id: 59, value: "17" },
    { id: 60, value: "98" },
    { id: 61, value: "80" },
    { id: 62, value: "53" },
    { id: 63, value: "**" },
  ],
  [
    { id: 64, value: "54" },
    { id: 65, value: "49" },
    { id: 66, value: "**" },
    { id: 67, value: "61" },
    { id: 68, value: "65" },
    { id: 69, value: "17" },
    { id: 70, value: "**" },
  ],
  [
    { id: 71, value: "66" },
    { id: 72, value: "28" },
    { id: 73, value: "12" },
    { id: 74, value: "23" },
    { id: 75, value: "41" },
    { id: 76, value: "25" },
    { id: 77, value: "**" },
  ],
  [
    { id: 78, value: "88" },
    { id: 79, value: "66" },
    { id: 80, value: "30" },
    { id: 81, value: "38" },
    { id: 82, value: "14" },
    { id: 83, value: "44" },
    { id: 84, value: "**" },
  ],
  [
    { id: 85, value: "80" },
    { id: 86, value: "36" },
    { id: 87, value: "63" },
    { id: 88, value: "86" },
    { id: 89, value: "20" },
    { id: 90, value: "90" },
    { id: 91, value: "**" },
  ],
  [
    { id: 92, value: "27" },
    { id: 93, value: "57" },
    { id: 94, value: "30" },
    { id: 95, value: "03" },
    { id: 96, value: "45" },
    { id: 97, value: "62" },
    { id: 98, value: "**" },
  ],
  [
    { id: 99, value: "12" },
    { id: 100, value: "85" },
    { id: 101, value: "46" },
    { id: 102, value: "21" },
    { id: 103, value: "16" },
    { id: 104, value: "13" },
    { id: 105, value: "**" },
  ],
  [
    { id: 106, value: "69" },
    { id: 107, value: "64" },
    { id: 108, value: "51" },
    { id: 109, value: "71" },
    { id: 110, value: "72" },
    { id: 111, value: "29" },
    { id: 112, value: "**" },
  ],
  [
    { id: 113, value: "89" },
    { id: 114, value: "10" },
    { id: 115, value: "45" },
    { id: 116, value: "29" },
    { id: 117, value: "56" },
    { id: 118, value: "34" },
    { id: 119, value: "**" },
  ],
  [
    { id: 120, value: "72" },
    { id: 121, value: "10" },
    { id: 122, value: "05" },
    { id: 123, value: "26" },
    { id: 124, value: "16" },
    { id: 125, value: "93" },
    { id: 126, value: "**" },
  ],
  [
    { id: 127, value: "30" },
    { id: 128, value: "26" },
    { id: 129, value: "76" },
    { id: 130, value: "48" },
    { id: 131, value: "82" },
    { id: 132, value: "55" },
    { id: 133, value: "**" },
  ],
  [
    { id: 134, value: "32" },
    { id: 135, value: "62" },
    { id: 136, value: "64" },
    { id: 137, value: "09" },
    { id: 138, value: "13" },
    { id: 139, value: "19" },
    { id: 140, value: "**" },
  ],
  [
    { id: 141, value: "99" },
    { id: 142, value: "28" },
    { id: 143, value: "49" },
    { id: 144, value: "68" },
    { id: 145, value: "57" },
    { id: 146, value: "09" },
    { id: 147, value: "**" },
  ],
  [
    { id: 148, value: "90" },
    { id: 149, value: "88" },
    { id: 150, value: "98" },
    { id: 151, value: "49" },
    { id: 152, value: "67" },
    { id: 153, value: "64" },
    { id: 154, value: "**" },
  ],
  [
    { id: 155, value: "93" },
    { id: 156, value: "19" },
    { id: 157, value: "23" },
    { id: 158, value: "60" },
    { id: 159, value: "85" },
    { id: 160, value: "24" },
    { id: 161, value: "**" },
  ],
  [
    { id: 162, value: "62" },
    { id: 163, value: "90" },
    { id: 164, value: "65" },
    { id: 165, value: "52" },
    { id: 166, value: "34" },
    { id: 167, value: "32" },
    { id: 168, value: "**" },
  ],
  [
    { id: 169, value: "33" },
    { id: 170, value: "32" },
    { id: 171, value: "69" },
    { id: 172, value: "29" },
    { id: 173, value: "57" },
    { id: 174, value: "31" },
    { id: 175, value: "**" },
  ],
  [
    { id: 176, value: "99" },
    { id: 177, value: "82" },
    { id: 178, value: "16" },
    { id: 179, value: "16" },
    { id: 180, value: "42" },
    { id: 181, value: "27" },
    { id: 182, value: "**" },
  ],
  [
    { id: 183, value: "38" },
    { id: 184, value: "56" },
    { id: 185, value: "59" },
    { id: 186, value: "33" },
    { id: 187, value: "44" },
    { id: 188, value: "85" },
    { id: 189, value: "**" },
  ],
  [
    { id: 190, value: "44" },
    { id: 191, value: "85" },
    { id: 192, value: "85" },
    { id: 193, value: "01" },
    { id: 194, value: "38" },
    { id: 195, value: "71" },
    { id: 196, value: "**" },
  ],
  [
    { id: 197, value: "49" },
    { id: 198, value: "07" },
    { id: 199, value: "11" },
    { id: 200, value: "53" },
    { id: 201, value: "93" },
    { id: 202, value: "95" },
    { id: 203, value: "**" },
  ],
  [
    { id: 204, value: "20" },
    { id: 205, value: "04" },
    { id: 206, value: "99" },
    { id: 207, value: "18" },
    { id: 208, value: "45" },
    { id: 209, value: "04" },
    { id: 210, value: "**" },
  ],
  [
    { id: 211, value: "88" },
    { id: 212, value: "17" },
    { id: 213, value: "91" },
    { id: 214, value: "79" },
    { id: 215, value: "10" },
    { id: 216, value: "72" },
    { id: 217, value: "**" },
  ],
  [
    { id: 218, value: "98" },
    { id: 219, value: "72" },
    { id: 220, value: "85" },
    { id: 221, value: "33" },
    { id: 222, value: "19" },
    { id: 223, value: "05" },
    { id: 224, value: "**" },
  ],
  [
    { id: 225, value: "24" },
    { id: 226, value: "**" },
    { id: 227, value: "43" },
    { id: 228, value: "11" },
    { id: 229, value: "44" },
    { id: 230, value: "82" },
    { id: 231, value: "**" },
  ],
  [
    { id: 232, value: "**" },
    { id: 233, value: "28" },
    { id: 234, value: "78" },
    { id: 235, value: "16" },
    { id: 236, value: "47" },
    { id: 237, value: "40" },
    { id: 238, value: "**" },
  ],
  [
    { id: 239, value: "42" },
    { id: 240, value: "39" },
    { id: 241, value: "37" },
    { id: 242, value: "60" },
    { id: 243, value: "36" },
    { id: 244, value: "09" },
    { id: 245, value: "**" },
  ],
  [
    { id: 246, value: "19" },
    { id: 247, value: "57" },
    { id: 248, value: "60" },
    { id: 249, value: "20" },
    { id: 250, value: "00" },
    { id: 251, value: "84" },
    { id: 252, value: "**" },
  ],
  [
    { id: 253, value: "70" },
    { id: 254, value: "26" },
    { id: 255, value: "51" },
    { id: 256, value: "93" },
    { id: 257, value: "97" },
    { id: 258, value: "78" },
    { id: 259, value: "**" },
  ],
  [
    { id: 260, value: "25" },
    { id: 261, value: "89" },
    { id: 262, value: "97" },
    { id: 263, value: "04" },
    { id: 264, value: "34" },
    { id: 265, value: "43" },
    { id: 266, value: "**" },
  ],
  [
    { id: 267, value: "73" },
    { id: 268, value: "15" },
    { id: 269, value: "05" },
    { id: 270, value: "39" },
    { id: 271, value: "77" },
    { id: 272, value: "80" },
    { id: 273, value: "**" },
  ],
  [
    { id: 274, value: "32" },
    { id: 275, value: "47" },
    { id: 276, value: "85" },
    { id: 277, value: "32" },
    { id: 278, value: "43" },
    { id: 279, value: "40" },
    { id: 280, value: "**" },
  ],
  [
    { id: 281, value: "15" },
    { id: 282, value: "86" },
    { id: 283, value: "99" },
    { id: 284, value: "38" },
    { id: 285, value: "99" },
    { id: 286, value: "38" },
    { id: 287, value: "**" },
  ],
  [
    { id: 288, value: "13" },
    { id: 289, value: "84" },
    { id: 290, value: "06" },
    { id: 291, value: "65" },
    { id: 292, value: "98" },
    { id: 293, value: "16" },
    { id: 294, value: "**" },
  ],
  [
    { id: 295, value: "38" },
    { id: 296, value: "26" },
    { id: 297, value: "02" },
    { id: 298, value: "02" },
    { id: 299, value: "39" },
    { id: 300, value: "57" },
    { id: 301, value: "**" },
  ],
  [
    { id: 302, value: "90" },
    { id: 303, value: "19" },
    { id: 304, value: "56" },
    { id: 305, value: "69" },
    { id: 306, value: "23" },
    { id: 307, value: "62" },
    { id: 308, value: "**" },
  ],
  [
    { id: 309, value: "65" },
    { id: 310, value: "10" },
    { id: 311, value: "19" },
    { id: 312, value: "68" },
    { id: 313, value: "**" },
    { id: 314, value: "**" },
    { id: 315, value: "**" },
  ],
  [
    { id: 316, value: "**" },
    { id: 317, value: "**" },
    { id: 318, value: "**" },
    { id: 319, value: "**" },
    { id: 320, value: "**" },
    { id: 321, value: "**" },
    { id: 322, value: "**" },
  ],
  [
    { id: 323, value: "82" },
    { id: 324, value: "34" },
    { id: 325, value: "51" },
    { id: 326, value: "77" },
    { id: 327, value: "45" },
    { id: 328, value: "15" },
    { id: 329, value: "**" },
  ],
  [
    { id: 330, value: "14" },
    { id: 331, value: "99" },
    { id: 332, value: "84" },
    { id: 333, value: "64" },
    { id: 334, value: "08" },
    { id: 335, value: "23" },
    { id: 336, value: "**" },
  ],
  [
    { id: 337, value: "90" },
    { id: 338, value: "24" },
    { id: 339, value: "48" },
    { id: 340, value: "68" },
    { id: 341, value: "57" },
    { id: 342, value: "71" },
    { id: 343, value: "**" },
  ],
  [
    { id: 344, value: "94" },
    { id: 345, value: "95" },
    { id: 346, value: "76" },
    { id: 347, value: "79" },
    { id: 348, value: "50" },
    { id: 349, value: "76" },
    { id: 350, value: "**" },
  ],
  [
    { id: 351, value: "77" },
    { id: 352, value: "41" },
    { id: 353, value: "53" },
    { id: 354, value: "54" },
    { id: 355, value: "21" },
    { id: 356, value: "63" },
    { id: 357, value: "**" },
  ],
  [
    { id: 358, value: "42" },
    { id: 359, value: "75" },
    { id: 360, value: "14" },
    { id: 361, value: "79" },
    { id: 362, value: "67" },
    { id: 363, value: "15" },
    { id: 364, value: "**" },
  ],
  [
    { id: 365, value: "50" },
    { id: 366, value: "88" },
    { id: 367, value: "80" },
    { id: 368, value: "96" },
    { id: 369, value: "69" },
    { id: 370, value: "79" },
    { id: 371, value: "**" },
  ],
  [
    { id: 372, value: "61" },
    { id: 373, value: "02" },
    { id: 374, value: "50" },
    { id: 375, value: "68" },
    { id: 376, value: "02" },
    { id: 377, value: "27" },
    { id: 378, value: "**" },
  ],
  [
    { id: 379, value: "77" },
    { id: 380, value: "08" },
    { id: 381, value: "88" },
    { id: 382, value: "01" },
    { id: 383, value: "77" },
    { id: 384, value: "18" },
    { id: 385, value: "**" },
  ],
  [
    { id: 386, value: "91" },
    { id: 387, value: "61" },
    { id: 388, value: "28" },
    { id: 389, value: "64" },
    { id: 390, value: "**" },
    { id: 391, value: "44" },
    { id: 392, value: "**" },
  ],
  [
    { id: 393, value: "12" },
    { id: 394, value: "50" },
    { id: 395, value: "05" },
    { id: 396, value: "99" },
    { id: 397, value: "05" },
    { id: 398, value: "04" },
    { id: 399, value: "**" },
  ],
  [
    { id: 400, value: "70" },
    { id: 401, value: "95" },
    { id: 402, value: "87" },
    { id: 403, value: "81" },
    { id: 404, value: "41" },
    { id: 405, value: "58" },
    { id: 406, value: "**" },
  ],
  [
    { id: 407, value: "40" },
    { id: 408, value: "66" },
    { id: 409, value: "44" },
    { id: 410, value: "52" },
    { id: 411, value: "05" },
    { id: 412, value: "62" },
    { id: 413, value: "**" },
  ],
  [
    { id: 414, value: "97" },
    { id: 415, value: "20" },
    { id: 416, value: "40" },
    { id: 417, value: "08" },
    { id: 418, value: "67" },
    { id: 419, value: "36" },
    { id: 420, value: "**" },
  ],
  [
    { id: 421, value: "01" },
    { id: 422, value: "05" },
    { id: 423, value: "46" },
    { id: 424, value: "94" },
    { id: 425, value: "27" },
    { id: 426, value: "92" },
    { id: 427, value: "**" },
  ],
  [
    { id: 428, value: "17" },
    { id: 429, value: "70" },
    { id: 430, value: "63" },
    { id: 431, value: "47" },
    { id: 432, value: "95" },
    { id: 433, value: "24" },
    { id: 434, value: "**" },
  ],
  [
    { id: 435, value: "13" },
    { id: 436, value: "98" },
    { id: 437, value: "01" },
    { id: 438, value: "78" },
    { id: 439, value: "53" },
    { id: 440, value: "19" },
    { id: 441, value: "**" },
  ],
  [
    { id: 442, value: "44" },
    { id: 443, value: "01" },
    { id: 444, value: "12" },
    { id: 445, value: "16" },
    { id: 446, value: "34" },
    { id: 447, value: "66" },
    { id: 448, value: "**" },
  ],
  [
    { id: 449, value: "**" },
    { id: 450, value: "37" },
    { id: 451, value: "84" },
    { id: 452, value: "30" },
    { id: 453, value: "57" },
    { id: 454, value: "46" },
    { id: 455, value: "**" },
  ],
  [
    { id: 456, value: "67" },
    { id: 457, value: "62" },
    { id: 458, value: "39" },
    { id: 459, value: "04" },
    { id: 460, value: "32" },
    { id: 461, value: "88" },
    { id: 462, value: "**" },
  ],
  [
    { id: 463, value: "82" },
    { id: 464, value: "86" },
    { id: 465, value: "31" },
    { id: 466, value: "33" },
    { id: 467, value: "95" },
    { id: 468, value: "23" },
    { id: 469, value: "**" },
  ],
  [
    { id: 470, value: "25" },
    { id: 471, value: "68" },
    { id: 472, value: "14" },
    { id: 473, value: "28" },
    { id: 474, value: "88" },
    { id: 475, value: "26" },
    { id: 476, value: "**" },
  ],
  [
    { id: 477, value: "62" },
    { id: 478, value: "62" },
    { id: 479, value: "72" },
    { id: 480, value: "76" },
    { id: 481, value: "54" },
    { id: 482, value: "68" },
    { id: 483, value: "**" },
  ],
  [
    { id: 484, value: "48" },
    { id: 485, value: "43" },
    { id: 486, value: "36" },
    { id: 487, value: "40" },
    { id: 488, value: "72" },
    { id: 489, value: "08" },
    { id: 490, value: "**" },
  ],
  [
    { id: 491, value: "88" },
    { id: 492, value: "06" },
    { id: 493, value: "11" },
    { id: 494, value: "41" },
    { id: 495, value: "40" },
    { id: 496, value: "90" },
    { id: 497, value: "**" },
  ],
];

// rajdhaninight

export const RajdhaniNight = [
  [
    { id: 1, value: "02" },
    { id: 2, value: "67" },
    { id: 3, value: "38" },
    { id: 4, value: "23" },
    { id: 5, value: "17" },
    { id: 6, value: "**" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "34" },
    { id: 9, value: "58" },
    { id: 10, value: "60" },
    { id: 11, value: "06" },
    { id: 12, value: "56" },
    { id: 13, value: "**" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "97" },
    { id: 16, value: "72" },
    { id: 17, value: "98" },
    { id: 18, value: "18" },
    { id: 19, value: "75" },
    { id: 20, value: "**" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "89" },
    { id: 23, value: "76" },
    { id: 24, value: "21" },
    { id: 25, value: "**" },
    { id: 26, value: "40" },
    { id: 27, value: "**" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "24" },
    { id: 30, value: "73" },
    { id: 31, value: "40" },
    { id: 32, value: "78" },
    { id: 33, value: "23" },
    { id: 34, value: "**" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "76" },
    { id: 37, value: "46" },
    { id: 38, value: "87" },
    { id: 39, value: "15" },
    { id: 40, value: "28" },
    { id: 41, value: "**" },
    { id: 42, value: "**" },
  ],
  [
    { id: 43, value: "53" },
    { id: 44, value: "21" },
    { id: 45, value: "08" },
    { id: 46, value: "21" },
    { id: 47, value: "69" },
    { id: 48, value: "**" },
    { id: 49, value: "**" },
  ],
  [
    { id: 50, value: "70" },
    { id: 51, value: "80" },
    { id: 52, value: "84" },
    { id: 53, value: "21" },
    { id: 54, value: "82" },
    { id: 55, value: "**" },
    { id: 56, value: "**" },
  ],
  [
    { id: 57, value: "18" },
    { id: 58, value: "01" },
    { id: 59, value: "23" },
    { id: 60, value: "82" },
    { id: 61, value: "93" },
    { id: 62, value: "**" },
    { id: 63, value: "**" },
  ],
  [
    { id: 64, value: "23" },
    { id: 65, value: "09" },
    { id: 66, value: "**" },
    { id: 67, value: "78" },
    { id: 68, value: "53" },
    { id: 69, value: "**" },
    { id: 70, value: "**" },
  ],
  [
    { id: 71, value: "98" },
    { id: 72, value: "74" },
    { id: 73, value: "37" },
    { id: 74, value: "56" },
    { id: 75, value: "70" },
    { id: 76, value: "**" },
    { id: 77, value: "**" },
  ],
  [
    { id: 78, value: "19" },
    { id: 79, value: "74" },
    { id: 80, value: "85" },
    { id: 81, value: "03" },
    { id: 82, value: "42" },
    { id: 83, value: "**" },
    { id: 84, value: "**" },
  ],
  [
    { id: 85, value: "04" },
    { id: 86, value: "34" },
    { id: 87, value: "87" },
    { id: 88, value: "03" },
    { id: 89, value: "05" },
    { id: 90, value: "**" },
    { id: 91, value: "**" },
  ],
  [
    { id: 92, value: "94" },
    { id: 93, value: "85" },
    { id: 94, value: "09" },
    { id: 95, value: "94" },
    { id: 96, value: "59" },
    { id: 97, value: "**" },
    { id: 98, value: "**" },
  ],
  [
    { id: 99, value: "77" },
    { id: 100, value: "04" },
    { id: 101, value: "72" },
    { id: 102, value: "92" },
    { id: 103, value: "65" },
    { id: 104, value: "**" },
    { id: 105, value: "**" },
  ],
  [
    { id: 106, value: "44" },
    { id: 107, value: "19" },
    { id: 108, value: "95" },
    { id: 109, value: "68" },
    { id: 110, value: "51" },
    { id: 111, value: "**" },
    { id: 112, value: "**" },
  ],
  [
    { id: 113, value: "42" },
    { id: 114, value: "22" },
    { id: 115, value: "12" },
    { id: 116, value: "83" },
    { id: 117, value: "63" },
    { id: 118, value: "**" },
    { id: 119, value: "**" },
  ],
  [
    { id: 120, value: "51" },
    { id: 121, value: "67" },
    { id: 122, value: "83" },
    { id: 123, value: "25" },
    { id: 124, value: "25" },
    { id: 125, value: "**" },
    { id: 126, value: "**" },
  ],
  [
    { id: 127, value: "02" },
    { id: 128, value: "00" },
    { id: 129, value: "73" },
    { id: 130, value: "46" },
    { id: 131, value: "14" },
    { id: 132, value: "**" },
    { id: 133, value: "**" },
  ],
  [
    { id: 134, value: "84" },
    { id: 135, value: "59" },
    { id: 136, value: "19" },
    { id: 137, value: "86" },
    { id: 138, value: "24" },
    { id: 139, value: "**" },
    { id: 140, value: "**" },
  ],
  [
    { id: 141, value: "05" },
    { id: 142, value: "67" },
    { id: 143, value: "13" },
    { id: 144, value: "17" },
    { id: 145, value: "20" },
    { id: 146, value: "**" },
    { id: 147, value: "**" },
  ],
  [
    { id: 148, value: "88" },
    { id: 149, value: "10" },
    { id: 150, value: "39" },
    { id: 151, value: "00" },
    { id: 152, value: "65" },
    { id: 153, value: "**" },
    { id: 154, value: "**" },
  ],
  [
    { id: 155, value: "95" },
    { id: 156, value: "77" },
    { id: 157, value: "24" },
    { id: 158, value: "18" },
    { id: 159, value: "13" },
    { id: 160, value: "**" },
    { id: 161, value: "**" },
  ],
  [
    { id: 162, value: "21" },
    { id: 163, value: "93" },
    { id: 164, value: "61" },
    { id: 165, value: "83" },
    { id: 166, value: "60" },
    { id: 167, value: "**" },
    { id: 168, value: "**" },
  ],
  [
    { id: 169, value: "23" },
    { id: 170, value: "40" },
    { id: 171, value: "57" },
    { id: 172, value: "26" },
    { id: 173, value: "45" },
    { id: 174, value: "**" },
    { id: 175, value: "**" },
  ],
  [
    { id: 176, value: "61" },
    { id: 177, value: "67" },
    { id: 178, value: "78" },
    { id: 179, value: "11" },
    { id: 180, value: "33" },
    { id: 181, value: "**" },
    { id: 182, value: "**" },
  ],
  [
    { id: 183, value: "09" },
    { id: 184, value: "11" },
    { id: 185, value: "72" },
    { id: 186, value: "14" },
    { id: 187, value: "20" },
    { id: 188, value: "**" },
    { id: 189, value: "**" },
  ],
  [
    { id: 190, value: "39" },
    { id: 191, value: "13" },
    { id: 192, value: "27" },
    { id: 193, value: "07" },
    { id: 194, value: "78" },
    { id: 195, value: "**" },
    { id: 196, value: "**" },
  ],
  [
    { id: 197, value: "37" },
    { id: 198, value: "39" },
    { id: 199, value: "30" },
    { id: 200, value: "93" },
    { id: 201, value: "91" },
    { id: 202, value: "**" },
    { id: 203, value: "**" },
  ],
  [
    { id: 204, value: "68" },
    { id: 205, value: "89" },
    { id: 206, value: "57" },
    { id: 207, value: "56" },
    { id: 208, value: "99" },
    { id: 209, value: "**" },
    { id: 210, value: "**" },
  ],
  [
    { id: 211, value: "49" },
    { id: 212, value: "81" },
    { id: 213, value: "33" },
    { id: 214, value: "71" },
    { id: 215, value: "91" },
    { id: 216, value: "**" },
    { id: 217, value: "**" },
  ],
  [
    { id: 218, value: "99" },
    { id: 219, value: "76" },
    { id: 220, value: "70" },
    { id: 221, value: "55" },
    { id: 222, value: "78" },
    { id: 223, value: "**" },
    { id: 224, value: "**" },
  ],
  [
    { id: 225, value: "06" },
    { id: 226, value: "**" },
    { id: 227, value: "52" },
    { id: 228, value: "44" },
    { id: 229, value: "09" },
    { id: 230, value: "**" },
    { id: 231, value: "**" },
  ],
  [
    { id: 232, value: "58" },
    { id: 233, value: "65" },
    { id: 234, value: "59" },
    { id: 235, value: "78" },
    { id: 236, value: "89" },
    { id: 237, value: "**" },
    { id: 238, value: "**" },
  ],
  [
    { id: 239, value: "57" },
    { id: 240, value: "56" },
    { id: 241, value: "68" },
    { id: 242, value: "36" },
    { id: 243, value: "04" },
    { id: 244, value: "**" },
    { id: 245, value: "**" },
  ],
  [
    { id: 246, value: "43" },
    { id: 247, value: "99" },
    { id: 248, value: "86" },
    { id: 249, value: "67" },
    { id: 250, value: "99" },
    { id: 251, value: "**" },
    { id: 252, value: "**" },
  ],
  [
    { id: 253, value: "72" },
    { id: 254, value: "61" },
    { id: 255, value: "94" },
    { id: 256, value: "99" },
    { id: 257, value: "01" },
    { id: 258, value: "**" },
    { id: 259, value: "**" },
  ],
  [
    { id: 260, value: "98" },
    { id: 261, value: "16" },
    { id: 262, value: "87" },
    { id: 263, value: "12" },
    { id: 264, value: "96" },
    { id: 265, value: "**" },
    { id: 266, value: "**" },
  ],
  [
    { id: 267, value: "20" },
    { id: 268, value: "63" },
    { id: 269, value: "99" },
    { id: 270, value: "45" },
    { id: 271, value: "81" },
    { id: 272, value: "**" },
    { id: 273, value: "**" },
  ],
  [
    { id: 274, value: "94" },
    { id: 275, value: "73" },
    { id: 276, value: "47" },
    { id: 277, value: "53" },
    { id: 278, value: "53" },
    { id: 279, value: "**" },
    { id: 280, value: "**" },
  ],
  [
    { id: 281, value: "91" },
    { id: 282, value: "04" },
    { id: 283, value: "41" },
    { id: 284, value: "27" },
    { id: 285, value: "65" },
    { id: 286, value: "**" },
    { id: 287, value: "**" },
  ],
  [
    { id: 288, value: "37" },
    { id: 289, value: "02" },
    { id: 290, value: "91" },
    { id: 291, value: "89" },
    { id: 292, value: "49" },
    { id: 293, value: "**" },
    { id: 294, value: "**" },
  ],
  [
    { id: 295, value: "33" },
    { id: 296, value: "40" },
    { id: 297, value: "61" },
    { id: 298, value: "12" },
    { id: 299, value: "53" },
    { id: 300, value: "**" },
    { id: 301, value: "**" },
  ],
  [
    { id: 302, value: "16" },
    { id: 303, value: "02" },
    { id: 304, value: "84" },
    { id: 305, value: "01" },
    { id: 306, value: "20" },
    { id: 307, value: "**" },
    { id: 308, value: "**" },
  ],
  [
    { id: 309, value: "38" },
    { id: 310, value: "34" },
    { id: 311, value: "42" },
    { id: 312, value: "56" },
    { id: 313, value: "**" },
    { id: 314, value: "**" },
    { id: 315, value: "**" },
  ],
  [
    { id: 316, value: "**" },
    { id: 317, value: "**" },
    { id: 318, value: "**" },
    { id: 319, value: "**" },
    { id: 320, value: "**" },
    { id: 321, value: "**" },
    { id: 322, value: "**" },
  ],
  [
    { id: 337, value: "54" },
    { id: 338, value: "93" },
    { id: 339, value: "29" },
    { id: 340, value: "51" },
    { id: 341, value: "08" },
    { id: 342, value: "**" },
    { id: 343, value: "**" },
  ],
  [
    { id: 344, value: "24" },
    { id: 345, value: "49" },
    { id: 346, value: "54" },
    { id: 347, value: "99" },
    { id: 348, value: "55" },
    { id: 349, value: "**" },
    { id: 350, value: "**" },
  ],
  [
    { id: 351, value: "74" },
    { id: 352, value: "26" },
    { id: 353, value: "47" },
    { id: 354, value: "24" },
    { id: 355, value: "37" },
    { id: 356, value: "**" },
    { id: 357, value: "**" },
  ],
  [
    { id: 358, value: "94" },
    { id: 359, value: "83" },
    { id: 360, value: "34" },
    { id: 361, value: "87" },
    { id: 362, value: "28" },
    { id: 363, value: "**" },
    { id: 364, value: "**" },
  ],
  [
    { id: 365, value: "94" },
    { id: 366, value: "47" },
    { id: 367, value: "30" },
    { id: 368, value: "15" },
    { id: 369, value: "38" },
    { id: 370, value: "**" },
    { id: 371, value: "**" },
  ],
  [
    { id: 372, value: "37" },
    { id: 373, value: "28" },
    { id: 374, value: "09" },
    { id: 375, value: "30" },
    { id: 376, value: "42" },
    { id: 377, value: "**" },
    { id: 378, value: "**" },
  ],
  [
    { id: 379, value: "22" },
    { id: 380, value: "86" },
    { id: 381, value: "85" },
    { id: 382, value: "18" },
    { id: 383, value: "22" },
    { id: 384, value: "**" },
    { id: 385, value: "**" },
  ],
  [
    { id: 386, value: "47" },
    { id: 387, value: "41" },
    { id: 388, value: "17" },
    { id: 389, value: "09" },
    { id: 390, value: "25" },
    { id: 391, value: "**" },
    { id: 392, value: "**" },
  ],
  [
    { id: 393, value: "90" },
    { id: 394, value: "12" },
    { id: 395, value: "01" },
    { id: 396, value: "21" },
    { id: 397, value: "52" },
    { id: 398, value: "**" },
    { id: 399, value: "**" },
  ],
  [
    { id: 400, value: "33" },
    { id: 401, value: "64" },
    { id: 402, value: "31" },
    { id: 403, value: "68" },
    { id: 404, value: "**" },
    { id: 405, value: "**" },
    { id: 406, value: "**" },
  ],
  [
    { id: 407, value: "38" },
    { id: 408, value: "95" },
    { id: 409, value: "24" },
    { id: 410, value: "90" },
    { id: 411, value: "60" },
    { id: 412, value: "**" },
    { id: 413, value: "**" },
  ],
  [
    { id: 414, value: "77" },
    { id: 415, value: "22" },
    { id: 416, value: "83" },
    { id: 417, value: "28" },
    { id: 418, value: "72" },
    { id: 419, value: "**" },
    { id: 420, value: "**" },
  ],
  [
    { id: 421, value: "03" },
    { id: 422, value: "60" },
    { id: 423, value: "36" },
    { id: 424, value: "07" },
    { id: 425, value: "45" },
    { id: 426, value: "**" },
    { id: 427, value: "**" },
  ],
  [
    { id: 428, value: "60" },
    { id: 429, value: "11" },
    { id: 430, value: "60" },
    { id: 431, value: "91" },
    { id: 432, value: "77" },
    { id: 433, value: "**" },
    { id: 434, value: "**" },
  ],
  [
    { id: 435, value: "38" },
    { id: 436, value: "84" },
    { id: 437, value: "36" },
    { id: 438, value: "79" },
    { id: 439, value: "91" },
    { id: 440, value: "**" },
    { id: 441, value: "**" },
  ],
  [
    { id: 442, value: "79" },
    { id: 443, value: "23" },
    { id: 444, value: "86" },
    { id: 445, value: "74" },
    { id: 446, value: "86" },
    { id: 447, value: "**" },
    { id: 448, value: "**" },
  ],
  [
    { id: 449, value: "68" },
    { id: 450, value: "25" },
    { id: 451, value: "85" },
    { id: 452, value: "79" },
    { id: 453, value: "17" },
    { id: 454, value: "**" },
    { id: 455, value: "**" },
  ],
  [
    { id: 456, value: "76" },
    { id: 457, value: "13" },
    { id: 458, value: "34" },
    { id: 459, value: "56" },
    { id: 460, value: "51" },
    { id: 461, value: "**" },
    { id: 462, value: "**" },
  ],
  [
    { id: 467, value: "**" },
    { id: 463, value: "18" },
    { id: 464, value: "31" },
    { id: 465, value: "83" },
    { id: 466, value: "55" },
    { id: 468, value: "**" },
    { id: 469, value: "**" },
  ],
  [
    { id: 470, value: "48" },
    { id: 471, value: "14" },
    { id: 472, value: "91" },
    { id: 473, value: "77" },
    { id: 474, value: "09" },
    { id: 475, value: "**" },
    { id: 476, value: "**" },
  ],
  [
    { id: 477, value: "43" },
    { id: 478, value: "41" },
    { id: 479, value: "54" },
    { id: 480, value: "02" },
    { id: 481, value: "63" },
    { id: 482, value: "**" },
    { id: 483, value: "**" },
  ],
  [
    { id: 484, value: "16" },
    { id: 485, value: "40" },
    { id: 486, value: "32" },
    { id: 487, value: "80" },
    { id: 488, value: "13" },
    { id: 489, value: "**" },
    { id: 490, value: "**" },
  ],
  [
    { id: 491, value: "28" },
    { id: 492, value: "18" },
    { id: 493, value: "29" },
    { id: 494, value: "48" },
    { id: 495, value: "05" },
    { id: 496, value: "**" },
    { id: 497, value: "**" },
  ],
  [
    { id: 498, value: "21" },
    { id: 499, value: "49" },
    { id: 500, value: "14" },
    { id: 501, value: "56" },
    { id: 502, value: "52" },
    { id: 503, value: "**" },
    { id: 504, value: "**" },
  ],
  [
    { id: 505, value: "05" },
    { id: 506, value: "46" },
    { id: 507, value: "96" },
    { id: 508, value: "18" },
    { id: 509, value: "64" },
    { id: 510, value: "**" },
    { id: 511, value: "**" },
  ],
  [
    { id: 512, value: "42" },
    { id: 513, value: "50" },
    { id: 514, value: "80" },
    { id: 515, value: "08" },
    { id: 516, value: "20" },
    { id: 517, value: "**" },
    { id: 518, value: "**" },
  ],
  [
    { id: 519, value: "40" },
    { id: 520, value: "21" },
    { id: 521, value: "25" },
    { id: 522, value: "27" },
    { id: 523, value: "63" },
    { id: 524, value: "**" },
    { id: 525, value: "**" },
  ],
  [
    { id: 526, value: "08" },
    { id: 527, value: "51" },
    { id: 528, value: "87" },
    { id: 529, value: "57" },
    { id: 530, value: "81" },
    { id: 531, value: "**" },
    { id: 532, value: "**" },
  ],
  [
    { id: 533, value: "72" },
    { id: 534, value: "20" },
    { id: 535, value: "53" },
    { id: 536, value: "41" },
    { id: 537, value: "55" },
    { id: 538, value: "**" },
    { id: 539, value: "**" },
  ],
  [
    { id: 540, value: "41" },
    { id: 541, value: "58" },
    { id: 542, value: "97" },
    { id: 543, value: "66" },
    { id: 544, value: "47" },
    { id: 545, value: "**" },
    { id: 546, value: "**" },
  ],
  [
    { id: 547, value: "99" },
    { id: 548, value: "85" },
    { id: 549, value: "01" },
    { id: 550, value: "08" },
    { id: 551, value: "39" },
    { id: 552, value: "**" },
    { id: 553, value: "**" },
  ],
  [
    { id: 554, value: "25" },
    { id: 555, value: "93" },
    { id: 556, value: "57" },
    { id: 557, value: "13" },
    { id: 558, value: "23" },
    { id: 559, value: "**" },
    { id: 560, value: "**" },
  ],
  [
    { id: 561, value: "37" },
    { id: 562, value: "11" },
    { id: 563, value: "61" },
    { id: 564, value: "78" },
    { id: 565, value: "12" },
    { id: 566, value: "**" },
    { id: 567, value: "**" },
  ],
];

// MAIN BAZAR

export const MainBazar = [
  [
    { id: 1, value: "93" },
    { id: 2, value: "49" },
    { id: 3, value: "15" },
    { id: 4, value: "62" },
    { id: 5, value: "88" },
    { id: 6, value: "**" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "74" },
    { id: 9, value: "31" },
    { id: 10, value: "56" },
    { id: 11, value: "53" },
    { id: 12, value: "31" },
    { id: 13, value: "**" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "31" },
    { id: 16, value: "52" },
    { id: 17, value: "24" },
    { id: 18, value: "60" },
    { id: 19, value: "84" },
    { id: 20, value: "**" },
    { id: 21, value: "**" },
  ],
  [
    { id: 1, value: "18" },
    { id: 2, value: "64" },
    { id: 3, value: "63" },
    { id: 4, value: "**" },
    { id: 5, value: "40" },
    { id: 6, value: "**" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "10" },
    { id: 9, value: "53" },
    { id: 10, value: "89" },
    { id: 11, value: "85" },
    { id: 12, value: "52" },
    { id: 13, value: "**" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "76" },
    { id: 16, value: "53" },
    { id: 17, value: "92" },
    { id: 18, value: "48" },
    { id: 19, value: "19" },
    { id: 20, value: "**" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "19" },
    { id: 23, value: "36" },
    { id: 24, value: "97" },
    { id: 25, value: "12" },
    { id: 26, value: "36" },
    { id: 27, value: "**" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "57" },
    { id: 30, value: "79" },
    { id: 31, value: "76" },
    { id: 32, value: "96" },
    { id: 33, value: "44" },
    { id: 34, value: "**" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "75" },
    { id: 37, value: "84" },
    { id: 38, value: "45" },
    { id: 39, value: "88" },
    { id: 40, value: "43" },
    { id: 41, value: "**" },
    { id: 42, value: "**" },
  ],
  [
    { id: 43, value: "32" },
    { id: 44, value: "88" },
    { id: 45, value: "**" },
    { id: 46, value: "51" },
    { id: 47, value: "65" },
    { id: 48, value: "**" },
    { id: 49, value: "**" },
  ],
  [
    { id: 50, value: "89" },
    { id: 51, value: "92" },
    { id: 52, value: "58" },
    { id: 53, value: "57" },
    { id: 54, value: "13" },
    { id: 55, value: "**" },
    { id: 56, value: "**" },
  ],
  [
    { id: 57, value: "32" },
    { id: 58, value: "71" },
    { id: 59, value: "88" },
    { id: 60, value: "76" },
    { id: 61, value: "81" },
    { id: 62, value: "**" },
    { id: 63, value: "**" },
  ],
  [
    { id: 64, value: "44" },
    { id: 65, value: "71" },
    { id: 66, value: "60" },
    { id: 67, value: "93" },
    { id: 68, value: "30" },
    { id: 69, value: "**" },
    { id: 70, value: "**" },
  ],
  [
    { id: 71, value: "88" },
    { id: 72, value: "72" },
    { id: 73, value: "57" },
    { id: 74, value: "40" },
    { id: 75, value: "90" },
    { id: 76, value: "**" },
    { id: 77, value: "**" },
  ],
  [
    { id: 78, value: "13" },
    { id: 79, value: "10" },
    { id: 80, value: "95" },
    { id: 81, value: "90" },
    { id: 82, value: "95" },
    { id: 83, value: "**" },
    { id: 84, value: "**" },
  ],
  [
    { id: 85, value: "28" },
    { id: 86, value: "28" },
    { id: 87, value: "11" },
    { id: 88, value: "56" },
    { id: 89, value: "27" },
    { id: 90, value: "**" },
    { id: 91, value: "**" },
  ],
  [
    { id: 92, value: "12" },
    { id: 93, value: "72" },
    { id: 94, value: "26" },
    { id: 95, value: "77" },
    { id: 96, value: "93" },
    { id: 97, value: "**" },
    { id: 98, value: "**" },
  ],
  [
    { id: 99, value: "58" },
    { id: 100, value: "87" },
    { id: 101, value: "24" },
    { id: 102, value: "56" },
    { id: 103, value: "26" },
    { id: 104, value: "**" },
    { id: 105, value: "**" },
  ],
  [
    { id: 106, value: "64" },
    { id: 107, value: "34" },
    { id: 108, value: "87" },
    { id: 109, value: "24" },
    { id: 110, value: "80" },
    { id: 111, value: "**" },
    { id: 112, value: "**" },
  ],
  [
    { id: 113, value: "47" },
    { id: 114, value: "15" },
    { id: 115, value: "93" },
    { id: 116, value: "82" },
    { id: 117, value: "64" },
    { id: 118, value: "**" },
    { id: 119, value: "**" },
  ],
  [
    { id: 120, value: "62" },
    { id: 121, value: "85" },
    { id: 122, value: "37" },
    { id: 123, value: "61" },
    { id: 124, value: "13" },
    { id: 125, value: "**" },
    { id: 126, value: "**" },
  ],
  [
    { id: 127, value: "95" },
    { id: 128, value: "14" },
    { id: 129, value: "03" },
    { id: 130, value: "98" },
    { id: 131, value: "20" },
    { id: 132, value: "**" },
    { id: 133, value: "**" },
  ],
  [
    { id: 134, value: "10" },
    { id: 135, value: "77" },
    { id: 136, value: "05" },
    { id: 137, value: "69" },
    { id: 138, value: "63" },
    { id: 139, value: "**" },
    { id: 140, value: "**" },
  ],
  [
    { id: 141, value: "61" },
    { id: 142, value: "43" },
    { id: 143, value: "05" },
    { id: 144, value: "13" },
    { id: 145, value: "94" },
    { id: 146, value: "**" },
    { id: 147, value: "**" },
  ],
  [
    { id: 148, value: "35" },
    { id: 149, value: "73" },
    { id: 150, value: "20" },
    { id: 151, value: "07" },
    { id: 152, value: "14" },
    { id: 153, value: "**" },
    { id: 154, value: "**" },
  ],
  [
    { id: 155, value: "99" },
    { id: 156, value: "26" },
    { id: 157, value: "93" },
    { id: 158, value: "50" },
    { id: 159, value: "99" },
    { id: 160, value: "**" },
    { id: 161, value: "**" },
  ],
  [
    { id: 162, value: "65" },
    { id: 163, value: "43" },
    { id: 164, value: "42" },
    { id: 165, value: "81" },
    { id: 166, value: "05" },
    { id: 167, value: "**" },
    { id: 168, value: "**" },
  ],
  [
    { id: 169, value: "52" },
    { id: 170, value: "03" },
    { id: 171, value: "31" },
    { id: 172, value: "48" },
    { id: 173, value: "48" },
    { id: 174, value: "**" },
    { id: 175, value: "**" },
  ],
  [
    { id: 176, value: "91" },
    { id: 177, value: "11" },
    { id: 178, value: "42" },
    { id: 179, value: "11" },
    { id: 180, value: "69" },
    { id: 181, value: "**" },
    { id: 182, value: "**" },
  ],
  [
    { id: 183, value: "30" },
    { id: 184, value: "94" },
    { id: 185, value: "24" },
    { id: 186, value: "25" },
    { id: 187, value: "61" },
    { id: 188, value: "**" },
    { id: 189, value: "**" },
  ],
  [
    { id: 190, value: "94" },
    { id: 191, value: "77" },
    { id: 192, value: "18" },
    { id: 193, value: "88" },
    { id: 194, value: "24" },
    { id: 195, value: "**" },
    { id: 196, value: "**" },
  ],
  [
    { id: 197, value: "75" },
    { id: 198, value: "92" },
    { id: 199, value: "69" },
    { id: 200, value: "40" },
    { id: 201, value: "92" },
    { id: 202, value: "**" },
    { id: 203, value: "**" },
  ],
  [
    { id: 1, value: "44" },
    { id: 2, value: "**" },
    { id: 3, value: "61" },
    { id: 4, value: "67" },
    { id: 5, value: "20" },
    { id: 6, value: "**" },
    { id: 7, value: "**" },
  ],
  [
    { id: 1, value: "22" },
    { id: 2, value: "75" },
    { id: 3, value: "05" },
    { id: 4, value: "94" },
    { id: 5, value: "44" },
    { id: 6, value: "**" },
    { id: 7, value: "**" },
  ],
  [
    { id: 1, value: "22" },
    { id: 2, value: "75" },
    { id: 3, value: "05" },
    { id: 4, value: "94" },
    { id: 5, value: "44" },
    { id: 6, value: "**" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "60" },
    { id: 9, value: "22" },
    { id: 10, value: "89" },
    { id: 11, value: "81" },
    { id: 12, value: "47" },
    { id: 13, value: "**" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "41" },
    { id: 16, value: "37" },
    { id: 17, value: "14" },
    { id: 18, value: "98" },
    { id: 19, value: "74" },
    { id: 20, value: "**" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "26" },
    { id: 23, value: "31" },
    { id: 24, value: "78" },
    { id: 25, value: "59" },
    { id: 26, value: "59" },
    { id: 27, value: "**" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "88" },
    { id: 30, value: "97" },
    { id: 31, value: "44" },
    { id: 32, value: "25" },
    { id: 33, value: "51" },
    { id: 34, value: "**" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "69" },
    { id: 37, value: "86" },
    { id: 38, value: "68" },
    { id: 39, value: "38" },
    { id: 40, value: "77" },
    { id: 41, value: "**" },
    { id: 42, value: "**" },
  ],
  [
    { id: 1, value: "83" },
    { id: 2, value: "83" },
    { id: 3, value: "35" },
    { id: 4, value: "21" },
    { id: 5, value: "26" },
    { id: 6, value: "**" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "80" },
    { id: 9, value: "61" },
    { id: 10, value: "39" },
    { id: 11, value: "56" },
    { id: 12, value: "30" },
    { id: 13, value: "**" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "25" },
    { id: 16, value: "24" },
    { id: 17, value: "40" },
    { id: 18, value: "25" },
    { id: 19, value: "04" },
    { id: 20, value: "**" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "47" },
    { id: 23, value: "40" },
    { id: 24, value: "24" },
    { id: 25, value: "35" },
    { id: 26, value: "84" },
    { id: 27, value: "**" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "50" },
    { id: 30, value: "55" },
    { id: 31, value: "87" },
    { id: 32, value: "30" },
    { id: 33, value: "28" },
    { id: 34, value: "**" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "29" },
    { id: 37, value: "67" },
    { id: 38, value: "41" },
    { id: 39, value: "30" },
    { id: 40, value: "59" },
    { id: 41, value: "**" },
    { id: 42, value: "**" },
  ],
  [
    { id: 36, value: "**" },
    { id: 37, value: "**" },
    { id: 38, value: "**" },
    { id: 39, value: "**" },
    { id: 40, value: "**" },
    { id: 41, value: "**" },
    { id: 42, value: "**" },
  ],
  [
    { id: 1, value: "59" },
    { id: 2, value: "34" },
    { id: 3, value: "72" },
    { id: 4, value: "15" },
    { id: 5, value: "83" },
    { id: 6, value: "**" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "55" },
    { id: 9, value: "66" },
    { id: 10, value: "69" },
    { id: 11, value: "91" },
    { id: 12, value: "97" },
    { id: 13, value: "**" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "27" },
    { id: 16, value: "89" },
    { id: 17, value: "28" },
    { id: 18, value: "26" },
    { id: 19, value: "10" },
    { id: 20, value: "**" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "62" },
    { id: 23, value: "09" },
    { id: 24, value: "66" },
    { id: 25, value: "14" },
    { id: 26, value: "03" },
    { id: 27, value: "**" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "71" },
    { id: 30, value: "66" },
    { id: 31, value: "51" },
    { id: 32, value: "34" },
    { id: 33, value: "29" },
    { id: 34, value: "**" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "39" },
    { id: 37, value: "48" },
    { id: 38, value: "19" },
    { id: 39, value: "91" },
    { id: 40, value: "34" },
    { id: 41, value: "**" },
    { id: 42, value: "**" },
  ],
  [
    { id: 43, value: "81" },
    { id: 44, value: "85" },
    { id: 45, value: "25" },
    { id: 46, value: "82" },
    { id: 47, value: "34" },
    { id: 48, value: "**" },
    { id: 49, value: "**" },
  ],
  [
    { id: 50, value: "85" },
    { id: 51, value: "21" },
    { id: 52, value: "02" },
    { id: 53, value: "90" },
    { id: 54, value: "23" },
    { id: 55, value: "**" },
    { id: 56, value: "**" },
  ],
  [
    { id: 57, value: "45" },
    { id: 58, value: "11" },
    { id: 59, value: "78" },
    { id: 60, value: "25" },
    { id: 61, value: "74" },
    { id: 62, value: "**" },
    { id: 63, value: "**" },
  ],
  [
    { id: 1, value: "89" },
    { id: 2, value: "47" },
    { id: 3, value: "04" },
    { id: 4, value: "16" },
    { id: 5, value: "**" },
    { id: 6, value: "**" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "58" },
    { id: 9, value: "70" },
    { id: 10, value: "55" },
    { id: 11, value: "87" },
    { id: 12, value: "72" },
    { id: 13, value: "**" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "28" },
    { id: 16, value: "99" },
    { id: 17, value: "72" },
    { id: 18, value: "01" },
    { id: 19, value: "65" },
    { id: 20, value: "**" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "04" },
    { id: 23, value: "77" },
    { id: 24, value: "55" },
    { id: 25, value: "52" },
    { id: 26, value: "77" },
    { id: 27, value: "**" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "70" },
    { id: 30, value: "96" },
    { id: 31, value: "54" },
    { id: 32, value: "94" },
    { id: 33, value: "14" },
    { id: 34, value: "**" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "50" },
    { id: 37, value: "04" },
    { id: 38, value: "68" },
    { id: 39, value: "03" },
    { id: 40, value: "55" },
    { id: 41, value: "**" },
    { id: 42, value: "**" },
  ],
  [
    { id: 43, value: "31" },
    { id: 44, value: "88" },
    { id: 45, value: "20" },
    { id: 46, value: "11" },
    { id: 47, value: "82" },
    { id: 48, value: "**" },
    { id: 49, value: "**" },
  ],
  [
    { id: 50, value: "15" },
    { id: 51, value: "15" },
    { id: 52, value: "71" },
    { id: 53, value: "90" },
    { id: 54, value: "41" },
    { id: 48, value: "**" },
    { id: 49, value: "**" },
  ],
  [
    { id: 1, value: "98" },
    { id: 2, value: "42" },
    { id: 3, value: "63" },
    { id: 4, value: "71" },
    { id: 5, value: "02" },
    { id: 6, value: "**" },
    { id: 7, value: "**" },
  ],
  [
    { id: 13, value: "**" },
    { id: 8, value: "12" },
    { id: 9, value: "71" },
    { id: 10, value: "84" },
    { id: 11, value: "08" },
    { id: 13, value: "**" },
    { id: 14, value: "**" },
  ],
  [
    { id: 1, value: "67" },
    { id: 2, value: "54" },
    { id: 3, value: "71" },
    { id: 4, value: "03" },
    { id: 5, value: "22" },
    { id: 6, value: "**" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "61" },
    { id: 9, value: "97" },
    { id: 10, value: "38" },
    { id: 11, value: "01" },
    { id: 12, value: "79" },
    { id: 13, value: "**" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "63" },
    { id: 16, value: "90" },
    { id: 17, value: "85" },
    { id: 18, value: "64" },
    { id: 19, value: "58" },
    { id: 20, value: "**" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "55" },
    { id: 23, value: "44" },
    { id: 24, value: "11" },
    { id: 25, value: "87" },
    { id: 26, value: "14" },
    { id: 27, value: "**" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "11" },
    { id: 30, value: "66" },
    { id: 31, value: "40" },
    { id: 32, value: "44" },
    { id: 33, value: "40" },
    { id: 34, value: "**" },
    { id: 35, value: "**" },
  ],
  [
    { id: 36, value: "67" },
    { id: 37, value: "20" },
    { id: 38, value: "32" },
    { id: 39, value: "86" },
    { id: 40, value: "67" },
    { id: 41, value: "**" },
    { id: 42, value: "**" },
  ],
  [
    { id: 43, value: "03" },
    { id: 44, value: "98" },
    { id: 45, value: "80" },
    { id: 46, value: "20" },
    { id: 47, value: "29" },
    { id: 48, value: "**" },
    { id: 49, value: "**" },
  ],
  [
    { id: 50, value: "54" },
    { id: 51, value: "81" },
    { id: 52, value: "04" },
    { id: 53, value: "97" },
    { id: 54, value: "46" },
    { id: 55, value: "**" },
    { id: 56, value: "**" },
  ],
  [
    { id: 57, value: "39" },
    { id: 58, value: "36" },
    { id: 59, value: "58" },
    { id: 60, value: "32" },
    { id: 61, value: "74" },
    { id: 62, value: "**" },
    { id: 63, value: "**" },
  ],
  [
    { id: 64, value: "19" },
    { id: 65, value: "67" },
    { id: 66, value: "68" },
    { id: 67, value: "32" },
    { id: 68, value: "65" },
    { id: 69, value: "**" },
    { id: 70, value: "**" },
  ],
  [
    { id: 71, value: "46" },
    { id: 72, value: "25" },
    { id: 73, value: "91" },
    { id: 74, value: "51" },
    { id: 75, value: "22" },
    { id: 76, value: "**" },
    { id: 77, value: "**" },
  ],
  [
    { id: 78, value: "35" },
    { id: 79, value: "13" },
    { id: 80, value: "11" },
    { id: 81, value: "18" },
    { id: 82, value: "65" },
    { id: 83, value: "**" },
    { id: 84, value: "**" },
  ],
  [
    { id: 85, value: "48" },
    { id: 86, value: "93" },
    { id: 87, value: "52" },
    { id: 88, value: "97" },
    { id: 89, value: "97" },
    { id: 90, value: "**" },
    { id: 91, value: "**" },
  ],
  [
    { id: 1, value: "48" },
    { id: 2, value: "93" },
    { id: 3, value: "52" },
    { id: 4, value: "97" },
    { id: 5, value: "97" },
    { id: 6, value: "**" },
    { id: 7, value: "**" },
  ],
  [
    { id: 8, value: "32" },
    { id: 9, value: "50" },
    { id: 10, value: "47" },
    { id: 11, value: "89" },
    { id: 12, value: "12" },
    { id: 13, value: "**" },
    { id: 14, value: "**" },
  ],
  [
    { id: 15, value: "09" },
    { id: 16, value: "96" },
    { id: 17, value: "25" },
    { id: 18, value: "13" },
    { id: 19, value: "81" },
    { id: 20, value: "**" },
    { id: 21, value: "**" },
  ],
  [
    { id: 22, value: "82" },
    { id: 23, value: "67" },
    { id: 24, value: "95" },
    { id: 25, value: "68" },
    { id: 26, value: "44" },
    { id: 27, value: "**" },
    { id: 28, value: "**" },
  ],
  [
    { id: 29, value: "40" },
    { id: 30, value: "45" },
    { id: 31, value: "40" },
    { id: 32, value: "82" },
    { id: 33, value: "13" },
    { id: 34, value: "**" },
    { id: 35, value: "**" },
  ],
  [
    { id: 398, value: "74" },
    { id: 399, value: "05" },
    { id: 400, value: "59" },
    { id: 401, value: "33" },
    { id: 402, value: "63" },
    { id: 403, value: "**" },
    { id: 404, value: "**" },
  ],
  [
    { id: 410, value: "**" },
    { id: 405, value: "44" },
    { id: 406, value: "31" },
    { id: 407, value: "32" },
    { id: 408, value: "29" },
    { id: 411, value: "**" },
    { id: 411, value: "**" },
  ],
  [
    { id: 409, value: "64" },
    { id: 412, value: "33" },
    { id: 413, value: "15" },
    { id: 414, value: "03" },
    { id: 415, value: "45" },
    { id: 411, value: "**" },
    { id: 411, value: "**" },
  ],
  [
    { id: 416, value: "01" },
    { id: 417, value: "01" },
    { id: 418, value: "07" },
    { id: 419, value: "65" },
    { id: 420, value: "16" },
    { id: 421, value: "**" },
    { id: 422, value: "**" },
  ],
  [
    { id: 423, value: "29" },
    { id: 424, value: "16" },
    { id: 425, value: "96" },
    { id: 426, value: "71" },
    { id: 427, value: "75" },
    { id: 428, value: "**" },
    { id: 429, value: "**" },
  ],
  [
    { id: 430, value: "64" },
    { id: 431, value: "12" },
    { id: 432, value: "42" },
    { id: 433, value: "66" },
    { id: 434, value: "61" },
    { id: 435, value: "**" },
    { id: 436, value: "**" },
  ],
  [
    { id: 437, value: "16" },
    { id: 438, value: "62" },
    { id: 439, value: "26" },
    { id: 440, value: "32" },
    { id: 441, value: "50" },
    { id: 442, value: "**" },
    { id: 443, value: "**" },
  ],
  [
    { id: 444, value: "49" },
    { id: 445, value: "22" },
    { id: 446, value: "46" },
    { id: 447, value: "42" },
    { id: 448, value: "55" },
    { id: 449, value: "**" },
    { id: 450, value: "**" },
  ],
  [
    { id: 451, value: "64" },
    { id: 452, value: "07" },
    { id: 453, value: "05" },
    { id: 454, value: "24" },
    { id: 455, value: "20" },
    { id: 456, value: "**" },
    { id: 457, value: "**" },
  ],
  [
    { id: 458, value: "75" },
    { id: 459, value: "51" },
    { id: 460, value: "95" },
    { id: 461, value: "77" },
    { id: 462, value: "60" },
    { id: 463, value: "**" },
    { id: 464, value: "**" },
  ],
  [
    { id: 465, value: "71" },
    { id: 466, value: "33" },
    { id: 467, value: "07" },
    { id: 468, value: "59" },
    { id: 469, value: "68" },
    { id: 463, value: "**" },
    { id: 464, value: "**" },
  ],
  [
    { id: 470, value: "30" },
    { id: 471, value: "59" },
    { id: 472, value: "34" },
    { id: 473, value: "60" },
    { id: 474, value: "02" },
    { id: 475, value: "**" },
    { id: 476, value: "**" },
  ],
  [
    { id: 477, value: "78" },
    { id: 478, value: "67" },
    { id: 479, value: "70" },
    { id: 480, value: "60" },
    { id: 481, value: "23" },
    { id: 482, value: "**" },
    { id: 483, value: "**" },
  ],
  [
    { id: 484, value: "07" },
    { id: 485, value: "76" },
    { id: 486, value: "36" },
    { id: 487, value: "61" },
    { id: 488, value: "68" },
    { id: 489, value: "**" },
    { id: 490, value: "**" },
  ],
  [
    { id: 491, value: "81" },
    { id: 492, value: "84" },
    { id: 493, value: "84" },
    { id: 494, value: "91" },
    { id: 495, value: "18" },
    { id: 496, value: "**" },
    { id: 497, value: "**" },
  ],
  [
    { id: 498, value: "74" },
    { id: 499, value: "43" },
    { id: 500, value: "79" },
    { id: 501, value: "47" },
    { id: 502, value: "05" },
    { id: 503, value: "**" },
    { id: 504, value: "**" },
  ],
  [
    { id: 505, value: "00" },
    { id: 506, value: "60" },
    { id: 507, value: "12" },
    { id: 508, value: "31" },
    { id: 509, value: "12" },
    { id: 510, value: "**" },
    { id: 511, value: "**" },
  ],
  [
    { id: 512, value: "57" },
    { id: 513, value: "77" },
    { id: 514, value: "15" },
    { id: 515, value: "97" },
    { id: 516, value: "77" },
    { id: 517, value: "**" },
    { id: 518, value: "**" },
  ],
  [
    { id: 519, value: "78" },
    { id: 520, value: "37" },
    { id: 521, value: "84" },
    { id: 522, value: "13" },
    { id: 523, value: "13" },
    { id: 524, value: "**" },
    { id: 525, value: "**" },
  ],
  [
    { id: 526, value: "39" },
    { id: 527, value: "32" },
    { id: 528, value: "69" },
    { id: 529, value: "90" },
    { id: 530, value: "36" },
    { id: 531, value: "**" },
    { id: 532, value: "**" },
  ],
  [
    { id: 533, value: "46" },
    { id: 534, value: "16" },
    { id: 535, value: "02" },
    { id: 536, value: "70" },
    { id: 537, value: "97" },
    { id: 531, value: "**" },
    { id: 532, value: "**" },
  ],
  [
    { id: 533, value: "34" },
    { id: 534, value: "85" },
    { id: 535, value: "61" },
    { id: 536, value: "**" },
    { id: 537, value: "52" },
    { id: 538, value: "**" },
    { id: 538, value: "**" },
  ],
  [
    { id: 539, value: "81" },
    { id: 540, value: "14" },
    { id: 541, value: "52" },
    { id: 542, value: "41" },
    { id: 543, value: "38" },
    { id: 545, value: "**" },
    { id: 545, value: "**" },
  ],
  [
    { id: 544, value: "21" },
    { id: 546, value: "61" },
    { id: 547, value: "82" },
    { id: 548, value: "31" },
    { id: 549, value: "01" },
    { id: 550, value: "**" },
    { id: 550, value: "**" },
  ],
];

// maya morning

export const MayaMorning = [
  [
    { id: 1, value: "16" },
    { id: 2, value: "27" },
    { id: 3, value: "09" },
    { id: 4, value: "36" },
    { id: 5, value: "92" },
    { id: 6, value: "87" },
    { id: 7, value: "41" },
  ],
  [
    { id: 8, value: "74" },
    { id: 9, value: "98" },
    { id: 10, value: "69" },
    { id: 11, value: "12" },
    { id: 12, value: "17" },
    { id: 13, value: "51" },
    { id: 14, value: "03" },
  ],
  [
    { id: 15, value: "88" },
    { id: 16, value: "24" },
    { id: 17, value: "28" },
    { id: 18, value: "13" },
    { id: 19, value: "44" },
    { id: 20, value: "05" },
    { id: 21, value: "90" },
  ],
  [
    { id: 1, value: "88" },
    { id: 2, value: "24" },
    { id: 3, value: "28" },
    { id: 4, value: "13" },
    { id: 5, value: "44" },
    { id: 6, value: "05" },
    { id: 7, value: "90" },
  ],
  [
    { id: 8, value: "13" },
    { id: 9, value: "11" },
    { id: 10, value: "65" },
    { id: 11, value: "20" },
    { id: 12, value: "24" },
    { id: 13, value: "12" },
    { id: 14, value: "88" },
  ],
  [
    { id: 15, value: "72" },
    { id: 16, value: "05" },
    { id: 17, value: "84" },
    { id: 18, value: "62" },
    { id: 19, value: "05" },
    { id: 20, value: "32" },
    { id: 21, value: "45" },
  ],
  [
    { id: 1, value: "24" },
    { id: 2, value: "59" },
    { id: 3, value: "54" },
    { id: 4, value: "26" },
    { id: 5, value: "12" },
    { id: 6, value: "80" },
    { id: 7, value: "63" },
  ],
  [
    { id: 8, value: "35" },
    { id: 9, value: "49" },
    { id: 10, value: "29" },
    { id: 11, value: "85" },
    { id: 12, value: "08" },
    { id: 13, value: "86" },
    { id: 14, value: "38" },
  ],
  [
    { id: 15, value: "19" },
    { id: 16, value: "67" },
    { id: 17, value: "65" },
    { id: 18, value: "52" },
    { id: 19, value: "72" },
    { id: 20, value: "07" },
    { id: 20, value: "94" },
  ],
  [
    { id: 1, value: "71" },
    { id: 2, value: "49" },
    { id: 3, value: "68" },
    { id: 4, value: "55" },
    { id: 5, value: "11" },
    { id: 6, value: "20" },
    { id: 7, value: "90" },
  ],
  [
    { id: 1, value: "71" },
    { id: 2, value: "49" },
    { id: 3, value: "68" },
    { id: 4, value: "55" },
    { id: 5, value: "11" },
    { id: 6, value: "20" },
    { id: 7, value: "90" },
  ],
  [
    { id: 1, value: "28" },
    { id: 2, value: "89" },
    { id: 3, value: "20" },
    { id: 4, value: "28" },
    { id: 5, value: "49" },
    { id: 6, value: "93" },
    { id: 7, value: "76" },
  ],
  [
    { id: 1, value: "66" },
    { id: 2, value: "89" },
    { id: 3, value: "00" },
    { id: 4, value: "39" },
    { id: 5, value: "05" },
    { id: 6, value: "87" },
    { id: 7, value: "28" },
  ],
  [
    { id: 1, value: "52" },
    { id: 2, value: "48" },
    { id: 3, value: "00" },
    { id: 4, value: "46" },
    { id: 5, value: "40" },
    { id: 6, value: "18" },
    { id: 7, value: "23" },
  ],
  [
    { id: 1, value: "03" },
    { id: 2, value: "79" },
    { id: 3, value: "27" },
    { id: 4, value: "13" },
    { id: 5, value: "15" },
    { id: 6, value: "89" },
    { id: 7, value: "42" },
  ],
  [
    { id: 1, value: "91" },
    { id: 2, value: "25" },
    { id: 3, value: "28" },
    { id: 4, value: "60" },
    { id: 5, value: "55" },
    { id: 6, value: "43" },
    { id: 7, value: "40" },
  ],
  [
    { id: 43, value: "41" },
    { id: 44, value: "91" },
    { id: 45, value: "33" },
    { id: 46, value: "91" },
    { id: 47, value: "96" },
    { id: 48, value: "66" },
    { id: 49, value: "93" },
  ],
  [
    { id: 50, value: "98" },
    { id: 51, value: "90" },
    { id: 52, value: "53" },
    { id: 53, value: "66" },
    { id: 54, value: "08" },
    { id: 55, value: "29" },
    { id: 56, value: "99" },
  ],
  [
    { id: 57, value: "73" },
    { id: 58, value: "91" },
    { id: 59, value: "68" },
    { id: 60, value: "01" },
    { id: 61, value: "71" },
    { id: 62, value: "87" },
    { id: 63, value: "91" },
  ],
  [
    { id: 64, value: "46" },
    { id: 65, value: "11" },
    { id: 66, value: "72" },
    { id: 67, value: "27" },
    { id: 68, value: "26" },
    { id: 69, value: "42" },
    { id: 70, value: "74" },
  ],

  [
    { id: 1, value: "98" },
    { id: 2, value: "06" },
    { id: 3, value: "24" },
    { id: 4, value: "83" },
    { id: 5, value: "57" },
    { id: 6, value: "87" },
    { id: 7, value: "68" },
  ],
  [
    { id: 1, value: "84" },
    { id: 2, value: "50" },
    { id: 3, value: "46" },
    { id: 4, value: "53" },
    { id: 5, value: "97" },
    { id: 6, value: "60" },
    { id: 7, value: "09" },
  ],
  [
    { id: 1, value: "20" },
    { id: 2, value: "80" },
    { id: 3, value: "09" },
    { id: 4, value: "65" },
    { id: 5, value: "48" },
    { id: 6, value: "10" },
    { id: 7, value: "83" },
  ],
  [
    { id: 1, value: "98" },
    { id: 2, value: "06" },
    { id: 3, value: "24" },
    { id: 4, value: "83" },
    { id: 5, value: "57" },
    { id: 6, value: "87" },
    { id: 7, value: "80" },
  ],
  [
    { id: 1, value: "84" },
    { id: 2, value: "50" },
    { id: 3, value: "46" },
    { id: 4, value: "53" },
    { id: 5, value: "97" },
    { id: 6, value: "60" },
    { id: 7, value: "66" },
  ],
  [
    { id: 1, value: "20" },
    { id: 2, value: "80" },
    { id: 3, value: "09" },
    { id: 4, value: "65" },
    { id: 5, value: "48" },
    { id: 6, value: "10" },
    { id: 7, value: "80" },
  ],
  [
    { id: 1, value: "89" },
    { id: 2, value: "76" },
    { id: 3, value: "94" },
    { id: 4, value: "57" },
    { id: 5, value: "34" },
    { id: 6, value: "12" },
    { id: 7, value: "83" },
  ],
  [
    { id: 1, value: "90" },
    { id: 2, value: "45" },
    { id: 3, value: "72" },
    { id: 4, value: "68" },
    { id: 5, value: "33" },
    { id: 6, value: "18" },
    { id: 7, value: "66" },
  ],
  [
    { id: 1, value: "44" },
    { id: 2, value: "85" },
    { id: 3, value: "13" },
    { id: 4, value: "90" },
    { id: 5, value: "40" },
    { id: 6, value: "55" },
    { id: 7, value: "90" },
  ],

  [
    { id: 1, value: "22" },
    { id: 2, value: "67" },
    { id: 3, value: "48" },
    { id: 4, value: "77" },
    { id: 5, value: "23" },
    { id: 6, value: "39" },
    { id: 7, value: "90" },
  ],
  [
    { id: 1, value: "88" },
    { id: 2, value: "12" },
    { id: 3, value: "77" },
    { id: 4, value: "33" },
    { id: 5, value: "64" },
    { id: 6, value: "90" },
    { id: 7, value: "53" },
  ],
  [
    { id: 1, value: "88" },
    { id: 2, value: "06" },
    { id: 3, value: "11" },
    { id: 4, value: "41" },
    { id: 5, value: "90" },
    { id: 6, value: "55" },
    { id: 7, value: "90" },
  ],
  [
    { id: 1, value: "66" },
    { id: 2, value: "47" },
    { id: 3, value: "93" },
    { id: 4, value: "15" },
    { id: 5, value: "34" },
    { id: 6, value: "58" },
    { id: 7, value: "06" },
  ],
  [
    { id: 1, value: "25" },
    { id: 2, value: "93" },
    { id: 3, value: "39" },
    { id: 4, value: "03" },
    { id: 5, value: "02" },
    { id: 6, value: "09" },
    { id: 7, value: "14" },
  ],
  [
    { id: 1, value: "89" },
    { id: 2, value: "64" },
    { id: 3, value: "30" },
    { id: 4, value: "75" },
    { id: 5, value: "52" },
    { id: 6, value: "11" },
    { id: 7, value: "64" },
  ],
  [
    { id: 1, value: "90" },
    { id: 2, value: "35" },
    { id: 3, value: "83" },
    { id: 4, value: "14" },
    { id: 5, value: "22" },
    { id: 6, value: "99" },
    { id: 7, value: "33" },
  ],
  [
    { id: 1, value: "67" },
    { id: 2, value: "43" },
    { id: 3, value: "56" },
    { id: 4, value: "27" },
    { id: 5, value: "90" },
    { id: 6, value: "29" },
    { id: 7, value: "67" },
  ],
  [
    { id: 1, value: "64" },
    { id: 2, value: "23" },
    { id: 3, value: "82" },
    { id: 4, value: "47" },
    { id: 5, value: "55" },
    { id: 6, value: "68" },
    { id: 7, value: "67" },
  ],
];

export const INDRAMORNING = [
  [
    { id: 1, value: "66" },
    { id: 2, value: "77" },
    { id: 3, value: "42" },
    { id: 4, value: "09" },
    { id: 5, value: "53" },
    { id: 6, value: "96" },
    { id: 7, value: "39" },
  ],
  [
    { id: 8, value: "23" },
    { id: 9, value: "66" },
    { id: 10, value: "03" },
    { id: 11, value: "30" },
    { id: 12, value: "50" },
    { id: 13, value: "71" },
    { id: 14, value: "54" },
  ],
  [
    { id: 15, value: "14" },
    { id: 16, value: "60" },
    { id: 17, value: "61" },
    { id: 18, value: "60" },
    { id: 19, value: "41" },
    { id: 20, value: "68" },
    { id: 21, value: "03" },
  ],
  [
    { id: 22, value: "49" },
    { id: 23, value: "27" },
    { id: 24, value: "05" },
    { id: 25, value: "17" },
    { id: 26, value: "32" },
    { id: 27, value: "42" },
    { id: 28, value: "14" },
  ],
  [
    { id: 29, value: "41" },
    { id: 30, value: "29" },
    { id: 31, value: "10" },
    { id: 32, value: "45" },
    { id: 33, value: "54" },
    { id: 34, value: "22" },
    { id: 35, value: "64" },
  ],
  [
    { id: 36, value: "33" },
    { id: 37, value: "00" },
    { id: 38, value: "59" },
    { id: 39, value: "41" },
    { id: 40, value: "58" },
    { id: 41, value: "15" },
    { id: 42, value: "80" },
  ],
  [
    { id: 43, value: "15" },
    { id: 44, value: "47" },
    { id: 45, value: "31" },
    { id: 46, value: "83" },
    { id: 47, value: "51" },
    { id: 48, value: "92" },
    { id: 49, value: "15" },
  ],
  [
    { id: 50, value: "92" },
    { id: 51, value: "31" },
    { id: 52, value: "42" },
    { id: 53, value: "32" },
    { id: 54, value: "42" },
    { id: 55, value: "10" },
    { id: 56, value: "95" },
  ],
  [
    { id: 57, value: "13" },
    { id: 58, value: "80" },
    { id: 59, value: "35" },
    { id: 60, value: "55" },
    { id: 61, value: "19" },
    { id: 62, value: "10" },
    { id: 63, value: "23" },
  ],
  [
    { id: 64, value: "04" },
    { id: 65, value: "89" },
    { id: 66, value: "49" },
    { id: 67, value: "02" },
    { id: 68, value: "83" },
    { id: 69, value: "21" },
    { id: 70, value: "43" },
  ],
  [
    { id: 71, value: "01" },
    { id: 72, value: "08" },
    { id: 73, value: "49" },
    { id: 74, value: "22" },
    { id: 75, value: "88" },
    { id: 76, value: "52" },
    { id: 77, value: "14" },
  ],
  [
    { id: 78, value: "52" },
    { id: 79, value: "11" },
    { id: 80, value: "65" },
    { id: 81, value: "78" },
    { id: 82, value: "30" },
    { id: 83, value: "70" },
    { id: 84, value: "14" },
  ],
  [
    { id: 1, value: "90" },
    { id: 2, value: "18" },
    { id: 3, value: "90" },
    { id: 4, value: "39" },
    { id: 5, value: "31" },
    { id: 6, value: "55" },
    { id: 7, value: "66" },
  ],
  [
    { id: 8, value: "67" },
    { id: 9, value: "31" },
    { id: 10, value: "90" },
    { id: 11, value: "94" },
    { id: 12, value: "79" },
    { id: 13, value: "46" },
    { id: 14, value: "82" },
  ],
  [
    { id: 15, value: "64" },
    { id: 16, value: "12" },
    { id: 17, value: "69" },
    { id: 18, value: "53" },
    { id: 19, value: "03" },
    { id: 20, value: "78" },
    { id: 21, value: "42" },
  ],
  [
    { id: 22, value: "27" },
    { id: 23, value: "83" },
    { id: 24, value: "70" },
    { id: 25, value: "05" },
    { id: 26, value: "98" },
    { id: 27, value: "82" },
    { id: 28, value: "99" },
  ],
  [
    { id: 29, value: "80" },
    { id: 30, value: "32" },
    { id: 31, value: "96" },
    { id: 32, value: "86" },
    { id: 33, value: "67" },
    { id: 34, value: "77" },
    { id: 35, value: "47" },
  ],
  [
    { id: 36, value: "79" },
    { id: 37, value: "09" },
    { id: 38, value: "29" },
    { id: 39, value: "74" },
    { id: 40, value: "39" },
    { id: 41, value: "56" },
    { id: 42, value: "95" },
  ],
  [
    { id: 43, value: "45" },
    { id: 44, value: "84" },
    { id: 45, value: "08" },
    { id: 46, value: "55" },
    { id: 47, value: "75" },
    { id: 48, value: "94" },
    { id: 49, value: "40" },
  ],
  [
    { id: 50, value: "23" },
    { id: 51, value: "07" },
    { id: 52, value: "24" },
    { id: 53, value: "48" },
    { id: 54, value: "51" },
    { id: 55, value: "99" },
    { id: 56, value: "63" },
  ],
  [
    { id: 57, value: "19" },
    { id: 58, value: "68" },
    { id: 59, value: "22" },
    { id: 60, value: "01" },
    { id: 61, value: "96" },
    { id: 62, value: "17" },
    { id: 63, value: "08" },
  ],

  [
    { id: 1, value: "61" },
    { id: 2, value: "10" },
    { id: 3, value: "83" },
    { id: 4, value: "91" },
    { id: 5, value: "35" },
    { id: 6, value: "79" },
    { id: 7, value: "27" },
  ],
  [
    { id: 8, value: "88" },
    { id: 9, value: "67" },
    { id: 10, value: "77" },
    { id: 11, value: "22" },
    { id: 12, value: "82" },
    { id: 13, value: "30" },
    { id: 14, value: "18" },
  ],
  [
    { id: 15, value: "58" },
    { id: 16, value: "25" },
    { id: 17, value: "82" },
    { id: 18, value: "75" },
    { id: 19, value: "08" },
    { id: 20, value: "62" },
    { id: 21, value: "20" },
  ],
  [
    { id: 22, value: "80" },
    { id: 23, value: "64" },
    { id: 24, value: "15" },
    { id: 25, value: "97" },
    { id: 26, value: "65" },
    { id: 27, value: "87" },
    { id: 28, value: "96" },
  ],
  [
    { id: 29, value: "61" },
    { id: 30, value: "99" },
    { id: 31, value: "39" },
    { id: 32, value: "81" },
    { id: 33, value: "25" },
    { id: 34, value: "77" },
    { id: 35, value: "52" },
  ],
  [
    { id: 36, value: "58" },
    { id: 37, value: "49" },
    { id: 38, value: "17" },
    { id: 39, value: "80" },
    { id: 40, value: "93" },
    { id: 41, value: "30" },
    { id: 42, value: "22" },
  ],
  [
    { id: 43, value: "99" },
    { id: 44, value: "21" },
    { id: 45, value: "67" },
    { id: 46, value: "07" },
    { id: 47, value: "51" },
    { id: 48, value: "74" },
    { id: 49, value: "13" },
  ],
  [
    { id: 50, value: "89" },
    { id: 51, value: "55" },
    { id: 52, value: "31" },
    { id: 53, value: "97" },
    { id: 54, value: "88" },
    { id: 55, value: "30" },
    { id: 56, value: "96" },
  ],
  [
    { id: 57, value: "73" },
    { id: 58, value: "93" },
    { id: 59, value: "67" },
    { id: 60, value: "50" },
    { id: 61, value: "15" },
    { id: 62, value: "32" },
    { id: 63, value: "19" },
  ],
  [
    { id: 1, value: "51" },
    { id: 2, value: "34" },
    { id: 3, value: "99" },
    { id: 4, value: "35" },
    { id: 5, value: "70" },
    { id: 6, value: "28" },
    { id: 7, value: "44" },
  ],
  [
    { id: 1, value: "27" },
    { id: 2, value: "05" },
    { id: 3, value: "45" },
    { id: 4, value: "84" },
    { id: 5, value: "75" },
    { id: 6, value: "17" },
    { id: 6, value: "54" },
  ],
  [
    { id: 1, value: "67" },
    { id: 2, value: "96" },
    { id: 3, value: "40" },
    { id: 4, value: "13" },
    { id: 5, value: "60" },
    { id: 6, value: "11" },
    { id: 7, value: "34" },
  ],
  [
    { id: 1, value: "30" },
    { id: 2, value: "21" },
    { id: 3, value: "88" },
    { id: 4, value: "41" },
    { id: 5, value: "78" },
    { id: 6, value: "37" },
    { id: 6, value: "37" },
  ],
  [
    { id: 1, value: "49" },
    { id: 2, value: "10" },
    { id: 3, value: "21" },
    { id: 4, value: "02" },
    { id: 5, value: "70" },
    { id: 6, value: "16" },
    { id: 7, value: "25" },
  ],
  [
    { id: 1, value: "66" },
    { id: 2, value: "41" },
    { id: 3, value: "99" },
    { id: 4, value: "15" },
    { id: 5, value: "59" },
    { id: 6, value: "89" },
    { id: 7, value: "20" },
  ],
  [
    { id: 1, value: "24" },
    { id: 2, value: "80" },
    { id: 3, value: "86" },
    { id: 4, value: "71" },
    { id: 5, value: "54" },
    { id: 6, value: "66" },
    { id: 7, value: "17" },
  ],
];
export const INDRADAY = [
  [
    { id: 1, value: "73" },
    { id: 2, value: "61" },
    { id: 3, value: "04" },
    { id: 4, value: "69" },
    { id: 5, value: "76" },
    { id: 6, value: "04" },
    { id: 7, value: "85" },
  ],
  [
    { id: 8, value: "77" },
    { id: 9, value: "96" },
    { id: 10, value: "73" },
    { id: 11, value: "42" },
    { id: 12, value: "43" },
    { id: 13, value: "35" },
    { id: 14, value: "96" },
  ],
  [
    { id: 15, value: "37" },
    { id: 16, value: "03" },
    { id: 17, value: "01" },
    { id: 18, value: "04" },
    { id: 19, value: "97" },
    { id: 20, value: "95" },
    { id: 21, value: "83" },
  ],
  [
    { id: 22, value: "54" },
    { id: 23, value: "58" },
    { id: 24, value: "14" },
    { id: 25, value: "07" },
    { id: 26, value: "51" },
    { id: 27, value: "07" },
    { id: 28, value: "46" },
  ],
  [
    { id: 29, value: "24" },
    { id: 30, value: "99" },
    { id: 31, value: "85" },
    { id: 32, value: "82" },
    { id: 33, value: "00" },
    { id: 34, value: "79" },
    { id: 35, value: "86" },
  ],
  [
    { id: 1, value: "51" },
    { id: 2, value: "57" },
    { id: 3, value: "62" },
    { id: 4, value: "62" },
    { id: 5, value: "94" },
    { id: 6, value: "58" },
    { id: 7, value: "97" },
  ],
  [
    { id: 8, value: "87" },
    { id: 9, value: "00" },
    { id: 10, value: "64" },
    { id: 11, value: "82" },
    { id: 12, value: "67" },
    { id: 13, value: "05" },
    { id: 14, value: "26" },
  ],
  [
    { id: 15, value: "06" },
    { id: 16, value: "02" },
    { id: 17, value: "27" },
    { id: 18, value: "96" },
    { id: 19, value: "**" },
    { id: 20, value: "22" },
    { id: 21, value: "40" },
  ],
  [
    { id: 22, value: "67" },
    { id: 23, value: "25" },
    { id: 24, value: "19" },
    { id: 25, value: "98" },
    { id: 26, value: "95" },
    { id: 27, value: "95" },
    { id: 28, value: "68" },
  ],
  [
    { id: 29, value: "19" },
    { id: 30, value: "46" },
    { id: 31, value: "68" },
    { id: 32, value: "59" },
    { id: 33, value: "87" },
    { id: 34, value: "32" },
    { id: 35, value: "24" },
  ],
  [
    { id: 36, value: "34" },
    { id: 37, value: "09" },
    { id: 38, value: "94" },
    { id: 39, value: "46" },
    { id: 40, value: "09" },
    { id: 41, value: "49" },
    { id: 42, value: "47" },
  ],
  [
    { id: 1, value: "01" },
    { id: 2, value: "51" },
    { id: 3, value: "47" },
    { id: 4, value: "19" },
    { id: 5, value: "48" },
    { id: 6, value: "57" },
    { id: 7, value: "97" },
  ],
  [
    { id: 8, value: "89" },
    { id: 9, value: "64" },
    { id: 10, value: "88" },
    { id: 11, value: "84" },
    { id: 12, value: "29" },
    { id: 13, value: "26" },
    { id: 14, value: "62" },
  ],
  [
    { id: 15, value: "54" },
    { id: 16, value: "60" },
    { id: 17, value: "14" },
    { id: 18, value: "94" },
    { id: 19, value: "94" },
    { id: 20, value: "59" },
    { id: 21, value: "87" },
  ],
  [
    { id: 22, value: "39" },
    { id: 23, value: "35" },
    { id: 24, value: "57" },
    { id: 25, value: "96" },
    { id: 26, value: "01" },
    { id: 27, value: "14" },
    { id: 28, value: "82" },
  ],
  [
    { id: 29, value: "55" },
    { id: 30, value: "45" },
    { id: 31, value: "25" },
    { id: 32, value: "49" },
    { id: 33, value: "58" },
    { id: 34, value: "22" },
    { id: 35, value: "19" },
  ],
  [
    { id: 36, value: "01" },
    { id: 37, value: "60" },
    { id: 38, value: "38" },
    { id: 39, value: "04" },
    { id: 40, value: "60" },
    { id: 41, value: "79" },
    { id: 42, value: "72" },
  ],
  [
    { id: 43, value: "47" },
    { id: 44, value: "09" },
    { id: 45, value: "08" },
    { id: 46, value: "09" },
    { id: 47, value: "49" },
    { id: 48, value: "06" },
    { id: 49, value: "94" },
  ],
  [
    { id: 1, value: "81" },
    { id: 2, value: "71" },
    { id: 3, value: "71" },
    { id: 4, value: "18" },
    { id: 5, value: "48" },
    { id: 6, value: "29" },
    { id: 7, value: "86" },
  ],
  [
    { id: 8, value: "13" },
    { id: 9, value: "97" },
    { id: 10, value: "17" },
    { id: 11, value: "56" },
    { id: 12, value: "84" },
    { id: 13, value: "16" },
    { id: 14, value: "64" },
  ],
  [
    { id: 15, value: "90" },
    { id: 16, value: "35" },
    { id: 17, value: "62" },
    { id: 18, value: "18" },
    { id: 19, value: "63" },
    { id: 20, value: "21" },
    { id: 21, value: "60" },
  ],
  [
    { id: 22, value: "82" },
    { id: 23, value: "69" },
    { id: 24, value: "79" },
    { id: 25, value: "01" },
    { id: 26, value: "85" },
    { id: 27, value: "98" },
    { id: 28, value: "05" },
  ],
  [
    { id: 29, value: "61" },
    { id: 30, value: "70" },
    { id: 31, value: "70" },
    { id: 32, value: "03" },
    { id: 33, value: "60" },
    { id: 34, value: "45" },
    { id: 35, value: "05" },
  ],
  [
    { id: 36, value: "61" },
    { id: 37, value: "70" },
    { id: 38, value: "60" },
    { id: 39, value: "82" },
    { id: 40, value: "35" },
    { id: 41, value: "71" },
    { id: 42, value: "48" },
  ],
  [
    { id: 1, value: "**" },
    { id: 1, value: "81" },
    { id: 2, value: "71" },
    { id: 3, value: "71" },
    { id: 4, value: "18" },
    { id: 5, value: "48" },
    { id: 6, value: "29" },
  ],
  [
    { id: 7, value: "86" },
    { id: 8, value: "13" },
    { id: 9, value: "97" },
    { id: 10, value: "17" },
    { id: 11, value: "56" },
    { id: 12, value: "84" },
    { id: 13, value: "16" },
  ],
  [
    { id: 14, value: "64" },
    { id: 15, value: "90" },
    { id: 16, value: "35" },
    { id: 17, value: "62" },
    { id: 18, value: "18" },
    { id: 19, value: "63" },
    { id: 20, value: "21" },
  ],
  [
    { id: 21, value: "60" },
    { id: 22, value: "82" },
    { id: 23, value: "69" },
    { id: 24, value: "79" },
    { id: 25, value: "01" },
    { id: 26, value: "85" },
    { id: 27, value: "98" },
  ],
  [
    { id: 28, value: "05" },
    { id: 29, value: "61" },
    { id: 30, value: "70" },
    { id: 31, value: "70" },
    { id: 32, value: "03" },
    { id: 33, value: "60" },
    { id: 34, value: "45" },
  ],
  [
    { id: 35, value: "05" },
    { id: 36, value: "61" },
    { id: 37, value: "70" },
    { id: 38, value: "60" },
    { id: 39, value: "82" },
    { id: 40, value: "35" },
    { id: 41, value: "71" },
  ],
  [
    { id: 1, value: "14" },
    { id: 2, value: "68" },
    { id: 3, value: "00" },
    { id: 4, value: "32" },
    { id: 5, value: "02" },
    { id: 6, value: "06" },
    { id: 7, value: "22" },
  ],
  [
    { id: 8, value: "43" },
    { id: 9, value: "36" },
    { id: 10, value: "64" },
    { id: 11, value: "79" },
    { id: 12, value: "79" },
    { id: 13, value: "91" },
    { id: 14, value: "77" },
  ],
  [
    { id: 15, value: "58" },
    { id: 16, value: "04" },
    { id: 17, value: "50" },
    { id: 18, value: "86" },
    { id: 19, value: "92" },
    { id: 20, value: "11" },
    { id: 21, value: "30" },
  ],
  [
    { id: 22, value: "28" },
    { id: 23, value: "19" },
    { id: 24, value: "53" },
    { id: 25, value: "46" },
    { id: 26, value: "47" },
    { id: 27, value: "76" },
    { id: 28, value: "12" },
  ],
  [
    { id: 29, value: "92" },
    { id: 30, value: "63" },
    { id: 31, value: "07" },
    { id: 32, value: "41" },
    { id: 33, value: "57" },
    { id: 34, value: "13" },
    { id: 35, value: "81" },
  ],
  [
    { id: 36, value: "79" },
    { id: 37, value: "54" },
    { id: 38, value: "80" },
    { id: 39, value: "13" },
    { id: 40, value: "37" },
    { id: 41, value: "59" },
    { id: 42, value: "08" },
  ],
  [
    { id: 1, value: "98" },
    { id: 2, value: "82" },
    { id: 3, value: "64" },
    { id: 4, value: "40" },
    { id: 5, value: "48" },
    { id: 6, value: "58" },
    { id: 7, value: "89" },
  ],
  [
    { id: 8, value: "15" },
    { id: 9, value: "51" },
    { id: 10, value: "71" },
    { id: 11, value: "88" },
    { id: 12, value: "59" },
    { id: 13, value: "44" },
    { id: 14, value: "17" },
  ],
  [
    { id: 15, value: "84" },
    { id: 16, value: "06" },
    { id: 17, value: "75" },
    { id: 18, value: "55" },
    { id: 19, value: "13" },
    { id: 20, value: "26" },
    { id: 21, value: "46" },
  ],
  [
    { id: 22, value: "35" },
    { id: 23, value: "05" },
    { id: 24, value: "97" },
    { id: 25, value: "21" },
    { id: 26, value: "30" },
    { id: 27, value: "57" },
    { id: 28, value: "84" },
  ],
  [
    { id: 29, value: "45" },
    { id: 30, value: "92" },
    { id: 31, value: "42" },
    { id: 32, value: "54" },
    { id: 33, value: "48" },
    { id: 34, value: "20" },
    { id: 35, value: "62" },
  ],
  [
    { id: 36, value: "15" },
    { id: 37, value: "28" },
    { id: 38, value: "39" },
    { id: 39, value: "17" },
    { id: 40, value: "46" },
    { id: 41, value: "94" },
    { id: 42, value: "67" },
  ],
  [
    { id: 43, value: "43" },
    { id: 44, value: "62" },
    { id: 45, value: "11" },
    { id: 46, value: "52" },
    { id: 47, value: "02" },
    { id: 48, value: "82" },
    { id: 48, value: "64" },
  ],
];
export const INDRANIGHT = [
  [
    { id: 1, value: "16" },
    { id: 2, value: "48" },
    { id: 3, value: "22" },
    { id: 4, value: "71" },
    { id: 5, value: "97" },
    { id: 6, value: "39" },
    { id: 7, value: "23" },
  ],
  [
    { id: 8, value: "39" },
    { id: 9, value: "88" },
    { id: 10, value: "82" },
    { id: 11, value: "76" },
    { id: 12, value: "82" },
    { id: 13, value: "78" },
    { id: 14, value: "59" },
  ],
  [
    { id: 15, value: "86" },
    { id: 16, value: "95" },
    { id: 17, value: "03" },
    { id: 18, value: "43" },
    { id: 19, value: "55" },
    { id: 20, value: "81" },
    { id: 21, value: "78" },
  ],
  [
    { id: 22, value: "65" },
    { id: 23, value: "22" },
    { id: 24, value: "70" },
    { id: 25, value: "45" },
    { id: 26, value: "08" },
    { id: 27, value: "82" },
    { id: 28, value: "71" },
  ],
  [
    { id: 29, value: "58" },
    { id: 30, value: "75" },
    { id: 31, value: "23" },
    { id: 32, value: "09" },
    { id: 33, value: "35" },
    { id: 34, value: "07" },
    { id: 35, value: "36" },
  ],
  [
    { id: 36, value: "65" },
    { id: 37, value: "68" },
    { id: 38, value: "08" },
    { id: 39, value: "73" },
    { id: 40, value: "28" },
    { id: 41, value: "60" },
    { id: 42, value: "36" },
  ],
  [
    { id: 43, value: "83" },
    { id: 44, value: "41" },
    { id: 45, value: "70" },
    { id: 46, value: "16" },
    { id: 47, value: "06" },
    { id: 48, value: "69" },
    { id: 49, value: "32" },
  ],
  [
    { id: 50, value: "52" },
    { id: 51, value: "62" },
    { id: 52, value: "49" },
    { id: 53, value: "07" },
    { id: 54, value: "03" },
    { id: 55, value: "67" },
    { id: 56, value: "18" },
  ],
  [
    { id: 57, value: "86" },
    { id: 58, value: "48" },
    { id: 59, value: "28" },
    { id: 60, value: "94" },
    { id: 61, value: "12" },
    { id: 62, value: "12" },
    { id: 63, value: "91" },
  ],
  [
    { id: 64, value: "86" },
    { id: 65, value: "08" },
    { id: 66, value: "11" },
    { id: 67, value: "11" },
    { id: 68, value: "17" },
    { id: 69, value: "55" },
    { id: 70, value: "37" },
  ],
  [
    { id: 1, value: "14" },
    { id: 2, value: "87" },
    { id: 3, value: "58" },
    { id: 4, value: "89" },
    { id: 5, value: "98" },
    { id: 6, value: "64" },
    { id: 5, value: "98" },
  ],
  [
    { id: 1, value: "89" },
    { id: 2, value: "45" },
    { id: 3, value: "74" },
    { id: 4, value: "83" },
    { id: 5, value: "50" },
    { id: 6, value: "62" },
    { id: 7, value: "11" },
  ],
  [
    { id: 1, value: "87" },
    { id: 2, value: "44" },
    { id: 3, value: "81" },
    { id: 4, value: "43" },
    { id: 5, value: "43" },
    { id: 6, value: "98" },
    { id: 7, value: "56" },
  ],
  [
    { id: 1, value: "78" },
    { id: 2, value: "91" },
    { id: 3, value: "19" },
    { id: 4, value: "08" },
    { id: 5, value: "26" },
    { id: 6, value: "73" },
    { id: 7, value: "20" },
  ],
  [
    { id: 1, value: "43" },
    { id: 2, value: "20" },
    { id: 3, value: "95" },
    { id: 4, value: "07" },
    { id: 5, value: "01" },
    { id: 6, value: "11" },
    { id: 7, value: "88" },
  ],
  [
    { id: 1, value: "49" },
    { id: 2, value: "90" },
    { id: 3, value: "83" },
    { id: 4, value: "16" },
    { id: 5, value: "24" },
    { id: 6, value: "04" },
    { id: 7, value: "30" },
  ],
  [
    { id: 1, value: "13" },
    { id: 2, value: "21" },
    { id: 3, value: "39" },
    { id: 4, value: "99" },
    { id: 5, value: "93" },
    { id: 6, value: "52" },
    { id: 7, value: "22" },
  ],
  [
    { id: 1, value: "46" },
    { id: 2, value: "60" },
    { id: 3, value: "01" },
    { id: 4, value: "20" },
    { id: 5, value: "74" },
    { id: 6, value: "84" },
    { id: 7, value: "67" },
  ],
  [
    { id: 1, value: "04" },
    { id: 2, value: "79" },
    { id: 3, value: "86" },
    { id: 4, value: "41" },
    { id: 5, value: "37" },
    { id: 6, value: "97" },
    { id: 7, value: "00" },
  ],
  [
    { id: 1, value: "81" },
    { id: 2, value: "26" },
    { id: 3, value: "73" },
    { id: 4, value: "31" },
    { id: 5, value: "96" },
    { id: 6, value: "43" },
    { id: 7, value: "18" },
  ],
  [
    { id: 1, value: "14" },
    { id: 2, value: "45" },
    { id: 3, value: "87" },
    { id: 4, value: "58" },
    { id: 5, value: "89" },
    { id: 6, value: "98" },
    { id: 7, value: "64" },
  ],
  [
    { id: 8, value: "89" },
    { id: 9, value: "45" },
    { id: 10, value: "74" },
    { id: 11, value: "83" },
    { id: 12, value: "50" },
    { id: 13, value: "62" },
    { id: 14, value: "11" },
  ],
  [
    { id: 15, value: "87" },
    { id: 16, value: "44" },
    { id: 17, value: "81" },
    { id: 18, value: "43" },
    { id: 19, value: "43" },
    { id: 20, value: "56" },
    { id: 21, value: "78" },
  ],
  [
    { id: 22, value: "26" },
    { id: 23, value: "19" },
    { id: 24, value: "08" },
    { id: 25, value: "26" },
    { id: 26, value: "73" },
    { id: 27, value: "20" },
    { id: 28, value: "20" },
  ],
  [
    { id: 1, value: "43" },
    { id: 2, value: "20" },
    { id: 3, value: "95" },
    { id: 4, value: "07" },
    { id: 5, value: "01" },
    { id: 6, value: "11" },
    { id: 7, value: "88" },
  ],
  [
    { id: 8, value: "49" },
    { id: 9, value: "90" },
    { id: 10, value: "83" },
    { id: 11, value: "16" },
    { id: 12, value: "24" },
    { id: 13, value: "04" },
    { id: 14, value: "30" },
  ],
  [
    { id: 15, value: "13" },
    { id: 16, value: "21" },
    { id: 17, value: "39" },
    { id: 18, value: "99" },
    { id: 19, value: "93" },
    { id: 20, value: "52" },
    { id: 21, value: "22" },
  ],
  [
    { id: 22, value: "46" },
    { id: 23, value: "60" },
    { id: 24, value: "01" },
    { id: 25, value: "20" },
    { id: 26, value: "74" },
    { id: 27, value: "84" },
    { id: 28, value: "67" },
  ],
  [
    { id: 29, value: "04" },
    { id: 30, value: "79" },
    { id: 31, value: "86" },
    { id: 32, value: "41" },
    { id: 33, value: "37" },
    { id: 34, value: "97" },
    { id: 35, value: "00" },
  ],
  [
    { id: 1, value: "81" },
    { id: 2, value: "26" },
    { id: 3, value: "73" },
    { id: 4, value: "31" },
    { id: 5, value: "96" },
    { id: 6, value: "43" },
    { id: 7, value: "18" },
  ],
  [
    { id: 8, value: "17" },
    { id: 9, value: "95" },
    { id: 10, value: "29" },
    { id: 11, value: "34" },
    { id: 12, value: "08" },
    { id: 13, value: "73" },
    { id: 14, value: "87" },
  ],
  [
    { id: 15, value: "24" },
    { id: 16, value: "86" },
    { id: 17, value: "31" },
    { id: 18, value: "04" },
    { id: 19, value: "61" },
    { id: 20, value: "52" },
    { id: 21, value: "12" },
  ],
  [
    { id: 22, value: "09" },
    { id: 23, value: "51" },
    { id: 24, value: "96" },
    { id: 25, value: "82" },
    { id: 26, value: "03" },
    { id: 27, value: "18" },
    { id: 28, value: "74" },
  ],
  [
    { id: 29, value: "17" },
    { id: 30, value: "92" },
    { id: 31, value: "20" },
    { id: 32, value: "66" },
    { id: 33, value: "19" },
    { id: 34, value: "83" },
    { id: 35, value: "30" },
  ],
  [
    { id: 1, value: "81" },
    { id: 2, value: "08" },
    { id: 3, value: "39" },
    { id: 4, value: "51" },
    { id: 5, value: "06" },
    { id: 6, value: "46" },
    { id: 7, value: "54" },
  ],
  [
    { id: 8, value: "30" },
    { id: 9, value: "26" },
    { id: 10, value: "02" },
    { id: 11, value: "71" },
    { id: 12, value: "84" },
    { id: 13, value: "07" },
    { id: 14, value: "91" },
  ],
  [
    { id: 15, value: "63" },
    { id: 16, value: "32" },
    { id: 17, value: "48" },
    { id: 18, value: "50" },
    { id: 19, value: "93" },
    { id: 20, value: "07" },
    { id: 21, value: "03" },
  ],
  [
    { id: 22, value: "71" },
    { id: 23, value: "98" },
    { id: 24, value: "42" },
    { id: 25, value: "81" },
    { id: 26, value: "63" },
    { id: 27, value: "57" },
    { id: 28, value: "91" },
  ],
  [
    { id: 29, value: "88" },
    { id: 30, value: "06" },
    { id: 31, value: "11" },
    { id: 32, value: "41" },
    { id: 33, value: "90" },
    { id: 34, value: "55" },
    { id: 35, value: "39" },
  ],
  [
    { id: 36, value: "81" },
    { id: 37, value: "08" },
    { id: 38, value: "39" },
    { id: 39, value: "51" },
    { id: 40, value: "06" },
    { id: 41, value: "46" },
    { id: 42, value: "54" },
  ],
  [
    { id: 1, value: "25" },
    { id: 2, value: "93" },
    { id: 3, value: "39" },
    { id: 4, value: "03" },
    { id: 5, value: "02" },
    { id: 6, value: "09" },
    { id: 7, value: "46" },
  ],
  [
    { id: 8, value: "32" },
    { id: 9, value: "99" },
    { id: 10, value: "16" },
    { id: 11, value: "25" },
    { id: 12, value: "10" },
    { id: 13, value: "77" },
    { id: 14, value: "83" },
  ],
  [
    { id: 15, value: "14" },
    { id: 16, value: "31" },
    { id: 17, value: "88" },
    { id: 18, value: "43" },
    { id: 19, value: "29" },
    { id: 20, value: "12" },
    { id: 21, value: "56" },
  ],
  [
    { id: 22, value: "70" },
    { id: 23, value: "19" },
    { id: 24, value: "32" },
    { id: 25, value: "84" },
    { id: 26, value: "49" },
    { id: 27, value: "93" },
    { id: 28, value: "10" },
  ],
  [
    { id: 29, value: "13" },
    { id: 30, value: "58" },
    { id: 31, value: "30" },
    { id: 32, value: "45" },
    { id: 33, value: "90" },
    { id: 34, value: "18" },
    { id: 35, value: "24" },
  ],
  [
    { id: 36, value: "62" },
    { id: 37, value: "40" },
    { id: 38, value: "88" },
    { id: 39, value: "15" },
    { id: 40, value: "67" },
    { id: 41, value: "21" },
    { id: 42, value: "73" },
  ],
];

export const MAYADAY = [
  [
    { id: 1, value: "93" },
    { id: 2, value: "50" },
    { id: 3, value: "27" },
    { id: 4, value: "65" },
    { id: 5, value: "53" },
    { id: 6, value: "62" },
    { id: 7, value: "54" },
  ],
  [
    { id: 8, value: "51" },
    { id: 9, value: "39" },
    { id: 10, value: "26" },
    { id: 11, value: "10" },
    { id: 12, value: "91" },
    { id: 13, value: "72" },
    { id: 14, value: "67" },
  ],
  [
    { id: 15, value: "54" },
    { id: 16, value: "28" },
    { id: 17, value: "79" },
    { id: 18, value: "29" },
    { id: 19, value: "51" },
    { id: 20, value: "57" },
    { id: 21, value: "62" },
  ],
  [
    { id: 1, value: "77" },
    { id: 2, value: "81" },
    { id: 3, value: "48" },
    { id: 4, value: "93" },
    { id: 5, value: "87" },
    { id: 6, value: "00" },
    { id: 7, value: "64" },
  ],
  [
    { id: 8, value: "14" },
    { id: 9, value: "30" },
    { id: 10, value: "94" },
    { id: 11, value: "14" },
    { id: 12, value: "85" },
    { id: 13, value: "95" },
    { id: 14, value: "90" },
  ],
  [
    { id: 8, value: "14" },
    { id: 9, value: "30" },
    { id: 10, value: "94" },
    { id: 11, value: "14" },
    { id: 12, value: "85" },
    { id: 13, value: "95" },
    { id: 14, value: "90" },
  ],
  [
    { id: 15, value: "50" },
    { id: 16, value: "87" },
    { id: 17, value: "23" },
    { id: 18, value: "04" },
    { id: 19, value: "95" },
    { id: 20, value: "06" },
    { id: 21, value: "93" },
  ],
  [
    { id: 22, value: "06" },
    { id: 23, value: "68" },
    { id: 24, value: "50" },
    { id: 25, value: "42" },
    { id: 26, value: "62" },
    { id: 27, value: "16" },
    { id: 28, value: "03" },
  ],
  [
    { id: 1, value: "31" },
    { id: 2, value: "26" },
    { id: 3, value: "78" },
    { id: 4, value: "49" },
    { id: 5, value: "91" },
    { id: 6, value: "20" },
    { id: 7, value: "29" },
  ],
  [
    { id: 8, value: "73" },
    { id: 9, value: "82" },
    { id: 10, value: "79" },
    { id: 11, value: "37" },
    { id: 12, value: "95" },
    { id: 13, value: "46" },
    { id: 14, value: "95" },
  ],
  [
    { id: 15, value: "90" },
    { id: 16, value: "40" },
    { id: 17, value: "74" },
    { id: 18, value: "28" },
    { id: 19, value: "93" },
    { id: 20, value: "07" },
    { id: 21, value: "69" },
  ],
  [
    { id: 22, value: "90" },
    { id: 23, value: "32" },
    { id: 24, value: "63" },
    { id: 25, value: "37" },
    { id: 26, value: "97" },
    { id: 27, value: "55" },
    { id: 28, value: "72" },
  ],
  [
    { id: 29, value: "23" },
    { id: 30, value: "97" },
    { id: 31, value: "92" },
    { id: 32, value: "28" },
    { id: 33, value: "53" },
    { id: 34, value: "03" },
    { id: 35, value: "15" },
  ],
  [
    { id: 1, value: "43" },
    { id: 2, value: "62" },
    { id: 3, value: "11" },
    { id: 4, value: "52" },
    { id: 5, value: "02" },
    { id: 6, value: "82" },
    { id: 7, value: "64" },
  ],
  [
    { id: 8, value: "66" },
    { id: 9, value: "98" },
    { id: 10, value: "14" },
    { id: 11, value: "40" },
    { id: 12, value: "65" },
    { id: 13, value: "45" },
    { id: 14, value: "98" },
  ],
  [
    { id: 15, value: "96" },
    { id: 16, value: "09" },
    { id: 17, value: "25" },
    { id: 18, value: "61" },
    { id: 19, value: "84" },
    { id: 20, value: "13" },
    { id: 21, value: "67" },
  ],
  [
    { id: 22, value: "11" },
    { id: 23, value: "37" },
    { id: 24, value: "09" },
    { id: 25, value: "88" },
    { id: 26, value: "54" },
    { id: 27, value: "06" },
    { id: 28, value: "93" },
  ],
  [
    { id: 1, value: "38" },
    { id: 2, value: "07" },
    { id: 3, value: "00" },
    { id: 4, value: "65" },
    { id: 5, value: "31" },
    { id: 6, value: "45" },
    { id: 7, value: "95" },
  ],
  [
    { id: 8, value: "04" },
    { id: 9, value: "86" },
    { id: 10, value: "50" },
    { id: 11, value: "80" },
    { id: 12, value: "93" },
    { id: 13, value: "44" },
    { id: 14, value: "86" },
  ],
  [
    { id: 15, value: "81" },
    { id: 16, value: "02" },
    { id: 17, value: "76" },
    { id: 18, value: "44" },
    { id: 19, value: "51" },
    { id: 20, value: "80" },
    { id: 21, value: "73" },
  ],
  [
    { id: 22, value: "39" },
    { id: 23, value: "61" },
    { id: 24, value: "02" },
    { id: 25, value: "10" },
    { id: 26, value: "39" },
    { id: 27, value: "90" },
    { id: 28, value: "26" },
  ],
  [
    { id: 29, value: "94" },
    { id: 30, value: "22" },
    { id: 31, value: "34" },
    { id: 32, value: "19" },
    { id: 33, value: "93" },
    { id: 34, value: "41" },
    { id: 35, value: "04" },
  ],
  [
    { id: 36, value: "66" },
    { id: 37, value: "56" },
    { id: 38, value: "74" },
    { id: 39, value: "75" },
    { id: 40, value: "19" },
    { id: 41, value: "89" },
    { id: 42, value: "89" },
  ],
  [
    { id: 1, value: "95" },
    { id: 2, value: "85" },
    { id: 3, value: "47" },
    { id: 4, value: "20" },
    { id: 5, value: "06" },
    { id: 6, value: "60" },
    { id: 7, value: "80" },
  ],
  [
    { id: 8, value: "47" },
    { id: 9, value: "31" },
    { id: 10, value: "81" },
    { id: 11, value: "65" },
    { id: 12, value: "92" },
    { id: 13, value: "81" },
    { id: 14, value: "71" },
  ],
  [
    { id: 15, value: "73" },
    { id: 16, value: "50" },
    { id: 17, value: "43" },
    { id: 18, value: "51" },
    { id: 19, value: "55" },
    { id: 20, value: "38" },
    { id: 21, value: "22" },
  ],
  [
    { id: 22, value: "83" },
    { id: 23, value: "18" },
    { id: 24, value: "25" },
    { id: 25, value: "30" },
    { id: 26, value: "70" },
    { id: 27, value: "14" },
    { id: 28, value: "31" },
  ],
  [
    { id: 29, value: "54" },
    { id: 30, value: "10" },
    { id: 31, value: "40" },
    { id: 32, value: "68" },
    { id: 33, value: "08" },
    { id: 34, value: "94" },
    { id: 35, value: "03" },
  ],
  [
    { id: 36, value: "03" },
    { id: 37, value: "08" },
    { id: 38, value: "48" },
    { id: 39, value: "22" },
    { id: 40, value: "84" },
    { id: 41, value: "62" },
    { id: 42, value: "79" },
  ],
  [
    { id: 1, value: "69" },
    { id: 2, value: "22" },
    { id: 3, value: "17" },
    { id: 4, value: "08" },
    { id: 5, value: "70" },
    { id: 6, value: "35" },
    { id: 7, value: "01" },
  ],
  [
    { id: 8, value: "87" },
    { id: 9, value: "96" },
    { id: 10, value: "43" },
    { id: 11, value: "39" },
    { id: 12, value: "87" },
    { id: 13, value: "51" },
    { id: 14, value: "99" },
  ],
  [
    { id: 15, value: "25" },
    { id: 16, value: "93" },
    { id: 17, value: "39" },
    { id: 18, value: "03" },
    { id: 19, value: "09" },
    { id: 20, value: "09" },
    { id: 21, value: "80" },
  ],
  [
    { id: 22, value: "33" },
    { id: 23, value: "77" },
    { id: 24, value: "55" },
    { id: 25, value: "42" },
    { id: 26, value: "89" },
    { id: 27, value: "11" },
    { id: 28, value: "23" },
  ],
  [
    { id: 29, value: "66" },
    { id: 30, value: "90" },
    { id: 31, value: "78" },
    { id: 32, value: "34" },
    { id: 33, value: "21" },
    { id: 34, value: "88" },
    { id: 35, value: "13" },
  ],
  [
    { id: 36, value: "72" },
    { id: 37, value: "55" },
    { id: 38, value: "16" },
    { id: 39, value: "44" },
    { id: 40, value: "33" },
    { id: 41, value: "61" },
    { id: 42, value: "29" },
  ],
  [
    { id: 43, value: "78" },
    { id: 44, value: "67" },
    { id: 45, value: "82" },
    { id: 46, value: "93" },
    { id: 47, value: "67" },
    { id: 48, value: "10" },
    { id: 49, value: "96" },
  ],
];

export const MAYANIGHT = [
  [
    { id: 1, value: "31" },
    { id: 2, value: "02" },
    { id: 3, value: "75" },
    { id: 4, value: "09" },
    { id: 5, value: "64" },
    { id: 6, value: "32" },
    { id: 7, value: "21" },
  ],
  [
    { id: 8, value: "74" },
    { id: 9, value: "11" },
    { id: 10, value: "94" },
    { id: 11, value: "31" },
    { id: 12, value: "43" },
    { id: 13, value: "26" },
    { id: 14, value: "89" },
  ],
  [
    { id: 15, value: "12" },
    { id: 16, value: "72" },
    { id: 17, value: "47" },
    { id: 18, value: "60" },
    { id: 19, value: "33" },
    { id: 20, value: "52" },
    { id: 21, value: "04" },
  ],
  [
    { id: 22, value: "14" },
    { id: 23, value: "70" },
    { id: 24, value: "68" },
    { id: 25, value: "21" },
    { id: 26, value: "86" },
    { id: 27, value: "90" },
    { id: 28, value: "73" },
  ],
  [
    { id: 29, value: "32" },
    { id: 30, value: "64" },
    { id: 31, value: "51" },
    { id: 32, value: "20" },
    { id: 33, value: "12" },
    { id: 34, value: "33" },
    { id: 35, value: "86" },
  ],
  [
    { id: 36, value: "05" },
    { id: 37, value: "21" },
    { id: 38, value: "36" },
    { id: 39, value: "54" },
    { id: 40, value: "59" },
    { id: 41, value: "66" },
    { id: 42, value: "43" },
  ],
  [
    { id: 1, value: "47" },
    { id: 2, value: "62" },
    { id: 3, value: "70" },
    { id: 4, value: "83" },
    { id: 5, value: "45" },
    { id: 6, value: "30" },
    { id: 7, value: "13" },
  ],
  [
    { id: 8, value: "42" },
    { id: 9, value: "08" },
    { id: 10, value: "23" },
    { id: 11, value: "99" },
    { id: 12, value: "82" },
    { id: 13, value: "97" },
    { id: 14, value: "91" },
  ],
  [
    { id: 15, value: "10" },
    { id: 16, value: "64" },
    { id: 17, value: "66" },
    { id: 18, value: "60" },
    { id: 19, value: "98" },
    { id: 20, value: "71" },
    { id: 21, value: "47" },
  ],
  [
    { id: 22, value: "62" },
    { id: 23, value: "36" },
    { id: 24, value: "91" },
    { id: 25, value: "37" },
    { id: 26, value: "43" },
    { id: 27, value: "83" },
    { id: 28, value: "25" },
  ],
  [
    { id: 29, value: "09" },
    { id: 30, value: "96" },
    { id: 31, value: "89" },
    { id: 32, value: "68" },
    { id: 33, value: "32" },
    { id: 34, value: "55" },
    { id: 35, value: "01" },
  ],
  [
    { id: 36, value: "25" },
    { id: 37, value: "51" },
    { id: 38, value: "31" },
    { id: 39, value: "63" },
    { id: 40, value: "51" },
    { id: 41, value: "20" },
    { id: 42, value: "47" },
  ],
  [
    { id: 43, value: "55" },
    { id: 44, value: "80" },
    { id: 45, value: "29" },
    { id: 46, value: "02" },
    { id: 47, value: "33" },
    { id: 48, value: "65" },
    { id: 49, value: "81" },
  ],
  [
    { id: 50, value: "59" },
    { id: 51, value: "40" },
    { id: 52, value: "24" },
    { id: 53, value: "92" },
    { id: 54, value: "52" },
    { id: 55, value: "86" },
    { id: 56, value: "02" },
  ],
  [
    { id: 57, value: "16" },
    { id: 58, value: "87" },
    { id: 59, value: "95" },
    { id: 60, value: "49" },
    { id: 61, value: "35" },
    { id: 62, value: "87" },
    { id: 63, value: "35" },
  ],
  [
    { id: 1, value: "22" },
    { id: 2, value: "08" },
    { id: 3, value: "44" },
    { id: 4, value: "65" },
    { id: 5, value: "79" },
    { id: 6, value: "36" },
    { id: 7, value: "42" },
  ],
  [
    { id: 8, value: "64" },
    { id: 9, value: "80" },
    { id: 10, value: "56" },
    { id: 11, value: "02" },
    { id: 12, value: "78" },
    { id: 13, value: "46" },
    { id: 14, value: "85" },
  ],
  [
    { id: 15, value: "15" },
    { id: 16, value: "84" },
    { id: 17, value: "77" },
    { id: 18, value: "21" },
    { id: 19, value: "04" },
    { id: 20, value: "19" },
    { id: 21, value: "94" },
  ],
  [
    { id: 22, value: "79" },
    { id: 23, value: "30" },
    { id: 24, value: "95" },
    { id: 25, value: "61" },
    { id: 26, value: "42" },
    { id: 27, value: "23" },
    { id: 28, value: "90" },
  ],
  [
    { id: 29, value: "01" },
    { id: 30, value: "41" },
    { id: 31, value: "38" },
    { id: 32, value: "25" },
    { id: 33, value: "49" },
    { id: 34, value: "52" },
    { id: 35, value: "06" },
  ],
  [
    { id: 36, value: "44" },
    { id: 37, value: "90" },
    { id: 38, value: "15" },
    { id: 39, value: "69" },
    { id: 40, value: "65" },
    { id: 41, value: "81" },
    { id: 42, value: "98" },
  ],
  [
    { id: 43, value: "61" },
    { id: 44, value: "97" },
    { id: 45, value: "03" },
    { id: 46, value: "28" },
    { id: 47, value: "43" },
    { id: 48, value: "53" },
    { id: 49, value: "57" },
  ],
  [
    { id: 50, value: "37" },
    { id: 51, value: "18" },
    { id: 52, value: "14" },
    { id: 53, value: "89" },
    { id: 54, value: "77" },
    { id: 55, value: "84" },
    { id: 56, value: "32" },
  ],
  [
    { id: 57, value: "52" },
    { id: 58, value: "93" },
    { id: 59, value: "49" },
    { id: 60, value: "25" },
    { id: 61, value: "33" },
    { id: 62, value: "79" },
    { id: 63, value: "38" },
  ],
  [
    { id: 1, value: "27" },
    { id: 2, value: "48" },
    { id: 3, value: "00" },
    { id: 4, value: "48" },
    { id: 5, value: "73" },
    { id: 6, value: "81" },
    { id: 7, value: "43" },
  ],
  [
    { id: 9, value: "81" },
    { id: 10, value: "52" },
    { id: 11, value: "49" },
    { id: 12, value: "96" },
    { id: 13, value: "07" },
    { id: 14, value: "59" },
    { id: 15, value: "80" },
  ],
  [
    { id: 16, value: "45" },
    { id: 17, value: "93" },
    { id: 18, value: "80" },
    { id: 19, value: "48" },
    { id: 20, value: "15" },
    { id: 21, value: "64" },
    { id: 22, value: "08" },
  ],
  [
    { id: 23, value: "93" },
    { id: 24, value: "50" },
    { id: 25, value: "27" },
    { id: 26, value: "65" },
    { id: 27, value: "53" },
    { id: 28, value: "62" },
    { id: 29, value: "54" },
  ],
  [
    { id: 30, value: "51" },
    { id: 31, value: "39" },
    { id: 32, value: "26" },
    { id: 33, value: "10" },
    { id: 34, value: "91" },
    { id: 35, value: "72" },
    { id: 36, value: "67" },
  ],
  [
    { id: 37, value: "60" },
    { id: 38, value: "98" },
    { id: 39, value: "88" },
    { id: 40, value: "25" },
    { id: 41, value: "90" },
    { id: 42, value: "56" },
    { id: 43, value: "74" },
  ],
  [
    { id: 44, value: "33" },
    { id: 45, value: "98" },
    { id: 46, value: "30" },
    { id: 47, value: "91" },
    { id: 48, value: "16" },
    { id: 49, value: "37" },
    { id: 50, value: "56" },
  ],
  [
    { id: 51, value: "25" },
    { id: 52, value: "31" },
    { id: 53, value: "63" },
    { id: 54, value: "24" },
    { id: 55, value: "03" },
    { id: 56, value: "36" },
    { id: 57, value: "38" },
  ],
  [
    { id: 58, value: "82" },
    { id: 59, value: "12" },
    { id: 60, value: "91" },
    { id: 61, value: "25" },
    { id: 62, value: "44" },
    { id: 63, value: "14" },
    { id: 64, value: "30" },
  ],
  [
    { id: 65, value: "44" },
    { id: 66, value: "80" },
    { id: 67, value: "47" },
    { id: 68, value: "52" },
    { id: 69, value: "44" },
    { id: 70, value: "80" },
    { id: 71, value: "38" },
  ],
  [
    { id: 72, value: "90" },
    { id: 73, value: "03" },
    { id: 74, value: "86" },
    { id: 75, value: "06" },
    { id: 76, value: "90" },
    { id: 77, value: "03" },
    { id: 78, value: "91" },
  ],
  [
    { id: 79, value: "30" },
    { id: 80, value: "61" },
    { id: 81, value: "62" },
    { id: 82, value: "46" },
    { id: 83, value: "70" },
    { id: 84, value: "54" },
    { id: 85, value: "55" },
  ],
  [
    { id: 86, value: "38" },
    { id: 87, value: "51" },
    { id: 88, value: "16" },
    { id: 89, value: "53" },
    { id: 90, value: "81" },
    { id: 91, value: "22" },
    { id: 92, value: "03" },
  ],
];

export const SUPREMEDAY = [
  [
    { id: 442, value: "00" },
    { id: 443, value: "68" },
    { id: 444, value: "46" },
    { id: 445, value: "01" },
    { id: 446, value: "66" },
    { id: 447, value: "97" },
    { id: 448, value: "28" },
  ],
  [
    { id: 449, value: "79" },
    { id: 450, value: "08" },
    { id: 451, value: "20" },
    { id: 452, value: "79" },
    { id: 453, value: "36" },
    { id: 454, value: "26" },
    { id: 455, value: "10" },
  ],
  [
    { id: 456, value: "46" },
    { id: 457, value: "38" },
    { id: 458, value: "97" },
    { id: 459, value: "20" },
    { id: 460, value: "85" },
    { id: 461, value: "70" },
    { id: 462, value: "06" },
  ],
  [
    { id: 463, value: "09" },
    { id: 464, value: "39" },
    { id: 465, value: "52" },
    { id: 466, value: "11" },
    { id: 465, value: "52" },

    { id: 468, value: "01" },
    { id: 469, value: "07" },
  ],
  [
    { id: 470, value: "91" },
    { id: 471, value: "32" },
    { id: 472, value: "35" },
    { id: 473, value: "81" },
    { id: 474, value: "45" },
    { id: 475, value: "79" },
    { id: 476, value: "45" },
  ],
  [
    { id: 477, value: "86" },
    { id: 478, value: "09" },
    { id: 479, value: "71" },
    { id: 480, value: "93" },
    { id: 481, value: "82" },
    { id: 482, value: "75" },
    { id: 483, value: "93" },
  ],
  [
    { id: 484, value: "59" },
    { id: 485, value: "55" },
    { id: 486, value: "33" },
    { id: 487, value: "08" },
    { id: 488, value: "06" },
    { id: 489, value: "15" },
    { id: 490, value: "81" },
  ],
  [
    { id: 491, value: "74" },
    { id: 492, value: "11" },
    { id: 493, value: "22" },
    { id: 494, value: "49" },
    { id: 495, value: "65" },
    { id: 496, value: "02" },
    { id: 497, value: "05" },
  ],
  [
    { id: 498, value: "41" },
    { id: 499, value: "35" },
    { id: 500, value: "86" },
    { id: 501, value: "76" },
    { id: 502, value: "92" },
    { id: 503, value: "15" },
    { id: 504, value: "86" },
  ],
  [
    { id: 505, value: "96" },
    { id: 506, value: "55" },
    { id: 507, value: "21" },
    { id: 508, value: "94" },
    { id: 509, value: "92" },
    { id: 510, value: "04" },
    { id: 511, value: "21" },
  ],
  [
    { id: 512, value: "35" },
    { id: 513, value: "76" },
    { id: 514, value: "64" },
    { id: 515, value: "35" },
    { id: 516, value: "45" },
    { id: 517, value: "33" },
    { id: 518, value: "84" },
  ],
  [
    { id: 519, value: "67" },
    { id: 520, value: "71" },
    { id: 521, value: "62" },
    { id: 522, value: "75" },
    { id: 523, value: "40" },
    { id: 524, value: "90" },
    { id: 525, value: "75" },
  ],
  [
    { id: 535, value: "08" },
    { id: 527, value: "23" },
    { id: 528, value: "34" },
    { id: 529, value: "62" },
    { id: 530, value: "09" },
    { id: 531, value: "89" },
    { id: 532, value: "43" },
  ],
  [
    { id: 533, value: "54" },
    { id: 534, value: "08" },
    { id: 535, value: "08" },
    { id: 536, value: "46" },
    { id: 537, value: "91" },
    { id: 538, value: "11" },
    { id: 539, value: "70" },
  ],
  [
    { id: 540, value: "25" },
    { id: 541, value: "64" },
    { id: 542, value: "02" },
    { id: 543, value: "39" },
    { id: 544, value: "31" },
    { id: 545, value: "15" },
    { id: 546, value: "13" },
  ],
  [
    { id: 547, value: "75" },
    { id: 548, value: "76" },
    { id: 549, value: "98" },
    { id: 550, value: "98" },
    { id: 551, value: "41" },
    { id: 552, value: "05" },
    { id: 553, value: "35" },
  ],
  [
    { id: 554, value: "55" },
    { id: 555, value: "71" },
    { id: 556, value: "48" },
    { id: 557, value: "16" },
    { id: 558, value: "66" },
    { id: 559, value: "05" },
    { id: 560, value: "61" },
  ],
  [
    { id: 561, value: "98" },
    { id: 562, value: "37" },
    { id: 563, value: "47" },
    { id: 564, value: "12" },
    { id: 565, value: "66" },
    { id: 566, value: "25" },
    { id: 567, value: "88" },
  ],
  [
    { id: 568, value: "01" },
    { id: 569, value: "38" },
    { id: 570, value: "12" },
    { id: 571, value: "11" },
    { id: 572, value: "90" },
    { id: 573, value: "90" },
    { id: 574, value: "97" },
  ],
  [
    { id: 575, value: "55" },
    { id: 576, value: "81" },
    { id: 577, value: "47" },
    { id: 578, value: "22" },
    { id: 579, value: "38" },
    { id: 580, value: "45" },
    { id: 581, value: "73" },
  ],
  [
    { id: 582, value: "59" },
    { id: 583, value: "78" },
    { id: 584, value: "06" },
    { id: 585, value: "15" },
    { id: 586, value: "10" },
    { id: 587, value: "22" },
    { id: 588, value: "10" },
  ],
  [
    { id: 589, value: "77" },
    { id: 590, value: "96" },
    { id: 591, value: "00" },
    { id: 592, value: "73" },
    { id: 593, value: "84" },
    { id: 594, value: "19" },
    { id: 595, value: "07" },
  ],
  [
    { id: 596, value: "03" },
    { id: 597, value: "72" },
    { id: 598, value: "11" },
    { id: 599, value: "77" },
    { id: 600, value: "89" },
    { id: 601, value: "44" },
    { id: 602, value: "79" },
  ],
  [
    { id: 603, value: "33" },
    { id: 604, value: "48" },
    { id: 605, value: "32" },
    { id: 606, value: "75" },
    { id: 607, value: "26" },
    { id: 608, value: "15" },
    { id: 609, value: "19" },
  ],
  [
    { id: 610, value: "85" },
    { id: 611, value: "20" },
    { id: 612, value: "94" },
    { id: 613, value: "88" },
    { id: 614, value: "69" },
    { id: 615, value: "78" },
    { id: 616, value: "88" },
  ],
  [
  { "id": 617, "value": "88" },
  { "id": 618, "value": "55" },
  { "id": 619, "value": "69" },
  { "id": 620, "value": "09" },
  { "id": 621, "value": "98" },
  { "id": 622, "value": "52" },
  { "id": 623, "value": "34" }
],
[
  { "id": 624, "value": "98" },
  { "id": 625, "value": "26" },
  { "id": 626, "value": "26" },
  { "id": 627, "value": "71" },
  { "id": 628, "value": "00" },
  { "id": 629, "value": "95" },
  { "id": 630, "value": "02" }
],
[
  { "id": 631, "value": "08" },
  { "id": 632, "value": "42" },
  { "id": 633, "value": "66" },
  { "id": 634, "value": "77" },
  { "id": 635, "value": "77" },
  { "id": 636, "value": "95" },
  { "id": 637, "value": "15" }
],
[
  { "id": 638, "value": "03" },
  { "id": 639, "value": "69" },
  { "id": 640, "value": "54" },
  { "id": 641, "value": "07" },
  { "id": 642, "value": "62" },
  { "id": 643, "value": "81" },
  { "id": 644, "value": "45" }
],
[
  { "id": 645, "value": "03" },
  { "id": 646, "value": "08" },
  { "id": 647, "value": "44" },
  { "id": 648, "value": "05" },
  { "id": 649, "value": "92" },
  { "id": 650, "value": "29" },
  { "id": 651, "value": "92" }
],
[
  { "id": 652, "value": "78" },
  { "id": 653, "value": "46" },
  { "id": 654, "value": "12" },
  { "id": 655, "value": "64" },
  { "id": 656, "value": "45" },
  { "id": 657, "value": "09" },
  { "id": 658, "value": "37" }
],
[
  { "id": 659, "value": "88" },
  { "id": 660, "value": "01" },
  { "id": 661, "value": "33" },
  { "id": 662, "value": "03" },
  { "id": 663, "value": "16" },
  { "id": 664, "value": "90" },
  { "id": 665, "value": "71" }
],
[
  { "id": 666, "value": "41" },
  { "id": 667, "value": "05" },
  { "id": 668, "value": "83" },
  { "id": 669, "value": "**" },
  { "id": 670, "value": "09" },
  { "id": 671, "value": "59" },
  { "id": 672, "value": "84" }
]
,[
  { "id": 673, "value": "14" },
  { "id": 674, "value": "36" },
  { "id": 675, "value": "16" },
  { "id": 676, "value": "14" },
  { "id": 677, "value": "52" },
  { "id": 678, "value": "22" },
  { "id": 679, "value": "34" }
],
[
  { "id": 680, "value": "11" },
  { "id": 681, "value": "36" },
  { "id": 682, "value": "60" },
  { "id": 683, "value": "06" },
  { "id": 684, "value": "08" },
  { "id": 685, "value": "79" },
  { "id": 686, "value": "83" }
],
[
  { "id": 687, "value": "12" },
  { "id": 688, "value": "77" },
  { "id": 689, "value": "25" },
  { "id": 690, "value": "80" },
  { "id": 691, "value": "08" },
  { "id": 692, "value": "98" },
  { "id": 693, "value": "71" }
],
[
  { "id": 694, "value": "97" },
  { "id": 695, "value": "16" },
  { "id": 696, "value": "65" },
  { "id": 697, "value": "10" },
  { "id": 698, "value": "25" },
  { "id": 699, "value": "76" },
  { "id": 700, "value": "70" }
],
[
  { "id": 701, "value": "89" },
  { "id": 702, "value": "21" },
  { "id": 703, "value": "70" },
  { "id": 704, "value": "84" },
  { "id": 705, "value": "54" },
  { "id": 706, "value": "73" },
  { "id": 707, "value": "39" }
],
[
  { "id": 708, "value": "94" },
  { "id": 709, "value": "47" },
  { "id": 710, "value": "79" },
  { "id": 711, "value": "25" },
  { "id": 712, "value": "06" },
  { "id": 713, "value": "33" },
  { "id": 714, "value": "90" }
],
[
  { "id": 715, "value": "88" },
  { "id": 716, "value": "20" },
  { "id": 717, "value": "42" },
  { "id": 718, "value": "41" },
  { "id": 719, "value": "98" },
  { "id": 720, "value": "29" },
  { "id": 721, "value": "30" }
],
[
  { "id": 722, "value": "34" },
  { "id": 723, "value": "00" },
  { "id": 724, "value": "79" },
  { "id": 725, "value": "22" },
  { "id": 726, "value": "89" },
  { "id": 727, "value": "08" },
  { "id": 728, "value": "77" }
]
,
];

export const SUPREMENIGHT = [
  [
    { id: 127, value: "16" },
    { id: 128, value: "61" },
    { id: 129, value: "93" },
    { id: 130, value: "88" },
    { id: 131, value: "07" },
    { id: 132, value: "73" },
    { id: 133, value: "98" },
  ],
  [
    { id: 134, value: "76" },
    { id: 135, value: "99" },
    { id: 136, value: "49" },
    { id: 137, value: "58" },
    { id: 138, value: "03" },
    { id: 139, value: "00" },
    { id: 140, value: "01" },
  ],
  [
    { id: 141, value: "34" },
    { id: 142, value: "11" },
    { id: 143, value: "36" },
    { id: 144, value: "69" },
    { id: 145, value: "97" },
    { id: 146, value: "48" },
    { id: 147, value: "53" },
  ],
  [
    { id: 148, value: "69" },
    { id: 149, value: "70" },
    { id: 150, value: "96" },
    { id: 151, value: "90" },
    { id: 152, value: "**" },
    { id: 153, value: "11" },
    { id: 154, value: "97" },
  ],
  [
    { id: 155, value: "77" },
    { id: 156, value: "07" },
    { id: 157, value: "28" },
    { id: 158, value: "32" },
    { id: 159, value: "88" },
    { id: 160, value: "91" },
    { id: 161, value: "60" },
  ],
  [
    { id: 162, value: "51" },
    { id: 163, value: "89" },
    { id: 164, value: "42" },
    { id: 165, value: "01" },
    { id: 166, value: "52" },
    { id: 167, value: "44" },
    { id: 168, value: "21" },
  ],
  [
    { id: 169, value: "33" },
    { id: 170, value: "42" },
    { id: 171, value: "10" },
    { id: 172, value: "73" },
    { id: 173, value: "93" },
    { id: 174, value: "55" },
    { id: 175, value: "00" },
  ],
  [
    { id: 176, value: "06" },
    { id: 177, value: "95" },
    { id: 178, value: "75" },
    { id: 179, value: "87" },
    { id: 180, value: "23" },
    { id: 181, value: "39" },
    { id: 182, value: "76" },
  ],
  [
    { id: 183, value: "05" },
    { id: 184, value: "41" },
    { id: 185, value: "92" },
    { id: 186, value: "46" },
    { id: 187, value: "41" },
    { id: 188, value: "73" },
    { id: 189, value: "08" },
  ],
  [
    { id: 190, value: "06" },
    { id: 191, value: "72" },
    { id: 192, value: "00" },
    { id: 193, value: "73" },
    { id: 194, value: "39" },
    { id: 195, value: "95" },
    { id: 196, value: "81" },
  ],
  [
    { id: 197, value: "99" },
    { id: 198, value: "76" },
    { id: 199, value: "56" },
    { id: 200, value: "41" },
    { id: 201, value: "41" },
    { id: 202, value: "02" },
    { id: 203, value: "35" },
  ],
  [
    { id: 204, value: "25" },
    { id: 205, value: "19" },
    { id: 206, value: "13" },
    { id: 207, value: "87" },
    { id: 208, value: "93" },
    { id: 209, value: "21" },
    { id: 210, value: "20" },
  ],
  [
    { id: 211, value: "**" },
    { id: 212, value: "46" },
    { id: 213, value: "65" },
    { id: 214, value: "10" },
    { id: 215, value: "37" },
    { id: 216, value: "98" },
    { id: 217, value: "53" },
  ],
  [
    { id: 218, value: "37" },
    { id: 219, value: "29" },
    { id: 220, value: "60" },
    { id: 221, value: "80" },
    { id: 222, value: "74" },
    { id: 223, value: "33" },
    { id: 224, value: "46" },
  ],
  [
    { id: 225, value: "51" },
    { id: 226, value: "07" },
    { id: 227, value: "47" },
    { id: 228, value: "68" },
    { id: 229, value: "68" },
    { id: 230, value: "75" },
    { id: 231, value: "24" },
  ],
  [
    { id: 232, value: "99" },
    { id: 233, value: "44" },
    { id: 234, value: "74" },
    { id: 235, value: "55" },
    { id: 236, value: "87" },
    { id: 237, value: "11" },
    { id: 238, value: "79" },
  ],
  [
    { id: 239, value: "66" },
    { id: 240, value: "05" },
    { id: 241, value: "81" },
    { id: 242, value: "46" },
    { id: 243, value: "26" },
    { id: 244, value: "45" },
    { id: 245, value: "49" },
  ],
  [
    { id: 246, value: "66" },
    { id: 247, value: "78" },
    { id: 248, value: "38" },
    { id: 249, value: "09" },
    { id: 250, value: "53" },
    { id: 251, value: "11" },
    { id: 252, value: "46" },
  ],
  [
    { id: 253, value: "27" },
    { id: 254, value: "90" },
    { id: 255, value: "06" },
    { id: 256, value: "54" },
    { id: 257, value: "83" },
    { id: 258, value: "40" },
    { id: 259, value: "62" },
  ],
  [
    { id: 260, value: "39" },
    { id: 261, value: "77" },
    { id: 262, value: "18" },
    { id: 263, value: "95" },
    { id: 264, value: "14" },
    { id: 265, value: "64" },
    { id: 266, value: "94" },
  ],
  [
    { id: 267, value: "20" },
    { id: 268, value: "12" },
    { id: 269, value: "65" },
    { id: 270, value: "73" },
    { id: 271, value: "48" },
    { id: 272, value: "97" },
    { id: 273, value: "01" },
  ],
  [
    { id: 274, value: "31" },
    { id: 275, value: "75" },
    { id: 276, value: "85" },
    { id: 277, value: "66" },
    { id: 278, value: "58" },
    { id: 279, value: "99" },
    { id: 280, value: "28" },
  ],
  [
    { id: 281, value: "79" },
    { id: 282, value: "12" },
    { id: 283, value: "95" },
    { id: 284, value: "33" },
    { id: 285, value: "48" },
    { id: 286, value: "20" },
    { id: 287, value: "47" },
  ],
  [
    { id: 288, value: "51" },
    { id: 289, value: "72" },
    { id: 290, value: "11" },
    { id: 291, value: "84" },
    { id: 292, value: "73" },
    { id: 293, value: "57" },
    { id: 294, value: "99" },
  ],
  [
    { id: 295, value: "26" },
    { id: 296, value: "43" },
    { id: 297, value: "58" },
    { id: 298, value: "90" },
    { id: 299, value: "12" },
    { id: 300, value: "15" },
    { id: 301, value: "35" },
  ],
  [
    { id: 302, value: "07" },
    { id: 303, value: "96" },
    { id: 304, value: "64" },
    { id: 305, value: "21" },
    { id: 306, value: "38" },
    { id: 307, value: "71" },
    { id: 308, value: "50" },
  ],
  [
    { id: 309, value: "13" },
    { id: 310, value: "76" },
    { id: 311, value: "50" },
    { id: 312, value: "75" },
    { id: 313, value: "45" },
    { id: 314, value: "19" },
    { id: 315, value: "76" },
  ],
  [
    { id: 316, value: "16" },
    { id: 317, value: "49" },
    { id: 318, value: "11" },
    { id: 319, value: "43" },
    { id: 320, value: "21" },
    { id: 321, value: "81" },
    { id: 322, value: "27" },
  ],
  [
    { id: 323, value: "05" },
    { id: 324, value: "54" },
    { id: 325, value: "39" },
    { id: 326, value: "81" },
    { id: 327, value: "79" },
    { id: 328, value: "75" },
    { id: 329, value: "41" },
  ],
  [
    { id: 330, value: "34" },
    { id: 331, value: "89" },
    { id: 332, value: "20" },
    { id: 333, value: "65" },
    { id: 334, value: "44" },
    { id: 335, value: "63" },
    { id: 336, value: "17" },
  ],
  [
    { id: 337, value: "20" },
    { id: 338, value: "86" },
    { id: 339, value: "53" },
    { id: 340, value: "02" },
    { id: 341, value: "71" },
    { id: 342, value: "62" },
    { id: 343, value: "84" },
  ],
  [
    { id: 344, value: "22" },
    { id: 345, value: "90" },
    { id: 346, value: "15" },
    { id: 347, value: "75" },
    { id: 348, value: "64" },
    { id: 349, value: "88" },
    { id: 350, value: "84" },
  ],
  [
    { id: 351, value: "74" },
    { id: 352, value: "33" },
    { id: 353, value: "17" },
    { id: 354, value: "**" },
    { id: 355, value: "62" },
    { id: 356, value: "93" },
    { id: 357, value: "17" },
  ],
  [
    { id: 358, value: "55" },
    { id: 359, value: "85" },
    { id: 360, value: "90" },
    { id: 361, value: "30" },
    { id: 362, value: "33" },
    { id: 363, value: "41" },
    { id: 364, value: "90" },
  ],
  [
    { id: 365, value: "35" },
    { id: 366, value: "72" },
    { id: 367, value: "19" },
    { id: 368, value: "27" },
    { id: 369, value: "86" },
    { id: 370, value: "06" },
    { id: 371, value: "87" },
  ],
  [
    { id: 372, value: "59" },
    { id: 373, value: "44" },
    { id: 374, value: "11" },
    { id: 375, value: "69" },
    { id: 376, value: "34" },
    { id: 377, value: "13" },
    { id: 378, value: "63" },
  ],
  [
    { id: 379, value: "75" },
    { id: 380, value: "80" },
    { id: 381, value: "44" },
    { id: 382, value: "73" },
    { id: 383, value: "15" },
    { id: 384, value: "25" },
    { id: 385, value: "37" },
  ],
  [
    { id: 386, value: "63" },
    { id: 387, value: "18" },
    { id: 388, value: "50" },
    { id: 389, value: "66" },
    { id: 390, value: "01" },
    { id: 391, value: "36" },
    { id: 392, value: "52" },
  ],
  [
    { id: 393, value: "32" },
    { id: 394, value: "98" },
    { id: 395, value: "76" },
    { id: 396, value: "01" },
    { id: 397, value: "93" },
    { id: 398, value: "25" },
    { id: 399, value: "80" },
  ],
  [
    { id: 400, value: "76" },
    { id: 401, value: "14" },
    { id: 402, value: "98" },
    { id: 403, value: "48" },
    { id: 404, value: "22" },
    { id: 405, value: "91" },
    { id: 406, value: "80" },
  ],
  [
    { id: 407, value: "72" },
    { id: 408, value: "34" },
    { id: 409, value: "40" },
    { id: 410, value: "96" },
    { id: 411, value: "84" },
    { id: 412, value: "65" },
    { id: 413, value: "11" },
  ],
  [
    { id: 414, value: "17" },
    { id: 415, value: "93" },
    { id: 416, value: "23" },
    { id: 417, value: "05" },
    { id: 418, value: "44" },
    { id: 419, value: "39" },
    { id: 420, value: "56" },
  ],
];

export const redJodi = [
  "11",
  "16",
  "61",
  "66",
  "22",
  "27",
  "77",
  "72",
  "38",
  "83",
  "88",
  "33",
  "50",
  "55",
  "00",
  "05",
  "49",
  "94",
  "99",
  "44",
];

export const redPana = [
  "000",
  "111",
  "222",
  "333",
  "444",
  "555",
  "666",
  "777",
  "888",
  "999",
];
